import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useVisible } from 'react-hooks-visible'
import styled from 'styled-components'
import { projectType } from '../constant'

const WorkItemStyles = styled.div`
  color: white;
  font-size: 25px;
  display: inline-block;
  width: 100%;
  min-height: 420px;
  // display: flex;
  vertical-align: top;
  .dot{
    background-color: #DEFC1C;
  }
  @media (min-height: 1800px) {
    min-height: 20vh;
  }
  &:hover {
    a {
      color: #DEFC1C;
    }
    .dot {
      background-color: #DEFC1C;
    }
  }
`

const Title = styled.div`
  color: white;
  a {
    color: white;
    font-family: 'reckless_light';
    font-size: 30px;
    line-height: 30px;
  }
`

const WorkType = styled.div`
  a {
    color: white;
    font-size: 20px;
    font-family: 'reckless_light';;
    font-weight: normal;
  }
  .dot {
    height: 8px;
    width: 8px;
    background-color: white;
    border-radius: 50%;
    margin-right: 5px;
    margin-bottom: 2px;
    display: inline-block;
  }
`

const Project = (props) => {
  const { project } = props || {}
  const primaryProjectType = useMemo(() => project.primaryProjectType, [project])

  const [isVisited, setIsVisited] = useState(false)
  const [targetRef, visible] = useVisible()

  useEffect(() => {
    if (isVisited) return
    if (visible === 0) return

    setIsVisited(true)
  }, [isVisited, visible, targetRef])

  return (
    <WorkItemStyles ref={targetRef} className='p-12 hover:bg-blue transition'>
      <Link
        to={{
          pathname: '/project',
          search: `id=${project.id}`,
        }}
      >
        {project.coverImage && isVisited && (
          <img
            src={project.coverImage}
            alt={project.title}
            style={{ width: '100%', height: 240, top: 0, objectFit: 'contain' }}
          />
          // <img src={project.coverImage} alt={project.title} style={{width: '100%' , height:'100%'}}/>
        )}
      </Link>
      <WorkType>
        <Link
          to={{
            pathname: '/work',
            search: `?type=${primaryProjectType}`,
          }}
        >
          <p className='py-4 pb-1 border-b border-secondary border-dashed inline-block text-center'>
            <span className='dot'></span>
            {projectType[primaryProjectType]}
          </p>
        </Link>
      </WorkType>
      <Title className='leading-8 pt-2'>
        <Link
          to={{
            pathname: '/project',
            search: `id=${project.id}`,
          }}
        >
          {project.title}
        </Link>
      </Title>
    </WorkItemStyles>
  )
}

export default Project
