import React, { useEffect, useState } from "react";
import styled from "styled-components";

const SpanWrapper = styled.span`
  position: relative;
  &::after {
    content: attr(data-label);
    display: inline-block;
    color: #a3a6a8;
    position: absolute;
    ${props => `top: ${props.fromtop || 36}px;`};
    font-size: 12px;
    right: 0;
    opacity: 0.999;
    white-space: nowrap;
  }
`;

const LengthLimitInput = (props) => {
  const { defaultValue = '', value = '', ...rest } = props;
  const { maxLength, fromtop } = rest
  const [currentLength, setCurrentLength] = useState(
    (defaultValue && defaultValue.trim().length) || (value && value.trim().length) || 0
  );

  useEffect(() => {
    if(!value || value.length === 0){
      setCurrentLength(0)
      return
    }
    const currentLength = value.trim().length;
    if(currentLength !== 0) {
      setCurrentLength(currentLength);
    } 
  }, [value]);

  const remainStr = `${maxLength - currentLength}/${maxLength} characters left`;

  return (
    <SpanWrapper data-label={remainStr} fromtop={fromtop}>
      <input {...rest} value={value || defaultValue} onChange={props.onChange} />
    </SpanWrapper>
  );
};

LengthLimitInput.defaultProps = {
  maxLength: 100,
  defaultValue: "",
};

export default LengthLimitInput;

export const LengthLimitTextarea = (props) => {
  const { defaultValue = '', value = '', ...rest } = props;
  const { maxLength, fromtop } = rest
  const [currentLength, setCurrentLength] = useState(
    (defaultValue && defaultValue.trim().length) || (value && value.trim().length) || 0
  );

  useEffect(() => {
    if(!value || value.length === 0){
      setCurrentLength(0)
      return
    }
    const currentLength = value.trim().length;
    if(currentLength !== 0) {
      setCurrentLength(currentLength);
    } 
  }, [value]);

  const remainStr = `${maxLength - currentLength}/${maxLength} characters left`;

  return (
    <SpanWrapper data-label={remainStr} fromtop={fromtop}>
      <textarea {...rest} value={value || defaultValue} onChange={props.onChange} />
    </SpanWrapper>
  );
}
