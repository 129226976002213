import styled from "styled-components";
import cursorSVG from "../../pages/2023/cursor-with-shadow-2023.svg";

export const Container = styled.div`
  position: relative;
  cursor: url(${cursorSVG}), auto;
  flex-direction: column;
  min-height: calc(100vh);
`

export const EventsBGTop = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;

  img {
    //min-width: 1920px;
    width: 100vw;
    height: 1893px;
    overflow: hidden;
  }

  .bgMobile {
    display: none;
  }

  @media (max-width: 720px) {
    img {

    }

    .bgMobile {
      display: block;
    }

    .bgDesktop {
      display: none;
    }
  }
`
export const ContainerSectionOne = styled.section`
  position: absolute;
  width: 100%;
  height: 870px;
  z-index: 10;
  @media (max-width: 720px) {
    height: 950px;
  }

`
export const EventsContainer = styled.div`
  position: relative;
  top: 870px;
  --a: ((150 - 110)/(1920 - 375));
  --b: 4150px - 1920px*var(--a);
  height: calc(var(--a)*100vw + var(--b));
  width: 100%;
  padding-bottom: 500px;
  @media (max-width: 1024px) {
    --b: 6150px - 1920px*var(--a);
  }
  @media (max-width: 622px) {
    --b: 5150px - 1920px*var(--a);
  }
`
export const TextCenter = styled.h1`
  position: relative;
  text-align: center;
  z-index: 10;
  color: #F0EEE9;
  font-family: Reckless_light, serif;
  font-size: 250px;
  line-height: 320px;
  letter-spacing: -0.02em;
  font-weight: 400;
  @media (max-width: 2879px) {

  }

  @media (max-width: 2559px) {

  }

  @media (max-width: 1919px) {

  }

  @media (max-width: 1679px) {

  }

  @media (max-width: 1279px) {

  }

  @media (max-width: 767px) {
    font-size: 120px;
    line-height: 130px;
  }

  @media (max-width: 359px) {

  }
`

export const TextCenterDiv = styled.div`
    position: relative;
    padding: 120px 0 60px 0;

  @media (max-width: 767px) {
    top: 20%;
`

export const TextCenterTop = styled.div`
  position: absolute;
  top: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  p,
  h2 {
    margin: 0;
    padding: 2px;
    font-family: Reckless_light, sans-serif;
    font-weight: 400;
    font-size: 42px;
    line-height: 50px;
    color: #191919;
  }

  p {
    margin-top: 60px;
    width: 1000px;
  }

  @media (max-width: 1024px) {
    p,
    h2 {
      font-size: 34px;
      width: 600px;
    }
  }
  @media (max-width: 720px) {
    top: 94%;
    p,
    h2 {
      font-size: 24px;
      width: 400px;
    }
  }
`
export const HeadContainer = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  background-color: #26439B;
  border-radius: 50%;
  img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 234px;
    height: 234px;
    object-fit: cover;
    filter: grayscale(100%);
    border-radius: 50%;
  }
  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 235px;
    height: 235px;
    //background-color: #000;
    //filter: invert(37%) sepia(17%) saturate(35%) hue-rotate(321deg) brightness(56%) contrast(113%);
    //opacity: 0.5;
    border-radius: 50%;
    z-index: 1;
  }
  @media (max-width: 1024px) {
    left:-webkit-calc(50% - 125px);
    left:-moz-calc(50% - 125px);
    left:calc(50% - 125px);
  }
  @media (max-width: 622px) {
    left:-webkit-calc(50% - 75px);
    left:-moz-calc(50% - 75px);
    left:calc(50% - 75px);
    width: 150px;
    height: 150px;
    img {
      width: 134px;
      height: 134px;
    }
    div {
      width: 135px;
      height: 135px;
    }
  }
`
export const EventDiv = styled.div`
  padding: 2%;
  display: inline-block;
  height: 100%;
  vertical-align: top;

  .title {
    color: #191919;
    font-size: 24px;
    font-family: 'reckless_light';
    margin-top: 8%;
    text-align: left;
    line-height: 1.4;
  }
  .time {
    color: #191919;
    text-align: left;
    font-size: 18px;
    display: inline-block;
    font-family: 'grotesque_pro_light';
  }
  .yellow {
    color: #defc1c;
    font-size: 18px;
    display: inline-block;
    font-family: 'grotesque_pro_light';
    margin-left: 2%;
  }
  .desc {
    text-align: left;
    margin-top: 2%;
    color: #191919;
    font-size: 18px;
    height: 200px;
    line-height: 1.6;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp: 10;
    // -webkit-box-orient: vertical;
  }
  button {
    color: #26439B;
    outline: none;
    width: 100%;
    height: 90px;
  }
`

export const EventsTimeline = styled.div`
  position: absolute;
  top: 3%;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 90%;
  z-index: 30;

  .timelineDayHeading {
    position: relative;
  }

  .timelineCircle {
    position: relative;
    top: 50px;
    left: -107px;
    width: 20px;
    height: 20px;
    background-color: #26439B;
    border-radius: 50%;
  }

  .timelineSticker {
    position: absolute;
    width: 160px;
    min-width: 160px;
    top: -70px;
    left: -242px;
  }

  .timelineStickerMid {
    width: 440px;
    min-width: 440px;
    position: absolute;
    top: -256px;
    left: -504px;
  }

  .timelineStickerBot {
    width: 240px;
    min-width: 240px;
    position: absolute;
    top: -116px;
    left: -288px;
  }


  .borderLeftFull {
    border-left: 5px solid #26439B;
    position: absolute;
    top: -20px;
    bottom: 0;
    left: -100px;
  }

  .borderLeftMulti{
    border-left-width: 5px;
    border-left-style: solid;
    border-image: linear-gradient(180deg, #26439B 60%,  #C7C2E1 40%);
    border-image-slice: 1;
    position: absolute;
    top: -20%;
    bottom: 0;
    left: -100px;
  }

  .borderLeftDayOne {
    border-left: 5px solid #26439B;
    position: absolute;
    top: 60%;
    bottom: 0;
    left: -100px;
  }

  .borderLeftDayThree {
    border-left: 5px solid #C7C2E1;
    position: absolute;
    top: -20px;
    bottom: 40%;
    left: -100px;
  }

  .timelineEventDays {
    padding-left: 20px;
    width: 100%;
  }

  .timelineEventDaySingle {
    display: grid;
    padding-bottom: 12px;
  }

  .timelineInfoTop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 2px solid #26439B;
  }

  .timelineDayHeading > h3 {
    position: relative;
    font-family: Reckless_light, sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 52px;
    color: #26439B;
  }

  .timelineDateTime {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 10px;
    font-family: 'grotesque_pro_light';
    font-weight: 400;
    line-height: 22px;
    color: #26439B;
  }

  .timelineDateTime > p {
    margin-bottom: 12px;
  }

  .timelineDate {
    font-size: 24px;
  }

  .timelineTime {
    font-size: 20px;
  }

  .timelineTime > a {
    text-decoration: underline #C7C2E1 dotted 2px;
    text-underline-offset: 5px;
  }

  .timelineEventNames {
    padding-top: 14px;
  }

  .timelineEventNames > ul > li {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 35px;
    color: #26439B;
  }

  .timelineDateSmall {
    display: none;
  }

  .EventDivContainer {
    padding-top: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 25px;
    position: relative;
  }


  @media (max-width: 1024px) {
    grid-template-columns: 0.5fr 3fr;

    .timelineEventDaySingle {
      display: grid;
      grid-template-rows: 0.1fr 1fr;
      padding-bottom: 12px;
    }

    .borderLeftFull, .borderLeftMulti, .borderLeftDayOne, .borderLeftDayThree {
      left: -40px;
    }

    .borderLeftDayOne {
      border-left: 5px solid #26439B;
      position: absolute;
    }

    .borderLeftMulti {
      top: -100px;
    }

    #borderLeftFull {
      top: -102px;
    }

    .borderLeftDayThree {
      top: -64px;

    }

    .timelineSticker {
      left: -181px;
    }

    .timelineStickerMid {
      top: -253px;
      left: -444px;
    }

    .timelineStickerBot {
      left: -228px;
    }

    .EventDivContainer {
      grid-template-columns: 1fr;

    }
  }
  @media (max-width: 622px) {
    .timelineInfoTop {
      grid-template-columns: 1fr 2fr;
    }
    .timelineDayHeading > h3 {
      font-size: 40px;
      line-height: 42px;
    }
    .timelineDateTime p {
      margin-bottom: 4px;
      font-size: 20px;
    }
  }
  @media (max-width: 472px) {
    .borderLeftFull, .borderLeftMulti, .borderLeftDayOne, .borderLeftDayThree {
      left: -20px;
    }
    .borderLeftDayThree {
      top: -64px;

    }

    .timelineSticker {
      left: -161px;
    }

    .timelineStickerMid {
      left: -424px;
    }

    .timelineStickerBot {
      left: -208px;
    }

  }
  @media (max-width: 440px) {

    .timelineDayHeading > h3 {
      font-size: 30px;
      line-height: 32px;
    }
    .timelineDateTime p {
      font-size: 16px;
    }
  }
`
export const EventsBGBottom = styled.div`
  position: relative;
  bottom: 0;
  //overflow: hidden;
  width: 100%;
  left: 0;
  z-index: 9;

  img {
    min-width: 1920px;
    width: 100vw;
    height: 1680px;
    overflow: hidden;
  }

  .bgMobile {
    display: none;
  }

  @media (max-width: 720px) {
    img {

    }

    .bgMobile {
      display: block;
    }

    .bgDesktop {
      display: none;
    }
  }
`
