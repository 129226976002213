import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import dayjs from 'dayjs'
import cn from 'classnames'
import { useWindowSize } from '../../../hook'
import VideoModal from './VideoPlayerModal'

const Container = styled.div`
  min-height: calc(100vh);
  background-color: #191919;
  cursor: url(${(props) => props.cursorUrl}), auto;
  z-index : 0;
  svg:first-child {
    // height: 1200px;
  }
`
const TextLogo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 8.5rem;
  line-height: 9rem;
  letter-spacing: 0.01em;
  font-weight: 400;
`
const TextCenterTop = styled.div`
@media (min-width: 300px) {
  top : 99%;
  margin-top : 0px;
}
@media (min-width: 480px) {
  margin-top : 0px;
}
@media (min-width: 768px) {
  margin-top : 0px;
  top : 95%;
}
@media (min-width: 1280px) {
  margin-top : 0px;
  top : 95%;

}
@media (min-width: 1680px) {
  margin-top : 0px;
  top : 95%;
}
@media (min-width: 1920px) {
  top : 95%;
}
@media (min-width: 2880px) {
  top : 85%;
}
  h2,
  h3 {
    font-family: 'grotesque_pro_light';
    font-weight: 200;
    font-size: 1.8rem;
    letter-spacing: 0.05rem;
  }
  @media (min-width: 768px) {
    // bottom: 0%;
  }
  @media (min-width: 1600px) {
    bottom: 2%;
  }
`
const EmailSignUpCenter = styled.div`
form {
  height: 14vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items:center;
  font-family: 'grotesque_pro_light';
  font-size: 1.8rem;
}
#email{
  color: #191919;
  width: 401px;
  height: 60px;
  border-radius: 30px;
  padding: 5px 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 3px solid ${(props) => (props.hasError ? 'red' : '#191919')};
  display: ${(props) => (props.submitted ? 'none' : 'block')};
}
#email:hover{
  border: 3px solid red;
}
#email:focus{
  outline: none;
  border: 3px solid red;
}
#submit{
  width: 401px;
  height: 60px;
  background-color: #FF6044;
  color: #191919;
  border-radius: 30px;
  display: ${(props) => (props.submitted ? 'none' : 'block')};
}
#submit:hover{
  border: 3px solid #fa8975;
}
#inline-error{
  color: white;
  margin-top: 10px;
  display: ${(props) => (props.hasError ? 'block' : 'none')};
}
#submitted{
  color: white;
  margin-top: 10px;
  display: ${(props) => (props.submitted ? 'block' : 'none')};
}
@media (min-width: 300px) {
  top : 130%;
  form {
    height: 30vh;
    margin-bottom: 7%;
  }
  #email{
    width: 250px;
  }
  #submit{
    width: 250px;
  }
}
@media (min-width: 480px) {
  top : 130%;
  form {
    height: 20vh;
    margin-bottom: 7%;
  }
  #email{
    width: 401px;
  }
  #submit{
    width: 401px;
  }
}
@media (min-width: 768px) {
  top : 120%;
  form {
    height: 27vh;
    margin-bottom: 7%;
  }
}
@media (min-width: 1280px) {
  top : 114%;
  form {
    min-height: 23vh;
    margin-bottom: 7%;
  }
}
@media (min-width: 1440px) {
  top : 112%;
  form {
    min-height: 19vh;
    margin-bottom: 7%;
  }
}
@media (min-width: 1920px) {
  top : 107%;
  form {
    min-height: 18vh;
    margin-bottom: 7%;
  }
}
@media (min-width: 2880px) {
  top : 92%;
  form {
    min-height: 10vh;
  }
}
`

const StickerContainer = styled.div`
  top: ${(props) => props.top};
  left: ${(props) => props.imgLeft || 28};
  cursor: ${(props) => (props.pointer ? 'pointer' : 'inherit')};
  img {
    width: ${(props) => props.imgWidth || 84}px;
  }
  p {
    position: absolute;
    top: calc(${(props) => props.top} + 16px);
    font-family: Reckless;
    transform: rotate(${(props) => props.textRotate}deg);
  }
  @media (max-width: 550px) {
    top : 90% !important;
  }
  @media (max-width: 436px) {
    left : 100% !important;
  }
`
const CenterContainer = styled.div`
  @media (min-width: 1280px) {
    // top: 10%;
    // max-height: 40vh;
  }
  @media (min-width: 1920px) {
    // top: 20%;
  }
`
const TextCenter = styled.h4`
  font-family: 'sharp_grotesque_05';
  font-size: 420px;
  line-height: 360px;
  @media (min-width: 480px) {
    font-size: 460px;
    line-height: 400px;
  }
  @media (min-width: 768px) {
    font-size: 550px;
    line-height: 550px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 670px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 700px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 1000px;
    line-height: 1200px;
  }
`
const LiveInContainer = styled.div`
  width: 100%;
  top: 15%;
  height: 80vh;
  @media (min-width: 1440px) {
    top: 32%;
  }
  @media (min-width: 1920px) {
    top: 31%;
  }
  @media (min-width: 2880px) {
    top: 30%;
  }
`
const Sticker4Days = styled.img`
  top: 0;
  left: 15%;
  cursor: inherit;
  @media (min-width: 1024px) {
    top: 20%;
    left: 20%;
  }
  @media (min-width: 1440px) {
    left: 23%;
  }
  @media (min-width: 1920px) {
    left: 27%;
  }
  @media (min-width: 2880px) {
    left: 33%;
  }
`
const StickerMay = styled.img`
  bottom: 20%;
  right: 5%;
  cursor: inherit;
  @media (min-width: 768px) {
    bottom: 5%;
  }
  @media (min-width: 1024px) {
    right: 15%;
  }
  @media (min-width: 1280px) {
    right: 17%;
  }
  @media (min-width: 1440px) {
    right: 18%;
  }
  @media (min-width: 1920px) {
    right: 21%;
  }
  @media (min-width: 2880px) {
    bottom: 17%;
    right: 32%;
  }
`

const END_DATE_COUNTDOWN = '03/28/2022'

const randDirX = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY = Math.floor(Math.random() * 2) === 1 ? 1 : -1

export default function Teaser() {
  const history = useHistory()
  const ref = useRef()
  const endDay = dayjs(END_DATE_COUNTDOWN, 'MM/DD/YYYY')
  const daysLeft = useMemo(() => {
    const left = endDay.diff(dayjs(), 'days')
    return left > 0 ? left : 0
  }, [endDay])
  const hoursLeft = useMemo(() => {
    const left = endDay.diff(dayjs(), 'hours') - (daysLeft || 0) * 24
    return left > 0 ? left : 0
  }, [endDay, daysLeft])

  const [state, setState] = useState({ x: 0, y: 0})
  const [email, setEmail] = useState('')

  const onMouseMove = useCallback((e) => {
    setState({ x: e.nativeEvent.x, y: e.nativeEvent.y})
  }, [])


  const { screen } = useWindowSize()
  const isMobile = screen === 'sm'

  const rect = ref?.current?.getBoundingClientRect()

  useEffect(() => {
    if (daysLeft !== 0 || hoursLeft !== 0) {
      return
    }
    history.push('/home')
  }, [daysLeft, hoursLeft, history])

  const [videoOpen, setVideoOpen] = useState(false)
  const [videoMute, setVideoMute] = useState(true)
  const [errorForm, setErrorForm] = useState(false)
  const [submitForm, setSubmitForm] = useState(false)

  const toggleOpenVideo = useCallback(() => {
    (!videoOpen && setVideoMute(true))
    setVideoOpen(v => !v)
  }, [videoOpen])
  const toggleMuteVideo = useCallback(() => setVideoMute(v => !v), [])


  const onHandleForm = useCallback((e) => {
    e.preventDefault()
    const emailFormat=/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if(!email || !email.match(emailFormat)){
      setErrorForm(true);
      console.log('form faild');
      return
    }
    const baseUrl = process.env.REACT_APP_API_ENDPOINT_BASE || 'https://api.georgebrown.ca'
    fetch(`${baseUrl}/join-waiting-list`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    })
    .then(response => response.json())
    .then(setSubmitForm(true))
    .then(data => {
      console.log(data)
    });

  }, [email])

  const onInputChange = useCallback((e) => {
    const email = e.target.value
    setEmail(email)
  }, [])

  return (
    <>
    <VideoModal
      videoOpen={videoOpen}
      setVideoClose={toggleOpenVideo}
      videoMute={videoMute}
      setVideoMute={toggleMuteVideo}
    />
    <Container
      className='w-full h-full bg-blue relative'
      cursorUrl='/static/svg/2022/cursor-with-shadow.svg'
      onMouseMove={onMouseMove}
    >
      {/* <div className='absolute bottom-0 w-full'> */}
        {/* <img
          src={`/static/svg/teaser-bg${isMobile ? '' : '-xl'}.svg`}
          alt='bg-teaser'
          className='w-full'
        /> */}
      {/* </div> */}
      <StickerNavs />
      <StickerContainer
        imgWidth={isMobile ? 84 : 120}
        imgLeft={isMobile ? '105%' : '5%'}
        className='absolute'
        top={isMobile ? '65%' : '45%'}
        textRotate={10}
      >
        <img src='/static/svg/2022/yes-blue-circle.svg' alt='yes-blue-circle' />
      </StickerContainer>
      <StickerContainer
        imgWidth={isMobile ? 124 : 160}
        imgLeft={isMobile ? '16%' : '10%'}
        className='absolute'
        top={isMobile ? '75%' : '46%'}
        textRotate={10}
        onClick={()=>window.open("https://www.instagram.com/sod_yes/")}
        style={{zIndex:100, cursor:'pointer'}}
        id="instasvg"
      >
          <img src='/static/svg/2022/sod-circle-blue.svg' alt='yes-blue-circle' />
      </StickerContainer>
      <StickerContainer
        imgWidth={isMobile ? 124 : 160}
        imgLeft={isMobile ? '70%' : '80%'}
        className='absolute'
        top={isMobile ? '75%' : '62%'}
        textRotate={10}
        onClick={()=>window.open("https://www.tiktok.com/@designgbc")}
        style={{zIndex:100, cursor:'pointer'}}
        id="tiktoksvg"
      >
        <img src='/static/svg/2022/sticker-tiktok.svg' alt='yes-blue-circle' />
      </StickerContainer>
      <StickerContainer
        imgWidth={isMobile ? 124 : 160}
        imgLeft={isMobile ? '105%' : '5%'}
        className='absolute'
        top='62%'
        textRotate={10}

      >
        <img src='/static/svg/2022/group-12.svg' className='shadow-md' alt='yes-blue-circle' />
      </StickerContainer>
      <CenterContainer className='w-full absolute mx-auto'>
        <div className='text-center relative h-full xl:-mt-36'>
          <TextCenter>
            <span className='text-white' style={{letterSpacing: 4}}>{daysLeft}</span>
            <span className='text-light-grey-2022' style={{letterSpacing: 4}}>d</span>{' '}
            <span className='text-white' style={{letterSpacing: 4}}>{hoursLeft}</span>
            <span className='text-light-grey-2022' style={{letterSpacing: 4}}>h</span>
          </TextCenter>
          <Sticker4Days
            src='/static/svg/2022/4-days.svg'
            alt='4-days'
            className={cn('absolute mx-auto cursor-pointer w-32 md:w-64 max-w-sm')}
            id="fourDays"
          />
          <StickerMay
            src='/static/svg/2022/may3-7.svg'
            alt='5-days'
            className={cn('absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
          />
          <TextCenterTop className='absolute w-full z-10'>
            <h2 className='text-light-grey-2022 uppercase text-center'>George Brown College</h2>
            <h2 className='text-light-grey-2022 uppercase text-center'>School of design</h2>
            <h3 className='text-light-grey-2022 text-center'>
              Year End Show
              <a
                className='text-red-coral'
                href='https://www.instagram.com/sod_yes/'
                // target='_blank'
                rel='noreferrer'
              >
                {' '}
                @sod_yes
              </a>
            </h3>
          </TextCenterTop>
          <EmailSignUpCenter className='absolute w-full z-10' hasError={errorForm} submitted={submitForm}>
            <form onSubmit={onHandleForm}>
              <label className='text-light-grey-2022'>Sign up to our mailing list to receive updates</label>
              <input id="email" type="text" name="name" placeholder="Enter Your Email" value={email} onChange={onInputChange}/>
              <input id="submit" type="submit" value="Sign Up!" />
              <div className="inline-error" id="inline-error">Please enter a valid email address</div>
              <div id="submitted"><span className="text-red-coral">Success!</span> Check your email to confirm.</div>
            </form>
          </EmailSignUpCenter>
        </div>
      </CenterContainer>
      <LiveInContainer
        className={cn('absolute mx-auto flex justify-center items-start')}
        style={{
          transform: `translate3d(${(state.x / 20 - (rect?.x || 0) / 20) * randDirX}px, ${
            (state.y / 20 - (rect?.y || 0) / 20) * randDirY
          }px, 0)`,
        }}
      >
        <img src='/static/svg/2022/live-in.svg' alt='yes-blue-circle' ref={ref} onClick={toggleOpenVideo} style={{cursor:'pointer'}}/>
      </LiveInContainer>
      <link rel="stylesheet" href="/static/transition-css.css" type="text/css" />

      <div className="left-layer z-10"></div>
      <div className="right-layer z-10"></div>
    </Container>
    </>
  )
}

function StickerNavs() {
  return (
    <>
      <div className='w-full flex justify-between pl-10 relative'>
        <div className='relative z-10 inline-block'>
          <TextLogo
            className='text-red-coral uppercase'
            // className='text-red-coral uppercase cursor-pointer'
            // onClick={() => history.push('/')}
          >
            Yes!2022
          </TextLogo>
        </div>
        {/* <div>
          <img src='/static/svg/glass.svg' alt='glass' className={cn(' cursor-pointer')} />
        </div> */}
      </div>
    </>
  )
}
