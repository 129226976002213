import React from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { projectType } from '../../constant'
import { VscChevronDown } from 'react-icons/vsc'
import { VscSearch } from 'react-icons/vsc'

const ExploreP = styled.div`
  background-color: white;

  z-index: 100;
  height: 60px;

  border-radius: 30px;

  &:hover {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
  }
  .dropdown-content a:hover {
    background-color: #26439b;
    color: #f0eee9;
  }

  svg {
    top: 12px;
    color: #191919;
    right: 24px;
    transition: transform 330ms;
  }

  &:hover svg {
    transform: rotateZ(180deg);
  }

  p {
    text-align: left;
    padding-left: 30px;
    color: #191919;
    font-size: 1.3em;
    position: absolute;
    line-height: 60px;
    margin-bottom: 0;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
  }
  .dropdown-content {
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s,
      opacity 0.3s ease-in;
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    left: 0;
    margin-top: 60px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
  .dropdown-content a {
    display: block;
    color: #191919;
    //text-transform: uppercase;
    text-align: left;
    padding-left: 30px;
  }
`
const ExploreC = styled.div`
  background-color: white;

  z-index: 99;

  height: 60px;

  border-radius: 30px;

  svg {
    top: 12px;
    color: #191919;
    right: 24px;
    transition: transform 330ms;
  }

  &:hover svg {
    transform: rotateZ(180deg);
  }

  &:hover {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
  }
  .dropdown-content a:hover {
    background-color: #26439b;
    color: #f0eee9;
  }

  p {
    text-align: left;
    padding-left: 30px;
    color: #191919;
    font-size: 1.3em;
    position: absolute;
    line-height: 60px;
    margin-bottom: 0;
    width: 100%;
  }
  .dropdown-content {
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s,
      opacity 0.3s ease-in;
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    left: 0;
    margin-top: 60px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
  .dropdown-content a {
    display: block;
    color: #191919;
    //text-transform: uppercase;
    text-align: left;
    padding-left: 30px;
  }
`

const ExploreG = styled.div`
  background-color: white;
  z-index: 98;
  height: 60px;

  border-radius: 30px;

  svg {
    top: 16px;
    color: #191919;
    right: 26px;
    transition: transform 330ms;
  }

  input {
    color: #191919;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    padding: 5px 20px;
    margin-bottom: 20px;
    margin-top: 0px;
    // border: 3px solid #191919;
    display: block;
    font-size: 1.3em;

    &::placeholder {
      color: #191919;
      font-family: 'reckless_light';
      font-size: 1.1em;
      padding-left: 10px;
    }
    &::focus {
      border: 3px solid red;
    }
  }

  p {
    text-align: left;
    padding-left: 30px;
    color: #191919;
    font-size: 1.3em;
    position: absolute;
    line-height: 60px;
    margin-bottom: 0;
    width: 100%;
  }
`

export const DropdownFilter = ({ program, type, onChangeSearchGrads }) => {
  const { pathname } = useLocation()

  const onChange = useDebouncedCallback((value) => {
    onChangeSearchGrads(value)
  }, 800)

  return (
    <>
      <ExploreP className='relative z-10 w-full md:w-1/2 xl:w-1/3 max-w-xl'>
        <p className='' style={{ textAlign: 'left', paddingLeft: '30px' }}>
          {program || 'Program'}
        </p>
        <VscChevronDown className='absolute text-6xl' />
        <div className='dropdown-content'>
          <Link
            to={{
              pathname,
            }}
          >
            None
          </Link>
          <Link
            to={{
              pathname,
              search: '?program=Brand%20Design',
            }}
          >
            Brand Design
          </Link>
          <Link
            to={{
              pathname,
              search: '?program=Graphic%20Design',
            }}
          >
            Graphic Design
          </Link>
          <Link to={{ pathname, search: '?program=Interaction%20Design' }}>Interaction Design</Link>
          <Link to={{ pathname, search: '?program=Game%20-%20Art' }}>Game -Art</Link>
          <Link to={{ pathname, search: '?program=Digital%20Experience%20Design' }}>
            Digital Experience Design
          </Link>
          <Link
            to={{
              pathname,
              search: '?program=Art%20%26%20Design%20Foundation',
            }}
          >
            Art & Design Foundation
          </Link>
          <Link
            to={{
              pathname,
              search: '?program=Interactive%20Media%20Management',
            }}
          >
            Interactive Media Management
          </Link>
          <Link to={{ pathname, search: '?program=Web%20Development%20-%20Front-End%20Design' }}>
            Web Development - Front-End Design
          </Link>
          <Link
            to={{
              pathname,
              search: '?program=Digital%20Design%20-%20Game%20Design',
            }}
          >
            Digital Design - Game Design
          </Link>
          <Link
            to={{
              pathname,
              search: '?program=Concept%20Art%20for%20Entertainment',
            }}
          >
            Concept Art for Entertainment
          </Link>

          <Link to={{ pathname, search: '?program=Design%20Management' }}>Design Management</Link>
        </div>
      </ExploreP>
      <ExploreC className='relative w-full md:w-1/2 xl:w-1/3 max-w-xl'>
        <p className='relative'>{projectType[type] || 'Explore by Category'}</p>
        <VscChevronDown className='absolute text-6xl' />
        <div className='dropdown-content'>
          <Link to={{ pathname }}>None</Link>
          <Link to={{ pathname, search: '?type=3d_design' }}>3D Design</Link>
          <Link to={{ pathname, search: '?type=3d_sculpting' }}>3D Sculpting</Link>
          <Link to={{ pathname, search: '?type=3d_visualizations' }}>3D Visualizations</Link>
          <Link to={{ pathname, search: '?type=advertising' }}>Advertising</Link>
          <Link to={{ pathname, search: '?type=animation' }}>3D Animation</Link>
          <Link to={{ pathname, search: '?type=augmented_virtual_reality' }}>
            Augmented/Virtual Reality
          </Link>
          <Link to={{ pathname, search: '?type=branding' }}>Branding</Link>
          <Link to={{ pathname, search: '?type=digital_applications' }}>3D Sculpting</Link>
          <Link to={{ pathname, search: '?type=3d_sculpting' }}>Digital Applications</Link>
          <Link to={{ pathname, search: '?type=drawing' }}>Drawing</Link>
          <Link to={{ pathname, search: '?type=editorial' }}>Editorial</Link>
          <Link to={{ pathname, search: '?type=exhibition' }}>Exhibition</Link>
          <Link to={{ pathname, search: '?type=illustration' }}>Illustration</Link>
          <Link to={{ pathname, search: '?type=immersive_environments' }}>
            Immersive Environments
          </Link>
          <Link to={{ pathname, search: '?type=interactive_design' }}>Interactive Design</Link>
          <Link to={{ pathname, search: '?type=interface_development' }}>
            Interface Development
          </Link>
          <Link to={{ pathname, search: '?type=media_production' }}>Media Production</Link>
          <Link to={{ pathname, search: '?type=modeling' }}>Modeling</Link>
          <Link to={{ pathname, search: '?type=motion_graphics' }}>Motion Graphics</Link>
          <Link to={{ pathname, search: '?type=packaging' }}>Packaging</Link>
          <Link to={{ pathname, search: '?type=photography' }}>Photography</Link>
          <Link to={{ pathname, search: '?type=physical_computing' }}>Physical Computing</Link>
          <Link to={{ pathname, search: '?type=strategy' }}>Strategy</Link>
          <Link to={{ pathname, search: '?type=systems_and_services' }}>Systems and Services</Link>
          <Link to={{ pathname, search: '?type=technological_systems' }}>
            Technological Systems
          </Link>
          <Link to={{ pathname, search: '?type=user_experience' }}>User Experience</Link>
          <Link to={{ pathname, search: '?type=video' }}>Video</Link>
          <Link to={{ pathname, search: '?type=web_design' }}>Web Design</Link>
        </div>
      </ExploreC>
      <ExploreG className='relative w-full md:w-1/2 xl:w-1/3 max-w-xl'>
        <input
          type='text'
          id='gname'
          name='gname'
          placeholder='Grad'
          onChange={(e) => onChange(e.target.value)}
        />
        <VscSearch className='absolute text-5xl' />
      </ExploreG>
    </>
  )
}
