import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'
import {StickersWrapper,StickerContainer} from '../../styles/2023/HomepageStyles'
import {TextLogo} from "../../styles/2023/HomepageStyles"

export default function StickerNavsBlue({ className, withTop = true }) {
  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])

    const navigate = useCallback(
        (url) => {
            if (url !== '/' && pathname?.startsWith(url)) {
                return
            }

            const leftLayer = document.getElementsByClassName('left-layer')?.[0]
            const rightLayer = document.getElementsByClassName('left-layer')?.[0]
            if (leftLayer && rightLayer) {
                leftLayer.classList.add('active')
                rightLayer.classList.add('active')
                setTimeout(() => history.push(url), 1450)
            }
        },
        [history, pathname],
    )

  return (
    <div className={cn('', className)}>
      {withTop && (
          <div className='absolute top-0 w-full  h-full flex justify-between pl-10'>
            <div className='absolute z-10 inline-block cursor-pointer'>
                <TextLogo
                className='uppercase'
                onClick={() => navigate('/2023/')}
                style={{color: '#26439B'}}
                >
                Yes!2023
                </TextLogo>
            </div>
        </div>
      )}
      <StickersWrapper
          className={cn('absolute w-96 top-10 h-screen transition-opacity duration-700 ease-in-out hidden lg:block')}
          top='210px'
          imgLeft='0%'>
        <StickerContainer
            pointer
            className="relative w-max"
            textRotate={-35}
            onClick={() => navigate('/2023/work')}
        >
            <svg className="no-hover" width={88} height={88} viewBox="0 0 110 110" xmlns="http://www.w3.org/2000/svg">
                <circle
                    fill={'#26439B'}
                    cx={55}
                    cy={55}
                    r={55}
                    fillRule="evenodd"
                    style={{display: "unset"}}
                />
            </svg>
            <p
                className={cn('text-5xl no-hover')}
                style={{
                    top: 'calc(25% + 4px)',
                    left: 10,
                    color: '#C7C2E1'
                }}
            >
                Work
            </p>
            <svg className="yes-hover" width={88} height={88} viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'>
                <circle
                    fill={'#C7C2E1'}
                    cx={55}
                    cy={55}
                    r={55}
                    fillRule='evenodd'
                    style={{display: "unset"}}
                />
            </svg>
            <p
                className={cn('text-5xl yes-hover')}
                style={{
                    top: 'calc(25% + 4px)',
                    left: 10,
                    color: '#26439B'
                }}
            >
                Work
            </p>
        </StickerContainer>
        <StickerContainer
            pointer
            imgWidth={124}
            className="relative -mt-4 w-max"
            textRotate={-15}
            onClick={() => navigate('/2023/grads')}
        >
            <svg className="no-hover" width={128} height={87} viewBox="0 0 164 87" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M.203 71.712S60.29 13.564 113.301 1.427c51.212-11.726 71.525 64.233 21.237 79.456-26.56 8.04-68.41 8.844-134.335-9.171"
                    fill={'#26439B'}
                    fillRule="evenodd"
                />
            </svg>
            <p
                className={cn('text-5xl no-hover"')}
                style={{
                    top: 'calc(35% + 0px)',
                    left: 42,
                    color: '#C7C2E1'
                }}
            >
                Grads
            </p>
            <svg className="yes-hover" width={128} height={87} viewBox='0 0 164 87' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M.203 71.712S60.29 13.564 113.301 1.427c51.212-11.726 71.525 64.233 21.237 79.456-26.56 8.04-68.41 8.844-134.335-9.171'
                    fill={'#C7C2E1'}
                    fillRule='evenodd'
                />
            </svg>
            <p
                className={cn('text-5xl yes-hover')}
                style={{
                    top: 'calc(35% + 0px)',
                    left: 42,
                    color: '#26439B'
                }}
            >
                Grads
            </p>
        </StickerContainer>
        <StickerContainer pointer imgWidth={124} className="relative w-max" textRotate={10} onClick={() => navigate('/2023/events')}>
            <img className="no-hover" src="/static/svg/2023/Homepage/nav-event.svg" alt="events-nav"/>
            <p
                className="text-5xl text-blue no-hover"
                style={{
                    top: 'calc(45% - 12px)',
                    left: 24,
                    color: '#C7C2E1'
                }}
            >
                Events
            </p>
            <img className="yes-hover" src='/static/svg/2023/Grads/nav-event.svg' alt='events-nav' />
            <p
                className='text-5xl text-blue yes-hover'
                style={{
                    top: 'calc(45% - 12px)',
                    left: 24,
                    color: '#26439B'
                }}
            >
                Events
            </p>
        </StickerContainer>
        <StickerContainer pointer imgWidth={94} className="relative w-max" textRotate={10}
                          onClick={() => window.open("https://instoregbc.com/", "_blank")}>
            <img className="no-hover" src="/static/svg/2023/Homepage/nav-show.svg" alt="show-nav"/>
            <p
                className="text-5xl text-blue no-hover"
                style={{
                    top: 'calc(56% - 16px)',
                    left: 16,
                    color: '#C7C2E1'
                }}
            >
                Shop<span style={{fontSize: '20px'}}>&#8599;</span>
            </p>
            <img className="yes-hover" src='/static/svg/2023/Grads/nav-show.svg' alt='show-nav' />
            <p
                className='text-5xl text-blue yes-hover'
                style={{
                    top: 'calc(56% - 16px)',
                    left: 13,
                    color: '#26439B'
                }}
            >
                Shop<span style={{fontSize:'20px'}}>&#8599;</span>
            </p>
        </StickerContainer>
      </StickersWrapper>
    </div>
  )
}
