import CreateProject from '../../components/CMS/CreateProject'
import PleaseSignIn from '../../components/CMS/PleaseSignin'
import React from 'react'

const Add = () => {
  return (
    <div>
      <PleaseSignIn>
        <CreateProject />
      </PleaseSignIn>
    </div>
  )
}

export default Add
