import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import UserProject from './UserProject'
import { CURRENT_USER_QUERY } from './User'
import { gql } from '@apollo/client'
import { UserProjectsContext } from './states/userProjects'
import { client } from '../../index'
import Loading from './Loading'

/* eslint-disable-next-line */
const Center = styled.div`
  text-align: center;
  margin-top: 100px;
`

const ProjectsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 100px auto 0;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const AddProject = styled.div`
  position: relative;
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(102, 102, 102, 0.3);

  .circle {
    border-radius: 50%;
    width: 172px;
    height: 172px;
    padding: 16px;
    font-size: 66px;
    color: rgb(102, 102, 102, 0.3);
    background: #fff;
    border: 2px solid rgb(102, 102, 102, 0.3);
    text-align: center;
    font-family: 'reckless_light';
  }
`

const STUDENT_PROJECTS_QUERY = gql`
  query STUDENT_PROJECTS_QUERY($id: ID!) {
    studentProjectsConsole(where: { id: $id }) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        user {
          id
        }
      }
      published
    }
  }
`

const UserProjects = (props) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(undefined)
  // state reducer
  const { state, dispatch } = useContext(UserProjectsContext)

  useEffect(() => {
    client
      .query({ query: CURRENT_USER_QUERY, fetchPolicy: 'no-cache' })
      .then(({ data, loading }) => {
        console.log('data', data)
        let me
        if (data && data.me) {
          me = data.me
          console.log('me', me)
          client
            .query({
              query: STUDENT_PROJECTS_QUERY,
              variables: { id: me.id },
              fetchPolicy: 'no-cache',
            })
            .then(({ data, error }) => {
              if (error) {
                setError(error.message)
                return
              }
              console.log('data', data)
              const projects = data.studentProjectsConsole.filter((project) =>
                project.members.some((member) => member.user.id === me.id),
              )
              dispatch({ type: 'SET_PROJECTS', payload: projects })
              setLoading(false)
            })
            .catch()
        } else {
          // history.push('/login')
        }
      })
    /* eslint-disable-next-line */
  }, [dispatch, props.page])

  if (loading) return <Loading />
  if (error) return <p>{error}</p>

  return (
    <ProjectsList>
      {state.userProjects.map((project) => (
        <UserProject project={project} key={project.id} />
      ))}
      <Link to='/add'>
        <AddProject>
          <div className='circle'>+</div>
        </AddProject>
      </Link>
    </ProjectsList>
  )
}

export default UserProjects
