import React, { PureComponent } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { client } from "../..";
import { gql } from '@apollo/client'

const STUDENT_PROJECTS_QUERY = gql`
  query STUDENT_PROJECTS_QUERY($id: ID!) {
    studentProjectsConsole(where: { id: $id }) {
      id
      title
      orderList {
        userId
        order
      }
    }
  }
`
const UPDATE_PROJECTS_ORDERS = gql`
  mutation UPDATE_PROJECTS_ORDER($userId: ID!, $orders: [ProjectOrders]) {
    updateProjectsOrder(userId: $userId, orders: $orders) {
      title
      orderList {
        userId
        order
      }
    }
  }
`

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, {...removed, order: endIndex});
  result.splice(startIndex, 1, {...result[startIndex], order: startIndex})

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "#F7D8E0" : "#E1E1E1",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#FFFFFF" : "#FFFFFF",
  padding: grid,
  width: 250,
  margin: "0 auto",
});

class ReorderProjects extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount(){
    const { meId } = this.props
    console.log('meId: ', meId)
    client.query({
      query: STUDENT_PROJECTS_QUERY,
      variables: { id: meId },
      fetchPolicy: 'no-cache'
    }).then(({data}) => {
      const items = data.studentProjectsConsole.map((prj) => {
        const { id, title, orderList } = prj
        const meOrdersList = orderList.find(ord => ord.userId === meId)
        return { projectId: id, projectTitle: title, order: meOrdersList ? meOrdersList.order : 0}
      }).sort((a,b) => a.order - b.order)
      this.setState({items:items})
    })
  }

  async onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );
    items = items.map((itm , index) => ({...itm, order: index}))
    const variables = {
      userId: this.props.meId,
      orders: items.map(itm => ({ projectId: itm.projectId, order: itm.order }))
    }
    try {
      await client.mutate({
        mutation: UPDATE_PROJECTS_ORDERS,
        variables
      })
    } catch (error) {
      console.log('error: ',error)
    }

    this.setState({
      items
    });
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.items.map((item, index) => (
                <Draggable key={item.projectId} draggableId={item.projectId} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {item.projectTitle}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default ReorderProjects;
