import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { gql } from 'apollo-boost'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import StickerNavs from '../StickerNavs'
import WorkCard from '../../../components/WorkCard'
import shuffle from 'lodash.shuffle'
import { useLazyQuery } from '@apollo/client'
import { DropdownFilter } from '../DropdownFilter'
import { programGroups } from '../../../constant'
import useScroll from '../../../hook/useScroll'
import WorkScrollNav from './WorkScrollNav'
import cn from 'classnames'
import SearchCol from '../../../components/2021/SearchCol'
import Footer from '../../../components/2021/Footer'
import { useDisclosure } from 'react-use-disclosure'
import { MobileRightNavs, MobileNavsBottom } from '../MobileRightNavs'

const Container = styled.div`
  min-height: calc(100vh);
  cursor: url(${(props) => props.cursorUrl}), auto;
  flex-direction: column;
`

const InterActiveMedia = styled.div`
  .dot {
    height: 12px;
    width: 12px;
    background-color: #d5fd00;
    border-radius: 50%;
    margin: 8px 10px;
    display: inline-block;
  }
`

const CenterContainer = styled.div`
  @media (min-width: 1280px) {
    // top: 10%;
    // max-height: 40vh;
  }
  @media (min-width: 1920px) {
    // top: 20%;
  }
  width:-webkit-calc(100%- 24rem);
  width:-moz-calc(100% - 24rem);
  width:calc(100% - 24rem);

  @media (max-width: 1280px) {
    width:100%;
  }
`
const TextCenter = styled.h4`
  font-family: 'sharp_grotesque_05';
  font-size: 420px;
  line-height: 360px;
  letter-spacing: 0.01em;
  font-weight: 400;
  @media (min-width: 360px) {
    font-size: 460px;
    line-height: 400px;
  }
  @media (min-width: 768px) {
    font-size: 550px;
    line-height: 550px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 600px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 700px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 1000px;
    line-height: 1200px;
  }
  @media (min-width: 2880px) {
    font-size: 1400px;
    line-height: 1400px;
  }
`

const PUBLIC_PROJECTS_QUERY = gql`
  query PUBLIC_PROJECTS_QUERY($where: ProjectWhereInput) {
    projects(where: $where) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        user {
          id
          program
        }
      }
    }
  }
`

const Y_POS_SWAP_NAVS = 240

export default function Work(props) {
  const location = useLocation()
  const { search: searchParams } = location
  const { keyword, program, type } = qs.parse(searchParams)
  const [gradsSearch, setGradsSearch] = useState()

  // scroll
  const scroll = useScroll()

  const OR = useMemo(() => {
    const OR = []
    if (keyword) {
      OR.push({ title_contains: keyword })
      OR.push({ tags_contains: keyword })
    }
    return OR
  }, [keyword])

  const [fetch, { data, loading }] = useLazyQuery(PUBLIC_PROJECTS_QUERY, {
  })


  useEffect(() => {
    fetch({
      variables: {
        where: {
          published: true,
          createdAt_gt: '2021-12-31',
          OR: OR.length > 0 ? OR : undefined,
        },
        gradsSearch
      }
    })
  }, [gradsSearch, OR, fetch])

  const groupedProjects = useMemo(() => {
    if (!data?.projects) {
      return {}
    }
    let projects = data.projects
    if (program) {
      let duplicateProjects = []
      projects = projects.filter((project) => {
        if (
          project?.members?.some?.((member) => member?.user?.program === program) &&
          !duplicateProjects.includes(project.id)
        ) {
          duplicateProjects.push(project.id)
          return true
        }
        return false
      })
    }

    if (type) {
      projects = projects.filter(
        (project) =>
          project.primaryProjectType === type ||
          project.secondaryProjectType === type ||
          project.secondaryProjectType_2 === type,
      )
    }
    // group by program
    const groupedProjects = {}
    programGroups.forEach((key) => {
      const groupProjects = projects.filter((project) => {
        if (project?.members?.some?.((member) => member?.user?.program === key)) {
          return true
        }
        return false
      })
      groupedProjects[key] = shuffle(groupProjects)
    })

    return groupedProjects
  }, [data, program, type])

  const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()

  return (
    <>

      <Container
        className='w-full h-full bg-blue flex mt-28'
        cursorUrl='/static/svg/cursor-with-shadow.svg'
      >

        <StickerNavs
          className={cn(
            'w-96 fixed  top-0 h-screen transition-opacity duration-700 ease-in-out hidden lg:block',
            {
              'opacity-1 visible': scroll.scrollY < Y_POS_SWAP_NAVS,
              'opacity-0 invisible': scroll.scrollY >= Y_POS_SWAP_NAVS,
            },
          )}
        />
        <WorkScrollNav
          className={cn(
            'w-96 fixed h-screen transition-opacity duration-700 ease-in-out hidden lg:block',
            {
              'opacity-1 visible': scroll.scrollY >= Y_POS_SWAP_NAVS,
              'opacity-0 invisible': scroll.scrollY < Y_POS_SWAP_NAVS,
            },
          )}
        />

        <CenterContainer className='w-full mx-auto lg:ml-96 pb-12'>
          <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs} />
          <div
            className={cn( 'transition',{
              'flex visible opacity-100': !isOpenMenuNavs,
              'hidden invisible opacity-0': isOpenMenuNavs,
            })}
          >
            <div className='w-full  lg:w-3/4 xl:w-full '>
              <div className='text-center relative'>
                <TextCenter className='text-secondary' style={{ pointerEvents: "none"}}>WORK</TextCenter>
                <DropdownFilter program={program} type={type} onChangeSearchGrads={setGradsSearch} />
              </div>

              {loading && <div className='text-white'>Loading...</div>}
              <div id='wrapper'>
                {Object.keys(groupedProjects).map((key) => {
                  if (groupedProjects[key]?.length <= 0) {
                    return null
                  }

                  return (
                    <div key={key}>
                      <InterActiveMedia>
                        <div className='text-center lg:my-4'>
                          <span className='block lg:inline-block text-4xl lg:text-5xl text-white mb-2 lg:mb-0 lg:mr-4'>
                            Class of 2021
                          </span>
                          <span className='flex justify-center items-center lg:inline-block text-5xl text-secondary mt-4 lg:mt-0 mb-4 lg:mb-0'>
                            <span className='w-4 h-4 bg-secondary rounded-full inline-block mr-2 lg:mb-2'></span>
                            <span className='border-b border-dashed border-secondary pb-2 lg:pb-1'>
                              {key}
                            </span>
                          </span>
                        </div>
                      </InterActiveMedia>
                      <div className='bg-dark flex flex-wrap justify-between'>
                        {groupedProjects[key].map((project) => (
                          <WorkCard project={project} key={project.id} className='w-full lg:w-1/2 xl:w-1/3' />
                        ))}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className='w-12 bg-blue hidden lg:block'>
              {/* <img src='/static/svg/glass.svg' alt='glass' className={cn('cursor-pointer')} /> */}
            </div>
          </div>
          <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
        </CenterContainer>
        <SearchCol className='hidden lg:block' />
        <Footer />
      </Container>
    </>
  )
}
