import styled from 'styled-components'

const CustomSelect = styled.div`
  position: relative;
  font-family: 'grotesque_pro_light';
  width: 100%;
  select {
    width: 100%;
    padding: 8px;
  }
  select.decorated option:hover {
    box-shadow: 0 0 10px 100px #3a3a3a inset;
  }
  .custom-select select {
    display: none; /*hide original SELECT element:*/
  }
  .select-selected {
    background-color: white;
  }

  /*style the arrow inside the select element:*/
  .select-selected:after {
    position: absolute;
    content: '';
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #3a3a3a transparent transparent transparent;
  }

  /*point the arrow upwards when the select box is open (active):*/
  .select-selected.select-arrow-active:after {
    border-color: transparent transparent #3a3a3a transparent;
    top: 7px;
  }

  /*style the items (options), including the selected item:*/
  .select-items div,
  .select-selected {
    color: #3a3a3a;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(222, 222, 222, 0.1) transparent;
    cursor: pointer;
    user-select: none;
  }

  /*style items (options):*/
  .select-items {
    position: absolute;
    background-color: white;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
  }

  /*hide the items when the select box is closed:*/
  .select-hide {
    display: none;
  }

  .select-items div:hover,
  .same-as-selected {
    background-color: rgba(222, 222, 222, 0.1);
  }
`

export default CustomSelect
