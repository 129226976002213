export const projectType = {
  '3d_design': '3D Design',
  '3d_sculpting': '3D Sculpting',
  '3d_visualizations': '3D Visualizations',
  advertising: 'Advertising',
  animation: 'Animation',
  augmented_virtual_reality: 'Augmented/Virtual Reality',
  branding: 'Branding',
  device_development: 'Device Development',
  digital_applications: 'Digital Applications',
  drawing: 'Drawing',
  editorial: 'Editorial',
  exhibition: 'Exhibition',
  game_design: 'Game Design',
  illustration: 'Illustration',
  immersive_environments: 'Immersive Environments',
  interactive_design: 'Interactive Design',
  interface_development: 'Interface Development',
  media_production: 'Media Production',
  modeling: 'Modeling',
  motion_graphics: 'Motion Graphics',
  packaging: 'Packaging',
  photography: 'Photography',
  physical_computing: 'Physical Computing',
  strategy: 'Strategy',
  systems_and_services: 'Systems and Services',
  technological_systems: 'Technological Systems',
  typography: 'Typography',
  user_experience: 'User Experience',
  video: 'Video',
  web_design: 'Web Design',
}

export const projectTypeMap = {
  '3d_design': 'art_animation_3d',
  '3d_sculpting': 'art_animation_3d',
  '3d_visualizations': 'art_animation_3d',
  advertising: 'advertising',
  animation: 'art_animation_3d',
  augmented_virtual_reality: 'ar_vr',
  branding: 'branding',
  device_development: 'ar_vr',
  digital_applications: 'ui_ux',
  drawing: 'illustration',
  editorial: 'editorial',
  exhibition: 'interactive_design',
  game_design: 'game_design',
  illustration: 'illustration',
  immersive_environments: 'interactive_design',
  interactive_design: 'interactive_design',
  interface_development: 'interactive_design',
  media_production: 'motion_graphics',
  modeling: 'art_animation_3d',
  motion_graphics: 'motion_graphics',
  packaging: 'branding',
  photography: 'branding',
  physical_computing: 'interactive_design',
  strategy: 'design_strategy',
  systems_and_services: 'design_strategy',
  technological_systems: 'design_strategy',
  typography: 'editorial',
  user_experience: 'ui_ux',
  video: 'motion_graphics',
  web_design: 'ui_ux',
}

export const projectTypeMap24 = {
  ui_ux: ['web_design','user_experience','digital_applications','ui_ux'],
  art_animation_3d: ['3d_design','3d_sculpting','3d_visualizations','animation','modeling','art_animation_3d'],
  game_design: ['game_design','game_development'],
  illustration: ['illustration','drawing'],
  branding: ['branding','identity','logo_design','packaging','typography'],
  advertising: ['advertising','campaign','marketing'],
  ar_vr: ['augmented_reality','virtual_reality','augmented_virtual_reality','device_development'],
  editorial: ['editorial','publication','print_design'],
  motion_graphics: ['motion_graphics','video','animation','video_production','media_production'],
  interactive_design: ['interactive_design','immersive_environments','interface_development','physical_computing','exhibition'],
  design_strategy: ['strategy','design_research','design_management','design_thinking','design_strategy','systems_and_services','technological_systems'],
}


export const programGroups = [
  'Graphic Design',
  'Interaction Design',
  'Game - Art',
  'Art & Design Foundation',
  'Interactive Media Management',
  'Digital Design - Game Design',
  'Concept Art for Entertainment',
  'Design Management',
  'Interdisciplinary Design Strategy',
]

export const END_DATE_COUNTDOWN_2021 = '05/3/2021'

export const programGroups24 = [
  'Brand Design',
  'Graphic Design',
  'Interaction Design',
  'Game - Art',
  'Digital Experience Design',
  'Art & Design Foundation',
  'Interactive Media Management',
  'Interdisciplinary Design Strategy',
  'Digital Design - Game Design',
  'Concept Art for Entertainment',
  'Design Management',
  'Front-End Design',
]

export const programToImage = {
  // Visual Design
  'Art & Design Foundation': 'static/2024/img/ProfileGreen.png',
  'Graphic Design': 'static/2024/img/ProfileGreen.png',
  'Brand Design': 'static/2024/img/ProfileGreen.png',
  // Game Design
  'Digital Design - Game Design': 'static/2024/img/ProfileBlue.png',
  'Concept Art for Entertainment': 'static/2024/img/ProfileBlue.png',
  'Game - Art': 'static/2024/img/ProfileBlue.png',
  // Interactive Design
  'Interaction Design': 'static/2024/img/ProfileYellow.png',
  'Interactive Media Management': 'static/2024/img/ProfileYellow.png',
  'Digital Experience Design': 'static/2024/img/ProfileYellow.png',
  'Front-End Design': 'static/2024/img/ProfileYellow.png',
  // Strategic Design
  'Interdisciplinary Design Strategy': 'static/2024/img/ProfileOrange.png',
  'Design Management': 'static/2024/img/ProfileOrange.png',
}

export const programColour = {
  // Visual Design
  'Art & Design Foundation': 'text-comfort-green',
  'Graphic Design': 'text-comfort-green',
  'Brand Design': 'text-comfort-green',
  // Game Design
  'Digital Design - Game Design': 'text-empowering-blue',
  'Concept Art for Entertainment': 'text-empowering-blue',
  'Game - Art': 'text-empowering-blue',
  // Interactive Design
  'Interaction Design': 'text-friendly-yellow',
  'Interactive Media Management': 'text-friendly-yellow',
  'Digital Experience Design': 'text-friendly-yellow',
  'Front-End Design': 'text-friendly-yellow',
  // Strategic Design
  'Interdisciplinary Design Strategy': 'text-warm-orange',
  'Design Management': 'text-warm-orange',
}

// array of program groupd 24 html encoded
export const programGroups24Encoded = [
  'Graphic%20Design',
  'Interaction%20Design',
  'Game%20-%20Art',
  'Digital%20Experience%20Design',
  'Art%20&%20Design%20Foundation',
  'Interactive%20Media%20Management',
  'Interdisciplinary%20Design%20Strategy',
  'Digital%20Design%20-%20Game%20Design',
  'Concept%20Art%20for%20Entertainment',
  'Design%20Management',
  'Front-End%20Design',
]

// array of program groupd 24 html encoded
export const programs24 = {
  graphic_design: 'Graphic%20Design',
  interaction_design: 'Interaction%20Design',
  game_art: 'Game%20-%20Art',
  digital_experience_design: 'Digital%20Experience%20Design',
  art_design_foundation: 'Art%20&%20Design%20Foundation',
  interactie_media_management: 'Interactive%20Media%20Management',
  interdisciplinary_design_strategy: 'Interdisciplinary%20Design%20Strategy',
  digital_design: 'Digital%20Design%20-%20Game%20Design',
  concept_art: 'Concept%20Art%20for%20Entertainment',
  design_management: 'Design%20Management',
  front_end_design: 'Front-End%20Design',
}

export const disciplines = {
  art_animation_3d: '3D Art/Animation',
  game_design: 'Game Design',
  illustration: 'Illustration',
  branding: 'Branding',
  advertising: 'Advertising',
  ar_vr: 'AR/VR',
  ui_ux: 'UI/UX',
  editorial: 'Editorial',
  motion_graphics: 'Motion Graphics',
  interactive_design: 'Interactive Design',
  design_strategy: 'Design Strategy',
}


export const events24 = {
  dayOne: [
    {
      id: 'event1',
      date: 'May 1',
      name: 'Art & Design Workshop',
      time: '1 - 3 PM',
      content: `Join us for an immersive art workshop designed to awaken your inner artist and expand your creative horizons! This workshop offers a unique blend of instruction, experimentation, and hands-on creation. Through a series of guided exercises and interactive demonstrations. Whether you're a beginner looking to dip your toes into the world of art or a seasoned creator seeking fresh inspiration, this workshop welcomes all skill levels! No experience necessary. All supplies provided.`,
      link: 'https://www.eventbrite.ca/e/880960094357?aff=oddtdtcreator',
    },
    {
      id: 'event2',
      date: 'May 1',
      name: 'YES! Annual Award Show',
      time: '3:30 - 5:30 PM',
      content: `Join us as we celebrate the 2024 YES! award winners from across the School of Design! This spring, nearly 400 students will graduate from the School of Design. Each has beneﬁtted from the wisdom and support of faculty, peers, staff, industry partners and the much broader School of Design community. YES! is a testament to their hard work and collaboration! Join us as we give out more than 100 honours to graduates from across our twelve programs.`,
      link: '',
    },
    {
      id: 'event3',
      date: 'May 1',
      name: 'Public Exhibition',
      time: '6 - 9 PM',
      content: `As you step into George Brown College's School of Design, you’re embarking on an exciting journey through the creative expeditions of over 400 graduating students. From the aesthetic excellence of graphic design to the immersive experiences of virtual reality, each exhibit offers a glimpse into the future of design through the creative explorations of the industry's next thought-leaders and change-makers. Engage, explore, and connect as you traverse through YES!`,
      link: '',
    },
  ],
  dayTwo: [
    {
      id: 'event4',
      date: 'May 2',
      name: 'Portfolio/Resume Review',
      time: '12 - 4 PM',
      content: `Join us for a one-on-one session with an industry professional to review your portfolio. YES! will provide students an opportunity to present their work and receive professional industry feedback on their portfolio.
      The 30-minute session will allow students to talk about their design process, their skills, and their overall interests as they pertain to the world of design and beyond. Further, it will provide you with an opportunity to receive constructive criticism from a local designer, in order to continue honing your craft.`,
      link: 'https://www.eventbrite.ca/e/881354183087?aff=oddtdtcreator',
    },
    {
      id: 'event5',
      date: 'May 2',
      name: 'Experimental Typography Workshop',
      time: '1 - 3 PM',
      content: `Join us for an interactive Experimental Typography Workshop with Dominic Ayre.
      What is experimental typography? This workshop will not answer that question. Instead, it'll offer up space to get your hands dirty and produce playful typographic compositions. Be ready to cut, stick, tape, draw, learn, laugh, sketch and, of course, experiment within a group setting that encourages chaotic beauty and stimulates new-found connections. No experience necessary. All supplies provided.`,
      link: 'https://www.eventbrite.ca/e/880964407257?aff=oddtdtcreator',
    },
    {
      id: 'event6',
      date: 'May 2',
      name: 'Industry Reception',
      time: '4 - 6 PM',
      content: `Step into our industry Reception, where we welcome designers and professionals from various sectors of the industry. Join us for an exclusive opportunity to review the outstanding work of our students and engage in meaningful conversations. As you network with fellow creatives and industry leaders, discover the potential collaborations and connections that await in the dynamic world of design.`,
      link: '',
    },
    {
      id: 'event7',
      date: 'May 2',
      name: 'Public Exhibition',
      time: '6 - 9 PM',
      content: `As you step into George Brown College's School of Design, you’re embarking on an exciting journey through the creative expeditions of over 400 graduating students. From the aesthetic excellence of graphic design to the immersive experiences of virtual reality, each exhibit offers a glimpse into the future of design through the creative explorations of the industry's next thought-leaders and change-makers. Engage, explore, and connect as you traverse through YES!`,
      link: '',
    },
  ],
}


export const directions = [
  {
    id: 'biking',
    name: 'Biking',
    content: `The Martin Goodman Trail runs alongside the Waterfront Campus, providing a convenient biking option for visitors. Additionally, there's a Bike Share Toronto station conveniently located in front of the campus.`,
    link: 'https://www.toronto.ca/services-payments/streets-parking-transportation/cycling-in-toronto/',
    linkTitle: 'Cycling in Toronto',
  },
  {
    id: 'transit',
    name: 'Transit',
    content: `The main subway stops for the Waterfront Campus are Union Station on Line 1 (Yonge-University), as well as Castle Frank Station and Pape Station, which are on Line 2 (Bloor-Danforth). From Union Station, you may take the 19 Bay or 72B Pape bus to Dockside Drive. From Castle Frank Station, you may take the 65 Parliament bus to Dockside Drive. From Pape Station, you may take the 72B Pape bus to Queens Quay East and Lower Sherbourne.`,
    link: 'https://www.ttc.ca/',
    linkTitle: 'TTC Trip Planner',
  },
  {
    id: 'parkopedia',
    name: 'Parking',
    content: `51 DOCKSIDE DRIVE
    Parking is available in the lower levels of 51 Dockside Drive.
    3 LOWER JARVIS
    The Entrance to the Daniels City of the Arts parking garage is located on the East side of the building on Richardson St, Just north of Queens Quay.
    There is no direct access to the new School of Design from the parking garage. Visitors will have to take the elevator to the main condominium lobby, exit the building onto Queens Quay, and walk East up Lower Jarvis to the main Campus doors.`,
    link: 'https://en.parkopedia.ca/parking/51_dockside_street_toronto/?arriving=202404061530&leaving=202404061730',
    linkTitle: 'Parkopedia',
  },
]

export const gradComments = [
  {
    id: 'comment1',
    who: 'Patrick Ingham, Graphic Design',
    content: `Somehow in my 2 years on campus I have become some kind of cryptid. Classes that I have never taken reference me, profs I don't have ask about me, people in other programs know me. I’ve turned into the betelgeuse of design but instead of having powers, I get made by Kristine (I am not her student).`,
    image: '/static/2024/img/Patrick.png',
  },
  {
    id: 'comment2',
    who: 'Haniye Reyhani, Interaction Design',
    content: `We're the ORWELL team! Quirky challenges fuelled our creativity, and our "no quarrel" mantra kept spirits high during late-night design sprints. Together, we tackled tough projects, honing our skills in collaboration and communication. This journey at the School of Design didn’t just create designs; it forged unbreakable bonds. Thanks for the unforgettable memories and lessons that now shape our creative futures!`,
    image: '/static/2024/img/Haniye.png',
  },
  {
    id: 'comment3',
    who: 'Gabriel Barrueco Pires, Game Art',
    content: `My time at George Brown was good, I met a lot of people, learned a lot of different things and had a lot of fun, the 3 years I spent at the College were really good, I'm just sad that I have to leave now, all the experiences I have, I am taking with me.`,
    image: '/static/2024/img/Gabriel.png',
  },
  {
    id: 'comment4',
    who: 'Yeojin Yoo, Digital Experience Design',
    content: `Navigating life as an international student, being away from my family and going through the pandemic, it wasn’t definitely the easiest four years. However, I was able to find the community and support that I needed right here at school of design. I am incredibly grateful for my time at GBC and the friendships I've made along the way, and to all my friends: we did it! I'm so proud of each and every one of us :).`,
    image: '/static/2024/img/Yeojin.png',
  },
  {
    id: 'comment5',
    who: 'Lina Hawng, Digital Experience Design',
    content: `Wow, 4 years of my time at SoD went by so fast! I'm so glad to have met the precious friends I have today who give me tons of motivation and laughter. I will miss the school building, going to Tim Horton's during break time, and walking around Sugar Beach when the sun's out! One day when I pass by the campus I will think of all the memories I made here. Thanks, everyone!`,
    image: '/static/2024/img/Lina.png',
  }
]
