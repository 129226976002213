import styled from "styled-components";

const Title = styled.h3`
  line-height: 1;
  width: 70%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
  /* text-align: center; */
  /* transform: skew(-5deg) rotate(-1deg); */
  /* margin-top: -3rem; */
  /* text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1); */
  a {
    /* background: ${(props) => props.theme.pink}; */
    font-family: 'grotesque_pro_light';
    display: inline;
    line-height: 8px;
    font-size: 16px;
    text-align: center;
    color: black;
    font-weight: 100;
  }
  a:hover , a:focus{
    color: black;
  }
  &:hover, $focus {
    color: black;
  }
`

export default Title;
