import Signin from "../../components/CMS/Signin";
import styled from "styled-components";
import React from "react";
import { withRouter } from "react-router";

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  font-family: 'grotesque_pro_light';
`;

const Login = props => (
  <Columns>
    <Signin />
  </Columns>
);

export default withRouter(Login);
