import styled from "styled-components";

const ProfileProjectStyles = styled.div`
  background: white;
  /* border: 1px solid ${(props) => props.theme.offWhite};
  box-shadow: ${(props) => props.theme.bs}; */
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  font-family: 'grotesque_pro_light';
  h3 {
    width: 100%;
  }
  h3 a {
    line-height: 34px;
    text-align: left;
    word-wrap: break-word;
  }
  a {
    display: block;
    font-size: 40px;
    max-height: 41vh;
    align-items: center;
    text-align: center;
  }
  .coverImgContainer{
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding-top: 46%;
    position: relative;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  p {
    font-size: 20px;
    margin-right: 50px;
  }
  .buttonList {
    display: grid;
    width: 100%;
    /* border-top: 1px solid ${(props) => props.theme.lightgrey}; */
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 1px;
    background: ${(props) => props.theme.lightgrey};
    & > * {
      background: white;
      border: 0;
      font-size: 1rem;
      padding: 1rem;
    }
  }
`;

export default ProfileProjectStyles;
