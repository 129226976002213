import { Helmet } from "react-helmet-async";
import React from "react";

const Meta = () => (
  <Helmet>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charSet="utf-8" />
    <link rel="shortcut icon" href="/static/favicon.svg" />
    <link rel="stylesheet" type="text/css" href="/static/nprogress.css" />
    <title>YES!</title>
  </Helmet>
);

export default Meta;
