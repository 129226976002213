import styled, { keyframes } from "styled-components";

const loading = keyframes`
  from {
    background-position: 0 0;
    /* rotate: 0; */
  }

  to {
    background-position: 100% 100%;
    /* rotate: 360deg; */
  }
`;

const ProfileForm = styled.form`
  padding: 20px;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: normal;
  margin: 50px 5vw 0;
  font-family: 'grotesque_pro_light';
  color: #B5B5B5;
  font-size: 24px;

  label {
    color: #b5b5b5;
    display: block;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  input,
  textarea,
  select {
    height: 50px;
    width: 100%;
    padding: 0.5rem 1.5rem;
    font-size: 24px;
    font-weight: normal;
    color: #000000;
    border: 1px solid #B5B5B5;
    
    &::placeholder {
      color: #b5b5b5;
      opacity: 1;
    }

    &:focus {
      outline: 0;
      border-color: ${props => props.theme.pink};
    }
  }

  textarea {
    height: 200px;
  }

  button,
  input[type="submit"] {
    width: 100%;
    background: ${props => props.theme.pink};
    color: ${props => props.theme.black};
    border: 0;
    font-family: 'grotesque_pro_light';
    font-size: 2rem;
    font-weight: 600;
    padding: 0.5rem 2.2rem;
    margin-top: 5rem;
    margin-bottom: 3rem;
    border-radius: 3rem;

    @media (min-width: 768px) {
      font-size: 2.4rem;
      font-weight: 300;
      min-width: 180px;
      padding: 0.75rem 1.5rem;
      text-transform: uppercase;
    }
  }

  fieldset {
    border: 0;
    padding: 0;

    /* &[disabled] {
      opacity: 0.5;
    }
    &::before {
      height: 10px;
      content: "";
      display: block;
      background-image: linear-gradient(
        to right,
        #f7d8e0 0%,
        #000000 50%,
        #f7d8e0 100%
      );
    }
    &[aria-busy="true"]::before {
      background-size: 50% auto;
      animation: ${loading} 0.5s linear infinite;
    } */
  }
`;

export default ProfileForm;
