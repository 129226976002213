import React, { useReducer, useContext } from "react";

export const initialState = {
  keyword: '',
  prevPath: ''
};

export function reducer(state, { type, payload }) {
  switch (type) {
    case "SET_KEYWORD": {
      if(payload === state.keyword){
        return state
      }
      return { ...state, keyword: payload };
    }
    case "SET_PREV_PATH": {
      if(payload === state.prevPath){
        return state
      }
      return { ...state, prevPath: payload}
    }
    default: {
      return state;
    }
  }
}

const NavContext = React.createContext(initialState);

function NavProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <NavContext.Provider value={{ state, dispatch }}>
      {children}
    </NavContext.Provider>
  );
}

const withNavContext = Component => props => (
  <NavProvider>
    <Component {...props} />
  </NavProvider>
);

const useNavContext = () => useContext(NavContext)

export { NavContext, NavProvider, withNavContext, useNavContext };
