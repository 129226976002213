import styled from 'styled-components'

export const EmailSignUpCenter = styled.div`
  position: relative;
  form {
    display: flex;
    flex-direction: column;
    justify-content: start;
    font-family: 'grotesque_pro_light';
    font-size: 2rem;
  }
  #email {
    font-family: 'grotesque_pro_light';
    width: calc(100vw - 40px);
    height: 60px;
    border-radius: 30px;
    padding: 5px 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 3px solid ${(props) => (props.hasError ? 'red' : '#71DF71')};
    display: ${(props) => (props.submitted ? 'none' : 'block')};
  }

  #email:hover {
    border: 3px solid #ff8a48;
  }
  #email:focus {
    outline: none;
    border: 3px solid #ff8a48;
  }
  #submit {
    width: calc(100vw - 40px);
    height: 60px;
    border-radius: 30px;
    font-family: 'grotesque_pro_light';
    font-weight: 100;
    cursor: pointer;
    display: ${(props) => (props.submitted ? 'none' : 'block')};
  }
  #submit:hover {
    border: 3px solid #fa8975;
  }
  #inline-error {
    color: white;
    margin-top: 10px;
    display: ${(props) => (props.hasError ? 'block' : 'none')};
  }
  #submitted {
    color: white;
    margin-top: 10px;
    display: ${(props) => (props.submitted ? 'block' : 'none')};
  }

  @media (min-width: 640px) {
    #email {
      width: 480px;
    }
    #submit {
      width: 480px;
    }
  }
`

export const MainContainer = styled.div`
    font-family: 'grotesque_pro_light';
`

export const LearnMore = styled.a`
  font-family: 'reckless_light';
`

export const ProgramCard = styled.div`

  padding-top: 10px;

  h3 {
    font-family: 'grotesque_pro_light';
    font-size: 24px;
    font-weight: 600;
    line-height: 40.8px;
    letter-spacing: -0.48px;
  }

  li {
    display: flex;
    padding: 4px;
    justify-content: start;
    align-items: center;
    gap: 8px;
  }
  p {
    font-family: 'reckless_light';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`