import React, { useState, useCallback } from 'react'
import { gql } from '@apollo/client'
import { Link } from 'react-router-dom'
import LoginForm from '../../../components/2020/styles/LoginForm'
import styled from 'styled-components'
import { client } from '../../../index'
import cogoToast from 'cogo-toast'

const Signup = styled.p`
  font-size: 16px;
  font-weight: 300;
  display: inline;
  border-bottom: 1px solid;
`
const REQUEST_FORGOT_PASSWD = gql`
  mutation REQUEST_FORGOT_PASSWD($email: String!) {
    requestForgotPassword(email: $email)
  }
`

const ReqForgotPasswd = () => {
  const [email, setEmail] = useState('')

  const handleChangeEmail = useCallback((e) => {
    const email = e.target.value
    setEmail(email)
  }, [])

  const handleForm = useCallback(
    (e) => {
      e && e.preventDefault()
      if (email) {
        client
          .mutate({ mutation: REQUEST_FORGOT_PASSWD, variables: { email } })
          .then(({ data }) => {
            if (data.requestForgotPassword) {
              setEmail('')
              cogoToast.success('Submit forgot password successfully')
            }
          })
      }
    },
    [email],
  )

  return (
    <LoginForm method='post' onSubmit={handleForm}>
      <fieldset>
        <label htmlFor='email'>
          <input
            id='email'
            type='email'
            name='email'
            placeholder='Email'
            value={email}
            onChange={handleChangeEmail}
          />
        </label>
        <button type='submit'>Confirm </button>
      </fieldset>
      <p style={{ fontSize: '16px', fontWeight: '300' }}>Already have an acoount?</p>
      <Link to='/signup' style={{ textDecoration: 'none' }}>
        <Signup>Login here</Signup>
      </Link>
    </LoginForm>
  )
}

export default ReqForgotPasswd
