import React from 'react'
import styled from 'styled-components'
import cn from 'classnames'

const Text = styled.p`
  font-family: 'grotesque_pro_light';
`
const LinkContainer = styled.div`
  a:last-child {
    border-bottom: 1px dashed ${(props) => (props.borderBlack ? '#ff6044' : '#ff6044')};
  }
`
const UserAvatar = styled.img``

const Profile = ({ user, className, borderBlack = true }) => {
  // console.log('🚀 ~ Profile ~ user', user)

  return (
    <>
      <div className={cn('px-4', className)} >
        {user?.image && (
          <UserAvatar
            src={user.image}
            alt='user-avatar'
            className='w-56 h-56 rounded-full mx-auto object-cover'
            style={{ filter: 'grayscale(100%)' }}
          />
        )}
        <div className='text-center mt-4'>
          <p className='text-charcoal inline-block'>
            {user?.firstName} {user?.lastName}
          </p>
        </div>
        <Text className='text-charcoal mt-4 text-2xl font-thin'>YES! {user?.tagline}</Text>
        <Text className='text-charcoal mt-4 text-2xl font-thin'>{user?.primaryExpertise}</Text>
        <Text className='text-charcoal text-2xl font-thin mt-2'>{user?.secondaryExpertise}</Text>
        <Text className='text-charcoal text-2xl font-thin mt-6'>{user?.about}</Text>
      </div>
      <div className='h-8' />
      <LinkContainerComp user={user} borderBlack={borderBlack} />
    </>
  )
}

export default Profile

export const ProfileSmall = ({ user }) => {

  return (
    <>
      <Text className='text-charcoal py-3 text-2xl font-thin'>YES! {user?.tagline}</Text>
      <Text className='text-charcoal py-3 text-2xl font-thin'>{user?.primaryExpertise}</Text>
      <Text className='text-charcoal text-2xl font-thin py-3'>{user?.secondaryExpertise}</Text>
      <Text className='text-charcoal text-2xl font-thin py-4'>{user?.about}</Text>
      <LinkContainerComp user={user} borderBlack={false} />
    </>
  )
}

const LinkContainerComp = ({ user, borderBlack }) => {
  return (
    <LinkContainer borderBlack={borderBlack}>
      {user.portfolio && (
        <a
          href={user.portfolio}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dashed p-4 hover:bg-red-coral hover:text-charcoal border-red-coral',
          )}
        >
          Portfolio
        </a>
      )}
      <a
        href={`mailto:${user.contactEmail}`}
        target='_blank'
        rel='noopener noreferrer'
        className={cn(
          'block text-charcoal transition border-t border-dashed p-4 hover:bg-red-coral hover:text-charcoal border-red-coral'
        )}
      >
        Email
      </a>
      {user.linkedIn && (
        <a
          href={user.linkedIn}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dashed p-4 hover:bg-red-coral hover:text-charcoal border-red-coral'
          )}
        >
          LinkedIn
        </a>
      )}
      {user.behance && (
        <a
          href={user.behance}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dashed p-4 hover:bg-red-coral hover:text-charcoal border-red-coral'
          )}
        >
          Behance
        </a>
      )}
      {user.instagram && (
        <a
          href={user.instagram}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dashed p-4 hover:bg-red-coral hover:text-charcoal border-red-coral'
          )}
        >
          Instagram
        </a>
      )}
      {user.twitter && (
        <a
          href={user.twitter}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dashed p-4 hover:bg-red-coral hover:text-charcoal border-red-coral'
          )}
        >
          Twitter
        </a>
      )}
      {user.facebook && (
        <a
          href={user.facebook}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dashed p-4 hover:bg-red-coral hover:text-charcoal border-red-coral'
          )}
        >
          Facebook
        </a>
      )}
      {user.dribble && (
        <a
          href={user.dribble}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dashed p-4 hover:bg-red-coral hover:text-charcoal border-red-coral'
          )}
        >
          Dribbble
        </a>
      )}
      {user.youtube && (
        <a
          href={user.youtube}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dashed p-4 hover:bg-red-coral hover:text-charcoal border-red-coral'
          )}
        >
          YouTube
        </a>
      )}
      {user.vimeo && (
        <a
          href={user.vimeo}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dashed p-4 hover:bg-red-coral hover:text-charcoal border-red-coral'
          )}
        >
          Vimeo
        </a>
      )}
      {user.medium && (
        <a
          href={user.medium}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dashed p-4 hover:bg-red-coral hover:text-charcoal border-red-coral'
          )}
        >
          Medium
        </a>
      )}
    </LinkContainer>
  )
}
