import React from 'react'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { withRouter, useHistory } from 'react-router-dom'

const SIGN_OUT_MUTATION = gql`
  mutation SIGN_OUT_MUTATION {
    signout {
      message
    }
  }
`
// , props.history.push({ pathname: "/login" })
const Signout = (props) => {
  const history = useHistory()

  return (
    <Mutation mutation={SIGN_OUT_MUTATION}>
      {(signout) => (
        <button
          onClick={async () => {
            await signout()
            history.push('/2020/login')
            window.location.reload()
          }}
        >
          Sign Out
        </button>
      )}
    </Mutation>
  )
}

export default withRouter(Signout)
