import { gql } from '@apollo/client'

export const SEARCH_QUERY = gql`
  query SearchQuery($q: String!, $year: Int) {
    searchGradWork(q: $q, year: $year) {
      users {
        id
        firstName
        lastName
        image
        email
        tagline
        program
        primaryExpertise
        secondaryExpertise
        studentYear
        permissions
      }
      projects {
        id
        title
        primaryProjectType
        secondaryProjectType
        secondaryProjectType_2
        contentType
        contentData
        coverImage
        createdAt
        members {
          role
          user {
            id
            firstName
            lastName
            image
            tagline
            program
            contactEmail
            primaryExpertise
            secondaryExpertise
            about
            image
            portfolio
            behance
            linkedIn
            instagram
            twitter
            facebook
            dribble
            youtube
            vimeo
            medium
          }
        }
      }
    }
  }
`
