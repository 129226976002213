import * as React from 'react'
import { ProgramCard } from '../../styles/2024/HomeStyles'
import { H2, A } from '../../styles/2024/TextStyles'
import { Link } from 'react-router-dom'

export default function Programs() {
  return (
    <>
      <H2 className='uppercase text-almost-black'>PROGRAMS</H2>
      <div className='flex flex-col lg:flex-row gap-10 w-full'>
        <div className='flex flex-col justify-start'>
          <img
            loading='lazy'
            src='/static/2024/gif/VisualDesign.gif'
            className='w-full'
            alt=''
          />
          <ProgramCard>
            <h3 className='text-almost-black'>Visual Design</h3>
            <ul>
              <li>
                <Link
                  to={{
                    pathname: '/work',
                    search: `program=Art%20&%20Design%20Foundation`,
                  }}
                >
                  <A className='border-b border-dotted border-almost-black text-almost-black hover:text-empowering-blue'>
                    • Art & Design Foundation
                  </A>
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/work',
                    search: `program=Graphic%20Design`,
                  }}
                >
                  <A className='border-b border-dotted border-almost-black text-almost-black hover:text-empowering-blue'>
                    • Graphic Design
                  </A>
                </Link>
              </li>
            </ul>
          </ProgramCard>
        </div>
        <div className='flex flex-col justify-start'>
          <img
            loading='lazy'
            src='/static/2024/gif/GameDesign.gif'
            className='w-full'
            alt=''
          />
          <ProgramCard>
            <h3 className='text-almost-black'>Game Design</h3>
            <ul>
              <li>
                <Link
                  to={{
                    pathname: '/work',
                    search: `program=Concept%20Art%20for%20Entertainment`,
                  }}
                >

                <A className='border-b border-dotted border-almost-black text-almost-black hover:text-empowering-blue'>
                  • Concept Art for Entertainment
                </A>
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/work',
                    search: `program=Game%20-%20Art`,
                  }}
                >
                <A className='border-b border-dotted border-almost-black text-almost-black hover:text-empowering-blue'>
                  • Game Art
                </A>
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/work',
                    search: `program=Digital%20Design%20-%20Game%20Design`,
                  }}
                >
                <A className='border-b border-dotted border-almost-black text-almost-black hover:text-empowering-blue'>
                  • Game Design
                </A>
                </Link>
              </li>
            </ul>
          </ProgramCard>
        </div>
        <div className='flex flex-col justify-start'>
          <img
            loading='lazy'
            src='/static/2024/gif/InteractiveDesign.gif'
            className='w-full'
            alt=''
          />

          <ProgramCard>
            <h3 className='text-almost-black'>Interactive Design</h3>
            <ul>
              <li>
                <Link
                  to={{
                    pathname: '/work',
                    search: `program=Interaction%20Design`,
                  }}
                >
                <A className='border-b border-dotted border-almost-black text-almost-black hover:text-empowering-blue'>
                  • Interaction Design
                </A>
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/work',
                    search: `program=Digital%20Experience%20Design`,
                  }}
                >
                <A className='border-b border-dotted border-almost-black text-almost-black hover:text-empowering-blue'>
                  • Digital Experience Design
                </A>
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/work',
                    search: `program=Interactive%20Media%20Management`,
                  }}
                >
                <A className='border-b border-dotted border-almost-black text-almost-black hover:text-empowering-blue'>
                  • Interactive Media Management
                </A>
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/work',
                    search: `program=Front-End%20Design`,
                  }}
                >
                <A className='border-b border-dotted border-almost-black text-almost-black hover:text-empowering-blue'>
                  • Front End Design
                </A>
                </Link>
              </li>
            </ul>
          </ProgramCard>
        </div>
        <div className='flex flex-col justify-start'>
          <img
            loading='lazy'
            src='/static/2024/gif/StrategicDesign.gif'
            className='w-full'
            alt=''
          />

          <ProgramCard>
            <h3 className='text-almost-black'>Strategic Design</h3>
            <ul>
              <li>
                <Link
                  to={{
                    pathname: '/work',
                    search: `program=Interdisciplinary%20Design%20Strategy`,
                  }}
                >
                <A className='border-b border-dotted border-almost-black text-almost-black hover:text-empowering-blue'>
                  • Interdisciplinary Design Strategy
                </A>
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/work',
                    search: `program=Design%20Management`,
                  }}
                >
                <A className='border-b border-dotted border-almost-black text-almost-black hover:text-empowering-blue'>
                  • Design Management
                </A>
                </Link>
              </li>
            </ul>
          </ProgramCard>
        </div>
      </div>
    </>
  )
}
