import React, {useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {useVisible} from 'react-hooks-visible'
import styled from 'styled-components'
import cn from 'classnames'
import {projectType} from '../../constant'


const WorkItemStyles = styled.div`
  color: white;
  font-size: 25px;
  display: inline-block;
  min-height: 420px;
  // display: flex;
  vertical-align: top;
  @media (min-height: 1800px) {
    min-height: 20vh;
  }

  &:hover {
    background-color: #26439B;

  }
`

const Title = styled.div`
  color: #191919;

  a {
    color: #191919;
    font-family: 'reckless_light';
    font-size: 30px;
    line-height: 30px;
  }

  a:hover {
    color: #F0EEE9;
  }
`

const WorkType = styled.div`
  a {
    font-size: 18px;
    font-family: 'reckless_light';
    font-weight: normal;
  }

  .dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 2px;
    display: inline-block;
  }
`

const Project = (props) => {
    const {project, className = ''} = props || {}
    const primaryProjectType = useMemo(() => project.primaryProjectType, [project])

    const [isVisited, setIsVisited] = useState(false)
    const [targetRef, visible] = useVisible()
    const [hovered, setHovered] = useState(false)

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    useEffect(() => {
        if (isVisited) return
        if (visible === 0) return

        setIsVisited(true)
    }, [isVisited, visible, targetRef])

    return (
        <WorkItemStyles ref={targetRef} className={cn('p-12 hover:bg-mad-blue transition', className)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
            <Link
                to={{
                    pathname: '/project',
                    search: `id=${project.id}`,
                }}
            >
                {project.coverImage && isVisited && (
                    <img
                        src={project.coverImage}
                        alt={project.title}
                        style={{width: '100%', height: 240, top: 0, objectFit: 'contain'}}
                    />
                    // <img src={project.coverImage} alt={project.title} style={{width: '100%' , height:'100%'}}/>
                )}
            </Link>
            <WorkType>
                <Link
                    to={{
                        pathname: '/work',
                        search: `?type=${primaryProjectType}`,
                    }}
                    style={{ color: hovered ? '#F0EEE9' : '#26439B' }}
                >
                    <p className="pt-4 border-b border-dotted " style={{width: 'fit-content', borderColor:  hovered ? '#F0EEE9' : '#26439B'}}>
                        <span className="dot" style={{ backgroundColor: hovered ? '#F0EEE9' : '#26439B' }}></span>
                        {projectType[primaryProjectType]}
                    </p>
                </Link>
            </WorkType>
            <Title className="leading-8 pt-2">
                <Link
                    to={{
                        pathname: '/project',
                        search: `id=${project.id}`,
                    }}
                    style={{ color: hovered ? '#F0EEE9' : '#26439B' }}
                >
                    {project.title}
                </Link>
            </Title>
        </WorkItemStyles>
    )
}

export default Project
