import * as userQueries from './query/user'
import * as searchQueries from './query/search'
import * as letterQueries from './query/letter'
import * as projectQueries from './query/project'
export const QUERY = {
  ...userQueries,
  ...searchQueries,
  ...letterQueries,
  ...projectQueries,
}
