import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'query-string'
import { client } from '../../../'
import { gql } from 'apollo-boost'
import Loading from '../../../components/2020/Loading'

const VERIFY_ACCOUNT = gql`
  mutation VERIFY_ACCOUNT($token: String!) {
    verifyUser(token: $token) {
      id
      email
      firstName
      lastName
    }
  }
`

const VerifyAccount = () => {
  const history = useHistory()
  const location = useLocation()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  /* eslint-disable-next-line */
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const search = qs.parse(location.search)
    if (search && search.token) {
      client
        .mutate({ mutation: VERIFY_ACCOUNT, variables: { token: search.token } })
        .then(({ data, errors }) => {
          if (errors && errors.length > 0) {
            setError(true)
          }
          if (data && data.verifyUser) {
            setSuccess(true)
            setTimeout(() => {
              history.push('/2020/edit')
            }, 2500)
          } else {
            setError(true)
          }
          setLoading(false)
        })
    } else {
      setError(true)
      setLoading(false)
    }
    /* eslint-disable-next-line */
  }, [])

  if (loading) return <Loading />
  if (error) return <div>The page you're looking for is not available</div>

  return (
    <div>
      Verify user successfully! <br /> You will be redirected in few seconds
    </div>
  )
}

export default VerifyAccount
