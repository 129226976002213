import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useHistory, useLocation } from 'react-router'
import qs from 'query-string'
import { Helmet } from 'react-helmet-async'
import ReactMarkDown from 'react-markdown'
import { Link } from 'react-router-dom'
import { H2, H3, P, A } from '../../styles/2024/TextStyles'
import { projectType, programToImage } from '../../constant'


const Image = styled.img`
  width: 100%;
`

const Button = styled.button`
  width: 100%;
  background: #26439B;
  color: #F0EEE9;
  border: 0;
  font-family: 'grotesque_pro_light';
  font-size: 2rem;
  font-weight: 600;
  padding: 0.5rem 2.2rem;
  margin-top: 5rem;
  margin-bottom: 3rem;
  border-radius: 3rem;
  width: 240px;

  @media (min-width: 768px) {
    font-size: 2.4rem;
    font-weight: 300;
    min-width: 180px;
    padding: 0.75rem 1.5rem;
    text-transform: uppercase;
  }
`



export default function PreviewProject(props) {
  const history = useHistory()
  const { search } = useLocation()
  const { id } = qs.parse(search)


  const { data } = useQuery(SINGLE_PROJECT_QUERY, {
    variables: {
      id,
    },
  })
  const project = useMemo(() => data?.project || {}, [data])


  const [publishProject] = useMutation(PUBLISH_PROJECT_MUTATION, {
    variables: {
      id,
    },
  })


  const handlePublish = useCallback(async () => {
    try {
      await publishProject()
      // change them to the single item page
      history.push({
        pathname: '/project',
        search: `?id=${id}`,
      })
    } catch (error) {
      console.log('error: ', error)
    }
  }, [publishProject, id, history])

  return (
    <div className='relative min-w-full min-h-screen bg-almost-black'>
      {project.title && (
        <Helmet>
          <title>YES! | {project.title}</title>
        </Helmet>
      )}

      {project?.coverImage && (
        <div
          style={{
            background: 'url(' + encodeURI(project?.coverImage) + ')',
            width: '100%',
            height: '380px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>
      )}
      <div className='px-[16px] lg:px-[48px] pt-32'>
        <div className='w-full mx-auto bg-almost-black text-beige-yellow grid grid-cols-1 gap-16 pb-32 lg:grid-cols-2'>
          <div className='flex flex-col items-start justify-start'>
            <H2 className='text-beige-yellow'>{project?.title}</H2>
            {project.members?.map((member) => (
              <div key={member?.user?.id} className='pt-2'>
                <div className='flex flex-col items-start justify-start'>
                  <div className='flex flex-wrap justify-start items-center gap-4 py-6'>
                    <P className='text-comfort-green inline-block p-0 m-0'>
                      ● {member?.user?.program}
                    </P>
                    {member?.user?.primaryExpertise && (
                      <P className='text-beige-yellow inline-block p-0 m-0'>
                        ● {projectType[member?.user?.primaryExpertise]}
                      </P>
                    )}
                    {member?.user?.secondaryExpertise && (
                      <P className='text-beige-yellow inline-blockl p-0 m-0'>
                        ● {projectType[member?.user?.secondaryExpertise]}
                      </P>
                    )}
                  </div>
                  <Link
                    to={{
                      pathname: '/student',
                      search: `id=${member?.user.id}`,
                    }}
                    className='inline-block flex items-center'
                    style={{ color: '#191919' }}
                  >
                    <img
                      src={member?.user?.image || programToImage[member?.user?.program]}
                      alt='member'
                      className='w-20 h-20 rounded-full mx-auto object-cover'
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <A className='border-b border-dotted border-beige-yellow text-beige-yellow mt-2 ml-4 text-4xl hover:text-comfort-green'>
                      {member?.user?.firstName} {member?.user?.lastName}
                    </A>
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <P>{project?.contentData?.length > 0 && project?.contentData[0]}</P>
        </div>
        <div className='text-beige-yellow'>
          <div className='mt-16'>
            {project?.contentType?.map((contentType, i) => {
              if (i === 0) {
                return null
              } else if (contentType === 'Heading') {
                return (
                  <H3 key={i} className='pb-16 capitalize'>
                    {project.contentData[i]}
                  </H3>
                )
              } else if (contentType === 'Text') {
                return (
                  <P className='w-full pb-8 first-letter:uppercase' key={`contenttype-${i}`}>
                    <ReactMarkDown key={i} children={project.contentData[i]} />
                  </P>
                )
              } else if (contentType === 'Image') {
                return (
                  <div className='w-full' key={i}>
                    <Image src={project.contentData[i]} alt='Image Preview' className='pb-16' />
                  </div>
                )
              } else if (contentType === 'Video') {
                return (
                  <div key={i} className='w-full lg:w-4/6 mx-auto pb-4 lg:pb-12 lg:px-0'>
                    <video controls className='w-full mx-auto'>
                      <source type='video/mp4' src={project.contentData[i]} />
                    </video>
                  </div>
                )
              }
              return null
            })}
          </div>
        </div>
        <div className='flex justify-end mt-6'>
              <div className='w-1/2 flex justify-between'>
                <Link
                  to={{
                    pathname: '/update',
                    search: `id=${project.id}`,
                  }}
                >
                  <Button className='mx-4'>Edit</Button>
                </Link>
                <Button className='mx-4' onClick={handlePublish}>
                  Publish
                </Button>
              </div>
            </div>

      </div>
    </div>
  )
}

const SINGLE_PROJECT_QUERY = gql`
  query SINGLE_PROJECT_QUERY($id: ID!) {
    project(where: { id: $id }) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        role
        user {
          id
          firstName
          lastName
          image
          tagline
          program
          contactEmail
          primaryExpertise
          secondaryExpertise
          about
          image
          portfolio
          behance
          linkedIn
          instagram
          twitter
          facebook
          dribble
          youtube
          vimeo
          medium
        }
      }
      published
    }
  }
`

const PUBLISH_PROJECT_MUTATION = gql`
  mutation PUBLISH_PROJECT_MUTATION($id: ID!) {
    publishProject(id: $id) {
      id
      title
      tags
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      members {
        id
        role
        user {
          id
        }
      }
      coverImage
      published
    }
  }
`
