import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import gql from 'graphql-tag'
import styled from 'styled-components'
import ProfileForm from './styles/ProfileForm'
import InputLimit, { LengthLimitTextarea } from './styles/InputLimit'
import Error from './ErrorMessage'
import { CURRENT_USER_QUERY } from './User'
import { client } from '../..'
import cogoToast from 'cogo-toast'
import ReorderProjects from './ReorderProjects'
import { Message } from 'semantic-ui-react'
import Loading from './Loading'

const MAX_IMG_SIZE = 5 * 1024 * 1024

const EDIT_PROFILE_MUTATION = gql`
  mutation EDIT_PROFILE_MUTATION(
    $id: ID!
    $firstName: String
    $lastName: String
    $tagline: String
    $program: String
    $contactEmail: String
    $primaryExpertise: String
    $secondaryExpertise: String
    $about: String
    $image: String
    $portfolio: String
    $behance: String
    $linkedIn: String
    $instagram: String
    $twitter: String
    $facebook: String
    $dribble: String
    $youtube: String
    $vimeo: String
    $medium: String
  ) {
    editProfile(
      id: $id
      firstName: $firstName
      lastName: $lastName
      tagline: $tagline
      program: $program
      contactEmail: $contactEmail
      primaryExpertise: $primaryExpertise
      secondaryExpertise: $secondaryExpertise
      about: $about
      image: $image
      portfolio: $portfolio
      behance: $behance
      linkedIn: $linkedIn
      instagram: $instagram
      twitter: $twitter
      facebook: $facebook
      dribble: $dribble
      youtube: $youtube
      vimeo: $vimeo
      medium: $medium
    ) {
      id
      firstName
      lastName
      tagline
      program
      contactEmail
      primaryExpertise
      secondaryExpertise
      about
      image
      portfolio
      behance
      linkedIn
      instagram
      twitter
      facebook
      dribble
      youtube
      vimeo
      medium
    }
  }
`
const USER_UPLOAD_FILE = gql`
  mutation ($file: Upload!, $isVideo: Boolean) {
    singleUpload(file: $file, isVideo: $isVideo) {
      id
      path
      filename
    }
  }
`
const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 20px;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`

// const PROGRAMS = [
//   "Graphic Design",
//   "Interaction Design",
//   "Game - Art",
//   "Art & Design Foundation",
//   "Interactive Media Management",
//   "Digital Design - Game Design",
//   "Concept Art for Entertainment",
//   "Design Management",
//   "Interdisciplinary Design Strategy",
// ];

const PROGRAMS = [
  'Brand Design',
  'Graphic Design',
  'Interaction Design',
  'Game - Art',
  'Digital Experience Design',
  'Art & Design Foundation',
  'Interactive Media Management',
  'Web Development - Front-End Design',
  'Digital Design - Game Design',
  'Concept Art for Entertainment',
  'Design Management',
  'Interdisciplinary Design Strategy',
]

const HelveticaTitle = styled.p`
  font-family:
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  font-weight: bold;
  color: black;
  font-size: 24px;
  margin-top: 50px;
  text-align: center;
`

const UploadBlock = styled.div`
  background-color: #e3e3e3;
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .upload-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(21.5em + 6.75rem + 2px);
    margin: 0;
    opacity: 0;
  }

  .upload-placeholder {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.5;
    z-index: 2;
  }
`

const UploadIcon = styled.img`
  background: rgb(243, 215, 222);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  height: 50px;
  width: 50px;
  padding: 10px;

  &:hover,
  &:focus {
    outline: none;
  }
`

const CoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`

const ErrorInput = styled.p`
  font-size: 16px;
  color: red;
`

class EditProfile extends Component {
  state = {}

  handleChange = (e) => {
    const { name, type, value } = e.target
    const val = type === 'number' ? parseFloat(value) : value
    this.setState({ [name]: val, [`${name}_error`]: undefined })
  }

  uploadFile = async (e) => {
    const { name } = e.target
    console.log('Uploading File')
    const files = e.target.files
    const file = files[0]
    if (file.size > MAX_IMG_SIZE) {
      alert('Maximum image size is 5MB')
      this.setState({ uploadFinish: true })
      e.target.value = null
      return
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async (event) => {
        if (event.total > MAX_IMG_SIZE) {
          alert('Maximum image size is 5MB')
          return
        }
        const { data } = await client.mutate({
          mutation: USER_UPLOAD_FILE,
          variables: { file },
        })
        const profilePicture = data.singleUpload.path
        this.setState({
          [name]: profilePicture,
        })
      }
    }
  }
  editProfile = async (e, editProfileMutation, meId) => {
    e.preventDefault()
    const isValid = this.validateFields()
    if (isValid) {
      try {
        await editProfileMutation({
          variables: {
            id: meId,
            ...this.state,
          },
        })
        cogoToast.success('Your profile updated successfully')
      } catch (error) {
        cogoToast.error('Failed when trying to update profile')
      }
    }
  }

  validateFields = () => {
    const {
      firstName,
      lastName,
      tagline,
      program,
      primaryExpertise,
      about,
      contactEmail,
      // portfolio,
      // behance,
      // linkedIn,
      // instagram,
      // twitter,
      // facebook,
      // dribble,
      // youtube,
      // medium,
      // vimeo,
    } = this.state
    const obj = {
      firstName,
      lastName,
      tagline,
      program,
      primaryExpertise,
      about,
      contactEmail,
      // portfolio,
      // behance,
      // linkedIn,
      // instagram,
      // twitter,
      // facebook,
      // dribble,
      // youtube,
      // medium,
      // vimeo,
    }
    const errorObj = {}
    for (const key of Object.keys(obj)) {
      if (!this.state[key] || this.state[key].length < 2) {
        // this.setState({ [`${key}_error`]: `Require an input` });
        errorObj[`${key}_error`] = `Require an input`
        // return false;
      }
    }
    if (Object.keys(errorObj).length > 0) {
      this.setState({ ...errorObj })
      return false
    }
    return true
  }

  render() {
    const styles = {
      marginTop: '60px',
    }
    const { state } = this

    return (
      <Query
        query={CURRENT_USER_QUERY}
        onCompleted={(data) => {
          const {
            firstName,
            lastName,
            tagline,
            program,
            primaryExpertise,
            about,
            contactEmail,
            // portfolio,
            // behance,
            // linkedIn,
            // instagram,
            // twitter,
            // facebook,
            // dribble,
            // youtube,
            // vimeo,
            // medium,
            image,
          } = data.me
          this.setState({
            firstName,
            lastName,
            tagline,
            program,
            primaryExpertise,
            about,
            contactEmail,
            // portfolio,
            // behance,
            // linkedIn,
            // instagram,
            // twitter,
            // facebook,
            // dribble,
            // youtube,
            // vimeo,
            // medium,
            image,
          })
        }}
      >
        {({ data, loading }) => {
          // console.log(data);
          if (loading) return <Loading />
          if (!data.me) return <p>No User Found for ID: {this.props.id}</p>
          return (
            <Mutation mutation={EDIT_PROFILE_MUTATION} variables={this.state}>
              {(editProfile, { loading, error }) => (
                <ProfileForm onSubmit={(e) => this.editProfile(e, editProfile, data.me.id)}>
                  <Error error={error} />
                  <fieldset disabled={loading} aria-busy={loading}>
                    <Grid>
                      <div>
                        <HelveticaTitle>Bio</HelveticaTitle>
                        <Columns>
                          <label htmlFor='firstName'>
                            First Name
                            <input
                              type='text'
                              id='firstName'
                              name='firstName'
                              placeholder=''
                              defaultValue={data.me.firstName}
                              onChange={this.handleChange}
                            />
                            {state['firstName_error'] && (
                              <ErrorInput>{state['firstName_error']}</ErrorInput>
                            )}
                          </label>
                          <label htmlFor='lastName'>
                            Last Name
                            <input
                              type='text'
                              id='lastName'
                              name='lastName'
                              placeholder=''
                              defaultValue={data.me.lastName}
                              onChange={this.handleChange}
                            />
                            {state['lastName_error'] && (
                              <ErrorInput>{state['lastName_error']}</ErrorInput>
                            )}
                          </label>
                        </Columns>
                        <label htmlFor='tagline'>
                          Tagline
                          <InputLimit
                            type='text'
                            id='tagline'
                            name='tagline'
                            placeholder='Tagline'
                            maxLength={80}
                            value={this.state.tagline}
                            onChange={this.handleChange}
                            fromtop={44}
                          />
                          {state['tagline_error'] && (
                            <ErrorInput>{state['tagline_error']}</ErrorInput>
                          )}
                        </label>
                        <Columns>
                          <label htmlFor='Program'>
                            Program
                            <select
                              name='program'
                              onChange={this.handleChange}
                              defaultValue={data.me.program}
                            >
                              {PROGRAMS.map((program, index) => (
                                <option key={`${program}-${index}`} value={program}>
                                  {program}
                                </option>
                              ))}
                            </select>
                            {state['program_error'] && (
                              <ErrorInput>{state['program_error']}</ErrorInput>
                            )}
                          </label>
                          <label />
                        </Columns>
                        <Columns>
                          <label htmlFor='primaryExpertise'>
                            Primary Expertise
                            <input
                              type='text'
                              id='primaryExpertise'
                              name='primaryExpertise'
                              placeholder='Primary Expertise'
                              defaultValue={data.me.primaryExpertise}
                              onChange={this.handleChange}
                            />
                          </label>
                          {state['primaryExpertise_error'] && (
                            <ErrorInput>{state['primaryExpertise_error']}</ErrorInput>
                          )}
                          <label htmlFor='secondaryExpertise'>
                            Secondary Expertise
                            <input
                              type='text'
                              id='secondaryExpertise'
                              name='secondaryExpertise'
                              placeholder='Secondary Expertise'
                              defaultValue={data.me.secondaryExpertise}
                              onChange={this.handleChange}
                            />
                          </label>
                        </Columns>
                        <label htmlFor='about' style={{ marginBottom: '2rem' }}>
                          About
                          <LengthLimitTextarea
                            type='text'
                            id='about'
                            name='about'
                            placeholder='About'
                            maxLength={200}
                            value={this.state.about}
                            onChange={this.handleChange}
                            fromtop={26}
                          />
                          {state['about_error'] && <ErrorInput>{state['about_error']}</ErrorInput>}
                        </label>
                        {/* <button type="submit">
                          UPDAT{loading ? "ING" : "E"}
                        </button> */}
                        <HelveticaTitle style={styles}>Contact / Social</HelveticaTitle>
                        <label htmlFor='contactEmail'>
                          Contact Email (displayed on profile page)
                          <input
                            type='email'
                            id='contactEmail'
                            name='contactEmail'
                            placeholder='Contact Email'
                            defaultValue={data.me.contactEmail}
                            onChange={this.handleChange}
                          />
                        </label>
                        {state['contactEmail_error'] && (
                          <ErrorInput>{state['contactEmail_error']}</ErrorInput>
                        )}
                        <label htmlFor='portfolio'>
                          Portfolio
                          <input
                            type='url'
                            id='portfolio'
                            name='portfolio'
                            placeholder='Portfolio'
                            defaultValue={data.me.portfolio}
                            onChange={this.handleChange}
                          />
                        </label>
                        {/* {state["portfolio_error"] && (
                          <ErrorInput>{state["portfolio_error"]}</ErrorInput>
                        )} */}
                        <label htmlFor='behance'>
                          Behance
                          <input
                            type='url'
                            id='behance'
                            name='behance'
                            placeholder='Behance'
                            defaultValue={data.me.behance}
                            onChange={this.handleChange}
                          />
                        </label>
                        {/* {state["behance_error"] && (
                          <ErrorInput>{state["behance_error"]}</ErrorInput>
                        )} */}
                        <label htmlFor='linkedIn'>
                          LinkedIn
                          <input
                            type='url'
                            id='linkedIn'
                            name='linkedIn'
                            placeholder='linkedIn'
                            defaultValue={data.me.linkedIn}
                            onChange={this.handleChange}
                          />
                        </label>
                        {/* {state["linkedIn_error"] && (
                          <ErrorInput>{state["linkedIn_error"]}</ErrorInput>
                        )} */}
                        <label htmlFor='instagram'>
                          Instagram
                          <input
                            type='url'
                            id='instagram'
                            name='instagram'
                            placeholder='Instagram'
                            defaultValue={data.me.instagram}
                            onChange={this.handleChange}
                          />
                        </label>
                        {/* {state["instagram_error"] && (
                          <ErrorInput>{state["instagram_error"]}</ErrorInput>
                        )} */}
                        <label htmlFor='twitter'>
                          Twitter
                          <input
                            type='url'
                            id='twitter'
                            name='twitter'
                            placeholder='Twitter'
                            defaultValue={data.me.twitter}
                            onChange={this.handleChange}
                          />
                        </label>
                        {/* {state["twitter_error"] && (
                          <ErrorInput>{state["twitter_error"]}</ErrorInput>
                        )} */}
                        <label htmlFor='facebook'>
                          Facebook
                          <input
                            type='url'
                            id='facebook'
                            name='facebook'
                            placeholder='Facebook'
                            defaultValue={data.me.facebook}
                            onChange={this.handleChange}
                          />
                        </label>
                        {/* {state["facebook_error"] && (
                          <ErrorInput>{state["facebook_error"]}</ErrorInput>
                        )} */}
                        <label htmlFor='dribble'>
                          Dribbble
                          <input
                            type='url'
                            id='dribble'
                            name='dribble'
                            placeholder='Dribbble'
                            defaultValue={data.me.dribble}
                            onChange={this.handleChange}
                          />
                        </label>
                        {/* {state["dribble_error"] && (
                          <ErrorInput>{state["dribble_error"]}</ErrorInput>
                        )} */}
                        <label htmlFor='youtube'>
                          YouTube
                          <input
                            type='url'
                            id='youtube'
                            name='youtube'
                            placeholder='YouYube'
                            defaultValue={data.me.youtube}
                            onChange={this.handleChange}
                          />
                        </label>
                        {/* {state["youtube_error"] && (
                          <ErrorInput>{state["youtube_error"]}</ErrorInput>
                        )} */}
                        <label htmlFor='vimeo'>
                          Vimeo
                          <input
                            type='url'
                            id='vimeo'
                            name='vimeo'
                            placeholder='Vimeo'
                            defaultValue={data.me.vimeo}
                            onChange={this.handleChange}
                          />
                        </label>
                        {/* {state["vimeo_error"] && (
                          <ErrorInput>{state["vimeo_error"]}</ErrorInput>
                        )} */}
                        <label htmlFor='medium'>
                          Medium
                          <input
                            type='url'
                            id='medium'
                            name='medium'
                            placeholder='Medium'
                            defaultValue={data.me.medium}
                            onChange={this.handleChange}
                          />
                        </label>
                        {/* {state["medium_error"] && (
                          <ErrorInput>{state["medium_error"]}</ErrorInput>
                        )} */}
                        <button type='submit'>UPDAT{loading ? 'ING' : 'E'}</button>
                      </div>
                      <div>
                        <HelveticaTitle>Profile Picture</HelveticaTitle>
                        <label htmlFor='image'>
                          Image
                          <UploadBlock>
                            <input
                              type='file'
                              id='image'
                              name='image'
                              placeholder='Upload an image'
                              onChange={this.uploadFile}
                              className='upload-input'
                            />
                            {!this.state.image && (
                              <img
                                className='upload-placeholder'
                                src={'/static/drawing.svg'}
                                alt=''
                              />
                            )}
                            <UploadIcon src='/static/edit.svg' alt='Change image' />
                            {this.state.image && (
                              <CoverImage src={this.state.image} alt='Upload Preview' />
                            )}
                          </UploadBlock>
                          {!this.state.image && (
                            <Message
                              warning
                              content='Your profile will not be active until you upload a profile photo'
                            />
                          )}
                        </label>
                        <HelveticaTitle>Project Profile Order</HelveticaTitle>
                        <ReorderProjects meId={data.me.id} />
                      </div>
                    </Grid>
                  </fieldset>
                </ProfileForm>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

export default EditProfile
export { EDIT_PROFILE_MUTATION }
