import React, { useState} from 'react'


import StickerNavs from '../StickerNavs'

import {MobileNavsBottom, MobileRightNavs} from '../MobileRightNavs'
import {useDisclosure} from 'react-use-disclosure'
import cn from 'classnames'
import FixedBottomNav from '../FixedBottomNav'
import {Element} from 'react-scroll'
import SearchIcon from '../../../components/2023/SearchIcon'
import Footer from '../Footer'

import {
    FooterContainer,
} from "../../../styles/2023/GradsStyle";
import {
    EventsContainer,
    Container,
    EventsBGBottom,
    ContainerSectionOne,
    TextCenterDiv,
    TextCenter, TextCenterTop, EventsTimeline, EventsBGTop, HeadContainer, EventDiv
} from "../../../styles/2023/EventsStyle";
import ColoredScrollbars2023 from "../../../components/2023/ColoredScrollbars2023";


export default function Events(props) {
    const [eventDay, setEventDay] = useState(0)

    const {isOpen: isOpenMenuNavs, toggle: toggleMobileNavs} = useDisclosure()

    const updateEventDay = () => {
        let dayOne = document.getElementById('dayOne');
        let dayTwo = document.getElementById('dayTwo');
        let dayThree = document.getElementById('dayThree');
        let positionOne = dayOne?.getBoundingClientRect?.();
        let positionTwo = dayTwo?.getBoundingClientRect?.();
        let positionThree = dayThree?.getBoundingClientRect?.();

        console.log()
        if (positionOne && positionTwo && positionThree) {
            if (positionOne.top >= -window.innerHeight/2 && positionOne.bottom <= window.innerHeight/1.5) {
                setEventDay(1);
            } else if (positionTwo.top >= -window.innerHeight/2 && positionTwo.bottom <= window.innerHeight/1.5) {
                setEventDay(2);
            } else if (positionThree.bottom <= window.innerHeight/1.5) {
                setEventDay(3);
            }
            else if(positionOne.top >= window.innerHeight/2){
                setEventDay(0);
            }
        }
    };


    return (
        <ColoredScrollbars2023 onScroll={updateEventDay}>
        <Container
            className="w-full"
            id="mainContainer"
            style={{background: '#F0EEE9'}}
        >
            <Element name="myScrollToElement" id="scroll2023top"></Element>
            <SearchIcon className='hidden lg:block' />
            <EventsBGTop>
                <img
                    src={`/static/svg/2023/Grads/GradsBGTop.png`}
                    alt=""
                />
            </EventsBGTop>
            <StickerNavs
                className="w-96 fixed top-0 transition-opacity duration-700 ease-in-out hidden lg:block z-50"/>
            <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs}/>
            <ContainerSectionOne>
                <TextCenterDiv>
                    <TextCenter className="text-secondary" style={{pointerEvents: 'none'}}>
                        Events
                    </TextCenter>
                </TextCenterDiv>
                <TextCenterTop>
                    <h2>Wednesday, May 3, 2023</h2>
                </TextCenterTop>
            </ContainerSectionOne>
            <EventsContainer className="w-full mx-auto">
                <EventsTimeline>
                    <div className="timelineGraphic">
                        <div className="timelineBorder"></div>

                    </div>
                    <div className="timelineEventDays">
                        <div className="timelineEventDaySingle" >
                            <div className="timelineInfoTop">
                                <div className="timelineDayHeading">
                                    <div className="borderLeftDayOne"
                                        style={{borderColor: (eventDay<1 ? '#C7C2E1' : '#26439B')}}></div>
                                    <img className="timelineSticker" src={`/static/svg/2023/Events/1_TL.png`} alt='' />
                                    <h3 id="dayOne">Day 1</h3>
                                </div>
                                <div className="timelineDateTime">
                                    <p className="timelineDate">Wednesday May 3, 2023</p>
                                    <p className="timelineTime">4pm EST</p>
                                </div>
                            </div>
                            <div className="timelineEventNames">
                                <ul>
                                    <li></li>
                                </ul>
                            </div>
                            <div className="EventDivContainer">
                                <div id="borderLeftFull" className="borderLeftFull"
                                    style={{borderColor: (eventDay<1 ? '#C7C2E1' : '#26439B')}}></div>
                                <EventDiv className={cn('w-full')}>
                                    <HeadContainer>
                                        <img
                                            src="/static/svg/2023/Events/dominicWS.png"
                                            alt="glass"
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className="title">
                                    Workshop: Experimental Typography with Dominic Ayre
                                    </div>
                                    <div style={{width: '100%', textAlign: 'left', height: '90px', lineHeight: 1}}>
                                        <div className="time">2–3:30PM EDT</div>
                                        <a
                                            className="ml-2 text-mad-blue hover:bg-mad-blue transition hover:text-coton"
                                            href="https://www.eventbrite.ca/e/experimental-typography-workshop-with-dominic-ayre-tickets-608638984587"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <span className="text-mad-blue">•</span> <span
                                            className="border-b border-dotted border-mad-blue ">Virtual Link</span>
                                        </a>
                                    </div>
                                </EventDiv>
                                <EventDiv className={cn('w-full')}>
                                    <HeadContainer>
                                        <img
                                            src="/static/Marks_1x/Mark_3.png"
                                            alt="glass"
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className="title">
                                        Panel: Design at the Intersection of Mental Health & Well-Being
                                    </div>
                                    <div style={{width: '100%', textAlign: 'left', height: '90px', lineHeight: 1}}>
                                        <div className="time">4–5:30PM EDT</div>
                                        <a
                                            className="ml-2 text-mad-blue hover:bg-mad-blue transition hover:text-coton"
                                            href="https://www.eventbrite.ca/e/design-at-the-intersection-of-mental-health-well-being-panel-discussion-tickets-608551442747"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <span className="text-mad-blue">•</span> <span
                                            className="border-b border-dotted border-mad-blue ">Virtual Link</span>
                                        </a>
                                    </div>
                                </EventDiv>
                                <EventDiv className={cn('w-full')}>
                                    <HeadContainer>
                                        <img
                                            src="/static/Marks_1x/Mark_7.png"
                                            alt="glass"
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className="title">
                                        Public Exhibition Opening
                                    </div>
                                    <div style={{width: '100%', textAlign: 'left', height: '90px', lineHeight: 1}}>
                                        <div className="time">6–9PM</div>
                                    </div>
                                </EventDiv>
                            </div>
                        </div>
                        <div className="timelineEventDaySingle">
                            <div className="timelineInfoTop">
                                <div className="timelineDayHeading">
                                    <div className="borderLeftMulti"
                                        //border-image: linear-gradient(180deg, #26439B 60%,  #C7C2E1 40%);
                                        style={{borderImage: (eventDay<2 ? 'linear-gradient(180deg, #26439B 60%,  #C7C2E1 40%) 1' : 'linear-gradient(180deg, #26439B 60%,  #26439B 40%) 1')}}></div>
                                    <img className="timelineStickerMid" src={(eventDay<2 ? `/static/svg/2023/Events/2W_TL.png` : `/static/svg/2023/Events/2_TL.png`)} alt='' />
                                    <h3 id="dayTwo">Day 2</h3>
                                </div>
                                <div className="timelineDateTime">
                                    <p className="timelineDate">Thursday, May 4, 2023</p>
                                    <p className="timelineTime">2pm EST</p>
                                </div>
                            </div>
                            <div className="EventDivContainer">
                                <div className="borderLeftFull"
                                style={{borderColor: (eventDay<2 ? '#C7C2E1' : '#26439B')}}></div>
                                <EventDiv className={cn('w-full')}>
                                    <HeadContainer>
                                        <img
                                            src="/static/svg/2023/Events/nicolaWS.png"
                                            alt="glass"
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className="title">
                                        Workshop: Collaging with Nicola Hamilton & Mitchell Keys
                                    </div>
                                    <div style={{width: '100%', textAlign: 'left', height: '90px', lineHeight: 1}}>
                                        <div className="time">2–4PM EDT</div>
                                        <a
                                            className="ml-2 text-mad-blue hover:bg-mad-blue transition hover:text-coton"
                                            href="https://www.eventbrite.ca/e/collage-workshop-with-nicola-hamilton-mitchell-keys-tickets-608575655167"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <span className="text-mad-blue">•</span> <span
                                            className="border-b border-dotted border-mad-blue ">Virtual Link</span>
                                        </a>
                                    </div>
                                </EventDiv>
                                <EventDiv className={cn('w-full')}>
                                    <HeadContainer>
                                        <img
                                            src="/static/Marks_1x/Mark_1.png"
                                            alt="glass"
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className="title">
                                        Exclusive: Industry Reception
                                    </div>
                                    <div style={{width: '100%', textAlign: 'left', height: '90px', lineHeight: 1}}>
                                        <div className="time">5–6PM EDT</div>
                                    </div>

                                </EventDiv>
                                <EventDiv className={cn('w-full')}>
                                    <HeadContainer>
                                        <img
                                            src="/static/Marks_1x/Mark_2.png"
                                            alt="glass"
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className="title">
                                        Public Exhibition
                                    </div>
                                    <div style={{width: '100%', textAlign: 'left', height: '90px', lineHeight: 1}}>
                                        <div className="time">6–9PM EDT</div>
                                    </div>
                                </EventDiv>
                            </div>
                        </div>
                        <div className="timelineEventDaySingle">
                            <div className="timelineInfoTop">
                                <div className="timelineDayHeading" style={{position:'relative'}}>
                                    <div className="borderLeftDayThree" style={{borderColor: (eventDay<2 ? '#C7C2E1' : '#26439B')}}></div>
                                    <img className="timelineStickerBot" src={(eventDay<3 ? `/static/svg/2023/Events/3W_TL.png` : `/static/svg/2023/Events/3_TL.png`)} alt='' />
                                    <h3 id="dayThree">Day 3</h3>
                                </div>
                                <div className="timelineDateTime">
                                    <p className="timelineDate">Friday, May 4, 2023</p>
                                    <p className="timelineTime">1pm EST</p>
                                </div>
                            </div>
                            <div className="EventDivContainer">
                                <EventDiv className={cn('w-full')}>
                                    <HeadContainer>
                                        <img
                                            src="/static/Marks_1x/Mark_2.png"
                                            alt="glass"
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className="title">
                                        Panel: Where It All Started: School of Design Alumni
                                    </div>
                                    <div style={{width: '100%', textAlign: 'left', height: '90px', lineHeight: 1}}>
                                        <div className="time">1–2:30PM EDT</div>
                                        <a
                                            className="ml-2 text-mad-blue hover:bg-mad-blue transition hover:text-coton"
                                            href="https://www.eventbrite.ca/e/where-it-started-a-school-of-design-alumni-panel-discussion-tickets-608569386417"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <span className="text-mad-blue">•</span> <span
                                            className="border-b border-dotted border-mad-blue ">Virtual Link</span>
                                        </a>
                                    </div>

                                </EventDiv>
                                <EventDiv className={cn('w-full')}>
                                    <HeadContainer>
                                        <img
                                            src="/static/Marks_1x/Mark_3.png"
                                            alt="glass"
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className="title">
                                        Awards: Annual Ceremony
                                    </div>
                                    <div style={{width: '100%', textAlign: 'left', height: '90px', lineHeight: 1}}>
                                        <div className="time">4–6PM EDT</div>
                                    </div>
                                </EventDiv>
                                <EventDiv className={cn('w-full')}>
                                    <HeadContainer>
                                        <img
                                            src="/static/Marks_1x/Mark_5.png"
                                            alt="glass"
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className="title">
                                        Public Exhibition Closing
                                    </div>
                                    <div style={{width: '100%', textAlign: 'left', height: '90px', lineHeight: 1}}>
                                        <div className="time">6–9PM EDT</div>

                                    </div>
                                </EventDiv>
                            </div>
                        </div>
                    </div>
                </EventsTimeline>
            </EventsContainer>
            <EventsBGBottom>
                <img
                    src={`/static/svg/2023/Grads/GradsBGBottom.png`}
                    alt=""
                />

            </EventsBGBottom>
            <FixedBottomNav className="hidden lg:block"/>
            <FooterContainer data-color="off_white">
                <Footer/>
            </FooterContainer>
            <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs}/>
        </Container>
        </ColoredScrollbars2023>
    )
}
