import React from 'react'
import { createRoot } from 'react-dom/client'
import './styles.scss'
import 'semantic-ui-css/semantic.min.css'

import * as serviceWorker from './serviceWorker'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { ApolloProvider } from '@apollo/react-hooks'
import { BrowserRouter } from 'react-router-dom'
import { createUploadLink } from 'apollo-upload-client'
import { HelmetProvider } from 'react-helmet-async'
import App from './App'

const cache = new InMemoryCache()

export const client = new ApolloClient({
  ssrMode: typeof window === 'undefined', // set to true for SSR
  cache: cache,
  link: createUploadLink({
    uri: process.env.REACT_APP_API_ENDPOINT || 'https://api.georgebrown.ca/graphql',
    fetch,
    fetchOptions: { credentials: 'include' },
  }),
})

const container = document.getElementById('root')
const root = createRoot(container) // Create a root.

root.render(
  // <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
      </BrowserRouter>
    </HelmetProvider>
//  </React.StrictMode>,
)

// Service worker setup remains the same
serviceWorker.unregister()
