import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import dayjs from 'dayjs'
import cn from 'classnames'
import { useWindowSize } from '../../../hook/2023'
import VideoModal from './VideoPlayerModal'
import cursorSVG from '../cursor-with-shadow-2023.svg';

const Container = styled.div`
  position: relative;
  height: 100%;
  background-color: #C7C2E1;
  display: flex;
  flex: 1;
  cursor: url(${cursorSVG}), auto;
  // z-index : 0;
  svg:first-child {
    // height: 1200px;
  }
  max-width:100vw;
`
const TextLogo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 8.5rem;
  line-height: 9rem;
  letter-spacing: 0.01em;
  font-weight: 400;
`
const TextCenterTop = styled.div`
  position: relative;
  top: -10px;
// @media (min-width: 300px) {
//   top : 99%;
//   margin-top : 0px;
// }
// @media (min-width: 480px) {
//   margin-top : 0px;
// }
// @media (min-width: 768px) {
//   margin-top : 0px;
//   top : 95%;
// }
// @media (min-width: 1280px) {
//   margin-top : 0px;
//   top : 95%;

// }
// @media (min-width: 1680px) {
//   margin-top : 0px;
//   top : 95%;
// }
// @media (min-width: 1920px) {
//   top : 95%;
// }
// @media (min-width: 2880px) {
//   top : 85%;
// }
  h2,
  h3 {
    font-family: 'grotesque_pro_light';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
  }
  // @media (min-width: 768px) {
  //   // bottom: 0%;
  // }
  // @media (min-width: 1600px) {
  //   bottom: 2%;
  // }
`
const EmailSignUpCenter = styled.div`
position: relative;
top: -10px;
form {
  // height: 14vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items:center;
  font-family: 'grotesque_pro_light';
  font-size: 1.8rem;
}
#email{
  color: #191919;
  width: 401px;
  height: 60px;
  border-radius: 30px;
  padding: 5px 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 3px solid ${(props) => (props.hasError ? 'red' : '#C7C2E1')};
  display: ${(props) => (props.submitted ? 'none' : 'block')};
}
#email:hover{
  border: 3px solid red;
}
#email:focus{
  outline: none;
  border: 3px solid red;
}
#submit{
  width: 401px;
  height: 60px;
  background-color: #26439B;
  color: #FFFFFF;
  border-radius: 30px;
  display: ${(props) => (props.submitted ? 'none' : 'block')};
}
#submit:hover{
  border: 3px solid #fa8975;
}
#inline-error{
  color: white;
  margin-top: 10px;
  display: ${(props) => (props.hasError ? 'block' : 'none')};
}
#submitted{
  color: white;
  margin-top: 10px;
  display: ${(props) => (props.submitted ? 'block' : 'none')};
}
@media (min-width: 300px) {
  // top : 130%;
  form {
    height: 30vh;
    margin-bottom: 7%;
  }
  #email{
    width: 250px;
  }
  #submit{
    width: 250px;
  }
}
@media (min-width: 480px) {
  // top : 130%;
  form {
    height: 20vh;
    margin-bottom: 7%;
  }
  #email{
    width: 401px;
  }
  #submit{
    width: 401px;
  }
}
@media (min-width: 768px) {
  // top : 120%;
  form {
    height: 27vh;
    margin-bottom: 7%;
  }
}
@media (min-width: 1280px) {
  // top : 114%;
  form {
    min-height: 23vh;
    margin-bottom: 7%;
  }
}
@media (min-width: 1440px) {
  // top : 112%;
  form {
    min-height: 19vh;
    margin-bottom: 7%;
  }
}
@media (min-width: 1920px) {
  // top : 107%;
  form {
    min-height: 18vh;
    margin-bottom: 7%;
  }
}
@media (min-width: 2880px) {
  // top : 92%;
  form {
    min-height: 10vh;
  }
}


`
const SwirlBG = styled.div`
  position: absolute;
  top: -10vw;
  // @media (max-width: 1920px) {
  //   top: 0;
  // }
`
const StickerContainer = styled.div`
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  display: ${(props) => props.display};
  left: ${(props) => props.imgLeft || 28};
  cursor: ${(props) => (props.pointer ? 'pointer' : 'inherit')};
  img {
    width: ${(props) => props.imgWidth || 84}px;
  }
  p {
    position: absolute;
    top: calc(${(props) => props.top} + 16px);
    font-family: Reckless;
    transform: rotate(${(props) => props.textRotate}deg);
  }
  @media (max-width: 550px) {
    top : 90% !important;
  }
  @media (max-width: 436px) {
    left : 100% !important;
  }
`
const CenterContainer = styled.div`
  postion: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1025px) {
    top: 60%;
  }
  `
const TextCenter = styled.h4`
  font-family: 'reckless_light';
  line-height: 240px;
  font-weight: 100;
  margin-top: 28vw !important;
  @media (max-width: 1025px) {
  }
  @media screen and (max-width: 480px)
  and (orientation: portrait) {
    margin-top: 58vw !important;
  }
  @media (min-width: 485px) {
    line-height: 260px;
  }
  @media (min-width: 1026px) {

  }
  @media (min-width: 1440px) {

  }
  @media (min-width: 1920px) {

  }
`
const CountdownContainer = styled.div`
  postion: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const LiveInContainer = styled.div`
  top: 48%;
  left: 50%;
  transform: translate(-50%,-50%);
  //pointer-events: none;
  img {
    width: 60%;
  }
  @media screen and (max-width: 480px)
  and (orientation: portrait) {
    top: 46%;
  }
  @media (min-width: 485px) {
    img {
      width: 70%;
    }
    top: 48%;
  }
  @media (min-width: 764px) {
    img {
      width: 70%;
    }
    top: 50%;
  }
  @media (min-width: 800px) {
    img {
      width: 70%;
    }
    top: 48%;
  }
  @media (min-width: 1026px) {
    img {
      width: 90%;
    }
    top: 45%;
  }
  @media (min-width: 1440px) {
    img {
      width: 100%;
    }
    top: 50%;
  }
`
const Sticker3Days = styled.img`
  top: 2%;
  left: 10%;
  cursor: inherit;
  @media (max-width: 1025px) {
    top: 3%;
    left: 60%;
    width: 20%;
  }
  @media (min-width: 1026px) {
    top: 10%;
    left: 10%;
  }
  @media (min-width: 1440px) {
    left: 10%;
  }
  @media (min-width: 1920px) {
    left: 13%;
  }
  @media (min-width: 2880px) {
    left: 13%;
  }

`
const StickerMay = styled.img`
  bottom: 20%;
  right: 5%;
  cursor: inherit;
  @media (max-width: 1025px) {
    margin: 0;
    top: 10%;
    left: 1%;
    width: 38%;
    bottom: 10%;
  }
  @media (min-width: 1026px) {
    right: 5%;
  }
  @media (min-width: 1280px) {
    right: 5%;
  }
  @media (min-width: 1440px) {
    right: 5%;
  }
  @media (min-width: 1920px) {
    right: 9%;
    bottom: 15%;
  }
  @media (min-width: 2880px) {
    bottom: 17%;
    right: 5%;
  }
  @media screen and (min-width: 1026px) and (max-height: 1000px) {
    bottom: -17%;
    right: 1%;
  }
`
const CountdownNumber = styled.span`
  font-size: calc(140px + 7.8125vw);
`
const CountdownText = styled.span`
  font-size: calc(80px + 3.90625vw);
  position: absolute;
  bottom: calc(30px + 6vw);
  line-height: calc(80px + 3.90625vw);
  @media (min-width: 500px) {
    //bottom: calc(100px + 5vw);
    //height: 10vh;
  }
  @media (min-width: 1025px) {
    bottom: calc(20px + 4vw);
  }
`
const END_DATE_COUNTDOWN = '04/28/2023'

const randDirX = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY = Math.floor(Math.random() * 2) === 1 ? 1 : -1

export default function Teaser() {
  const history = useHistory()
  const ref = useRef()
  const endDay = dayjs(END_DATE_COUNTDOWN, 'MM/DD/YYYY')
  const daysLeft = useMemo(() => {
    const left = endDay.diff(dayjs(), 'days')
    return left > 0 ? left : 0
  }, [endDay])
  const hoursLeft = useMemo(() => {
    const left = endDay.diff(dayjs(), 'hours') - (daysLeft || 0) * 24
    return left > 0 ? left : 0
  }, [endDay, daysLeft])

  const [state, setState] = useState({ x: 0, y: 0})
  const [email, setEmail] = useState('')

  const onMouseMove = useCallback((e) => {
    setState({ x: e.nativeEvent.x, y: e.nativeEvent.y})
  }, [])


  const { screen } = useWindowSize()
  const isMobile = screen === 'md'
  const isTab = screen === 'tab'

  const rect = ref?.current?.getBoundingClientRect()

  useEffect(() => {
    if (daysLeft !== 0 || hoursLeft !== 0) {
      return
    }
    history.push('/2023/home')
  }, [daysLeft, hoursLeft, history])

  const [videoOpen, setVideoOpen] = useState(false)
  const [videoMute, setVideoMute] = useState(true)
  const [errorForm, setErrorForm] = useState(false)
  const [submitForm, setSubmitForm] = useState(false)

  const toggleOpenVideo = useCallback(() => {
    (!videoOpen && setVideoMute(true))
    setVideoOpen(v => !v)
  }, [videoOpen])
  const toggleMuteVideo = useCallback(() => setVideoMute(v => !v), [])


  const onHandleForm = useCallback((e) => {
    e.preventDefault()
    const emailFormat=/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if(!email || !email.match(emailFormat)){
      setErrorForm(true);
      console.log('form failed');
      return
    }
    const baseUrl = process.env.REACT_APP_API_ENDPOINT_BASE || 'https://api.georgebrown.ca'
    fetch(`${baseUrl}/join-waiting-list`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    })
    .then(response => response.json())
    .then(setSubmitForm(true))
    .then(data => {
      //console.log(data)
    });

  }, [email])

  const onInputChange = useCallback((e) => {
    const email = e.target.value
    setEmail(email)
  }, [])

  return (
    <>
    <VideoModal
      videoOpen={videoOpen}
      setVideoClose={toggleOpenVideo}
      videoMute={videoMute}
      setVideoMute={toggleMuteVideo}
    />
    <Container
      className=' bg-blue'
      cursorUrl='/static/svg/2023/cursor-with-shadow.svg'
      onMouseMove={onMouseMove}
    >
      <SwirlBG className='absolute w-full '>
         <img
          src={`/static/svg/2023/fill.svg`}
          alt='bg-teaser'
          className='w-full'
        />
     </SwirlBG>
      <StickerNavs />
      <StickerContainer
        imgWidth={isMobile ? 84 : 220}
        imgLeft={isTab ? '1%' : '12%'}
        className='absolute'
        //top={isMobile ? '65%' : '55%'}
        bottom={isTab ? '-10%' : '10%'}
        textRotate={10}
        display={isMobile ? 'none' : 'block' }
      >
        <img src='/static/svg/2023/yes-blue-circle.svg' alt='yes-blue-circle' />
      </StickerContainer>
      <StickerContainer
        imgWidth={isMobile ? 124 : 160}
        imgLeft={isTab ? '14%' : '20%'}
        className='absolute'
        //top={isMobile ? '75%' : '66%'}
        bottom={isTab ? '-10%' : '5%'}
        textRotate={10}
        onClick={()=>window.open("https://www.instagram.com/sod_yes/")}
        style={{zIndex:100, cursor:'pointer'}}
        display={isMobile ? 'none' : 'block' }
        id="instasvg"
      >
          <img src='/static/svg/2023/sod-circle-blue.svg' alt='yes-blue-circle' />
      </StickerContainer>
      <StickerContainer
        imgWidth={isMobile ? 124 : 160}
        imgLeft={isMobile ? '70%' : '84%'}
        className='absolute'
        //top={isMobile ? '75%' : '78%'}
        bottom={isTab ? '-24%' : '10%'}
        textRotate={10}
        onClick={()=>window.open("https://www.tiktok.com/@designgbc")}
        style={{zIndex:100, cursor:'pointer'}}
        display={isMobile ? 'none' : 'block' }
        id="tiktoksvg"
      >
        <img src='/static/svg/2023/sticker-tiktok.svg' alt='yes-blue-circle' />
      </StickerContainer>
      <StickerContainer
        imgWidth={isMobile ? 124 : 200}
        imgLeft={isTab ? '2%' : '12%'}
        className='absolute'
        //top='72%'
        bottom={isTab ? '-30%' : '1%'}
        textRotate={10}
        display={isMobile ? 'none' : 'block' }
      >
        <img src='/static/svg/2023/group-12.svg' alt='yes-blue-circle' />
      </StickerContainer>
      <Sticker3Days
            src='/static/svg/2023/3-days.svg'
            alt='3-days'
            className={cn('absolute mx-auto cursor-pointer')}
            id="fourDays"
          />
      <StickerMay
        src='/static/svg/2023/may3-5.svg'
        alt='5-days'
        className={cn('absolute mx-auto cursor-pointer')}
      />
      <CenterContainer className='w-full absolute mx-auto'>
        <CountdownContainer className='text-center relative h-full'>
          <TextCenter>
            <CountdownNumber className='text-mad-blue'>{daysLeft>10 ? daysLeft : '0'+daysLeft}</CountdownNumber>
            <span style={{position: 'relative', width: 0, height: 0}} >
              <CountdownText className='text-mad-blue'>d</CountdownText>
            </span>
            {'   '}
            {isMobile && <div style={{height: '0vw', width: '20px'}} ></div>}
            {!isMobile && <span style={{display: 'inline-block', width: '220px'}} ></span>}
            <CountdownNumber className='text-mad-blue'>{hoursLeft>=10 ? hoursLeft : '0'+hoursLeft}</CountdownNumber>
            <span style={{position: 'relative', width: 0, height: 0}}>
              <CountdownText className='text-mad-blue'>h</CountdownText>
            </span>
          </TextCenter>

          <TextCenterTop className='realtive w-full z-10'>
            <h2 className='text-black uppercase text-center p-0 m-0'>George Brown College</h2>
            <h2 className='text-black uppercase text-center p-0 m-0'>School of design</h2>
            <h3 className='text-black text-center p-0 m-0'>
              Year End Show
              <a
                className='text-mad-blue'
                href='https://www.instagram.com/sod_yes/'
                // target='_blank'
                rel='noreferrer'
              >
                {' '}
                @sod_yes
              </a>
            </h3>
          </TextCenterTop>
          <br/>
          <EmailSignUpCenter className='absolute w-full z-10' hasError={errorForm} submitted={submitForm}>
            <form onSubmit={onHandleForm}>
              <label className='text-black'>Sign up to our mailing list to receive updates</label>
              <input id="email" type="text" name="name" placeholder="Enter Your Email" value={email} onChange={onInputChange}/>
              <input id="submit" type="submit" value="Sign Up!" />
              <div className="inline-error" id="inline-error">Please enter a valid email address</div>
              <div id="submitted"><span className="text-mad-blue">Success!</span>You’re in!</div>
            </form>
          </EmailSignUpCenter>
        </CountdownContainer>
      </CenterContainer>
      <LiveInContainer
        className={cn('absolute mx-auto flex justify-center items-start')}
        style={{
          //transform: (isMobile ? 'translate3d(0, -1vw, 0)' :`translate3d(-50%, -50%, 0) translate3d(${(state.x / 20 - (rect?.x || 0) / 20) * randDirX}px, ${(state.y / 20 - (rect?.y || 0) / 20) * randDirY}px, 0)`),
          transform: (isMobile ? 'translate(-50%, -50%)' : `translate(calc(-50% + ${(state.x / 20 - (rect?.x || 0) / 20) * randDirX}px), calc(-50% + ${(state.y / 20 - (rect?.y || 0) / 20) * randDirY}px))`)
        }}
      >
        <img src='/static/svg/2023/live-in.svg' alt='yes-blue-circle' ref={ref} onClick={toggleOpenVideo} style={{cursor:'pointer'}}/>
      </LiveInContainer>
      <link rel="stylesheet" href="/static/transition-css.css" type="text/css" />

      <div className="left-layer z-10"></div>
      <div className="right-layer z-10"></div>
    </Container>
    </>
  )
}

function StickerNavs() {

  return (
    <>
      <div className='w-full flex justify-between pl-10 relative'>
        <div className='relative z-10 inline-block'>
          <TextLogo className='text-mad-blue uppercase'>
            Yes!2023
          </TextLogo>
        </div>
      </div>
    </>
  )
}
