import React, { useEffect, useMemo, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { DropdownFilter } from '../../../components/2024/DropdownFilter'
import qs from 'query-string'
import groupBy from 'lodash.groupby'
import { QUERY } from '../../../graphql'
import shuffle from 'lodash.shuffle'
import { TextCenterDiv, SearchContainer } from '../../../styles/2024/GradsStyles'
import { GradCard } from '../../../components/2024/GradCard'
import { H1M, H3, P } from '../../../styles/2024/TextStyles'
import { programGroups24 as programGroups, projectTypeMap24 } from '../../../constant'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { SpringContext } from '../../../context/SpringContext'
import { useWindowSize } from '../../../hook/2024/hooks/useWindowSize'

const Header = React.lazy(() => import('../../../components/2024/Header'))
const Countdown = React.lazy(() => import('../../../components/2024/Countdown'))
const Footer = React.lazy(() => import('../../../components/2024/Footer'))
const FullScreenLoader = React.lazy(() => import('../../../components/2024/FullScreenLoader'))
const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

const ITEMS_PER_PAGE = 12

export default function Grads() {
  const location = useLocation()
  const { search: searchParams } = location
  const { program, type, page } = qs.parse(searchParams)
  const [gradsSearch, setGradsSearch] = useState('')
  // const [currentPage, setCurrentPage] = useState(1)
  // const [totalPages, setTotalPages] = useState(1)
  const spring = useContext(SpringContext)
  const { screen } = useWindowSize()
  const isMobile = screen === 'sm'

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring])

  // const handleScrollTop = () => {

  //   if (spring) {
  //     spring.setCurrentValue(0).setAtRest()
  //     spring.setEndValue(0)
  //   }
  // }

  // const [currentGrads, setCurrentGrads] = useState([])

  // const { data: totalGradsCount } = useQuery(QUERY.GET_TOTAL_GRADS_COUNT)

  // const gradCount = useMemo(
  //   () => totalGradsCount?.usersConnection?.aggregate?.count || 0,
  //   [totalGradsCount],
  // )

  // useEffect(() => {
  //   if (gradCount) {
  //     setTotalPages(Math.ceil(gradCount / ITEMS_PER_PAGE))
  //   }
  // }, [gradCount])

  // useEffect(() => {
  //   if (page) {
  //     const _pageNumber = parseInt(page)
  //     setCurrentPage(_pageNumber)
  //   }
  // }, [page])

  const { data, loading } = useQuery(QUERY.USERS_QUERY, {
    variables: {
      where: {
        studentYear: 2024,
        activeAt_gt: '2023-12-31',
        nameSearch: gradsSearch,
      },
      // skip: (currentPage - 1) * ITEMS_PER_PAGE,
      // first: ITEMS_PER_PAGE,
    },
  })

  const grads = useMemo(() => {
    if (!data?.users) {
      return {}
    }
    let users = data?.users || []

    if (program) {
      users = users.filter((user) => user.program === program)
    }
    if (type) {
      const typleList = projectTypeMap24[type]
      users = users.filter((stu) => {
        const stdPrimaryExpertise = stu.primaryExpertise?.replace(' ', '_')?.toLowerCase() || ''
        const stdSecondaryExpertise = stu.secondaryExpertise?.replace(' ', '_')?.toLowerCase() || ''
        if (!stdPrimaryExpertise) return typleList.includes(stdSecondaryExpertise)
        if (!stdSecondaryExpertise) return typleList.includes(stdPrimaryExpertise)
        return typleList.includes(stdPrimaryExpertise) || typleList.includes(stdSecondaryExpertise)
      })
    }
    // if (type) {
    //   users = users.filter((user) => (user.primaryExpertise === type) || (user.secondaryExpertise === type))
    // }
    const grouped = groupBy(shuffle(users), 'program')

    const obj = {}
    programGroups.forEach((key) => {
      if (grouped[key]?.length > 0) {
        obj[key] = grouped[key]
      }
    })

    return obj
  }, [data, program, type])

  const gradsSorted = useMemo(() => {
    // let lists = []
    const alphabetKey = {}
    Object.keys(grads).forEach((key) => {
      const sorted = grads[key].sort(function (a, b) {
        var textA = a.firstName.toUpperCase()
        var textB = b.firstName.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
      alphabetKey[key] = sorted
      // lists.push(sorted)
    })
    let gradList = []
    Object.entries(alphabetKey).forEach(([gradName, students]) => {
      students.map((student) => {
        return gradList.push(student)
      })

      const obj = {}
      alphabet.forEach((char) => {
        obj[char] = students
          .filter((grad) => !!grad.firstName)
          .filter((grad) => grad.firstName.charAt(0).toUpperCase() === char)
      })
      alphabetKey[gradName] = obj
    })

    gradList.sort(function (a, b) {
      var textA = a[0]?.firstName.toUpperCase()
      var textB = b[0]?.firstName.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })
    return gradList
  }, [grads])

  require('./grads.scss')
  return (
    <div className='absolute w-full bg-almost-black flex flex-col items-center justify-start pb-20 lg:pb-0'>
      {loading && <FullScreenLoader />}
      {Countdown && <Countdown />}
      {Header && <Header />}
      <div className='w-full relative px-[16px] lg:px-[48px] max-w-[1440px] pb-16'>
        <div className='w-full xl:w-full'>
          <TextCenterDiv>
            <H1M className='text-comfort-green lg:!text-[72px]' style={{ pointerEvents: 'none' }}>
              Graduates
            </H1M>
            {isMobile ? (
              <div className='text-center pt-[16px]'>
                <H3 className='text-beige-yellow normal-case m-0' style={{ pointerEvents: 'none' }}>
                  YES! We’re innovative
                </H3>
                <H3 className='text-beige-yellow normal-case m-0' style={{ pointerEvents: 'none' }}>
                  problem solvers!
                </H3>
              </div>
            ) : (
              <H3 className='text-beige-yellow normal-case' style={{ pointerEvents: 'none' }}>
                YES! We’re innovative problem solvers!
              </H3>
            )}
          </TextCenterDiv>
          <div className='flex gap-6'>
            <DropdownFilter program={program} type={type} onChangeSearchGrads={setGradsSearch} />
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-2 w-full my-16 items-center'>
            {gradsSorted?.length === 0 ? (
              <P className='text-beige-yellow'> No graduates found.</P>
            ) : gradsSorted?.length === 1 ? (
              <P className='text-beige-yellow'>
                There is <span className='text-comfort-green'>1</span> graduate.
              </P>
            ) : gradsSorted?.length > 1 ? (
              <P className='text-beige-yellow'>
                There are <span className='text-comfort-green'>{gradsSorted?.length}</span>
                graduates.
              </P>
            ) : (
              <P className='text-beige-yellow'>Loading</P>
            )}
            <SearchContainer className='flex relative w-full items-end justify-end'>
              <input
                type='text'
                id='gname'
                name='gname'
                placeholder='Search graduates'
                className='text-almost-black bg-beige-yellow placeholder-almost-black border border-almost-black w-full lg:w-4/5 '
                onChange={(e) => setGradsSearch(e.target.value)}
              />
              <img className='absolute' src='/static/2024/svg/search.svg' alt='Search' />
            </SearchContainer>
          </div>
          <TransitionGroup className='grid grid-cols-1 lg:grid-cols-3 gap-16'>
            {gradsSorted?.length > 0 &&
              gradsSorted?.map((grad, index) => (
                <CSSTransition key={grad.id} timeout={500} classNames='item'>
                  <GradCard grad={grad} key={index} />
                </CSSTransition>
              ))}
          </TransitionGroup>
        </div>
      </div>
      <div className='relative w-full bg-almost-black flex flex-col items-center justify-center w-full'>
        <div className='relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] bg-almost-black max-w-[1440px]'>
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}
