import React, { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useLocation } from 'react-router'
import qs from 'query-string'
import cn from 'classnames'
import upperFirst from 'lodash.upperfirst'
import StickerNavs from '../StickerNavs'
import { Helmet } from 'react-helmet-async'
import ReactMarkDown from 'react-markdown'
import { Link } from 'react-router-dom'
import useScroll from '../../../hook/useScroll'
import take from 'lodash.take'
import shuffle from 'lodash.shuffle'
import { ProjectScroll } from './ProjectScroll'
import { projectType } from '../../../constant'
import Footer from '../../../components/2021/Footer'
import WorkCard from '../../../components/SimilarWorkCard'
import SearchCol from '../../../components/2021/SearchCol'
import { MobileRightNavs, MobileNavsBottom} from '../MobileRightNavs'
import { useDisclosure } from 'react-use-disclosure'

const Main = styled.div`
  cursor: url(${(props) => props.cursorUrl}), auto;
`

const Container = styled.div`
  .projectText p {
    text-align: left;
    margin: auto;
    padding-bottom: 50px;
    width: 90%;
    @media (min-width: 1024px) {
      width: 50vw;
    }
  }

  .projectText p a {
    color: #defc1c;
  }
  a {
    color: #fff;
  }
  a:hover {
    color: #5933ff;
  }
`

const CurvedContainer = styled.div`
  background: #5933ff;
  display: inline-block;
  position: relative;
  width: 100%;

  height: 400px;
  vertical-align: middle;
  overflow: hidden;
`

const TitleContent = styled.h2`
  font-size: 44px;
  font-family: 'grotesque_pro_light';
  text-align: center;
  margin: auto;
  padding-bottom: 24px;
  width: 50vw;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`
const Image = styled.img`
  width: 100%;
`

const TextLogo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 8.5rem;
  line-height: 9rem;
  letter-spacing: 0.01em;
  font-weight: 400;
`
const ProjectTitle = styled.h3`
  font-family: 'reckless_light';
`

const Y_POS_SWAP_NAVS = 240

export default function PublishedProject(props) {
  const scroll = useScroll()
  const relatedWorkRef = useRef()
  const relatedRefTop = relatedWorkRef?.current?.getBoundingClientRect()?.y
  const scrollRef = useRef()
  const scrollRefHeight = scrollRef?.current?.offsetHeight

  const { search } = useLocation()
  const { id } = qs.parse(search)

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])

  const { data } = useQuery(PUBLISHED_PROJECT, {
    variables: {
      id,
    },
  })
  const project = useMemo(() => data?.publishedProject || {}, [data])

  const [fetchSimilar, { data: projectSimilar }] = useLazyQuery(ALL_PROJECTS_QUERY, {
    variables: {
      where: {
        published: true,
        primaryProjectType: project?.primaryProjectType,
        createdAt_gt: '2020-12-31',
      },
      first: 30,
    },
  })

  useEffect(() => {
    if (!project?.primaryProjectType) {
      return
    }

    fetchSimilar()
    }, [project, fetchSimilar])

    const similarProjects = useMemo(() => {
      if (projectSimilar?.projects?.length > 0) {
        const projects = projectSimilar?.projects
        return take(shuffle(projects), 3)
      }
      return []
    }, [projectSimilar])

    const leadMember = useMemo(() => {
      const lead = project?.members?.find((member) => member.role === 'Project Lead')?.user
      return lead || project?.members?.[0]?.user || {}
    }, [project])

    const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()

    return (
      <Main cursorUrl='/static/svg/cursor-with-shadow.svg'>
        {project.title && (
          <Helmet>
            <title>YES! | {project.title}</title>
          </Helmet>
        )}

        <Container className='flex min-h-screen bg-blue  mt-40 lg:mt-0'>
          <StickerNavs
            className={cn(
              'w-96 fixed  top-0 h-screen transition-opacity duration-700 ease-in-out hidden lg:block',
              {
                'opacity-1 visible': scroll.scrollY < Y_POS_SWAP_NAVS,
                'opacity-0 invisible': scroll.scrollY >= Y_POS_SWAP_NAVS,

              },
            )}
          />
          <ProjectScroll
            className={cn('w-96 fixed transition-opacity duration-700 ease-in-out hidden lg:block', {
              'opacity-1 visible': scroll.scrollY >= Y_POS_SWAP_NAVS,
              'opacity-0 invisible': scroll.scrollY < Y_POS_SWAP_NAVS,
              fixed: relatedRefTop - scrollRefHeight >= 80,
              absolute: relatedRefTop - scrollRefHeight < 80,
            })}
            user={leadMember}
            ref={scrollRef}
          />

          <div className={cn('w-full text-white lg:flex lg:ml-96', {
            'bg-dark': !isOpenMenuNavs,
            'bg-blue': isOpenMenuNavs
          })}>
            <MobileRightNavs
              isOpenMenuNavs={isOpenMenuNavs}
              toggleMobileNavs={toggleMobileNavs}
              className='bg-blue'
            />
            {
               (window.innerWidth < 600 &&  !isOpenMenuNavs)  ?  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 320" preserveAspectRatio="xMidYMin slice"
              style={{position: 'relative', top: '3px',width: '100%', paddingBottom: '20%', height: '50px',overflow: 'visible', marginTop:'-30px', zIndex:6, backgroundColor:"#333"}}><path fill="#5933FF" fillOpacity="1" d="M0,64L120,69.3C240,75,480,85,720,122.7C960,160,1200,224,1320,256L1440,288L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z" ></path></svg>: null
            }
            <div
              className={cn('transition w-full', {
                'visible opacity-100': !isOpenMenuNavs,
                'hidden invisible opacity-0': isOpenMenuNavs,
              })}
            >
              <div className='w-full mx-auto pt-2 lg:pt-24 '>
                {project.members?.map((member) => {
                  const user = member?.user || {}
                  if(user?.image != null){
                  return (
                    <div key={user?.id}  className="pt-2">
                      <div className='flex items-center justify-center'>
                        <Link
                          to={{
                            pathname: '/2021/student',
                            search: `id=${user.id}`,
                          }}
                          className='inline-block flex items-center text-white'
                        >
                          <img
                            src={user?.image}
                            alt='member'
                            className='w-20 h-20 rounded-full mx-auto object-cover'
                            style={{ filter: 'grayscale(100%)' }}
                          />
                          <p className='border-b border-dashed border-secondary -mt-2 ml-4 text-4xl'>
                            {user?.firstName} {user?.lastName}
                          </p>
                        </Link>
                      </div>

                      <p className='text-center mt-4'>
                        {user?.primaryExpertise}
                        {user?.secondaryExpertise && `, ${user?.secondaryExpertise}`}
                      </p>
                    </div>
                  )
                }
                return null
                })}
                <ProjectTitle className='text-6xl lg:text-8xl text-center my-10 px-1 lg:px-16'>
                  {project?.title}
                </ProjectTitle>
                <div className='flex justify-center flex-wrap lg:flex-no-wrap'>
                  {['primaryProjectType', 'secondaryProjectType', 'secondaryProjectType_2'].map(
                    (key) => {
                      if (project[key]) {
                        return (
                          <div className='flex items-center mr-12' key={key}>
                            <Link
                              to={{
                                pathname: '/2021/work',
                                search: `?type=${projectType[project[key]]}`,
                              }}
                            >
                              <span className='w-3 h-3 bg-secondary rounded-full inline-block'></span>
                              <span className='inline-block ml-2 border-b border-dashed border-secondary'>
                                {projectType[project[key]] || upperFirst(project[key])}
                              </span>
                            </Link>
                          </div>
                        )
                      }
                      return null
                    },
                  )}
                </div>
                <div className='mt-16 md:px-12 xl:px-24'>
                  {project?.contentType?.map((type, i) => {
                    if (type === 'Heading') {
                      return <TitleContent key={i}>{project.contentData[i]}</TitleContent>
                    } else if (type === 'Text') {
                      return (
                        <div className='projectText' key={`contenttype-${i}`}>
                          <ReactMarkDown key={i} children={project.contentData[i]} />
                        </div>
                      )
                    } else if (type === 'Image') {
                      return (
                        <div key={i}>
                          <Image
                            src={project.contentData[i]}
                            alt='Image Preview'
                            className='pb-4 lg:pb-12'
                            // onClick={() => handleImgLightbox(project.contentData[i])}
                          />
                        </div>
                      )
                    } else if (type === 'Video') {
                      return (
                        <div key={i} className='w-full mx-auto pb-4 lg:pb-12'>
                          <video controls className='w-full mx-auto'>
                            <source type='video/mp4' src={project.contentData[i]} />
                          </video>
                        </div>
                      )
                    }
                    return null
                  })}
                </div>
              </div>
              <div className='w-12 bg-blue hidden lg:block'>
                {/* <img src='/static/svg/glass.svg' alt='glass' className={cn('cursor-pointer')} /> */}
              </div>
            </div>
            <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
          </div>
        </Container>
        <div className={cn('bg-blue', {
          'hidden': isOpenMenuNavs,
        })}>
          <TextLogo className='text-center text-secondary py-12' ref={relatedWorkRef}>
            Related work
          </TextLogo>
          <div className='flex text-white justify-between flex-wrap bg-dark'>
            {similarProjects?.map((_project, idx) => {
              if (idx !== 2) {
                return (
                  <div key={_project.id} className='w-1/2'>
                    {<WorkCard project={_project} key={_project.id} />}
                  </div>
                )
              }
              return (
                <div key={_project.id} className='w-full grid grid-cols-3'>
                  <div></div>
                  {<WorkCard project={_project} key={_project.id} className='w-1/2' />}
                  <div></div>
                </div>
              )
            })}
            <CurvedContainer>
              <svg viewBox='0 0 500 500' preserveAspectRatio='xMinYMin meet'>
                <path
                  d='M0,50 C150,100 350,0 500,10 L500,00 L0,0 Z'
                  style={{ keyStroke: 'none', fill: '#333' }}
                ></path>
              </svg>
            </CurvedContainer>
          </div>
        </div>
        <Footer />
        <SearchCol className='hidden lg:block' />
      </Main>
    )
  }

const PUBLISHED_PROJECT = gql`
  query PUBLISHED_PROJECT($id: ID!) {
    publishedProject(id: $id) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        role
        user {
          id
          firstName
          lastName
          image
          tagline
          program
          contactEmail
          primaryExpertise
          secondaryExpertise
          about
          image
          portfolio
          behance
          linkedIn
          instagram
          twitter
          facebook
          dribble
          youtube
          vimeo
          medium
        }
      }
      published
    }
  }
`

const ALL_PROJECTS_QUERY = gql`
  query ALL_PROJECTS_QUERY($where: ProjectWhereInput, $skip: Int = 0, $first: Int = 24) {
    projects(where: $where, first: $first, skip: $skip, orderBy: createdAt_DESC) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
    }
  }
`
