import styled, { keyframes } from "styled-components";

const loading = keyframes`
  from {
    background-position: 0 0;
    /* rotate: 0; */
  }

  to {
    background-position: 100% 100%;
    /* rotate: 360deg; */
  }
`;

const ProjectForm = styled.form`
  padding: 20px !important;
  font-size: 1.5rem !important;
  line-height: 1.5 !important;
  font-weight: normal !important;
  margin: 50px 5vw 0 !important;
  font-family: 'grotesque_pro_light' !important;
  color: #B5B5B5 !important;
  font-size: 24px !important;

  label {
    color: #b5b5b5 !important;
    display: block !important;
    font-weight: 300 !important;
    margin-bottom: 1rem !important;
  }

  input,
  textarea,
  select {
    height: 50px !important;
    width: 100% !important;
    padding: 0.5rem 1.5rem !important;
    font-size: 24px !important;
    font-weight: normal !important;
    color: #000000 !important;
    border: 1px solid #B5B5B5 !important;
    
    &::placeholder {
      color: #b5b5b5 !important;
      opacity: 1 !important;
    }

    &:focus {
      outline: 0 !important;
      border-color: ${props => props.theme.pink} !important;
    }
  }

  textarea {
    height: 200px !important;
  }

  button,
  input[type="submit"] {
    width: 100%;
    background: ${props => props.theme.pink} !important;
    color: ${props => props.theme.black} !important;
    border: 0 !important;
    font-family: 'grotesque_pro_light' !important;
    font-size: 2rem !important;
    font-weight: 600 !important;
    padding: 0.5rem 2.2rem !important;
    margin-top: 5rem !important;
    margin-bottom: 3rem !important;
    border-radius: 3rem !important;

    @media (min-width: 768px) {
      font-size: 2.4rem !important;
      font-weight: 300 !important;
      min-width: 180px !important;
      padding: 0.75rem 1.5rem !important;
      text-transform: uppercase !important;
    }
  }

  fieldset {
    border: 0 !important;
    padding: 0 !important;
/* 
    &[disabled] {
      opacity: 0.5;
    }
    &::before {
      height: 10px;
      content: "";
      display: block;
      background-image: linear-gradient(
        to right,
        #f7d8e0 0%,
        #000000 50%,
        #f7d8e0 100%
      );
    }
    &[aria-busy="true"]::before {
      background-size: 50% auto;
      animation: ${loading} 0.5s linear infinite;
    } */
  }
`;

export default ProjectForm;
