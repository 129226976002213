import * as React from 'react'
import { useEffect, useRef } from 'react'
import { P } from '../../styles/2024/TextStyles'

const bgColorList = ['bg-warm-orange', 'bg-friendly-yellow', 'bg-comfort-green']

const scrollBarClasses = ['green-scrollbar', 'blue-scrollbar']

const LetterBox = ({ letter, index }) => {
  const arrIndex = index % 3
  const messageRef = useRef(null)
  const bgColor = bgColorList[arrIndex]
  const scrollBarIndex = arrIndex === 1 ? 0 : 1
  const scrollBarClass = scrollBarClasses[scrollBarIndex]
  const { sender, recipient, message } = letter

  useEffect(() => {
    // You can control the scrollbar with messageRef here if needed
  }, []) // Empty array ensures effect only runs on mount

  const _message = message.replace(/^\s+|\s+$/g, '');

  require('./scrollbar.scss')
  return (
    <div
      className={`relative flex flex-col justify-between items-start  self-strecth h-[318px] flex-[1_0_0] p-[24px] ${bgColor}`}
    >
      <div className='flex flex-col items-start self-stretch gap-[10px]'>
        <P className='text-almost-black'>To: {recipient}</P>
      </div>

      <div
        className={`flex relative items-start flex-[1_0_0] ${scrollBarClass}`}
        ref={messageRef}
        style={{ overflowX: 'hidden', overflowY: 'auto'}}
      >
        <div className='pr-4'>
          <P className='flex flex-wrap text-almost-black lg:max-w-[250px] break-word'>{_message}</P>
        </div>
      </div>
      <div className='flex flex-col items-end self-stretch gap-[10px]'>
        <P className='text-almost-black'>From: {sender}</P>
      </div>
    </div>
  )
}

export default LetterBox
