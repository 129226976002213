import { gql } from '@apollo/client'

export const PUBLIC_PROJECTS_QUERY = gql`
  query PUBLIC_PROJECTS_QUERY($where: ProjectWhereInput, $gradsSearch: String) {
    projects(where: $where, gradsSearch: $gradsSearch) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        user {
          id
          program
          firstName
          lastName
        }
      }
    }
  }
`

export const PROJECTS_PAGE_QUERY = gql`
  query PUBLIC_PROJECTS_QUERY($where: ProjectWhereInput, $gradsSearch: String, $skip: Int, $first: Int) {
    projects(where: $where, gradsSearch: $gradsSearch, skip: $skip, first: $first) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        user {
          id
          program
          firstName
          lastName
        }
      }
    }
  }
`

export const GET_TOTAL_PROJECT_COUNT = gql`
  query GET_TOTAL_PROJECT_COUNT($where: ProjectWhereInput, $gradsSearch: String) {
    projectsConnection(where: $where, gradsSearch: $gradsSearch) {
      aggregate {
        count
      }
    }
  }
`

// export const GET_TOTAL_PROJECT_COUNT = gql`
//   query GET_TOTAL_PROJECT_COUNT {
//     projectsConnection(where: {
//       createdAt_gt: "2022-12-31"
//       createdAt_lt: "2024-01-01"
//     }) {
//       aggregate {
//         count
//       }
//     }
//   }
// `
