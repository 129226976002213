import styled from 'styled-components'
import cursorSVG from '../../pages/2023/cursor-with-shadow-2023.svg'

export const Container = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&display=swap');
  @import url('https://fonts.cdnfonts.com/css/basis-grotesque-pro');
  position: relative;
  height: 9418px;
  background-color: #26439b;
  display: flex;
  flex: 1;
  width: 100%;
  cursor: url(${cursorSVG}), auto;

  @media (max-width: 1024px) {
  }
  @media (max-width: 720px) {
    overflow: hidden;
    height: 6980px;
  }
  @media (max-width: 480px) {
  }
`

export const TextCenterTop = styled.div`
  position: relative;
  h2,
  h3 {
    font-family: 'grotesque_pro_light';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 18px;
  }
`
export const EmailSignUpCenter = styled.div`
  position: relative;
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'grotesque_pro_light';
    font-size: 2rem;
  }
  #email {
    font-family: 'grotesque_pro_light';
    width: calc(100vw - 40px);
    height: 60px;
    border-radius: 30px;
    padding: 5px 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 3px solid ${(props) => (props.hasError ? 'red' : '#71DF71')};
    display: ${(props) => (props.submitted ? 'none' : 'block')};
  }

  #email:hover {
    border: 3px solid #ff8a48;
  }
  #email:focus {
    outline: none;
    border: 3px solid #ff8a48;
  }
  #submit {
    width: calc(100vw - 40px);
    height: 60px;
    border-radius: 30px;
    font-family: 'grotesque_pro_light';
    font-weight: 100;
    cursor: pointer;
    display: ${(props) => (props.submitted ? 'none' : 'block')};
  }
  #submit:hover {
    border: 3px solid #fa8975;
  }
  #inline-error {
    color: white;
    margin-top: 10px;
    display: ${(props) => (props.hasError ? 'block' : 'none')};
  }
  #submitted {
    color: white;
    margin-top: 10px;
    display: ${(props) => (props.submitted ? 'block' : 'none')};
  }

  @media (min-width: 640px) {
    #email {
      width: 480px;
    }
    #submit {
      width: 480px;
    }
  }
`

export const CountdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const CountdownTimer = styled.p`
  font-family: 'sharpgrotesque20';
  font-size: 56px;
  line-height: 56px;
  font-weight: 900;

  @media (min-width: 1026px) {
    font-size: 72px;
    line-height: 72px;
  }
`
export const CountdownTitle = styled.h1`
  font-family: 'sharpgrotesque25';
  font-size: 96px;
  line-height: 96px;
  font-weight: 900;

  @media (min-width: 1026px) {
    font-size: 128px;
    line-height: 128px;
  }
`

export const AlmostBlackTitle = styled.h2`
  font-family: 'sharpgrotesque20';
  font-size: 40px;
  line-height: 40px;
`

export const CalendarBody = styled.p`
  font-family: 'grotesque_pro_light';
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  @media (min-width: 1026px) {
    font-size: 18px;
    line-height: 30px;
  }
`

export const StayTuned = styled.h1`
  font-family: 'sharpgrotesque25';
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
  @media (min-width: 768px) {
    font-size: 64px;
  }

  @media (min-width: 1280px) {
    font-size: 128px;
  }
`
