import React, { Component, Suspense } from 'react'
import { ThemeProvider } from 'styled-components'
import Meta from './components/2020/Meta'
import Footer from './components/2020/Footer'
import { Switch, Route, withRouter } from 'react-router-dom'
import { Events, scroller } from 'react-scroll'
import { NavProvider } from './components/2020/states/nav'
import Notfound from './pages/Notfound'
import ColoredScrollbars from './components/2021/ColoredScrollbars'
import ColoredScrollbars2022 from './components/2022/ColoredScrollbars2022'
import ColoredScrollbars2023 from './components/2023/ColoredScrollbars2023'
import ColoredScrollbars2024 from './components/2024/ColoredScrollbars2024'
import { getRoutes2020 } from './routes/routes2020'
import { getRoutes2021 } from './routes/routes2021'
import { getRoutes2022 } from './routes/routes2022'
import { getRoutes2023 } from './routes/routes2023'
import { getRoutes2024 } from './routes/routes2024'
import { getRoutesCMS } from './routes/routesCMS'
import { Teaser as Teaser24 } from './pages/2024'
import UserNav from './components/CMS/UserNav'
import { theme, StyledPage, GlobalStyle, StyledDiv } from './styles/AppStyles'
import Header from './components/2020/Header'
import FullScreenLoader from './components/2024/FullScreenLoader'
import { isMobile } from 'react-device-detect'
import { Home as Home24 } from './pages/2024'
import Archive from './components/2024/Archive'
class Page extends Component {
  constructor(props) {
    super(props)
    this.scrollbars = React.createRef()
    this.getScrollTop = this.getScrollTop.bind(this)
  }

  state = {
    marquee: true,
  }

  marqueeShow() {
    this.setState({
      marque: true,
    })
  }

  marqueeHide() {
    this.setState({
      marque: false,
    })
  }

  isRenderHeaderAndFooter = () => {
    const allowdUrls = [
      '/2020',
      '/2020/login',
      '/2020/signup',
      '/2020/edit',
      '/2020/grads',
      '/2020/work',
      '/2020/add',
      '/2020/project',
      '/2020/preview',
      '/2020/student',
      '/2020/update',
    ]
    const current = this.props.location.pathname
    return allowdUrls.includes(current)
  }

  scrollTo = () => {
    scroller.scrollTo('scroll-to-element', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    })
  }

  scrollToWithContainer() {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register('end', () => {
        resolve()
        Events.scrollEvent.remove('end')
      })

      scroller.scrollTo('scroll-container', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      })
    })

    goToContainer.then(() =>
      /* eslint-disable */
      scroller.scrollTo('scroll-container-second-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container',
      }),
    )
  }

  getScrollTop() {
    this.scrollbars.getScrollTop()
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin')
    Events.scrollEvent.remove('end')
  }

  isNoPadding = () => {
    const { pathname } = this.props?.location || {}
    if (pathname.startsWith('/2020')) {
      return false
    }
    return true
  }

  render() {
    const renderFooter = this.isRenderHeaderAndFooter()
    const noPadding = this.isNoPadding()
    const { pathname } = this.props.location

    return (
      <ThemeProvider theme={theme}>
        <NavProvider>
          <StyledPage className='supports-[height:100cqh]:h-[100cqh] supports-[height:100svh]:h-[100svh]'>
            <Meta />
            {pathname.startsWith('/2020') && (
              <Header marquee={this.state.marquee} shouldRenderNav={renderFooter} />
            )}

            <StyledDiv withPadding={renderFooter} noPadding={noPadding}>
              <Switch>
                {pathname.startsWith('/login') ||
                pathname.startsWith('/projects') ||
                pathname.startsWith('/add') ||
                pathname.startsWith('/preview') ||
                pathname.startsWith('/edit') ||
                pathname.startsWith('/update') ||
                pathname.startsWith('/new-password') ||
                pathname.startsWith('/forgotpassword') ? (
                  <Suspense fallback={<FullScreenLoader />}>
                    <UserNav />
                    <div
                      className='w-full relative flex items-start justify-center min-safe-h-screen overflow-scroll'
                      style={{ width: '100%', height: '100vh' }}
                    >
                      {getRoutesCMS(this.getScrollTop)}
                    </div>
                  </Suspense>
                ) : pathname.startsWith('/2020') ? (
                  <Suspense fallback={<FullScreenLoader />}>{getRoutes2020()}</Suspense>
                ) : pathname.startsWith('/2021') ? (
                  <Suspense fallback={<FullScreenLoader />}>
                    <ColoredScrollbars
                      style={{ width: '100%', height: '100vh', background: '#000000' }}
                      ref={this.scrollbars}
                      id=''
                    >
                      {getRoutes2021()}
                    </ColoredScrollbars>
                  </Suspense>
                ) : pathname.startsWith('/2022') ? (
                  <Suspense fallback={<FullScreenLoader />}>
                    <ColoredScrollbars2022
                      style={{ width: '100%', minHeight: '100vh', background: '#e3e3de' }}
                      ref={this.scrollbars}
                    >
                      {getRoutes2022()}
                    </ColoredScrollbars2022>
                  </Suspense>
                ) : pathname.startsWith('/2023') ? (
                  <Suspense fallback={<FullScreenLoader />}>
                    <ColoredScrollbars2023
                      style={{ width: '100%', height: '100vh', background: '#F0EEE9' }}
                      ref={this.scrollbars}
                      id=''
                    >
                      {getRoutes2023()}
                    </ColoredScrollbars2023>
                  </Suspense>
                ) : pathname.startsWith('/about') ||
                  pathname.startsWith('/teaser') ||
                  pathname.startsWith('/events') ||
                  pathname.startsWith('/home') ||
                  pathname.startsWith('/show') ||
                  pathname.startsWith('/work') ||
                  pathname.startsWith('/grads') ||
                  pathname.startsWith('/student') ||
                  pathname.startsWith('/project') ||
                  pathname.startsWith('/search') ||
                  pathname.startsWith('/letters') ||
                  pathname.startsWith('/2024') ? (
                  <Suspense fallback={<FullScreenLoader />}>
                    <ColoredScrollbars2024
                      className='w-full bg-almost-black relative flex items-center justify-center min-safe-h-screen overflow-hidden'
                      ref={this.scrollbars}
                      id=''
                    >
                      {getRoutes2024(this.getScrollTop)}
                      <Archive />
                    </ColoredScrollbars2024>
                  </Suspense>
                ) : pathname.length == 1 ? (
                  <Suspense fallback={<FullScreenLoader />}>
                    <ColoredScrollbars2024
                      className='w-full bg-almost-black relative flex items-center justify-center min-safe-h-screen overflow-hidden'
                      ref={this.scrollbars}
                      id=''
                    >
                      <Route
                        key='mainHome'
                        exact
                        path='/'
                        render={(props) => <Home24 {...props} getScrollTop={this.getScrollTop} />}
                      />
                      <Archive />
                    </ColoredScrollbars2024>
                  </Suspense>
                ) : (
                  <Route path='*' component={Notfound} />
                )}
              </Switch>
            </StyledDiv>
            {renderFooter && <Footer />}
          </StyledPage>
        </NavProvider>
        <GlobalStyle />
      </ThemeProvider>
    )
  }
}

export default withRouter(Page)
