import React from 'react';
import { Route } from 'react-router-dom';

import {
    Show as Show2021,
    Teaser as Teaser2021,
    Homepage as Homepage2021,
    Work as Work2021,
    Grads as Grads2021,
    Profile as Profile2021,
    PublishedProject as PublishedProject2021,
    EventsComp as EventsComp2021,
    Search as Search2021,
} from '../pages/2021'

export const getRoutes2021 = () => [
    <Route key="Homepage2021" exact path="/2021" component={Homepage2021}/>,
    <Route key="Show2021" exact path="/2021/show" component={Show2021}/>,
    <Route key="Teaser2021" exact path="/2021/teaser" component={Teaser2021}/>,
    <Route key="Work2021" exact path="/2021/work" component={Work2021}/>,
    <Route key="Grads2021" exact path="/2021/grads" component={Grads2021}/>,
    <Route key="Profile2021" exact path="/2021/student" component={Profile2021}/>,
    <Route key="PublishedProject2021" exact path="/2021/project" component={PublishedProject2021}/>,
    <Route key="EventsComp2021" exact path="/2021/events" component={EventsComp2021}/>,
    <Route key="Search2021" exact path="/2021/search" component={Search2021}/>,
];