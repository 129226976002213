import {gql} from '@apollo/client'

export const LETTERS_QUERY = gql`
  query LETTERS_QUERY($where: LetterWhereInput) {
    letters(where: $where) {
      sender
      recipient
      message
    }
  }
`