import React from "react";
import styled from "styled-components";

const MovingImage = styled.img`
  width: 100%;
  position: relative;
  z-index: -1;
`;

const MovingDiv = styled.div`
  transition: transform 0.01s;
  width: 15vw;
`;

class Move extends React.Component {

  render() {
    const {
      x,
      y,
      posx,
      posy,
      directionX,
      directionY,
      logoSrc,
      rotate,
    } = this.props;
    const styles = {
      position: "absolute",
      left: `${posx}vw`,
      top: `${posy}vh`,
      transform: `translate3d(${(x / 20 - posx / 20) * directionX}px, ${(y / 20 - posy / 20) * directionY}px, 0)`
    };
    const imgStyles = {
      transform: `rotate(${rotate}deg)`
    };
    return (
      <MovingDiv style={styles}>
        <MovingImage src={logoSrc} style={imgStyles} alt="logo" />
      </MovingDiv>
    );
  }
}

export default Move;
