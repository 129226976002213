import styled from 'styled-components'

export const TextLogo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 8.5rem;
  line-height: 9rem;
  letter-spacing: 0.01em;
  font-weight: 400;
  color: #ff6044;
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`
export const TextCenter = styled.h4`
  color: #ff6044;
  font-family: 'sharp_grotesque_05';
  font-size: 420px;
  line-height: 360px;
  letter-spacing: 0.01em;
  font-weight: 400;
  @media (min-width: 360px) {
    font-size: 460px;
    line-height: 400px;
  }
  @media (min-width: 768px) {
    font-size: 550px;
    line-height: 550px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 600px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 700px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 1000px;
    line-height: 1200px;
  }
  @media (min-width: 2880px) {
    font-size: 1400px;
    line-height: 1400px;
  }
`
export const Sticker4Days = styled.img`
  top: 0.8%;
  left: 29%;
  cursor: inherit;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  @media (min-width: 1024px) {
    top: 1%;
    left: 30%;
  }
  @media (min-width: 1440px) {
    left: 33%;
  }
  @media (min-width: 1920px) {
    left: 35%;
  }
  @media (min-width: 2400px) {
    left: 40%;
  }
`
export const StickerMay = styled.img`
  top: 1.2%;
  right: 10%;
  cursor: inherit;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  @media (min-width: 768px) {
    top: 1.5%;
    right: 24%;
  }
  @media (min-width: 1024px) {
    top: 2.2%;
    right: 25%;
  }
  @media (min-width: 1280px) {
    top: 2.4%;
    right: 26%;
  }
  @media (min-width: 1600px) {
    top: 2.8%;
    right: 30%;
  }
  @media (min-width: 2400px) {
    right: 35%;
  }
`
export const StickersWrapper = styled.div`
  top: ${(props) => props.top};
  left: ${(props) => props.imgLeft || 28}px;
  min-width: 160px;
  min-height: 480px;
`
export const StickerContainer = styled.div`
  top: ${(props) => props.top};
  left: ${(props) => props.imgLeft || 28}px;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'inherit')};
  img {
    width: ${(props) => props.imgWidth || 84}px;
    // -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    // filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  }
  svg {
    // -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    // filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  }
  p {
    position: absolute;
    top: calc(${(props) => props.top} + 16px);
    font-family: Reckless;
    transform: rotate(${(props) => props.textRotate}deg);
  }
`
export const TextCenterTop = styled.div`
  h2,
  h3 {
    font-family: 'grotesque_pro_light';
    font-weight: 200;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.05rem;
  }
  // bottom: 4%;
  // top : 0;
  @media (min-width: 768px) {
    // bottom: 0%;
  }
  @media (min-width: 1600px) {
    // bottom: 2%;
  }
`
export const EmailSignUpCenter = styled.div`
  form {
    height: 14vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    font-family: 'grotesque_pro_light';
    font-size: 1.8rem;
  }
  #email {
    color: #191919;
    width: 401px;
    height: 60px;
    border-radius: 30px;
    padding: 5px 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 3px solid ${(props) => (props.hasError ? 'red' : '#191919')};
    display: ${(props) => (props.submitted ? 'none' : 'block')};
  }
  #email:hover {
    border: 3px solid red;
  }
  #email:focus {
    outline: none;
    border: 3px solid red;
  }
  #submit {
    width: 401px;
    height: 60px;
    background-color: #ff6044;
    color: #191919;
    border-radius: 30px;
    display: ${(props) => (props.submitted ? 'none' : 'block')};
  }
  #submit:hover {
    border: 3px solid #fa8975;
  }
  #inline-error {
    color: white;
    margin-top: 10px;
    display: ${(props) => (props.hasError ? 'block' : 'none')};
  }
  #submitted {
    color: white;
    margin-top: 10px;
    display: ${(props) => (props.submitted ? 'block' : 'none')};
  }
  @media (min-width: 300px) {
    top: 130%;
    form {
      height: 30vh;
      margin-bottom: 7%;
    }
    #email {
      width: 250px;
    }
    #submit {
      width: 250px;
    }
  }
  @media (min-width: 480px) {
    top: 130%;
    form {
      height: 20vh;
      margin-bottom: 7%;
    }
    #email {
      width: 401px;
    }
    #submit {
      width: 401px;
    }
  }
  @media (min-width: 768px) {
    top: 120%;
    form {
      height: 27vh;
      margin-bottom: 7%;
    }
  }
  @media (min-width: 1280px) {
    top: 114%;
    form {
      min-height: 23vh;
      margin-bottom: 7%;
    }
  }
  @media (min-width: 1440px) {
    top: 112%;
    form {
      min-height: 19vh;
      margin-bottom: 7%;
    }
  }
  @media (min-width: 1920px) {
    top: 107%;
    form {
      min-height: 18vh;
      margin-bottom: 7%;
    }
  }
  @media (min-width: 2880px) {
    top: 92%;
    form {
      min-height: 10vh;
    }
  }
`
export const TextCenterDescription = styled.h4`
  color: #191919;
  font-family: 'sharp_grotesque_15';
  font-size: 150px;
  line-height: 150px;
  letter-spacing: 0.01em;
  font-weight: 400;
  padding-top: 100px;
  text-align: center;
  @media (min-width: 360px) {
    font-size: 150px;
    line-height: 150px;
  }
  @media (min-width: 768px) {
    font-size: 350px;
    line-height: 350px;
  }
  @media (min-width: 1280px) {
    font-size: 400px;
    line-height: 400px;
  }
  @media (min-width: 1680px) {
    font-size: 400px;
    line-height: 400px;
  }
  @media (min-width: 1920px) {
    font-size: 500px;
    line-height: 500px;
  }
  @media (min-width: 2560px) {
    font-size: 500px;
    line-height: 500px;
  }
  @media (min-width: 2880px) {
    font-size: 6000px;
    line-height: 600px;
  }
  @media (min-width: 2880px) {
    font-size: 700px;
    line-height: 700px;
  }
`
export const DescriptionSubtitle = styled.div`
  width: 80vw;
  @media (min-width: 768px) {
    width: 80vw;
  }
  margin: auto;
  padding-top: 4vh;
  padding-bottom: 10vh;
  p {
    font-family: 'grotesque_pro_light';
    font-size: 22px;
    color: #191919;
    text-align: left;
  }
  @media (max-width: 411px), (max-height: 700px) {
    height: 0vh;
  }
  @media (min-width: 500px) {
    padding-top: 10vh;
    p {
      font-size: 24px;
    }
  }
`
export const DescriptionTitle = styled.div`
  padding-top: 20px;
  text-align: center;
  width: 80vw;
  p {
    font-family: 'sharp_grotesque_15';
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    letter-spacing: normal;
    font-size: 42px;
    line-height: normal;
    color: #191919;
  }
`
export const Sticker1 = styled.img`
  position: relative;
  display: inline;
  top: 10%;
  right: 10%;
  width: 15%;
  @media (min-width: 768px) {
    width: 5%;
  }
`
export const Sticker2 = styled.img`
  position: relative;
  display: inline;
  top: 10%;
  right: 20%;
  width: 17%;
  @media (min-width: 768px) {
    width: 7%;
  }
`
export const Sticker3 = styled.img`
  position: relative;
  display: inline;
  top: 20%;
  right: 30%;
  width: 18%;
  @media (min-width: 768px) {
    width: 8%;
  }
`
export const Sticker4 = styled.img`
  position: relative;
  display: inline;
  top: 3%;
  right: 13%;
  width: 20%;
  @media (min-width: 768px) {
    width: 15%;
  }
`
export const Sticker6 = styled.img`
  position: relative;
  display: inline;
  top: 23%;
  right: 10%;
  width: 19%;
  @media (min-width: 768px) {
    width: 9%;
  }
`
export const Sticker7 = styled.img`
  position: relative;
  display: inline;
  top: 20%;
  right: 23%;
  width: 20%;
  @media (min-width: 768px) {
    width: 10%;
  }
`
export const Sticker5 = styled.img`
  position: relative;
  display: inline;
  top: 17%;
  right: 5%;
  width: 19%;
  @media (min-width: 768px) {
    width: 9%;
  }
`
export const Sticker8 = styled.img`
  position: relative;
  display: inline;
  top: 30%;
  right: 12%;
  width: 17%;
  @media (min-width: 768px) {
    width: 7%;
  }
`
export const Sticker9 = styled.img`
  position: relative;
  display: inline;
  top: 20%;
  right: 20%;
  width: 25%;
  @media (min-width: 768px) {
    width: 20%;
  }
`
export const Sticker10 = styled.img`
  position: relative;
  display: inline;
  top: 40%;
  right: 10%;
  width: 15%;
  @media (min-width: 768px) {
    width: 5%;
  }
`
export const Sticker11 = styled.img`
  position: relative;
  display: inline;
  top: 10%;
  right: 10%;
  width: 40%;
  @media (min-width: 768px) {
    width: 40%;
  }
`
export const Sticker12 = styled.img`
  position: relative;
  display: inline;
  top: 50%;
  right: 20%;
  width: 25%;
  @media (min-width: 768px) {
    width: 15%;
  }
`
export const Sticker15 = styled.img`
  position: relative;
  display: inline;
  top: 10%;
  right: -50%;
  width: 12%;
  @media (min-width: 768px) {
    width: 2%;
  }
`
export const StickerPlay = styled.img`
  position: relative;
  display: inline;
  top: 10%;
  right: -3%;
  width: 19%;
  @media (min-width: 768px) {
    width: 9%;
  }
`
export const TitleLeft = styled.h5`
  color: #ff6044;
  font-family: 'sharp_grotesque_05';
  font-size: 250px;
  line-height: 250px;
  letter-spacing: 0.01em;
  font-weight: 900;
  padding-left: 0px;
  @media (min-width: 360px) {
    font-size: 380px;
    line-height: 380px;
  }
  @media (min-width: 768px) {
    font-size: 650px;
    line-height: 650px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 700px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 800px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 900px;
    line-height: 900px;
  }
`
export const TitleWrapper = styled.div`
  left: ${(props) => props.imgLeft || 28}px;
  min-width: 160px;
  min-height: 480px;
`
export const TitleEvents = styled.h5`
  color: #ff6044;
  font-family: 'sharp_grotesque_05';
  font-size: 400px;
  letter-spacing: 0.01em;
  font-weight: 900;
  padding-left: 12px;
  @media (min-width: 360px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 1280px) {
  }
  @media (min-width: 1680px) {
  }
  @media (min-width: 1920px) {
  }
  @media (min-width: 2560px) {
  }
  @media (min-width: 2880px) {
  }
  @media (min-width: 2880px) {
  }
`
export const ProgramItem = styled.div`
  width: max-content;
  height: 0.7em;
  :hover {
    background-color: ${(props) => props.theme.orange2022};
  }
  .spanCont {
    display: block;
  }
  .dot {
    height: 12px;
    width: 12px;
    background-color: #ff6044;
    border-radius: 50%;
    margin: 8px 10px;
    display: inline-block;
  }
  .programName {
    border-style: dotted;
    border-bottom: 2px dotted ${(props) => props.theme.orange2022};
  }
`
export const EventDiv = styled.div`
  padding: 2%;
  display: inline-block;
  height: 100%;
  vertical-align: top;

  .title {
    color: white;
    font-size: 24px;
    font-family: 'reckless_light';
    margin-top: 8%;
    text-align: left;
    line-height: 1.4;
  }
  .time {
    color: white;
    text-align: left;
    font-size: 18px;
    display: inline-block;
    font-family: 'grotesque_pro_light';
  }
  .yellow {
    color: #defc1c;
    font-size: 18px;
    display: inline-block;
    font-family: 'grotesque_pro_light';
    margin-left: 2%;
  }
  .desc {
    text-align: left;
    margin-top: 2%;
    color: white;
    font-size: 18px;
    height: 200px;
    line-height: 1.6;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp: 10;
    // -webkit-box-orient: vertical;
  }
  button {
    color: #ff6044;
    outline: none;
    width: 100%;
    height: 90px;
  }
`
export const HeadContainer = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  background-color: #ff6044;
  border-radius: 50%;
  img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 234px;
    height: 234px;
    object-fit: cover;
    filter: grayscale(100%);
    border-radius: 50%;
  }
  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 235px;
    height: 235px;
    background-color: #000;
    filter: invert(37%) sepia(17%) saturate(35%) hue-rotate(321deg) brightness(56%) contrast(113%);
    // filter: invert(37%) sepia(87%) saturate(435%) hue-rotate(321deg) brightness(116%) contrast(113%);
    opacity: 0.5;
    border-radius: 50%;
    z-index: 1;
  }
  @media (max-width: 1024px) {
    left: -webkit-calc(50% - 125px);
    left: -moz-calc(50% - 125px);
    left: calc(50% - 125px);
  }
`
export const GradsFlexCont = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`
export const GradsFlexTitle = styled.div`
  flex: 1;
  text-align: center;
  background: #e3e3de;
`

export const GradsFlexList = styled.div`
  flex: 1;
  padding-top: 1%;
  @media (min-width: 1024px) {
    padding-top: 6%;
  }
`

export const FooterCont = styled.div`
  overflow: hidden;
  flex: none;
  minwidth: 10vh;
  minheight: 10vh;
  height: 100vh;
  font-size: 1em;
  @media (min-width: 768px) {
    height: 100vh;
  }
  @media (min-width: 1024px) {
    height: 100vh;
  }
  @media (min-width: 1280px) {
    height: 100vh;
  }
  @media (min-width: 1600px) {
    height: 100vh;
  }
  @media (min-width: 2400px) {
    height: 100vh;
  }
`

export const FooterContainer = styled.div`
  height: 120vh;
  position: relative;
  //bottom: -200px;
  @media (min-width: 768px) {
    height: 150vh;
    //bottom: -200px;
  }
  @media (min-width: 1024px) {
    //bottom: -300px;
    height: 120vh;
  }
  @media (min-width: 1280px) {
    height: 110vh;
  }
  @media (min-width: 2560px) {
    height: 90vh;
  }
`
