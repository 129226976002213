import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useLazyQuery } from '@apollo/client'
import qs from 'query-string'
import shuffle from 'lodash.shuffle'
import { useDebounce } from 'use-debounce'
import cn from 'classnames'
import { QUERY } from '../../../graphql'
import { InputSearch } from './InputSearch'
import { GradCard } from '../../../components/2024/GradCard'
import { H1M, H4 } from '../../../styles/2024/TextStyles'
import { ProjectCard } from '../../../components/2024/ProjectCard'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { SpringContext } from '../../../context/SpringContext'
const Header = React.lazy(() => import('../../../components/2024/Header'))
const Countdown = React.lazy(() => import('../../../components/2024/Countdown'))
const Footer = React.lazy(() => import('../../../components/2024/Footer'))

export const TextCenterDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 120px 0 0px 0;
`

export default function Search(props) {
  const history = useHistory()
  const location = useLocation()
  const { search: searchParams } = location
  const { keyword } = qs.parse(searchParams)
  const [searchInput, setSearchInput] = useState(keyword || '')
  const [searchInputDebounce] = useDebounce(searchInput, 800)
  const spring = useContext(SpringContext)

  useEffect(() => {
    if (spring!=null && spring!==undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring])

  const [fetchSearch, { data: searchData, loading }] = useLazyQuery(QUERY.SEARCH_QUERY, {
    variables: {
      q: searchInputDebounce,
      year: 2024,
    },
  })

  const onInputChange = useCallback((e) => {
    const val = e.target.value
    setSearchInput(val)
  }, [])

  const onSearchClick = (() => {
    const val = searchInput
    setSearchInput(val)
  })

  useEffect(() => {
    if (!searchInputDebounce || searchInputDebounce?.length === 0) {
      history.replace('/search')
      return
    }
    history.push({ search: `?keyword=${searchInputDebounce}` })
  }, [searchInputDebounce, history])

  useEffect(() => {
    fetchSearch()
  }, [keyword, fetchSearch])

  const results = useMemo(() => {
    const users = searchData?.searchGradWork?.users || []
    const projects = searchData?.searchGradWork?.projects || []
    return shuffle([...users, ...projects])
  }, [searchData])

  // const renderResult = useMemo(() => {
  //   if (results?.length === 0) {
  //     return (
  //       <div className='w-full mt-4'>
  //         <p className='text-4xl text-center text-mad-blue'>No results found, try again!</p>
  //       </div>
  //     )
  //   }

  //   const rows = results.map((result) => {
  //     if (result.__typename === 'User') {
  //       return <GradCard key={result.id} grad={result} />
  //     } else if (result.__typename === 'Project') {
  //       return <ProjectCard project={result} key={result.id} />
  //     }
  //     return null
  //   })
  //   return rows
  // }, [results])

  require('./styles.scss')

  return (
    <div className='absolute w-full bg-almost-black flex flex-col items-center justify-start pb-20 lg:pb-0'>
      {Countdown && <Countdown />}
      {Header && <Header />}
      <div className='relative px-[16px] lg:px-[48px] max-w-[1440px] pb-16'>
        <div className='w-full xl:w-full '>
          <TextCenterDiv>
            <H1M className='text-comfort-green lg:!text-[72px]' style={{ pointerEvents: 'none' }}>
              Search
            </H1M>
          </TextCenterDiv>
          <div className='text-center relative my-20'>
            <InputSearch
              type='text'
              id='gname'
              name='gname'
              placeholder='Search graduates'
              className='text-almost-black bg-beige-yellow placeholder-almost-black border border-almost-black w-full lg:w-4/5 '
              onChange={(e) => onInputChange(e)}
              onSearchClick={(e) => onSearchClick(e)}
              value={searchInput}
            />
          </div>

          {/* <div className='mt-72 mb-20'>
            <TextH4 className='text-6xl text-mad-blue text-center pt-40 md:pt-20'>
              Search Results
            </TextH4>
          </div>
          {loading && <div className='text-mad-blue relative center font-bold'>Loading...</div>} */}
          {results && results.length === 0 && (
            <H4 className='text-beige-yellow relative text-center'>
              No results found, let’s try again!
            </H4>
          )}

          <TransitionGroup
            className={cn('grid grid-cols-1 lg:grid-cols-3 w-full my-16 items-center gap-16')}
          >
            {results.map((result) => (
              <CSSTransition key={result.id} timeout={300} classNames='item'>
                <>
                  {result.__typename === 'User' && <GradCard grad={result} />}
                  {result.__typename === 'Project' && <ProjectCard project={result} />}
                </>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      </div>
      <div className='relative w-full bg-almost-black flex flex-col items-center justify-center w-full'>
        <div className='relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] bg-almost-black max-w-[1440px]'>
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}
