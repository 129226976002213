import React from 'react'
import styled from 'styled-components'
import useCountdown from '../../hook/useCountdown'
import cn from 'classnames'


const MainContainer = styled.div`
  position: absolute;
  left:0;
  bottom:0;
  font-size: 20px;
  overflow: hidden;
`
const FooterItem = styled.footer`
color: #e3e3de;
  .yes2021 {
    color: #e3e3de;
    width: 100%;
    font-family: 'sharp_grotesque',serif;
    font-size: 160px;
    line-height: 80%;
    padding-top: 10px;
    @media (min-width: 360px) {
      font-size: 400px;
    }
    @media (min-width: 768px) {
      font-size: 550px;
    }
    @media (min-width: 1280px) {
      font-size: 600px;
    }
    @media (min-width: 1680px) {
      font-size: 650px;
    }
    @media (min-width: 1920px) {
      font-size: 700px;
    }
  }

  p, a {
    margin-bottom: 5px;
    font-family: 'grotesque_pro_light',serif;
  }
  a {
    top: -6px;
    line-height: 30px;

    border-bottom: 1px solid #F0EEE9;
    border-bottom-style: dotted;
  }
  @media (min-width: 1024px) {
    top: 0px;
  }
`

export default function Footer({className = ''}) {
  const { minutes, hours, days } = useCountdown('05/03/2023')

  return (
    <MainContainer>
    <FooterItem className={cn('relative lg:flex justify-between z-5', className)} style={{backgroundColor:'rgba(38,67,155,0)', width: '100vw'}}>
      <div className='xl:ml-12 relative' style={{backgroundColor:'#191919'}}>
        <p className='yes2021' style={{backgroundColor:'#26439B'}}>YES!</p>
        <p className='absolute top-0 left-0 uppercase text-coton lg:-ml-4 font-light' style={{backgroundColor:'transparent'}}>
          School Of Design
        </p>
        <p className='absolute top-0 right-0 text-coton -ml-4 font-light' style={{backgroundColor:'transparent'}}>Year End Show</p>
      </div>
      <div className='xl:mr-12 relative' style={{backgroundColor:'#26439B'}}>
        <p className='yes2021 ' style={{backgroundColor:'#26439B'}}>2023</p>
        <a
          href='https://www.instagram.com/sod_yes/'
          target='_blank'
          rel='noreferrer'
          className='absolute top-0 left-0  lg:-ml-4 font-light text-coton hover:text-coton hover:opacity-80 transition-opacity'
          style={{backgroundColor:'transparent'}}
        >
          @sod_yes
        </a>
        <p className='absolute top-0 right-0 mr-4 font-light' style={{backgroundColor:'transparent'}}>
          {days} days • {hours} hours • {minutes} minutes
        </p>
      </div>

    </FooterItem>
    </MainContainer>
  )
}
