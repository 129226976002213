import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useLazyQuery } from '@apollo/client'
import StickerNavsBlue from '../StickerNavsBlue'
import qs from 'query-string'
import shuffle from 'lodash.shuffle'
import { useDebounce } from 'use-debounce'
import cn from 'classnames'
import { QUERY } from '../../../graphql'
import { InputSearch } from './InputSearch'
import { programGroups } from '../../../constant'
import SearchIcon from '../../../components/2023/SearchIcon'
import { useDisclosure } from 'react-use-disclosure'
import { MobileRightNavs, MobileNavsBottom } from '../MobileRightNavs'
import FixedBottomNav from '../FixedBottomNav'

const Main = styled.div`
  position: relative;
`

const Container = styled.div`
  min-height: calc(100vh);
  cursor: url(${(props) => props.cursorUrl}), auto;
  z-index: 0;
  overflow: auto;
  padding-top: 0px;

  svg:first-child {
    // height: 1200px;
  }
  @media (max-width: 1024px) {
    padding-top: 110px;
  }
`
const TextH4 = styled.h4`
  font-family: 'reckless_light';
`

const CenterContainer = styled.div`
padding-botom: 500px;
  @media (min-width: 1280px) {
    // top: 10%;
    // max-height: 40vh;
  }
  @media (min-width: 1920px) {
    // top: 20%;
  }
`

export const TextCenterDiv = styled.div`
    position: relative;
    padding: 120px 0 60px 0;
`

const TextCenter = styled.h4`
  position: relative;
  text-align: center;
  z-index: 10;
  color: #26439B;
  font-family: Reckless_light, serif;
  font-size: 250px;
  line-height: 320px;
  letter-spacing: 0.01em;
  font-weight: 400;
  @media (max-width: 2879px) {

  }

  @media (max-width: 2559px) {

  }

  @media (max-width: 1919px) {

  }

  @media (max-width: 1679px) {

  }

  @media (max-width: 1279px) {

  }

  @media (max-width: 767px) {
    font-size: 120px;
    line-height: 130px;
  }

  @media (max-width: 359px) {

  }
`

export default function Search(props) {
  const history = useHistory()
  const location = useLocation()
  const { search: searchParams } = location
  const { keyword } = qs.parse(searchParams)
  const [searchInput, setSearchInput] = useState(keyword || '')
  const [searchInputDebounce] = useDebounce(searchInput, 800)

  const [fetchSearch, { data: searchData, loading }] = useLazyQuery(QUERY.SEARCH_QUERY, {
    variables: {
      q: searchInputDebounce,
      year: 2023,
    },
  })

  const onInputChange = useCallback((e) => {
    const val = e.target.value
    setSearchInput(val)
  }, [])

  useEffect(() => {
    if (!searchInputDebounce || searchInputDebounce?.length === 0) {
      history.replace('/2023/search')
      return
    }
    history.push({ search: `?keyword=${searchInputDebounce}` })
  }, [searchInputDebounce, history])

  useEffect(() => {
    fetchSearch()
  }, [keyword, fetchSearch])

  const results = useMemo(() => {
    const users = searchData?.searchGradWork?.users || []
    const programs = !keyword
      ? programGroups.map((p) => ({ program: p }))
      : programGroups
          .filter((p) => p.toLowerCase().includes(keyword.toLowerCase()))
          .map((p) => ({
            program: p,
          }))
    return shuffle([...users, ...programs])
  }, [searchData, keyword])

  const renderResult = useMemo(() => {
    if (results?.length === 0) {
      return (
        <div className='w-full mt-4'>
          <p className='text-4xl text-center text-mad-blue'>No results found, try again!</p>
        </div>
      )
    }

    const rows = results.map((result) => {
      if (result.__typename === 'User') {
        return (
          <Link
            className='w-full md:w-6/12 lg:w-4/12 inline-block text-mad-blue bg-coton  hover:bg-mad-blue hover:text-coton p-10 transition'
            key={result.id}
            to={{
              pathname: '/2023/student',
              search: `id=${result.id}`,
            }}
          >
            <p className='inline-block border-b border-dotted border-mad-blue text-4xl'>
              {result?.firstName} {result?.lastName}
            </p>
          </Link>
        )
      }
      return (
        <Link
          className='w-full md:w-6/12 lg:w-4/12 inline-block text-charcoal bg-coton  hover:bg-mad-blue hover:text-coton p-10 transition'
          key={result.program}
          to={{
            pathname: '/2023/grads',
            search: `program=${result.program}`,
          }}
        >
          <span className='w-3 h-3 bg-mad-blue rounded-full inline-block mr-2'></span>
          <p className='inline-block border-b border-dotted border-mad-blue text-4xl'>
            {result.program}
          </p>
        </Link>
      )
    })
    return rows
  }, [results])

  const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()

  return (
    <Main>
      <Container
        className='w-full h-full bg-coton flex'
        cursorUrl='/static/svg/2023/cursor-with-shadow.svg'
      >
        <StickerNavsBlue className={cn('w-96 relative h-screen transition-opacity duration-700 ease-in-out hidden lg:block')} />
        <CenterContainer className='w-full mx-auto py-12 absolute top-10 pb-0'>
        <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs} />
          <div
            className={cn( 'transition',{
              'flex visible opacity-100': !isOpenMenuNavs,
              'hidden invisible opacity-0': isOpenMenuNavs,
            })}
          >
          <div className='w-full'>

            <TextCenterDiv>
                <TextCenter className="text-mad-blue" style={{pointerEvents: 'none'}}>
                    Search
                </TextCenter>
            </TextCenterDiv>
            <div className='text-center relative'>
              <InputSearch onChange={onInputChange} value={searchInput} />
            </div>

            <div className='mt-72 mb-20'>
              <TextH4 className='text-6xl text-mad-blue text-center pt-40 md:pt-20'>Search Results</TextH4>
            </div>
            {loading && <div className='text-mad-blue relative center font-bold'>Loading...</div>}
            {searchData && searchData.searchGradWork && searchData.searchGradWork.length === 0 && <div className='z-8 text-mad-blue relative text-center font-bold text-5xl'>No results, please try again.</div>}
            <div
              className={cn('flex justify-evenly lg:justify-between flex-wrap bg-coton pb-20 relative z-10 px-0 lg:px-72', { 'bg-coton': (results?.length > 0 && window.innerWidth>800 ) })}
            >
              {renderResult}
            </div>
          </div>
          <SearchIcon goBack className='hidden lg:block' />
          </div>
          <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
          {/* <FooterContainer className="single-item bg-mad-blue" data-color="off_white" style={{position:'relative',background:'#26439B', overflow:'hidden', flex:'none' }}>
                    <Footer/>
        </FooterContainer> */}
        </CenterContainer>
      </Container>
      <FixedBottomNav className='hidden lg:block' />
    </Main>
  )
}
