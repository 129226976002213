import React, { useEffect, useState } from "react";
import { useNavContext } from "../../components/2020/states/nav";
import Graduates from "../../components/2020/Graduates";
import Confetti from "../../components/2020/Confetti";

const Grads = (props) => {
  const { dispatch, state } = useNavContext();

  const [isFromLanding, setIsFromLanding] = useState(false)

  useEffect(() => {
    dispatch({ type: "SET_KEYWORD", payload: ''})
    if(state.prevPath === '/'){
      setIsFromLanding(true)
      dispatch({ type: 'SET_PREV_PATH', payload: ''})
    }
  /* eslint-disable-next-line */
  }, []);

  return (
    <div>
      {isFromLanding && <Confetti />}
      <Graduates program={props.location.search.split("program=")[1]} />
    </div>
  );
};

export default Grads;
