import React, { useReducer } from "react";

export const initialState = {
  userProjects: []
};

export function reducer(state, { type, payload }) {
  switch (type) {
    case "SET_PROJECTS": {
      return { ...state, userProjects: payload };
    }
    case "REMOVE_ONE_PROJECT": {
      const userProjects = state.userProjects.filter(project => project.id !== payload)
      return { ...state, userProjects }
    }
    default: {
      return state;
    }
  }
}

const UserProjectsContext = React.createContext(initialState);

function UserProjectsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UserProjectsContext.Provider value={{ state, dispatch }}>
      {children}
    </UserProjectsContext.Provider>
  );
}

const withProjectsContext = Component => props => (
  <UserProjectsProvider>
    <Component {...props} />
  </UserProjectsProvider>
);

export { UserProjectsContext, withProjectsContext };
