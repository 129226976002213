import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { VscMenu, VscSearch } from 'react-icons/vsc'
import MobileStickerNavs from './MobileStickerNavs'
import { Link, useHistory, useLocation } from 'react-router-dom'

const TextLogo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 7rem;
  line-height: 7rem;
  letter-spacing: 0.01em;
  font-weight: 400;
  color: #ff6044;
`

const Container = styled.div`
  height: 100vh;
  background: #e3e3de;
  position: fixed;
  top:0;
  width: 100vw;
  z-index: 322;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
`

const TextCenter = styled.h4`
  font-family: 'sharp_grotesque_05';
  font-size: 420px;
  line-height: 360px;
  letter-spacing: 0.01em;
  font-weight: 400;
  background: #e3e3de;

  @media (min-width: 360px) {
    font-size: 460px;
    line-height: 400px;
  }
  @media (min-width: 768px) {
    font-size: 550px;
    line-height: 550px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 600px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 700px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 1000px;
    line-height: 1200px;
  }
  @media (min-width: 2880px) {
    font-size: 1400px;
    line-height: 1400px;
  }
`

export const MobileRightNavs = ({ isOpenMenuNavs, toggleMobileNavs, className = '' }) => {
  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])

  const navigate = useCallback(
    (url) => {
      if (url !== '/' && pathname?.startsWith(url)) {
        return
      }

      const leftLayer = document.getElementsByClassName('left-layer')?.[0]
      const rightLayer = document.getElementsByClassName('left-layer')?.[0]
      if (leftLayer && rightLayer) {
        leftLayer.classList.add('active')
        rightLayer.classList.add('active')
        setTimeout(() => history.push(url), 1500)
      }
    },
    [history, pathname],
  )

  const isHome = useMemo(() => {
    if (pathname?.length < 2) {
      return true
    }
    return false
  }, [pathname])

  return (
    <div
      className={cn(
        'fixed top-0 flex lg:hidden justify-between mb-8 lg:mb-0 bg-blue z-50 h-28 w-full',
        className,
      )}
      style={{ background: '#ffffff', zIndex: 1444 }}
    >
      <TextLogo
        className='fixed text-center px-6 cursor-pointer uppercase z-50 '
        onClick={!isHome ? () => navigate('/2022/home') : null}
      >
        Yes!2022
      </TextLogo>
      <div className='fixed z-50 my-4' style={{ top:'8px', right: '8px' ,display: 'flex', alignItems: 'center', flexDirection: 'row-reverse', width:'100px', justifyContent:'space-around'}}>
        {!isOpenMenuNavs
          ? ((document.body.style.overflow = 'auto'),
            (<VscMenu onClick={toggleMobileNavs} style={{color:'#191919', fontSize:'30px'}}/>))
          : ((document.body.style.overflow = 'hidden'),
            (
              <img
                src='/static/svg/2022/closeBlack.svg'
                alt='close'
                style={{width:'30px'}}
                onClick={toggleMobileNavs}
              />
            ))}
        <Link to='/2022/search'>
          <VscSearch style={{color:'#191919', fontSize:'30px'}}/>
        </Link>
      </div>
    </div>
  )
}

export const MobileNavsBottom = ({ isOpenMenuNavs }) => {
  return (
    <Container
      className={cn('transition', {
        'block visible opacity-100 relative py-24': isOpenMenuNavs,
        'hidden invisible opacity-0': !isOpenMenuNavs,
      })}
    >
      <MobileStickerNavs withTop={false} className='flex justify-center items-center'/>
      <div className='text-center absolute top-0 left-0 w-full'
      style={{top: '50%',left: '50%',transform: 'translate(-50%, -50%)'}}>
        <TextCenter className='text-black uppercase opacity-10 uppercase text-center'>
          Explore
        </TextCenter>
      </div>
    </Container>
  )
}
