import React, { useEffect, useCallback, useMemo } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
// import dayjs from 'dayjs'
import cn from 'classnames'
import { useWindowSize } from '../../hook'
import Footer from '../../components/2021/Footer'
import SearchCol from '../../components/2021/SearchCol'
import { useDisclosure } from 'react-use-disclosure'
import { MobileRightNavs, MobileNavsBottom } from './MobileRightNavs'

const Container = styled.div`
  min-height: calc(250vh);
  cursor: url(${(props) => props.cursorUrl}), auto;
  z-index: 0;
  svg:first-child {
    // height: 1200px;
  }
`

const FootCont = styled.div`
  position: relative;
`
const CurvedContainer = styled.div`
  background: #5933ff;
  display: inline-block;
  position: relative;
  width: 100%;

  height: 400px;
  vertical-align: middle;
  overflow: hidden;
`

const TextLogo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 8.5rem;
  line-height: 9rem;
  letter-spacing: 0.01em;
  font-weight: 400;
`
const TextCenterTop = styled.div`
  h2,
  h3 {
    font-family: 'grotesque_pro_light';
    font-weight: 200;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.05rem;
  }
  // bottom: 4%;
  // top : 0;
  @media (min-width: 768px) {
    // bottom: 0%;
  }
  @media (min-width: 1600px) {
    // bottom: 2%;
  }
`
const StickersWrapper = styled.div`
  top: ${(props) => props.top};
  left: ${(props) => props.imgLeft || 28}px;
  min-width: 160px;
  min-height: 480px;
`
const StickerContainer = styled.div`
  top: ${(props) => props.top};
  left: ${(props) => props.imgLeft || 28}px;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'inherit')};
  img {
    width: ${(props) => props.imgWidth || 84}px;
  }
  p {
    position: absolute;
    top: calc(${(props) => props.top} + 16px);
    font-family: Reckless;
    transform: rotate(${(props) => props.textRotate}deg);
  }

`
const CenterContainer = styled.div`
  img {
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  }
  @media (min-width: 1280px) {
    // top: 10%;
    // max-height: 40vh;
  }
  @media (min-width: 1920px) {
    // top: 20%;
  }
`
const TextCenter = styled.h4`
  font-family: 'sharp_grotesque_05';
  font-size: 420px;
  line-height: 360px;
  letter-spacing: 0.01em;
  margin-top: 0.1em!important;
  font-weight: 400;
  @media (min-width: 360px) {
    font-size: 460px;
    line-height: 400px;
  }
  @media (min-width: 768px) {
    font-size: 550px;
    line-height: 550px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 600px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 700px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 1000px;
    line-height: 1200px;
  }
  @media (min-width: 2880px) {
    font-size: 1400px;
    line-height: 1400px;
  }
`
const Sticker5Days = styled.img`
  top: 10%;
  left: 15%;
  cursor: inherit;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  @media (min-width: 1024px) {
    top: 15%;
    left: 26%;
  }
  @media (min-width: 1440px) {
    left: 30%;
  }
  @media (min-width: 1920px) {
    left: 35%;
  }
  @media (min-width: 2400px) {
    left: 40%;
  }
`
const StickerMay = styled.img`
  // bottom: 20%;
  top: 15%;
  right: 15%;
  cursor: inherit;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  @media (min-width: 768px) {
    // bottom: -5%;
  }
  @media (min-width: 1024px) {
    right: 20%;
  }
  @media (min-width: 1280px) {
    right: 25%;
  }
  @media (min-width: 1600px) {
    right: 30%;
  }
`

const DescriptionTitle = styled.div`
  margin-top: 15vh;
  p {
    font-family: 'sharp_grotesque_15';
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    letter-spacing: normal;
    font-size: 42px;
    line-height: normal;
  }
`

const DescriptionSubtitle = styled.div`
  width: 50vw;
  margin: auto;
  margin-top: 25vh;
  p {
    // width : 20vw;
    font-family: 'grotesque_pro_light';
    font-size: '22px';
    color: white;
    // fontWeight : 'bolder';
    text-align: left;
  }
  @media (max-width: 411px),
   (max-height: 700px) {
    height: 0vh;
  }
`
const Sticker1 = styled.img`
    top: 100%;
    right: 90%;
    width: 5%;
    @media (max-width: 768px) {
      top: 80%;
      width: 10%;
    }
`
const Sticker2 = styled.img`
    top: 110%;
    right: 20%;
    width: 7%;
    @media (max-width: 768px) {
      top: 90%;
      width: 14%;
    }
`
const Sticker3 = styled.img`
    top: 120%;
    right: 30%;
    width: 8%;
    @media (max-width: 768px) {
      top: 100%;
      width: 16%;
    }
`

const Sticker4 = styled.img`
    top: 130%;
    right: 93%;
    width: 15%;
    @media (max-width: 768px) {
      top: 110%;
      width: 30%;
    }
`
const Sticker6 = styled.img`
    top: 140%;
    right: 50%;
    width: 9%;
    @media (max-width: 768px) {
      top: 120%;
      width: 18%;
    }
`
const Sticker7 = styled.img`
    top: 150%;
    right: 60%;
    width: 10%;
    @media (max-width: 768px) {
      top: 130%;
      width: 20%;
    }
`
const Sticker5 = styled.img`
    top: 100%;
    right: 5%;
    width: 9%;
    @media (max-width: 768px) {
      top: 80%;
      width: 18%;
    }
`
const Sticker8 = styled.img`
    top: 110%;
    right: 50%;
    width: 7%;
    @media (max-width: 768px) {
      top: 90%;
      width: 14%;
    }
`
const Sticker9 = styled.img`
    top: 120%;
    right: 70%;
    width: 20%;
    @media (max-width: 768px) {
      top: 100%;
      width: 30%;
    }
`
const Sticker10 = styled.img`
    top: 130%;
    right: 60%;
    width: 5%;
    @media (max-width: 768px) {
      top: 110%;
      width: 10%;
    }
`
const Sticker11= styled.img`
    top: 100%;
    right: 50%;
    width: 40%;
    @media (max-width: 768px) {
      top: 80%;
      width: 50%;
    }
`
const Sticker12= styled.img`
    top: 130%;
    right: 40%;
    width: 15%;
    @media (max-width: 768px) {
      top: 110%;
      width: 30%;
    }
`
const Sticker15= styled.img`
    top: 130%;
    right: 60%;
    width: 2%;
    @media (max-width: 768px) {
      top: 110%;
      width: 6%;
    }
`

const StickerPlay= styled.img`
    top: 110%;
    right: 30%;
    width: 9%;
    @media (max-width: 768px) {
      top: 90%;
      width: 18%;
    }
`

const ENABLE_NAVIGATION = true

export default function Homepage() {

  const { screen } = useWindowSize()
  const isMobile = screen === 'sm'

  const history = useHistory()
  const { pathname } = useLocation()

  const isWork = useMemo(() => {
    if (pathname?.startsWith('/work')) {
      return true
    }
    return false
  }, [pathname])

  const isGrads = useMemo(() => {
    if (pathname?.startsWith('/grads')) {
      return true
    }
    return false
  }, [pathname])

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])

  const navigate = useCallback(
    (url) => {
      if (pathname?.startsWith(url)) {
        return
      }
      const leftLayer = document.getElementsByClassName('left-layer')?.[0]
      const rightLayer = document.getElementsByClassName('left-layer')?.[0]
      if (leftLayer && rightLayer) {
        leftLayer.classList.add('active')
        rightLayer.classList.add('active')
        setTimeout(() => history.push(url), 1500)
      }
    },
    [history, pathname],
  )

  useEffect(() => {
    ;(async () => {
      // const script = document.createElement('script')
      // script.src = '/static/transition.js'
      // script.async = true
      // script.type = 'text/javascript'

      const script2 = document.createElement('script')
      script2.src = '/static/movingsvg.js'
      script2.async = true
      script2.type = 'text/javascript'

      // document.body.appendChild(script)
      document.body.appendChild(script2)
      // script.onload = function () {
      //   const canvas = document.getElementsByTagName('canvas')[0]
      //   if (canvas) {
      //     canvas.style.cssText = `cursor: inherit ;z-index: 1 ; display: block !important;left: 0;position: absolute;top: 0; width:100%; height:100%; `
      //   }
      // }
    })()
  }, [])

  const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()

  return (
    <>

      {/* <VideoModal
      videoOpen={videoOpen}
      setVideoClose={toggleOpenVideo}
    /> */}
      <Container
        className='w-full h-full bg-black relative mt-28'
        cursorUrl='/static/svg/cursor-with-shadow.svg'
        // onMouseMove={onMouseMove}
      >

        <div className='absolute w-full' style={{marginTop: "-9%"}}>
          <img
            src={`/static/svg/teaser-bg-black${isMobile ? '' : '-xl'}.svg`}
            alt='bg-teaser'
            className='w-full'
          />
          {/* <img
            src={`/static/svg/teaser2-bg${isMobile ? '' : '-xl'}.svg`}
            alt='bg-teaser2'
            className='w-full'
          /> */}
        </div>
        <StickersWrapper
          className={cn('z-10 fixed  w-96 fixed top-10 h-screen transition-opacity duration-700 ease-in-out hidden lg:block', { hidden: !ENABLE_NAVIGATION })}
          top='210px'
          imgLeft='0%'
        >
          <StickerContainer
            pointer
            className='relative w-max'
            textRotate={-35}
            onClick={() => navigate('/2021/work')}
          >
            <svg width={88} height={88} viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'>
              <circle
                fill={isWork ? '#333' : '#DEFC1C'}
                cx={55}
                cy={55}
                r={55}
                fillRule='evenodd'
                style={{display: "unset"}}
              />
            </svg>
            <p
              className={cn('text-5xl text-blue absolute', {
                'text-secondary': isWork,
                'text-blue': !isWork,
              })}
              style={{
                top: 'calc(25% + 4px)',
                left: 10,
              }}
            >
              Work
            </p>
          </StickerContainer>
          <StickerContainer
            pointer
            imgWidth={124}
            className='relative -mt-4 w-max'
            textRotate={-15}
            onClick={() => navigate('/2021/grads')}
          >
            <svg width={128} height={87} viewBox='0 0 164 87' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M.203 71.712S60.29 13.564 113.301 1.427c51.212-11.726 71.525 64.233 21.237 79.456-26.56 8.04-68.41 8.844-134.335-9.171'
                fill={isGrads ? '#333' : '#DEFC1C'}
                fillRule='evenodd'
              />
            </svg>
            <p
              className={cn('text-5xl text-blue', {
                'text-secondary': isGrads,
                'text-blue': !isGrads,
              })}
              style={{
                top: 'calc(35% + 0px)',
                left: 42,
              }}
            >
              Grads
            </p>
          </StickerContainer>
          <StickerContainer pointer imgWidth={124} className='relative w-max' textRotate={10} onClick={() => navigate('/2021/events')}>
            <img src='/static/svg/nav-event.svg' alt='events-nav' />
            <p
              className='text-5xl text-blue'
              style={{
                top: 'calc(45% - 12px)',
                left: 24,
              }}
            >
              Events
            </p>
          </StickerContainer>
          <StickerContainer pointer imgWidth={94} className='relative w-max' textRotate={10} onClick={() => navigate('/2021/show')}>
            <img src='/static/svg/nav-show.svg' alt='show-nav' />
            <p
              className='text-5xl text-blue'
              style={{
                top: 'calc(56% - 16px)',
                left: 16,
              }}
            >
              Show
            </p>
          </StickerContainer>
        </StickersWrapper>
        <StickerContainer
          imgWidth={isMobile ? 84 : 120}
          className='absolute'
          top='32%'
          textRotate={10}
        >
          <img  id="yesss" src='/static/svg/yes-blue-circle.svg' alt='yes-blue-circle' />
        </StickerContainer>
        <StickerContainer
          imgWidth={isMobile ? 124 : 160}
          imgLeft={isMobile ? 76 : 84}
          className='absolute'
          top='33%'
          textRotate={10}
        >
          <img id="yesss" src='/static/svg/sod-circle-blue.svg' className='shadow-md' alt='yes-blue-circle' />
        </StickerContainer>

        <div className='w-full flex justify-between pl-10 relative hidden lg:block'>
          <div className='fixed  top-0 z-10 inline-block' style={{ left: '51px' }}>
            {/* <img src='/static/svg/yes-logo-2021.svg' alt='logo' className='w-48' /> */}
            <Link to='/'>
              <TextLogo className='text-secondary uppercase'>Yes!2021</TextLogo>
            </Link>
          </div>
          {/* <div>
            <img
              src='/static/svg/glass.svg'
              alt='glass'
              className={cn('-mt-4 cursor-pointer', { hidden: !ENABLE_NAVIGATION })}
            />
          </div> */}
        </div>
        <CenterContainer className='w-full absolute mx-auto'>
        <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs} />
        <div
            className={cn( 'transition',{
              'flex visible opacity-100': !isOpenMenuNavs,
              'hidden invisible opacity-0': isOpenMenuNavs,
            })}
          >
          <div className='text-center relative h-full xl:-mt-36 flex'>
            <div className='w-full'>
              <TextCenter className='text-secondary'>YES!</TextCenter>
              <Sticker5Days
                src='/static/svg/5-days.svg'
                alt='5-days'
                className={cn('absolute mx-auto cursor-pointer w-32 md:w-64 max-w-sm')}
              />
              <StickerMay
                src='/static/svg/may3-7.svg'
                alt='5-days'
                className={cn('absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <TextCenterTop className='absolute w-full z-10'>
                <h2 className='text-white uppercase text-center'>George Brown College</h2>
                <h2 className='text-white uppercase text-center'>School of Design</h2>
                <h3 className='text-white text-center'>
                  Year End Show
                  <a
                    className='text-secondary'
                    href='https://www.instagram.com/sod_yes/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {' '}
                    @sod_yes
                  </a>
                </h3>
              </TextCenterTop>
              <DescriptionTitle className='w-full'>
                <p className='text-center text-secondary' style={{fontFamily: 'reckless_light', color:  'white', fontWeight: 'normal'}}>
                  YES! is the official grad show at <br></br>
                    <a href='https://www.georgebrown.ca/'
                            rel='noreferrer' >
                    <span className='inline border-b border-secondary border-dashed text-white'>
                      George Brown College’s
                    </span>
                    </a> School of Design
                  <br></br> for&nbsp;
                  <a href='https://yes.schoolofdesign.ca/grads'
                            rel='noreferrer' >
                    <span className='inline border-b border-secondary border-dashed text-white'>
                    380 students
                    </span>
                    </a>, across&nbsp;
                    <a href='https://yes.schoolofdesign.ca/work'
                            rel='noreferrer' >
                    <span className='inline border-b border-secondary border-dashed text-white'>
                    10 programs
                    </span>
                    </a>.
                </p>
              </DescriptionTitle>
              <DescriptionSubtitle className=''>
                <p>
                  2021 Year End Show<br></br>
                  This spring, 380 students will graduate from the School of Design. Each has
                  benefitted from the wisdom and support of faculty, peers, staff, industry partners
                  and the much broader School of Design community. Join us May 3–7 to celebrate all
                  of their hard work and dedication. Yes! is a testament to their collaboration.
                  Yes! We’re designers. Yes! We’re future-ready. Yes! We’re the School of Design
                </p>
              </DescriptionSubtitle>
              <Sticker1
                src='/static/svg/sticker-1.svg'
                style={{ top: '100%', right: '90%', width: '5%' }}
                className={cn('moving1 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <Sticker2
                src='/static/svg/sticker-2.svg'
                style={{ top: '110%', right: '20%', width: '7%' }}
                className={cn('moving2 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <Sticker3
                src='/static/svg/sticker-3-yes.svg'
                style={{ top: '120%', right: '30%', width: '8%' }}
                className={cn('moving3 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <Sticker4
                src='/static/svg/sticker-4.svg'
                style={{ top: '130%', right: '93%', width: '15%' }}
                className={cn('moving4 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <Sticker6
                src='/static/svg/sticker-6-yes.svg'
                style={{ top: '140%', right: '50%', width: '9%' }}
                className={cn('moving5 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <Sticker7
                src='/static/svg/sticker-7-yes.svg'
                style={{ top: '150%', right: '60%', width: '10%' }}
                className={cn('moving6 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <Sticker5
                src='/static/svg/sticker-5-yes.svg'
                style={{ top: '100%', right: '5%', width: '9%' }}
                className={cn('moving7 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <Sticker8
                src='/static/svg/sticker-8-yes.svg'
                style={{ top: '110%', right: '50%', width: '7%' }}
                className={cn('moving8 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <Sticker9
                src='/static/svg/sticker-9-sod.svg'
                style={{ top: '120%', right: '70%', width: '20%' }}
                className={cn('moving9 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <Sticker10
                src='/static/svg/sticker-10-yes.svg'
                style={{ top: '130%', right: '60%', width: '5%' }}
                className={cn('moving10 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <Sticker11
                src='/static/svg/sticker-11-future.svg'
                style={{ top: '100%', right: '50%', width: '40%' }}
                className={cn('moving11 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <Sticker12
                src='/static/svg/sticker-12-missthis.svg'
                style={{ top: '130%', right: '40%', width: '15%' }}
                className={cn('moving13 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <Sticker15
                src='/static/svg/sticker-15.svg'
                style={{ top: '130%', right: '60%', width: '2%' }}
                className={cn('moving14 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <StickerPlay
                src='/static/svg/sticker-play.svg'
                style={{ top: '110%', right: '30%', width: '9%' }}
                className={cn('moving15 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
            </div>
          </div>
          </div>
          <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
        </CenterContainer>

      </Container>
      {
        !isOpenMenuNavs && (
            <CurvedContainer>
            <svg viewBox='0 0 500 500' preserveAspectRatio='xMinYMin meet'>
              <path
                d='M0,50 C150,100 350,0 500,10 L500,00 L0,0 Z'
                style={{ keyStroke: 'none', fill: '#000' }}
              ></path>
            </svg>
          </CurvedContainer>
        )
      }
      <FootCont style={{
        display: isOpenMenuNavs ? "none" : "block"
      }}>
        <Footer  />
      </FootCont>

     <SearchCol className='hidden lg:block' />
      </>
  )
}
