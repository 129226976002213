import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useHistory, useLocation } from 'react-router'
import qs from 'query-string'
import upperFirst from 'lodash.upperfirst'
import { Helmet } from 'react-helmet-async'
import ReactMarkDown from 'react-markdown'
import { Link } from 'react-router-dom'
import { projectType } from '../../constant'
import { animateScroll as scroll } from 'react-scroll'

const Button = styled.button`
  width: 100%;
  background: #defc1c;
  color: #5933ff;
  border: 0;
  font-family: 'grotesque_pro_light';
  font-size: 2rem;
  font-weight: 600;
  padding: 0.5rem 2.2rem;
  margin-top: 5rem;
  margin-bottom: 3rem;
  border-radius: 3rem;
  width: 240px;

  @media (min-width: 768px) {
    font-size: 2.4rem;
    font-weight: 300;
    min-width: 180px;
    padding: 0.75rem 1.5rem;
    text-transform: uppercase;
  }
`

const Container = styled.div`
  .projectText p {
    text-align: left;
    margin: auto;
    padding-bottom: 50px;
    width: 50vw;
  }
  .projectText p a {
    color: #defc1c;
  }
`

const TitleContent = styled.h2`
  font-size: 44px;
  font-family: 'grotesque_pro_light';
  text-align: center;
  margin: auto;
  padding-bottom: 24px;
  width: 50vw;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`
const Image = styled.img`
  width: 100%;
  margin-bottom: 50px;
`

const Video = styled.video`
  width: 80vw;
  max-width: 80vw;
  margin-bottom: 50px;
`

export default function PublishedProject(props) {
  const history = useHistory()
  const { search } = useLocation()
  const { id } = qs.parse(search)

  useEffect(() => {
    scroll.scrollTo(0, {
      duration: 200,
    })
  }, [])

  const { data } = useQuery(SINGLE_PROJECT_QUERY, {
    variables: {
      id,
    },
  })
  const project = useMemo(() => data?.project || {}, [data])

  // const leadMember = useMemo(() => {
  //   const lead = project?.members?.find((member) => member.role === 'Project Lead')?.user
  //   return lead || project?.members?.[0]?.user || {}
  // }, [project])

  const [publishProject] = useMutation(PUBLISH_PROJECT_MUTATION, {
    variables: {
      id,
    },
  })

  const handlePublish = useCallback(async () => {
    try {
      await publishProject()
      // change them to the single item page
      history.push({
        pathname: '/project',
        search: `?id=${id}`,
      })
    } catch (error) {
      console.log('error: ', error)
    }
  }, [publishProject, id, history])

  return (
    <>
      {project.title && (
        <Helmet>
          <title>YES! | {project.title}</title>
        </Helmet>
      )}

      <Container className='flex min-h-screen bg-blue'>
        <div className='w-full bg-dark text-white flex'>
          <div className='w-full mx-auto pt-24'>
            {project.members?.map((member) => {
              const user = member?.user || {}
              return (
                <div key={user?.id}>
                  <div className='flex items-center justify-center'>
                    <Link
                      to={{
                        pathname: '/student',
                        search: `id=${user.id}`,
                      }}
                      className='inline-block flex items-center text-white'
                    >
                      <img
                        src={user?.image}
                        alt='member'
                        className='w-20 h-20 rounded-full mx-auto object-cover'
                        style={{ filter: 'grayscale(100%)' }}
                      />
                      <p className='border-b border-dashed border-secondary -mt-2 ml-4 text-4xl'>
                        {user?.firstName} {user?.lastName}
                      </p>
                    </Link>
                  </div>

                  <p className='text-center mt-4'>
                    {user?.primaryExpertise}
                    {user?.secondaryExpertise && `, ${user?.secondaryExpertise}`}
                  </p>
                </div>
              )
            })}
            <h3 className='text-8xl text-center my-10 px-16'>{project?.title}</h3>
            <div className='flex justify-center'>
              {['primaryProjectType', 'secondaryProjectType', 'secondaryProjectType_2'].map(
                (key) => {
                  if (project[key]) {
                    return (
                      <div className='flex items-center mr-12' key={key}>
                        <span className='w-3 h-3 bg-secondary rounded-full inline-block'></span>
                        <span className='inline-block ml-2 border-b border-dashed border-secondary'>
                          {projectType[project[key]] || upperFirst(project[key])}
                        </span>
                      </div>
                    )
                  }
                  return null
                },
              )}
            </div>
            <div className='mt-16 px-24'>
              {project?.contentType?.map((type, i) => {
                if (type === 'Heading') {
                  return <TitleContent key={i}>{project.contentData[i]}</TitleContent>
                } else if (type === 'Text') {
                  return (
                    <div className='projectText' key={`contenttype-${i}`}>
                      <ReactMarkDown key={i} children={project.contentData[i]} />
                    </div>
                  )
                } else if (type === 'Image') {
                  return (
                    <div key={i}>
                      <Image
                        src={project.contentData[i]}
                        alt='Image Preview'
                        // onClick={() => handleImgLightbox(project.contentData[i])}
                      />
                    </div>
                  )
                } else if (type === 'Video') {
                  return (
                    <div key={i}>
                      <Video controls>
                        <source type='video/mp4' src={project.contentData[i]} />
                      </Video>
                    </div>
                  )
                }
                return null
              })}
            </div>
            <div className='flex justify-end mt-6'>
              <div className='w-1/2 flex justify-between'>
                <Link
                  to={{
                    pathname: '/update',
                    search: `id=${project.id}`,
                  }}
                >
                  <Button className='mx-4'>Edit</Button>
                </Link>
                <Button className='mx-4' onClick={handlePublish}>
                  Publish
                </Button>
              </div>
            </div>
          </div>
          {/* <div className='w-12 bg-blue'>
            <img src='/static/svg/glass.svg' alt='glass' className={cn('cursor-pointer')} />
          </div> */}
        </div>
      </Container>
    </>
  )
}

const SINGLE_PROJECT_QUERY = gql`
  query SINGLE_PROJECT_QUERY($id: ID!) {
    project(where: { id: $id }) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        role
        user {
          id
          firstName
          lastName
          image
          tagline
          program
          contactEmail
          primaryExpertise
          secondaryExpertise
          about
          image
          portfolio
          behance
          linkedIn
          instagram
          twitter
          facebook
          dribble
          youtube
          vimeo
          medium
        }
      }
      published
    }
  }
`

const PUBLISH_PROJECT_MUTATION = gql`
  mutation PUBLISH_PROJECT_MUTATION($id: ID!) {
    publishProject(id: $id) {
      id
      title
      tags
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      members {
        id
        role
        user {
          id
        }
      }
      coverImage
      published
    }
  }
`
