import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { gql } from 'apollo-boost'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import StickerNavs from '../StickerNavs'
import WorkCard from '../../../components/2022/WorkCard'
import shuffle from 'lodash.shuffle'
import { useLazyQuery } from '@apollo/client'
import { DropdownFilter } from '../DropdownFilter'
import { programGroups } from '../../../constant'
import useScroll from '../../../hook/useScroll'
import WorkScrollNav from './WorkScrollNav'
import cn from 'classnames'
import { useDisclosure } from 'react-use-disclosure'
import { MobileRightNavs, MobileNavsBottom } from '../MobileRightNavs'
import FixedBottomNav from '../../../components/2022/FixedBottomNav'
import { Element } from 'react-scroll'
import SearchIcon from '../../../components/2022/SearchIcon'
import Footer from '../../../components/2022/Footer'

const Container = styled.div`
  min-height: calc(100vh);
  cursor: url(${(props) => props.cursorUrl}), auto;
  flex-direction: column;

  #curvyBG {
    top: -100vw;
  }
  @media (min-width: 512px) {
    #curvyBG {
      top: -70vw;
    }
  }
  @media (min-width: 768px) {
    #curvyBG {
      top: -50vw;
    }
  }
  @media (min-width: 1280px) {
    #curvyBG {
      top: -20vw;
    }
  }
  @media (min-width: 1680px) {
    #curvyBG {
      top: -20vw;
    }
  }
  @media (min-width: 1920px) {
    #curvyBG {
      top: -10vw;
    }
  }
  @media (min-width: 2560px) {
    #curvyBG {
      top: -20vw;
    }
  }
  @media (min-width: 2880px) {
    #curvyBG {
      top: -20vw;
    }
  }
  @media (min-width: 2880px) {
    #curvyBG {
      top: -20vw;
    }
  }
`

const InterActiveMedia = styled.div`
  .dot {
    height: 12px;
    width: 12px;
    background-color: #d5fd00;
    border-radius: 50%;
    margin: 8px 10px;
    display: inline-block;
  }
`

const CenterContainer = styled.div`
  @media (min-width: 1280px) {
    // top: 10%;
    // max-height: 40vh;
  }
  @media (min-width: 1920px) {
    // top: 20%;
  }
  width: -webkit-calc(100%- 48rem);
  width: -moz-calc(100%- 48rem);
  width: calc(100% - 48rem);

  @media (max-width: 1280px) {
    width: 100%;
  }
`
const TextCenter = styled.h4`
  color: #ff6044;
  font-family: 'sharp_grotesque_05';
  font-size: 420px;
  line-height: 360px;
  letter-spacing: 0.01em;
  font-weight: 400;
  @media (min-width: 360px) {
    font-size: 460px;
    line-height: 400px;
  }
  @media (min-width: 768px) {
    font-size: 550px;
    line-height: 550px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 600px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 700px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 1000px;
    line-height: 1200px;
  }
  @media (min-width: 2880px) {
    font-size: 1400px;
    line-height: 1400px;
  }
`

const FooterContainer =styled.div`
  height: 130vh;
  @media (min-width: 768px) {
    height: 150vh;
  }
  @media (min-width: 1024px) {
    height: 120vh;
  }
  @media (min-width: 2560px) {
      height: 90vh;
  }
`

const PUBLIC_PROJECTS_QUERY = gql`
  query PUBLIC_PROJECTS_QUERY($where: ProjectWhereInput, $gradsSearch: String) {
    projects(where: $where, gradsSearch: $gradsSearch) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        user {
          id
          program
        }
      }
    }
  }
`

const Y_POS_SWAP_NAVS = 240

export default function Work(props) {
  const location = useLocation()
  const { search: searchParams } = location
  const { keyword, program, type } = qs.parse(searchParams)
  const [gradsSearch, setGradsSearch] = useState()

  // scroll
  const scroll = useScroll()

  const OR = useMemo(() => {
    const OR = []
    if (keyword) {
      OR.push({ title_contains: keyword })
      OR.push({ tags_contains: keyword })
    }
    return OR
  }, [keyword])


  const [fetch, { data, loading }] = useLazyQuery(PUBLIC_PROJECTS_QUERY, {
    // variables: {
    //   where: {
    //     // published: true,
    //     // createdAt_gt: '2020-12-31',
    //     OR: OR.length > 0 ? OR : undefined,
    //   },
    //   gradsSearch
    // },
  })
  //console.log('Work -> data', data)

  useEffect(() => {
    fetch({
      variables: {
        where: {
          published: true,
          createdAt_gt: '2021-12-31',
          OR: OR.length > 0 ? OR : undefined,
        },
        gradsSearch
      }
    })
  }, [gradsSearch, OR, fetch])

  const groupedProjects = useMemo(() => {
    if (!data?.projects) {
      return {}
    }
    let projects = data.projects
    if (program) {
      let duplicateProjects = []
      projects = projects.filter((project) => {
        if (
          project?.members?.some?.((member) => member?.user?.program === program) &&
          !duplicateProjects.includes(project.id)
        ) {
          duplicateProjects.push(project.id)
          return true
        }
        return false
      })
    }

    if (type) {
      projects = projects.filter(
        (project) =>
          project.primaryProjectType === type.toLowerCase() ||
          project.secondaryProjectType === type.toLowerCase() ||
          project.secondaryProjectType_2 === type.toLowerCase(),
      )
    }
    // group by program
    const groupedProjects = {}
    programGroups.forEach((key) => {
      const groupProjects = projects.filter((project) => {
        if (project?.members?.some?.((member) => member?.user?.program === key)) {
          return true
        }
        return false
      })
      groupedProjects[key] = shuffle(groupProjects)
    })

    return groupedProjects
  }, [data, program, type])

  const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()

  require('./work.scss')

  useEffect(() => {
    let body = document.getElementsByTagName('body')?.[0]
    body.style.height = 'auto'
    body.style.overflow = 'hidden'
    window.scroll(0, 0)
  }, [])

  const isEmptyWorkVisible = useMemo(() => {
    let visible = true
    for (const key of Object.keys(groupedProjects)) {
      if (groupedProjects[key] && groupedProjects[key].length > 0) {
        visible = false
        break
      }
    }
    return visible
  }, [groupedProjects])

  return (
    <>
      <Element name="myScrollToElement" id="scroll2022top"></Element>
      <Container
        id='top'
        className='w-full h-full bg-grey flex relative'
        cursorUrl='/static/svg/2022/cursor-with-shadow.svg'
        style={{ background: '#e3e3de' }}
      >
        <svg
          width='100%'
          viewBox='0 0 2198 1347'
          version='1.1'
          style={{ position: 'absolute'}}
          id="curvyBG"
        >
          <g
            id='teaser-bg-xl'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
            style={{ position: 'absolute', width: '100vw', zIndex: 1 }}
          >
            <rect fill='#e3e3de' x='0' y='0' width='2198' height='1347'></rect>
            <path
              d='M-611,-35 L2738,-40 C2458.19686,301.117387 2218.7579,511.757842 2019.68312,591.921364 C1387.94312,846.313536 626.827196,516.489551 74.221014,1022.52081 C-213.850221,1286.31342 -1029,1869 -1212,1008 C-1334,434 -1133.66667,86.3333333 -611,-35 Z'
              id='Fill-2'
              stroke='#e3e3de'
              fill='#191919'
            ></path>
          </g>
        </svg>
        {/* <img
            src={`/static/svg/2022/teaser-bg-black${isMobile ? '' : '-xl'}.svg`}
            alt='bg-teaser'
            style={{position: 'absolute', width:'100vw'}}
        /> */}
        <StickerNavs
          className={cn(
            'w-96 fixed top-0 h-screen transition-opacity duration-700 ease-in-out hidden lg:block z-20',
            {
              'opacity-1 visible': scroll.scrollY < Y_POS_SWAP_NAVS,
              'opacity-0 invisible': scroll.scrollY >= Y_POS_SWAP_NAVS,
            },
          )}
        />
        <WorkScrollNav
          className={cn(
            'w-96 absolute h-screen transition-opacity duration-700 ease-in-out hidden lg:block z-10',
            {
              'opacity-1 visible': scroll.scrollY >= Y_POS_SWAP_NAVS,
              'opacity-0 invisible': scroll.scrollY < Y_POS_SWAP_NAVS,
            },
          )}
        />

        <CenterContainer className='w-full mx-auto pb-12'>
          <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs} />
          <div
            className={cn('transition', {
              'flex visible opacity-100': !isOpenMenuNavs,
              'hidden invisible opacity-0': isOpenMenuNavs,
            })}
          >
            <div className='w-full xl:w-full '>
              <div className='text-center relative z-10 mt-36 sm:mt-24'>
                <TextCenter className='text-secondary' style={{ pointerEvents: 'none' }}>
                  WORK
                </TextCenter>
                <DropdownFilter program={program} type={type} onChangeSearchGrads={setGradsSearch}  />
              </div>
              {loading && <div className='z-8 text-charcoal relative text-center font-bold text-5xl'>Loading...</div>}
              {data && data.projects && data.projects.length === 0 && <div className='z-8 text-charcoal relative text-center font-bold text-5xl'>No results, please try again.</div>}
              {isEmptyWorkVisible && <div className='z-8 text-charcoal relative text-center font-bold text-5xl'>No results, please try again.</div>}
              <div id='wrapper' className='z-9 pt-10'>
                {Object.keys(groupedProjects).map((key) => {
                  if (groupedProjects[key]?.length <= 0) {
                    return null
                  }

                  return (
                    <div key={key}>
                      <InterActiveMedia>
                        <div
                          className='text-center lg:my-4'
                          style={{
                            maxWidth: '50%',
                            margin: '0 auto',
                            padding: '50px',
                            fontFamily: 'sharp_grotesque_15',
                          }}
                        >
                          {/* <span className='block lg:inline-block text-4xl lg:text-5xl text-white mb-2 lg:mb-0 lg:mr-4' style={{color: '#191919', zIndex: 20,position: 'relative'}}>
                            Class of 2021
                          </span> */}
                          <span className='flex justify-center items-center lg:inline-block text-5xl text-secondary mt-4 lg:mt-0 mb-4 lg:mb-0'>
                            <span
                              className='pb-2 lg:pb-1'
                              style={{
                                color: '#191919',
                                zIndex: 8,
                                position: 'relative',
                                fontSize: '60px',
                                fontWeight: '900',
                              }}
                            >
                              {key.toUpperCase()}
                            </span>
                          </span>
                        </div>
                      </InterActiveMedia>
                      <div className='bg-grey2022 flex flex-wrap justify-between relative z-8'>
                        {groupedProjects[key].map((project) => (
                          <WorkCard
                            project={project}
                            key={project.id}
                            className='w-full lg:w-1/2 xl:w-1/3'
                          />
                        ))}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className='w-12 bg-blue hidden lg:block'>
              {/* <img src='/static/svg/glass.svg' alt='glass' className={cn('cursor-pointer')} /> */}
            </div>
          </div>
          <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
        </CenterContainer>
        {/* <SearchCol className='hidden lg:block' />*/}
        {/* <Footer />
        <img
            src='/static/svg/2022/glass.svg'
            style={{ position: 'fixed', top: '0px',right: '0px', width: '30px' , zIndex: 1013 }}
            alt='glass'
            className={cn('cursor-pointer')}
            // onClick={() => navigate('/2021/search')}
        /> */}

        <SearchIcon className='hidden lg:block' />
        <FixedBottomNav className='hidden lg:block' />
        <FooterContainer className="single-item bg-light-grey-2022" data-color="off_white" style={{position:'relative',background:'#191919', overflow:'hidden', flex:'none' ,minWidth: '10vh', minHeight: '10vh'}}>
                    <div style={{position:'absolute', top: '-20px', width:'100vw', height:'100px', background:'#e3e3de', zIndex:'9'}}></div>
                    <Footer/>
        </FooterContainer>
      </Container>
    </>
  )
}
