import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { SpringSystem } from 'rebound'

export default class ColoredScrollbars2022 extends Component {
  constructor(props, ...rest) {
    super(props, ...rest)
    this.scrollbars = React.createRef()
    this.state = { top: 0 }
    this.handleUpdate = this.handleUpdate.bind(this)
    this.renderView = this.renderView.bind(this)
    this.renderThumb = this.renderThumb.bind(this)
    this.handleSpringUpdate = this.handleSpringUpdate.bind(this)
  }

  handleUpdate(values) {
    const { top } = values
    this.setState({ top })
  }

  renderView({ style, ...props }) {
    const viewStyle = {
      padding: 0,
      overflowX: 'hidden',
      color: `#26439B`,
      top: 0,
      left: 0,
      width: '100%',
      height: '100vh',
      position: 'relative',
      backgroundColor:
        window.location.pathname === '/'
          ? '#C7C2E1'
          : window.location.pathname === '/teaser'
            ? '#C7C2E1'
            : window.location.pathname === '/teaser/'
              ? '#C7C2E1'
              : '#f0eee9',
    }
    return <div className='box' style={{ ...style, ...viewStyle }} {...props} />
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = {
      position: 'absolute',
      right: window.innerWidth < 1000 ? '-2px' : '0px',
      bottom: '140px',
      top: 0,
      borderRadius: '3px',
      zIndex: 1001,
      width: '8px',
      backgroundColor: `#26439B`,
      marginTop: 0,
    }
    return <div style={{ ...style, ...thumbStyle }} {...props} />
  }

  componentDidMount() {
    this.springSystem = new SpringSystem()
    this.spring = this.springSystem.createSpring()
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate })
  }

  componentWillUnmount() {
    this.springSystem.deregisterSpring(this.spring)
    this.springSystem.removeAllListeners()
    this.springSystem = undefined
    this.spring.destroy()
    this.spring = undefined
  }

  getScrollTop() {
    return this.scrollbars.current.getScrollTop()
  }

  getScrollHeight() {
    return this.scrollbars.current.getScrollHeight()
  }

  getHeight() {
    return this.scrollbars.current.getHeight()
  }

  scrollTop(top) {
    this.spring.setCurrentValue(0).setAtRest()
    this.spring.setEndValue(0)
  }

  handleSpringUpdate(spring) {
    const val = spring.getCurrentValue()
    this.scrollbars.current.scrollTop(val)
  }

  render() {
    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbVertical={this.renderThumb}
        autoHide={true}
        {...this.props}
        onScroll={this.props.onScroll}
        ref={this.scrollbars}
      />
    )
  }
}
