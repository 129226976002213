import { Link } from 'react-router-dom'
import UserNavStyles from './styles/UserNavStyles'
import Signout from './Signout'
import React from 'react'
import Loading from './Loading'
import { gql, useQuery } from '@apollo/client'

const UserNav = () => {
  const { data, loading } = useQuery(CURRENT_USER_QUERY)

  if (loading) return <Loading size='mini' />
  if (!data || !data.me) return <p />
  return (
    <UserNavStyles>
      {data.me && (
        <>
          <Link to='/projects' style={{ color: '#000' }}>
            Projects
          </Link>
          <Link to='/edit' style={{ color: '#000' }}>
            Profile
          </Link>
          <Signout />
        </>
      )}
      {!data.me && (
        <Link to='/login' style={{ color: '#000' }}>
          Log In
        </Link>
      )}
    </UserNavStyles>
  )
}

export default UserNav


const CURRENT_USER_QUERY = gql`
  query {
    me {
      id
      email
      firstName
      lastName
      tagline
      program
      primaryExpertise
      secondaryExpertise
      about
      image
      portfolio
      contactEmail
      behance
      linkedIn
      instagram
      twitter
      facebook
      dribble
      youtube
      vimeo
      medium
      permissions
    }
  }
`;