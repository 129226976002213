import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Psm } from '../../styles/2024/TextStyles'
import cn from 'classnames'

import { SpringContext } from '../../context/SpringContext'

export default function Archive() {
  const spring = useContext(SpringContext)
  const [isExpanded, setIsExpanded] = useState(false)
  const timeoutId = useRef(null)

  const handleScrollTop = () => {
    if (spring) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }

  useEffect(() => {
    if (spring) {
      spring.addListener({ onSpringUpdate: handleSpringUpdate })
      return () => spring.removeListener(handleSpringUpdate)
    }
  }, [spring])

  const handleSpringUpdate = (spring) => {
    const value = spring.getCurrentValue()
    // Use this value to update UI, e.g., scrolling position, opacity, transform, etc.
  }

  const menuClasses = cn(
    'fixed bottom-0 left-[16px] lg:left-[48px] bg-almost-black h-screen transition-transform duration-500 cursor-pointer',
    {
      'translate-y-[100vh] opacity-80': !isExpanded, // Menu slides in to natural position from right
      'translate-y-0 opacity-100': isExpanded, // Menu slides out to the right
    },
  )

  const handleExpand = () => {
    // Toggle the expanded state
    if (!isExpanded) {
      setIsExpanded(true)
      // Clear any existing timeout to prevent multiple timeouts running
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
      // Set a new timeout to collapse after 3 seconds
      timeoutId.current = setTimeout(() => {
        setIsExpanded(false)
        timeoutId.current = null // Reset the timeout ID after it executes
      }, 3000)
    } else {
      // If it's already expanded, collapse immediately
      setIsExpanded(false)
      // Clear the timeout if it exists
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
        timeoutId.current = null
      }
    }
  }

  return (
    <div className='fixed bottom-0 left-0 w-full bg-almost-black h-16 z-50 center-text'>
      <div className='absolute w-full bg-almost-black h-16 z-50 op-1/2 left-1/2 transform -translate-x-1/2 max-w-[1440px]'>
        <button onClick={() => handleExpand()} className='text-5xl inline-block mt-6 mb-20'>
          <div className='absolute flex items-center justify-center bottom-0 left-[16px] lg:left-[48px] bg-almost-black z-50 gap-4'>
            <img
              className='h-[14px] w-[20px] mb-[5px]'
              src={`${
                isExpanded ? '/static/2024/svg/openFolder.svg' : '/static/2024/svg/closedFolder.svg'
              }`}
              alt='Back to top'
            />
            <Psm
              className={`${
                isExpanded ? 'text-comfort-green' : 'text-beige-yellow'
              } w-64 text-left pb-2`}
            >
              Archive
            </Psm>
          </div>
        </button>
        <div className={menuClasses}>
          <Link to={{ pathname: '/2020' }} className='text-5xl inline-block mt-6 mb-20'>
            <div
              className={cn('w-64 bottom-64 bg-[#efd6d9]')}
              style={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '41px',
              }}
            >
              <p
                className='text-[#26439B]'
                style={{
                  position: 'relative',
                  fontFamily: 'sharp_grotesque',
                  fontSize: '42px',
                  lineHeight: '100%',
                  letterSpacing: '0.02em',
                  background: 'transparent',
                }}
              >
                2020
              </p>
            </div>
          </Link>
          <Link to={{ pathname: '/2021' }} className='text-5xl inline-block mt-6 mb-20'>
            <div
              className={cn('w-64 bottom-48 bg-[#5933ff]')}
              style={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '41px',
              }}
            >
              <p
                className='text-[#defc1c]'
                style={{
                  fontFamily: 'sharp_grotesque',
                  fontSize: '42px',
                  lineHeight: '100%',
                  letterSpacing: '0.02em',
                  background: 'transparent',
                }}
              >
                2021
              </p>
            </div>
          </Link>
          <Link to={{ pathname: '/2022/home' }} className='text-5xl inline-block mt-6 mb-20'>
            <div
              className={cn('w-64 bottom-32 bg-[#FF6044]')}
              style={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '41px',
              }}
            >
              <p
                className='text-[#26439B]'
                style={{
                  fontFamily: 'sharp_grotesque',
                  fontSize: '42px',
                  lineHeight: '100%',
                  letterSpacing: '0.02em',
                  background: 'transparent',
                }}
              >
                2022
              </p>
            </div>
          </Link>
          <Link to={{ pathname: '/2023/home' }} className='text-5xl inline-block mt-6 mb-20'>
            <div
              className={cn('w-64 bottom-16 bg-[#26439B]')}
              style={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '41px',
              }}
            >
              <p
                className='text-[#C7C2E1]'
                style={{
                  fontFamily: 'sharp_grotesque',
                  fontSize: '42px',
                  lineHeight: '100%',
                  letterSpacing: '0.02em',
                  background: 'transparent',
                }}
              >
                2023
              </p>
            </div>
          </Link>
        </div>
        <button onClick={() => handleScrollTop()} className='text-5xl inline-block mt-6 mb-20'>
          <div className='absolute flex items-center justify-center bottom-0 right-[16px] lg:right-[48px] b bg-almost-black z-50 gap-4'>
            <Psm className='text-beige-yellow m-0 mb-2'>Back to top</Psm>
            <img
              className='h-[16px] w-[16px] mb-4'
              src='/static/2024/svg/triangeTop.svg'
              alt='Back to top'
            />
          </div>
        </button>
      </div>
    </div>
  )
}
