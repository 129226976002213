import * as React from 'react'
import { H4, P, Asm } from '../../styles/2024/TextStyles'
import {programColour, disciplines } from '../../constant'
import { Link } from 'react-router-dom'

export const ProjectCard = ({ project, scrollTop }) => {
  return (
    <div className='flex flex-col self-stretch'>
      <Link
        to={{
          pathname: '/project',
          search: `id=${project?.id}`,
        }}
        onClick={() => {
          scrollTop && scrollTop()
        }}
        className='h-[300px] w-full overflow-hidden flex items-center justify-center'
      >
        <img src={encodeURI(project?.coverImage)} alt={project.title} className='object-cover w-full h-full' />

      </Link>
      <div className='flex flex-wrap justify-start items-center gap-4 pt-6'>
        {project?.members[0]?.user?.program && (
          <P className='text-beige-yellow inline-blockl p-0 m-0'>
            <span className={programColour[project?.members[0]?.user?.program]}>●</span>{' '}
            {project?.members[0]?.user?.program}
          </P>
        )}
        {project?.primaryProjectType && disciplines[project?.primaryProjectType] && (
          <P className='text-beige-yellow inline-block p-0 m-0'>
            ● {disciplines[project?.primaryProjectType]}
          </P>
        )}
      </div>
      <H4 className='text-beige-yellow mt-2'>{project?.title}</H4>
      <Link
        to={{
          pathname: '/student',
          search: `id=${project?.members[0]?.user?.id}`,
        }}
        onClick={() => {
          scrollTop && scrollTop()
        }}
      >
        <Asm className='w-fit text-beige-yellow border-b border-dotted border-beige-yellow hover:text-comfort-green cursor-pointer'>
          {project?.members[0]?.user?.firstName} {project?.members[0]?.user?.lastName}
        </Asm>
      </Link>
    </div>
  )
}
