import { Link } from "react-router-dom";
import UserNavStyles from "./styles/UserNavStyles";
import Signout from "./Signout";
import React from "react";
import { CURRENT_USER_QUERY } from './User';
import { Query } from "react-apollo";
import Loading from "./Loading";


const UserNav = () => {
  return (
    <Query query={CURRENT_USER_QUERY}>
      {({ data, loading }) => {
        if (loading) return <Loading size='mini'/>;
        if (!data || !data.me) return <p />
        return (
          <UserNavStyles>
            {data.me && (
              <>
                <Link to="/2020/projects">
                  Projects
                </Link>
                <Link to="/2020/edit">
                  Profile
                </Link>
                <Signout />
              </>
            )}
            {!data.me && (
              <Link to="/2020/login">
                Log In
              </Link>
            )}
          </UserNavStyles>
        )
      }}
    </Query>
  )
};

export default UserNav;
