import React, { useCallback, useState, useEffect, useContext } from 'react'
import { SpringContext } from '../../../context/SpringContext'
import { P, H2, A } from '../../../styles/2024/TextStyles'
import Programs from '../../../components/2024/Programs'
import { useWindowSize } from '../../../hook/2024/hooks/useWindowSize'
import { Link } from 'react-router-dom'
const Header = React.lazy(() => import('../../../components/2024/Header'))
const Countdown = React.lazy(() => import('../../../components/2024/Countdown'))
const Footer = React.lazy(() => import('../../../components/2024/Footer'))
const VideoModal = React.lazy(() => import('../../../components/2024/VideoModal'))
const YESdesktop = React.lazy(() =>
  import('../Teaser/lottie/LottieModal').then((module) => ({
    default: module.YESdesktop,
  })),
)
const YESmobile = React.lazy(() =>
  import('../Teaser/lottie/LottieModal').then((module) => ({
    default: module.YESmobile,
  })),
)

export default function Index() {
  const { screen } = useWindowSize()
  const isMobile = screen === 'sm'
  const [videoOpen, setVideoOpen] = useState(true)
  const [videoMute, setVideoMute] = useState(true)

  const spring = useContext(SpringContext)
  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring])

  const toggleOpenVideo = useCallback(() => {
    !videoOpen && setVideoMute(true)
    setVideoOpen((v) => !v)
  }, [videoOpen])
  const toggleMuteVideo = useCallback(() => setVideoMute((v) => !v), [])

  return (
    <div className='absolute w-full bg-almost-black flex flex-col items-center justify-start pb-20 lg:pb-0'>
      {Countdown && <Countdown />}
      {Header && <Header />}

      <div className='relative w-full bg-almost-black flex flex-cols-1 px-6 max-w-[1440px]'>
        {isMobile ? (
          <div className='relative flex flex-col bg-almost-black w-full'>
            <div className='relative grid grid-cols-1 items-center justify-center top-[-28vw]'>
              <YESmobile />
              <div className='absolute flex w-full justify-center row-span-5 bottom-0'>
                <div className='relative w-full flex flex-col items-center justify-center'>
                  <h2
                    className='text-beige-yellow uppercase font-light m-1'
                    style={{ fontFamily: 'grotesque_pro_light', fontSize: '20px' }}
                  >
                    George Brown College
                  </h2>
                  <h2
                    className='text-beige-yellow uppercase font-light m-1'
                    style={{ fontFamily: 'grotesque_pro_light', fontSize: '20px' }}
                  >
                    School of design
                  </h2>
                  <h3
                    className='text-beige-yellow font-light'
                    style={{ fontFamily: 'grotesque_pro_light', fontSize: '20px' }}
                  >
                    Year End Show{' '}
                    <a
                      className='text-comfort-green pointer hover:text-warm-orange '
                      href='https://www.instagram.com/sod_yes/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      @sod_yes
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='relative flex flex-col w-full bg-almost-black h-1/2 pb-20'>
            <div className='relative w-full h-1/2 bg-almost-black grid grid-cols-1 gap-4 items-center justify-center px-6'>
              <div className='relative grid grid-cols-1 items-center justify-center'>
                <YESdesktop />
                <div className='absolute flex w-full justify-center row-span-5 h-3/5'>
                  <div className='relative w-full flex flex-col items-center justify-end'>
                    <h2
                      className='text-beige-yellow uppercase font-light m-1'
                      style={{ fontFamily: 'grotesque_pro_light', fontSize: '20px' }}
                    >
                      George Brown College
                    </h2>
                    <h2
                      className='text-beige-yellow uppercase font-light m-1'
                      style={{ fontFamily: 'grotesque_pro_light', fontSize: '20px' }}
                    >
                      School of design
                    </h2>
                    <h3
                      className='text-beige-yellow font-light'
                      style={{ fontFamily: 'grotesque_pro_light', fontSize: '20px' }}
                    >
                      Year End Show{' '}
                      <a
                        className='text-comfort-green pointer hover:text-warm-orange '
                        href='https://www.instagram.com/sod_yes/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        @sod_yes
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='relative w-full min-h-max bg-comfort-green flex flex-col items-center justify-center w-full'>
        <div className='relative w-full bg-comfort-green flex flex-col items-left justify-center px-[16px] lg:px-[48px] py-20 max-w-[1440px]'>
          <div className='relative grid grid-cols-1 lg:grid-cols-2 gap-14'>
            <div className='relative flex flex-col'>
              <img
                loading='lazy'
                src='/static/2024/img/Frame54.jpeg'
                alt=''
                className='grow w-full'
              />
            </div>
            <div className='relative flex flex-col'>
              <div className='flex flex-col text-lg font-light text-almost-black '>
                <H2 className='uppercase'>
                  {isMobile ? 'Mark your calendar!' : 'join us for yes!'}
                </H2>
                <P className='mt-4 lg:pr-[20%]'>
                  YES! is the official annual graduate showcase for George Brown College’s School of
                  Design. This spring, more than 400 students will celebrate the culmination of
                  their studies at the School of Design; YES! serves as an interactive platform to
                  showcase and exhibit critical design solutions that have characterized their time
                  with us.
                </P>
                <br />
                <P className='relative mt-4 lg:pr-[20%]'>
                  Join us May 1-2 as we take over all three floors of our campus and celebrate the
                  talent of our students and faculty across our twelve diverse programs.
                </P>
                <Link to='/events'>
                  <A className='text-almost-black justify-center self-start p-1 mt-4 border-b border-dotted border-almost-black text-3xl hover:text-empowering-blue'>
                    Learn more about our event →
                  </A>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='relative w-full h-full bg-warm-orange flex flex-col items-center justify-center'>
        <div className='relative w-full h-full bg-warm-orange flex flex-col items-left justify-center px-[16px] lg:px-[48px] py-20 max-w-[1440px]'>
          <div className='flex flex-col lg:flex-row-reverse gap-14'>
            <div className='flex flex-col flex-1'>
              <img
                loading='lazy'
                src='/static/2024/img/GBCSOD.png'
                alt=''
                className='grow w-full aspect-[1.49]'
              />
            </div>
            <div className='flex flex-col flex-1'>
              <div className='flex flex-col text-lg font-light text-almost-black '>
                <H2 className='uppercase lg:w-[320px]'>YES! WE’re the School of design</H2>
                <P className='mt-4 lg:pr-[20%]'>
                  Located at the heart of Downtown Toronto, the School of Design at George Brown
                  College offers a dynamic and inclusive environment for aspiring designers to
                  flourish. As a team, we communicate and exchange ideas, fostering a strong cohort
                  that values inclusivity and diversity.
                </P>
                <Link to='/about'>
                  <A className='text-almost-black justify-center self-start p-1 mt-4 border-b border-dotted border-almost-black text-3xl hover:text-empowering-blue'>
                    Learn more about School of Design →
                  </A>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='relative w-full min-h-max bg-friendly-yellow flex flex-col items-center justify-center w-full'>
        <div className='relative w-full bg-friendly-yellow px-[16px] lg:px-[48px] py-20 pb-40 max-w-[1440px]'>
          <Programs />
        </div>
      </div>
      <div className='relative w-full min-h-max bg-empowering-blue flex flex-col justify-center items-center'>
        <div className='relative w-full bg-empowering-blue px-[16px] lg:px-[48px] py-20 pb-40  max-w-[1440px]'>
          <H2 className='uppercase text-beige-yellow text-center mb-8'>SHOWREEL</H2>
          <VideoModal
            videoOpen={videoOpen}
            setVideoClose={toggleOpenVideo}
            videoMute={videoMute}
            setVideoMute={toggleMuteVideo}
          />
        </div>
      </div>
      <div className='relative w-full bg-almost-black flex flex-col items-center justify-center w-full'>
        <div className='relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] bg-almost-black max-w-[1440px]'>
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}
