import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useLazyQuery } from '@apollo/client'
import StickerNavs from '../StickerNavs'
import qs from 'query-string'
import shuffle from 'lodash.shuffle'
import { useDebounce } from 'use-debounce'
import cn from 'classnames'
import { QUERY } from '../../../graphql'
import { InputSearch } from './InputSearch'
import { programGroups } from '../../../constant'
import SearchIcon from '../../../components/2022/SearchIcon'
import Footer from '../../../components/2022/Footer'
import { useDisclosure } from 'react-use-disclosure'
import { MobileRightNavs, MobileNavsBottom } from '../MobileRightNavs'
import FixedBottomNav from '../../../components/2022/FixedBottomNav'

const Main = styled.div`
  position: relative;
`
const FooterContainer =styled.div`
  postion: relative;
  bottom: 30px;
  height: 130vh;
  @media (min-width: 768px) {
    height: 150vh;
  }
  @media (min-width: 1024px) {
    height: 120vh;
  }
  @media (min-width: 2560px) {
      height: 90vh;
  }
`
const Container = styled.div`
  min-height: calc(100vh);
  cursor: url(${(props) => props.cursorUrl}), auto;
  z-index: 0;
  overflow: auto;
  padding-top: 0px;
  svg:first-child {
    // height: 1200px;
  }
  @media (max-width: 1024px) {
    padding-top: 110px;
  }
`
const TextH4 = styled.h4`
  font-family: 'reckless_light';
`

const CenterContainer = styled.div`
  @media (min-width: 1280px) {
    // top: 10%;
    // max-height: 40vh;
  }
  @media (min-width: 1920px) {
    // top: 20%;
  }
`
const TextCenter = styled.h4`
  font-family: 'sharp_grotesque_05';
  font-size: 420px;
  line-height: 360px;
  letter-spacing: 0.01em;
  font-weight: 400;
  @media (min-width: 360px) {
    font-size: 460px;
    line-height: 250px;
  }
  @media (min-width: 768px) {
    font-size: 650px;
    line-height: 450px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 500px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 600px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 700px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 700px;
  }
  @media (min-width: 2880px) {
    font-size: 1000px;
    line-height: 1200px;
  }
  @media (min-width: 2880px) {
    font-size: 1400px;
    line-height: 1400px;
  }
`

export default function Search(props) {
  const history = useHistory()
  const location = useLocation()
  const { search: searchParams } = location
  const { keyword } = qs.parse(searchParams)
  const [searchInput, setSearchInput] = useState(keyword || '')
  const [searchInputDebounce] = useDebounce(searchInput, 800)

  const [fetchSearch, { data: searchData, loading }] = useLazyQuery(QUERY.SEARCH_QUERY, {
    variables: {
      q: searchInputDebounce,
      year: 2022,
    },
  })

  const onInputChange = useCallback((e) => {
    const val = e.target.value
    setSearchInput(val)
  }, [])

  useEffect(() => {
    if (!searchInputDebounce || searchInputDebounce?.length === 0) {
      history.replace('/2022/search')
      return
    }
    history.push({ search: `?keyword=${searchInputDebounce}` })
  }, [searchInputDebounce, history])

  useEffect(() => {
    fetchSearch()
  }, [keyword, fetchSearch])

  const results = useMemo(() => {
    const users = searchData?.searchGradWork?.users || []
    const programs = !keyword
      ? programGroups.map((p) => ({ program: p }))
      : programGroups
          .filter((p) => p.toLowerCase().includes(keyword.toLowerCase()))
          .map((p) => ({
            program: p,
          }))
    return shuffle([...users, ...programs])
  }, [searchData, keyword])

  const renderResult = useMemo(() => {
    if (results?.length === 0) {
      return (
        <div className='w-full mt-4'>
          <p className='text-4xl text-center text-charcoal'>No results found, try again!</p>
        </div>
      )
    }

    const rows = results.map((result) => {
      if (result.__typename === 'User') {
        return (
          <Link
            className='w-full md:w-6/12 lg:w-4/12 inline-block text-charcoal bg-light-grey-2022  hover:bg-red-coral hover:text-charcoal p-10 transition'
            key={result.id}
            to={{
              pathname: '/2022/student',
              search: `id=${result.id}`,
            }}
          >
            <p className='inline-block border-b border-dashed border-red-coral text-4xl'>
              {result?.firstName} {result?.lastName}
            </p>
          </Link>
        )
      }
      return (
        <Link
          className='w-full md:w-6/12 lg:w-4/12 inline-block text-charcoal bg-light-grey-2022  hover:bg-red-coral hover:text-charcoal p-10 transition'
          key={result.program}
          to={{
            pathname: '/2022/grads',
            search: `program=${result.program}`,
          }}
        >
          <span className='w-3 h-3 bg-red-coral rounded-full inline-block mr-2'></span>
          <p className='inline-block border-b border-dashed border-red-coral text-4xl'>
            {result.program}
          </p>
        </Link>
      )
    })
    return rows
  }, [results])

  const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()

  return (
    <Main>
      <Container
        className='w-full h-full bg-light-grey-2022 flex mt-40 md:mt-20'
        cursorUrl='/static/svg/2022/cursor-with-shadow.svg'
      >
        <StickerNavs className={cn('w-96 relative h-screen transition-opacity duration-700 ease-in-out hidden lg:block')} />
        <CenterContainer className='w-full mx-auto py-12 absolute top-10 pb-0'>
        <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs} />
          <div
            className={cn( 'transition',{
              'flex visible opacity-100': !isOpenMenuNavs,
              'hidden invisible opacity-0': isOpenMenuNavs,
            })}
          >
          <div className='w-full'>
            <div className='text-center relative'>
              <TextCenter className='text-charcoal uppercase opacity-10'>Search</TextCenter>
              <InputSearch onChange={onInputChange} value={searchInput} />
            </div>
            <div className='mt-6 mb-10'>
              <TextH4 className='text-6xl text-charcoal text-center pt-40 md:pt-20'>Search Results</TextH4>
            </div>
            {loading && <div className='text-black relative center font-bold'>Loading...</div>}
            {searchData && searchData.searchGradWork && searchData.searchGradWork.length === 0 && <div className='z-8 text-charcoal relative text-center font-bold text-5xl'>No results, please try again.</div>}
            <div
              className={cn('flex justify-evenly lg:justify-between flex-wrap bg-light-grey-2022 relative z-10 px-0 lg:px-72', { 'bg-bg-light-grey-2022': (results?.length > 0 && window.innerWidth>800 ) })}
            >
              {renderResult}
            </div>
          </div>
          <SearchIcon goBack className='hidden lg:block' />
          </div>
          <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
          <FooterContainer className="single-item bg-light-grey-2022" data-color="off_white" style={{position:'relative',background:'#191919', overflow:'hidden', flex:'none' ,minWidth: '10vh', minHeight: '10vh'}}>
                    <div style={{position:'absolute', top: '-20px', width:'100vw', height:'100px', background:'#e3e3de', zIndex:'18'}}></div>
                    <Footer/>
        </FooterContainer>
        </CenterContainer>
      </Container>
      <FixedBottomNav className='hidden lg:block' />
    </Main>
  )
}
