import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import StickerNavs from '../StickerNavs'
import { DropdownFilter } from '../DropdownFilter'
import qs from 'query-string'
import groupBy from 'lodash.groupby'
import { QUERY } from '../../../graphql'
import shuffle from 'lodash.shuffle'
import { MobileNavsBottom, MobileRightNavs } from '../MobileRightNavs'
import { useDisclosure } from 'react-use-disclosure'
import cn from 'classnames'
import FixedBottomNav from '../../../components/2022/FixedBottomNav'
import { Element } from 'react-scroll'
import SearchIcon from '../../../components/2022/SearchIcon'
import './grads.scss';
import Footer from '../../../components/2022/Footer'

const Container = styled.div`
  min-height: calc(100vh);
  cursor: url(${(props) => props.cursorUrl}), auto;
  flex-direction: column;

`

const InterActiveMedia = styled.div`
  .classOf2021 {
    color: white;
    display: inline-block;
    font-size: 30px;
  }
  .interactive {
    display: inline-block;
    font-size: 30px;
  }
  .dot {
    height: 12px;
    width: 12px;
    background-color: #d5fd00;
    border-radius: 50%;
    margin: 8px 10px;
    // margin-bottom : 7px;
    display: inline-block;
  }
`

const CenterContainer = styled.div`
  @media (min-width: 1280px) {
    // top: 10%;
    // max-height: 40vh;
  }
  @media (min-width: 1920px) {
    // top: 20%;
  }
  width:-webkit-calc(100%- 48rem);
  width:-moz-calc(100% - 48rem);
  width:calc(100% - 48rem);

  @media (max-width: 1280px) {
    width: 100%;
  }
`
const TextCenter = styled.h4`
  color: #ff6044;
  font-family: 'sharp_grotesque_05';
  font-size: 420px;
  line-height: 360px;
  letter-spacing: 0.01em;
  font-weight: 400;
  @media (min-width: 360px) {
    font-size: 460px;
    line-height: 400px;
  }
  @media (min-width: 768px) {
    font-size: 550px;
    line-height: 550px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 600px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 700px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 1000px;
    line-height: 1200px;
  }
  @media (min-width: 2880px) {
    font-size: 1400px;
    line-height: 1400px;
  }
`

const GradList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  font-family: 'reckless_light';

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  a:hover {
    background-color: #ff6044;
  }
`

const FooterContainer =styled.div`
  height: 130vh;
  @media (min-width: 768px) {
    height: 150vh;
  }
  @media (min-width: 1024px) {
    height: 120vh;
  }
  @media (min-width: 2560px) {
      height: 90vh;
  }
`

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

export default function Grads(props) {
  const location = useLocation()
  const { search: searchParams } = location
  const { program, type } = qs.parse(searchParams)

  const [gradsSearch, setGradsSearch] = useState('')

  const { data, loading } = useQuery(QUERY.USERS_QUERY, {
    variables: {
      where: {
        studentYear: 2022,
        activeAt_gt: '2021-12-31',
        nameSearch: gradsSearch
      },
    },
  })

  const grads = useMemo(() => {
    if (!data?.users) {
      return {}
    }
    let users = data?.users || []

    if (program) {
      users = users.filter((user) => user.program === program)
    }
    if (type) {
      users = users.filter(stu => {
        const stdPrimaryExpertise = stu.primaryExpertise?.replace(' ', '_')?.toLowerCase() || ''
        const stdSecondaryExpertise = stu.secondaryExpertise?.replace(' ', '_')?.toLowerCase() || ''
        if (!stdPrimaryExpertise) return stdSecondaryExpertise === type
        if (!stdSecondaryExpertise) return stdPrimaryExpertise === type
        return stdPrimaryExpertise === type || stdSecondaryExpertise === type
      })
    }
    // if (type) {
    //   users = users.filter((user) => (user.primaryExpertise === type) || (user.secondaryExpertise === type))
    // }
    const grouped = groupBy(shuffle(users), 'program')
    const programGroups = [
      "Graphic Design",
      "Interaction Design",
      "Game - Art",
      "Digital Experience Design",
      "Art & Design Foundation",
      "Interactive Media Management",
      "Web Development - Front-End Design",
      "Digital Design - Game Design",
      "Concept Art for Entertainment",
      "Design Management",
      "Interdisciplinary Design Strategy",
    ]
    const obj = {}
    programGroups.forEach((key) => {
      if (grouped[key]?.length > 0) {
        obj[key] = grouped[key]
      }
    })

    return obj
  }, [data, program, type])

  const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()

  //require('./grads.scss')

  useEffect(() => {
    let body = document.getElementsByTagName('body')?.[0]
    body.style.height = 'auto'
    window.scroll(0, 0)
  }, [])

  const gradsSorted = useMemo(() => {
    // let lists = []
    const alphabetKey = {}
    Object.keys(grads).forEach((key) => {
      const sorted = grads[key].sort(function (a, b) {
        var textA = a.firstName.toUpperCase()
        var textB = b.firstName.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
      alphabetKey[key] = sorted
      // lists.push(sorted)
    })
    Object.entries(alphabetKey).forEach(([gradName, students]) => {
      const obj = {}
      alphabet.forEach((char) => {
        obj[char] = students
          .filter((grad) => !!grad.firstName)
          .filter((grad) => grad.firstName.charAt(0).toUpperCase() === char)
      })
      alphabetKey[gradName] = obj
    })
    // lists = lists.map((list) => {
    //   const obj = {}
    //   alphabet.forEach((char) => {
    //     obj[char] = list
    //       .filter((grad) => !!grad.firstName)
    //       .filter((grad) => grad.firstName.charAt(0).toUpperCase() === char)
    //   })
    //   return obj
    // })
    // return lists
    return alphabetKey
  }, [grads])
  // console.log('gradsSorted -> gradsSorted', gradsSorted)

  const isEmptyWorkVisible = useMemo(() => {
    if (Object.keys(gradsSorted).length === 0) {
      return true
    }
    return false
  }, [gradsSorted])

  return (
    <>
      <Element name="myScrollToElement" id="scroll2022top"></Element>
      <Container
        className='w-full h-full flex mt-28 grads-scroll'
        id='top'
        cursorUrl='/static/svg/2022/cursor-with-shadow.svg'
        style={{ background: '#e3e3de'}}
      >
        <svg
          width='100%'
          viewBox='0 0 2198 1347'
          version='1.1'
          style={{ position: 'absolute'}}
          id="curvyBG"
        >
          <g
            id='teaser-bg-xl'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
            style={{ position: 'absolute', width: '100vw'}}
          >
            <rect fill='#e3e3de' x='0' y='0' width='2198' height='1347'></rect>
            <path
              d='M-611,-35 L2738,-40 C2458.19686,301.117387 2218.7579,511.757842 2019.68312,591.921364 C1387.94312,846.313536 626.827196,516.489551 74.221014,1022.52081 C-213.850221,1286.31342 -1029,1869 -1212,1008 C-1334,434 -1133.66667,86.3333333 -611,-35 Z'
              id='Fill-2'
              stroke='#e3e3de'
              fill='#191919'
            ></path>
          </g>
        </svg>
        <StickerNavs className='w-96 fixed top-0 h-screen transition-opacity duration-700 ease-in-out hidden lg:block z-20' />
        <CenterContainer className='w-full mx-auto pb-12'>
          <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs} />
          <div
            className={cn('transition', {
              'flex visible opacity-100': !isOpenMenuNavs,
              'hidden invisible opacity-0': isOpenMenuNavs,
            })}
          >
            <div className='w-full xl:w-full '>
              <div className='text-center relative z-10'>
                <TextCenter className='text-secondary' style={{ pointerEvents: 'none' }}>
                  GRADS
                </TextCenter>
                <DropdownFilter program={program} type={type} onChangeSearchGrads={setGradsSearch} />
              </div>
              {loading && <div className='z-20 text-black relative center font-bold'>Loading...</div>}
              {data && data.users && data.users.length === 0 && <div className='z-20 text-black relative center font-bold'>No results, try again.</div>}
              {isEmptyWorkVisible && <div className='z-20 text-black relative center font-bold'>No results, try again.</div>}
              <div id='wrapper' className='z-10 pt-10 '>
                {Object.keys(gradsSorted).map((key) => {
                  return (
                    <div key={key} className=''>
                      <InterActiveMedia>
                        <div
                          className='text-center lg: my-2'
                          style={{
                            maxWidth: '50%',
                            margin: '0 auto',
                            padding: '50px',
                            fontFamily: 'sharp_grotesque_15',
                          }}
                        >
                          {/* <p className='text-5xl text-white lg:text-6xl my-4 lg:my-8'>Class of 2021 */}
                          <span className='flex justify-center items-center lg:inline-block text-5xl text-secondary pt-12 mt-4 lg:mt-0 mb-4 lg:mb-0'>
                            <span
                              className='pb-2 lg:pb-1'
                              style={{
                                color: '#191919',
                                zIndex: 9,
                                position: 'relative',
                                fontSize: '60px',
                                fontWeight: '900',
                              }}
                            >
                              {/* <span className='dot'></span> */}
                              {key.toUpperCase()}
                            </span>
                          </span>
                          {/* </p> */}
                        </div>
                      </InterActiveMedia>
                      {Object.entries(gradsSorted[key])
                        .filter(([alphabetKey, students]) => students.length > 0)
                        .map(([alphabetKey, students]) => (
                          <div key={alphabetKey} className='w-full m:m-42 relative' style={{zIndex:9}}>
                            <p className='uppercase text-charcoal text-8xl m-0 px-12 pt-2 pb-8 z-2'>{alphabetKey}</p>
                            <div className='flex'>
                              <GradList
                                className='bg-dark relative z-2'
                                style={{ background: '#e3e3de', color: '#191919', width: '100%'}}
                              >
                                {students.map((grad) => {
                                  return (
                                    <Link
                                      key={grad.id}
                                      className='inline-block p-10 transition'
                                      to={{
                                        pathname: '/2022/student',
                                        search: `id=${grad.id}`,
                                      }}
                                    >
                                      <p
                                        className='text-white text-3xl inline'
                                        style={{
                                          borderBottom: '1px #ff6044 dashed',
                                          color: '#191919',
                                          fontSize: '30px',
                                        }}
                                      >
                                        {grad?.firstName} {grad?.lastName}
                                      </p>
                                    </Link>
                                  )
                                })}
                              </GradList>
                            </div>
                          </div>
                        ))}
                      {/* <div className='flex'>
                        <GradList
                          className='bg-dark w-full lg:mt-64 m:m-42 relative z-2'
                          style={{ background: '#e3e3de', color: '#191919' }}
                        >
                          {grads[key]
                            .sort(function (a, b) {
                              var textA = a.firstName.toUpperCase()
                              var textB = b.firstName.toUpperCase()
                              return textA < textB ? -1 : textA > textB ? 1 : 0
                            })
                            .map((grad) => {

                              return (
                                <Link
                                  key={grad.id}
                                  className='inline-block p-10 transition'
                                  to={{
                                    pathname: '/student',
                                    search: `id=${grad.id}`,
                                  }}
                                >
                                  <p
                                    className='text-white text-4xl inline'
                                    style={{
                                      borderBottom: '1px #ff6044 dashed',
                                      color: '#191919',
                                      fontSize: '40px',
                                    }}
                                  >
                                    {grad?.firstName} {grad?.lastName}
                                  </p>
                                </Link>
                              )
                            })}
                        </GradList>
                      </div> */}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className='w-12 bg-blue hidden lg:block'>
              {/* <img src='/static/svg/glass.svg' alt='glass' className={cn('cursor-pointer')} /> */}
            </div>
          </div>
          <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
        </CenterContainer>
        <SearchIcon className='hidden lg:block' />
        <FixedBottomNav className='hidden lg:block' />

        <FooterContainer className="single-item bg-light-grey-2022" data-color="off_white" style={{position:'relative',background:'#191919', overflow:'hidden', flex:'none' ,minWidth: '10vh', minHeight: '10vh'}}>
                    <div style={{position:'absolute', top: '-20px', width:'100vw', height:'100px', background:'#e3e3de', zIndex:'9'}}></div>
                    <Footer/>
        </FooterContainer>
      </Container>
    </>
  )
}
