import React, {useEffect, useMemo, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import {useQuery} from '@apollo/client'
import StickerNavs from '../StickerNavs'
import {DropdownFilter} from '../DropdownFilter'
import qs from 'query-string'
import groupBy from 'lodash.groupby'
import {QUERY} from '../../../graphql'
import shuffle from 'lodash.shuffle'
import {MobileNavsBottom, MobileRightNavs} from '../MobileRightNavs'
import {useDisclosure} from 'react-use-disclosure'
import cn from 'classnames'
import FixedBottomNav from '../FixedBottomNav'
import {Element} from 'react-scroll'
import SearchIcon from '../../../components/2023/SearchIcon'
import Footer from '../Footer'
import cursorSVG from '../cursor-with-shadow-2023.svg';
import {
    TextCenterDiv,
    FooterContainer,
    AlphabetBarDiv,
    GradBGTop,
    GradBGBottom
} from "../../../styles/2023/GradsStyle";

const Container = styled.div`
  position: relative;
  cursor: url(${cursorSVG}), auto;
  flex-direction: column;
  min-height: calc(100vh);
  //overflow: hidden;
`

const InterActiveMedia = styled.div`
  .classOf2021 {
    color: white;
    display: inline-block;
    font-size: 30px;
  }

  .interactive {
    display: inline-block;
    font-size: 30px;
  }

  .dot {
    height: 12px;
    width: 12px;
    background-color: #d5fd00;
    border-radius: 50%;
    margin: 8px 10px;
    // margin-bottom : 7px;
    display: inline-block;
  }
`

const CenterContainer = styled.div`
  padding-bottom: 500px;
  .selected-letter-text {
    margin-top: 30px;
    padding-left: 25px;
    width: 100%;
    text-align: left;
    font-family: Reckless_light, serif;
    font-size: 40px;
    line-height: 45px;
    letter-spacing: 0.01em;
    font-weight: 400;
  }

  @media (min-width: 1280px) {
    // top: 10%;
    // max-height: 40vh;
  }
  @media (min-width: 1920px) {
    // top: 20%;
  }
  width: -webkit-calc(100% - 48rem);
  width: -moz-calc(100% - 48rem);
  width: calc(100% - 48rem);

  @media (max-width: 1280px) {
    width: 100%;
  }
`
const TextCenter = styled.h4`
  position: relative;
  text-align: center;
  z-index: 10;
  color: #F0EEE9;
  font-family: Reckless_light, serif;
  font-size: 250px;
  line-height: 320px;
  letter-spacing: -0.02em;
  font-weight: 300;
  @media (max-width: 2879px) {

  }

  @media (max-width: 2559px) {

  }

  @media (max-width: 1919px) {

  }

  @media (max-width: 1679px) {

  }

  @media (max-width: 1279px) {

  }

  @media (max-width: 767px) {
    font-size: 120px;
    line-height: 130px;
  }

  @media (max-width: 359px) {

  }
`

const GradList = styled.div`
  display: grid;
  color: #191919;
  grid-template-columns: 1fr;
  font-family: 'reckless_light',sans-serif;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  a:hover {
    color: #ffffff;
    background-color: #26439B
  }
`

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

export default function Grads(props) {
    const location = useLocation()
    const {search: searchParams} = location
    const {program, type} = qs.parse(searchParams)

    const [gradsSearch, setGradsSearch] = useState('')

    const {data, loading} = useQuery(QUERY.USERS_QUERY, {
        variables: {
            where: {
                studentYear: 2023,
                activeAt_gt: '2022-12-31',
                nameSearch: gradsSearch
            },
        },
    })

    const grads = useMemo(() => {
        if (!data?.users) {
            return {}
        }
        let users = data?.users || []

        if (program) {
            users = users.filter((user) => user.program === program)
        }
        if (type) {
            users = users.filter(stu => {
                const stdPrimaryExpertise = stu.primaryExpertise?.replace(' ', '_')?.toLowerCase() || ''
                const stdSecondaryExpertise = stu.secondaryExpertise?.replace(' ', '_')?.toLowerCase() || ''
                if (!stdPrimaryExpertise) return stdSecondaryExpertise === type
                if (!stdSecondaryExpertise) return stdPrimaryExpertise === type
                return stdPrimaryExpertise === type || stdSecondaryExpertise === type
            })
        }
        // if (type) {
        //   users = users.filter((user) => (user.primaryExpertise === type) || (user.secondaryExpertise === type))
        // }
        const grouped = groupBy(shuffle(users), 'program')
        const programGroups = [
            "Brand Design",
            "Graphic Design",
            "Interaction Design",
            "Game - Art",
            "Digital Experience Design",
            "Art & Design Foundation",
            "Interactive Media Management",
            "Web Development - Front-End Design",
            "Digital Design - Game Design",
            "Concept Art for Entertainment",
            "Design Management",
            "Front-End Design",
        ]
        const obj = {}
        programGroups.forEach((key) => {
            if (grouped[key]?.length > 0) {
                obj[key] = grouped[key]
            }
        })

        return obj
    }, [data, program, type])

    const {isOpen: isOpenMenuNavs, toggle: toggleMobileNavs} = useDisclosure()

    //require('./grads.scss')

    useEffect(() => {
        let body = document.getElementsByTagName('body')?.[0]
        body.style.height = 'auto'
        window.scroll(0, 0)
    }, [])

    const gradsSorted = useMemo(() => {
        // let lists = []
        const alphabetKey = {}
        Object.keys(grads).forEach((key) => {
            const sorted = grads[key].sort(function (a, b) {
                var textA = a.firstName.toUpperCase()
                var textB = b.firstName.toUpperCase()
                return textA < textB ? -1 : textA > textB ? 1 : 0
            })
            alphabetKey[key] = sorted
            // lists.push(sorted)
        })
        Object.entries(alphabetKey).forEach(([gradName, students]) => {
            const obj = {}
            alphabet.forEach((char) => {
                obj[char] = students
                    .filter((grad) => !!grad.firstName)
                    .filter((grad) => grad.firstName.charAt(0).toUpperCase() === char)
            })
            alphabetKey[gradName] = obj
        })
        return alphabetKey
    }, [grads])

    const isEmptyWorkVisible = useMemo(() => {
        if (Object.keys(gradsSorted).length === 0) {
            return true
        }
        return false
    }, [gradsSorted])

    const [selectedLetter, setSelectedLetter] = useState('');

    const handleLetterClick = (letter) => {
        setSelectedLetter(letter);
    };

    const AlphabetBar = ({ onLetterClick }) => {
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

        return (
            <AlphabetBarDiv>
                <div className="alphabet-bar">
                    {alphabet.map((letter, index) => (
                        <button
                            key={index}
                            className={`alphabet-btn${selectedLetter === letter ? ' selected' : ''}`}
                            onClick={() => onLetterClick(letter)}
                        >
                            {letter}
                        </button>
                    ))}
                </div>
            </AlphabetBarDiv>
        );
    };

    return (
        <>
            <Element name="myScrollToElement" id="scroll2023top"></Element>
            <Container
                className="w-full flex grads-scroll"
                id="top"
                style={{background: '#F0EEE9'}}
            >
                <GradBGTop>
                    <img
                        src={`/static/svg/2023/Grads/GradsBGTop.png`}
                        alt=""
                    />
                </GradBGTop>
                <SearchIcon className='hidden lg:block' />
                <StickerNavs
                    className="w-96 fixed top-0 transition-opacity duration-700 ease-in-out hidden lg:block z-50"/>
                <CenterContainer className="w-full mx-auto pb-12 z-40">
                    <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs}/>
                    <div
                        className={cn('transition', {
                            'flex visible opacity-100': !isOpenMenuNavs,
                            'hidden invisible opacity-0': isOpenMenuNavs,
                        })}
                    >
                        <div className="w-full xl:w-full ">
                            <TextCenterDiv>
                                <TextCenter className="text-secondary" style={{pointerEvents: 'none'}}>
                                    Grads
                                </TextCenter>
                            </TextCenterDiv>
                            <div style={{display: 'flex', flexDirection: "column", justifyContent: "space-between", alignItems: "center", textAlign: "center", height: "228px"}}>
                                <DropdownFilter program={program} type={type} onChangeSearchGrads={setGradsSearch}/>
                            </div>
                            <div>
                                <AlphabetBar onLetterClick={handleLetterClick} />
                            </div>
                            {loading && <div className="z-20 text-black relative center font-bold">Loading...</div>}
                            {data && data.users && data.users.length === 0 &&
                                <div className="z-20 text-black relative center font-bold">No results, try again.</div>}
                            {isEmptyWorkVisible &&
                                <div className="z-20 text-black relative center font-bold">No results, try again.</div>}
                            <div id="wrapper" className="z-10 pt-10 ">
                                <div className="selected-letter-text">{selectedLetter}</div>
                                {Object.keys(gradsSorted)
                                    .filter((key) => {
                                        return selectedLetter === "" || gradsSorted[key][selectedLetter].length > 0
                                    })
                                    .map((key) => {
                                    return (
                                        <div key={key} className="">
                                            <InterActiveMedia>
                                                <div
                                                    className="text-center lg: my-2"
                                                    style={{
                                                        maxWidth: '70%',
                                                        margin: '0 auto',
                                                        padding: '50px',
                                                        fontFamily: 'Reckless_light',
                                                    }}
                                                >
                                                    {/* <p className='text-5xl text-white lg:text-6xl my-4 lg:my-8'>Class of 2021 */}
                                                    <span
                                                        className="flex justify-center items-center lg:inline-block text-5xl text-secondary pt-12 mt-4 lg:mt-0 mb-4 lg:mb-0">
                                                        <span
                                                            className="pb-2 lg:pb-1"
                                                            style={{
                                                                color: '#191919',
                                                                zIndex: 9,
                                                                position: 'relative',
                                                                fontSize: '48px',
                                                                fontWeight: '400',
                                                                letterSpacing: '-2px'
                                                            }}
                                                        >
                                                        {/* <span className='dot'></span> */}
                                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                                        </span>
                                                     </span>

                                                    {/* </p> */}
                                                </div>
                                            </InterActiveMedia>
                                            {Object.entries(gradsSorted[key])
                                                // .filter(([alphabetKey, students]) => ( alphabetKey.toUpperCase() === selectedLetter && students.length > 0))
                                                .filter(([alphabetKey, students]) => ( students.length > 0))
                                                .map(([alphabetKey, students]) => (
                                                    <div key={alphabetKey} className="w-full m:m-42 relative"
                                                         style={{zIndex: 9}}>
                                                        {selectedLetter === "" && (
                                                            <p className="uppercase text-charcoal text-6xl m-0 px-12 pt-2 pb-8 z-2">
                                                                {alphabetKey}
                                                            </p>
                                                        )}
                                                        <div className="flex">
                                                            <GradList
                                                                className="bg-dark relative z-2"
                                                                style={{
                                                                    background: 'rgba(199,194,225,0)',
                                                                    color: '#191919',
                                                                    width: '100%',
                                                                    borderRadius: '20px'
                                                                }}
                                                            >
                                                                {
                                                                    students
                                                                        .filter(
                                                                            (grad) =>
                                                                                selectedLetter === "" ||
                                                                                grad.firstName
                                                                                    .charAt(0)
                                                                                    .toUpperCase() ===
                                                                                selectedLetter
                                                                        )
                                                                        .map((grad) => {
                                                                    return (
                                                                        <Link
                                                                            key={grad.id}
                                                                            className="inline-block p-10 transition"
                                                                            to={{
                                                                                pathname: '/2023/student',
                                                                                search: `id=${grad.id}`,
                                                                            }}
                                                                        >
                                                                            <p
                                                                                className="text-3xl inline"
                                                                                style={{
                                                                                    borderBottom: '1px #26439B dotted',
                                                                                    fontSize: '30px',
                                                                                }}
                                                                            >
                                                                                {grad?.firstName} {grad?.lastName}
                                                                            </p>
                                                                        </Link>
                                                                    )
                                                                })}
                                                            </GradList>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="w-12 bg-blue hidden lg:block">
                            {/* <img src='/static/svg/glass.svg' alt='glass' className={cn('cursor-pointer')} /> */}
                        </div>
                    </div>
                    <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs}/>
                </CenterContainer>
                <GradBGBottom>
                    <img
                        src={`/static/svg/2023/Grads/GradsBGBottom.png`}
                        alt=""
                    />
                </GradBGBottom>
                <FixedBottomNav className="hidden lg:block"/>
                <FooterContainer data-color="off_white" >
                    <Footer/>
                </FooterContainer>
            </Container>
        </>
    )
}
