import styled from "styled-components";

const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  img {
    flex: 0 0 64px;
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 50%;

    @media (min-width: 768px) {
      flex: 0 0 70px;
      height: 70px;
      width: 70px;
    }
  }
  
  h3 {
    align-items: center;
    display: flex;
    font-family: 'reckless_light';
    justify-content: flex-start;
    margin: 0;
    padding: 0 10px;
    padding-left: 21px;

    @media (min-width: 768px) {
      padding-left: 50px;
    }
  }

  a {
    background: white;
    color: black;
    display: inline;
    font-size: 20px;
    font-weight: 100;
    flex-grow: 1;
    line-height: 1.3;
    margin: 0;
    padding: 0;
    text-align: left;

    @media (min-width: 576px) {
      font-size: 30px;
    }

    @media (min-width: 992px) {
      font-size: 32px;
    }
  }
`;

export default Item;
