import {gql} from '@apollo/client'

export const USERS_QUERY = gql`
  query UsersQuery($where: UserWhereInput) {
    users(where: $where) {
      id
      email
      firstName
      lastName
      tagline
      program
      contactEmail
      primaryExpertise
      secondaryExpertise
      about
      image
      portfolio
      behance
      linkedIn
      instagram
      twitter
      facebook
      dribble
      youtube
      vimeo
      medium
      permissions
    }
  }
`

export const GRADS_PAGE_QUERY = gql`
  query UsersQuery($where: UserWhereInput, $skip: Int, $first: Int) {
    users(where: $where, skip: $skip, first: $first) {
      id
      email
      firstName
      lastName
      tagline
      program
      contactEmail
      primaryExpertise
      secondaryExpertise
      about
      image
      portfolio
      behance
      linkedIn
      instagram
      twitter
      facebook
      dribble
      youtube
      vimeo
      medium
      permissions
    }
  }
`

export const GET_TOTAL_GRADS_COUNT = gql`
  query GetTotalGradsCount {
    usersConnection(where: {
      studentYear: 2024
      activeAt_gt: "2023-12-31"
    }) {
      aggregate {
        count
      }
    }
  }
`;