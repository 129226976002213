import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { SpringSystem } from 'rebound'
import { SpringContext } from '../../context/SpringContext'

export default class ColoredScrollbars2024 extends Component {
  constructor(props, ...rest) {
    super(props, ...rest)
    this.scrollbars = React.createRef()
    this.state = { top: 0, spring: null }
    this.handleUpdate = this.handleUpdate.bind(this)
    this.renderView = this.renderView.bind(this)
    this.renderThumb = this.renderThumb.bind(this)
    this.handleSpringUpdate = this.handleSpringUpdate.bind(this)
  }

  handleUpdate(values) {
    const { top } = values
    this.setState({ top })
  }

  renderView({ style, ...props }) {
    const viewStyle = {
      padding: 0,
      color: `#26439B`,
      position: 'relative',
      overflowX: 'hidden',
      top: 0,
      left: 0,
      marginRight: '0px',
      marginBottom: '0px',
      backgroundColor: `#181818`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
    return <div className='min-safe-h-screen overflow-hidden w-screen lg:w-full bg-almost-black' style={{ ...style, ...viewStyle }} {...props} />
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = {
      position: 'absolute',
      right: window.innerWidth < 1000 ? '-2px' : '0px',
      bottom: 0,
      top: 0,
      borderRadius: '3px',
      zIndex: 1001,
      width: '8px',
      backgroundColor: `#2371E8`,
      marginTop: 0,
    }
    return <div style={{ ...style, ...thumbStyle }} {...props} />
  }

  componentDidMount() {
    this.springSystem = new SpringSystem()
    this.spring = this.springSystem.createSpring()
    this.setState({ spring: this.spring });
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate })
  }

  componentWillUnmount() {
    if (this.springSystem && this.spring) {
      this.springSystem.deregisterSpring(this.spring)
      this.spring.removeAllListeners()
      this.spring.destroy()
    }
    this.springSystem = undefined
    this.spring = undefined
  }

  getScrollTop() {
    return this.scrollbars.current.getScrollTop()
  }

  getScrollHeight() {
    return this.scrollbars.current.getScrollHeight()
  }

  getHeight() {
    return this.scrollbars.current.getHeight()
  }

  scrollTop(top) {
    this.spring.setCurrentValue(0).setAtRest()
    this.spring.setEndValue(0)
  }

  handleSpringUpdate(spring) {
    if (this.spring && this.scrollbars.current) {
      const val = spring.getCurrentValue()
      this.scrollbars.current.scrollTop(val)
    }
  }

  render() {
    return (
      <SpringContext.Provider value={this.state.spring}>
        <Scrollbars
          renderView={this.renderView}
          renderThumbVertical={this.renderThumb}
          autoHide={true}
          {...this.props}
          onScroll={this.props.onScroll}
          ref={this.scrollbars}
        />
      </SpringContext.Provider>
    )
  }
}
