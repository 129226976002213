import { useState, useEffect, useCallback } from 'react'

// const EmptySSRRect = {
//   bottom: 0,
//   height: 0,
//   left: 0,
//   right: 0,
//   top: 0,
//   width: 0,
//   x: 0,
//   y: 0,
// }

export default function useScroll(ref){
  const [state, setState] = useState({
    lastScrollTop: 0,
    bodyOffset: document.body.getBoundingClientRect(),
    scrollY: document.body.getBoundingClientRect().top,
    scrollX: document.body.getBoundingClientRect().left,
    scrollDirection: '', // down, up
  })

  const handleScrollEvent = useCallback((e) => {
    setState((prevState) => {
      const prevLastScrollTop = prevState.lastScrollTop
      const bodyOffset = document.body.getBoundingClientRect()

      return {
        setBodyOffset: bodyOffset,
        scrollY: -bodyOffset.top,
        scrollX: bodyOffset.left,
        scrollDirection: prevLastScrollTop > -bodyOffset.top ? 'down' : 'up',
        lastScrollTop: -bodyOffset.top,
      }
    })
  }, [])

  useEffect(() => {
    const scrollListener = (e) => {
      handleScrollEvent(e)
    }
    if (ref) {
      ref.addEventListener('scroll', scrollListener)
    } else {
      window.addEventListener('scroll', scrollListener)
    }

    return () => {
      if (ref) {
        ref.removeEventListener('scroll', scrollListener)
      } else {
        window.removeEventListener('scroll', scrollListener)
      }
    }
  }, [handleScrollEvent, ref])

  return {
    scrollY: state.scrollY,
    scrollX: state.scrollX,
    scrollDirection: state.scrollDirection,
  }
}
