import React from 'react';
import { Route } from 'react-router-dom';
import {
    Index2020,
    Profile2020,
    Grads2020,
    Work2020,
    PreviewProject2020,
} from '../pages/2020'

export const getRoutes2020 = () => [

    <Route key={"Index2020"} exact path="/2020" component={Index2020}/>,
    <Route key={"Grads2020"} exact path="/2020/grads" component={Grads2020}/>,
    <Route key={"Work2020"} exact path="/2020/work" component={Work2020}/>,
    <Route key={"PreviewProject2020"} exact path="/2020/preview" component={PreviewProject2020}/>,
    <Route key={"Profile2020"} exact path="/2020/student" component={Profile2020}/>,
];
