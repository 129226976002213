import styled, { createGlobalStyle } from 'styled-components'

export const theme = {
  pink: '#F7D8E0',
  black: '#000000',
  grey: '#3A3A3A',
  lightgrey: '#E1E1E1',
  offWhite: '#EDEDED',
  white: '#FFFFFF',
  maxWidth: '80vw',
  bs: '0 12px 24px 0 rgba(0, 0, 0, 0.09)',
  blue: '#5933FF',
  secondary: '#DEFC1C',
  orange2022: '#ff6044',
  grey2022: '#e3e3de',
  black2022: '#191919',
  madBlue: '#26439B',
  smokey: '#C7C2E1',
  coton: '#F0EEE9',
}

export const StyledPage = styled.div`
  // background: white;
  // color: ${(props) => props.theme.black};
  // width: 100vw;
  // position: relative;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100vh; /* old browsers */
  height: 100dvh; /* new browsers */

  width: 100%;

`

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'reckless_light';
  font-style: normal;
  font-weight: 300;
  src: url('/static/Reckless-Light.woff2') format('woff2');

}

@font-face {
  font-family: 'grotesque_pro_light';
  src: url('/static/basis-grotesque-light-pro.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'basis-grotesque-light-pro';
  font-style: normal;
  font-weight: 300;
  src: url('/static/basis-grotesque-light-pro.eot');
  src:
    url('/static/basis-grotesque-light-pro.eot?#iefix') format('embedded-opentype'),
    url('/static/basis-grotesque-light-pro.woff2') format('woff2'),
    url('/static/basis-grotesque-light-pro.woff') format('woff'),
    url('/static/basis-grotesque-light-pro.ttf') format('truetype');
  }
  @font-face {
    font-family: 'sharp_grotesque';
    src: url('/static/SharpGroteskBlack10.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'sharp_grotesque_05';
    src: url('/static/SharpGroteskBlack05.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  // @font-face {
  //   font-family: 'sharp_grotesque_15';
  //   font-style: normal;
  //   font-weight: 900;
  //   src: url('/static/SharpGroteskBlack15.woff2') format('woff2');
  //   font-style: normal;
  // }

  @font-face {
    font-family: 'sharp_grotesque_15';
    font-style: normal;
    font-weight: 900;
    src: local(''),
      url('/static/SharpGroteskBlack15.woff2') format('woff2'),
      url('/static/SharpGroteskBlack15.woff') format('woff');
      url('/static/SharpGroteskBlack15.ttf') format('truetype');
  }

  @font-face {
    font-family: 'sharpgrotesque15';
    font-style: normal;
    font-weight: 900;
    src: local(''),
      url('/static/SharpGroteskBlack15.woff2') format('woff2'),
      url('/static/SharpGroteskBlack15.woff') format('woff');
      url('/static/SharpGroteskBlack15.ttf') format('truetype');
  }

  @font-face {
    font-family: 'sharpgrotesque20';
    font-style: normal;
    font-weight: 900;
    src: local(''),
      url('/static/SharpGroteskBlack20.woff2') format('woff2'),
      url('/static/SharpGroteskBlack20.woff') format('woff');
      url('/static/SharpGroteskBlack20.ttf') format('truetype');
  }

  @font-face {
    font-family: 'sharpgrotesque25';
    src: local(''),
      url('/static/SharpGroteskBlack25.woff2') format('woff2'),
      url('/static/SharpGroteskBlack25.woff') format('woff');
      url('/static/SharpGroteskBlack25.ttf') format('truetype');
  }

  html {
    box-sizing: border-box;
    font-size: 10px;
    margin: 0;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  body {
    padding: 0;
    margin: 0;
    font-size: 1.8rem;
    line-height: 2;
    font-family: 'reckless_light';
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;
    color: ${theme.black};
  }

  button {
    font-family: 'reckless_light';
  }

  #nprogress .bar {
    background: #F7D8E0 !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;

  .min-safe-h-screen {
    /* equivalent to 100dvh in major browsers */
    min-height: calc(
      100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
    );
  }

  @supports (-webkit-touch-callout: none) {
    .min-safe-h-screen {
      /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
      min-height: -webkit-fill-available;
    }
  }

`

export const StyledDiv = styled.div`
  max-width: 100vw;
  overflow: hidden;
  padding: 0 0 190px 0;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .box {
    padding-top: 90px;
    margin-bottom: -60px;
  }

  @media (min-width: 1024px) {
    .box {
      margin-top: 0px;
    }
  }
  @media (min-width: 768px) {
    ${(props) => (props.withPadding ? 'padding: 50px 0 190px 0;' : 'padding: 50px 0 0 0;')}
    ${(props) => (props.noPadding ? 'padding: 0' : '')}
  }
  ${(props) => (props.noPadding ? 'padding: 0' : '')}
`
