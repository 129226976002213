import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import cn from 'classnames'
import {TextLogo} from "../../styles/2023/HomepageStyles"

const Text = styled.p`
  font-family: 'grotesque_pro_light';
`
const LinkContainer = styled.div`
  a:last-child {
    border-bottom: 1px dotted ${(props) => (props.borderBlack ? '#26439B' : '#26439B')};
  }
  a:hover{
    background:linear-gradient(0.25turn, #26439B 75%, #F0EEE9 20%);
    color: #F0EEE9;
  }
`
const UserAvatar = styled.img``
const SocialLinks = styled.a`

`

const Profile = ({ user, className, borderBlack = true, withTop = true }) => {
  // console.log('🚀 ~ Profile ~ user', user)
  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])

    const navigate = useCallback(
        (url) => {
            if (url !== '/' && pathname?.startsWith(url)) {
                return
            }

            const leftLayer = document.getElementsByClassName('left-layer')?.[0]
            const rightLayer = document.getElementsByClassName('left-layer')?.[0]
            if (leftLayer && rightLayer) {
                leftLayer.classList.add('active')
                rightLayer.classList.add('active')
                setTimeout(() => history.push(url), 1450)
            }
        },
        [history, pathname],
    )

  return (
    <div className={cn('', className)}>
      {withTop && (
          <div className='absolute top-0 w-full  h-full flex justify-between pl-10'>
            <div className='absolute z-10 inline-block cursor-pointer'>
                <TextLogo
                className='uppercase'
                onClick={() => navigate('/2023/home')}
                style={{color: '#26439B'}}
                >
                Yes!2023
                </TextLogo>
            </div>
        </div>
      )}
      <div className={cn('px-4 mt-96 ', className)} >
      <div style={{ position: 'absolute', overflow: 'scroll', height: '100vh', paddingBottom: '300px'}}>
        {user?.image && (
          <UserAvatar
            src={user.image}
            alt='user-avatar'
            className='w-56 h-56 rounded-full mx-auto object-cover'
            style={{ filter: 'grayscale(100%)' }}
          />
        )}
        <div className='text-center mt-4'>
          <p className='text-charcoal inline-block'>
            {user?.firstName} {user?.lastName}
          </p>
        </div>
        <Text className='text-charcoal mt-4 text-2xl font-thin'>YES! {user?.tagline}</Text>
        <Text className='text-charcoal mt-4 text-2xl font-thin'>{user?.primaryExpertise}</Text>
        <Text className='text-charcoal text-2xl font-thin mt-2'>{user?.secondaryExpertise}</Text>
        <Text className='text-charcoal text-2xl font-thin mt-6'>{user?.about}</Text>
        <div className='h-8' />
        <LinkContainerComp user={user} borderBlack={borderBlack} />
      </div>
          </div>
    </div>
  )
}

export default Profile

export const ProfileSmall = ({ user }) => {

  return (
    <>
      <Text className='text-charcoal py-3 text-2xl font-thin'>YES! {user?.tagline}</Text>
      <Text className='text-charcoal py-3 text-2xl font-thin'>{user?.primaryExpertise}</Text>
      <Text className='text-charcoal text-2xl font-thin py-3'>{user?.secondaryExpertise}</Text>
      <Text className='text-charcoal text-2xl font-thin py-4'>{user?.about}</Text>
      <LinkContainerComp user={user} borderBlack={false} />
    </>
  )
}

const LinkContainerComp = ({ user, borderBlack }) => {
  return (
    <LinkContainer borderBlack={borderBlack}>
      {user.portfolio && (
        <SocialLinks
          href={user.portfolio}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dotted p-4 hover:bg-mad-blue hover:text-coton border-mad-blue',
          )}
        >
          Portfolio
        </SocialLinks>
      )}
      <SocialLinks
        href={`mailto:${user.contactEmail}`}
        target='_blank'
        rel='noopener noreferrer'
        className={cn(
          'block text-charcoal transition border-t border-dotted p-4 border-mad-blue'
        )}
      >
        Email
      </SocialLinks>
      {user.linkedIn && (
        <a
          href={user.linkedIn}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dotted p-4 hover:bg-mad-blue hover:text-coton border-mad-blue'
          )}
        >
          LinkedIn
        </a>
      )}
      {user.behance && (
        <a
          href={user.behance}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dotted p-4 hover:bg-mad-blue hover:text-coton border-mad-blue'
          )}
        >
          Behance
        </a>
      )}
      {user.instagram && (
        <a
          href={user.instagram}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dotted p-4 hover:bg-mad-blue hover:text-coton border-mad-blue'
          )}
        >
          Instagram
        </a>
      )}
      {user.twitter && (
        <a
          href={user.twitter}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dotted p-4 hover:bg-mad-blue hover:text-coton border-mad-blue'
          )}
        >
          Twitter
        </a>
      )}
      {user.facebook && (
        <a
          href={user.facebook}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dotted p-4 hover:bg-mad-blue hover:text-coton border-mad-blue'
          )}
        >
          Facebook
        </a>
      )}
      {user.dribble && (
        <a
          href={user.dribble}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dotted p-4 hover:bg-mad-blue hover:text-coton border-mad-blue'
          )}
        >
          Dribbble
        </a>
      )}
      {user.youtube && (
        <a
          href={user.youtube}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dotted p-4 hover:bg-mad-blue hover:text-coton border-mad-blue'
          )}
        >
          YouTube
        </a>
      )}
      {user.vimeo && (
        <a
          href={user.vimeo}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dotted p-4 hover:bg-mad-blue hover:text-coton border-mad-blue'
          )}
        >
          Vimeo
        </a>
      )}
      {user.medium && (
        <a
          href={user.medium}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(
            'block text-charcoal transition border-t border-dotted p-4 hover:bg-mad-blue hover:text-coton border-mad-blue'
          )}
        >
          Medium
        </a>
      )}
    </LinkContainer>
  )
}
