import styled, { keyframes } from "styled-components";

const loading = keyframes`
  from {
    background-position: 0 0;
    /* rotate: 0; */
  }

  to {
    background-position: 100% 100%;
    /* rotate: 360deg; */
  }
`;

const LoginForm = styled.form`
  font-family: 'grotesque_pro_light';
  padding: 20px;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 600;
  position: absolute;
  width: 70vw;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1100px;
  transform: translateY(-50%);

  label {
    display: block;
    margin-bottom: 1rem;
  }

  input,
  textarea,
  select {
    font-family: 'grotesque_pro_light';
    width: 100%;
    padding-left: 1rem;
    margin-top: 5rem;
    font-size: 3rem;
    font-weight: 100;
    border: none;
    border-bottom: 1px solid black;

    &::placeholder {
      color: #b5b5b5;
      opacity: 1;
    }

    &:focus {
      outline: 0;
      border-color: ${props => props.theme.pink};
    }

    @media (min-width: 768px) {
      color: #000000;
      font-size: 60px;
      font-weight: 300;
    }
  }

  button,
  input[type="submit"] {
    width: auto;
    background: ${props => props.theme.pink};
    color: ${props => props.theme.black};
    border: 0;
    font-family: 'grotesque_pro_light';
    font-size: 2rem;
    font-weight: 600;
    padding: 0.5rem 2.2rem;
    margin-top: 5rem;
    margin-bottom: 3rem;
    border-radius: 3rem;

    @media (min-width: 768px) {
      font-size: 2.4rem;
      font-weight: 300;
      min-width: 180px;
      padding: 0.75rem 1.5rem;
      text-transform: uppercase;
    }
  }

  fieldset {
    border: 0;
    padding: 0;

    /* &[disabled] {
      opacity: 0.5;
    }
    &::before {
      height: 10px;
      content: "";
      display: block;
      background-image: linear-gradient(
        to right,
        #f7d8e0 0%,
        #000000 50%,
        #f7d8e0 100%
      );
    }
    &[aria-busy="true"]::before {
      background-size: 50% auto;
      animation: ${loading} 0.5s linear infinite;
    } */
  }
`;

export default LoginForm;
