import React, {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useLocation} from 'react-router-dom'
import qs from 'query-string'
import StickerNavs from '../StickerNavs'
import WorkCard from '../../../components/2023/WorkCard'
import shuffle from 'lodash.shuffle'
import {gql, useLazyQuery} from '@apollo/client'
import {DropdownFilter} from '../DropdownFilter'
import {programGroups} from '../../../constant'
import useScroll from '../../../hook/useScroll'
import cn from 'classnames'
import {useDisclosure} from 'react-use-disclosure'
import {MobileRightNavs, MobileNavsBottom} from '../MobileRightNavs'
import FixedBottomNav from '../FixedBottomNav'
import {Element} from 'react-scroll'
import SearchIcon from '../../../components/2023/SearchIcon'
import Footer from '../Footer'
import cursorSVG from '../cursor-with-shadow-2023.svg';
import {WorkBGTop, TextCenterDiv, FooterContainer, WorkBGBottom} from "../../../styles/2023/WorkStyle";


const Container = styled.div`
  min-height: calc(100vh);
  cursor: url(${cursorSVG}), auto;
  flex-direction: column;
  background-color: #F0EEE9 !important;

`

const InterActiveMedia = styled.div`
  .dot {
    height: 12px;
    width: 12px;
    background-color: #d5fd00;
    border-radius: 50%;
    margin: 8px 10px;
    display: inline-block;
  }
`

const CenterContainer = styled.div`
  z-index: 10;
  padding-bottom: 500px;

  width: -webkit-calc(100% - 48rem);
  width: -moz-calc(100% - 48rem);
  width: calc(100% - 48rem);

  @media (max-width: 1280px) {
    width: 100%;
  }
`
const TextCenter = styled.h4`
  position: relative;
  text-align: center;
  z-index: 10;
  color: #F0EEE9;
  font-family: Reckless_light, serif;
  font-size: 250px;
  line-height: 320px;
  letter-spacing: -0.02em;
  font-weight: 400;

  @media (max-width: 767px) {
    font-size: 120px;
    line-height: 130px;
  }

  @media (max-width: 359px) {

  }
`


const PUBLIC_PROJECTS_QUERY = gql`
  query PUBLIC_PROJECTS_QUERY($where: ProjectWhereInput, $gradsSearch: String) {
    projects(where: $where, gradsSearch: $gradsSearch) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        user {
          id
          program
        }
      }
    }
  }
`

const Y_POS_SWAP_NAVS = 240

export default function Index(props) {
    const location = useLocation()
    const {search: searchParams} = location
    const {keyword, program, type} = qs.parse(searchParams)
    const [gradsSearch, setGradsSearch] = useState()

    // scroll
    const scroll = useScroll()

    const OR = useMemo(() => {
        const OR = []
        if (keyword) {
            OR.push({title_contains: keyword})
            OR.push({tags_contains: keyword})
        }
        return OR
    }, [keyword])


    const [fetch, {data, loading}] = useLazyQuery(PUBLIC_PROJECTS_QUERY, {})

    useEffect(() => {
        fetch({
            variables: {
                where: {
                    published: true,
                    createdAt_gt: '2022-12-31',
                    OR: OR.length > 0 ? OR : undefined,
                },
                gradsSearch
            }
        })
    }, [gradsSearch, OR, fetch])

    const groupedProjects = useMemo(() => {
        if (!data?.projects) {
            return {}
        }
        let projects = data.projects
        if (program) {
            let duplicateProjects = []
            projects = projects.filter((project) => {
                if (
                    project?.members?.some?.((member) => member?.user?.program === program) &&
                    !duplicateProjects.includes(project.id)
                ) {
                    duplicateProjects.push(project.id)
                    return true
                }
                return false
            })
        }

        if (type) {
            projects = projects.filter(
                (project) =>
                    project.primaryProjectType === type.toLowerCase() ||
                    project.secondaryProjectType === type.toLowerCase() ||
                    project.secondaryProjectType_2 === type.toLowerCase(),
            )
        }
        // group by program
        const groupedProjects = {}
        programGroups.forEach((key) => {
            const groupProjects = projects.filter((project) => {
                if (project?.members?.some?.((member) => member?.user?.program === key)) {
                    return true
                }
                return false
            })
            groupedProjects[key] = shuffle(groupProjects)
        })

        return groupedProjects
    }, [data, program, type])

    const {isOpen: isOpenMenuNavs, toggle: toggleMobileNavs} = useDisclosure()

    require('./work.scss')

    useEffect(() => {
        let body = document.getElementsByTagName('body')?.[0]
        body.style.height = 'auto'
        body.style.overflow = 'hidden'
        window.scroll(0, 0)
    }, [])

    const isEmptyWorkVisible = useMemo(() => {
        let visible = true
        for (const key of Object.keys(groupedProjects)) {
            if (groupedProjects[key] && groupedProjects[key].length > 0) {
                visible = false
                break
            }
        }
        return visible
    }, [groupedProjects])

    return (
        <>
            <Element name="myScrollToElement" id="scroll2023top"></Element>
            <Container
                id="top"
                className="w-full h-full bg-grey flex relative"
                style={{background: '#e3e3de'}}
            >
                <WorkBGTop>
                    <img
                        src={`/static/svg/2023/Work/WorkBGTop.png`}
                        alt=""
                    />
                </WorkBGTop>
                <SearchIcon className='hidden lg:block' />
                <StickerNavs
                    className={cn(
                        'w-96 fixed top-0 h-screen transition-opacity duration-700 ease-in-out hidden lg:block z-20',
                        {
                            'opacity-1 visible': scroll.scrollY < Y_POS_SWAP_NAVS,
                            'opacity-0 invisible': scroll.scrollY >= Y_POS_SWAP_NAVS,
                        },
                    )}
                />

                <CenterContainer className="w-full mx-auto pb-12">
                    <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs}/>
                    <div
                        className={cn('transition', {
                            'flex visible opacity-100': !isOpenMenuNavs,
                            'hidden invisible opacity-0': isOpenMenuNavs,
                        })}
                    >
                        <div className="w-full xl:w-full ">
                            <TextCenterDiv>
                                <TextCenter className="text-secondary" style={{pointerEvents: 'none'}}>
                                    Work
                                </TextCenter>
                            </TextCenterDiv>
                            <div style={{display: 'flex', flexDirection: "column", justifyContent: "space-between", alignItems: "center", textAlign: "center", height: "228px"}}>
                                <DropdownFilter program={program} type={type} onChangeSearchGrads={setGradsSearch}/>
                            </div>
                            {loading && <div
                                className="z-8 text-charcoal relative text-center font-bold text-5xl">Loading...</div>}
                            {data && data.projects && data.projects.length === 0 &&
                                <div className="z-8 text-charcoal relative text-center font-bold text-5xl">No results,
                                    please try again.</div>}
                            {isEmptyWorkVisible &&
                                <div className="z-8 text-charcoal relative text-center font-bold text-5xl">No results,
                                    please try again.</div>}
                            <div id="wrapper" className="z-9 pt-10">
                                {Object.keys(groupedProjects).map((key) => {
                                    if (groupedProjects[key]?.length <= 0) {
                                        return null
                                    }

                                    return (
                                        <div key={key}>
                                            <InterActiveMedia>
                                                <div
                                                    className="text-center lg:my-4"
                                                    style={{
                                                        maxWidth: '70%',
                                                        margin: '0 auto',
                                                        padding: '50px',
                                                        fontFamily: 'Reckless_light',
                                                    }}
                                                >
                                                    <span
                                                        className="flex justify-center items-center lg:inline-block text-5xl text-secondary mt-4 lg:mt-0 mb-4 lg:mb-0">
                            <span
                                className="pb-2 lg:pb-1"
                                style={{
                                    color: '#191919',
                                    zIndex: 8,
                                    position: 'relative',
                                    fontSize: '60px',
                                    fontWeight: '400',
                                    letterSpacing: '-2px'
                                }}
                            >
                              {key.charAt(0).toUpperCase() + key.slice(1)}
                            </span>
                          </span>
                                                </div>
                                            </InterActiveMedia>
                                            <div className="bg-grey2022 flex flex-wrap justify-between relative z-8">
                                                {groupedProjects[key].map((project) => (
                                                    <WorkCard
                                                        project={project}
                                                        key={project.id}
                                                        className="w-full lg:w-1/2 xl:w-1/3"
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="w-12 bg-blue hidden lg:block">
                            {/* <img src='/static/svg/glass.svg' alt='glass' className={cn('cursor-pointer')} /> */}
                        </div>
                    </div>
                    <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs}/>
                </CenterContainer>
                <WorkBGBottom>
                    <img
                        src={`/static/svg/2023/Work/WorkBGBottom.png`}
                        alt=""
                    />
                </WorkBGBottom>
                <FixedBottomNav className="hidden lg:block"/>
                <FooterContainer data-color="off_white" >
                    <Footer/>
                </FooterContainer>
            </Container>
        </>
    )
}
