import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'
import {TextLogo, StickersWrapper, StickerContainer} from "../../styles/2023/HomepageStyles"

export default function StickerNavs({ className, withTop = true }) {
  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])

    const navigate = useCallback(
        (url) => {
            if (url !== '/2023/' && pathname?.startsWith(url)) {
                return
            }

            const leftLayer = document.getElementsByClassName('left-layer')?.[0]
            const rightLayer = document.getElementsByClassName('left-layer')?.[0]
            if (leftLayer && rightLayer) {
                leftLayer.classList.add('active')
                rightLayer.classList.add('active')
                setTimeout(() => history.push(url), 1450)
            }
        },
        [history, pathname],
    )

  const isWork = useMemo(() => {
    if (pathname?.startsWith('/2023/work')) {
      return true
    }
    return false
  }, [pathname])

  const isGrads = useMemo(() => {
    if (pathname?.startsWith('/2023/grads')) {
      return true
    }
    return false
  }, [pathname])


  return (
    <div className={cn('', className)}>
      {withTop && (
          <div className='absolute top-0 w-full  h-full flex justify-between pl-10'>
            <div className='absolute z-10 inline-block cursor-pointer'>
                <TextLogo
                className='uppercase'
                onClick={() => navigate('/2023/home')}
                style={{color: '#C7C2E1',filter: 'drop-shadow(1px 1px 1px #26439B)'}}
                >
                Yes!2023
                </TextLogo>
            </div>
        </div>
      )}
      <StickersWrapper
          className={cn('z-10 absolute w-96 fixed top-10 transition-opacity duration-700 ease-in-out lg:block')}
          top='210px'
          imgLeft='0%'>
        <StickerContainer
          pointer
          className='relative'
          textRotate={-35}
          onClick={() => navigate('/2023/work')}
        >
          <svg className="no-hover" width={88} height={88} viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'
            style={{
              filter: 'drop-shadow(1px 1px 1px #26439B)'
            }}>
            <circle
                fill={isWork ? '#F0EEE9' : '#C7C2E1'}
                cx={55}
                cy={55}
                r={55}
                fillRule='evenodd'
                style={{display: "unset"}}
            />
          </svg>
          <p
          className={cn('text-5xl no-hover')}
          style={{
              top: 'calc(25% + 4px)',
              left: 10,
              color: '#26439B'
          }}
          >
          Work
          </p>
          <svg className="yes-hover" width={88} height={88} viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'
            style={{
              filter: 'drop-shadow(1px 1px 1px #26439B)'
            }}>
                <circle
                    fill={'#F0EEE9'}
                    cx={55}
                    cy={55}
                    r={55}
                    fillRule='evenodd'
                    style={{display: "unset"}}
                />
            </svg>
            <p
                className={cn('text-5xl yes-hover')}
                style={{
                    top: 'calc(25% + 4px)',
                    left: 10,
                    color: '#26439B'
                }}
            >
                Work
            </p>
        </StickerContainer>
        <StickerContainer
          pointer
          imgWidth={124}
          className='relative -mt-4'
          textRotate={-15}
          onClick={() => navigate('/2023/grads')}
        >
            <svg className={"no-hover"} width={128} height={87} viewBox='0 0 164 87' xmlns='http://www.w3.org/2000/svg'
            style={{
              filter: 'drop-shadow(1px 1px 1px #26439B)'
            }}>
            <path
                d='M.203 71.712S60.29 13.564 113.301 1.427c51.212-11.726 71.525 64.233 21.237 79.456-26.56 8.04-68.41 8.844-134.335-9.171'
                fill={isGrads ? '#F0EEE9' : '#C7C2E1'}
                fillRule='evenodd'
            />
            </svg>
            <p
            className={cn('text-5xl no-hover"')}
            style={{
                top: 'calc(35% + 0px)',
                left: 42,
                color: '#26439B'
            }}
            >
            Grads
            </p>
            <svg className="yes-hover" width={128} height={87} viewBox='0 0 164 87' xmlns='http://www.w3.org/2000/svg'
              style={{
                filter: 'drop-shadow(1px 1px 1px #26439B)'
              }}>
                <path
                    d='M.203 71.712S60.29 13.564 113.301 1.427c51.212-11.726 71.525 64.233 21.237 79.456-26.56 8.04-68.41 8.844-134.335-9.171'
                    fill={'#F0EEE9'}
                    fillRule='evenodd'
                />
            </svg>
            <p
                className={cn('text-5xl yes-hover')}
                style={{
                    top: 'calc(35% + 0px)',
                    left: 42,
                    color: '#26439B'
                }}
            >
                Grads
            </p>
        </StickerContainer>
        <StickerContainer
          pointer
          imgWidth={124}
          className='relative'
          textRotate={10}
          onClick={() => navigate('/2023/events')}>
           <img className="no-hover" src='/static/svg/2023/Grads/nav-event.svg' alt='events-nav'
              style={{
              filter: 'drop-shadow(1px 1px 1px #26439B)'
            }}/>

            <p
            className='text-5xl text-blue no-hover'
            style={{
                top: 'calc(45% - 12px)',
                left: 24,
                color: '#26439B'
            }}
            >
            Events
            </p>
            <img className="yes-hover" src='/static/svg/2023/Grads/nav-event-white.svg' alt='events-nav'
              style={{
                filter: 'drop-shadow(1px 1px 1px #26439B)'
              }}/>
            <p
                className='text-5xl text-blue yes-hover'
                style={{
                    top: 'calc(45% - 12px)',
                    left: 24,
                    color: '#26439B'
                }}
            >
                Events
            </p>
        </StickerContainer>
        <StickerContainer
          pointer
          imgWidth={94}
          className='relative'
          textRotate={10}
          onClick={() => window.open("https://instoregbc.com/", "_blank")}
        >
          <img className="no-hover" src='/static/svg/2023/Grads/nav-show.svg' alt='show-nav'
            style={{
              filter: 'drop-shadow(1px 1px 1px #26439B)'
            }}/>
            <p
            className='text-5xl text-blue no-hover'
            style={{
                top: 'calc(56% - 16px)',
                left: 13,
                color: '#26439B'
            }}
            >
            Shop<span style={{fontSize:'20px'}}>&#8599;</span>
            </p>
            <img className="yes-hover" src='/static/svg/2023/Grads/nav-show-white.svg' alt='show-nav'
              style={{
                filter: 'drop-shadow(1px 1px 1px #26439B)'
              }}/>
            <p
                className='text-5xl text-blue yes-hover'
                style={{
                    top: 'calc(56% - 16px)',
                    left: 13,
                    color: '#26439B'
                }}
            >
                Shop<span style={{fontSize:'20px'}}>&#8599;</span>
            </p>
        </StickerContainer>
      </StickersWrapper>
    </div>
  )
}
