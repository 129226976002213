import React, { useCallback } from 'react'
import cn from 'classnames'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const Main = styled.div`
  a:hover {
    color: #5933ff;
  }
`

export default function SearchCol({ className = '', goBack = false }) {
  const history = useHistory()
  const { pathname } = useLocation()

  const onGoBack = useCallback(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('left-layer')?.[0]
    if (leftLayer && rightLayer) {
      leftLayer.classList.add('active')
      rightLayer.classList.add('active')
      setTimeout(() => history.goBack(), 1500)
    }
  }, [history])

  const navigate = useCallback(
    (url) => {
      if (pathname?.startsWith(url)) {
        return
      }

      const leftLayer = document.getElementsByClassName('left-layer')?.[0]
      const rightLayer = document.getElementsByClassName('left-layer')?.[0]
      if (leftLayer && rightLayer) {
        leftLayer.classList.add('active')
        rightLayer.classList.add('active')
        setTimeout(() => history.push(url), 1500)
      }
    },
    [history, pathname],
  )

  return (
    <Main
      className={cn('w-12 bg-blue', className)}
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        height: '100vh',
        boxShadow: '-1px 0px 10px 1px rgba(0, 0, 0, 0.5)',
        zIndex: 1001,
      }}
    >
      {!goBack ? (
        <img
          src='/static/svg/glass.svg'
          style={{ position: 'fixed', top: '0px', width: '30px' , zIndex: 1013 }}
          alt='glass'
          className={cn('cursor-pointer')}
          onClick={() => navigate('/2021/search')}
        />
      ) : (
        <img
          src='/static/svg/close.svg'
          style={{ position: 'fixed', top: '0px', width: '30px', zIndex: 1013 }}
          alt='glass'
          className={cn('cursor-pointer')}
          onClick={onGoBack}
        />
      )}
      <Link to={{ pathname: '/2020' }} className='text-5xl inline-block mt-6 mb-20'>
        <div
          className='w-12 '
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            height: '140px',
            backgroundColor: '#efd6d9',
            zIndex: '2222',
          }}
        >
          <p
            style={{
              paddingLeft: '40px',
              paddingTop: '10px',
              transform: 'rotate(90deg)',
              fontFamily: 'sharp_grotesque',
              fontSize: '30px',
              letterSpacing: '0.02em'
            }}
          >
            2020
          </p>
        </div>
      </Link>
      <Link to={{ pathname: '/2022/home' }} className='text-5xl inline-block mt-6 mb-20'>
          <div
            className={cn('w-12 bg-[#FF6044]')}
            style={{
              position: 'absolute',
              display: 'flex',
              bottom: 140,
              right: 0,
              alignItems: 'center',
              justifyContent: 'center',
              height: '140px',
            }}
          >
            <p
              className='text-[#26439B]'
              style={{
                fontWeight: 900,
                transform: 'rotate(90deg)',
                fontFamily: 'sharp_grotesque',
                fontSize: '30px',
                letterSpacing: '0.02em',
                background: 'transparent',
              }}
            >
              2022
            </p>
          </div>
        </Link>
        <Link to={{ pathname: '/2023/home' }} className='text-5xl inline-block mt-6 mb-20'>
        <div
          className='w-12'
          style={{
            position: 'absolute',
            bottom: 280,
            right: 0,
            height: '140px',
            backgroundColor: '#26439B',
            zIndex: '2222',
            color: '#C7C2E1'
          }}
        >
          <p
            style={{
              paddingLeft: '40px',
              paddingTop: '10px',
              transform: 'rotate(90deg)',
              fontFamily: 'sharp_grotesque',
              fontSize: '30px',
              letterSpacing: '0.02em'
            }}
          >
            2023
          </p>
        </div>
        </Link>
        <Link to={{ pathname: '/home' }} className='text-5xl inline-block mt-6 mb-20 '>
          <div
            className='w-12 bg-almost-black'
            style={{
              position: 'absolute',
              bottom: 420,
              right: 0,
              height: '140px',
              zIndex: '2222',
            }}
          >
            <p
            className='text-comfort-green'
              style={{
                paddingLeft: '40px',
                paddingTop: '10px',
                transform: 'rotate(90deg)',
                fontFamily: 'sharp_grotesque',
                fontSize: '30px',
                letterSpacing: '0.02em'
              }}
            >
              2024
            </p>
          </div>
        </Link>

    </Main>
  )
}
