import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { END_DATE_COUNTDOWN_2021 } from '../constant'

const SECS_OF_DAY = 86400
const SECS_OF_HOUR = 3600
const SECS_OF_MINUTE = 60

export default function useCountdown(end = END_DATE_COUNTDOWN_2021) {
  const endDay = dayjs(end, 'MM/DD/YYYY')
  const secs = endDay.diff(dayjs(), 'seconds') + 68400
  const [seconds, setSecondsLeft] = useState(secs)

  useEffect(() => {
    const pid = setInterval(() => setSecondsLeft((v) => (v -= 1)), [1000])
    return () => {
      clearInterval(pid)
    }
  }, [])

  const days = useMemo(() => Math.floor(seconds / SECS_OF_DAY), [seconds])
  const hours = useMemo(() => Math.floor((seconds - days * SECS_OF_DAY) / SECS_OF_HOUR), [
    seconds,
    days,
  ])
  const minutes = useMemo(
    () => Math.floor((seconds - (days * SECS_OF_DAY + hours * SECS_OF_HOUR)) / SECS_OF_MINUTE),
    [seconds, days, hours],
  )
  const secondsLeft = useMemo(
    () => seconds - (days * SECS_OF_DAY + hours * SECS_OF_HOUR + minutes * SECS_OF_MINUTE),
    [seconds, days, hours, minutes],
  )

  return {
    seconds: days < 0 ? 0: secondsLeft,
    minutes: days < 0 ? 0: minutes,
    hours: days < 0 ? 0: hours,
    days: days < 0 ? 0: days,
  }
}
