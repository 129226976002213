import styled from 'styled-components'

export const TextCenterDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding: 120px 0 60px 0;
`

export const SearchContainer = styled.div`

  p {
    text-align: left;
    padding-left: 30px;
    color: #191919;
    font-size: 1.3em;
    position: absolute;
    line-height: 60px;
    margin-bottom: 0;
    width: 100%;
  }
  position: relative;

  img {
    top: 36px;
    color: #191919;
    right: 22px;
    transition: transform 330ms;
  }

  input {
    font-size: 2rem;
    font-family: 'grotesque_pro_light';
    height: 60px;
    border-radius: 30px;
    padding: 5px 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 3px solid ${(props) => (props.hasError ? 'red' : '#71DF71')};
    display: ${(props) => (props.submitted ? 'none' : 'block')};
  }

  input:hover {
    border: 3px solid #ff8a48;
  }
  input:focus {
    outline: none;
    border: 3px solid #ff8a48;
  }

  @media (min-width: 640px) {
    #email {
      width: 480px;
    }

  }
`