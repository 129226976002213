import React from 'react';
import { Route } from 'react-router-dom';

import {
    Work as Work2022,
    Grads as Grads2022,
    Profile as Profile2022,
    PublishedProject as PublishedProject2022,
    Search as Search2022,
} from '../pages/2022';

const HomeScroll = React.lazy(() => import('../pages/2022/HomeScroll'));
const EventsComp2022 = React.lazy(() => import('../pages/2022/EventsComp/EventsComp'));


export const getRoutes2022 = () => [
    <Route key={"Work2022"} exact path="/2022/work" component={Work2022}/>,
    <Route key={"Grads2022"} exact path="/2022/grads" component={Grads2022}/>,
    <Route key={"Profile2022"} exact path="/2022/student" component={Profile2022}/>,
    <Route key={"PublishedProject2022"} exact path="/2022/project" component={PublishedProject2022}/>,
    <Route key={"EventsComp2022"} exact path="/2022/events" component={EventsComp2022}/>,
    <Route key={"Search2022"} exact path="/2022/search" component={Search2022}/>,
    <Route key={"HomeScroll"} exact path="/2022" component={HomeScroll}/>,
    <Route key={"HomeScroll2"} exact path="/2022/home" component={HomeScroll}/>,
];
