import React, { useEffect, useRef } from 'react'
import Lottie from 'lottie-react'
import { useIsVisible } from '../../../../hook/2024/hooks/isVisible'
import lottieYearEndShow from './YES.json'
import loadingLottie from './Loading.json'
import yesMobile from './Mobile.json'
import yesDesktop from './Desktop.json'

const LottieModal = ({isPlaying, setIsPlaying}) => {
  const lottieRef = useRef();
  const isVisible = useIsVisible(lottieRef);

  useEffect(() => {
    if (isVisible) {
      setIsPlaying(true);
    }
  }, [isVisible, setIsPlaying]);
  return (
    <div ref={lottieRef} className='!overflow-x-visible'>
      {isPlaying && <Lottie lottieRef={lottieRef} animationData={lottieYearEndShow} autoplay={true} loop={false} /> }
    </div>
  )
}

export default LottieModal;


export const LoadingLottie = () => {
  return (
       <Lottie animationData={loadingLottie} autoplay={true} loop={true} />
  )
}



export const YESmobile = () => {
  return (
       <Lottie animationData={yesMobile} autoplay={true} loop={false} />
  )
}



export const YESdesktop = () => {
  return (
       <Lottie animationData={yesDesktop} autoplay={true} loop={false} />
  )
}
