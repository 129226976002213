import React, { useState, useRef, useEffect } from "react";
import { Label, Icon, Input } from "semantic-ui-react";
import styled from "styled-components";

const SpanWrapIcon = styled.span`
  cursor: pointer;
`

const TagsInput = (props) => {
  const inputEl = useRef(null);
  const [tags, setTags] = useState(props.defaultValue || []);
  const [inputVisible, setInputVisible] = useState(false);

  const toggleInputVisible = () => setInputVisible((val) => !val);

  useEffect(() => {
    if (inputVisible) {
      inputEl && inputEl.current && inputEl.current.focus();
    }
  }, [inputVisible]);

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      const value = e.target.value;
      const newTags = [...tags, value];
      setTags(newTags);
      toggleInputVisible();
      props.onChange && props.onChange(newTags);
    }
  };

  const handleRemoveTag = index => {
    const newTags = tags.filter((_, idx) => idx !== index)
    setTags(newTags)
    props.onChange && props.onChange(newTags);
  }

  return (
    <div>
      {tags.map((tag, index) => (
        <Label size="big" key={`${tag}-${index}`}>
          {tag} {"\u00A0"} {"\u00A0"}
          <SpanWrapIcon onClick={() => handleRemoveTag(index)}><Icon name="delete" /></SpanWrapIcon>
        </Label>
      ))}
      {inputVisible ? (
        <Input placeholder="New tag" onKeyDown={handleEnter} />
      ) : (
        <Label size="big" onClick={toggleInputVisible}>
          New {"\u00A0"} {"\u00A0"}
          <Icon name="add" />
        </Label>
      )}
    </div>
  );
};

export default TagsInput;
