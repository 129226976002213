import React , { useEffect, useState } from 'react'
import { H4, P } from '../../styles/2024/TextStyles'
import { programToImage, programColour, projectTypeMap, disciplines } from '../../constant'
import { Link } from 'react-router-dom'

export const GradCard = ({ grad, scrollTop }) => {
  const [gradImage, setGradImage] = useState(programToImage[grad?.program])
  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (error) {
      return false;
    }
  }

  useEffect(() => {
    const _gradImage = grad?.image && isValidUrl(grad.image)
      ? encodeURI(grad.image)
      : programToImage[grad?.program];
    setGradImage(_gradImage)
  }
  , [grad])

  return (
    <div className='flex flex-col self-stretch'>
      <Link
        to={{
          pathname: '/student',
          search: `id=${grad?.id}`,
        }}
        onClick={() => {
          scrollTop && scrollTop()
        }}
        className='h-[300px] w-full overflow-hidden flex items-center justify-center'
      >
        <img src={gradImage} alt={grad?.firstName} className='object-cover w-full h-full'
          onError={() => setGradImage(programToImage[grad?.program])}
        />
      </Link>
      <div className='flex flex-wrap justify-start items-center gap-4 pt-6'>
        <P className='text-beige-yellow inline-block p-0 m-0'>
          <span className={programColour[grad?.program]}>●</span> {grad?.program}
        </P>
        {grad?.primaryExpertise && (
          <P className='text-beige-yellow inline-block p-0 m-0'>
            ● {disciplines[projectTypeMap[grad?.primaryExpertise]]}
          </P>
        )}
      </div>
      <Link
        to={{
          pathname: '/student',
          search: `id=${grad?.id}`,
        }}
        onClick={() => {
          scrollTop && scrollTop()
        }}
      >
        <H4 className='text-beige-yellow mt-2 cursor-pointer hover:text-comfort-green'>
          {grad?.firstName} {grad?.lastName}
        </H4>
      </Link>
    </div>
  )
}
