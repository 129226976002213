import React, { useCallback, useEffect, useState } from 'react'
import { useVisible } from 'react-hooks-visible'
import { Link, withRouter } from 'react-router-dom'
import GradStyles from './styles/GradStyles'

const Grad = (props) => {
  const { grad } = props

  const [isVisited, setIsVisited] = useState(false)
  const [targetRef, visible] = useVisible()

  useEffect(() => {
    if (isVisited) return
    if (visible === 0) return

    setIsVisited(true)
  }, [isVisited, visible])



  const handleGotoStudent = useCallback(() => {
    const { history, grad } = this.props
    history.push({
      pathname: '/2020/student',
      search: `?id=${grad.id}`,
    })
  }, [])

  return (
    <GradStyles ref={targetRef}>
      {grad.image && isVisited ? (
        <img
          style={{ cursor: 'pointer' }}
          src={grad.image}
          onClick={handleGotoStudent}
          alt={grad.title}
        />
      ) : (
        <img src={'/static/no-image.png'} alt='Grad' />
      )}
      <h3>
        <Link
          to={{
            pathname: '/2020/student',
            search: `id=${grad.id}`,
          }}
        >
          {grad.firstName} {grad.lastName}
        </Link>
      </h3>
    </GradStyles>
  )
}

export default withRouter(Grad)
