import React, { useEffect, useState } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import ProjectForm from './styles/ProjectForm'
import InputLimit, { LengthLimitTextarea } from './styles/InputLimit'
import styled from 'styled-components'
import { client } from '../../index'
import { withRouter } from 'react-router-dom'
import TagsInput from './TagsInput'
import { Progress } from 'semantic-ui-react'
import SearchInput from './SearchInput'
import Loading from './Loading'
import { mapErrorsMsg } from '../2020/utils'
import { QUERY } from '../../graphql'
import cogoToast from 'cogo-toast'
import { disciplines as projectType } from '../../constant'

const MAX_IMG_SIZE = 5 * 1024 * 1024
const MAX_VIDEO_SIZE = 50 * 1024 * 1024
const allowedImageExt = 'image/bmp, image/jpeg, image/png, image/gif'
const allowedVideoExt = 'video/*, .mkv, video/mp4,video/x-m4v'

const UpdateProject = (props) => {
  const history = useHistory()
  const [project, setProject] = useState({
    id: '',
    title: '',
    primaryProjectType: '',
    secondaryProjectType: '',
    secondaryProjectType_2: '',
    contentType: { set: [] },
    contentData: { set: [] },
    coverImage: '',
    members: [],
    published: false,
  })
  const [memberInputs, setMemberInputs] = useState([])
  const [contentInputs, setContentInputs] = useState([])
  const [uploadFinished, setUploadFinished] = useState(true)
  const [loadingStates, setLoadingStates] = useState({})
  const [usersData, setUsersData] = useState([])
  const [error, setError] = useState(undefined)

  const projectId = props.location.search.split('id=')[1]

  const { data: userQuery } = useQuery(QUERY.USERS_QUERY, {
    variables: {
      where: {
        studentYear: 2024,
      },
    },
  })

  const { data, loading } = useQuery(SINGLE_PROJECT_QUERY, {
    variables: { id: projectId },
    fetchPolicy: 'no-cache',
  })

  const [uploadFile] = useMutation(USER_UPLOAD_FILE)

  useEffect(() => {
    try {
      if (!loading && data?.project && userQuery?.users) {
        if (Array.isArray(data.project.contentType)) {
          const _project = { ...data.project }
          _project.contentType = {
            set: data.project.contentType.map((type) => type),
          }
          _project.contentData = {
            set: data.project.contentData.map((contentData) => contentData),
          }
          _project.members = _project.members.map((member) => ({
            id: member.user.id,
            role: member.role,
          }))

          const _memberInputs = _project.members.map((member, i) => `${i+1}`)
          setMemberInputs(_memberInputs)

          const existingInputs = _project.contentType.set.map((type, i) => `${i}`)

          setContentInputs(existingInputs)
          _project.tags = _project.tags > 0 ? _project.tags.split(',') : []

          setProject(_project)
        }
        const usersData = userQuery.users
        setUsersData(usersData)
      }
    } catch (error) {
      const err = mapErrorsMsg(error)
      setError(err)
    }
  }, [data, loading, userQuery])

  const appendMember = () => {
    var newMemberInput = `${memberInputs.length + 1}`
    setMemberInputs(memberInputs.concat([newMemberInput]))
    const studentProject = { ...project }
    studentProject.members = [...studentProject.members, { id: '', role: '' }]
    setProject(studentProject)
  }

  const appendContent = () => {
    const newContentInput = `${contentInputs.length}`
    const newContentInputs = contentInputs.concat([newContentInput])
    setContentInputs(newContentInputs)
    const _project = { ...project }
    _project.contentType.set.push('Text')
    _project.contentData.set.push('')
    setProject(_project)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setProject((prev) => ({ ...prev, [name]: value }))
  }

  const handleMemberSearch = (value, index) => {
    const _project = { ...project }
    const members = _project.members.map((mb, idx) => {
      if (index === idx) {
        return { ...mb, id: value }
      }
      return mb
    })
    setProject({ ...project, members })
  }

  const handleRole = (e, index) => {
    const { value } = e.target
    const _project = { ...project }
    const members = _project.members.map((mb, idx) => {
      if (idx === index) {
        return { ...mb, role: value }
      }
      return mb
    })
    setProject({ ...project, members })
  }

  const handleContentType = (e) => {
    const { name, value } = e.target
    const [, index] = name.split('contentType_')

    const _project = { ...project }

    _project.contentType.set.splice(parseInt(index), 1, value)
    _project.contentData.set.splice(parseInt(index), 1, '')

    setProject(_project)
  }

  const handleContentData = (e) => {
    const { name, value } = e.target
    /* eslint-disable-next-line */
    const [, index] = name.split('contentData_')
    const _project = { ...project }

    _project.contentData.set.splice(parseInt(index), 1, value)

    setProject(_project)
  }

  const handleFileUpload = async (e) => {
    const files = e.target.files
    if (!files.length) {
      return // Exit if no file is selected
    }

    const file = files[0]
    // console.log(file);
    if (file.size > MAX_IMG_SIZE) {
      alert('Maximum image size is 5MB')
      e.target.value = '' // Reset the input for consistent behavior
      return
    }

    setUploadFinished(false)
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async (event) => {
      // Use onloadend for comprehensive completion checking
      if (reader.error) {
        console.error('FileReader error:', reader.error)
      } else if (event.total > MAX_IMG_SIZE) {
        alert('Maximum image size is 5MB')
        return
      } else {
        try {
          uploadFile({ variables: { file } }).then(({ data }) => {
            console.log(data)
            const coverImage = data.singleUpload.path
            setProject((prevState) => ({
              ...prevState,
              coverImage,
            }))
            setUploadFinished(true)
          })
        } catch (error) {
          console.error('Error uploading file:', error)
        }
        event.target.value = '' // Optionally reset the input after processing
      }

      reader.onerror = (error) => {
        console.error('Error reading file:', error)
        setUploadFinished(true)
      }
    }
  }

  const uploadContentFile = async (e) => {
    const { id, name } = e.target
    setUploadFinished(false)

    const files = e.target.files
    const file = files[0]
    const [media, index] = id.split('_')
    const isVideo = media === 'video'

    let interval
    const keyState = `contentType_${index}_loading`
    if (isVideo) {
      interval = fakeLoading(keyState)
    }
    const size = isVideo ? MAX_VIDEO_SIZE : MAX_IMG_SIZE
    if (file.size > size) {
      alert(`Maximum ${isVideo ? 'video' : 'image'} size is ${isVideo ? '50 MB' : '5 MB'}`)
      setUploadFinished(true)
      clearInterval(interval)
      e.target.value = null
      return
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async (event) => {
        if (event.total > size) {
          alert(`Maximum ${isVideo ? 'video' : 'image'} size is ${isVideo ? '50 MB' : '5 MB'}`)
          setUploadFinished(true)
          clearInterval(interval)
          return
        }
        uploadFile({ variables: { file, isVideo } }).then(({ data }) => {
          clearInterval(interval)
          const path = data.singleUpload.path
          /* eslint-disable-next-line */
          const [, index] = name.split('contentData_')
          const _contentType = isVideo ? 'Video' : 'Image'
          console.log('_contentType', _contentType)
          const _project = { ...project }
          if (_project.contentType.set.length <= parseInt(index)) {
            _project.contentType.set.push(_contentType)
            _project.contentData.set.push(path)
          } else {
            _project.contentType.set.splice(parseInt(index), 1, _contentType)
            _project.contentData.set.splice(parseInt(index), 1, path)
          }
          setProject(_project)
          setUploadFinished(true)
        })
      }
    }
  }

  const fakeLoading = (keyState) => {
    const percent = Math.ceil(Math.random() * 7) * 10
    const interval = setInterval(() => {
      setLoadingStates((prevStates) => {
        const currentPercent = prevStates[keyState] || 0
        return {
          ...prevStates,
          [keyState]: currentPercent < 90 ? currentPercent + percent : 10,
        }
      })
    }, 500)
    return interval
  }

  const updateProject = async (e) => {
    e.preventDefault()
    const tags = project.tags ? project.tags.join(',') : ''

    if (!project.title) {
      cogoToast.error('Project title is required')
      return
    }
    if (!project.coverImage) {
      cogoToast.error('Project cover image title is required')
      return
    }
    if (!project.primaryProjectType) {
      cogoToast.error('Project primary type is required')
      return
    }

    if (!project.contentData.set[0] || project.contentData.set[0].length === 0) {
      cogoToast.error('Project description is required')
      return
    }

    const filteredContentData = project.contentData.set.filter((cData) => cData !== '')

    const filteredContentType = project.contentType.set.filter(
      (cType, index) => index < filteredContentData.length,
    )

    const variables = {
      id: projectId,
      title: project.title,
      tags,
      primaryProjectType: project.primaryProjectType,
      secondaryProjectType: project.secondaryProjectType,
      secondaryProjectType_2: project.secondaryProjectType_2,
      contentType: filteredContentType,
      contentData: filteredContentData,
      coverImage: project.coverImage,
      members: project.members,
    }

    const res = await client.mutate({
      mutation: UPDATE_PROJECT_MUTATION,
      variables,
    })
    const newProject = {
      ...project,
      members: res.data.updateProject.members.map((mb) => {
        const { role, user } = mb
        return { id: user.id, role }
      }),
    }

    setProject(newProject)
    history.push({
      pathname: '/project',
      search: `?id=${res.data.updateProject.id}`,
    })
  }

  const handlePreview = async () => {
    const tags = project.tags ? project.tags.join(',') : ''

    if (!project.title) {
      cogoToast.error('Project title is required')
      return
    }
    if (!project.coverImage) {
      cogoToast.error('Project cover image title is required')
      return
    }
    if (!project.primaryProjectType) {
      cogoToast.error('Project primary type is required')
      return
    }

    if (!project.contentData.set[0] || project.contentData.set[0].length === 0) {
      cogoToast.error('Project description is required')
      return
    }

    const filteredContentData = project.contentData.set.filter((cData) => cData !== '')

    const filteredContentType = project.contentType.set.filter(
      (cType, index) => index < filteredContentData.length,
    )

    const variables = {
      id: projectId,
      title: project.title,
      tags,
      primaryProjectType: project.primaryProjectType,
      secondaryProjectType: project.secondaryProjectType,
      secondaryProjectType_2: project.secondaryProjectType_2,
      contentType: filteredContentType,
      contentData: filteredContentData,
      coverImage: project.coverImage,
      members: project.members,
    }

    try {
      await client.mutate({
        mutation: UPDATE_PROJECT_MUTATION,
        variables,
      })
    } catch (error) {
      console.log('error: ', error)
    }
    // change them to the single item page
    history.push({
      pathname: '/preview',
      search: `?id=${projectId}`,
    })
    return true
  }

  const handleRemoveUserFromProject = async (member, index) => {
    const _project = { ...project }
    const members = _project.members.filter((_, idx) => idx !== index)
    setProject({ ...project, members })
    const _memberInputs = [ ...memberInputs ]
    _memberInputs.splice(index, 1)
    setMemberInputs(_memberInputs)
  }

  const handleRemoveContent = (index) => {
    const _project = { ...project }
    const setType = _project.contentType.set.filter((type, idx) => idx !== index)
    const setData = _project.contentData.set.filter((type, idx) => idx !== index)
    setProject({
      ...project,
      contentType: { set: setType },
      contentData: { set: setData },
    })
  }

  const handleChangeTags = (tags) => {
    setProject({
      ...project,
      tags,
    })
  }


  useEffect(() => {
    console.log(project)
  }, [project])


  if (loading) return <Loading />

  if (error) {
    return <div>{error}</div>
  }

  const handleDescription = (e) => {
    const { name, value } = e.target
    /* eslint-disable-next-line */
    const [, index] = name.split('contentData_')
    const _project = { ...project }
    _project.contentData.set.splice(0, 1, value)
    setProject(_project)
  }

  return (
    <ProjectForm onSubmit={(e) => updateProject(e)}>
      <fieldset disabled={loading} aria-busy={loading}>
        <GridOne>
          <Grid>
            <div>
              <HelveticaTitle>Project Information</HelveticaTitle>
              <label htmlFor='title'>
                * project title (&lt;55 characters)
                <InputLimit
                  type='text'
                  id='title'
                  name='title'
                  placeholder='Title'
                  required
                  maxLength={55}
                  value={project.title}
                  onChange={(e) => handleChange(e)}
                />
              </label>
              {project.members.map((member, index) => {
                const { role, id } = member
                const user = usersData.find((usr) => usr.id === id)
                return (
                  <Columns key={`key_${index + 1}`}>
                    <label htmlFor={`member_${index + 1}`}>
                      Member
                      <SearchInput
                        users={usersData.map(
                          (usr) => !project.members.some((mb) => mb.id === usr.id) && usr,
                        )}
                        onChange={(value) => handleMemberSearch(value, index)}
                        value={user && `${user?.firstName} ${user?.lastName}`}
                        disabled={index === 0}
                      />
                      {index !== 0 && (
                        <p
                          onClick={() => handleRemoveUserFromProject(member, index)}
                          style={{
                            fontSize: '18px',
                            color: '#000',
                            textDecoration: 'underline',
                          }}
                        >
                          Remove
                        </p>
                      )}
                    </label>
                    <label htmlFor={`role_${index + 1}`}>
                      Role
                      <input
                        type='text'
                        defaultValue={role}
                        onChange={(e) => handleRole(e, index)}
                      />
                    </label>
                  </Columns>
                )
              })}
              <p
                onClick={() => appendMember()}
                style={{
                  fontSize: '18px',
                  color: '#000',
                  textDecoration: 'underline',
                }}
              >
                + Group Member
              </p>
            </div>
            <div>
              <label htmlFor='coverImage'>
                <HelveticaTitle>Cover Image</HelveticaTitle>
                <UploadBlock>
                  <input
                    type='file'
                    id='coverImage'
                    name='coverImage'
                    placeholder='Upload an image'
                    onChange={(e) => handleFileUpload(e)}
                    className='upload-input'
                    accept={allowedImageExt}
                  />
                  {!project.coverImage ? (
                    <img className='upload-placeholder' src={'/static/drawing.svg'} alt='' />
                  ) : (
                    <CoverImage src={project.coverImage} alt='Upload Preview' />
                  )}
                  <UploadIcon src='/static/edit.svg' alt='Change Image' />
                </UploadBlock>
              </label>
            </div>
            <div>
              <label htmlFor='tags'>
                <HelveticaTitle>Tags</HelveticaTitle>
                <TagsInput defaultValue={project.tags || []} onChange={handleChangeTags} />
              </label>
            </div>
          </Grid>
          <GridThree>
            <label htmlFor='primaryProjectType'>
              <HelveticaTitle>* Primary Project Type</HelveticaTitle>
              <select
                id='primaryProjectType'
                name='primaryProjectType'
                value={project.primaryProjectType}
                onChange={handleChange}
              >
                <option disabled value={''}>
                  -- select a project type --
                </option>
                {Object.keys(projectType).map((key) => (
                  <option key={key} value={key}>
                    {projectType[key]}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor='secondaryProjectType'>
              <HelveticaTitle>Secondary Project Type</HelveticaTitle>
              <select
                id='secondaryProjectType'
                name='secondaryProjectType'
                value={project?.secondaryProjectType}
                onChange={handleChange}
              >
                <option disabled value={''}>
                  -- select a project type --
                </option>
                {Object.keys(projectType).map((key) => (
                  <option key={key} value={key}>
                    {projectType[key]}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor='secondaryProjectType_2'>
              <HelveticaTitle>Secondary Project Type</HelveticaTitle>
              <select
                id='secondaryProjectType_2'
                name='secondaryProjectType_2'
                value={project?.secondaryProjectType_2}
                onChange={handleChange}
              >
                <option disabled value={''}>
                  -- select a project type --
                </option>
                {Object.keys(projectType).map((key) => (
                  <option key={key} value={key}>
                    {projectType[key]}
                  </option>
                ))}
              </select>
            </label>
          </GridThree>
          <label htmlFor={`contentData_0`}>
            <HelveticaTitle>Short Description</HelveticaTitle>
            <LengthLimitTextarea
              type='text'
              id={`contentData_0`}
              name={`contentData_0`}
              maxLength={400}
              placeholder=''
              defaultValue={project?.contentData?.set[0]}
              onChange={(e) => handleDescription(e)}
            />
          </label>
          <HelveticaTitle>Content</HelveticaTitle>
          {contentInputs &&
            contentInputs?.length > 1 &&
            contentInputs?.slice(1).map((input) => (
              <GridOne key={`contentKey_${input}`}>
                <label htmlFor={`contentType_${input}`}>
                  <select
                    id={`contentType_${input}`}
                    name={`contentType_${input}`}
                    onChange={(e) => handleContentType(e)}
                    defaultValue={project?.contentType?.set[parseInt(input)]}
                    style={{ width: '24%' }}
                  >
                    <option disabled value={'DEFAULT'}>
                      -- select a content type --
                    </option>
                    <option value='Text'>Text</option>
                    <option value='Heading'>Heading</option>
                    <option value='Video'>Video</option>
                    <option value='Image'>Image</option>
                  </select>
                </label>
                {project.contentType.set[parseInt(input)] === 'Heading' && (
                  <label htmlFor={`contentData_${input}`}>
                    Heading
                    <ContentInput
                      type='text'
                      id={`contentData_${input}`}
                      name={`contentData_${input}`}
                      placeholder=''
                      defaultValue={project.contentData.set[parseInt(input)]}
                      onChange={(e) => handleContentData(e)}
                    />
                    <p
                      style={{
                        fontSize: '18px',
                        color: '#000',
                        textDecoration: 'underline',
                      }}
                      onClick={() => this.handleRemoveContent(parseInt(input))}
                    >
                      Remove
                    </p>
                  </label>
                )}
                {project.contentType.set[parseInt(input)] === 'Text' && (
                  <label htmlFor={`contentData_${input}`}>
                    Text
                    <ContentTextarea
                      type='text'
                      id={`contentData_${input}`}
                      name={`contentData_${input}`}
                      placeholder=''
                      defaultValue={project.contentData.set[parseInt(input)]}
                      onChange={(e) => handleContentData(e)}
                    />
                    <p
                      style={{
                        fontSize: '18px',
                        color: '#000',
                        textDecoration: 'underline',
                      }}
                      onClick={() => handleRemoveContent(parseInt(input))}
                    >
                      Remove
                    </p>
                  </label>
                )}
                {project.contentType.set[parseInt(input)] === 'Video' && (
                  <label htmlFor={`contentData_${input}`}>
                    Video
                    <ContentInput
                      type='file'
                      id={`video_${input}`}
                      name={`contentData_${input}`}
                      placeholder='Upload a video'
                      onChange={uploadContentFile}
                      accept={allowedVideoExt}
                    />
                    {[`contentType_${input}_loading`] > 0 && (
                      <Progress
                        percent={[`contentType_${input}_loading`]}
                        size='small'
                        color='green'
                      />
                    )}
                    <p
                      style={{
                        fontSize: '18px',
                        color: '#000',
                        textDecoration: 'underline',
                      }}
                      onClick={() => handleRemoveContent(parseInt(input))}
                    >
                      Remove
                    </p>
                  </label>
                )}
                {project.contentType.set[parseInt(input)] === 'Image' && (
                  <label htmlFor={`contentData_${input}`}>
                    Image
                    <ContentInput
                      type='file'
                      id={`image_${input}`}
                      name={`contentData_${input}`}
                      placeholder='Upload an image'
                      onChange={uploadContentFile}
                      accept={allowedImageExt}
                    />
                    {[`contentType_${input}_loading`] > 0 && (
                      <Progress
                        percent={[`contentType_${input}_loading`]}
                        size='small'
                        color='green'
                      />
                    )}
                    <p
                      style={{
                        fontSize: '18px',
                        color: '#000',
                        textDecoration: 'underline',
                      }}
                      onClick={() => handleRemoveContent(parseInt(input))}
                    >
                      Remove
                    </p>
                  </label>
                )}
                {project.contentData.set.map((content, j) => {
                  if (content !== undefined && parseInt(j) === parseInt(input)) {
                    if (project.contentType.set[parseInt(input)] === 'Image') {
                      return <img src={content} alt='Upload Preview ' key={content} />
                    } else if (project?.contentType?.set[parseInt(input)] === 'Video') {
                      return (
                        <video key={j}>
                          <source type='video/mp4' src={content} key={content}></source>
                        </video>
                      )
                    }
                  }
                  return null
                })}
              </GridOne>
            ))}
          <p onClick={() => appendContent()}>+ Add Field</p>
          <GridButton>
            <div></div>
            <Button type='Button' onClick={handlePreview} isLoading={!uploadFinished}>
              SAVE/PREVIEW
            </Button>
            <Button type='submit' isLoading={!uploadFinished}>
              PUBLISH
            </Button>
          </GridButton>
        </GridOne>
      </fieldset>
    </ProjectForm>
  )
}

export default withRouter(UpdateProject)

const SINGLE_PROJECT_QUERY = gql`
  query SINGLE_PROJECT_QUERY($id: ID!) {
    project(where: { id: $id }) {
      id
      title
      tags
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        id
        role
        user {
          id
        }
      }
      published
    }
  }
`

const UPDATE_PROJECT_MUTATION = gql`
  mutation UPDATE_PROJECT_MUTATION(
    $id: ID!
    $title: String
    $tags: String
    $primaryProjectType: String
    $secondaryProjectType: String
    $secondaryProjectType_2: String
    $contentType: [ContentType]
    $contentData: [String]
    $coverImage: String
    $members: [CustomInput]
    $published: Boolean
  ) {
    updateProject(
      id: $id
      title: $title
      tags: $tags
      primaryProjectType: $primaryProjectType
      secondaryProjectType: $secondaryProjectType
      secondaryProjectType_2: $secondaryProjectType_2
      contentType: $contentType
      contentData: $contentData
      members: $members
      coverImage: $coverImage
      published: $published
    ) {
      id
      title
      tags
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      members {
        id
        role
        user {
          id
        }
      }
      coverImage
      published
    }
  }
`
export { UPDATE_PROJECT_MUTATION }

const USER_UPLOAD_FILE = gql`
  mutation ($file: Upload!, $isVideo: Boolean) {
    singleUpload(file: $file, isVideo: $isVideo) {
      id
      path
      filename
    }
  }
`

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 20px;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 60px;
`
const GridOne = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  video {
    max-width: 50%;
  }
`
const GridThree = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 0.5fr;
  grid-gap: 60px;
`
const GridButton = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.5fr;
  grid-gap: 60px;
`

const UploadBlock = styled.div`
  background-color: #e3e3e3;
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .upload-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
  }

  .upload-placeholder {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.5;
    z-index: 2;
  }
`

const UploadIcon = styled.img`
  background: rgb(243, 215, 222);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  height: 50px;
  width: 50px;
  padding: 10px;

  &:hover,
  &:focus {
    outline: none;
  }
`

const CoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`

const ContentInput = styled.input`
  margin-bottom: 30px;
`

const ContentTextarea = styled.textarea`
  margin-bottom: 30px;
`

const HelveticaTitle = styled.p`
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: black;
  font-size: 24px;
`
const Button = styled.button`
  ${(props) =>
    props.isLoading
      ? `
    opacity: .7;
    pointer-events: none;`
      : ''}
`
