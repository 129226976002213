import React from 'react'
import styled from 'styled-components'
import StickerNavs from '../StickerNavs'
import cn from 'classnames'
import { useDisclosure } from 'react-use-disclosure'
import Footer from '../../../components/2022/Footer'
import SearchCol from '../../../components/2022/SearchIcon'
import { MobileRightNavs, MobileNavsBottom } from '../MobileRightNavs'


const Container = styled.div`
  min-height: calc(100vh);
  cursor: url(${(props) => props.cursorUrl}), auto;
  flex-direction: column;
`
const CurvedContainer = styled.div`
  background: #5933ff;
  display: inline-block;
  position: relative;
  width: 100%;

  height: 300px;
  vertical-align: middle;
  overflow: hidden;
`

const CenterContainer = styled.div`
  @media (min-width: 1280px) {
    // top: 10%;
    // max-height: 40vh;
  }
  @media (min-width: 1920px) {
    // top: 20%;
  }
  width:-webkit-calc(100%- 24rem);
  width:-moz-calc(100% - 24rem);
  width:calc(100% - 24rem);

  @media (max-width: 1280px) {
    width:100%;
  }
`
const TextCenter = styled.h4`
  font-family: 'sharp_grotesque_05';
  font-size: 420px;
  line-height: 360px;
  letter-spacing: 0.01em;
  font-weight: 400;
  @media (min-width: 360px) {
    font-size: 460px;
    line-height: 400px;
  }
  @media (min-width: 768px) {
    font-size: 650px;
    line-height: 550px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 600px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 700px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 1000px;
    line-height: 1200px;
  }
  @media (min-width: 2880px) {
    font-size: 1400px;
    line-height: 1400px;
  }
`

export default function Work(props) {

  const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()

  return (
    <>
    <Container
      className='w-full h-full bg-blue flex mt-28 lg:mt-0'
      cursorUrl='/static/svg/cursor-with-shadow.svg'
    >
      <StickerNavs
        className={cn('w-96 fixed  top-0 h-screen transition-opacity duration-700 ease-in-out hidden lg:block')}
      />
      <CenterContainer className='w-full mx-auto lg:ml-96 pb-12'>
      <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs} />
      <div
            className={cn( 'transition',{
              'flex visible opacity-100': !isOpenMenuNavs,
              'hidden invisible opacity-0': isOpenMenuNavs,
            })}
          >
          {
            (window.innerWidth < 600 && !isOpenMenuNavs) ?  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 320" preserveAspectRatio="xMidYMin slice"
            style={{position: 'relative', top: '3px',width: '100%', paddingBottom: '1%', height: '50px',overflow: 'visible', marginTop:'-30px', zIndex:6, backgroundColor:"#333"}}><path fill="#5933FF" fillOpacity="1" d="M0,64L120,69.3C240,75,480,85,720,122.7C960,160,1200,224,1320,256L1440,288L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z" ></path></svg>: null
          }
        <div className='flex'>
          <div className='w-full lg:w-3/4 xl:w-full ' style={{backgroundColor: '#333', backgroundRepeat: 'no-repeat',zIndex:5, height:'auto'}}>
            <div className='text-center'>
              <TextCenter className='text-secondary'>SHOW</TextCenter>
            </div>
            <div id='wrapper'>
            </div>
            <div className={cn('w-11/12 lg:w-5/12 lg:pb-24')} style={{color:'white', fontFamily: 'reckless_light', fontSize:'40px', margin:'auto', textAlign:'center', lineHeight:'1em'}}>
              YES! is the official grad show of the School of Design at <br/>George Brown College.
            </div>
            <div className={cn('pt-4 leading-9 xl:leading-10')} style={{color:'white', fontFamily: 'reckless_light', width:'40vw', fontSize:'20px', margin:'auto', textAlign:'center'}}>
              Join us live for our Annual Award Show on May 7  <br/>@ 7PM EST
            </div>
            <button style={{backgroundColor: '#5e31fe', height: '40px' , borderRadius: '10px' , width:'100px' , color: 'white', fontFamily: 'grotesque_pro_light' , position:'absolute', left:'53%', marginTop:'50px'}}>
              Virtual Link
            </button>
            <CurvedContainer>
              <svg viewBox='0 0 500 500' preserveAspectRatio='xMinYMin meet'>
                <path
                  d='M0,50 C150,100 350,0 500,10 L500,00 L0,0 Z'
                  style={{ keyStroke: 'none', fill: '#333' }}
                ></path>
              </svg>
            </CurvedContainer>
          </div>
          <div className='w-12 bg-blue hidden lg:block'>
            {/* <img src='/static/svg/glass.svg' alt='glass' className={cn('cursor-pointer')} /> */}
          </div>
        </div>
      </div>
      <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
      </CenterContainer>
      <Footer/>
   </Container>
   <SearchCol  className='hidden lg:block' />
   </>
  )
}
