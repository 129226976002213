import React, { Component } from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import Error from './ErrorMessage'
import styled from 'styled-components'
import {Helmet} from 'react-helmet-async'
import { Link, withRouter } from 'react-router-dom'
import SimilarProjects from './SimilarProjects'
import ReactMarkDown from 'react-markdown'
import { client } from '../..'
import withImageLightbox from '../2020/HOC/withImageLightbox'
import Loading from './Loading'

const SingleProjectStyles = styled.div`
  align-items: center;
  text-align: center;
  margin: 0 24px;
  font-family: 'grotesque_pro_light';

  @media (min-width: 768px) {
    margin: 0 50px;
    margin-top: 100px;
  }

  .projectText p {
    text-align: left;
    margin: auto;
    padding-bottom: 50px;
    width: 50vw;
  }
`

const Image = styled.img`
  width: max-content;
  max-width: 80vw;
  width: 80vw;
  margin-bottom: 50px;
`

const Video = styled.video`
  width: 80vw;
  max-width: 80vw;
  margin-bottom: 50px;
`

const Title = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px 20px;
  margin: 0 auto 20px;
  font-size: 32px;
  line-height: 1.1;
  letter-spacing: -1px;
  word-break: break-all;

  @media (min-width: 576px) {
    font-size: 44px;
  }

  @media (min-width: 768px) {
    grid-gap: 50px 80px;
    margin-bottom: 50px;
    max-width: ${(props) => props.theme.maxWidth};
    padding: 0;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }

  div {
    position: relative;
    display: block;
  }

  p {
    float: left;
    text-align: left;
  }
`
const Types = styled.div`
  display: flex;
  margin: 0 auto;
  margin-bottom: 12px;
  font-size: 20px;
  font-family: 'grotesque_pro_light';
  letter-spacing: -1px;

  @media (min-width: 768px) {
    justify-content: flex-end;
    max-width: ${(props) => props.theme.maxWidth};
  }

  div {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  p {
    margin-right: 15px;
    text-decoration: underline;

    @media (min-width: 768px) {
      margin-left: 50px;
      margin-right: 0;
    }
  }
`

const Members = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px 20px;
  margin: 0 auto;
  font-size: 20px;
  font-family: 'grotesque_pro_light';
  letter-spacing: -1px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    max-width: ${(props) => props.theme.maxWidth};
    grid-gap: 50px 80px;
  }

  p {
    float: left;
  }
`

const Member = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: center;
  font-size: 20px;
  font-family: 'grotesque_pro_light';
  letter-spacing: -1px;

  p {
    float: left;
  }

  a {
    float: left;
    text-decoration: underline;
  }
`

const GridButton = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.5fr;
  grid-gap: 60px;
`
const Button = styled.button`
  width: 100%;
  background: ${(props) => props.theme.pink};
  color: ${(props) => props.theme.black};
  border: 0;
  font-family: 'grotesque_pro_light';
  font-size: 2rem;
  font-weight: 600;
  padding: 0.5rem 2.2rem;
  margin-top: 5rem;
  margin-bottom: 3rem;
  border-radius: 3rem;

  @media (min-width: 768px) {
    font-size: 2.4rem;
    font-weight: 300;
    min-width: 180px;
    padding: 0.75rem 1.5rem;
    text-transform: uppercase;
  }
`

const TitleContent = styled.h2`
  font-size: 44px;
  font-family: 'grotesque_pro_light';
  text-align: left;
  margin: auto;
  padding-bottom: 24px;
  width: 50vw;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`

const ErrorWrapper = styled.div`
  transform: translateY(65%);
`

const SINGLE_ITEM_QUERY = gql`
  query SINGLE_ITEM_QUERY($id: ID!) {
    project(where: { id: $id }) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        role
        user {
          id
          firstName
          lastName
        }
      }
      published
    }
  }
`

const PUBLISH_PROJECT_MUTATION = gql`
  mutation PUBLISH_PROJECT_MUTATION($id: ID!) {
    publishProject(id: $id) {
      id
      title
      tags
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      members {
        id
        role
        user {
          id
        }
      }
      coverImage
      published
    }
  }
`

class SingleProject extends Component {
  handlePublish = async () => {
    const variables = {
      id: this.props.id,
    }

    try {
      await client.mutate({
        mutation: PUBLISH_PROJECT_MUTATION,
        variables,
      })
    } catch (error) {
      console.log('error: ', error)
    }
    // change them to the single item page
    this.props.history.push({
      pathname: '/2020/project',
      search: `?id=${this.props.id}`,
    })
    // console.log("res data", res.data);
    return true
  }

  handleImgLightbox = (imgSrc) => {
    const { lightbox } = this.props
    lightbox.show({ img: imgSrc })
  }

  render() {
    return (
      <Query
        query={SINGLE_ITEM_QUERY}
        variables={{
          id: this.props.id,
        }}
      >
        {({ error, loading, data }) => {
          console.log('error: ', error)
          if (error) {
            const errorMsg = error.graphQLErrors.map((err) => err.message).join(',')
            return (
              <ErrorWrapper>
                <Error error={{ message: errorMsg }} />
              </ErrorWrapper>
            )
          }
          if (loading) return <Loading />
          if (!data.project) return <p> No Project found for {this.props.id}</p>
          const project = data.project
          const primaryProjectType = project.primaryProjectType
          let ppt = primaryProjectType.replace('_', ' ')
          let splitStr = ppt.split(' ')
          for (let i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
            if (splitStr[i].charAt(0) === '3') {
              splitStr[i] =
                splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1).toUpperCase()
            }
          }
          const projectTypeOne = splitStr.join(' ')

          const secondaryProjectType = project.secondaryProjectType
          let pptX = secondaryProjectType.replace('_', ' ')
          let splitStrX = pptX.split(' ')
          for (let i = 0; i < splitStrX.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStrX[i] = splitStrX[i].charAt(0).toUpperCase() + splitStrX[i].substring(1)
            if (splitStrX[i].charAt(0) === '3') {
              splitStrX[i] =
                splitStrX[i].charAt(0).toUpperCase() + splitStrX[i].substring(1).toUpperCase()
            }
          }
          const projectTypeTwo = splitStrX.join(' ')

          const secondaryProjectTypeTwo = project.secondaryProjectType_2
          let pptY = secondaryProjectTypeTwo.replace('_', ' ')
          let splitStrY = pptY.split(' ')
          for (var i = 0; i < splitStrY.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStrY[i] = splitStrY[i].charAt(0).toUpperCase() + splitStrY[i].substring(1)
            if (splitStrY[i].charAt(0) === '3') {
              splitStrY[i] =
                splitStrY[i].charAt(0).toUpperCase() + splitStrY[i].substring(1).toUpperCase()
            }
          }
          const projectTypeThree = splitStrY.join(' ')
          return (
            <SingleProjectStyles>
              <Helmet>
                <title>YES! | {project.title}</title>
              </Helmet>
              <Title>
                <div>
                  <p>{project.title}</p>
                </div>
              </Title>
              <Types>
                <div>
                  <Link
                    to={{
                      pathname: '/work',
                      search: `?type=${project.secondaryProjectType_2}`,
                    }}
                  >
                    <p>{projectTypeOne}</p>
                  </Link>
                  <Link
                    to={{
                      pathname: '/work',
                      search: `?type=${project.secondaryProjectType}`,
                    }}
                  >
                    <p>{projectTypeTwo}</p>
                  </Link>
                  <Link
                    to={{
                      pathname: '/work',
                      search: `?type=${project.primaryProjectType}`,
                    }}
                  >
                    <p>{projectTypeThree}</p>
                  </Link>
                </div>
              </Types>
              {project.members.map((member, index) => (
                <Members key={`member-${index}`}>
                  <Member>
                    <div>
                      <Link
                        to={{
                          pathname: '/student',
                          search: `id=${member.user.id}`,
                        }}
                      >
                        {member.user.firstName} {member.user.lastName}
                      </Link>
                    </div>
                    <div>
                      <p>/&nbsp;&nbsp;{member.role}</p>
                    </div>
                  </Member>
                </Members>
              ))}
              <br />
              {/* <Image src={project.coverImage} alt={project.title} /> */}

              {project.contentType.map((type, i) => {
                if (type === 'Heading') {
                  return <TitleContent key={i}>{project.contentData[i]}</TitleContent>
                } else if (type === 'Text') {
                  return (
                    <div className='projectText'>
                      <ReactMarkDown key={i} children={project.contentData[i]} />
                    </div>
                  )
                } else if (type === 'Image') {
                  return (
                    <div key={i}>
                      <Image
                        src={project.contentData[i]}
                        alt='Image Preview'
                        onClick={() => this.handleImgLightbox(project.contentData[i])}
                      />
                    </div>
                  )
                } else if (type === 'Video') {
                  return (
                    <div key={i}>
                      <Video controls>
                        <source type='video/mp4' src={project.contentData[i]} />
                      </Video>
                    </div>
                  )
                }
                return null
              })}
              {this.props.location.pathname === '/project' ? (
                <SimilarProjects currentProjectId={project.id} type={project.primaryProjectType} />
              ) : (
                <GridButton>
                  <div></div>
                  <Link
                    to={{
                      pathname: '/update',
                      search: `id=${project.id}`,
                    }}
                  >
                    <Button type='Button'>EDIT</Button>
                  </Link>
                  <Button type='button' onClick={this.handlePublish}>
                    PUBLISH
                  </Button>
                </GridButton>
              )}
            </SingleProjectStyles>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(withImageLightbox(SingleProject))
