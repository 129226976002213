import React from 'react'
import styled from 'styled-components'
import useWindowSize from 'react-use/lib/useWindowSize'

const Viewbox = styled.div`
  overflow: hidden;
  width: 100%;
  height: 150px;
  margin-bottom: 20vh;
  margin-top: 22vh;
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 3fr 6fr;
  mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, rgba(0, 0, 0, 0)),
    color-stop(0.35, rgba(0, 0, 0, 1)),
    color-stop(0.65, rgba(0, 0, 0, 1)),
    color-stop(1, rgba(0, 0, 0, 0))
  );
  @media (max-width: 768px) {
    margin-top: 7vh;
  }
`
const Wrapper = styled.div`
  position: relative;
  transition: transform 0.01s;
`

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const Li = styled.li`
  display: flex;
  align-items: start;
  height: 144px;
  text-align: left;
  font-size: 23px;
  line-height: 1;
  font-family: 'grotesque_pro_light';
  @media (min-width: 768px) {
    font-size: 40px;
  }
  @media (min-width: 992px) {
    font-size: 50px;
  }
`

let transformValue = 20;
const Scrollbox = (props) => {
    const { y } = props;
    const { height } = useWindowSize();

    if (y <= height/15*3) {
      transformValue = 0;
    } else if (height/15*3 <= y && y <= height/15*4) {
      transformValue = -144;
    } else if (height/15*4 <= y && y <= height/15*5) {
      transformValue = -288;
    } else if (height/15*5 <= y && y <= height/15*6) {
      transformValue = -433;
    } else if (height/15*6 <= y && y <= height/15*7) {
      transformValue = -575;
    } else if (height/15*7 <= y && y <= height/15*8) {
      transformValue = -720;
    } else if (height/15*8 <= y && y <= height/15*9) {
      transformValue = -864;
    } else if (height/15*9 <= y && y <= height/15*10) {
      transformValue = -1008;
    } else if (height/15*10 <= y && y <= height/15*11) {
      transformValue = -1153;
    } else if (height/15*11 <= y && y <= height/15*12) {
      transformValue = -1297;
    } else if (height/15*12 <= y && y <= height/15*13) {
      transformValue = -1440;    
    } else if (height/15*13 <= y && y <= height/15*14) {
      transformValue = -1585;
    } else if (y >= height/15*14) {
      transformValue = -1728;
    }


    const styles = {
      transform: `translateY(${transformValue}px)`,
      textAlign: 'left',
      paddingLeft: '10px',
      paddingTop: '26px',
      transition: 'transform 0.1s',
    }
    const yeStyles = {
      float: 'right',
      paddingTop: "26px",
    }
    return (
      <Viewbox>
        <Wrapper id='slotmachine'>
          <Ul style={yeStyles}>
            <Li>YES!</Li>
            <Li>YES!</Li>
            <Li>YES!.</Li>
          </Ul>
        </Wrapper>
        <Wrapper style={styles} id='slotmachine'>
          <Ul>
            <Li>We’re designers.</Li>
            <Li>We’re multi-talented.</Li>
            <Li>We’re game-changers.</Li>
            <Li>We’re an amazing team.</Li>
            <Li>We’re strategic thinkers.</Li>
            <Li>We’re experts in our field.</Li>
            <Li>We’re going to get through this.</Li>
            <Li>We’re problem solvers.</Li>
            <Li>We’re future-ready.</Li>
            <Li>We’re ahead of curves.</Li>
            <Li>We’re a supportive bunch.</Li>
            <Li>We’re still going to show you what we’ve been up to -- no matter what.</Li>
            <Li>We’re the School of Design.</Li>
          </Ul>
        </Wrapper>
      </Viewbox>
    )
}

export default Scrollbox;
