import styled from "styled-components";
import React, { Component } from "react";

const LogoGram = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  a {
    color: ${(props) => props.theme.black};
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
  }
  img {
    margin-right: 10px;
    width: 20px;
  }
  div {
    display: inline;
    padding-left: 5px;
    font-size: 18px;
    font-weight: normal;
    font-family: 'reckless_light';
  }
  textalign: center;
  lineheight: 10px;
  margin: auto;
  float: left;
  @media (max-width: 768px) {
    margin: 0;
    text-align: center;
    float: none;
  }
`

const LogoMail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  a {
    color: ${(props) => props.theme.black};
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
  }
  img {
    margin-right: 10px;
    width: 20px;
  }
  div {
    display: inline;
    padding-left: 5px;
    font-size: 18px;
    font-weight: normal;
    font-family: 'reckless_light';
  }
  textalign: center;
  lineheight: 10px;
  margin: auto;
  float: right;
  @media (max-width: 768px) {
    float: none;
    margin: 0;
    text-align: center;
  }
`

const StyledFooter = styled.div`
  margin-top: 190px;
  height: 140px;
  width: 100vw;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0px;
  background-color: ${(props) => props.theme.pink};
  color: ${(props) => props.theme.black};
  overflow: hidden;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  padding: 1em;
  line-height: normal;
  @media (max-width: 768px) {
    grid-gap: 50px;
  }
`

const FooterLinks = styled.div`
  width: 100vw;
  display: grid;
  grid-template-columns: 6fr 5fr;
  grid-gap: 15px;
  background-color: ${(props) => props.theme.pink};
  color: ${(props) => props.theme.black};
  overflow: hidden;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0 2em 0;
  line-height: normal;
  a:hover {
    color: black;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
  }
`


class Footer extends Component {
  state = {
    url: ""
  };
  componentDidMount() {
    this.setState({ url: window.location.href });
  }
  render() {

    const pStyles = {
      textAlign: "center",
      lineHeight: "10px",
      margin: "auto"
    }
    if (
      this.state.url.indexOf("projects") > -1 ||
      this.state.url.indexOf("edit") > -1 ||
      this.state.url.indexOf("login") > -1 ||
      this.state.url.indexOf("update") > -1 ||
      this.state.url.indexOf("add") > -1 ||
      this.state.url.indexOf("signup") > -1
    ) {
      return (null);
    }
    return (
      <StyledFooter>
        <p style={pStyles}>George Brown College, School of Design</p>
        <FooterLinks>
          <a href='mailto:design@georgebrown.ca' target='_blank' rel='noopener noreferrer'>
            <LogoMail>
              <img src={'/static/email.png'} alt={'Email Icon'} />
              <div>design@georgebrown.ca</div>
            </LogoMail>
          </a>
          <a href='https://www.instagram.com/designgbc/' target='_blank' rel='noopener noreferrer'>
            <LogoGram>
              <img src={'/static/instaLogo.png'} alt='logo' />
              <div>@designGBC</div>
            </LogoGram>
          </a>
        </FooterLinks>
      </StyledFooter>
    )
  }
}

export default Footer;
