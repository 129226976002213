import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import cn from 'classnames'

const Main = styled.div`
  a:hover {
    color: #5933ff;
  }
`

export default function FixedBottomNav({ className = '', goBack = false }) {

  const onClickScroll = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
    let scroll2022top = document.getElementById("scroll2022top");
    if(scroll2022top){
      scroll2022top.scrollIntoView();
    }
  }

  return (
    <Main
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100vw',
        backgroundColor: '#191919',
        height: '40px',
        zIndex: 1001,
      }}
    >
      <link rel='stylesheet' href='/static/2022/css/transition-css.css' type='text/css' />
      <div className='left-layer'></div>
      <div className='right-layer'></div>
      <Link to="/2020"  className='text-5xl inline-block mt-6 mb-20'>
        <div
          className={cn('w-36 sm:w-72')}
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bottom: 0,
            left: 0,
            height: '40px',
            backgroundColor: '#efd6d9',
            zIndex: '1002',
          }}
        >
          <p
            style={{
                position: 'relative',
                fontWeight: 900,
                fontFamily: 'sharp_grotesque',
                fontSize: '30px',
                lineHeight: 'normal',
                letterSpacing: '0.02em',
                background: 'transparent'
            }}
          >
            2020
          </p>
        </div>
      </Link>
      <Link to="/2021" className='text-5xl inline-block mt-6 mb-20'>
        <div
          className={cn('w-36 sm:w-72 left-36 sm:left-72')}
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bottom: 0,
            height: '40px',
            backgroundColor: '#5933ff',
            zIndex: '1002',
          }}
        >
          <p
            style={{
                fontWeight: 900,
                fontFamily: 'sharp_grotesque',
                fontSize: '30px',
                lineHeight: 'normal',
                letterSpacing: '0.02em',
                color: '#defc1c',
                background: 'transparent'
            }}
          >
            2021
          </p>
        </div>
      </Link>

      <Link to='/2023/home' className='text-5xl inline-block mt-6 mb-20'>
        <div
          className={cn('w-36 sm:w-72 left-72 sm:left-144')}
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bottom: 0,
            height: '40px',
            backgroundColor: '#26439B',
            zIndex: '1002',
            border: '1px solid #26439B',
          }}
        >
          <p
            style={{
                fontWeight: 900,
                fontFamily: 'sharp_grotesque',
                fontSize: '30px',
                lineHeight: 'normal',
                letterSpacing: '0.02em',
                color: '#fbe8ee',
                background: 'transparent'
            }}
          >
            2023
          </p>
        </div>
      </Link>
      <Link to='/home' className='text-5xl inline-block mt-6 mb-20'>
        <div
          className={cn('w-36 sm:w-72 left-120 sm:left-192 bg-almost-black')}
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bottom: 0,
            height: '40px',
            zIndex: '1002',
          }}
        >
          <p
           className='text-comfort-green'
            style={{
                fontWeight: 900,
                fontFamily: 'sharp_grotesque',
                fontSize: '30px',
                lineHeight: 'normal',
                letterSpacing: '0.02em',
                background: 'transparent'
            }}
          >
            2024
          </p>
        </div>
      </Link>
      <button onClick={onClickScroll} className='text-5xl inline-block mt-6 mb-20'>
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bottom: 0,
            right: 0,
            height: '40px',
            width: '40px',
            background: '#191919',
            zIndex: '1002',
          }}
        >
          <img
              src='/static/svg/2022/back-to-top-42x42.svg'
              alt='glass'
              style={{background: '#191919',}}
          />
        </div>
      </button>
    </Main>
  )
}
