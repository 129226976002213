import Profile from "../../components/2020/Profile";
import React from "react";

const Project = props => (
  <div>
    <Profile id={props.location.search.split("id=")[1]} />
  </div>
);

export default Project;
