import styled from 'styled-components'

export const H1 = styled.h1`
  font-family: 'sharpgrotesque20';
  font-size: 72px;
  line-height: 100%;
  letter-spacing: 0.01em;
  @-moz-document url-prefix() {
    font-weight: 100; /* Adjust as necessary */
  }

`
export const H1M = styled.h1`
  font-family: 'sharpgrotesque20';
  font-size: 56px;
  line-height: 100%;
  letter-spacing: 0.01em;
  @-moz-document url-prefix() {
    font-weight: 100; /* Adjust as necessary */
  }
`

export const H2 = styled.h2`
  font-family: 'sharp_grotesque_15';
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  @-moz-document url-prefix() {
    font-weight: 100; /* Adjust as necessary */
  }
`

export const H3 = styled.h3`
  font-family: 'reckless_light';
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: -0.02em;
  @-moz-document url-prefix() {
    font-weight: 100; /* Adjust as necessary */
  }
`

export const H4 = styled.h4`
  font-family: 'basis-grotesque-light-pro';
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: -0.02em;
  @-moz-document url-prefix() {
    font-weight: 100; /* Adjust as necessary */
  }
`

export const P = styled.p`
  font-family: 'basis-grotesque-light-pro';
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 170%;
  letter-spacing: -0.02em;
`


export const FormBody = styled.form`
  font-family: 'basis-grotesque-light-pro';
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 170%;
  letter-spacing: -0.02em;
`

export const Psm = styled.p`
  font-family: 'basis-grotesque-light-pro';
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;
`

export const A = styled.span`
  font-family: 'reckless_light';
  font-size: 18px;
  font-weight: 300;
  line-height: 170%;
  letter-spacing: 0em;
`
export const Asm = styled.span`
  font-family: 'reckless_light';
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0e;
`

export const H1_sm = styled.h1`
  font-family: 'sharpgrotesque25';
  font-size: 40px;
  line-height: 100%;
  letter-spacing: 0.01em;
  @-moz-document url-prefix() {
    font-weight: 100; /* Adjust as necessary */
  }
`
