import React from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { projectType } from '../../constant'
import { VscChevronDown } from 'react-icons/vsc'

const ExploreP = styled.div`
  background-color: white;
  position: absolute;
  height: 60px;
  top: 30%;
  @media (min-width: 768px) {
    top: 40%;
  }
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  right: 0;
  border-radius: 30px;

  &:hover {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
  }
  .dropdown-content a:hover {
    background-color: #ddd;
  }

  svg {
    top: 12px;
    color: #5933ff;
    right: 24px;
    transition: transform 330ms;
  }

  &:hover svg {
    transform: rotateZ(180deg);
  }

  p {
    color: #5933ff;
    position: absolute;
    line-height: 60px;
    margin-bottom: 0;
    width: 100%;
  }
  .dropdown-content {
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s,
      opacity 0.3s ease-in;
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    left: 0;
    margin-top: 60px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
  .dropdown-content a {
    display: block;
    color: #5933ff;
  }
`
const ExploreC = styled.div`
  background-color: white;
  position: absolute;
  top: 100px;
  height: 60px;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  right: 0;
  border-radius: 30px;

  svg {
    top: 12px;
    color: #5933ff;
    right: 24px;
    transition: transform 330ms;
  }

  &:hover svg {
    transform: rotateZ(180deg);
  }

  &:hover {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
  }
  .dropdown-content a:hover {
    background-color: #ddd;
  }

  p {
    color: #5933ff;
    position: absolute;
    line-height: 60px;
    margin-bottom: 0;
    width: 100%;
  }
  .dropdown-content {
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s,
      opacity 0.3s ease-in;
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    left: 0;
    margin-top: 60px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
  .dropdown-content a {
    display: block;
    color: #5933ff;
  }
`

export const DropdownFilter = ({ program, type }) => {
  const { pathname } = useLocation()
  return (
    <>
      <ExploreP className='relative z-10 w-full md:w-1/2 xl:w-1/3 max-w-xl'>
        <p className=''>{program || 'Explore by program'}</p>
        <VscChevronDown className='absolute text-6xl' />
        <div className='dropdown-content'>
          <Link
            to={{
              pathname,
            }}
          >
            None
          </Link>
          <Link
            to={{
              pathname,
              search: '?program=Graphic%20Design',
            }}
          >
            Graphic Design
          </Link>
          <Link to={{ pathname, search: '?program=Interaction%20Design' }}>Interaction Design</Link>
          <Link
            to={{
              pathname,
              search: '?program=Interactive%20Media%20Management',
            }}
          >
            Interactive Media Management
          </Link>
          <Link to={{ pathname, search: '?program=Game%20-%20Art' }}>Game - Art</Link>
          <Link
            to={{
              pathname,
              search: '?program=Concept%20Art%20for%20Entertainment',
            }}
          >
            Concept Art for Entertainment
          </Link>
          <Link
            to={{
              pathname,
              search: '?program=Digital%20Design%20-%20Game%20Design',
            }}
          >
            Digital Design - Game Design
          </Link>
          <Link to={{ pathname, search: '?program=Design%20Management' }}>Design Management</Link>
          <Link
            to={{
              pathname,
              search: '?program=Interdisciplinary%20Design%20Strategy',
            }}
          >
            Interdisciplinary Design Strategy
          </Link>
          <Link
            to={{
              pathname,
              search: '?program=Art%20%26%20Design%20Foundation',
            }}
          >
            Art & Design Foundation
          </Link>
        </div>
      </ExploreP>
      <ExploreC className='relative w-full md:w-1/2 xl:w-1/3 max-w-xl'>
        <p className='relative'>{projectType[type] || 'Explore by Category'}</p>
        <VscChevronDown className='absolute text-6xl' />
        <div className='dropdown-content'>
          <Link to={{ pathname, search: '?type=3d_design' }}>3D Design</Link>
          <Link to={{ pathname, search: '?type=3d_sculpting' }}>3D Sculpting</Link>
          <Link to={{ pathname, search: '?type=3d_visualizations' }}>3D Visualizations</Link>
          <Link to={{ pathname, search: '?type=advertising' }}>Advertising</Link>
          <Link to={{ pathname, search: '?type=animation' }}>3D Animation</Link>
          <Link to={{ pathname, search: '?type=augmented_virtual_reality' }}>
            Augmented/Virtual Reality
          </Link>
          <Link to={{ pathname, search: '?type=branding' }}>Branding</Link>
          <Link to={{ pathname, search: '?type=digital_applications' }}>3D Sculpting</Link>
          <Link to={{ pathname, search: '?type=3d_sculpting' }}>Digital Applications</Link>
          <Link to={{ pathname, search: '?type=drawing' }}>Drawing</Link>
          <Link to={{ pathname, search: '?type=editorial' }}>Editorial</Link>
          <Link to={{ pathname, search: '?type=exhibition' }}>Exhibition</Link>
          <Link to={{ pathname, search: '?type=illustration' }}>Illustration</Link>
          <Link to={{ pathname, search: '?type=immersive_environments' }}>
            Immersive Environments
          </Link>
          <Link to={{ pathname, search: '?type=interactive_design' }}>Interactive Design</Link>
          <Link to={{ pathname, search: '?type=interface_development' }}>
            Interface Development
          </Link>
          <Link to={{ pathname, search: '?type=media_production' }}>Media Production</Link>
          <Link to={{ pathname, search: '?type=modeling' }}>Modeling</Link>
          <Link to={{ pathname, search: '?type=motion_graphics' }}>Motion Graphics</Link>
          <Link to={{ pathname, search: '?type=packaging' }}>Packaging</Link>
          <Link to={{ pathname, search: '?type=photography' }}>Photography</Link>
          <Link to={{ pathname, search: '?type=physical_computing' }}>Physical Computing</Link>
          <Link to={{ pathname, search: '?type=strategy' }}>Strategy</Link>
          <Link to={{ pathname, search: '?type=systems_and_services' }}>Systems and Services</Link>
          <Link to={{ pathname, search: '?type=technological_systems' }}>
            Technological Systems
          </Link>
          <Link to={{ pathname, search: '?type=user_experience' }}>User Experience</Link>
          <Link to={{ pathname, search: '?type=video' }}>Video</Link>
          <Link to={{ pathname, search: '?type=web_design' }}>Web Design</Link>
        </div>
      </ExploreC>
    </>
  )
}
