import UserProjects from "../../components/CMS/UserProjects";
import React from "react";
import { withProjectsContext } from "../../components/CMS/states/userProjects";

const Projects = props => {
  return(
  <div>
    <UserProjects page={1} {...props} />
  </div>
)
};

export default withProjectsContext( Projects );
