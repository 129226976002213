import React, { useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {gql, useQuery} from '@apollo/client'
import { useLocation} from 'react-router'
import qs from 'query-string'
import cn from 'classnames'
import upperFirst from 'lodash.upperfirst'
import {useDisclosure} from 'react-use-disclosure'
import {Link} from 'react-router-dom'
import {VscMenu} from 'react-icons/vsc'
import {default as UserProfile, ProfileSmall} from '../../../components/2023/Profile'
import StickerNavsBlue from '../StickerNavsBlue'
import SearchIcon from '../../../components/2023/SearchIcon'
import {projectType} from '../../../constant'
import {MobileNavsBottom, MobileRightNavs} from '../MobileRightNavs'
import FixedBottomNav from '../FixedBottomNav'
import {Element} from 'react-scroll'
import Footer from '../Footer'
import {WorkBGBottom, FooterContainer} from "../../../styles/2023/WorkStyle";
import cursorSVG from "../cursor-with-shadow-2023.svg";
import ColoredScrollbars2023 from "../../../components/2023/ColoredScrollbars2023";


const Container = styled.div`
  min-height: calc(100vh);
  cursor: url(${cursorSVG}), auto;
  flex-direction: column;
  background-color: #F0EEE9 !important;

`

const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 210px auto;
  ${props => props.isThereProject ? '' : 'min-height: 1200px'};
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    
    .sidebar {
      display: none;
    }
  }
`

const WorksContainer = styled.div`
  background: #F0EEE9;

  svg {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .curve-container {
    background: #F0EEE9;
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 15%;
    vertical-align: middle;
    overflow: hidden;
  }

  @media (max-width: 1023px) {
    margin-top: 90px;
  }
`
const StudentName = styled.h3`
  font-family: 'reckless_light';
`

export default function Profile(props) {
    const {search} = useLocation()
    const {id} = qs.parse(search)
    // console.log('🚀 ~ Profile ~ id', id)

    useEffect(() => {
        const leftLayer = document.getElementsByClassName('left-layer')?.[0]
        const rightLayer = document.getElementsByClassName('right-layer')?.[0]
        if (leftLayer && rightLayer) {
            let topWidth = window.innerWidth
            if (window.innerWidth < 600) {
                topWidth = 5840
            }
            leftLayer.style.borderTopWidth = `${topWidth}px`
            leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
            rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
            rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
            rightLayer.style.zIndex = 0
        }
    }, [])

    useEffect(() => {
        let body = document.getElementsByTagName('body')?.[0]
        body.style.height = 'auto'
        window.scroll(0, 0)
    }, [])

    const {data: studentData} = useQuery(SINGLE_USER_QUERY, {
        variables: {
            id,
        },
    })

    const {data: studentProjectData} = useQuery(STUDENT_PROJECTS_QUERY, {
        variables: {
            id,
        },
    })

    const userId = props.id
    const user = studentData?.student || {}

    // console.log('🚀 ~ Profile ~ user', user)
    const projects = useMemo(
        () =>
            (studentProjectData?.studentProjects || [])
                .map((prj) => {
                    const {orderList} = prj
                    const meOrdersList = orderList.find((ord) => ord.userId === userId)
                    return {...prj, order: meOrdersList ? meOrdersList.order : 0}
                })
                .sort((a, b) => a.order - b.order),
        [studentProjectData, userId],
    )

    const [isThereProject, setIsThereProject] = useState(false);
    useEffect(() => {
        if (studentProjectData?.studentProjects && studentProjectData.studentProjects.length > 0) {
            setIsThereProject(true);
        } else {
            setIsThereProject(false);
        }
    }, [studentProjectData])

    const [manualToggle, setManualToggle] = useState(false);
    const {isOpen, toggle} = useDisclosure();
    const handleToggleClick = () => {
        setManualToggle(true);
        toggle();
    };
    const scrollRevealProfile = () => {
        if (manualToggle) return;

        let topProfile = document.getElementById('nameTop');
        let positionTopProfile = topProfile?.getBoundingClientRect?.();
        if(positionTopProfile) {
            if(positionTopProfile.bottom <= 0) {
                if(!isOpen) {
                    toggle();
                }
            } else if (positionTopProfile.top > 0) {
                if(isOpen) {
                    toggle();
                }
            }
        }
    }

    const {isOpen: isOpenMenuNavs, toggle: toggleMobileNavs} = useDisclosure()
    require("./profile.scss");
    return (
        <ColoredScrollbars2023 onScroll={scrollRevealProfile}>
        <>
            <Element name="myScrollToElement" id="scroll2023top"></Element>
            <Container id="top"
                       className="w-full h-full bg-grey flex relative"
                       style={{background: '#F0EEE9'}}>
                <MainGrid isThereProject={isThereProject}>
                    <div className="sidebar sticky top-0">
                        <div
                            className="w-96 z-50 fixed border-t border-b border-mad-blue border-dotted mb-8 mt-60 text-center text-mad-blue hover:bg-mad-blue hover:text-coton transition cursor-pointer"
                            onClick={handleToggleClick}
                        >
                            <VscMenu className="text-5xl text-center mx-auto my-4"/>
                        </div>
                        {isOpen ? (
                            <UserProfile withTop={true} user={user} className="w-96 fixed top-0 transition-opacity duration-700 ease-in-out hidden lg:block z-50"/>
                        ) : (  
                            <StickerNavsBlue withTop={true} className="w-96 fixed top-0 transition-opacity duration-700 ease-in-out hidden lg:block z-50" />
                        )}
                    </div>
                    <div className="w-full text-charcoal lg:flex">
                        <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs}/>
                        <WorksContainer className={cn('w-full mx-auto mt-8 transition', {
                            'flex visible opacity-100': !isOpenMenuNavs,
                            'hidden invisible opacity-0': isOpenMenuNavs,
                        })}>
                            {/* Avatar small screen */}
                            <img src={user?.image} className="w-64 h-64 rounded-full object-cover mx-auto pt-4"
                                 alt="user-avatar" style={{filter: 'grayscale(100%)'}}/>
                            <StudentName id="nameTop" className="text-6xl lg:text-7xl text-center my-8 lg:my-16">
                                {user?.firstName} {user?.lastName}
                            </StudentName>
                            {/* Profile small screen */}
                            <div className="px-8 py-4 lg:hidden">
                                <ProfileSmall user={user}/>
                            </div>
                            {projects.map((project) => (
                                <div key={project.id} className="px-8 md:px-24 lg:px-96 mt-8 flex flex-col">
                                    <Link
                                        to={{pathname: '/2023/project', search: `id=${project.id}`}}

                                    >
                                        <img src={project.coverImage} alt={project.title}/>
                                    </Link>

                                    <div className="flex flex-wrap py-4 lg:py-0">
                                        {['primaryProjectType', 'secondaryProjectType', 'secondaryProjectType_2'].map(
                                            (key) =>
                                                project[key] && (
                                                    <div key={key} className="flex items-center mr-12">
                                                        <Link
                                                            to={{
                                                                pathname: '/2023/work',
                                                                search: `?type=${project[key]}`,
                                                            }}
                                                        >
                                                        <span
                                                            className="w-3 h-3 bg-mad-blue rounded-full inline-block"></span>
                                                            <span
                                                                className="break-normal pt-2 inline-block ml-2 border-b border-mad-blue border-dotted leading-relaxed text-charcoal hover:text-mad-blue transition">
                              {projectType[project[key]] || upperFirst(project[key])}
                            </span>
                                                        </Link>
                                                    </div>
                                                ),
                                        )}
                                    </div>
                                    <Link
                                        to={{pathname: '/project', search: `id=${project.id}`}}
                                        className="text-5xl inline-block mt-6 mb-20 text-charcoal hover:text-mad-blue transition"
                                    >
                                        {project.title}
                                    </Link>
                                </div>
                            ))}
                        </WorksContainer>
                        <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs}/>
                    </div>
                </MainGrid>
                <WorkBGBottom>
                    <img
                        src={`/static/svg/2023/Work/WorkBGBottom.png`}
                        alt=""
                    />
                </WorkBGBottom>
                <FixedBottomNav className="hidden lg:block"/>
                <FooterContainer data-color="off_white">
                    <Footer/>
                </FooterContainer>
            </Container>
            <SearchIcon className="hidden lg:block"/>
        </>
        </ColoredScrollbars2023>
    )
}

const SINGLE_USER_QUERY = gql`
  query SINGLE_USER_QUERY($id: ID!) {
    student(where: { id: $id }) {
      id
      email
      firstName
      lastName
      tagline
      program
      contactEmail
      primaryExpertise
      secondaryExpertise
      about
      image
      portfolio
      behance
      linkedIn
      instagram
      twitter
      facebook
      dribble
      youtube
      vimeo
      medium
    }
  }
`

const STUDENT_PROJECTS_QUERY = gql`
  query STUDENT_PROJECTS_QUERY($id: ID!) {
    studentProjects(where: { id: $id }) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      orderList {
        userId
        order
      }
    }
  }
`
