import React from 'react'
import styled from 'styled-components'
import { VscSearch } from 'react-icons/vsc'

const Container = styled.div`
  background-color: white;
  position: absolute;
  width: calc(100% - 32px);
  right: 16px;
  @media (min-width: 1024px) {
    width: 50vw;
    right: 0;
  }
  @media (min-width: 1280px) {
    width: 40vw;
  }
  @media (min-width: 1600px) {
    width: 30vw;
  }
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  border-radius: 40px;

  input {
    border-radius: 40px;
    font-family: 'reckless_light';

    &::placeholder {
      color: #5933ff;
      font-family: 'reckless_light';
      font-size: 3rem;
    }
  }

  svg {
    top: 24px;
    color: #5933ff;
    right: 24px;
    transition: transform 330ms;
  }
`

export const InputSearch = ({ onChange, value }) => {
  if (window.innerWidth < 1200) {
    return (
      <Container className='relative z-10'>
        {/* <p className=''>{'Explore by program'}</p> */}
        <input
          placeholder='Search'
          className='w-full h-full px-12 text-blue text-4xl'
          onChange={onChange}
          value={value}
        />
        <VscSearch className='absolute text-5xl' />
      </Container>
    )
  } else {
    return (
      <Container className='relative z-10'>
        {/* <p className=''>{'Explore by program'}</p> */}
        <input
          placeholder='Search the website'
          className='w-full h-full px-12 text-blue text-4xl'
          onChange={onChange}
          value={value}
        />
        <VscSearch className='absolute text-5xl' />
      </Container>
    )
  }
}
