import React, { useState, useContext, useEffect } from 'react'
import { P, H2, H1M, H3, H4, A } from '../../../styles/2024/TextStyles'
import { SpringContext } from '../../../context/SpringContext'
import { useWindowSize } from '../../../hook/2024/hooks/useWindowSize'
import { TextCenterDiv } from '../../../styles/2024/GradsStyles'
import { events24, directions } from '../../../constant'
const Header = React.lazy(() => import('../../../components/2024/Header'))
const Countdown = React.lazy(() => import('../../../components/2024/Countdown'))
const Footer = React.lazy(() => import('../../../components/2024/Footer'))
const ThreeSlider = React.lazy(() =>
  import('../../../components/2024/SlickSlider').then((module) => ({
    default: module.ThreeSlider,
  })),
)

export default function Index() {
  const { screen } = useWindowSize()
  const spring = useContext(SpringContext)
  const [visibleEvent, setVisibleEvent] = useState(null)

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring])

  const toggleEventVisibility = (id) => {
    setVisibleEvent(visibleEvent === id ? null : id)
  }

  const isMobile = screen === 'sm'
  const handleDragStart = (e) => e.preventDefault()

  const showInMapClicked = () => {
    window.open('https://maps.google.com?q=43.6444842,-79.3688826')
  }

  require('./events.scss')
  return (
    <div className='absolute w-full min-h-screen bg-almost-black flex flex-col items-center justify-start pb-20 lg:pb-0'>
      {Countdown && <Countdown />}
      {Header && <Header />}

      <div className='relative px-[16px] lg:px-[48px] max-w-[1440px] pb-16'>
        <div className='w-full xl:w-full '>
          <TextCenterDiv>
            <H1M className='text-comfort-green lg:!text-[72px]' style={{ pointerEvents: 'none' }}>
              Events
            </H1M>
            {isMobile ? (
              <div className='text-center pt-[16px]'>
                <H3 className='text-beige-yellow normal-case m-0' style={{ pointerEvents: 'none' }}>
                  YES!
                </H3>
                <H3 className='text-beige-yellow normal-case m-0' style={{ pointerEvents: 'none' }}>
                  The party’s this way.
                </H3>
              </div>
            ) : (
              <H3 className='text-beige-yellow normal-case' style={{ pointerEvents: 'none' }}>
                YES! The party’s this way.
              </H3>
            )}
          </TextCenterDiv>
        </div>
      </div>
      <div className='relative w-full min-h-max bg-comfort-green flex flex-col items-center justify-center w-full'>
        <div className='relative w-full bg-comfort-green flex flex-col items-left justify-center px-[16px] lg:px-[48px] py-20 max-w-[1440px]'>
          <div className='relative grid grid-cols-1 lg:grid-cols-2 gap-14'>
            <div className='relative flex flex-col'>
              <div className='flex flex-col text-lg font-light text-almost-black '>
                <H2 className='uppercase'>Join us on May 1-2!</H2>
                <P className='mt-4 lg:pr-[20%]'>
                  YES! is an interactive showcase of creativity and talent nurtured across our
                  campus. Immerse yourself in inspiration, participate in engaging workshops, and
                  interact with our vibrant design community during this celebration of students’
                  achievement.
                </P>
              </div>
            </div>
            <div className='relative flex flex-col'>
              <img
                loading='lazy'
                src='/static/2024/img/gbc_sod_wf_3.png'
                alt=''
                className='grow w-full'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='relative w-full min-h-max bg-friendly-yellow flex flex-col items-center justify-center w-full'>
        <div className='relative w-full bg-friendly-yellow px-[16px] lg:px-[48px] py-20 pb-40 max-w-[1440px]'>
          <H2 className='uppercase text-almost-black'>AGENDA</H2>
          <div className='flex flex-col w-full'>
            <div className='hidden lg:grid grid-cols-1 lg:grid-cols-10 '>
              <div className='col-span-1 text-almost-black'></div>
              <div className='grid grid-cols-1 lg:grid-cols-3 lg:col-span-9 text-almost-black ml-[48px] border-b border-almost-black'>
                <H3 className='col-span-1'>Event</H3>
                <div></div>
                <H3 className='col-span-1'>Time</H3>
              </div>
            </div>
            {Object.entries(events24).map(([key, events]) => (
              <div key={key} className='grid grid-cols-1 lg:grid-cols-10 mb-[24px]'>
                <div className='col-span-1 text-almost-black'>
                  {key === 'dayOne' ? (
                    <img src='/static/2024/svg/may1.svg' alt='May 1 logo' />
                  ) : (
                    <img src='/static/2024/svg/may2.svg' alt='May 2 logo' />
                  )}
                </div>
                <div className='grid lg:col-span-9 text-almost-black lg:ml-[48px]'>
                  {events.map((event) => (
                    <React.Fragment key={event.id}>
                      <div
                        className='grid grid-cols-3 border-b border-almost-black border-dashed px-[32px] pt-[16px] pb-[24px] cursor-pointer'
                        onClick={() => toggleEventVisibility(event.id)}
                      >
                        <H4 className='col-span-1 m-0'>{event.name}</H4>
                        <div className='col-span-1'></div>
                        <div className='grid lg:grid-cols-2 col-span-1 w-full'>
                          <H4 className='hidden lg:block m-0'>{event.time} </H4>
                          <img
                            src='/static/2024/svg/chevron.svg'
                            alt='Chevron Icon'
                            className={`justify-self-end chevron ${
                              visibleEvent === event.id ? 'chevron-up' : ''
                            }`}
                          />
                        </div>
                        <div
                          className={`col-span-3 transition-max-height duration-500 ease-in-out overflow-hidden ${
                            visibleEvent === event.id ? 'max-h-[600px] lg:max-h-96' : 'max-h-0'
                          }`}
                        >
                          <P>{event.content}</P>
                          {event.link && event.link !== '' && (
                            <A className='border-b border-almost-black border-dashed'>
                              <a href={event.link} target='_blank' rel='noreferrer'>
                                Sign up for this event ↗
                              </a>
                            </A>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='relative w-full min-h-max bg-warm-orange flex flex-col items-center justify-center w-full'>
        <div className='relative w-full bg-warm-orange flex flex-col items-left justify-center pb-12 !max-w-[1440px] lg:px-[48px]'>
          <H2 className='uppercase text-almost-black pl-6 relative pt-20 lg:pt-20 w-2/3 lg:w-full '>
            What To Expect
          </H2>

          {isMobile ? (
            <div className='flex flex-col items-center relative pb-20 px-[16px] gap-6'>
              <img
                src='/static/2024/img/Frame62.jpeg'
                alt='Year End Show Activities'
                role='presentation'
                className='relative w-full pb-4'
              />

              <img
                src='/static/2024/img/Frame63.jpeg'
                alt='Year End Show Activities'
                onDragStart={handleDragStart}
                role='presentation'
              />

              <img
                src='/static/2024/img/Frame64.jpeg'
                alt='Year End Show Activities'
                onDragStart={handleDragStart}
                role='presentation'
              />

              <img
                src='/static/2024/img/Frame65.jpeg'
                alt='Year End Show Activities'
                onDragStart={handleDragStart}
                role='presentation'
              />
            </div>
          ) : (
            <ThreeSlider />
          )}
        </div>
      </div>
      <div className='relative w-full bg-almost-black flex flex-col items-center justify-center w-full'>
        <div className='relative w-full pt-16 px-[16px] lg:px-[48px] bg-almost-black max-w-[1440px] grid grid-cols-1 lg:grid-cols-2 gap-20'>
          <div>
            <H2 className='text-beige-yellow border-b border-beige-yellow border-dashed pb-[36px]'>
              DIRECTIONS
            </H2>
            {directions.map((direction) => (
              <React.Fragment key={direction.id}>
                <div
                  className='grid grid-cols-1 border-b border-beige-yellow border-dashed px-[32px] pt-[16px] pb-[24px] cursor-pointer '
                  onClick={() => toggleEventVisibility(direction.id)}
                >
                  <H4 className='m-0 text-beige-yellow '>{direction.name}</H4>
                  <div className='grid lg:grid-cols-2 col-span-1 w-full'>
                    <img
                      src='/static/2024/svg/chevronWhite.svg'
                      alt='Chevron Icon'
                      className={`justify-self-end self-center chevron ${
                        visibleEvent === direction.id ? 'chevron-up' : ''
                      }`}
                    />
                  </div>
                  <div
                    className={`col-span-3 transition-max-height duration-500 ease-in-out overflow-hidden ${
                      visibleEvent === direction.id ? 'max-h-[600px]' : 'max-h-0'
                    }`}
                  >
                    <P className='text-beige-yellow whitespace-pre-line'>{direction.content}</P>
                    {direction.link && direction.link !== '' && (
                      <A className='border-b border-beige-yellow border-dotted pb-[4px]'>
                        <a
                          href={direction.link}
                          target='_blank'
                          rel='noreferrer'
                          className='text-beige-yellow hover:text-comfort-green'
                        >
                          {direction.linkTitle} ↗
                        </a>
                      </A>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
          <div>
            <img
              src='/static/2024/img/lowerJmap.png'
              alt='Map'
              className='w-full pb-4 cursor-pointer'
              onClick={() => showInMapClicked()}
            />
            <img
              src='/static/2024/svg/pin.svg'
              className='inline cursor-pointer'
              alt='Pin Icon'
              onClick={() => showInMapClicked()}
            />
            <A
              className='border-b border-comfort-green border-dashed pb-[4px text-comfort-green hover:text-comfort-green cursor-pointer'
              onClick={() => showInMapClicked()}
            >
              3 Lower Jarvis St, Toronto, ON M5E 3Y5
            </A>
          </div>
        </div>
      </div>
      <div className='relative w-full bg-almost-black flex flex-col items-center justify-center w-full'>
        <div className='relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] bg-almost-black max-w-[1440px]'>
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}
