import React, { useMemo } from 'react'
import gql from 'graphql-tag'
// import { Query } from 'react-apollo'
import Error from './ErrorMessage'
import styled from 'styled-components'
import {Helmet} from 'react-helmet-async'
import ProfileProject from './ProfileProject'
import Loading from './Loading'
import { useQuery } from '@apollo/client'

const ProfileStyles = styled.div`
  max-width: 1200px;
  margin: 2rem 25px 2rem;
  min-height: 70vh;
  font-family: 'grotesque_pro_light';
  line-height: normal;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  p {
    line-height: normal !important;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const GridOne = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`
const GridTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

/* eslint-disable-next-line */
const UploadBlock = styled.div`
  background-color: #e3e3e3;
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .upload-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
  }

  .upload-placeholder {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.5;
    z-index: 2;
  }
`

/* eslint-disable-next-line */
const UploadIcon = styled.img`
  background: rgb(243, 215, 222);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  height: 50px;
  width: 50px;
  padding: 10px;

  &:hover,
  &:focus {
    outline: none;
  }
`

/* eslint-disable-next-line */
const CoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`

const ProfileTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 25px;
  p {
    font-size: 30px;
  }
  @media (max-width: 768px) {
    padding-bottom: 0;
  }
`
const Tagline = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 25px;
  p {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    padding-bottom: 0;
  }
`
const ProfileInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 0;
  .details h2 {
    font-size: 20px;
    font-weight: normal;
    padding: 10px 0;
  }
  .details img {
    width: 100%;
    object-fit: cover;
  }

  .details div {
    width: 20vw;
    height: 20vw;
  }
  @media (max-width: 768px) {
    .details {
      display: flex;
      justify-content: center;
      height: 200px;
      margin: 1em 0;
    }

    .details div {
      width: auto;
      height: auto;
    }

    .details h2 {
      padding: 0;
    }
  }
`
const ProjectsContainer = styled.div`
  margin: 0 25px;
  height: 72vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    /* width: 15px;
    height: 15px;
    border: 1px solid black; */
    display: none;
  }
  @media (max-width: 768px) {
    height: auto;
  }
`

const Expertise = styled.p`
  font-size: 20px;
  text-align: center;
  margin: 0;
  margin-top: 10px;
  @media (max-width: 768px) {
    font-size: normal;
    margin-top: 0;
  }
`
const About = styled.p`
  font-size: 20px;
  margin: 25px 0;
`

const SINGLE_USER_QUERY = gql`
  query SINGLE_USER_QUERY($id: ID!) {
    student(where: { id: $id }) {
      id
      email
      firstName
      lastName
      tagline
      program
      contactEmail
      primaryExpertise
      secondaryExpertise
      about
      image
      portfolio
      behance
      linkedIn
      instagram
      twitter
      facebook
      dribble
      youtube
      vimeo
      medium
    }
  }
`

const STUDENT_PROJECTS_QUERY = gql`
  query STUDENT_PROJECTS_QUERY($id: ID!) {
    studentProjects(where: { id: $id }) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      orderList {
        userId
        order
      }
    }
  }
`

const Profile = (props) => {
  const { error, loading, data: studentData } = useQuery(SINGLE_USER_QUERY, {
    variables: {
      id: props.id,
    },
    fetchPolicy: 'no-cache',
  })

  const { data: studentProjectData } = useQuery(STUDENT_PROJECTS_QUERY, {
    variables: {
      id: props.id,
    },
  })

  const userId = props.id
  const user = studentData?.student
  const projects = useMemo(
    () =>
      (studentProjectData?.studentProjects || [])
        .map((prj) => {
          const { orderList } = prj
          const meOrdersList = orderList.find((ord) => ord.userId === userId)
          return { ...prj, order: meOrdersList ? meOrdersList.order : 0 }
        })
        .sort((a, b) => a.order - b.order),
    [studentProjectData, userId],
  )

  if (error) return <Error error={error} />
  if (loading) return <Loading />
  if (!user) return <p> No User found for {props.id}</p>

  return (
    <Grid>
      <ProfileStyles>
        <Helmet>
          <title>
            YES! | {user.firstName} {user.lastName}
          </title>
        </Helmet>
        <GridOne>
          <ProfileTitle>
            <p>
              {user.firstName} {user.lastName}
            </p>
          </ProfileTitle>
          <Tagline>
            <p>YES! {user.tagline}</p>
          </Tagline>
          <ProfileInfo>
            <div></div>
            <div className='details'>
              <div>
                <img src={user.image} alt={user.about} />
              </div>
            </div>
            <div></div>
          </ProfileInfo>
          <Expertise>{user.primaryExpertise}</Expertise>
          <Expertise>{user.secondaryExpertise}</Expertise>
          <About>{user.about}</About>
          <GridTwo>
            {user.portfolio != null && (
              <a href={user.portfolio} target='_blank' rel='noopener noreferrer'>
                Portfolio
              </a>
            )}
            <a href={`mailto:${user.contactEmail}`}>Email</a>
            {user.linkedIn != null && (
              <a href={user.linkedIn} target='_blank' rel='noopener noreferrer'>
                LinkedIn
              </a>
            )}
            {user.behance != null && (
              <a href={user.behance} target='_blank' rel='noopener noreferrer'>
                Behance
              </a>
            )}
            {user.instagram != null && (
              <a href={user.instagram} target='_blank' rel='noopener noreferrer'>
                Instagram
              </a>
            )}
            {user.twitter != null && (
              <a href={user.twitter} target='_blank' rel='noopener noreferrer'>
                Twitter
              </a>
            )}
            {user.facebook != null && (
              <a href={user.facebook} target='_blank' rel='noopener noreferrer'>
                Facebook
              </a>
            )}
            {user.dribble != null && (
              <a href={user.dribble} target='_blank' rel='noopener noreferrer'>
                Dribbble
              </a>
            )}
            {user.youtube != null && (
              <a href={user.youtube} target='_blank' rel='noopener noreferrer'>
                YouTube
              </a>
            )}
            {user.vimeo != null && (
              <a href={user.vimeo} target='_blank' rel='noopener noreferrer'>
                Vimeo
              </a>
            )}
            {user.medium != null && (
              <a href={user.medium} target='_blank' rel='noopener noreferrer'>
                Medium
              </a>
            )}
          </GridTwo>
        </GridOne>
      </ProfileStyles>
      <ProjectsContainer>
        {projects.length === 0 ? (
          <p>No projects created yet.</p>
        ) : (
          projects.map((project) => <ProfileProject project={project} key={project.id} />)
        )}
      </ProjectsContainer>
    </Grid>
  )
}

export default Profile
