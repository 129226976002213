import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Nav from './Nav'
import Marquee from "react-fast-marquee";

const Logo = styled.h1`
  flex-grow: 1;
  font-size: 2rem;
  margin-bottom: 0;
  margin-left: 10px;
  position: relative;
  z-index: 2;

  @media (min-width: 768px) {
    margin-left: 20px;
  }

  a {
    // padding: 0.5rem 1rem;
    color: ${(props) => props.theme.black};
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      color: ${(props) => props.theme.black} !important;
    }
  }

  img {
    margin-top: 10px;
    width: 50px;
  }


`


const StyledHeader = styled.div`
  position: relative;

  a {
    color: ${(props) => props.theme.black2022};
    transition: all 0.4s ease-out;
    font-family: 'reckless_light';
  }

  .marquee {
    height: 50px;
    background-color: ${(props) => props.theme.grey2022};
    color: ${(props) => props.theme.black2022};
    overflow: hidden;
    font-size: 28px;
    font-family: 'grotesque_pro_light';
    line-height: 2;
    @media (min-width: 768px) {
      background-color: ${(props) => props.theme.grey2022};
    }
  }

  .marqueeItem{
      display: flex;
  }

  .marqueeItem:hover{
    background-color: ${(props) => props.theme.grey2022};
  }

  .marqueeItem a{
    font-size: 26px;
    height: 60px;
    position: relative;
    top: 5px;
  }

  .marqueeContainer {
    height: 50px;
    display: inline-block;
    padding: 0 4px;
    float: left;
    transition: all 0.2s ease-out;
    text-decoration-style: dotted!important;
    text-decoration: underline;
    text-decoration-color: #ff6044
  }

  .marqueeEx {
    height: 50px;
    line-height: 64px;
    display: inline-block;
    margin: 0 2px;
    float: left;
    transition: all 0.4s ease-out;
    color: ${(props) => props.theme.orange2022};
  }

  .marqueeContainer:hover {
    background-color: ${(props) => props.theme.orange2022};
    @media (min-width: 768px) {
      background-color: ${(props) => props.theme.orange2022};
    }
  }

`


const FastMarquee = ({ marquee, shouldRenderNav }) => {

  return (
    <StyledHeader className=' bg-red-coral'>
      {marquee && (
        <Marquee speed={130} pauseOnHover={true} gradientColor="#ff6044">
            <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                    to={{
                    pathname: '/work',
                    search: '?type=3d_design',
                    }}
                >
                    <div className='marqueeContainer'>3D Design</div>
              </Link>
            </div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=3d_sculpting',
                }}
              >
                <div className='marqueeContainer'>3D Sculpting</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=3d_visualizations',
                }}
              >
                <div className='marqueeContainer'>3D Visualizations</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=advertising',
                }}
              >
                <div className='marqueeContainer'>Advertising</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=animation',
                }}
              >
                <div className='marqueeContainer'>Animation</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=augmented_virtual_reality',
                }}
              >
                <div className='marqueeContainer'>Augmented/Virtual Reality</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=branding',
                }}
              >
                <div className='marqueeContainer'>Branding</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=brand_strategy',
                }}
              >
                <div className='marqueeContainer'>Brand Strategy</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=digital_applications',
                }}
              >
                <div className='marqueeContainer'>Digital Applications</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=drawing',
                }}
              >
                <div className='marqueeContainer'>Drawing</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=editorial',
                }}
              >
                <div className='marqueeContainer'>Editorial</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=exhibition',
                }}
              >
                <div className='marqueeContainer'>Exhibition</div>
              </Link>
</div>
<div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=extended_reality',
                }}
              >
                <div className='marqueeContainer'>Extended Reality</div>
              </Link>
</div>
<div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=front-end_design',
                }}
              >
                <div className='marqueeContainer'>Front-End Design</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=illustration',
                }}
              >
                <div className='marqueeContainer'>Illustration</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=immersive_environments',
                }}
              >
                <div className='marqueeContainer'>Immersive Environments</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=interactive_design',
                }}
              >
                <div className='marqueeContainer'>Interactive Design</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=interface_development',
                }}
              >
                <div className='marqueeContainer'>Interface Development</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=media_production',
                }}
              >
                <div className='marqueeContainer'>Media Production</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=modeling',
                }}
              >
                <div className='marqueeContainer'>Modeling</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=motion_graphics',
                }}
              >
                <div className='marqueeContainer'>Motion Graphics</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=packaging',
                }}
              >
                <div className='marqueeContainer'>Packaging</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=photography',
                }}
              >
                <div className='marqueeContainer'>Photography</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=physical_computing',
                }}
              >
                <div className='marqueeContainer'>Physical Computing</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=strategy',
                }}
              >
                <div className='marqueeContainer'>Strategy</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=systems_and_services',
                }}
              >
                <div className='marqueeContainer'>Systems and Services</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=technological_systems',
                }}
              >
                <div className='marqueeContainer'>Technological Systems</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=user_experience',
                }}
              >
                <div className='marqueeContainer'>User Experience</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=video',
                }}
              >
                <div className='marqueeContainer'>Video</div>
              </Link>
</div>
              <div className="marqueeItem">
<p className='marqueeEx'>&#9679;</p>
<Link
                to={{
                  pathname: '/work',
                  search: '?type=web_design',
                }}
              >
                <div className='marqueeContainer'>Web Design</div>
              </Link>
</div>
            </Marquee>
      )}
      <div className='bar'>
        {shouldRenderNav && (
          <div className="marqueeItem">
            <Logo>
              <Link to='/'>
                <img src={'/static/logo.svg'} alt='logo' />
              </Link>
            </Logo>
            <Nav />
          </div>
        )}
      </div>
    </StyledHeader>
  )
}

export default withRouter(FastMarquee)
