import { Query } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
// import { useQuery } from "@apollo/react-hooks";

const CURRENT_USER_QUERY = gql`
  query {
    me {
      id
      email
      firstName
      lastName
      tagline
      program
      primaryExpertise
      secondaryExpertise
      about
      image
      portfolio
      contactEmail
      behance
      linkedIn
      instagram
      twitter
      facebook
      dribble
      youtube
      vimeo
      medium
      permissions
    }
  }
`;

const User = props => {
  return  <Query {...props} query={CURRENT_USER_QUERY}>
    {payload => props.children(payload)}
  </Query>
}


User.propTypes = {
  children: PropTypes.func.isRequired
};

export default User;
export { CURRENT_USER_QUERY };
