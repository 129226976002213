import PleaseSignIn from '../../components/CMS/PleaseSignin'
import EditProfile from '../../components/CMS/EditProfile'
import React from 'react'

const Edit = () => {
  return (
      <PleaseSignIn>
        <EditProfile />
      </PleaseSignIn>
  )
}

export default Edit
