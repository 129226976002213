import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import StickerNavs from '../StickerNavs'
import { DropdownFilter } from '../DropdownFilter'
import qs from 'query-string'
import groupBy from 'lodash.groupby'
import { QUERY } from '../../../graphql'
import shuffle from 'lodash.shuffle'
import SearchCol from '../../../components/2021/SearchCol'
import Footer from '../../../components/2021/Footer'
import { MobileNavsBottom, MobileRightNavs } from '../MobileRightNavs'
import { useDisclosure } from 'react-use-disclosure'
import cn from 'classnames'

const Container = styled.div`
  min-height: calc(100vh);
  cursor: url(${(props) => props.cursorUrl}), auto;
  flex-direction: column;
`

const InterActiveMedia = styled.div`
  .classOf2021 {
    color: white;
    display: inline-block;
    font-size: 40px;
  }
  .interactive {
    display: inline-block;
    font-size: 40px;
  }
  .dot {
    height: 12px;
    width: 12px;
    background-color: #d5fd00;
    border-radius: 50%;
    margin: 8px 10px;
    // margin-bottom : 7px;
    display: inline-block;
  }
`

const CenterContainer = styled.div`
  @media (min-width: 1280px) {
    // top: 10%;
    // max-height: 40vh;
  }
  @media (min-width: 1920px) {
    // top: 20%;
  }
  width:-webkit-calc(100%- 24rem);
  width:-moz-calc(100% - 24rem);
  width:calc(100% - 24rem);

  @media (max-width: 1280px) {
    width:100%;
  }
`
const TextCenter = styled.h4`
  font-family: 'sharp_grotesque_05';
  font-size: 420px;
  line-height: 360px;
  letter-spacing: 0.01em;
  font-weight: 400;
  @media (min-width: 360px) {
    font-size: 460px;
    line-height: 400px;
  }
  @media (min-width: 768px) {
    font-size: 550px;
    line-height: 550px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 600px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 700px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 1000px;
    line-height: 1200px;
  }
  @media (min-width: 2880px) {
    font-size: 1400px;
    line-height: 1400px;
  }
`

const GradList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  font-family: 'reckless_light';

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`

export default function Grads(props) {
  const location = useLocation()
  const { search: searchParams } = location
  const { program, type } = qs.parse(searchParams)

  const { data } = useQuery(QUERY.USERS_QUERY, {
    variables: {
      where: {
        studentYear: 2021,
        activeAt_gt: '2020-12-31',
      },
    },
  })
  const grads = useMemo(() => {
    if (!data?.users) {
      return {}
    }
    let users = data?.users || []

    if (program) {
      users = users.filter((user) => user.program === program)
    }
    const grouped = groupBy(shuffle(users), 'program')
    const programGroups = [
      'Graphic Design',
      'Interaction Design',
      'Interactive Media Management',
      'Game - Art',
      'Concept Art for Entertainment',
      'Digital Design - Game Design',
      'Design Management',
      'Interdisciplinary Design Strategy',
      'Art & Design Foundation',
    ]
    const obj = {}
    programGroups.forEach((key) => {
      if (grouped[key]?.length > 0) {
        obj[key] = grouped[key]
      }
    })

    return obj
  }, [data, program])
  const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()

  return (

    <>
      <Container
        className='w-full h-full bg-blue flex mt-28'
        cursorUrl='/static/svg/cursor-with-shadow.svg'
      >
        <StickerNavs className='w-96 fixed  top-0 h-screen transition-opacity duration-700 ease-in-out hidden lg:block' />
        <CenterContainer className='w-full mx-auto lg:ml-96 pb-12'>
          <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs} />
          <div
            className={cn('transition', {
              'flex visible opacity-100': !isOpenMenuNavs,
              'hidden invisible opacity-0': isOpenMenuNavs,
            })}
          >
            <div className='text-center relative'>
              <TextCenter className='text-secondary' style={{ pointerEvents: "none"}}>GRADS</TextCenter>
              <DropdownFilter program={program} type={type} />
            </div>
            {Object.keys(grads).map((key) => {
              return (
                <div key={key}>
                  <InterActiveMedia>
                    <div className='text-center lg: my-4'>
                      <p className='text-5xl text-white lg:text-6xl my-4 lg:my-8'>Class of 2021
                      <span className='text-secondary text-6xl block mb-4 md:mb-6 lg:mb-8  lg: inline'>
                        <span className='dot'></span>
                        {key}
                      </span>
                      </p>
                    </div>
                  </InterActiveMedia>
                  <div className='flex'>
                    <GradList className='bg-dark w-full lg:ml-24'>
                      {grads[key]
                        .sort(function (a, b) {
                          var textA = a.firstName.toUpperCase()
                          var textB = b.firstName.toUpperCase()
                          return textA < textB ? -1 : textA > textB ? 1 : 0
                        })
                        .map((grad) => (
                          <Link
                            key={grad.id}
                            className='inline-block p-10 hover:bg-blue transition'
                            to={{
                              pathname: '/2021/student',
                              search: `id=${grad.id}`,
                            }}
                          >
                            <p
                              className='text-white text-4xl inline'
                              style={{ borderBottom: '1px #DEFC1C dashed' }}
                            >
                              {grad?.firstName} {grad?.lastName}
                            </p>
                          </Link>
                        ))}
                    </GradList>
                  </div>
                </div>
              )
            })}
            <div className='w-12 bg-blue hidden lg:block'>
              {/* <img src='/static/svg/glass.svg' alt='glass' className={cn('cursor-pointer')} /> */}
            </div>
          </div>
          <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
        </CenterContainer>
        <SearchCol className='hidden lg:block' />
        <Footer />
        </Container>
      </>
  )
}
