import styled from "styled-components";
import cursorSVG from '../../pages/2023/cursor-with-shadow-2023.svg';

export const Container = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&display=swap');
  @import url('https://fonts.cdnfonts.com/css/basis-grotesque-pro');
  position: relative;
  height: 9418px;
  background-color: #26439B;
  display: flex;
  flex: 1;
  width: 100%;
  cursor: url(${cursorSVG}), auto;

  @media (max-width: 1024px) {

  }
  @media (max-width: 720px) {
    overflow: hidden;
    height: 6980px;
  }
  @media (max-width: 480px) {

  }
`

export const SwirlBG = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 3;

  .bgDesktop {
    min-width: 1920px;
    width: 100vw;
    height: 9418px;
    overflow: hidden;
  }
  
  .bgMobile {
    display: none;
  }

  @media (max-width: 1024px) {

  }
  @media (max-width: 720px) {
    .bgMobile {
      min-width: 600px;
      width: 100vw;
      height: 6980px;
      overflow: hidden;
    }

    .bgMobile {
      display: block;
    }

    .bgDesktop {
      display: none;
    }
  }
`

export const BGImages = styled.div`
  min-width: 1920px;
  width: 100vw;
  overflow: hidden;
  
  .bgImageAll {
    min-width: 1920px;
    position: absolute;
    z-index: 2;
  }
  
  .bgImage1 {
    top: 1900px;
  }
  
  .bgImage2 {
    top: 5500px;
  }
  
  .bgImage3 {
    bottom: 400px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`
export const ContainerSectionOne = styled.section`
  position: absolute;
  width: 100%;
  height: 1200px;
  z-index: 10;
  @media (max-width: 720px) {
    height: 950px;
  }

`

export const TextLogo = styled.h2`
  position: absolute;
  left: 2%;
  font-family: 'sharp_grotesque', serif;
  font-size: 100px;
  line-height: 110px;
  letter-spacing: 1px;
  font-weight: 400;
  display: block;
  @media (max-width: 1023px) {
    display: none;
  }
`

export const TextCenterYes = styled.h4`
  //color: #ff6044;
  position: absolute;
  top: 32%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Reckless_light, serif;
  font-size: calc(400px + 7.8125vw);
  line-height: 360px;
  letter-spacing: 0.01em;
  font-weight: 200;
  @media (max-width: 1024px) {
    font-size: calc(300px + 7.8125vw);
    line-height: 310px;
  }
  @media (max-width: 720px) {
    top: 40%;
    font-size: calc(260px + 7.8125vw);
    line-height: 270px;
  }
  @media (max-width: 480px) {
    top: 34%;
    font-size: calc(200px + 7.8125vw);
    line-height: 210px;
  }
`

export const StickersWrapper = styled.div`
  top: ${(props) => props.top};
  left: ${(props) => props.imgLeft || 28}px;
  min-width: 160px;
  min-height: 480px;
`
export const StickerContainer = styled.div`
  top: ${(props) => props.top};
  left: ${(props) => props.imgLeft || 28}px;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'inherit')};
  display: table;
  
  .no-hover {
    display: block;
  }
  .yes-hover {
    display: none;
  }
  &:hover {
    .no-hover {
      display: none;
    }
    .yes-hover {
      display: block;
    }
  }

  img {
    width: ${(props) => props.imgWidth || 84}px;
    filter: drop-shadow(1px 1px 1px #26439B)
  }

  svg {
    filter: drop-shadow(1px 1px 1px #26439B)
  }

  p {
    position: absolute;
    top: calc(${(props) => props.top} + 16px);
    font-family: Reckless_light, serif;
    transform: rotate(${(props) => props.textRotate}deg);
  }
`

export const Sticker3Days = styled.img`
  position: absolute;
  top: 20%;
  left: 2%;
  cursor: inherit;
  -webkit-filter: drop-shadow(3px 3px 2px #26439B);
  filter: drop-shadow(3px 3px 2px #26439B);
  @media (min-width: 1024px) {
    top: 13%;
    left: 14%;
  }
  @media (min-width: 1440px) {
    left: 13%;
  }
  @media (min-width: 1920px) {
    top: 15%;
    left: 22%;
  }
  @media (min-width: 2400px) {

  }
`

export const Sticker1Container = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  cursor: ${(props) => (props.pointer ? 'pointer' : 'inherit')};

  img {
    width: ${(props) => props.imgWidth};
    height: ${(props) => props.imgHeight};
  }

  p {
    position: absolute;
    top: 70px;
    left: 25px;
    font-family: Reckless_light, serif;
    transform: rotate(${(props) => props.textRotate}deg);
    color: #F0EEE9;
  }
  @media (max-width: 1030px) {
    p {
      top: 80px;
      left: 25px;
      font-size: 20px;
    }
  }
  @media (max-width: 780px) {
    display: none;
  }
`

export const Sticker2 = styled.img`
  position: relative;
  top: 50%;
  left: 70%;
  width: 387px;
  height: 309px;
  @media (max-width: 720px) {
    top: 45%;
    width: 220px;
    height: auto;
  }
  @media (max-width: 480px) {
    top: 40%;
    left: 65%;
    width: 220px;
    height: auto;
  }
`
export const TextCenterTop = styled.div`
  position: absolute;
  top: 60%;

  h2,
  h3 {
    margin: 0;
    padding: 2px;
    font-family: 'grotesque_pro_light', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }

  h3 {
    font-size: 22px;
  }

  @media (max-width: 1024px) {

  }
  @media (max-width: 720px) {
    top: 60%;
  }
  @media (max-width: 480px) {
    top: 55%;
  }


`
export const EmailSignUpCenter = styled.div`
  position: absolute;
  top: 70%;

  form {
    //height: 14vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    font-family: Reckless_light, serif;
    font-size: 1.8rem;
    min-height: 200px;
    margin-bottom: 7%;
  }

  label {
    font-size: 22px;
    color: #000000;
  }

  #email {
    color: #26439B;
    width: 401px;
    height: 60px;
    font-family: 'grotesque_pro_light', serif;
    font-size: 24px;
    border-radius: 30px;
    padding: 5px 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 3px solid ${(props) => (props.hasError ? 'red' : '#26439B')};
    display: ${(props) => (props.submitted ? 'none' : 'block')};
  }

  #email:hover {
    border: 3px solid white;
  }

  #email:focus {
    outline: none;
    border: 3px solid white;
  }

  #submit {
    width: 401px;
    height: 60px;
    background-color: #26439B;
    color: #ffffff;
    border-radius: 30px;
    display: ${(props) => (props.submitted ? 'none' : 'block')};
    cursor: pointer;
  }

  #submit:hover {
    border: 3px solid #ffffff;
  }

  #inline-error {
    color: white;
    margin-top: 10px;
    display: ${(props) => (props.hasError ? 'block' : 'none')};
  }

  #submitted {
    color: white;
    margin-top: 10px;
    display: ${(props) => (props.submitted ? 'block' : 'none')};
  }

  @media (max-width: 1024px) {

  }
  @media (max-width: 720px) {
    top: 75%;
  }
  @media (max-width: 480px) {
    top: 70%;
    label {
      text-align: center;
      font-size: 18px;
    }

    #email {
      width: 350px;
      height: 50px;
    }

    #submit {
      width: 350px;
      height: 50px;
    }
  }

`
export const ContainerSectionTwo = styled.section`
  position: absolute;
  top: 1200px;
  width: 100%;
  height: 1000px;
  z-index: 10;
  @media (max-width: 720px) {
    top: 1000px;
    height: 900px;
  }
  @media (max-width: 480px) {

  }

`
export const MarqueeSubjectBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  .mSquare {
    width: 46px;
    height: 46px;
    margin-left: 20px;
    background-color: ${(props) => props.boxColor};
  }

  .mSubject {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 46px;
    background-color: ${(props) => props.boxColor};
  }

  a {
    width: 100%;
    text-align: center;
    font-family: Reckless_light, serif;
    font-size: 30px;
    color: ${(props) => props.fontColor};
  }

  @media (max-width: 1024px) {
    .mSubject {
      width: 75vw;
    }
  }
  @media (max-width: 720px) {
    .mSubject {
      width: 90vw;
    }

    a {
      font-size: 24px;
    }
  }

`

export const SectionTwoText1 = styled.div`
  position: relative;
  top: 5%;
  left: 0;
  width: 100vw;
  height: auto;
  padding: 0 20px;

  p {
    text-align: center;
    font-family: Reckless_light, serif;
    font-size: 26px;
    line-height: 40px;
    color: #26439B;
  }

  @media (min-width: 721px) {
    width: 554px;
    top: 20%;
    left: 8%;
    p {
      text-align: left;
      font-size: 35px;
      line-height: 45px;
    }
  }
  @media (min-width: 1024px) {
    top: 20%;
    left: 4%;
    padding: 0;
    p {
      font-size: 38px;
      line-height: 50px;
    }
  }
  @media (min-width: 1440px) {
    top: 38%;
    left: 10%;
  }
  @media (min-width: 1920px) {
    top: 33%;
    left: 12%;
  }
  @media (min-width: 2400px) {

  }
`
export const SectionTwoText2 = styled.div`
  position: relative;
  top: 8%;
  left: 3%;
  width: 95vw;
  height: auto;

  p {
    font-family: 'grotesque_pro_light', sans-serif;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: 22px;
    color: #26439B;
  }

  @media (min-width: 721px) {
    top: 24%;
    left: 8%;
    width: 80vw;
    p {
      font-size: 18px;
      line-height: 33px;
    }
  }

  @media (min-width: 1024px) {
    top: 5%;
    left: 55%;
    width: 554px;
    p {
      font-size: 20px;
      line-height: 33px;
    }
  }
  @media (min-width: 1440px) {
    top: 1%;
    left: 60%;
  }
  @media (min-width: 1920px) {
    top: 0%;
    left: 60%;
  }
  @media (min-width: 2400px) {

  }
`

export const ContainerSectionThree = styled.section`
  position: absolute;
  top: 2800px;
  width: 100%;
  height: 3100px;
  z-index: 10;
  @media (max-width: 720px) {
    top: 2000px;
    height: 2400px;
  }
`

export const Sticker3 = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  @media (min-width: 720px) {
    
  }
  @media (min-width: 1024px) {
    top: 10%;
    left: 20%;
  }
  @media (min-width: 1440px) {
    top: 10%;
    left: 20%;
  }
  @media (min-width: 1920px) {
    top: 10%;
    left: 20%;
  }
  @media (min-width: 2400px) {

  }
`

export const Sticker4 = styled.img`
  position: absolute;
  top: 15%;
  left: 70%;
  display: none;
  @media (min-width: 1024px) {
    display: block;
    top: 15%;
    left: 70%;
  }
  @media (min-width: 1440px) {
    top: 15%;
    left: 70%;
  }
  @media (min-width: 1920px) {
    top: 15%;
    left: 70%;
  }
  @media (min-width: 2400px) {

  }
`
export const SectionThreeHeader1 = styled.h2`
  position: absolute;
  top: 22%;
  left: 8%;
  font-family: Reckless_light, sans-serif;
  font-size: 100px;
  font-weight: 400;
  line-height: 120px;
  letter-spacing: -5px;
  color: #26439B;
  @media (max-width: 1440px) {

  }
  @media (max-width: 1024px) {
    top: 20%;
  }
  @media (max-width: 720px) {
    top: 8%;
    font-size: 80px;
  }
  @media (max-width: 480px) {
  }
`

export const SubjectList = styled.div`
  position: absolute;
  top: 30%;
  left: 21%;
  display: flex;
  flex-direction: row;
  gap: 50px;

  ul {
    list-style: none;
  }

  a {
    display: inline-block;
    font-family: Reckless_light, sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 62px;
    text-decoration: underline #C7C2E1 dotted 2px;
    text-underline-offset: 5px;
    color: #26439B;
  }

  li {
    display: flex;
    align-items: center;
  }

  li::before {
    content: "•";
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    color: #C7C2E1
  }

  li:hover {
    background-color: #C7C2E1;
  }

  @media (max-width: 1440px) {

  }
  @media (max-width: 1024px) {
    top: 25%;
    left: 10%;
    a {
      font-size: 24px;
      line-height: 50px;
    }
  }
  @media (max-width: 720px) {
    top: 14%;
    left: 10%;
    flex-direction: column;
    gap: 0;
    a {
      font-size: 24px;
      line-height: 50px;
    }
  }
  @media (max-width: 480px) {
    a {
      font-size: 20px;
      line-height: 50px;
    }
  }
`

export const SectionThreeHeader2 = styled.h2`
  position: absolute;
  top: 47%;
  left: 8%;
  font-family: Reckless_light, sans-serif;
  font-size: 100px;
  font-weight: 400;
  line-height: 120px;
  letter-spacing: -5px;
  color: #26439B;
  @media (max-width: 1440px) {

  }
  @media (max-width: 1024px) {
    top: 42%;
  }
  @media (max-width: 720px) {
    top: 40%;
    font-size: 80px;
  }
  @media (max-width: 480px) {
  }
`

export const EventsTimeline = styled.div`
  position: absolute;
  top: 52%;
  left: 0;
  display: grid;
  grid-template-columns: 1.4fr 3fr;
  width: 90%;
  height: 1200px;

  .timelineGraphic {
    width: 100%;
    direction: rtl;
    overflow: hidden;
  }

  .timelineGraphic > img {
    min-width: 547px;
    height: 836px;

  }

  .timelineEventDays {
    padding-left: 40px;
    width: 100%;
  }

  .timelineEventDaySingle {
    display: grid;
    grid-template-rows: 0.7fr 1fr;
    padding-bottom: 12px;
  }

  .timelineInfoTop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 2px solid #26439B;
  }

  .timelineDayHeading > h3 {
    font-family: Reckless_light, sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 125px;
    color: #26439B;
  }

  .timelineDateTime {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 10px;
    font-family: 'grotesque_pro_light', sans-serif;
    font-weight: 300;
    line-height: 22px;
    color: #26439B;
  }

  .timelineDateTime > p {
    margin-bottom: 12px;
  }

  .timelineDate {
    font-size: 24px;
    color: #191919;
  }

  .timelineTime {
    font-size: 20px;
    color: #26439B;
  }

  .timelineTime > a {
    text-decoration: underline #C7C2E1 dotted 2px;
    text-underline-offset: 5px;
    color: #26439B;
  }

  .timelineTime > a:hover {
    background-color: #C7C2E1;
  }

  .timelineEventNames {
    padding-top: 14px;
  }

  .timelineEventNames > ul > li {
    display: flex;
    justify-content: space-between;
    font-family: 'grotesque_pro_light', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 24px;
    color: #191919;
    a {
      margin-bottom: 8px;
      color: #26439B;
    }
    a:hover {
      background-color: #C7C2E1;
    }
    p {
      margin-bottom: 8px;
    }
  }

  .timelineDateSmall {
    display: none;
  }
  .padding1 {
    padding-top: 80px;
  }

  .padding2 {
    padding-top: 62px;
  }

  .padding3 {
    padding-top: 50px;
  }
  
  @media (max-width: 1381px) {
    .padding1 {
      padding-top: 80px;
    }

    .padding2 {
      padding-top: 62px;
    }

    .padding3 {
      padding-top: 50px;
    }
  }
  @media (max-width: 1024px) {
    position: absolute;
    top: 46%;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 3fr;
    width: 98%;
    height: 850px;

    .timelineGraphic {
      width: 100%;
      direction: rtl;
      overflow: hidden;
    }

    .timelineGraphic > img {
      min-width: 547px;
      height: 836px;

    }

    .timelineEventDays {
      padding-left: 10px;
      width: 100%;
    }

    .timelineEventDaySingle {
      display: grid;
      grid-template-rows: 0.2fr 1fr;
      padding-bottom: 12px;
    }

    .timelineDayHeading > h3 {
      font-size: 30px;
      line-height: 50px;
    }

    .timelineInfoTop {
      grid-template-columns: 1fr;
      grid-template-rows: 50px 60px;
    }

    .timelineDateTime {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 10px;
    }

    .timelineDateTime > p {
      margin-bottom: 0;
    }

    .timelineDate {
      display: none;
    }

    .timelineDateSmall {
      display: block;
      font-size: 16px;
      padding-left: 0px;
    }

    .timelineTime {
      font-size: 16px;
    }

    .timelineEventNames > ul > li {
      font-size: 18px;
      line-height: 22px;
    }
    .padding1 {
      padding-top: 120px;
    }

    .padding2 {
      padding-top: 90px;
    }

    .padding3 {
      padding-top: 80px;
    }
  }
  @media (max-width: 720px) {
    top: 44%;
    .padding1 {
      padding-top: 120px;
    }

    .padding2 {
      padding-top: 50px;
    }

    .padding3 {
      padding-top: 55px;
    }
  }
  @media (max-width: 618px) {
    .padding1 {
      padding-top: calc(120px);
    }

    .padding2 {
      padding-top: calc(80px - 5vw);
    }

    .padding3 {
      padding-top: calc(80px - 5vw);
    }
  }
  @media (max-width: 440px) {
    .timelineDateSmall {
      padding-left: 5px;
      color: #191919;
    }

    .timelineEventNames > ul > li {
      font-size: 16px;
      line-height: 18px;
    }
  }
`

export const ViewEventDetails = styled.div`
  position: absolute;
  top: 90%;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100px;
  &:hover {
    cursor: pointer;
  }
  a {
    padding: 12px;
    font-family: Reckless_light, sans-serif;
    font-size: 60px;
    font-weight: 400;
    line-height: 35px;
    color: #26439B;
    text-decoration: underline #26439B dotted 6px;
    text-underline-offset: 20px;
  }
  
  a:hover {
    background-color: #C7C2E1;
  }

  @media (max-width: 1440px) {

  }
  @media (max-width: 1024px) {

  }
  @media (max-width: 720px) {
    top: 88%;
    a {
      font-family: Reckless_light, sans-serif;
      font-size: 26px;
      line-height: 35px;
    }
  }
  @media (max-width: 480px) {
    top: 85%;
    a {
      font-size: 22px;
    }
  }
`
export const Sticker5 = styled.img`
  position: absolute;
  top: 8%;
  left: 60%;
  @media (min-width: 1024px) {
    top: 30%;
    left: 1%;
  }
  @media (min-width: 1440px) {
    top: 30%;
    left: 1%;
  }
  @media (min-width: 1920px) {

  }
  @media (min-width: 2400px) {

  }
`

export const Sticker6 = styled.img`
  position: absolute;
  top: 40%;
  left: 60%;
  @media (min-width: 1024px) {
    top: 45%;
    left: 60%;
  }
  @media (min-width: 1440px) {
    top: 45%;
    left: 60%;
  }
  @media (min-width: 1920px) {

  }
  @media (min-width: 2400px) {

  }
`
export const Sticker10 = styled.img`
  position: absolute;
  bottom: -12%;
  left: 16%;
  @media (min-width: 1024px) {
    bottom: -5%;
    left: 16%;
  }
  @media (min-width: 1440px) {

  }
  @media (min-width: 1920px) {

  }
  @media (min-width: 2400px) {

  }
`

export const ContainerSectionFour = styled.section`
  position: absolute;
  top: 6200px;
  width: 100%;
  height: 2000px;
  z-index: 10;
  @media (max-width: 720px) {
    top: 4500px;
    height: 1200px;
  }
`

export const ShowBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 25%;
  margin: 0 auto;
  width: 60%;
  height: 1000px;

  h2 {
    position: relative;
    top: 1%;
    font-family: Reckless_light, sans-serif;
    font-size: 200px;
    font-weight: 400;
    color: #26439B;
  }

  button {

    background-color: #F0EEE9;
    border: none;
    color: #26439B;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 60px;
    width: 2em;

  }

  .buttonContainer {
    position: relative;
    top: 10%;
    border-radius: 50%;
    padding: 10vw;

    background: #F0EEE9;
    color: #666;
    text-align: center;
    width: 16em;
    height: 16em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    position: relative;
    top: 18%;
    text-align: center;
    font-family: Reckless_light, sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 62px;
    color: #26439B;
  }
    @media (max-width: 1440px) {

    }
    @media (max-width: 1024px) {

    }
    @media (max-width: 720px) {
      top: 15%;
      margin: 0 auto;
      width: 90%;
      height: 1000px;

      h2 {
        position: relative;
        top: 1%;
        font-size: 100px;
      }

      button {
        top: 10%;
        font-size: 60px;
        margin: 4px 2px;
        border-radius: 50%;
      }
      p {
        
        font-size: 26px;
      }
    }
    @media (max-width: 480px) {
    }
`
export const Sticker11 = styled.img`
  position: absolute;
  top: 0%;
  left: 20%;
  display: none;
  @media (min-width: 1024px) {
    top: 6%;
    left: 65%;
    display: block;
  }
  @media (min-width: 1440px) {
    
  }
  @media (min-width: 1920px) {

  }
  @media (min-width: 2400px) {

  }
`
export const Sticker12 = styled.img`
  position: absolute;
  top: 0%;
  left: 20%;
  display: none;
  @media (min-width: 1024px) {
    top: 8%;
    left: 18%;
    display: block;
  }
  @media (min-width: 1440px) {
    
  }
  @media (min-width: 1920px) {

  }
  @media (min-width: 2400px) {

  }
`
export const Sticker13 = styled.img`
  position: absolute;
  top: 80%;
  left: 60%;
  @media (min-width: 1024px) {
    top: 28%;
    left: 80%;
  }
  @media (min-width: 1440px) {
    
  }
  @media (min-width: 1920px) {

  }
  @media (min-width: 2400px) {

  }
`
export const Sticker14 = styled.img`
  position: absolute;
  top: 0%;
  left: 20%;
  display: none;
  @media (min-width: 1024px) {
    top: 32%;
    left: 8%;
    display: block;
  }
  @media (min-width: 1440px) {
    
  }
  @media (min-width: 1920px) {

  }
  @media (min-width: 2400px) {

  }
`
export const Sticker15 = styled.img`
  position: absolute;
  top: 0%;
  left: 20%;
  display: none;
  @media (min-width: 1024px) {
    top: 46%;
    left: 75%;
    display: block;
  }
  @media (min-width: 1440px) {
    
  }
  @media (min-width: 1920px) {

  }
  @media (min-width: 2400px) {

  }
`
export const Sticker16 = styled.img`
  position: absolute;
  top: 0%;
  left: 20%;
  display: none;
  @media (min-width: 1024px) {
    top: 55%;
    left: 0;
    display: block;
  }
  @media (min-width: 1440px) {
    
  }
  @media (min-width: 1920px) {

  }
  @media (min-width: 2400px) {

  }
`
export const Sticker17 = styled.img`
  position: absolute;
  top: 0%;
  left: 20%;
  display: none;
  @media (min-width: 1024px) {
    top: 78%;
    left: 70%;
    display: block;
  }
  @media (min-width: 1440px) {
    
  }
  @media (min-width: 1920px) {

  }
  @media (min-width: 2400px) {

  }
`
export const Sticker18 = styled.img`
  position: absolute;
  top: 64%;
  left: -20%;
  @media (min-width: 1024px) {
    top: 73%;
    left: 20%;
  }
  @media (min-width: 1440px) {
    
  }
  @media (min-width: 1920px) {

  }
  @media (min-width: 2400px) {

  }
`

export const ContainerSectionFive = styled.section`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
`
