import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import styled from 'styled-components'
import ProjectForm from './styles/ProjectForm'
import InputLimit from './styles/InputLimit'
import Error from './ErrorMessage'
import { gql } from '@apollo/client'
import { CURRENT_USER_QUERY } from './User'
import { withRouter } from 'react-router'
import { client } from '../..'
import cogoToast from 'cogo-toast'
import SearchInput from './SearchInput'
import { Progress } from 'semantic-ui-react'
import Loading from './Loading'
import { QUERY } from '../../graphql'

const MAX_IMG_SIZE = 5 * 1024 * 1024
const MAX_VIDEO_SIZE = 50 * 1024 * 1024
const allowedImageExt = 'image/bmp, image/jpeg, image/png, image/gif'
const allowedVideoExt = 'video/*, .mkv, video/mp4,video/x-m4v'

const CREATE_PROJECT_MUTATION = gql`
  mutation CREATE_PROJECT_MUTATION(
    $title: String!
    $primaryProjectType: String
    $secondaryProjectType: String
    $secondaryProjectType_2: String
    $contentType: ProjectCreatecontentTypeInput
    $contentData: ProjectCreatecontentDataInput
    $coverImage: String!
    $members: [CustomInput!]!
    $published: Boolean!
  ) {
    createProject(
      title: $title
      primaryProjectType: $primaryProjectType
      secondaryProjectType: $secondaryProjectType
      secondaryProjectType_2: $secondaryProjectType_2
      contentType: $contentType
      contentData: $contentData
      coverImage: $coverImage
      members: $members
      published: $published
    ) {
      id
    }
  }
`

const USER_UPLOAD_FILE = gql`
  mutation ($file: Upload!, $isVideo: Boolean) {
    singleUpload(file: $file, isVideo: $isVideo) {
      id
      path
      filename
    }
  }
`

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 20px;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 60px;
`
const GridOne = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 60px;
  margin-top: 50px;
  video {
    max-width: 50%;
  }
`
const GridThree = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 0.5fr;
  grid-gap: 60px;
`
const GridButton = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.5fr;
  grid-gap: 60px;
`

const UploadBlock = styled.div`
  background-color: #e3e3e3;
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .upload-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(21.5em + 6.75rem + 2px);
    margin: 0;
    opacity: 0;
  }

  .upload-placeholder {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.5;
    z-index: 2;
  }
`

const UploadIcon = styled.img`
  background: rgb(243, 215, 222);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  height: 50px;
  width: 50px;
  padding: 10px;

  &:hover,
  &:focus {
    outline: none;
  }
`

const CoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`

const HelveticaTitle = styled.p`
  font-family:
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  font-weight: bold;
  color: black;
  font-size: 24px;
`
const Button = styled.button`
  ${(props) =>
    props.isLoading
      ? `
    opacity: .7;
    pointer-events: none;`
      : ''}
`
const ErrorText = styled.p`
  font-size: 16px;
  color: red;
`

class CreateProject extends Component {
  state = {
    currentUser: undefined,
    project: {
      title: '',
      primaryProjectType: '',
      secondaryProjectType: '',
      secondaryProjectType_2: '',
      contentType: { set: [] },
      contentData: { set: [] },
      coverImage: '',
      members: [],
      published: true,
    },
    memberInputs: ['1'],
    contentInputs: [],
    contents: {
      contentType_1: '',
      contentData_1: '',
      contentType_2: '',
      contentData_2: '',
      contentType_3: '',
      contentData_3: '',
      contentType_4: '',
      contentData_4: '',
      contentType_5: '',
      contentData_5: '',
      contentType_6: '',
      contentData_6: '',
      contentType_7: '',
      contentData_7: '',
      contentType_8: '',
      contentData_8: '',
      contentType_9: '',
      contentData_9: '',
      contentType_10: '',
      contentData_10: '',
      contentType_11: '',
      contentData_11: '',
    },
    uploadFinish: true,
    errorEmptyRole: false,
  }
  appendMember = () => {
    var newMemberInput = `${this.state.memberInputs.length + 1}`
    this.setState((prevState) => ({
      memberInputs: prevState.memberInputs.concat([newMemberInput]),
      project: {
        ...prevState.project,
        members: [...prevState.project.members, { id: prevState.currentUser.id, role: '' }],
      },
    }))
  }
  appendContent = () => {
    var newContentInput = `${this.state.contentInputs.length + 1}`
    this.setState((prevState) => ({
      contentInputs: prevState.contentInputs.concat([newContentInput]),
    }))
  }
  handlePublish = () => {
    this.setState((prevState) => {
      let project = Object.assign({}, prevState.project)
      project.published = true
      return { project }
    })
    return true
  }

  handlePreview = async () => {
    const valid = this.validateRole()
    if (!valid) {
      return
    }

    const variables = { ...this.state.project }
    if (!variables.title) {
      cogoToast.error('Project title is required')
      return
    }
    if (!variables.coverImage) {
      cogoToast.error('Project cover image title is required')
      return
    }
    if (!variables.primaryProjectType) {
      cogoToast.error('Project primary type is required')
      return
    }

    variables.published = false
    console.log('variables: ', variables)
    const res = await client.mutate({
      mutation: CREATE_PROJECT_MUTATION,
      variables,
    })
    // change them to the single item page
    this.props.history.push({
      pathname: '/preview',
      search: `?id=${res.data.createProject.id}`,
    })
    return true
  }

  handleChange = (e) => {
    const { name, type, value } = e.target
    const val = type === 'number' ? parseFloat(value) : value
    this.setState((prevState) => {
      let project = Object.assign({}, prevState.project) // creating copy of state variable project
      project[name] = val // update the name property, assign a new value
      return { project } // return new object project object
    })
  }
  handleType = (e) => {
    const { name, value } = e.target
    this.setState((prevState) => {
      let project = Object.assign({}, prevState.project)
      project[name] = { set: [value] }
      return { project }
    })
  }
  handleMember = (e) => {
    const { value } = e.target
    this.setState((prevState) => {
      let project = Object.assign({}, prevState.project)
      let memberExists = false
      project.members.map((member) => {
        if (member.id === value) {
          memberExists = true
        }
        return null
      })
      !memberExists &&
        project.members.push({
          id: value,
          role: '',
        })
      return { project }
    })
  }

  handleMemberSearch = (value, index) => {
    const { project } = this.state
    const members = project.members.map((mb, idx) => {
      if (index === idx) {
        return { ...mb, id: value }
      }
      return mb
    })
    this.setState({ project: { ...project, members } })
  }

  handleRole = (e, index) => {
    const { value } = e.target
    const { project } = this.state
    const members = project.members.map((mb, idx) => {
      if (idx === index) {
        return { ...mb, role: value }
      }
      return mb
    })
    this.setState({ project: { ...project, members } })
  }
  handleContentType = (e) => {
    const { id, name, value } = e.target
    this.setState({ [id]: value })
    /* eslint-disable-next-line */
    const [a, index] = name.split('contentType_')
    this.setState((prevState) => {
      let project = Object.assign({}, prevState.project)
      if (project.contentType.set.length < index) {
        project.contentType.set.push(value)
      } else {
        project.contentType.set.splice(parseInt(index) - 1, 1, value)
      }
      return { project }
    })
  }
  handleContentData = (e) => {
    const { name, value } = e.target
    /* eslint-disable-next-line */
    const [a, index] = name.split('contentData_')
    this.setState((prevState) => {
      let project = Object.assign({}, prevState.project)
      if (project.contentData.set.length < index) {
        project.contentData.set.push(value)
      } else {
        project.contentData.set.splice(parseInt(index) - 1, 1, value)
      }
      return { project }
    })
  }
  uploadFile = async (e) => {
    this.setState({ uploadFinish: false })
    const files = e.target.files
    const file = files[0]

    if (file.size > MAX_IMG_SIZE) {
      alert('Maximum image size is 5MB')
      this.setState({ uploadFinish: true })
      e.target.value = null
      return
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async (event) => {
        if (event.total > MAX_IMG_SIZE) {
          alert('Maximum image size is 5MB')
          return
        }
        const { data } = await client.mutate({
          mutation: USER_UPLOAD_FILE,
          variables: { file },
        })
        const coverImage = data.singleUpload.path
        this.setState((prevState) => {
          let project = Object.assign({}, prevState.project)
          project.coverImage = coverImage
          return { project, uploadFinish: true }
        })
      }
    }
  }
  uploadContentFile = async (e) => {
    const { id, name } = e.target
    this.setState({ uploadFinish: false })

    const files = e.target.files
    const file = files[0]
    const [media, index] = id.split('_')
    const isVideo = media === 'video'

    let interval
    const keyState = `contentType_${index}_loading`
    if (isVideo) {
      interval = this.fakeLoading(keyState)
    }

    const size = isVideo ? MAX_VIDEO_SIZE : MAX_IMG_SIZE
    if (file.size > size) {
      alert(`Maximum ${isVideo ? 'video' : 'image'} size is ${isVideo ? '50 MB' : '5 MB'}`)
      this.setState({ uploadFinish: true })
      clearInterval(interval)
      e.target.value = null
      return
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async (event) => {
        if (event.total > size) {
          alert(`Maximum ${isVideo ? 'video' : 'image'} size is ${isVideo ? '50 MB' : '5 MB'}`)
          this.setState({ uploadFinish: true })
          clearInterval(interval)
          return
        }
        const { data } = await client.mutate({
          mutation: USER_UPLOAD_FILE,
          variables: { file, isVideo },
        })
        clearInterval(interval)
        const path = data.singleUpload.path
        /* eslint-disable-next-line */
        const [a, index] = name.split('contentData_')
        this.setState(
          (prevState) => {
            let project = Object.assign({}, prevState.project)
            if (project.contentData.set.length < index) {
              project.contentData.set.push(path)
            } else {
              project.contentData.set.splice(parseInt(index) - 1, 1, path)
            }
            return { project, uploadFinish: true, [keyState]: 100 }
          },
          () => {
            setTimeout(() => {
              this.setState({ [keyState]: undefined })
            }, 3000)
          },
        )
      }
    }
  }

  fakeLoading = (keyState) => {
    const percent = Math.ceil(Math.random(7) * 10)
    const interval = setInterval(() => {
      if (this.state[keyState] && this.state[keyState] < 90) {
        this.setState((prevState) => ({ [keyState]: prevState[keyState] + percent }))
      } else {
        this.setState((prevState) => ({ [keyState]: 10 }))
      }
    }, 500)
    return interval
  }

  validateRole = () => {
    const { members } = this.state.project
    for (const member of members) {
      if (!member.role || member.role.length === 0) {
        this.setState({ errorEmptyRole: true })
        return false
      }
    }
    return true
  }

  render() {
    return (
      <Query
        query={CURRENT_USER_QUERY}
        onCompleted={(data) => {
          this.setState((prevState) => {
            let project = Object.assign({}, prevState.project)
            let memberExists = false
            /* eslint-disable-next-line */
            project.members.map((member) => {
              if (member.id === data.me.id) {
                memberExists = true
              }
            })
            /* eslint-disable-next-line */
            {
              !memberExists &&
                project.members.push({
                  id: data.me.id,
                  role: 'Project Lead',
                })
            }
            return { project, currentUser: data.me }
          })
        }}
      >
        {({ data, loading }) => {
          if (loading) return <Loading />
          if (!data.me) return <p>No Users Found</p>
          /* eslint-disable-next-line */
          const currentUser = data.me
          return (
            <Query
              query={QUERY.USERS_QUERY}
              variables={{
                where: {
                  studentYear: 2023,
                },
              }}
            >
              {({ data, loading }) => {
                if (loading) return <Loading />
                if (!data.users) return <p>No Users Found</p>
                return (
                  <Mutation mutation={CREATE_PROJECT_MUTATION} variables={this.state.project}>
                    {(createProject, { loading, error }) => (
                      <ProjectForm
                        onSubmit={async (e) => {
                          // Stop the form from submitting
                          e.preventDefault()
                          const valid = this.validateRole()
                          // call the mutation
                          if (valid) {
                            const res = await createProject()
                            //console.log(this.props);
                            // change them to the single item page
                            this.props.history.push({
                              pathname: '/project',
                              search: `?id=${res.data.createProject.id}`,
                            })
                          }
                        }}
                      >
                        <Error error={error} />
                        <fieldset disabled={loading} aria-busy={loading}>
                          <GridOne>
                            <Grid>
                              <div>
                                <HelveticaTitle>Project Information</HelveticaTitle>
                                <label htmlFor='title'>
                                  Project Title
                                  <InputLimit
                                    type='text'
                                    id='title'
                                    name='title'
                                    placeholder='Title'
                                    required
                                    maxLength={55}
                                    value={this.state.project.title}
                                    onChange={this.handleChange}
                                    fromtop={44}
                                  />
                                </label>
                                {/* <Columns>
                                  <SearchInput users={data.users} />
                                </Columns> */}
                                {this.state.project.members.map((member, index) => {
                                  const { role, id } = member
                                  const user = data.users.find((usr) => usr.id === id)

                                  return (
                                    <Columns key={`key_${index + 1}`}>
                                      <label htmlFor={`member_${index + 1}`}>
                                        Member
                                        <SearchInput
                                          users={data.users}
                                          onChange={(value) =>
                                            this.handleMemberSearch(value, index)
                                          }
                                          value={`${user?.firstName} ${user?.lastName}`}
                                          // value={`${user.firstName} ${user.lastName}`}
                                          disabled={index === 0}
                                        />
                                      </label>
                                      <label htmlFor={`role_${index + 1}`}>
                                        Role
                                        <input
                                          type='text'
                                          defaultValue={role}
                                          onChange={(e) => this.handleRole(e, index)}
                                        />
                                      </label>
                                    </Columns>
                                  )
                                })}
                                {this.state.errorEmptyRole && (
                                  <ErrorText>Role cannot be empty</ErrorText>
                                )}
                                <p
                                  onClick={() => this.appendMember()}
                                  style={{
                                    fontSize: '18px',
                                    color: '#000',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  + Group Member
                                </p>
                              </div>
                              <div>
                                <label htmlFor='coverImage'>
                                  Cover Image
                                  <UploadBlock>
                                    <input
                                      type='file'
                                      id='coverImage'
                                      name='coverImage'
                                      placeholder='Upload an image'
                                      onChange={this.uploadFile}
                                      required
                                      className='upload-input'
                                      accept={allowedImageExt}
                                    />
                                    {!this.state.project.coverImage && (
                                      <img
                                        className='upload-placeholder'
                                        src={'/static/drawing.svg'}
                                        alt=''
                                      />
                                    )}
                                    <UploadIcon src='/static/edit.svg' alt='Change Image' />
                                    {this.state.project.coverImage && (
                                      <CoverImage
                                        src={this.state.project.coverImage}
                                        alt='Upload Preview'
                                      />
                                    )}
                                  </UploadBlock>
                                </label>
                              </div>
                            </Grid>
                            <GridThree>
                              <label htmlFor='primaryProjectType'>
                                <HelveticaTitle>Primary Project Type</HelveticaTitle>
                                <select
                                  id='primaryProjectType'
                                  name='primaryProjectType'
                                  defaultValue={''}
                                  onChange={this.handleChange}
                                  required
                                >
                                  <option disabled value={''}>
                                    -- select a project type --
                                  </option>
                                  <option value='3d_design'>3D Design</option>
                                  <option value='3d_sculpting'>3D Sculpting</option>
                                  <option value='3d_visualizations'>3D Visualizations</option>
                                  <option value='advertising'>Advertising</option>
                                  <option value='animation'>Animation</option>
                                  <option value='augmented_virtual_reality'>
                                    Augmented/Virtual Reality
                                  </option>
                                  <option value='branding'>Branding</option>
                                  <option value='device_development'>Device Development</option>
                                  <option value='digital_applications'>Digital Applications</option>
                                  <option value='drawing'>Drawing</option>
                                  <option value='editorial'>Editorial</option>
                                  <option value='exhibition'>Exhibition</option>
                                  <option value='game_design'>Game Design</option>
                                  <option value='illustration'>Illustration</option>
                                  <option value='immersive_environments'>
                                    Immersive Environments
                                  </option>
                                  <option value='interactive_design'>Interactive Design</option>
                                  <option value='interface_development'>
                                    Interface Development
                                  </option>
                                  <option value='media_production'>Media Production</option>
                                  <option value='modeling'>Modeling</option>
                                  <option value='motion_graphics'>Motion Graphics</option>
                                  <option value='packaging'>Packaging</option>
                                  <option value='photography'>Photography</option>
                                  <option value='physical_computing'>Physical Computing</option>
                                  <option value='strategy'>Strategy</option>
                                  <option value='systems_and_services'>Systems and Services</option>
                                  <option value='technological_systems'>
                                    Technological Systems
                                  </option>
                                  <option value='typography'>Typography</option>
                                  <option value='user_experience'>User Experience</option>
                                  <option value='video'>Video</option>
                                  <option value='web_design'>Web Design</option>
                                </select>
                              </label>
                              <label htmlFor='secondaryProjectType'>
                                <HelveticaTitle>Secondary Project Type</HelveticaTitle>
                                <select
                                  id='secondaryProjectType'
                                  name='secondaryProjectType'
                                  defaultValue={''}
                                  onChange={this.handleChange}
                                >
                                  <option disabled value={''}>
                                    -- select a project type --
                                  </option>
                                  <option value='3d_design'>3D Design</option>
                                  <option value='3d_sculpting'>3D Sculpting</option>
                                  <option value='3d_visualizations'>3D Visualizations</option>
                                  <option value='advertising'>Advertising</option>
                                  <option value='animation'>Animation</option>
                                  <option value='augmented_virtual_reality'>
                                    Augmented/Virtual Reality
                                  </option>
                                  <option value='branding'>Branding</option>
                                  <option value='device_development'>Device Development</option>
                                  <option value='digital_applications'>Digital Applications</option>
                                  <option value='drawing'>Drawing</option>
                                  <option value='editorial'>Editorial</option>
                                  <option value='exhibition'>Exhibition</option>
                                  <option value='game_design'>Game Design</option>
                                  <option value='illustration'>Illustration</option>
                                  <option value='immersive_environments'>
                                    Immersive Environments
                                  </option>
                                  <option value='interactive_design'>Interactive Design</option>
                                  <option value='interface_development'>
                                    Interface Development
                                  </option>
                                  <option value='media_production'>Media Production</option>
                                  <option value='modeling'>Modeling</option>
                                  <option value='motion_graphics'>Motion Graphics</option>
                                  <option value='packaging'>Packaging</option>
                                  <option value='photography'>Photography</option>
                                  <option value='physical_computing'>Physical Computing</option>
                                  <option value='strategy'>Strategy</option>
                                  <option value='systems_and_services'>Systems and Services</option>
                                  <option value='technological_systems'>
                                    Technological Systems
                                  </option>
                                  <option value='typography'>Typography</option>
                                  <option value='user_experience'>User Experience</option>
                                  <option value='video'>Video</option>
                                  <option value='web_design'>Web Design</option>
                                </select>
                              </label>
                              <label htmlFor='secondaryProjectType_2'>
                                <HelveticaTitle>Secondary Project Type</HelveticaTitle>
                                <select
                                  id='secondaryProjectType_2'
                                  name='secondaryProjectType_2'
                                  defaultValue={''}
                                  value={''}
                                  onChange={this.handleChange}
                                >
                                  <option disabled value={''}>
                                    -- select a project type --
                                  </option>
                                  <option value='3d_design'>3D Design</option>
                                  <option value='3d_sculpting'>3D Sculpting</option>
                                  <option value='3d_visualizations'>3D Visualizations</option>
                                  <option value='advertising'>Advertising</option>
                                  <option value='animation'>Animation</option>
                                  <option value='augmented_virtual_reality'>
                                    Augmented/Virtual Reality
                                  </option>
                                  <option value='branding'>Branding</option>
                                  <option value='device_development'>Device Development</option>
                                  <option value='digital_applications'>Digital Applications</option>
                                  <option value='drawing'>Drawing</option>
                                  <option value='editorial'>Editorial</option>
                                  <option value='exhibition'>Exhibition</option>
                                  <option value='game_design'>Game Design</option>
                                  <option value='illustration'>Illustration</option>
                                  <option value='immersive_environments'>
                                    Immersive Environments
                                  </option>
                                  <option value='interactive_design'>Interactive Design</option>
                                  <option value='interface_development'>
                                    Interface Development
                                  </option>
                                  <option value='media_production'>Media Production</option>
                                  <option value='modeling'>Modeling</option>
                                  <option value='motion_graphics'>Motion Graphics</option>
                                  <option value='packaging'>Packaging</option>
                                  <option value='photography'>Photography</option>
                                  <option value='physical_computing'>Physical Computing</option>
                                  <option value='strategy'>Strategy</option>
                                  <option value='systems_and_services'>Systems and Services</option>
                                  <option value='technological_systems'>
                                    Technological Systems
                                  </option>
                                  <option value='typography'>Typography</option>
                                  <option value='user_experience'>User Experience</option>
                                  <option value='video'>Video</option>
                                  <option value='web_design'>Web Design</option>
                                </select>
                              </label>
                            </GridThree>
                            <HelveticaTitle>Content</HelveticaTitle>
                            {this.state.contentInputs.map((input, i) => (
                              <GridOne key={`contentKey_${input}`}>
                                <label htmlFor={`contentType_${input}`}>
                                  <select
                                    id={`contentType_${input}`}
                                    name={`contentType_${input}`}
                                    onChange={this.handleContentType}
                                    defaultValue={'DEFAULT'}
                                    style={{ width: '24%' }}
                                  >
                                    <option disabled value={'DEFAULT'}>
                                      -- select a content type --
                                    </option>
                                    <option value='Heading'>Heading</option>
                                    <option value='Text'>Text</option>
                                    <option value='Video'>Video</option>
                                    <option value='Image'>Image</option>
                                  </select>
                                </label>
                                {this.state[`contentType_${input}`] === 'Heading' && (
                                  <label htmlFor={`contentData_${input}`}>
                                    Heading
                                    <input
                                      type='text'
                                      id={`contentData_${input}`}
                                      name={`contentData_${input}`}
                                      placeholder=''
                                      defaultValue=''
                                      onChange={this.handleContentData}
                                    />
                                  </label>
                                )}
                                {this.state[`contentType_${input}`] === 'Text' && (
                                  <label htmlFor={`contentData_${input}`}>
                                    Text
                                    <textarea
                                      type='text'
                                      id={`contentData_${input}`}
                                      name={`contentData_${input}`}
                                      placeholder=''
                                      defaultValue=''
                                      onChange={this.handleContentData}
                                    />
                                    {/* <MdEditor
                                      onChange={(value) => {
                                        this.setState((prevState) => {
                                          let project = Object.assign(
                                            {},
                                            prevState.project
                                          );
                                          if (
                                            project.contentData.set.length < i
                                          ) {
                                            project.contentData.set.push(value);
                                          } else {
                                            project.contentData.set[i] = value;
                                          }
                                          return { project };
                                        });
                                      }}
                                    /> */}
                                  </label>
                                )}
                                {this.state[`contentType_${input}`] === 'Video' && (
                                  <label htmlFor={`video_${input}`}>
                                    Video
                                    <input
                                      type='file'
                                      id={`video_${input}`}
                                      name={`contentData_${input}`}
                                      placeholder='Upload a video'
                                      required
                                      onChange={this.uploadContentFile}
                                      accept={allowedVideoExt}
                                    />
                                    {this.state[`contentType_${input}_loading`] > 0 && (
                                      <Progress
                                        percent={this.state[`contentType_${input}_loading`]}
                                        size='small'
                                        color='green'
                                      />
                                    )}
                                  </label>
                                )}
                                {this.state[`contentType_${input}`] === 'Image' && (
                                  <label htmlFor={`image_${input}`}>
                                    Image
                                    <input
                                      type='file'
                                      id={`image_${input}`}
                                      name={`contentData_${input}`}
                                      placeholder='Upload an image'
                                      required
                                      onChange={this.uploadContentFile}
                                      accept={allowedImageExt}
                                    />
                                    {this.state[`contentType_${input}_loading`] > 0 && (
                                      <Progress
                                        percent={this.state[`contentType_${input}_loading`]}
                                        size='small'
                                        color='green'
                                      />
                                    )}
                                  </label>
                                )}
                                {this.state.project.contentData.set.map(
                                  /* eslint-disable-next-line */
                                  (content, j) => {
                                    if (
                                      content !== undefined &&
                                      parseInt(j) === parseInt(i) &&
                                      content.includes('http://yearendshow.schoolofdesign.ca')
                                    ) {
                                      if (this.state.project.contentType.set[i] === 'Image') {
                                        return (
                                          <img src={content} alt='Upload Preview ' key={content} />
                                        )
                                      } else {
                                        return (
                                          <video>
                                            <source
                                              type='video/mp4'
                                              src={content}
                                              key={content}
                                            ></source>
                                          </video>
                                        )
                                      }
                                    }
                                  },
                                )}
                              </GridOne>
                            ))}
                            <p onClick={() => this.appendContent()}>+ Add Field</p>
                            <GridButton>
                              <div></div>
                              <Button
                                type='button'
                                onClick={this.handlePreview}
                                isLoading={!this.state.uploadFinish}
                              >
                                SAVE/PREVIEW
                              </Button>
                              <Button
                                type='submit'
                                onSubmit={this.handlePublish}
                                isLoading={!this.state.uploadFinish}
                              >
                                PUBLISH
                              </Button>
                            </GridButton>
                          </GridOne>
                        </fieldset>
                      </ProjectForm>
                    )}
                  </Mutation>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(CreateProject)
export { CREATE_PROJECT_MUTATION }
