import React, { useMemo, useState, useContext, useEffect } from 'react'
import { P, H1M, H4 } from '../../../styles/2024/TextStyles'
import LetterForm from '../../../components/2024/LetterForm'
import { useQuery } from '@apollo/client'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { QUERY } from '../../../graphql'
import LetterBox from '../../../components/2024/LetterBox'
import { SpringContext } from '../../../context/SpringContext'
import { SearchContainer } from '../../../styles/2024/GradsStyles'
const Header = React.lazy(() => import('../../../components/2024/Header'))
const Countdown = React.lazy(() => import('../../../components/2024/Countdown'))
const Footer = React.lazy(() => import('../../../components/2024/Footer'))

export default function Index() {
  const [gradsSearch, setGradsSearch] = useState('')
  const spring = useContext(SpringContext)
  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring])
  const { data, loading } = useQuery(QUERY.LETTERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      where: {
        createdAt_gt: '2023-12-31',
      },
      order: {
        createdAt: 'inc',
      },
    },
  })

  const filteredLetters = useMemo(() => {
    if (!data?.letters) {
      return {}
    }
    let letters = data?.letters || []
    // update the data based on the search if there is any search in data.senders or data.recipients
    if (gradsSearch) {
      letters = letters.filter((letter) => {
        const { sender, recipient } = letter
        return (
          sender.toLowerCase().includes(gradsSearch.toLowerCase()) ||
          recipient.toLowerCase().includes(gradsSearch.toLowerCase())
        )
      })
    }

    return letters
  }, [data, gradsSearch])

  return (
    <div className='absolute w-full bg-almost-black flex flex-col items-center justify-start pb-20 lg:pb-0'>
      {Countdown && <Countdown />}
      {Header && <Header />}

      <div className='relative w-full min-h-max bg-comfort-green flex flex-col items-center justify-center w-full'>
        <div className='relative w-full bg-comfort-green flex flex-col items-left justify-center px-[16px] lg:px-[48px] py-20 max-w-[1440px]'>
          <div className='relative grid grid-cols-1 lg:grid-cols-2 gap-14'>
            <div className='relative flex flex-col'>
              <div className='flex flex-col text-lg font-light text-almost-black '>
                <H1M
                  className='text-almost-black uppercase lg:!text-[72px]'
                  style={{ pointerEvents: 'none' }}
                >
                  Send your graduation wishes!
                </H1M>
                <H4 className='mt-4 lg:pr-[20%]'>
                  Leave your congratulatory messages for the graduating class of 2024!
                </H4>
              </div>
            </div>
            <LetterForm />
          </div>
        </div>
      </div>

      <div className='relative w-full min-h-max bg-almost-black flex flex-col items-center justify-center w-full'>
        <div className='grid grid-cols-1 lg:grid-cols-2 w-full my-16 items-center px-[16px] lg:px-[48px]  max-w-[1440px]'>
          {filteredLetters?.length === 0 ? (
            <P className='text-beige-yellow'> No messages found.</P>
          ) : filteredLetters?.length === 1 ? (
            <P className='text-beige-yellow'>
              There is <span className='text-comfort-green'>1</span> message.
            </P>
          ) : filteredLetters?.length > 1 ? (
            <P className='text-beige-yellow'>
              There are <span className='text-comfort-green'>{filteredLetters?.length}</span>{' '}
              messages.
            </P>
          ) : (
            <P className='text-beige-yellow'>Loading</P>
          )}
          <SearchContainer className='flex relative w-full items-end justify-end'>
            <input
              type='text'
              id='gname'
              name='gname'
              placeholder='Search graduates'
              className='text-almost-black bg-beige-yellow placeholder-almost-black border border-almost-black w-full lg:w-4/5 '
              onChange={(e) => setGradsSearch(e.target.value)}
            />
            <img className='absolute' src='/static/2024/svg/search.svg' alt='Search' />
          </SearchContainer>
        </div>
        <div className='relative w-full bg-almost-black px-[16px] lg:px-[48px] pb-20 max-w-[1440px]'>
          <TransitionGroup className='grid grid-cols-1 lg:grid-cols-4 gap-14'>
            {filteredLetters.length > 0 &&
              filteredLetters?.map((letter, index) => (
                <CSSTransition key={index} timeout={500} classNames='item'>
                  <LetterBox key={`letter${index}`} letter={letter} index={index} />
                </CSSTransition>
              ))}
          </TransitionGroup>
        </div>
      </div>

      <div className='relative w-full bg-almost-black flex flex-col items-center justify-center w-full'>
        <div className='relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] bg-almost-black max-w-[1440px]'>
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}
