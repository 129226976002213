import PropTypes from "prop-types";
import _ from "lodash";
import React, { Component } from "react";
import { Grid, Search } from "semantic-ui-react";
import styles from './styles.module.css'

const resultRenderer = ({ description }) => {
  return (
    <div>{description}</div>
  )
};

resultRenderer.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  id: PropTypes.string
};

const initialState = { isLoading: false, results: [], value: "" };

export default class SearchInput extends Component {
  state = { 
    ...initialState,
    value: this.props.value || initialState.value
  };

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.description }, () => {
      this.props.onChange && this.props.onChange(result.title)
    });
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });
    console.log('value: ',value)

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState(initialState);

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = (result) =>
        re.test(`${result.firstName} ${result.lastName}`);

      this.setState({
        isLoading: false,
        results: _.filter(this.props.users, isMatch),
      });
    }, 300);
  };

  render() {
    const { isLoading, value, results } = this.state;
    const { disabled = false } = this.props
    const searchResults = results.map(res => ({title: res.id, description: `${res.firstName} ${res.lastName}`}))
    // const currentValue = value || defaultValue
    // const currentUser = users.find(usr => usr.id === currentValue) || {}
    // const valueStr = `${currentUser.firstName} ${currentUser.lastName}`

    return (
      <Grid>
        <Grid.Column>
          <Search
            disabled={disabled}
            loading={isLoading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true,
            })}
            results={searchResults}
            value={value}
            resultRenderer={resultRenderer}
            fluid
            className={styles['border-radius-none']}
          />
        </Grid.Column>
      </Grid>
    );
  }
}
