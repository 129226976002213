import styled from "styled-components";

const ProjectItemStyles = styled.div`
  background: white;
  /* border: 1px solid ${props => props.theme.offWhite};
  box-shadow: ${props => props.theme.bs}; */
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  padding-top: 30px;
  
  @media (min-width: 768px) {
    padding-top: 40px;

    & > a,
    & > h3,
    & > p {
      margin-left: auto;
      margin-right: auto;
      max-width: 80%;
    }
  }
  
  @media (min-width: 992px) {
    padding-top: 50px;

    & > a,
    & > h3,
    & > p {
      max-width: 60%;
    }
  }

  & > h3,
  & > p {
    font-family: 'grotesque_pro_light';
    font-size: 16px;
    line-height: 18px;
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
  
  p {
    line-height: 2;
    font-weight: 300;
    flex-grow: 1;
    padding: 0 3rem;
    font-size: 1.5rem;
  }
  
  a {
    text-align: center;
    width: 60%;
    word-wrap: break-word;
  }
  
  .buttonList {
    display: grid;
    width: 100%;
    border-top: 1px solid ${props => props.theme.lightgrey};
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 1px;
    background: ${props => props.theme.lightgrey};
    & > * {
      background: white;
      border: 0;
      font-size: 1rem;
      padding: 1rem;
    }
  }
`;

export default ProjectItemStyles;
