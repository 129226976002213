import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Nav from './Nav'

const Logo = styled.h1`
  flex-grow: 1;
  font-size: 2rem;
  margin-bottom: 0;
  margin-left: 10px;
  position: relative;
  z-index: 2;

  @media (min-width: 768px) {
    margin-left: 20px;
  }

  a {
    // padding: 0.5rem 1rem;
    color: ${(props) => props.theme.black};
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      color: ${(props) => props.theme.black} !important;
    }
  }

  img {
    margin-top: 10px;
    width: 50px;
  }

  /* @media (max-width: 1300px) {
    margin: 0;
    text-align: center;
  } */
`

const StyledHeader = styled.div`
  position: relative;
  // z-index: 2001;
  // width: 100%;
  // max-width: 100vw;

  @media (max-width: 1024px) {
    margin-bottom: 100px  !important;
  }

  a {
    // color: ${(props) => props.theme.secondary}
    color: #DEFC1C;
    transition: all 0.4s ease-out;
    font-family: 'reckless_light';
  }

  .bar {
    color: ${(props) => props.theme.white};
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    z-index: 300;
    background-color: white;
    flex-wrap: wrap;
  }

  .sub-bar {
    display: grid;
    grid-template-columns: 1fr auto;
    border-bottom: 1px solid ${(props) => props.theme.lightgrey};
  }

  .marquee {
    height: 50px;
    width: 100vw;
    background-color: ${(props) => props.theme.blue};
    color: ${(props) => props.theme.secondary};
    overflow: hidden;
    box-sizing: border-box;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    z-index: 300;
    font-size: 28px;
    font-family: 'grotesque_pro_light';
    line-height: 2;
  }

  .marquee:hover {
    background-color: ${(props) => props.theme.secondary};
    color: ${(props) => props.theme.blue} !important;
  }

  .marquee:hover .marqueeContainer {
    color: ${(props) => props.theme.blue} !important;
  }

  .marquee:hover a {
    color: ${(props) => props.theme.blue} !important;
  }

  .marquee:hover span {
    color: ${(props) => props.theme.blue} !important;
  }

  .marqueeInner {
    display: block;
    width: 8000px;
    position: relative;
    -webkit-animation: marquee 20s linear infinite;
    -moz-animation: marquee 20s linear infinite;
    -o-animatione: marquee 20s linear infinite;
    animation: marquee 20s linear infinite;
    height: inherit;
  }

  .marquee:hover .marqueeInner {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
  }

  .marqueeContainer {
    height: 50px;
    display: inline-block;
    margin: 0 4px;
    float: left;
    transition: all 0.4s ease-out;
    border-style: dashed;
    border-bottom-width: 1px;
    border-color: #DEFC1C;
  }

  .marqueeEx {
    height: 50px;
    line-height: 64px;
    display: inline-block;
    margin: 0 2px;
    float: left;
    transition: all 0.4s ease-out;
  }

  .marqueeContainer:hover {
    text-decoration: underline;
  }

  span {
    float: left;
    width: 4000px;
    margin: 0;
    height: inherit;
  }

  @keyframes marquee {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }
  @media (max-width: 768px) {
    span {
      width: 8000px;
    }
    .marqueeInner {
      width: 16000px;
      animation: marquee 60s linear infinite;
    }
    @keyframes marquee {
      0% {
        left: 10%;
      }
      100% {
        left: -1440%;
      }
    }
  }
`

const Marquee = ({ marquee, shouldRenderNav }) => {

  return (
    <StyledHeader className=' bg-blue'>
      {marquee && (
        <div className='marquee'>
          <div className='marqueeInner'>
            <span>
              <Link
                to={{
                  pathname: '/2021/2021/work',
                  search: '?type=3d_design',
                }}
              >
                <div className='marqueeContainer'>3D Design!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=3d_sculpting',
                }}
              >
                <div className='marqueeContainer'>3D Sculpting!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=3d_visualizations',
                }}
              >
                <div className='marqueeContainer'>3D Visualizations!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=advertising',
                }}
              >
                <div className='marqueeContainer'>Advertising!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=animation',
                }}
              >
                <div className='marqueeContainer'>Animation!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=augmented_virtual_reality',
                }}
              >
                <div className='marqueeContainer'>Augmented/Virtual Reality!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=branding',
                }}
              >
                <div className='marqueeContainer'>Branding!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=digital_applications',
                }}
              >
                <div className='marqueeContainer'>Digital Applications!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=drawing',
                }}
              >
                <div className='marqueeContainer'>Drawing!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=editorial',
                }}
              >
                <div className='marqueeContainer'>Editorial!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=exhibition',
                }}
              >
                <div className='marqueeContainer'>Exhibition!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=illustration',
                }}
              >
                <div className='marqueeContainer'>Illustration!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=immersive_environments',
                }}
              >
                <div className='marqueeContainer'>Immersive Environments!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=interactive_design',
                }}
              >
                <div className='marqueeContainer'>Interactive Design!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=interface_development',
                }}
              >
                <div className='marqueeContainer'>Interface Development!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=media_production',
                }}
              >
                <div className='marqueeContainer'>Media Production!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=modeling',
                }}
              >
                <div className='marqueeContainer'>Modeling!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=motion_graphics',
                }}
              >
                <div className='marqueeContainer'>Motion Graphics!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=packaging',
                }}
              >
                <div className='marqueeContainer'>Packaging!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=photography',
                }}
              >
                <div className='marqueeContainer'>Photography!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=physical_computing',
                }}
              >
                <div className='marqueeContainer'>Physical Computing!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=strategy',
                }}
              >
                <div className='marqueeContainer'>Strategy!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=systems_and_services',
                }}
              >
                <div className='marqueeContainer'>Systems and Services!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=technological_systems',
                }}
              >
                <div className='marqueeContainer'>Technological Systems!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=user_experience',
                }}
              >
                <div className='marqueeContainer'>User Experience!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=video',
                }}
              >
                <div className='marqueeContainer'>Video!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=web_design',
                }}
              >
                <div className='marqueeContainer'>Web Design!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
            </span>
            <span>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=3d_design',
                }}
              >
                <div className='marqueeContainer'>3D Design!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=3d_sculpting',
                }}
              >
                <div className='marqueeContainer'>3D Sculpting!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=3d_visualizations',
                }}
              >
                <div className='marqueeContainer'>3D Visualizations!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=advertising',
                }}
              >
                <div className='marqueeContainer'>Advertising!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=animation',
                }}
              >
                <div className='marqueeContainer'>Animation!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=augmented_virtual_reality',
                }}
              >
                <div className='marqueeContainer'>Augmented/Virtual Reality!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=branding',
                }}
              >
                <div className='marqueeContainer'>Branding!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=digital_applications',
                }}
              >
                <div className='marqueeContainer'>Digital Applications!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=drawing',
                }}
              >
                <div className='marqueeContainer'>Drawing!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=editorial',
                }}
              >
                <div className='marqueeContainer'>Editorial!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=exhibition',
                }}
              >
                <div className='marqueeContainer'>Exhibition!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=illustration',
                }}
              >
                <div className='marqueeContainer'>Illustration!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=immersive_environments',
                }}
              >
                <div className='marqueeContainer'>Immersive Environments!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=interactive_design',
                }}
              >
                <div className='marqueeContainer'>Interactive Design!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=interface_development',
                }}
              >
                <div className='marqueeContainer'>Interface Development!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=media_production',
                }}
              >
                <div className='marqueeContainer'>Media Production!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=modeling',
                }}
              >
                <div className='marqueeContainer'>Modeling!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=motion_graphics',
                }}
              >
                <div className='marqueeContainer'>Motion Graphics!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=packaging',
                }}
              >
                <div className='marqueeContainer'>Packaging!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=photography',
                }}
              >
                <div className='marqueeContainer'>Photography!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=physical_computing',
                }}
              >
                <div className='marqueeContainer'>Physical Computing!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=strategy',
                }}
              >
                <div className='marqueeContainer'>Strategy!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=systems_and_services',
                }}
              >
                <div className='marqueeContainer'>Systems and Services!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=technological_systems',
                }}
              >
                <div className='marqueeContainer'>Technological Systems!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=user_experience',
                }}
              >
                <div className='marqueeContainer'>User Experience!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=video',
                }}
              >
                <div className='marqueeContainer'>Video!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
              <Link
                to={{
                  pathname: '/2021/work',
                  search: '?type=web_design',
                }}
              >
                <div className='marqueeContainer'>Web Design!</div>
              </Link>
              <p className='marqueeEx'>&#9679;</p>
            </span>
          </div>
        </div>
      )}
      <div className='bar'>
        {shouldRenderNav && (
          <>
            <Logo>
              <Link to='/'>
                <img src={'/static/logo.svg'} alt='logo' />
              </Link>
            </Logo>
            <Nav />
          </>
        )}
      </div>
    </StyledHeader>
  )
}

export default withRouter(Marquee)
