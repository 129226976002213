import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Project from "../../components/2020/Project";
import { useLocation, Link } from "react-router-dom";
import qs from "query-string";
import { client } from "../../index";
import { gql } from "apollo-boost";
import { useNavContext } from "../../components/2020/states/nav";
import Confetti from "../../components/2020/Confetti";

const Center = styled.div`
  text-align: center;
  
  @media (min-width: 768px) {
    margin-top: 100px;
  }
`;

const ProjectsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0;
  max-width: 100vw;
  gap: 0;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

const Title = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px 20px;
  margin: 0 auto 20px;
  padding: 0 24px;
  font-size: 32px;
  line-height: 1.1;
  letter-spacing: -1px;
  font-family: "reckless_light";
  
  @media (min-width: 576px) {
    font-size: 44px;
    grid-template-columns: 1fr auto;
  }
  
  @media (min-width: 768px) {
    grid-gap: 50px 80px;
    margin-bottom: 50px;
    max-width: ${(props) => props.theme.maxWidth};
    padding: 0;
  }

  div {
    position: relative;
    display: block;
  }
  
  .dropdowns {
    text-align: left;

    @media (min-width: 768px) {
      display: flex;      
    }
  }

  p {
    float: left;
  }
`;

const Dropdown = styled.div`
  font-family: 'grotesque_pro_light';
  .dropbtn {
    color: black;
    padding: 4px;
    font-size: 20px;
    border: none;
    background-color: white;
    text-decoration: underline;
    font-family: 'grotesque_pro_light' !important;
  }

  .dropdown {
    position: relative;
    width: 100%;
    display: inline-block;
    float: right;

    @media (min-width: 768px) {
      padding-left: 50px;
      width: auto;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    min-width: 400px;
    z-index: 1;
    text-align: left;
    padding: 0 5px 5px 5px;
  }

  .dropdown-content a {
    font-size: 20px;
    color: black;
    padding: 1px 4px;
    text-decoration: none;
    display: block;
    background-color: white;
    line-height: 24px;
  }
  .dropdown-content a:hover {
    text-decoration: underline;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
`

/* eslint-disable-next-line */
const DropdownReverse = styled.div`
  .dropbtn {
    color: black;
    padding: 4px;
    font-size: 20px;
    border: none;
    background-color: white;
    text-decoration: underline;
  }

  .dropdown {
    position: relative;
    width: 100%;
    display: inline-block;
    float: right;

    @media (min-width: 768px) {
      padding-left: 50px;
      width: auto;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    min-width: 400px;
    z-index: 1;
    padding: 0 5px 5px 5px;
    @media (min-width: 768px) {
      text-align: right;
      transform: translateX(-316px);
    }
  }

  .dropdown-content a {
    font-size: 20px;
    color: black;
    padding: 1px 4px;
    text-decoration: none;
    display: block;
    background-color: white;
    line-height: 24px;
  }
  .dropdown-content a:hover {
    text-decoration: underline;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
`

const PUBLIC_PROJECTS_QUERY = gql`
  query PUBLIC_PROJECTS_QUERY($where: ProjectWhereInput) {
    projects(where: $where) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        user {
          program
        }
      }
    }
  }
`;

const shuffleArr = (arra1) => {
    var ctr = arra1.length,
      temp,
      index;
    // While there are elements in the array
    while (ctr > 0) {
      // Pick a random index
      index = Math.floor(Math.random() * ctr);
      // Decrease ctr by 1
      ctr--;
      // And swap the last element with it
      temp = arra1[ctr];
      arra1[ctr] = arra1[index];
      arra1[index] = temp;
    }
    return arra1;
};

const Work = (props) => {
  const { dispatch, state } = useNavContext()

  const [isFromLanding, setIsFromLanding] = useState(false)
  
  const { search: searchParams } = useLocation();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if(state.prevPath === '/'){
      setIsFromLanding(true)
      dispatch({ type: 'SET_PREV_PATH', payload: ''})
    }
  /* eslint-disable-next-line */
  },[])

  useEffect(() => {
    let OR;
    const { keyword } = qs.parse(searchParams);
    if (keyword) {
      OR = [];
      OR.push({ title_contains: keyword  });
      OR.push({ tags_contains: keyword })
    }
    client
      .query({
        query: PUBLIC_PROJECTS_QUERY,
        variables: {
          where: {
            published: true,
            createdAt_lte: '2020-12-31',
            OR,
          },
        },
        fetchPolicy: 'no-cache'
      })
      .then(({ data }) => {
        const randProjects = shuffleArr(data.projects);
        setProjects(randProjects);
      });
  }, [searchParams]);

  if(props.location.search.includes("type=")){
    const searchType = props.location.search.split("type=")[1];
      return (
        <div>
          <Center>
            <Title>
              <div>
                <p>GRADUATE PROJECTS</p>
              </div>
              <div className="dropdowns">
                <Dropdown>
                  <div className="dropdown">
                    <button className="dropbtn">Program</button>
                    <div className="dropdown-content">
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?program=Graphic%20Design",
                        }}
                      >
                        Graphic Design
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?program=Interaction%20Design",
                        }}
                      >
                        Interaction Design
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?program=Game%20-%20Art",
                        }}
                      >
                        Game - Art
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?program=Art%20%26%20Design%20Foundation",
                        }}
                      >
                        Art & Design Foundation
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?program=Interactive%20Media%20Management",
                        }}
                      >
                        Interactive Media Management
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search:
                            "?program=Digital%20Design%20-%20Game%20Design",
                        }}
                      >
                        Digital Design - Game Design
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search:
                            "?program=Concept%20Art%20for%20Entertainment",
                        }}
                      >
                        Concept Art for Entertainment
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?program=Design%20Management",
                        }}
                      >
                        Design Management
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search:
                            "?program=Interdisciplinary%20Design%20Strategy",
                        }}
                      >
                        Interdisciplinary Design Strategy
                      </Link>
                    </div>
                  </div>
                </Dropdown>
                <Dropdown>
                  <div className="dropdown">
                    <button className="dropbtn">Project Category</button>
                    <div className="dropdown-content">
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=3d_design",
                        }}
                      >
                        <div className="marqueeContainer">3D Design</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=3d_sculpting",
                        }}
                      >
                        <div className="marqueeContainer">3D Sculpting</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=3d_visualizations",
                        }}
                      >
                        <div className="marqueeContainer">
                          3D Visualizations
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=advertising",
                        }}
                      >
                        <div className="marqueeContainer">Advertising</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=animation",
                        }}
                      >
                        <div className="marqueeContainer">Animation</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=augmented_virtual_reality",
                        }}
                      >
                        <div className="marqueeContainer">
                          Augmented/Virtual Reality
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=branding",
                        }}
                      >
                        <div className="marqueeContainer">Branding</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=digital_applications",
                        }}
                      >
                        <div className="marqueeContainer">
                          Digital Applications
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=drawing",
                        }}
                      >
                        <div className="marqueeContainer">Drawing</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=editorial",
                        }}
                      >
                        <div className="marqueeContainer">Editorial</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=exhibition",
                        }}
                      >
                        <div className="marqueeContainer">Exhibition</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=illustration",
                        }}
                      >
                        <div className="marqueeContainer">Illustration</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=immersive_environments",
                        }}
                      >
                        <div className="marqueeContainer">
                          Immersive Environments
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=interactive_design",
                        }}
                      >
                        <div className="marqueeContainer">
                          Interactive Design
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=interface_development",
                        }}
                      >
                        <div className="marqueeContainer">
                          Interface Development
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=media_production",
                        }}
                      >
                        <div className="marqueeContainer">Media Production</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=modeling",
                        }}
                      >
                        <div className="marqueeContainer">Modeling</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=motion_graphics",
                        }}
                      >
                        <div className="marqueeContainer">Motion Graphics</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=packaging",
                        }}
                      >
                        <div className="marqueeContainer">Packaging</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=photography",
                        }}
                      >
                        <div className="marqueeContainer">Photography</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=physical_computing",
                        }}
                      >
                        <div className="marqueeContainer">
                          Physical Computing
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=strategy",
                        }}
                      >
                        <div className="marqueeContainer">Strategy</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=systems_and_services",
                        }}
                      >
                        <div className="marqueeContainer">
                          Systems and Services
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=technological_systems",
                        }}
                      >
                        <div className="marqueeContainer">
                          Technological Systems
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=user_experience",
                        }}
                      >
                        <div className="marqueeContainer">User Experience</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=video",
                        }}
                      >
                        <div className="marqueeContainer">Video</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=web_design",
                        }}
                      >
                        <div className="marqueeContainer">Web Design</div>
                      </Link>
                    </div>
                  </div>
                </Dropdown>
              </div>
            </Title>
            <ProjectsList>
              {projects.length > 0 ? projects.map((project) => {
                if (
                  project.primaryProjectType === searchType ||
                  project.secondaryProjectType === searchType ||
                  project.secondaryProjectType_2 === searchType
                ) {
                  return <Project project={project} key={project.id} />;
                }
                return null
              }): <div>No results found</div>}
            </ProjectsList>
          </Center>
        </div>
      );
  }
  if (props.location.search.includes("program=")) {
    let projectsToList = [];
    const searchProgram = props.location.search.split("program=")[1];
    console.log('🚀 ~ Work ~ searchProgram', searchProgram)
      return (
        <div>
          <Center>
            <Title>
              <div>
                <p>GRADUATE PROJECTS</p>
              </div>
              <div className="dropdowns">
                <Dropdown>
                  <div className="dropdown">
                    <button className="dropbtn">Program</button>
                    <div className="dropdown-content">
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?program=Graphic%20Design",
                        }}
                      >
                        Graphic Design
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?program=Interaction%20Design",
                        }}
                      >
                        Interaction Design
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?program=Game%20-%20Art",
                        }}
                      >
                        Game - Art
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?program=Art%20%26%20Design%20Foundation",
                        }}
                      >
                        Art & Design Foundation
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?program=Interactive%20Media%20Management",
                        }}
                      >
                        Interactive Media Management
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search:
                            "?program=Digital%20Design%20-%20Game%20Design",
                        }}
                      >
                        Digital Design - Game Design
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search:
                            "?program=Concept%20Art%20for%20Entertainment",
                        }}
                      >
                        Concept Art for Entertainment
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?program=Design%20Management",
                        }}
                      >
                        Design Management
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search:
                            "?program=Interdisciplinary%20Design%20Strategy",
                        }}
                      >
                        Interdisciplinary Design Strategy
                      </Link>
                    </div>
                  </div>
                </Dropdown>
                <Dropdown>
                  <div className="dropdown">
                    <button className="dropbtn">Project Category</button>
                    <div className="dropdown-content">
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=3d_design",
                        }}
                      >
                        <div className="marqueeContainer">3D Design</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=3d_sculpting",
                        }}
                      >
                        <div className="marqueeContainer">3D Sculpting</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=3d_visualizations",
                        }}
                      >
                        <div className="marqueeContainer">
                          3D Visualizations
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=advertising",
                        }}
                      >
                        <div className="marqueeContainer">Advertising</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=animation",
                        }}
                      >
                        <div className="marqueeContainer">Animation</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=augmented_virtual_reality",
                        }}
                      >
                        <div className="marqueeContainer">
                          Augmented/Virtual Reality
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=branding",
                        }}
                      >
                        <div className="marqueeContainer">Branding</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=digital_applications",
                        }}
                      >
                        <div className="marqueeContainer">
                          Digital Applications
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=drawing",
                        }}
                      >
                        <div className="marqueeContainer">Drawing</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=editorial",
                        }}
                      >
                        <div className="marqueeContainer">Editorial</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=exhibition",
                        }}
                      >
                        <div className="marqueeContainer">Exhibition</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=illustration",
                        }}
                      >
                        <div className="marqueeContainer">Illustration</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=immersive_environments",
                        }}
                      >
                        <div className="marqueeContainer">
                          Immersive Environments
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=interactive_design",
                        }}
                      >
                        <div className="marqueeContainer">
                          Interactive Design
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=interface_development",
                        }}
                      >
                        <div className="marqueeContainer">
                          Interface Development
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=media_production",
                        }}
                      >
                        <div className="marqueeContainer">Media Production</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=modeling",
                        }}
                      >
                        <div className="marqueeContainer">Modeling</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=motion_graphics",
                        }}
                      >
                        <div className="marqueeContainer">Motion Graphics</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=packaging",
                        }}
                      >
                        <div className="marqueeContainer">Packaging</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=photography",
                        }}
                      >
                        <div className="marqueeContainer">Photography</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=physical_computing",
                        }}
                      >
                        <div className="marqueeContainer">
                          Physical Computing
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=strategy",
                        }}
                      >
                        <div className="marqueeContainer">Strategy</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=systems_and_services",
                        }}
                      >
                        <div className="marqueeContainer">
                          Systems and Services
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=technological_systems",
                        }}
                      >
                        <div className="marqueeContainer">
                          Technological Systems
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=user_experience",
                        }}
                      >
                        <div className="marqueeContainer">User Experience</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=video",
                        }}
                      >
                        <div className="marqueeContainer">Video</div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/2020/work",
                          search: "?type=web_design",
                        }}
                      >
                        <div className="marqueeContainer">Web Design</div>
                      </Link>
                    </div>
                  </div>
                </Dropdown>
              </div>
            </Title>
            <ProjectsList>
              {projects.length > 0 ? projects.map((project) =>
                project.members.map((member) => {
                  if (
                    member.user.program === decodeURIComponent(searchProgram) &&
                    !projectsToList.includes(project.id)
                  ) {
                    projectsToList.push(project.id);
                    return <Project project={project} key={project.id} />;
                  }
                  return null
                })
              ) : <div>No results found</div>}
            </ProjectsList>
          </Center>
        </div>
      );
  }
  return (
    <div>
      {isFromLanding && <Confetti /> } 
      <Center>
        <Title>
          <div>
            <p>GRADUATE PROJECTS</p>
          </div>
          <div className="dropdowns">
            <Dropdown>
              <div className="dropdown">
                <button className="dropbtn">Program</button>
                <div className="dropdown-content">
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?program=Graphic%20Design",
                    }}
                  >
                    Graphic Design
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?program=Interaction%20Design",
                    }}
                  >
                    Interaction Design
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?program=Game%20-%20Art",
                    }}
                  >
                    Game - Art
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?program=Art%20%26%20Design%20Foundation",
                    }}
                  >
                    Art & Design Foundation
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?program=Interactive%20Media%20Management",
                    }}
                  >
                    Interactive Media Management
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?program=Digital%20Design%20-%20Game%20Design",
                    }}
                  >
                    Digital Design - Game Design
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?program=Concept%20Art%20for%20Entertainment",
                    }}
                  >
                    Concept Art for Entertainment
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?program=Design%20Management",
                    }}
                  >
                    Design Management
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?program=Interdisciplinary%20Design%20Strategy",
                    }}
                  >
                    Interdisciplinary Design Strategy
                  </Link>
                </div>
              </div>
            </Dropdown>
            <Dropdown>
              <div className="dropdown">
                <button className="dropbtn">Project Category</button>
                <div className="dropdown-content">
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=3d_design",
                    }}
                  >
                    <div className="marqueeContainer">3D Design</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=3d_sculpting",
                    }}
                  >
                    <div className="marqueeContainer">3D Sculpting</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=3d_visualizations",
                    }}
                  >
                    <div className="marqueeContainer">3D Visualizations</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=advertising",
                    }}
                  >
                    <div className="marqueeContainer">Advertising</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=animation",
                    }}
                  >
                    <div className="marqueeContainer">Animation</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=augmented_virtual_reality",
                    }}
                  >
                    <div className="marqueeContainer">
                      Augmented/Virtual Reality
                    </div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=branding",
                    }}
                  >
                    <div className="marqueeContainer">Branding</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=digital_applications",
                    }}
                  >
                    <div className="marqueeContainer">Digital Applications</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=drawing",
                    }}
                  >
                    <div className="marqueeContainer">Drawing</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=editorial",
                    }}
                  >
                    <div className="marqueeContainer">Editorial</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=exhibition",
                    }}
                  >
                    <div className="marqueeContainer">Exhibition</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=illustration",
                    }}
                  >
                    <div className="marqueeContainer">Illustration</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=immersive_environments",
                    }}
                  >
                    <div className="marqueeContainer">
                      Immersive Environments
                    </div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=interactive_design",
                    }}
                  >
                    <div className="marqueeContainer">Interactive Design</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=interface_development",
                    }}
                  >
                    <div className="marqueeContainer">
                      Interface Development
                    </div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=media_production",
                    }}
                  >
                    <div className="marqueeContainer">Media Production</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=modeling",
                    }}
                  >
                    <div className="marqueeContainer">Modeling</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=motion_graphics",
                    }}
                  >
                    <div className="marqueeContainer">Motion Graphics</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=packaging",
                    }}
                  >
                    <div className="marqueeContainer">Packaging</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=photography",
                    }}
                  >
                    <div className="marqueeContainer">Photography</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=physical_computing",
                    }}
                  >
                    <div className="marqueeContainer">Physical Computing</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=strategy",
                    }}
                  >
                    <div className="marqueeContainer">Strategy</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=systems_and_services",
                    }}
                  >
                    <div className="marqueeContainer">Systems and Services</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=technological_systems",
                    }}
                  >
                    <div className="marqueeContainer">
                      Technological Systems
                    </div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=user_experience",
                    }}
                  >
                    <div className="marqueeContainer">User Experience</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=video",
                    }}
                  >
                    <div className="marqueeContainer">Video</div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/2020/work",
                      search: "?type=web_design",
                    }}
                  >
                    <div className="marqueeContainer">Web Design</div>
                  </Link>
                </div>
              </div>
            </Dropdown>
          </div>
        </Title>
        <ProjectsList>
          {projects.length > 0 ? projects.map((project) => (
            <Project project={project} key={project.id} />
          )) : <div>No results found</div>}
        </ProjectsList>
      </Center>
    </div>
  );
};

export default Work;
