import React from 'react';
import SingleProject from "../../components/2020/SingleProject";

const Project = props => (
  <div>
    <SingleProject id={props.location.search.split("id=")[1]} />
  </div>
);

export default Project;
