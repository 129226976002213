import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import cn from 'classnames'

const StickersWrapper = styled.div`
  top: ${(props) => props.top};
  left: ${(props) => props.imgLeft || 28}px;
  min-width: 160px;
  min-height: 480px;

`
const StickerContainer = styled.div`
  top: ${(props) => props.top};
  left: ${(props) => props.imgLeft || 28}px;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'inherit')};
  img {
    width: ${(props) => props.imgWidth || 84}px;
  }
  p {
    position: absolute;
    top: calc(${(props) => props.top} + 16px);
    font-family: Reckless;
    transform: rotate(${(props) => props.textRotate}deg);
  }
  z-index:1;
`
const TextLogo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 8.5rem;
  line-height: 9rem;
  letter-spacing: 0.01em;
  font-weight: 400;
`

export default function StickerNavs({ className, withTop = true }) {
  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])

  const navigate = useCallback(
    (url) => {
      if (url !== '/' && pathname?.startsWith(url)) {
        return
      }

      const leftLayer = document.getElementsByClassName('left-layer')?.[0]
      const rightLayer = document.getElementsByClassName('left-layer')?.[0]
      if (leftLayer && rightLayer) {
        leftLayer.classList.add('active')
        rightLayer.classList.add('active')
        setTimeout(() => history.push(url), 1500)
      }
    },
    [history, pathname],
  )

  const isWork = useMemo(() => {
    if (pathname?.startsWith('/2021/work')) {
      return true
    }
    return false
  }, [pathname])

  const isGrads = useMemo(() => {
    if (pathname?.startsWith('/2021/grads')) {
      return true
    }
    return false
  }, [pathname])

  const isEvents = useMemo(() => {
    if (pathname?.startsWith('/2021/events')) {
      return true
    }
    return false
  }, [pathname])

  const isShow= useMemo(() => {
    if (pathname?.startsWith('/2021/show')) {
      return true
    }
    return false
  }, [pathname])

  return (
    <div className={cn('', className)}>
      {withTop && (
        <TextLogo
          className='text-center px-6 text-secondary cursor-pointer uppercase'
          onClick={() => navigate('/2021')}
        >
          Yes!2021
        </TextLogo>
      )}
      <StickersWrapper className={cn({ 'mt-48': withTop })}>
        <StickerContainer
          pointer
          className='relative'
          textRotate={-35}
          onClick={() => navigate('/2021/work')}
        >
          <svg width={88} height={88} viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'>
            <circle fill={isWork ? '#333' : '#DEFC1C'} cx={55} cy={55} r={55} fillRule='evenodd' />
          </svg>
          <p
            className={cn('text-5xl text-blue absolute', {
              'text-secondary': isWork,
              'text-blue': !isWork,
            })}
            style={{
              top: 'calc(25% + 4px)',
              left: 10,
            }}
          >
            Work
          </p>
        </StickerContainer>
        <StickerContainer
          pointer
          imgWidth={124}
          className='relative -mt-4'
          textRotate={-15}
          onClick={() => navigate('/2021/grads')}
        >
          <svg width={128} height={87} viewBox='0 0 164 87' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M.203 71.712S60.29 13.564 113.301 1.427c51.212-11.726 71.525 64.233 21.237 79.456-26.56 8.04-68.41 8.844-134.335-9.171'
              fill={isGrads ? '#333' : '#DEFC1C'}
              fillRule='evenodd'
            />
          </svg>
          <p
            className={cn('text-5xl text-blue', {
              'text-secondary': isGrads,
              'text-blue': !isGrads,
            })}
            style={{
              top: 'calc(35% + 0px)',
              left: 42,
            }}
          >
            Grads
          </p>
        </StickerContainer>
        <StickerContainer
          pointer
          imgWidth={124}
          className='relative'
          textRotate={10}
          onClick={() => navigate('/2021/events')}>
          <svg
            width={124}
            viewBox="0 0 148 98"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill={isEvents ? '#333' : '#DEFC1C'}
              d="M.502 77.767L38.23 65.496l25.158 31.688 24.467-31.17 38.048 28.192 21.21-39.978-38.168-51.432-18.24 17.79L41.886 7.212l-1.237 19.714L15.489.399.503 77.767"
              fillRule="evenodd"
            />
          </svg>
          <p
            className={cn('text-5xl text-blue', {
              'text-secondary': isEvents,
              'text-blue': !isEvents,
            })}
            style={{
              top: 'calc(45% - 12px)',
              left: 24,
            }}
          >
            Events
          </p>
        </StickerContainer>
        <StickerContainer
          pointer
          imgWidth={94}
          className='relative'
          textRotate={10}
          onClick={() => navigate('/2021/show')}
        >
          <svg
            width={94}
            viewBox="0 0 118 89"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>{"nav-show"}</title>
            <path
              d="M.501 40.556C2.893 6.351 17.011-1.532 61.727 1.595c43.31 3.028 57.881 12.918 55.49 47.123-2.47 35.309-16.49 41.814-61.49 38.667C7.636 84.022-1.91 75.038.502 40.556z"
              fill={isShow ? '#333' : '#DEFC1C'}
              fillRule="evenodd"
            />
          </svg>
          <p
            className={cn('text-5xl text-blue', {
              'text-secondary': isShow,
              'text-blue': !isShow,
            })}
            style={{
              top: 'calc(56% - 16px)',
              left: 16,
            }}
          >
            Show
          </p>
        </StickerContainer>
      </StickersWrapper>
    </div>
  )
}
