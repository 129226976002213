import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { programGroups } from '../../../constant'
import { VscMenu } from 'react-icons/vsc'
import { useDisclosure } from 'react-use-disclosure'
import StickerNavs from '../StickerNavs'

const TextLogo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 8.5rem;
  line-height: 9rem;
  letter-spacing: 0.01em;
  font-weight: 400;
`
const LinkContainer = styled.div`
  a:first-child {
    border-top: 0;
  }
`

export default function WorkScrollNav({ className }) {
  const history = useHistory()
  const { pathname } = useLocation()
  const { isOpen, toggle } = useDisclosure()

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])

  const navigate = useCallback(
    (url) => {
      if (pathname?.startsWith(url)) {
        return
      }

      const leftLayer = document.getElementsByClassName('left-layer')?.[0]
      const rightLayer = document.getElementsByClassName('left-layer')?.[0]
      if (leftLayer && rightLayer) {
        leftLayer.classList.add('active')
        rightLayer.classList.add('active')
        setTimeout(() => history.push(url), 1500)
      }
    },
    [history, pathname],
  )

  const classNameLine =
    'block text-white bg-blue transition hover:text-blue border-b border-secondary border-dashed p-4 hover:bg-secondary leading-9'

  return (
    <div className={cn('', className)}>
      <TextLogo
        className='text-center px-6 text-secondary cursor-pointer uppercase'
        onClick={() => navigate('/')}
      >
        Yes!2021
      </TextLogo>
      <div
        className='border-t border-b border-secondary border-dashed py-4 text-center text-secondary hover:bg-secondary hover:text-blue transition cursor-pointer'
        role='button'
        onClick={toggle}
      >
        <VscMenu className='text-5xl text-center mx-auto my-2' />
      </div>
      {!isOpen ? (
        <LinkContainer className=''>
          {programGroups.map((prg) => (
            <Link
              to={{
                pathname,
                search: `?program=${prg}`,
              }}
              className={classNameLine}
              key={prg}
            >
              {prg}
            </Link>
          ))}
        </LinkContainer>
      ) : (
        <StickerNavs withTop={false} className={cn('mt-16')} />
      )}
    </div>
  )
}
