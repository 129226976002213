import React, { useEffect, useMemo, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { ProjectCard } from '../../../components/2024/ProjectCard'
import shuffle from 'lodash.shuffle'
import { useQuery } from '@apollo/client'
import { DropdownFilter } from '../../../components/2024/DropdownFilter'
import { programGroups24 as programGroups } from '../../../constant'
import { TextCenterDiv } from '../../../styles/2024/WorkStyles'
import { H1M, H3 } from '../../../styles/2024/TextStyles'
import { SearchContainer } from '../../../styles/2024/GradsStyles'
import { P } from '../../../styles/2024/TextStyles'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { QUERY } from '../../../graphql'
import { SpringContext } from '../../../context/SpringContext'
import { useWindowSize } from '../../../hook/2024/hooks/useWindowSize'

const Header = React.lazy(() => import('../../../components/2024/Header'))
const Countdown = React.lazy(() => import('../../../components/2024/Countdown'))
const Footer = React.lazy(() => import('../../../components/2024/Footer'))
const FullScreenLoader = React.lazy(() => import('../../../components/2024/FullScreenLoader'))

export default function Index(props) {
  const location = useLocation()
  const { search: searchParams } = location
  const { keyword, program, type, page } = qs.parse(searchParams)
  const [gradsSearch, setGradsSearch] = useState()
  const spring = useContext(SpringContext)
  const { screen } = useWindowSize()
  const isMobile = screen === 'sm'

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring])

  const { data, loading } = useQuery(QUERY.PUBLIC_PROJECTS_QUERY, {
    variables: {
      where: {
        published: true,
        createdAt_gt: '2023-12-31',
        createdAt_lt: '2025-01-01',
      },
      gradsSearch,
    },
  })

  const groupedProjects = useMemo(() => {
    if (!data?.projects) {
      return []
    }
    // Filter projects once based on the optional program and type filters.
    const filteredProjects = data.projects.filter((project) => {
      const isProgramMatch =
        !program || project.members?.some((member) => member.user.program === program)
      const isTypeMatch =
        !type ||
        [
          project.primaryProjectType,
          project.secondaryProjectType,
          project.secondaryProjectType_2,
        ].some((t) => t?.toLowerCase() === type.toLowerCase())
      return isProgramMatch && isTypeMatch
    })

    // Object to hold grouped projects
    const projectsObj = {}
    const seenProjectIds = new Set()

    programGroups.forEach((key) => {
      projectsObj[key] = [] // Initialize an empty array for each group
      filteredProjects.forEach((project) => {
        if (project.members?.some((member) => member.user.program === key)) {
          if (!seenProjectIds.has(project.id)) {
            projectsObj[key].push(project) // Add project to the current group
            seenProjectIds.add(project.id) // Mark this project id as seen
          }
        }
      })
    })

    let groupedProjects = []
    Object.keys(projectsObj).map(
      (key) =>
        projectsObj[key]?.length > 0 &&
        projectsObj[key].map((project) => {
          return groupedProjects.push(project)
        }),
    )

    groupedProjects = shuffle(groupedProjects)
    // return data.projects
    return groupedProjects
  }, [data, program, type])

  require('./work.scss')

  return (
    <div className='absolute w-full bg-almost-black flex flex-col items-center justify-start pb-20 lg:pb-0'>

      {loading && <FullScreenLoader />}
      {Countdown && <Countdown />}
      {Header && <Header />}
      <div className='relative px-[16px] lg:px-[48px] max-w-[1440px] pb-16'>
        <div className='w-full xl:w-full '>
          <TextCenterDiv>
            <H1M className='text-comfort-green lg:!text-[72px]' style={{ pointerEvents: 'none' }}>
              Work
            </H1M>
            {isMobile ? (
              <div className='text-center pt-[16px]'>
                <H3 className='text-beige-yellow normal-case m-0' style={{ pointerEvents: 'none' }}>
                  YES! We’re bringing
                </H3>
                <H3 className='text-beige-yellow normal-case m-0' style={{ pointerEvents: 'none' }}>
                  ideas to life!
                </H3>
              </div>
            ) : (
              <H3 className='text-beige-yellow normal-case' style={{ pointerEvents: 'none' }}>
                YES! We’re bringing ideas to life!
              </H3>
            )}
          </TextCenterDiv>
          <div className='flex gap-6'>
            <DropdownFilter program={program} type={type} onChangeSearchGrads={setGradsSearch} />
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-2 w-full my-16 items-center'>
            {groupedProjects?.length === 0 ? (
              <P className='text-beige-yellow'>No projects found.</P>
            ) : groupedProjects?.length === 1 ? (
              <P className='text-beige-yellow'>
                There is <span className='text-comfort-green'>1</span> project.
              </P>
            ) : groupedProjects?.length > 1 ? (
              <P className='text-beige-yellow'>
                There are <span className='text-comfort-green'>{groupedProjects?.length}</span>{' '}
                projects.
              </P>
            ) : (
              <P className='text-beige-yellow'>Loading</P>
            )}
            <SearchContainer className='flex relative w-full items-end justify-end'>
              <input
                type='text'
                id='gname'
                name='gname'
                placeholder='Search projects or graduates'
                className='text-almost-black bg-beige-yellow placeholder-almost-black border border-almost-black w-full lg:w-4/5 '
                onChange={(e) => setGradsSearch(e.target.value)}
              />
              <img className='absolute' src='/static/2024/svg/search.svg' alt='Search' />
            </SearchContainer>
          </div>
          <TransitionGroup className='grid grid-cols-1 lg:grid-cols-3 gap-16 pt-10'>
            {groupedProjects?.length > 0 &&
              groupedProjects.map((project) => (
                <CSSTransition key={project.id} timeout={500} classNames='item'>
                  <ProjectCard project={project} key={project.id} />
                </CSSTransition>
              ))}
          </TransitionGroup>
          {/* {totalPages > 1 && (
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              itemsPerPage={ITEMS_PER_PAGE}
              onPageChange={handlePageChange}
            />
          )} */}
        </div>
      </div>

      <div className='relative w-full bg-almost-black flex flex-col items-center justify-center w-full'>
        <div className='relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] bg-almost-black max-w-[1440px]'>
          {Footer && <Footer />}
        </div>
      </div>
    </div>

  )
}
