import React, { useCallback } from 'react'
import styled from 'styled-components'
import {TextLogo} from '../styles/2022/HomeScrollStyles'
import { useHistory, useLocation } from 'react-router-dom'

const NotFoundCont = styled.div`
  background: url("/static/404_backgound.png") no-repeat center center
    fixed;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
`;

const Text = styled.div`
  font-weight: 404;
  font-size: 120px;
  font-family: "sharp_grotesque";
  position: absolute;
  top: 48%;
  left: 48%;
  transform: translate(-50%, -50%);
`;



export default function NotFound() {

  const history = useHistory()
  const { pathname } = useLocation()

  const navigate = useCallback(
    (url) => {
    if (pathname?.startsWith(url)) {
        return
    }
      history.push(url)
    },
    [history, pathname],
)
  return (
    <NotFoundCont>
      <div className='absolute top-0 w-full  h-full flex justify-between pl-10'>
          <div className='absolute z-10 inline-block cursor-pointer' onClick={() => navigate('/home')}>
              <TextLogo
              className='text-red-coral uppercase'
              onClick={() => navigate('/home')}
              >
              Yes!2022
              </TextLogo>
          </div>
      </div>
      <Text>404!</Text>
    </NotFoundCont>
  )
}
