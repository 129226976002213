import { CURRENT_USER_QUERY } from './User'
import Signin from './Signin'
import React from 'react'
import Loading from './Loading'
import { useQuery } from '@apollo/client'

const PleaseSignIn = (props) => {
  const { data, loading } = useQuery(CURRENT_USER_QUERY)

  if (loading) return <Loading />
  if (!data.me) {
    return (
      <div>
        <p>Please Sign In before Continuing</p>
        <Signin />
      </div>
    )
  }
  return props.children
}

export default PleaseSignIn
