import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Title from "./styles/Title";
import UserProjectStyles from "./styles/UserProjectStyles";
import CustomSelect from "./styles/CustomSelect";
import DeleteProject from "./DeleteProject";
import { client } from "../../index";
import { UPDATE_PROJECT_MUTATION } from "./UpdateProject";

export default class UserProject extends Component {
  static propTypes = {
    project: PropTypes.object.isRequired
  };

  handleChangePublished = async (event) => {
    const { id,  contentType , contentData } = this.props.project
    const { value } = event.target
    const isTrueSet = (value === 'true');
    try {
      await client.mutate({
        mutation: UPDATE_PROJECT_MUTATION,
        variables: {
          id,
          published: isTrueSet,
          members: [],
          contentType,
          contentData
        }
      })
    } catch (error) {
      console.log('error: ',error)
    }
    return true;
  }

  render() {
    const { project } = this.props;
    const primaryProjectType = project.primaryProjectType;
    let ppt = primaryProjectType.replace("_", " ");
    let splitStr = ppt.split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      if (splitStr[i].charAt(0) === "3") {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1).toUpperCase();
      }
    }
    const projectType = splitStr.join(' ');
    const styles = {
      float: 'right',
      textAlign: 'right',
      width: '70%',
      padding: 0,
    }
    return (
      <UserProjectStyles>
        {project.coverImage && (
          <img src={project.coverImage} alt={project.title} />
        )}
        <Title>
          <Link
            to={{
              pathname: "/project",
              search: `?id=${project.id}`
            }}
          >
            {project.title}
          </Link>
        </Title>
        <p style={styles}>{projectType}</p>
        <br /><br />
        <CustomSelect className="custom-select">
          <select onChange={this.handleChangePublished} defaultValue={project.published}>
            <option value={false}>PRIVATE</option>
            <option value={true}>PUBLISHED</option>
          </select>
        </CustomSelect>
        <div className="buttonList">
          <Link
            to={{
              pathname: "/update",
              search: `?id=${project.id}`
            }}
          >
            &nbsp;&nbsp;EDIT&nbsp;&nbsp;
          </Link>
          <div>
            <DeleteProject id={project.id}>DELETE</DeleteProject>
          </div>
        </div>
      </UserProjectStyles>
    );
  }
}
