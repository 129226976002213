import React, { useState } from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

const ConfettiComponent = () => {
  const [recycle, setRecycle] = useState(true)

  setInterval(() => {
    setRecycle(false)
  }, 10000)
  const { width, height } = useWindowSize()
  return (
    <Confetti
      width={width}
      height={height}
      style={{ zIndex: 5000, position: 'fixed' }}
      recycle={recycle}
    />
  )
}

export default ConfettiComponent
