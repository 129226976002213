import React, { useState, useEffect } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import cogoToast from 'cogo-toast'
import styled, { keyframes } from 'styled-components'
import { CURRENT_USER_QUERY } from './User'
import Loading from './Loading'
import Error from './ErrorMessage'
import { client } from '../../index'
import InputLimit, { LengthLimitTextarea } from './styles/InputLimit'
import SearchInput from './SearchInput'
import { QUERY } from '../../graphql'
import { Progress } from 'semantic-ui-react'
import { disciplines as projectType } from '../../constant'

const MAX_IMG_SIZE = 5 * 1024 * 1024
const MAX_VIDEO_SIZE = 50 * 1024 * 1024
const allowedImageExt = 'image/bmp, image/jpeg, image/png, image/gif'
const allowedVideoExt = 'video/*, .mkv, video/mp4,video/x-m4v'

const CreateProject = () => {
  const history = useHistory()
  const [project, setProject] = useState({
    title: '',
    primaryProjectType: '',
    secondaryProjectType: '',
    secondaryProjectType_2: '',
    contentType: { set: ['Text'] },
    contentData: { set: [] },
    coverImage: '',
    members: [],
    published: true,
  })
  const [memberInputs, setMemberInputs] = useState(['1'])
  const [contentInputs, setContentInputs] = useState(['0'])
  const [loadingStates, setLoadingStates] = useState({})

  const [uploadFinished, setUploadFinished] = useState(true)
  const [errorEmptyRole, setErrorEmptyRole] = useState(false)

  const {
    data: currentUser,
    loading: currentUserLoading,
    error: currentUserError,
  } = useQuery(CURRENT_USER_QUERY)

  const [createProject, { loading: createProjectLoading, error: createProjectError }] =
    useMutation(CREATE_PROJECT_MUTATION)

  const [uploadFile] = useMutation(USER_UPLOAD_FILE)
  const { data, loading } = useQuery(QUERY.USERS_QUERY, {
    variables: {
      where: {
        studentYear: 2024,
      },
    },
  })

  const appendMember = () => {
    var newMemberInput = `${memberInputs.length + 1}`
    setMemberInputs(memberInputs.concat([newMemberInput]))
    const studentProject = { ...project }
    studentProject.members = [...studentProject.members, { id: currentUser?.me.id, role: '' }]
    setProject(studentProject)
  }


  const appendContent = () => {
    const newContentInput = `${contentInputs.length}`
    const newContentInputs = contentInputs.concat([newContentInput])
    setContentInputs(newContentInputs)

    const _project = { ...project }
    _project.contentType.set.push('Text')
    _project.contentData.set.push('')
    setProject(_project)
  }

  const handlePublish = async (e) => {
    e.preventDefault()
    const valid = validateRole()
    if (!valid) {
      cogoToast.error('Role cannot be empty')
      return
    }

    const variables = { ...project }
    if (!variables.title) {
      cogoToast.error('Project title is required')
      return
    }
    if (!variables.coverImage) {
      cogoToast.error('Project cover image title is required')
      return
    }
    if (!variables.primaryProjectType) {
      cogoToast.error('Project primary type is required')
      return
    }

    if (!variables.contentData.set[0] || variables.contentData.set[0].length === 0) {
      cogoToast.error('Project description is required')
      return
    }

    variables.published = true

    const res = await client.mutate({
      mutation: CREATE_PROJECT_MUTATION,
      variables,
    })
    // change them to the single item page
    history.push({
      pathname: '/project',
      search: `?id=${res.data.createProject.id}`,
    })
    return true
  }

  const handlePreview = async () => {
    const valid = validateRole()
    if (!valid) {
      cogoToast.error('Role cannot be empty')
      return
    }

    const variables = { ...project }
    if (!variables.title) {
      cogoToast.error('Project title is required')
      return
    }
    if (!variables.coverImage) {
      cogoToast.error('Project cover image title is required')
      return
    }
    if (!variables.primaryProjectType) {
      cogoToast.error('Project primary type is required')
      return
    }

    if (!variables.contentData.set[0] || variables.contentData.set[0].length === 0) {
      cogoToast.error('Project description is required')
      return
    }

    variables.published = false

    const res = await client.mutate({
      mutation: CREATE_PROJECT_MUTATION,
      variables,
    })
    // change them to the single item page
    history.push({
      pathname: '/preview',
      search: `?id=${res.data.createProject.id}`,
    })
    return true
  }

  // Handle state and form input changes
  const handleChange = (e) => {
    const { name, value } = e.target
    setProject((prev) => ({ ...prev, [name]: value }))
  }

  const handleMemberSearch = (value, index) => {
    const _project = { ...project }
    const members = _project.members.map((mb, idx) => {
      if (index === idx) {
        return { ...mb, id: value }
      }
      return mb
    })
    setProject({ ...project, members })
  }

  const handleRemoveUserFromProject = async (member, index) => {
    const _project = { ...project }
    const members = _project.members.filter((_, idx) => idx !== index)
    setProject({ ...project, members })
    const _memberInputs = [ ...memberInputs ]
    _memberInputs.splice(index, 1)
    setMemberInputs(_memberInputs)
  }

  const handleRole = (e, index) => {
    const { value } = e.target
    const _project = { ...project }
    const members = _project.members.map((mb, idx) => {
      if (idx === index) {
        return { ...mb, role: value }
      }
      return mb
    })
    setProject({ ...project, members })
  }

  const handleContentType = (e) => {
    const { name, value } = e.target
    const [, index] = name.split('contentType_')

    const _project = { ...project }

    _project.contentType.set.splice(parseInt(index), 1, value)
    _project.contentData.set.splice(parseInt(index), 1, '')

    setProject(_project)
  }

  const handleContentData = (e) => {
    const { name, value } = e.target
    /* eslint-disable-next-line */
    const [, index] = name.split('contentData_')
    const _project = { ...project }

    _project.contentData.set.splice(parseInt(index), 1, value)

    setProject(_project)
  }

  const handleDescription = (e) => {
    const { name, value } = e.target
    /* eslint-disable-next-line */
    const [, index] = name.split('contentData_')
    const _project = { ...project }
    _project.contentData.set.splice(0, 1, value)
    setProject(_project)
  }

  const handleFileUpload = async (e) => {
    const files = e.target.files
    if (!files.length) {
      return // Exit if no file is selected
    }

    const file = files[0]
    // console.log(file);
    if (file.size > MAX_IMG_SIZE) {
      alert('Maximum image size is 5MB')
      e.target.value = '' // Reset the input for consistent behavior
      return
    }

    setUploadFinished(false)
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async (event) => {
      if (reader.error) {
        console.error('FileReader error:', reader.error)
      } else if (event.total > MAX_IMG_SIZE) {
        alert('Maximum image size is 5MB')
        return
      } else {
        try {
          uploadFile({ variables: { file } }).then(({ data }) => {
            const coverImage = data.singleUpload.path
            setProject((prevState) => ({
              ...prevState,
              coverImage,
            }))
            setUploadFinished(true)
          })
        } catch (error) {
          console.error('Error uploading file:', error)
        }
        event.target.value = '' // Optionally reset the input after processing
      }

      reader.onerror = (error) => {
        console.error('Error reading file:', error)
        setUploadFinished(true)
      }
    }
  }

  const uploadContentFile = async (e) => {
    const { id, name } = e.target
    setUploadFinished(false)

    const files = e.target.files
    const file = files[0]
    const [media, index] = id.split('_')
    const isVideo = media === 'video'

    let interval
    const keyState = `contentType_${index}_loading`
    if (isVideo) {
      interval = fakeLoading(keyState)
    }
    const size = isVideo ? MAX_VIDEO_SIZE : MAX_IMG_SIZE
    if (file.size > size) {
      alert(`Maximum ${isVideo ? 'video' : 'image'} size is ${isVideo ? '50 MB' : '5 MB'}`)
      setUploadFinished(true)
      clearInterval(interval)
      e.target.value = null
      return
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async (event) => {
        if (event.total > size) {
          alert(`Maximum ${isVideo ? 'video' : 'image'} size is ${isVideo ? '50 MB' : '5 MB'}`)
          setUploadFinished(true)
          clearInterval(interval)
          return
        }
        uploadFile({ variables: { file, isVideo } }).then(({ data }) => {
          clearInterval(interval)
          const path = data.singleUpload.path
          /* eslint-disable-next-line */
          const [, index] = name.split('contentData_')
          const _contentType = isVideo ? 'Video' : 'Image'
          console.log('_contentType', _contentType)
          const _project = { ...project }
          if (_project.contentType.set.length <= parseInt(index)) {
            _project.contentType.set.push(_contentType)
            _project.contentData.set.push(path)
          } else {
            _project.contentType.set.splice(parseInt(index), 1, _contentType)
            _project.contentData.set.splice(parseInt(index), 1, path)
          }
          setProject(_project)
          setUploadFinished(true)
        })
      }
    }
  }

  const fakeLoading = (keyState) => {
    const percent = Math.ceil(Math.random() * 7) * 10
    const interval = setInterval(() => {
      setLoadingStates((prevStates) => {
        const currentPercent = prevStates[keyState] || 0
        return {
          ...prevStates,
          [keyState]: currentPercent < 90 ? currentPercent + percent : 10,
        }
      })
    }, 500)
    return interval
  }


  const validateRole = () => {
    const members = [...project.members]
    if (members.length === 0) {
      setErrorEmptyRole(true)
      return false
    }
    for (const member of members) {
      if (!member.role || member.role.length === 0) {
        setErrorEmptyRole(true)
        return false
      }
    }
    return true
  }

  const handleRemoveContent = (index) => {
    const _project = { ...project }
    const setType = _project.contentType.set.filter((type, idx) => idx !== index)
    const setData = _project.contentData.set.filter((type, idx) => idx !== index)
    setProject({
      ...project,
      contentType: { set: setType },
      contentData: { set: setData },
    })
  }

  currentUserLoading && <Loading />
  currentUserError && <p>Error: {currentUserError.message}</p>

  createProjectError && <Error error={createProjectError} />

  useEffect(() => {
    if (currentUser && currentUser?.me && !currentUserLoading && !currentUserError && project?.members.length === 0) {
      const studentProject = { ...project }
      console.log('currentUser', currentUser)
      studentProject.members.push({ id: currentUser?.me?.id, role: 'Lead' })
      setProject(studentProject)

  }}, [currentUser, currentUserLoading, currentUserError, project])

  return (
    <ProjectForm onSubmit={(e) => handlePublish(e)} >
      <fieldset disabled={loading} aria-busy={loading}>
        <GridOne>
          <Grid>
            <div>
              <HelveticaTitle>Project Information</HelveticaTitle>
              <label htmlFor='title'>
                Project Title
                <InputLimit
                  type='text'
                  id='title'
                  name='title'
                  placeholder='Title'
                  required
                  maxLength={55}
                  defaultValue={project.title}
                  onChange={handleChange}
                  fromtop={44}
                />
              </label>
              {project?.members.map((member, index) => {
                const { role, id } = member
                const user = data?.users?.find((usr) => usr.id === id)

                return (
                  <Columns key={`key_${index + 1}`}>
                    <label htmlFor={`member_${index + 1}`}>
                      Member
                      <SearchInput
                        users={data?.users?.map(
                          (usr) => !project.members.some((mb) => mb.id === usr.id) && usr,
                        )}
                        onChange={(value) => handleMemberSearch(value, index)}
                        value={ user && `${user?.firstName} ${user?.lastName}`}
                        disabled={index === 0}
                      />
                      {index !== 0 && (
                          <p
                            onClick={() => handleRemoveUserFromProject(member, index)}
                            style={{
                              fontSize: '18px',
                              color: '#000',
                              textDecoration: 'underline',
                            }}
                          >
                            Remove
                          </p>
                        )}
                    </label>
                    <label htmlFor={`role_${index + 1}`}>
                      Role
                      <input
                        type='text'
                        defaultValue={role}
                        onChange={(e) => handleRole(e, index)}
                      />
                    </label>
                  </Columns>
                )
              })}
              {errorEmptyRole && <ErrorText>Role cannot be empty</ErrorText>}
              <p
                onClick={() => appendMember()}
                style={{
                  fontSize: '18px',
                  color: '#000',
                  textDecoration: 'underline',
                }}
              >
                + Group Member
              </p>
            </div>
            <div>
              <label htmlFor='coverImage'>
                Cover Image
                <UploadBlock>
                  <input
                    type='file'
                    id='coverImage'
                    name='coverImage'
                    placeholder='Upload an image'
                    onChange={(e) => handleFileUpload(e)}
                    required
                    className='upload-input'
                    accept={allowedImageExt}
                  />
                  {!project?.coverImage ? (
                    <img className='upload-placeholder' src={'/static/drawing.svg'} alt='' />
                  ) : (
                    <CoverImage src={project?.coverImage} alt='Upload Preview' />
                  )}
                  <UploadIcon src='/static/edit.svg' alt='Change Image' />
                </UploadBlock>
              </label>
            </div>
          </Grid>
          <GridThree>
            <label htmlFor='primaryProjectType'>
              <HelveticaTitle>Primary Project Type</HelveticaTitle>
              <select
                id='primaryProjectType'
                name='primaryProjectType'
                defaultValue={''}
                onChange={(e) => handleChange(e)}
                required
              >
                <option disabled value={''}>
                  -- select a project type --
                </option>
                {Object.keys(projectType).map((key) => (
                  <option key={key} value={key}>
                    {projectType[key]}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor='secondaryProjectType'>
              <HelveticaTitle>Secondary Project Type</HelveticaTitle>
              <select
                id='secondaryProjectType'
                name='secondaryProjectType'
                defaultValue={''}
                onChange={(e) => handleChange(e)}
              >
                <option disabled value={''}>
                  -- select a project type --
                </option>
                {Object.keys(projectType).map((key) => (
                  <option key={key} value={key}>
                    {projectType[key]}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor='secondaryProjectType_2'>
              <HelveticaTitle>Secondary Project Type</HelveticaTitle>
              <select
                id='secondaryProjectType_2'
                name='secondaryProjectType_2'
                defaultValue={''}
                onChange={(e) => handleChange(e)}
              >
                <option disabled value={''}>
                  -- select a project type --
                </option>
                {Object.keys(projectType).map((key) => (
                  <option key={key} value={key}>
                    {projectType[key]}
                  </option>
                ))}
              </select>
            </label>
          </GridThree>

          <label htmlFor={`contentData_0`}>
            <HelveticaTitle>Short Description</HelveticaTitle>
            <LengthLimitTextarea
              type='text'
              id={`contentData_0`}
              name={`contentData_0`}
              maxLength={400}
              placeholder=''
              defaultValue={project?.contentData?.set[0]}
              onChange={(e) => handleDescription(e)}
            />
          </label>
          <HelveticaTitle>Content</HelveticaTitle>
          {contentInputs &&
            contentInputs?.length > 1 &&
            contentInputs?.slice(1).map((input) => (
              <GridOne key={`contentKey_${input}`}>
                <label htmlFor={`contentType_${input}`}>
                  <select
                    id={`contentType_${input}`}
                    name={`contentType_${input}`}
                    onChange={(e) => handleContentType(e)}
                    defaultValue={project?.contentType?.set[parseInt(input)]}
                    style={{ width: '24%' }}
                  >
                    <option disabled value={'DEFAULT'}>
                      -- select a content type --
                    </option>
                    <option value='Text'>Text</option>
                    <option value='Heading'>Heading</option>
                    <option value='Video'>Video</option>
                    <option value='Image'>Image</option>
                  </select>
                </label>
                {project?.contentType?.set[parseInt(input)] === 'Heading' && (
                  <label htmlFor={`contentData_${input}`}>
                    Heading
                    <ContentInput
                      type='text'
                      id={`contentData_${input}`}
                      name={`contentData_${input}`}
                      placeholder=''
                      defaultValue={project.contentData.set[parseInt(input)]}
                      onChange={(e) => handleContentData(e)}
                    />
                    <p
                      style={{
                        fontSize: '18px',
                        color: '#000',
                        textDecoration: 'underline',
                      }}
                      onClick={() => handleRemoveContent(parseInt(input))}
                    >
                      Remove
                    </p>
                  </label>
                )}
                {project?.contentType?.set[parseInt(input)] === 'Text' && (
                  <label htmlFor={`contentData_${input}`}>
                    Text
                    <ContentTextarea
                      type='text'
                      id={`contentData_${input}`}
                      name={`contentData_${input}`}
                      placeholder=''
                      defaultValue={project.contentData.set[parseInt(input)]}
                      onChange={(e) => handleContentData(e)}
                    />
                    <p
                      style={{
                        fontSize: '18px',
                        color: '#000',
                        textDecoration: 'underline',
                      }}
                      onClick={() => handleRemoveContent(parseInt(input))}
                    >
                      Remove
                    </p>
                  </label>
                )}
                {project?.contentType?.set[parseInt(input)] === 'Video' && (
                  <label htmlFor={`contentData_${input}`}>
                    Video
                    <ContentInput
                      type='file'
                      id={`video_${input}`}
                      name={`contentData_${input}`}
                      placeholder='Upload a video'
                      onChange={uploadContentFile}
                      accept={allowedVideoExt}
                    />
                    {[`contentType_${input}_loading`] > 0 && (
                      <Progress
                        percent={[`contentType_${input}_loading`]}
                        size='small'
                        color='green'
                      />
                    )}
                    <p
                      style={{
                        fontSize: '18px',
                        color: '#000',
                        textDecoration: 'underline',
                      }}
                      onClick={() => handleRemoveContent(parseInt(input))}
                    >
                      Remove
                    </p>
                  </label>
                )}
                {project?.contentType?.set[parseInt(input)] === 'Image' && (
                  <label htmlFor={`contentData_${input}`}>
                    Image
                    <ContentInput
                      type='file'
                      id={`image_${input}`}
                      name={`contentData_${input}`}
                      placeholder='Upload an image'
                      onChange={(e) => uploadContentFile(e)}
                      accept={allowedImageExt}
                    />
                    {[`contentType_${input}_loading`] > 0 && (
                      <Progress
                        percent={[`contentType_${input}_loading`]}
                        size='small'
                        color='green'
                      />
                    )}
                    <p
                      style={{
                        fontSize: '18px',
                        color: '#000',
                        textDecoration: 'underline',
                      }}
                      onClick={() => handleRemoveContent(parseInt(input))}
                    >
                      Remove
                    </p>
                  </label>
                )}
                {project.contentData.set.map((content, j) => {
                  if (content !== undefined && parseInt(j) === parseInt(input)) {
                    if (project?.contentType?.set[parseInt(input)] === 'Image') {
                      return <img src={content} alt='Upload Preview ' key={content} />
                    } else if (project?.contentType?.set[parseInt(input)] === 'Video') {
                      return (
                        <video key={j}>
                          <source type='video/mp4' src={content} key={content}></source>
                        </video>
                      )
                    }
                  }
                  return null
                })}
              </GridOne>
            ))}
          <p onClick={() => appendContent()}>+ Add Field</p>
          <GridButton>
            <div></div>
            <Button type='button' onClick={() => handlePreview()} isLoading={!uploadFinished}>
              SAVE/PREVIEW
            </Button>
            <Button type='submit' isLoading={!uploadFinished}>
              PUBLISH
            </Button>
          </GridButton>
        </GridOne>
      </fieldset>
    </ProjectForm>
  )
}

export default CreateProject

const CREATE_PROJECT_MUTATION = gql`
  mutation CREATE_PROJECT_MUTATION(
    $title: String!
    $primaryProjectType: String
    $secondaryProjectType: String
    $secondaryProjectType_2: String
    $contentType: ProjectCreatecontentTypeInput
    $contentData: ProjectCreatecontentDataInput
    $coverImage: String!
    $members: [CustomInput!]!
    $published: Boolean!
  ) {
    createProject(
      title: $title
      primaryProjectType: $primaryProjectType
      secondaryProjectType: $secondaryProjectType
      secondaryProjectType_2: $secondaryProjectType_2
      contentType: $contentType
      contentData: $contentData
      coverImage: $coverImage
      members: $members
      published: $published
    ) {
      id
    }
  }
`

const USER_UPLOAD_FILE = gql`
  mutation ($file: Upload!, $isVideo: Boolean) {
    singleUpload(file: $file, isVideo: $isVideo) {
      id
      path
      filename
    }
  }
`

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 20px;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 60px;
`
const GridOne = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  margin-top: 50px;
  video {
    max-width: 50%;
  }
`
const GridThree = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 0.5fr;
  grid-gap: 60px;
`
const GridButton = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.5fr;
  grid-gap: 60px;
`

const UploadBlock = styled.div`
  background-color: #e3e3e3;
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .upload-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(21.5em + 6.75rem + 2px);
    margin: 0;
    opacity: 0;
  }

  .upload-placeholder {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.5;
    z-index: 2;
  }
`

const UploadIcon = styled.img`
  background: rgb(243, 215, 222);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  height: 50px;
  width: 50px;
  padding: 10px;

  &:hover,
  &:focus {
    outline: none;
  }
`

const CoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`

const HelveticaTitle = styled.p`
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: black;
  font-size: 24px;
`
const Button = styled.button`
  ${(props) =>
    props.isLoading
      ? `
    opacity: .7;
    pointer-events: none;`
      : ''}
`
const ErrorText = styled.p`
  font-size: 16px;
  color: red;
`

const loading = keyframes`
  from {
    background-position: 0 0;
    /* rotate: 0; */
  }

  to {
    background-position: 100% 100%;
    /* rotate: 360deg; */
  }
`

const ProjectForm = styled.form`
  padding: 20px !important;
  font-size: 1.5rem !important;
  line-height: 1.5 !important;
  font-weight: normal !important;
  margin: 50px 5vw 0 !important;
  font-family: 'grotesque_pro_light' !important;
  color: #b5b5b5 !important;
  font-size: 24px !important;

  label {
    color: #b5b5b5 !important;
    display: block !important;
    font-weight: 300 !important;
    margin-bottom: 1rem !important;
  }

  input,
  textarea,
  select {
    height: 50px !important;
    width: 100% !important;
    padding: 0.5rem 1.5rem !important;
    font-size: 24px !important;
    font-weight: normal !important;
    color: #000000 !important;
    border: 1px solid #b5b5b5 !important;

    &::placeholder {
      color: #b5b5b5 !important;
      opacity: 1 !important;
    }

    &:focus {
      outline: 0 !important;
      border-color: ${(props) => props.theme.pink} !important;
    }
  }

  textarea {
    height: 200px !important;
  }

  button,
  input[type='submit'] {
    width: 100%;
    background: ${(props) => props.theme.pink} !important;
    color: ${(props) => props.theme.black} !important;
    border: 0 !important;
    font-family: 'grotesque_pro_light' !important;
    font-size: 2rem !important;
    font-weight: 600 !important;
    padding: 0.5rem 2.2rem !important;
    margin-top: 5rem !important;
    margin-bottom: 3rem !important;
    border-radius: 3rem !important;

    @media (min-width: 768px) {
      font-size: 2.4rem !important;
      font-weight: 300 !important;
      min-width: 180px !important;
      padding: 0.75rem 1.5rem !important;
      text-transform: uppercase !important;
    }
  }

  fieldset {
    border: 0 !important;
    padding: 0 !important;
    /*
    &[disabled] {
      opacity: 0.5;
    }
    &::before {
      height: 10px;
      content: "";
      display: block;
      background-image: linear-gradient(
        to right,
        #f7d8e0 0%,
        #000000 50%,
        #f7d8e0 100%
      );
    }
    &[aria-busy="true"]::before {
      background-size: 50% auto;
      animation: ${loading} 0.5s linear infinite;
    } */
  }
`
const ContentInput = styled.input`
  margin-bottom: 30px;
`

const ContentTextarea = styled.textarea`
  margin-bottom: 30px;
`
