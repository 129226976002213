import React from "react";
import styled from "styled-components";

const MovingImage = styled.img`
  width: 100%;
  position: relative;
`;

const MovingDiv = styled.div`
  transition: transform .01s;
  width: 1000px;
`;

class UnMove extends React.Component {

  render() {
    const {
      posx,
      posy,
      logoSrc,
      rotate,
    } = this.props;
    const styles = {
      position: "absolute",
      left: `${posx}vw`,
      top: `${posy}vh`,
    };
    const imgStyles = {
      transform: `rotate(${rotate}deg)`
    };
    return (
      <MovingDiv style={styles}>
        <MovingImage src={logoSrc} style={imgStyles} alt="logo" />
      </MovingDiv>
    );
  }
}

export default UnMove;
