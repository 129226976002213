import styled from "styled-components";

export const WorkBGTop = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  img {
    min-width: 1920px;
    width: 100vw;
    height: 1893px;
    overflow: hidden;
  }

  .bgMobile {
    display: none;
  }

  @media (max-width: 720px) {
    img {

    }

    .bgMobile {
      display: block;
    }

    .bgDesktop {
      display: none;
    }
  }
`

export const WorkBGBottom = styled.div`
  position: relative;
  bottom: 0;
  //overflow: hidden;
  width: 100%;
  left: 0;
  z-index: 9;

  img {
    min-width: 1920px;
    width: 100vw;
    height: 1680px;
    overflow: hidden;
  }

  .bgMobile {
    display: none;
  }

  @media (max-width: 720px) {
    img {

    }

    .bgMobile {
      display: block;
    }

    .bgDesktop {
      display: none;
    }
  }
`

export const TextCenterDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding: 120px 0 60px 0;
`

export const AlphabetBarDiv = styled.div`
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: auto;
  z-index: 70;
  background-color: #F0EEE9;

  .alphabet-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
  }

  .alphabet-btn {
    padding: 15px 15px;
    cursor: pointer;
    font-size: 22px;
    transition: background-color 0.2s;
  }

  .alphabet-btn:hover {
    color: #F0EEE9;
    background-color: #26439B;
  }
  .alphabet-btn.selected {
    color: #F0EEE9;
    background-color: #26439B;
}

`

export const FooterContainer = styled.section`
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`
