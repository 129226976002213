import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Title from "./styles/Title";
import ProfileProjectStyles from "./styles/ProfileProjectStyles";
import styled from 'styled-components';

const Types = styled.div`
  display: block;
  p {
    display: inline;
  }
`;
export default class Project extends Component {
  static propTypes = {
    project: PropTypes.object.isRequired
  };

  render() {
    const { project } = this.props;
    const primaryProjectType = project.primaryProjectType;
    let ppt = primaryProjectType.split("_").join(" ");
    let splitStr = ppt.split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      if (splitStr[i].charAt(0) === "3") {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1).toUpperCase();
      }
    }
    const projectTypeOne = splitStr.join(' ');

    const secondaryProjectType = project.secondaryProjectType;
    let pptX = secondaryProjectType.split("_").join(" ");
    let splitStrX = pptX.split(' ');
    for (let i = 0; i < splitStrX.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStrX[i] = splitStrX[i].charAt(0).toUpperCase() + splitStrX[i].substring(1);
      if (splitStrX[i].charAt(0) === "3") {
        splitStrX[i] = splitStrX[i].charAt(0).toUpperCase() + splitStrX[i].substring(1).toUpperCase();
      }
    }
    const projectTypeTwo = splitStrX.join(' ');

    const secondaryProjectTypeTwo = project.secondaryProjectType_2;
    let pptY = secondaryProjectTypeTwo.split("_").join(" ");
    let splitStrY = pptY.split(' ');
    for (let i = 0; i < splitStrY.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStrY[i] = splitStrY[i].charAt(0).toUpperCase() + splitStrY[i].substring(1);
      if (splitStrY[i].charAt(0) === "3") {
        splitStrY[i] = splitStrY[i].charAt(0).toUpperCase() + splitStrY[i].substring(1).toUpperCase();
      }
    }
    const projectTypeThree = splitStrY.join(' ');

    return (
      <ProfileProjectStyles>
        {project.coverImage && (
          <Link
            className="coverImgContainer"
            to={{
              pathname: "/2020/project",
              search: `?id=${project.id}`,
            }}
          >
            <img src={project.coverImage} alt={project.title} />
          </Link>
        )}
        <Title>
          <Link
            to={{
              pathname: "/2020/project",
              search: `?id=${project.id}`,
            }}
          >
            {project.title}
          </Link>
        </Title>
        <Types>
          {project.primaryProjectType !== "" && <p>{projectTypeOne}</p>}
          {project.secondaryProjectType !== "" && <p>{projectTypeTwo}</p>}
          {project.secondaryProjectType_2 !== "" && <p>{projectTypeThree}</p>}
        </Types>
      </ProfileProjectStyles>
    );
  }
}
