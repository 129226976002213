import { Dimmer, Loader } from "semantic-ui-react";
import React from 'react';

const LoaderExampleText = (props) => (
  <div>
    <Dimmer active inverted>
      {
      props.size 
      ? (<Loader size={props.size} inverted>Loading</Loader>) 
      : (<Loader inverted>Loading</Loader>)
      }

    </Dimmer>
  </div>
);

export default LoaderExampleText;
