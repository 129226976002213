
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useWindowSize } from '../../../hook'
import {TextLogo,TextCenter,StickersWrapper,StickerContainer, TitleWrapper, HeadContainer,FooterContainer} from '../../../styles/2022/HomeScrollStyles'
import {EventDiv} from '../../../styles/2022/EventsStyles'
import Footer from '../../../components/2022/Footer';
import cn from 'classnames'
import SearchIcon from '../../../components/2022/SearchIcon';
import FixedBottomNav from '../../../components/2022/FixedBottomNav'
import { MobileRightNavs, MobileNavsBottom } from '../MobileRightNavs'
import { useDisclosure } from 'react-use-disclosure'

const ENABLE_NAVIGATION = true

export default function EventsComp() {
    const [eventDay, setEventDay] = useState(1)
    const [dayOneClicked, setDayOneClicked] = useState(false)
    const [dayTwoClicked, setDayTwoClicked] = useState(false)
    const [dayThreeClicked, setDayThreeClicked] = useState(false)
    const [dayFourClicked, setDayFourClicked] = useState(false)

    const _isMounted = useRef(true);
    const { screen } = useWindowSize()
    const isMobile = screen === 'sm'

    const history = useHistory()
    const { pathname } = useLocation()


    const isWork = useMemo(() => {
        if (pathname?.startsWith('/2022/work')) {
          return true
        }
        return false
      }, [pathname])

    const isGrads = useMemo(() => {
        if (pathname?.startsWith('/2022/grads')) {
          return true
        }
        return false
      }, [pathname])

      const isEvents = useMemo(() => {
        if (pathname?.startsWith('/2022/events')) {
          return true
        }
        return false
      }, [pathname])

    const navigate = useCallback(
        (url) => {
        if (pathname?.startsWith(url)) {
            return
        }

        const leftLayer = document.getElementsByClassName('left-layer')?.[0]
        const rightLayer = document.getElementsByClassName('left-layer')?.[0]
        if (leftLayer && rightLayer) {
            leftLayer.classList.add('active')
            rightLayer.classList.add('active')
            setTimeout(() => history.push(url), 1500)
        }
        },
        [history, pathname],
    )
    const updateEventDay = useCallback(() => {
        // const lastScrollY = window.scrollY
        // console.log('updateEventDay -> lastScrollY', lastScrollY)
        let dayOne = document.getElementById('dayOne')
        let dayTwo = document.getElementById('dayTwo')
        let dayThree = document.getElementById('dayThree')
        let dayFour = document.getElementById('dayFour')
        let positionOne = dayOne?.getBoundingClientRect?.()
        let positionTwo = dayTwo?.getBoundingClientRect?.()
        let positionThree = dayThree?.getBoundingClientRect?.()
        let positionFour = dayFour?.getBoundingClientRect?.()
        // console.log('updateEventDay -> position', position)
        // checking whether fully visible
        if(dayOneClicked===false&&dayTwoClicked===false&&dayThreeClicked===false&&dayFourClicked===false){
            if (positionOne.top >= 0 && positionOne.bottom <= window.innerHeight) {
                    setEventDay(1);
            }
            else if (positionTwo.top >= 0 && positionTwo.bottom <= window.innerHeight) {
                    setEventDay(2);
            }
            else if (positionThree.top >= 0 && positionThree.bottom <= window.innerHeight) {
                    setEventDay(3);
            }
            else if (positionFour.top >= 0 && positionFour.bottom <= window.innerHeight) {
                    setEventDay(4);
            }
            }
        // checking for partial visibility
    //   if (position.top < window.innerHeight && position.bottom >= 0) {
    //     console.log('Element is partially visible in screen')
    //   }
    }, [dayOneClicked, dayTwoClicked, dayThreeClicked, dayFourClicked, setEventDay]);

    const scrollTo = (offset, eventDay) => {
        const fixedOffset = offset.toFixed();
        const onScroll = function () {
                if (window.pageYOffset.toFixed() === fixedOffset) {
                    window.removeEventListener('scroll', onScroll);
                    setDayOneClicked(false);
                    setDayTwoClicked(false);
                    setDayThreeClicked(false);
                    setDayFourClicked(false);
                    setEventDay(eventDay);
                }
            }

        window.addEventListener('scroll', onScroll)
        onScroll()
        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        window.addEventListener("scroll", updateEventDay);
        return () => {
          window.removeEventListener("scroll", updateEventDay);
          _isMounted.current = false;
        }
    }, [updateEventDay]);



    const onClickEventDay = (elementID, eventDay) => {
        let dayFour = document.getElementById(elementID);
        let newY = dayFour?.getBoundingClientRect?.()?.top-48 + window.scrollY;
        scrollTo(newY, eventDay);
    };


    useEffect(() => {
        // Dynamically import the CSS file
        import('../scroll.css').then(() => {
            console.log('CSS dynamically loaded!');
        }).catch(err => {
            console.error('Error loading the CSS file:', err);
        });
      const script = document.createElement('script')
      if (!window.expandEvent) {

        script.src = '/static/expandEvent.js'
        script.async = true
        script.type = 'text/javascript'
        document.body.appendChild(script)
      }

      // const script3= document.createElement('script')
      // script3.src = '/static/2022/js/scroll.js'
      // script3.async = true
      // script3.type = 'text/javascript'
      // document.body.appendChild(script3)

      const scroller = new SmoothScroll({
        // scrollBody: document.querySelector(".scroll-content"),
        // scrollSpacer: document.querySelector(".spacer"),
        target: document.querySelector('.scroll-container'), // element container to scroll
        scrollEase: 0.05,
      })

      return () => {
        if(document.body.contains(script)){
            document.body.removeChild(script)
            scroller._onDestroy()
        }

      }
    }, [])

    useEffect(() => {
      const leftLayer = document.getElementsByClassName('left-layer')?.[0]
      const rightLayer = document.getElementsByClassName('right-layer')?.[0]
      if (leftLayer && rightLayer) {
        let topWidth = window.innerWidth
        if (window.innerWidth < 600) {
          topWidth = 5840
        }
        leftLayer.style.borderTopWidth = `${topWidth}px`
        leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
        rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
        rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
        rightLayer.style.zIndex = 0
      }
    }, [])

    useEffect(() => {
        if (dayOneClicked) {
          onClickEventDay('dayOneT', 1);
        } else if (dayTwoClicked) {
            onClickEventDay('dayTwoT', 2);
        }else if (dayThreeClicked) {
            onClickEventDay('dayThreeT', 3);
        }else if (dayFourClicked) {
            onClickEventDay('dayFourT', 4);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dayOneClicked,dayTwoClicked,dayThreeClicked,dayFourClicked,]);

    const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()

  return (
    <>
        <div className="change_color_page"></div>
        <header></header>
        <div className="viewport">
            <div className="scroll-container">
                <div className="content" style={{background:'#e3e3de'}}>
                    <div id="top" className="single-item active" data-color="off_white" style={{background:'#e3e3de'}}>
                        <svg
                        width='2198px'
                        height='1347px'
                        viewBox='0 0 2198 1347'
                        version='1.1'
                        style={{ position: 'absolute', zIndex: 1, width: '100vw'}}
                        id="eventsCurvyBG"
                        >
                        <g
                            id='teaser-bg-xl'
                            stroke='none'
                            strokeWidth='1'
                            fill='none'
                            fillRule='evenodd'
                            style={{ position: 'absolute', width: '100vw', zIndex: 1 }}
                        >
                            <rect fill='#e3e3de' x='0' y='0' width='2198' height='1347'></rect>
                            <path
                            d='M-611,-35 L2738,-40 C2458.19686,301.117387 2218.7579,511.757842 2019.68312,591.921364 C1387.94312,846.313536 626.827196,516.489551 74.221014,1022.52081 C-213.850221,1286.31342 -1029,1869 -1212,1008 C-1334,434 -1133.66667,86.3333333 -611,-35 Z'
                            id='Fill-2'
                            stroke='#e3e3de'
                            fill='#191919'
                            ></path>
                        </g>
                        </svg>
                        <div className='absolute top-0 w-full h-full flex justify-between pl-10'>
                            <div className='absolute z-10 inline-block' onClick={() => navigate('/2022/home')}>
                                <TextLogo
                                className='text-red-coral uppercase'
                                onClick={() => navigate('/2022/home')}
                                >
                                Yes!2022
                                </TextLogo>
                            </div>
                        </div>
                        <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs} />
                        <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
                        <StickersWrapper
                        className={cn('z-10 fixed  w-96 fixed top-10 h-screen transition-opacity duration-700 ease-in-out hidden lg:block', { hidden: !ENABLE_NAVIGATION })}
                        top='210px'
                        imgLeft='0%'
                        >
                        <StickerContainer
                            pointer
                            className='relative w-max'
                            textRotate={-35}
                            onClick={() => navigate('/2022/work')}
                        >
                            <svg width={88} height={88} viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'>
                            <circle
                                fill={isWork ? '#191919' : '#ff6044'}
                                cx={55}
                                cy={55}
                                r={55}
                                fillRule='evenodd'
                                style={{display: "unset"}}
                            />
                            </svg>
                            <p
                            className={cn('text-5xl')}
                            style={{
                                top: 'calc(25% + 4px)',
                                left: 10,
                                color: isWork ? '#e3e3de' : '#191919'
                            }}
                            >
                            Work
                            </p>
                        </StickerContainer>
                        <StickerContainer
                            pointer
                            imgWidth={124}
                            className='relative -mt-4 w-max'
                            textRotate={-15}
                            onClick={() => navigate('/2022/grads')}
                        >
                            <svg width={128} height={87} viewBox='0 0 164 87' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M.203 71.712S60.29 13.564 113.301 1.427c51.212-11.726 71.525 64.233 21.237 79.456-26.56 8.04-68.41 8.844-134.335-9.171'
                                fill={isGrads ? '#191919' : '#ff6044'}
                                fillRule='evenodd'
                            />
                            </svg>
                            <p
                            className={cn('text-5xl')}
                            style={{
                                top: 'calc(35% + 0px)',
                                left: 42,
                                color: isGrads ? '#e3e3de' : '#191919'
                            }}
                            >
                            Grads
                            </p>
                        </StickerContainer>
                        <StickerContainer pointer imgWidth={124} className='relative w-max' textRotate={10} onClick={() => navigate('/2022/events')}>
                            <svg width="148px" height="98px" viewBox="0 0 148 98" version="1.1" >
                                <g id="nav-event" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <polyline id="Fill-1-Copy-4" fill={isEvents ? '#e3e3de' : '#191919' } transform="translate(75.290755, 49.038743) scale(1, -1) rotate(-95.000000) translate(-75.290755, -49.038743) " points="110.427584 -22.9612567 94.9150232 13.5527011 124.290755 41.3767876 91.1071351 63.0342095 115.875489 103.393913 74.2009868 121.038743 26.2907552 78.5340864 45.6037054 61.9137514 36.5333076 12.1151323 56.2801655 12.6013688 32.0477904 -14.7737882 110.427584 -22.9612567"></polyline>
                                </g>
                            </svg>
                            <p
                            className='text-5xl text-blue'
                            style={{
                                top: 'calc(45% - 12px)',
                                left: 24,
                                color: isGrads ? '#e3e3de' : '#191919'
                            }}
                            >
                            Events
                            </p>
                        </StickerContainer>
                        <StickerContainer pointer imgWidth={94} className='relative w-max' textRotate={10} onClick={() => window.open("https://instoregbc.com/", "_blank")}>
                            <img src='/static/svg/2022/nav-show.svg' alt='show-nav' />
                            <p
                            className='text-5xl text-blue'
                            style={{
                                top: 'calc(56% - 16px)',
                                left: 16,
                                color: isGrads ? '#e3e3de' : '#191919'
                            }}
                            >
                            Shop<span style={{fontSize:'20px'}}>&#8599;</span>
                            </p>
                        </StickerContainer>
                        </StickersWrapper>
                        <div className='absolute top-20 text-center w-full h-full xl:mt-4 flex z-10' style={{pointerEvents:'none'}}>
                            <div className='absolute top-0 w-full h-full'>
                                <TextCenter className='text-red-coral'>EVENTS</TextCenter>
                            </div>
                        </div>
                    </div>
                    <div className="single-item bg-light-grey-2022 " data-color="off_white" style={{position:'relative',top: '-400px'}} id="eventStart">
                            <TitleWrapper
                                className={cn('z-10  w-96 top-10 transition-opacity duration-700 ease-in-out hidden lg:block')}>
                                <div className='text-center relative'>
                                    {/* <TitleEvents className='text-red-coral' style={{ pointerEvents: "none"}}>EVENTS</TitleEvents> */}
                                </div>
                            </TitleWrapper>
                            <div style={{display: 'flex', flexDirection:'column', alignItems: 'center',position: 'absolute',zIndex:1, top: '115px',left: 0,right: 0}}>
                                <div  id="unsticky" style={{flex: 1,display: 'flex', flexDirection:'row'}}>
                                    <div onClick={() => !dayOneClicked && setDayOneClicked(true)} style={{textAlign:'center', cursor:'pointer'}}>
                                        <div style={{transform: (eventDay!==1 ? 'scaleY(-1)' : '')}}>
                                        <svg width={(isMobile ? '100' : '120' )} height={(isMobile ? '52' : '60' )} viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill={(eventDay!==1 ? '#ff6044' : '#191919')} fillRule="evenodd"/>
                                        </svg>
                                        </div>
                                        <p
                                        style={{
                                            position: 'relative',
                                            top: 'calc(-56px)',
                                            color: (eventDay!==1 ? '#191919' : '#e3e3de'),
                                            fontSize: '42px',
                                            letterSpacing: '1.12px',
                                            fontFamily: 'sharp_grotesque',
                                            height: 0,
                                            overflow: 'visible',
                                        }}
                                        >
                                        DAY 1
                                        </p>
                                    </div>
                                    <div  onClick={() => !dayTwoClicked && setDayTwoClicked(true)} style={{textAlign:'center', cursor:'pointer'}}>
                                        <div style={{transform: (eventDay!==2 ? 'scaleY(-1)' : '')}}>
                                        <svg width={(isMobile ? '100' : '120' )} height={(isMobile ? '52' : '60' )} viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill={(eventDay!==2 ? '#ff6044' : '#191919')} fillRule="evenodd"/>
                                        </svg>
                                    </div>
                                    <p
                                        style={{
                                            position: 'relative',
                                            top: 'calc(-56px)',
                                            color: (eventDay!==2 ? '#191919' : '#e3e3de'),
                                            fontSize: '42px',
                                            letterSpacing: '1.12px',
                                            fontFamily: 'sharp_grotesque',
                                            height: 0,
                                            overflow: 'visible'
                                            }}
                                        >
                                        DAY 2
                                        </p>
                                        </div>
                                        <div onClick={() => !dayThreeClicked && setDayThreeClicked(true)} style={{textAlign:'center', cursor:'pointer'}}>
                                        <div style={{transform: (eventDay!==3 ? 'scaleY(-1)' : '')}}>
                                        <svg width={(isMobile ? '100' : '120' )} height={(isMobile ? '52' : '60' )} viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill={(eventDay!==3 ? '#ff6044' : '#191919')} fillRule="evenodd"/>
                                        </svg>
                                        </div>
                                        <p
                                        style={{
                                            position: 'relative',
                                            top: 'calc(-56px)',
                                            color: (eventDay!==3 ? '#191919' : '#e3e3de'),
                                            fontSize: '42px',
                                            letterSpacing: '1.12px',
                                            fontFamily: 'sharp_grotesque',
                                            height: 0,
                                            overflow: 'visible'
                                        }}
                                        >
                                        DAY 3
                                        </p>
                                        </div>
                                        <div onClick={() => !dayFourClicked && setDayFourClicked(true)} style={{textAlign:'center', cursor:'pointer'}}>
                                        <div style={{transform: (eventDay!==4 ? 'scaleY(-1)' : '')}} >
                                        <svg width={(isMobile ? '100' : '120' )} height={(isMobile ? '52' : '60' )} viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill={(eventDay!==4 ? '#ff6044' : '#191919')} fillRule="evenodd"/>
                                        </svg>
                                        </div>
                                        <p
                                        style={{
                                            position: 'relative',
                                            top: 'calc(-56px)',
                                            color: (eventDay!==4 ? '#191919' : '#e3e3de'),
                                            fontSize: '42px',
                                            letterSpacing: '1.12px',
                                            fontFamily: 'sharp_grotesque',
                                            height: 0,
                                            overflow: 'visible'
                                        }}
                                        >
                                        DAY 4
                                        </p>
                                        </div>

                                    </div>
                                    <div style={{flex: 4,position: 'relative', top: (isMobile ? '-10px' : '-2px' ), textAlign:'center'}}>
                                        <svg width={(isMobile ? '400' : '480' )} height={(isMobile ? '217' : '241' )}  viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill="#191919" fillRule="evenodd"/>
                                        </svg>
                                        <p
                                        id='dayOneT'
                                        style={{
                                            position: 'relative',
                                            top: 'calc(-250px)',
                                            color: '#e3e3de',
                                            fontSize: '160px',
                                            letterSpacing: '1.12px',
                                            fontFamily: 'sharp_grotesque'
                                        }}
                                        >
                                        DAY 1
                                        </p>
                                    </div>
                            </div>
                            <div style={{ paddingBottom: '5%'}} className={cn('pt-96 lg:pt-2 relative')}>
                                <div
                                id='dayOne'
                                style={{
                                    fontSize: '50px',
                                    fontWeight: '900',
                                    margin: 'auto',
                                    textAlign: 'center',
                                    color: '#191919',
                                    lineHeight: 1,
                                    fontFamily: 'sharp_grotesque_15',
                                    textTransform: 'uppercase',

                                }}
                                className={cn('w-11/12  lg:w-5/12 pt-96 lg:pt-2')}
                                >
                                Tuesday, May 3, 2022
                                <br />
                                Yes! We’re reimagining design.
                                </div>
                                <div
                                style={{
                                    fontSize: '24px',
                                    margin: '40px auto',
                                    textAlign: 'center',
                                    color: '#191919',
                                    lineHeight: '1.4',
                                }}
                                className={cn('w-11/12  lg:w-5/12 pb-8')}
                                >
                               We’re kicking off four days of YES! with an overview of our second entirely virtual academic year.
                               Join us as we launch an exciting program of activity focused on designing in and around a pandemic.
                                </div>

                                <div style={{ margin: '0 auto' }}>
                                <EventDiv className={cn('w-full lg:w-4/12')}>
                                <HeadContainer>
                                    <img
                                            src='/static/speakers/YES_1_CMYK-Black.png'
                                            alt='glass'
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className='title'>Yes! 2021-22 Kickoff<br /><br /></div>

                                    <div style={{ width: '100%', textAlign: 'left' , height:'90px' ,lineHeight:1}}>
                                    <div className='time'>10–10:30AM EDT</div>
                                    <a
                                        className='ml-2 text-charcoal hover:bg-red-coral transition hover:text-charcoal'
                                        href='https://www.eventbrite.com/e/yes-2021-22-kickoff-tickets-317183794267?aff=odcleoeventsincollection&keep_tld=1'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <span className='text-red-coral'>•</span> <span className='border-b border-dashed border-red-coral '>Virtual Link</span>
                                    </a>
                                    </div>
                                    <div className='desc text-charcoal'>
                                    Join us as we kick off the 2021-22 YES! with overview of the academic year, and a look at the events planned for the week!
                                    <br/>
                                    Dr. Ana Rita Morais, Chair for the School of Design, will overview some key milestones from the 2021-22 academic year, and provide details on the programme of activity for YES! including portfolio reviews, thesis pitches, our incredible speaker series, and our annual award show!
                                    <br />
                                    <br />
                                    </div>
                                </EventDiv>
                                <EventDiv className={cn('w-full lg:w-4/12')}>
                                <HeadContainer>
                                    <img
                                            src='/static/2022/speakers/paula-scher.jpg'
                                            alt='glass'
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className='title'>
                                    Paula Scher
                                    <br />
                                    “25 Years at the Public”
                                    </div>
                                    <div style={{ width: '100%', textAlign: 'left' , height:'90px' ,lineHeight:1}}>
                                    <div className='time'>11AM–12PM EDT</div>
                                    <a
                                        className='ml-2 text-charcoal hover:bg-red-coral transition hover:text-charcoal'
                                        href='https://www.eventbrite.com/e/yes-speaker-series-paula-scher-tickets-317222419797?aff=odcleoeventsincollection&keep_tld=1'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <span className='text-red-coral'>•</span> <span className='border-b border-dashed border-red-coral '>Virtual Link</span>
                                    </a>
                                    </div>
                                    <div className='desc text-charcoal'>
                                    A presentation outlining all of Paula's design work for the Public Theater over the span of 25 years.
                                    <br />
                                    <br />
                                    <b>Bio</b>
                                    <br />
                                    Paula Scher is one of the most acclaimed graphic designers in the world. She has been a principal in the New York office of the distinguished international design consultancy Pentagram since 1991, where she has designed identity systems, environmental graphics, packaging and publications for a wide range of clients that includes, among others, the Public Theater, the Museum of Modern Art, the High Line, the Metropolitan Opera, Tiffany & Co., Citibank and Microsoft. Scher has been the recipient of hundreds of industry honors including the National Design Award and the AIGA medal. She is an established artist exhibiting worldwide, and her designs are in the permanent collections of the Museum of Modern Art, the Cooper Hewitt National Design Museum, the Library of Congress, the Victoria and Albert Museum, and other institutions. A documentary on Scher and her work can be seen in the Netflix series “Abstract: The Art of Design.”
                                    <br />
                                    <br />
                                    <b>Links</b>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://www.pentagram.com/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://www.pentagram.com/
                                    </a>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://www.instagram.com/pentagramdesign'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://www.instagram.com/pentagramdesign
                                    </a>
                                    </div>
                                    <button className='expandEvent'>
                                    <img
                                        src='/static/svg/2022/icon-arrow-down.svg'
                                        style={{ width: '30px', margin: 'auto' }}
                                        alt='down-arrow'
                                    />
                                    </button>
                                </EventDiv>
                                <EventDiv className={cn('w-full lg:w-4/12')}>
                                    <HeadContainer>
                                        <img
                                            src='/static/speakers/YES_1_CMYK-Black.png'
                                            alt='glass'
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>
                                    <div className='title'>
                                    Design During & After the Pandemic
                                    <br />
                                    A School of Design Panel Discussion
                                    </div>
                                    <div style={{ width: '100%', textAlign: 'left' , height:'90px' ,lineHeight:1}}>
                                    <div className='time'>1–2:30PM EDT</div>
                                    <a
                                        className='ml-2 text-charcoal hover:bg-red-coral transition hover:text-charcoal'
                                        href='https://www.eventbrite.com/e/design-during-after-the-pandemic-panel-discussion-tickets-317741422147'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <span className='text-red-coral'>•</span> <span className='border-b border-dashed border-red-coral '>Virtual Link</span>
                                    </a>
                                    </div>
                                    <div className='desc text-charcoal'>
                                    It is undeniable that design responds to a changing world— and there has been no shortage of major change (and monotony?) over the last two years. While the pandemic is far from over, our relations to things, spaces, and one another across the world of design has been lastingly altered. As we look at the way the design landscape has changed over the last two years, education will need to respond accordingly to ensure learners are prepared to hit the ground running when they graduate.
                                    <br />
                                    <br />
                                    Join us for a panel discussion on designing, teaching, and working in a pandemic. Industry professionals, faculty members, alumni, and students from the School of Design will share the experiences that have characterized the last year of their life, including challenges, barriers and opportunities. Audience members will be able to ask questions and share their own perspectives on the changes felt across design education and work over the last two years.
                                    </div>
                                    <button className='expandEvent'>
                                    <img
                                        src='/static/svg/2022/icon-arrow-down.svg'
                                        style={{ width: '30px', margin: 'auto' }}
                                        alt='down-arro'
                                    />
                                    </button>
                                </EventDiv>
                                <EventDiv className={cn('w-full lg:w-4/12')}>
                                <HeadContainer>
                                    <img
                                            src='/static/2022/speakers/laura-stein.png'
                                            alt='glass'
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className='title'>
                                    Laura Stein
                                    <br />
                                    Design needs optimism. Optimism needs design.
                                    </div>
                                    <div style={{ width: '100%', textAlign: 'left' , height:'90px' ,lineHeight:1}}>
                                    <div className='time'>3–4PM EDT</div>
                                    <a
                                        className='ml-2 text-charcoal hover:bg-red-coral transition hover:text-charcoal'
                                        href='https://www.eventbrite.com/e/yes-speaker-series-laura-stein-tickets-317331907277'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <span className='text-red-coral'>•</span> <span className='border-b border-dashed border-red-coral '>Virtual Link</span>
                                    </a>
                                    </div>
                                    <div className='desc'>
                                    We all know that design is an inherently optimistic act but the current climate can curtail even the most driven designer. How do we stay engaged enough to envision and create new possibilities?
                                    <br />
                                    <br />
                                    <b>Bio</b>
                                    <br />
                                    As Partner and Chief Creative Officer at Bruce Mau Design, Laura provides overall creative and strategic direction for the studio’s brand and design output. Bruce Mau Design works across borders, cultures, and channels helping organizations at every scale to grow and transform.
                                    <br />
                                    <br />
                                    Laura has grown her career at BMD, working with some of the world’s leading brands and cultural institutions. For Laura, great design is about the clear ideas and strong signals that rally communities. The way to get there is through strategic thinking, deep-seated stories, and inventive expression.
                                    <br />
                                    <br />
                                    Laura has led some of BMD’s highest-profile initiatives in recent years including ASICS, ASICS Tiger, Sonos, and OCAD University. As Creative Director, she led a long-term partnership with Sonos, leading her team through the design of multiple facets of the wireless audio brand experience — digital, retail, social, packaging, and Sonos Studio content. Over the course of the 5-year collaboration, Sonos transformed from a technology company beloved by audiophiles to a globally recognized brand. Recent clients include lululemon, Department of Culture and Tourism Abu Dhabi, Great Rivers Greenway in St. Louis, MO, Shinsegae in South Korea.
                                    <br />
                                    <br />
                                    Her work has been featured in books such as Dynamic Identities, and Designing Brand Identity, publications such as Fast Company, Communication Arts, Creative Review, Branding Magazine, Business Insider, as well as recognized by Cannes Lions and D&AD, among others.
                                    <br />
                                    <br />
                                    <b>Links</b>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://www.brucemaudesign.com/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://www.brucemaudesign.com/
                                    </a>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://www.instagram.com/brucemaudesign/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://www.instagram.com/brucemaudesign/
                                    </a>
                                    <br />
                                    </div>
                                    <button className='expandEvent'>
                                    <img
                                        src='/static/svg/2022/icon-arrow-down.svg'
                                        style={{ width: '30px', margin: 'auto' }}
                                        alt='down-arrow'
                                    />
                                    </button>
                                </EventDiv>
                                </div>
                            </div>
                    </div>
                    <div className="single-item bg-light-grey-2022" data-color="dark" style={{position:'relative'}}>
                        <div style={{width:'100vw', height:'15px', background:'#191919'}}></div>
                        <div style={{display: 'flex', flexDirection:'column', alignItems: 'center',left: 0,right: 0}}>
                            <div style={{flex: 4,position: 'relative', top: (isMobile ? '-10px' : '-2px' ), textAlign:'center'}}>
                                <svg width={(isMobile ? '400' : '480' )} height={(isMobile ? '217' : '241' )}  viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill="#191919" fillRule="evenodd"/>
                                </svg>
                                <p
                                id="dayTwoT"
                                style={{
                                    position: 'relative',
                                    top: 'calc(-250px)',
                                    color: '#e3e3de',
                                    fontSize: '160px',
                                    letterSpacing: '1.12px',
                                    fontFamily: 'sharp_grotesque'
                                }}
                                >
                                DAY 2
                                </p>
                            </div>
                        </div>
                        <div style={{ paddingBottom: '5%', top:'-160px'}} className='relative'>
                                <div
                                id="dayTwo"
                                style={{
                                    fontSize: '50px',
                                    fontWeight: '900',
                                    margin: 'auto',
                                    textAlign: 'center',
                                    color: '#191919',
                                    lineHeight: 1,
                                    fontFamily: 'sharp_grotesque_15',
                                    textTransform: 'uppercase',

                                }}
                                className={cn('w-11/12  lg:w-5/12 ')}
                                >
                                Wednesday, May 4, 2022
                                <br />
                                Yes! We’re creating new realities.
                                </div>
                                <div
                                style={{
                                    fontSize: '24px',
                                    margin: '40px auto',
                                    textAlign: 'center',
                                    color: '#191919',
                                    lineHeight: '1.4',
                                }}
                                className={cn('w-11/12  lg:w-5/12 pb-8')}
                                >
                                Demonstrating a mastery of the art of the pitch, graduating students across a series of our programs will present their final thesis and capstone projects to an esteemed panel of faculty and industry professionals.
                                </div>
                                <div style={{ margin: '0 auto' }}>
                                <EventDiv className={cn('w-full lg:w-4/12')}>
                                <HeadContainer>
                                    <img
                                            src='/static/2022/speakers/meg-lewis.png'
                                            alt='glass'
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className='title text-charcoal'>
                                    Meg Lewis
                                    <br />
                                    “Creating A Unique Design Style”
                                    </div>
                                    <div style={{ width: '100%', textAlign: 'left' , height:'90px' ,lineHeight:1}}>
                                    <div className='time'>11AM–12PM EDT</div>
                                    <a
                                        className='ml-2 text-charcoal hover:bg-red-coral transition hover:text-charcoal'
                                        href='https://www.eventbrite.com/e/yes-speaker-series-meg-lewis-tickets-317316200297'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <span className='text-red-coral'>•</span> <span className='border-b border-dashed border-red-coral '>Virtual Link</span>
                                    </a>
                                    </div>
                                    <div className='desc text-charcoal'>
                                    Setting yourself apart from other creatives can sometimes feel impossible—like every style has already been taken. How can we create a unique style that celebrates our personality and abilities? And when should we set our styles, personality, and preferences aside for the sake of better design decisions?
                                    <br />
                                    <br />
                                    <b>Bio</b>
                                    <br />
                                    Meg Lewis is an award-losing designer making the world a happier place through books! talks! writing! spaces! podcasts! workshops! and videos! Meg works with happy companies to create friendly, personable visual experiences with an emphasis on clean, expressive type and bold colors. Beyond design, Meg combines comedy with her unique skillset to create businesses, projects, and offerings that turn traditionally boring subjects into fun, impactful experiences. Meg empowers individuals to discover their unique selves through books, video series, workshops, and talks titled Full Time You. She also founded Ghostly Ferns, an international collective of designers and commercial artists and Fool Proof, a shared workspace for creativity and collaboration.
                                    <br />
                                    <br />
                                    <b>Links</b>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://meglewis.com/ '
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://meglewis.com/
                                    </a>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://www.instagram.com/yourbuddymeg/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://www.instagram.com/yourbuddymeg/
                                    </a>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://twitter.com/yourbuddymeg'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://twitter.com/yourbuddymeg
                                    </a>
                                    </div>
                                    <button className='expandEvent'>
                                    <img
                                        src='/static/svg/2022/icon-arrow-down.svg'
                                        style={{ width: '30px', margin: 'auto' }}
                                        alt='down-arrow'
                                    />
                                    </button>
                                </EventDiv>
                                <EventDiv className={cn('w-full lg:w-4/12')}>
                                <HeadContainer>
                                    <img
                                            src='/static/2022/speakers/brian-collins.jpeg'
                                            alt='glass'
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className='title'>
                                    Brian Collins
                                    <br />
                                    Brace for Impact
                                    </div>
                                    <div style={{ width: '100%', textAlign: 'left' , height:'90px' ,lineHeight:1}}>
                                    <div className='time'>3–4PM EDT</div>
                                    <a
                                        className='ml-2 text-charcoal hover:bg-red-coral transition hover:text-charcoal'
                                        href='https://www.eventbrite.com/e/yes-speaker-series-brian-collins-tickets-317305799187'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <span className='text-red-coral'>•</span> <span className='border-b border-dashed border-red-coral '>Virtual Link</span>
                                    </a>
                                    </div>
                                    <div className='desc text-charcoal'>
                                    Time reveals wisdom. Brian Collins will share the three ideas he believes are crucial to your career in the 21st century.
                                    <br />
                                    <br />
                                    <b>Bio</b>
                                    <br />
                                    Brian Collins is Chief Creative Officer of COLLINS, an independent strategy and experience design company in San Francisco and New York City. In their work, they aim to mix imagination, craft and technology to build brands that can’t be ignored. They were named by Forbes as one of the companies reshaping the future of brand building.
                                    <br />
                                    <br />
                                    The firm has won every major creative award. Their work has been featured in The New York Times, Businessweek, Creativity, Fortune, NBC News, MSNBC, ABC News and Fast Company, which named Brian an American Master of Design. For ten years prior to the founding of COLLINS, Brian was Chief Creative Officer of the brand and innovation division of Ogilvy. COLLINS has been on Working Not Working’s annual list of companies "Creative People Want to Work For Next", along with Pixar, Tesla, Disney, Nike and NASA. COLLINS was the first design company to appear on the AdAge A-List and was awarded their Design Agency of the Year in  3 consecutive years from 2019 to 2021. In July 2021, DesignWeek awarded the firm Best of Show for their creative partnership with Twitch.
                                    <br />
                                    <br />
                                    <b>Links</b>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://www.wearecollins.com/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://www.wearecollins.com/
                                    </a>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://www.instagram.com/thisiscollins/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://www.instagram.com/thisiscollins/
                                    </a>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://twitter.com/wearecollins'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://twitter.com/wearecollins
                                    </a>
                                    </div>
                                    <button className='expandEvent'>
                                    <img
                                        src='/static/svg/2022/icon-arrow-down.svg'
                                        style={{ width: '30px', margin: 'auto' }}
                                        alt='down-arrow'
                                    />
                                    </button>
                                </EventDiv>
                                </div>
                        </div>
                    </div>
                    <div className="single-item bg-light-grey-2022" data-color="off_white" style={{position:'relative'}}>
                        <div style={{width:'100vw', height:'15px', background:'#191919'}}></div>
                        <div style={{display: 'flex', flexDirection:'column', alignItems: 'center',left: 0,right: 0}}>
                            <div style={{flex: 4,position: 'relative', top: (isMobile ? '-10px' : '-2px' ), textAlign:'center'}}>
                                <svg width={(isMobile ? '400' : '480' )} height={(isMobile ? '217' : '241' )}  viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill="#191919" fillRule="evenodd"/>
                                </svg>
                                <p
                                id="dayThreeT"
                                style={{
                                    position: 'relative',
                                    top: 'calc(-250px)',
                                    color: '#e3e3de',
                                    fontSize: '160px',
                                    letterSpacing: '1.12px',
                                    fontFamily: 'sharp_grotesque'
                                }}
                                >
                                DAY 3
                                </p>
                            </div>
                        </div>
                        <div style={{ paddingBottom: '5%', top:'-160px'}} className='relative'>
                                <div
                                id="dayThree"
                                style={{
                                    fontSize: '50px',
                                    fontWeight: '900',
                                    margin: 'auto',
                                    textAlign: 'center',
                                    color: '#191919',
                                    lineHeight: 1,
                                    fontFamily: 'sharp_grotesque_15',
                                    textTransform: 'uppercase',

                                }}
                                className={cn('w-11/12  lg:w-5/12 ')}
                                >
                                Thursday, May 5, 2022
                                <br />
                                Yes! We’re questioning the conventional.
                                </div>
                                <div
                                style={{
                                    fontSize: '24px',
                                    margin: '40px auto',
                                    textAlign: 'center',
                                    color: '#191919',
                                    lineHeight: '1.4',
                                }}
                                className={cn('w-11/12  lg:w-5/12 pb-8')}
                                >
                                Design holds a tremendous power to impact change. We'll focus on our commitment to design ethics and prioritizing accessibility in design— both inside and outside of the classroom.
                                </div>

                                <div style={{ margin: '0 auto' }}>
                                <EventDiv className={cn('w-full lg:w-4/12')}>
                                <HeadContainer>
                                    <img
                                            src='/static/2022/speakers/kelly-small-b.jpeg'
                                            alt='glass'
                                            className={cn('cursor-pointer')}
                                            style={{filter:'none'}}
                                        />
                                        {/* <div></div> */}
                                    </HeadContainer>

                                    <div className='title'>
                                    Kelly Small
                                    <br />
                                    “Design Ethics for the Real World”
                                    </div>
                                    <div style={{ width: '100%', textAlign: 'left' , height:'90px' ,lineHeight:1}}>
                                    <div className='time'>11AM–12PM EDT</div>
                                    <a
                                        className='ml-2 text-charcoal hover:bg-red-coral transition hover:text-charcoal'
                                        href='https://www.eventbrite.com/e/yes-speaker-series-kelly-small-tickets-317300152297'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <span className='text-red-coral'>•</span> <span className='border-b border-dashed border-red-coral '>Virtual Link</span>
                                    </a>
                                    </div>
                                    <div className='desc'>
                                    Increasingly, designers want their careers to centre purpose over profit but it can’t compromise their ability to buy lunch. How might we dispel myths and assumptions about values-driven work to widen its reach and help make it available to all?
                                    <br />
                                    <br />
                                    <b>Bio</b>
                                    <br />
                                    Founder and COO of creative agency Intents & Purposes, and bestselling author of *The Conscious Creative: Practical Ethics for Purposeful Work*, Kelly Riback Small is an award-winning creative director, designer, writer, and educator whose career is driven by the pursuit of practical action toward ethical, inclusive, and sustainable futures.
                                    <br />
                                    <br />
                                    With nearly 20 years of leading major brands to proven success, Kelly’s driving philosophy is that purposeful work can happen anywhere. Their work centres on providing design direction, strategic creative leadership, ethical design education, and thoughtfully co-created approaches to help clients, practitioners, and students achieve measurably impactful work. A Master of Design graduate and affiliated design researcher with Emily Carr University, Kelly is also a professor of design ethics at the George Brown School of Design and mentor with The RGD and OCAD University. Kelly is currently authoring a book about practical ethics for a youth audience to be published by Groundwood Books in 2023.
                                    <br />
                                    <br />
                                    <b>Links</b>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://www.intentspurposes.co/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://www.intentspurposes.co/
                                    </a>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://www.linkedin.com/in/kellysmall/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://www.linkedin.com/in/kellysmall/
                                    </a>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://www.instagram.com/kellysmall/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://www.instagram.com/kellysmall/
                                    </a>
                                    </div>
                                    <button className='expandEvent'>
                                    <img
                                        src='/static/svg/2022/icon-arrow-down.svg'
                                        style={{ width: '30px', margin: 'auto' }}
                                        alt='down-arrow'
                                    />
                                    </button>
                                </EventDiv>
                                <EventDiv className={cn('w-full lg:w-4/12')}>
                                <HeadContainer>
                                    <img
                                            src='/static/speakers/YES_1_CMYK-Black.png'
                                            alt='glass'
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className='title'>
                                    Design at the Intersection of Accessibility and Inclusion
                                    <br />
                                    “A School of Design Panel Discussion”
                                    </div>
                                    <div style={{ width: '100%', textAlign: 'left' , height:'90px' ,lineHeight:1}}>
                                    <div className='time'>1–2:30PM EDT</div>
                                    <a
                                        className='ml-2 text-charcoal hover:bg-red-coral transition hover:text-charcoal'
                                        href='https://www.eventbrite.com/e/design-at-the-intersection-of-accessibility-and-inclusion-panel-discussion-tickets-317744561537'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <span className='text-red-coral'>•</span> <span className='border-b border-dashed border-red-coral '>Virtual Link</span>
                                    </a>
                                    </div>
                                    <div className='desc text-charcoal'>
                                    To be meaningful and inclusive, accessibility must underpin all of the work and processes of a designer. But where do we start? As designers, we play a critical role in providing experiences that are more navigable— whether two or three-dimensional.
                                    <br />
                                    <br />
                                    Join us for a panel discussion on the ways in which accessible design has the power to improve the lives of all users. Industry professionals, faculty members, alumni, and students from the School of Design will share their projects and insights on the triumphs, challenges, barriers and opportunities in accessible design. Audience members will be able to ask questions and share their own perspectives on accessibility and design.
                                    <br />
                                    <br />
                                    </div>
                                </EventDiv>
                                <EventDiv className={cn('w-full lg:w-4/12')}>
                                <HeadContainer>
                                    <img
                                            src='/static/2022/speakers/marlo-onilla.jpeg'
                                            alt='glass'
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>
                                    <div className='title'>
                                    Marlo Onilla
                                    <br />
                                    Challenge Convention - Avoid the Obvious
                                    </div>
                                    <div style={{ width: '100%', textAlign: 'left' , height:'90px' ,lineHeight:1}}>
                                    <div className='time'>3–4PM EDT</div>
                                    <a
                                        className='ml-2 text-charcoal hover:bg-red-coral transition hover:text-charcoal'
                                        href='https://www.eventbrite.com/e/yes-speaker-series-marlo-onilla-tickets-317298888517?aff=odcleoeventsincollection&keep_tld=1'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <span className='text-red-coral'>•</span> <span className='border-b border-dashed border-red-coral '>Virtual Link</span>
                                    </a>
                                    </div>
                                    <div className='desc'>
                                    As a business (Design Industry) how do you stay the course? How to stay relevant. Exploring the unpredictabe through some of Biography’s past work in the pursuit of the constant evolution.
                                    <br />
                                    <br />
                                    <b>Bio</b>
                                    <br />
                                    Marlo Onilla has been working in the design industry for 25+ years, and has been vital to Biography’s success since it’s inception. A fine blend of creative vision and project objectives, Marlo is mainly responsible for the firm’s development, creative leadership and team management. As Creative Director and Founder, Marlo pursues effective and appropriate solutions while leading clients in the areas of brand development, visual identity and strategic communications.
                                    <br />
                                    <br />
                                    Since 1993, Marlo has garnered numerous prestigious awards for design excellence. His
                                    work has been recognized and featured in every leading design publications such as Communication Arts, The Advertising and Design Club of Canada, American Institute of Graphic Arts, Designlines Magazine, Art Directors Club, Coupe Magazine, How Magazine, Applied Arts Magazine, Type Directors Club and Print magazine just to name a few.
                                    <br />
                                    <br />
                                    Biography is a multi-disciplinary branding consultancy of designers and creative thinkers. Our vision is for inspired results that make a lasting impact. Our passion is for compelling design. Our strength is an ability to provide innovative concepts that extend beyond the predictable. We are listeners and storytellers who combine imaginative ideas with thoughtful design solutions to craft and cultivate brands.
                                    <br />
                                    <br />
                                    <b>Links</b>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='biographydesign.com'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        biographydesign.com
                                    </a>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://www.instagram.com/biographydesign'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://www.instagram.com/biographydesign
                                    </a>
                                    </div>
                                    <button className='expandEvent'>
                                    <img
                                        src='/static/svg/2022/icon-arrow-down.svg'
                                        style={{ width: '30px', margin: 'auto' }}
                                        alt='down-arro'
                                    />
                                    </button>
                                </EventDiv>
                                </div>
                        </div>
                    </div>
                    <div className="single-item bg-light-grey-2022" data-color="off_white" style={{position:'relative'}}>
                        <div style={{width:'100vw', height:'15px', background:'#191919'}}></div>
                        <div style={{display: 'flex', flexDirection:'column', alignItems: 'center',left: 0,right: 0}}>
                            <div style={{flex: 4,position: 'relative', top: (isMobile ? '-10px' : '-2px' ), textAlign:'center'}}>
                                <svg width={(isMobile ? '400' : '480' )} height={(isMobile ? '217' : '241' )}  viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill="#191919" fillRule="evenodd"/>
                                </svg>
                                <p
                                id="dayFourT"
                                style={{
                                    position: 'relative',
                                    top: 'calc(-250px)',
                                    color: '#e3e3de',
                                    fontSize: '160px',
                                    letterSpacing: '1.12px',
                                    fontFamily: 'sharp_grotesque'
                                }}
                                >
                                DAY 4
                                </p>
                            </div>
                        </div>
                        <div style={{ paddingBottom: '5%', top:'-160px'}} className='relative'>
                                <div
                                id="dayFour"
                                style={{
                                    fontSize: '50px',
                                    fontWeight: '900',
                                    margin: 'auto',
                                    textAlign: 'center',
                                    color: '#191919',
                                    lineHeight: 1,
                                    fontFamily: 'sharp_grotesque_15',
                                    textTransform: 'uppercase',

                                }}
                                className={cn('w-11/12  lg:w-5/12 ')}
                                >
                                Friday, May 6, 2022
                                <br />
                                Yes! We’re the School of Design.
                                </div>
                                <div
                                style={{
                                    fontSize: '24px',
                                    margin: '40px auto',
                                    textAlign: 'center',
                                    color: '#191919',
                                    lineHeight: '1.4',
                                }}
                                className={cn('w-11/12  lg:w-5/12 pb-8')}
                                >
                                There's no party like a School of Design party. We're live streaming our annual Award Show, where we'll announce over 100 awards to students and faculty across our ten programs. Join us to celebrate the award recipients on their dedication and hard work!
                                </div>
                                <div style={{ margin: '0 auto' }}>
                                <EventDiv className={cn('w-full lg:w-4/12')}>
                                    <HeadContainer>
                                        <img
                                            src='/static/2022/speakers/maurice-cherry.jpg'
                                            alt='glass'
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className='title'>
                                    Maurice Cherry
                                    <br />
                                    Advice For Designing at the End of the World
                                    </div>
                                    <div style={{ width: '100%', textAlign: 'left' , height:'90px' ,lineHeight:1}}>
                                    <div className='time'>11AM–12PM EDT</div>
                                    <a
                                        className='ml-2 text-charcoal hover:bg-red-coral transition hover:text-charcoal'
                                        href='https://www.eventbrite.com/e/yes-speaker-series-maurice-cherry-tickets-317254716397'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <span className='text-red-coral'>•</span> <span className='border-b border-dashed border-red-coral '>Virtual Link</span>
                                    </a>
                                    </div>
                                    <div className='desc text-charcoal'>
                                    The last few years have thrown a lot at us all. A global health crisis. Environmental disasters. Political instability. Season 8 of Game of Thrones. As you close out the year, you might be wondering what’s out there for you in this brave new world. That’s why I’m here. Sit a spell and hear some words of wisdom from someone with 20 years in the game as a working creative. Who knows, you might learn a lesson or two in the process!
                                    <br />
                                    <br />
                                    <b>Bio</b>
                                    <br />
                                    Maurice Cherry is a designer, strategist and podcaster located in Atlanta, GA. He is principal and creative director at Lunch, an award-winning multidisciplinary studio he created in 2008 that helps creative brands craft messages and tell stories for their targeted audiences, including fostering relationships with underrepresented communities. Past clients and collaborators included Facebook, Mailchimp, Vox Media, NIKE, Mediabistro, Site5, SitePoint, and The City of Atlanta.
                                    <br />
                                    <br />
                                    Maurice is a pioneering digital creator who is most well-known for Revision Path, an award-winning podcast which is the first podcast to be added to the permanent collection of the Smithsonian’s National Museum of African American History and Culture (NMAAHC). Other projects of Maurice’s include the Black Weblog Awards, 28 Days of the Web, The Year of Tea and the design anthology RECOGNIZE.
                                    <br />
                                    <br />
                                    <b>Links</b>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://www.mauricecherry.com/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        mauricecherry.com
                                    </a>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://twitter.com/mauricecherry'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        twitter.com/mauricecherry
                                    </a>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://linkedin.com/in/mauricecherry'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        linkedin.com/in/mauricecherry
                                    </a>
                                    </div>
                                    <button className='expandEvent'>
                                    <img
                                        src='/static/svg/2022/icon-arrow-down.svg'
                                        style={{ width: '30px', margin: 'auto' }}
                                        alt='down-arrow'
                                    />
                                    </button>
                                </EventDiv>
                                <EventDiv className={cn('w-full lg:w-4/12')}>
                                <HeadContainer>
                                    <img
                                            src='/static/2022/speakers/john-maeda.jpg'
                                            alt='glass'
                                            className={cn('cursor-pointer')}
                                        />
                                        <div></div>
                                    </HeadContainer>

                                    <div className='title'>
                                    John Maeda
                                    <br />
                                    “Recover Faster: Living a Resilient Career”
                                    </div>
                                    <div style={{ width: '100%', textAlign: 'left' , height:'90px' ,lineHeight:1}}>
                                    <div className='time'>3–4PM EDT</div>
                                    <a
                                        className='ml-2 text-charcoal hover:bg-red-coral transition hover:text-charcoal'
                                        href='https://www.eventbrite.com/e/yes-speaker-series-john-maeda-tickets-317249280137'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <span className='text-red-coral'>•</span> <span className='border-b border-dashed border-red-coral '>Virtual Link</span>
                                    </a>
                                    </div>
                                    <div className='desc text-charcoal'>
                                    The unwritten rule of learning is that although knowledge makes you more prepared, nothing can really prepare you to fail over and over again. Pithy phrases like "fail faster" or "fail harder" always seem fine and good when printed in beautifully rendered images posted on inspiration walls, but in reality nobody really wants to fail. That's why I like to say "recover faster" instead of "fail faster" — it accepts the fact that you're going to fall down once in a while. So instead of getting good at failing, you're better off getting good at recovering. Let's learn how to recover. Faster!
                                    <br />
                                    <br />
                                    <b>Bio</b>
                                    <br />
                                    American technologist and product experience leader with a passion for RESILIENCE and renewal. Currently serving as chief technology officer of Everbridge. Formerly Sonos board of directors, Wieden+Kennedy board of directors, Kleiner Perkins partner, MIT Media Lab data visualization lead, Publicis Sapient executive vice president and chief experience officer, Automattic head of design + inclusion, and 16th president/ceo of the Rhode Island School of Design (RISD).
                                    <br />
                                    <br />
                                    Author of five books including a gentle intro to AI/ML "How To Speak Machine" and the tech bestseller of 2006 "Laws of Simplicity." Writings, interviews, or talks include WSJ, NYT, TED, BBC, WEF, COP, CNN, The Economist, Forbes, USA Today, Fortune, Fast Company, Esquire. Honors include three honorary doctorate degrees, TIME Best Twitter 140, White House National Design Award, Fast Company Masters of Innovation, LinkedIn Top 10 US Influencer, Esquire 21 Most Influential of the 21st Century, and Tribeca Film Festival Disruptor Award for launching the STEM to STEAM movement in the US.
                                    <br />
                                    <br />
                                    <b>Links</b>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://maedastudio.com/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://maedastudio.com/
                                    </a>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://twitter.com/johnmaeda'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://twitter.com/johnmaeda
                                    </a>
                                    <br />
                                    <a
                                        className='text-red-coral hover:text-red-coral transition'
                                        href='https://www.linkedin.com/in/johnmaeda/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        https://www.linkedin.com/in/johnmaeda/
                                    </a>
                                    </div>
                                    <button className='expandEvent'>
                                    <img
                                        src='/static/svg/2022/icon-arrow-down.svg'
                                        style={{ width: '30px', margin: 'auto' }}
                                        alt='down-arrow'
                                    />
                                    </button>
                                </EventDiv>
                                <EventDiv className={cn('w-full lg:w-4/12')}>
                                    <HeadContainer>
                                        <img
                                                src='/static/speakers/YES_1_CMYK-Black.png'
                                                alt='glass'
                                                className={cn('cursor-pointer')}
                                            />
                                        <div></div>
                                    </HeadContainer>
                                    <div className='title'>
                                    YES! Annual Award Show
                                    <br />
                                    <br />
                                    </div>
                                    <div style={{ width: '100%', textAlign: 'left' , height:'90px' ,lineHeight:1}}>
                                    <div className='time'>7–8:30PM EDT</div>
                                    <a
                                        className='ml-2 text-charcoal hover:bg-red-coral transition hover:text-charcoal'
                                        href='https://www.eventbrite.com/e/yes-annual-award-show-tickets-317787971377'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <span className='text-red-coral'>•</span> <span className='border-b border-dashed border-red-coral '>Virtual Link</span>
                                    </a>
                                    </div>
                                    <div className='desc text-charcoal'>
                                    This spring, nearly 430 students will graduate from the School of Design. Each has beneﬁtted from the wisdom and support of faculty, peers, staff, industry partners and the much broader School of Design community. YES! is a testament to their collaboration.
                                    <br />
                                    <br />
                                    To commemorate all of the hard work and dedication of our students, we will livestream our annual award show. We encourage you to set up viewing parties on Zoom and engage @designgbc in your conversations!
                                    <br />
                                    <br />
                                    </div>
                                    <button className='expandEvent'>
                                    <img
                                        src='/static/svg/2022/icon-arrow-down.svg'
                                        style={{ width: '30px', margin: 'auto' }}
                                        alt='down-arrow'
                                    />
                                    </button>
                                </EventDiv>
                                </div>
                        </div>
                        <div style={{position:'absolute', bottom: '-70px', width:'100vw', height:'100px', background:'#e3e3de', zIndex:'20'}}></div>
                    </div>
                    <FooterContainer className="single-item bg-light-grey-2022 pb-0" data-color="off_white" style={{position:'relative',background:'#191919', overflow:'hidden', flex:'none' ,minWidth: '10vh', minHeight: '10vh',top: 0}}>
                                <div style={{position:'absolute', top: '-30px', width:'100vw', height:'100px', background:'#e3e3de', zIndex:'18'}}></div>
                                <Footer/>
                    </FooterContainer>
                    {/* <div className="single-item" data-color="off_white" style={{overflow:'hidden', flex:'none' ,minWidth: '10vh', minHeight: '10vh',height: '100vh'}}>
                        <Footer />
                    </div> */}
                </div>
            </div>
        </div>
        <SearchIcon className='hidden lg:block' />
        <div id="sticky" style={{display: 'none', flexDirection:'column', alignItems: 'center',position: 'fixed', top: 0,left: 0,right: 0 , background:'#e3e3de'}}>
            <div  id="unsticky" style={{flex: 1,display: 'flex', flexDirection:'row'}}>
                <div onClick={() => !dayOneClicked && setDayOneClicked(true)}
                    style={{textAlign:'center', cursor:'pointer'}}>
                <div style={{transform: (eventDay!==1 ? 'scaleY(-1)' : '')}}>
                <svg width={(isMobile ? '100' : '120' )} height={(isMobile ? '52' : '60' )} viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                    <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill={(eventDay!==1 ? '#ff6044' : '#191919')} fillRule="evenodd"/>
                </svg>
                </div>
                <p
                style={{
                    position: 'relative',
                    top: 'calc(-56px)',
                    color: (eventDay!==1 ? '#191919' : '#e3e3de'),
                    fontSize: '42px',
                    letterSpacing: '1.12px',
                    fontFamily: 'sharp_grotesque',
                    height: 0,
                    overflow: 'visible'
                }}
                >
                DAY 1
                </p>
            </div>
            <div onClick={() => !dayTwoClicked && setDayTwoClicked(true)}
                    style={{textAlign:'center', cursor:'pointer'}}>
                <div style={{transform: (eventDay!==2 ? 'scaleY(-1)' : '')}}>
                <svg width={(isMobile ? '100' : '120' )} height={(isMobile ? '52' : '60' )} viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                    <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill={(eventDay!==2 ? '#ff6044' : '#191919')} fillRule="evenodd"/>
                </svg>
            </div>
            <p
                style={{
                    position: 'relative',
                    top: 'calc(-56px)',
                    color: (eventDay!==2 ? '#191919' : '#e3e3de'),
                    fontSize: '42px',
                    letterSpacing: '1.12px',
                    fontFamily: 'sharp_grotesque',
                    height: 0,
                    overflow: 'visible'
                    }}
                >
                DAY 2
                </p>
                </div>
                <div onClick={() => !dayThreeClicked && setDayThreeClicked(true)}
                    style={{textAlign:'center', cursor:'pointer'}}>
                <div style={{transform: (eventDay!==3 ? 'scaleY(-1)' : '')}}>
                <svg width={(isMobile ? '100' : '120' )} height={(isMobile ? '52' : '60' )} viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                    <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill={(eventDay!==3 ? '#ff6044' : '#191919')} fillRule="evenodd"/>
                </svg>
                </div>
                <p
                style={{
                    position: 'relative',
                    top: 'calc(-56px)',
                    color: (eventDay!==3 ? '#191919' : '#e3e3de'),
                    fontSize: '42px',
                    letterSpacing: '1.12px',
                    fontFamily: 'sharp_grotesque',
                    height: 0,
                    overflow: 'visible'
                }}
                >
                DAY 3
                </p>
                </div>
                <div  onClick={() => !dayFourClicked && setDayFourClicked(true)}
                    style={{textAlign:'center', cursor:'pointer'}}>
                <div style={{transform: (eventDay!==4 ? 'scaleY(-1)' : '')}} >
                <svg width={(isMobile ? '100' : '120' )} height={(isMobile ? '52' : '60' )} viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                    <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill={(eventDay!==4 ? '#ff6044' : '#191919')} fillRule="evenodd"/>
                </svg>
                </div>
                <p
                style={{
                    position: 'relative',
                    top: 'calc(-56px)',
                    color: (eventDay!==4 ? '#191919' : '#e3e3de'),
                    fontSize: '42px',
                    letterSpacing: '1.12px',
                    fontFamily: 'sharp_grotesque',
                    height: 0,
                    overflow: 'visible'
                }}
                >
                DAY 4
                </p>
                </div>
            </div>
            <SearchIcon className='hidden lg:block' />
        </div>
        < FixedBottomNav className='hidden lg:block' />
    </>
  );
};


class SmoothScroll {
  constructor(options) {
    this.endThreshold = 0.05
    this.requestId = null
    this.maxDepth = 10
    this.viewHeight = 0
    this.halfViewHeight = 0
    this.maxDistance = 0
    this.viewWidth = 0
    this.halfViewWidth = 0
    this.maxDistanceWidth = 0
    this.scrollHeight = 0
    this.endScroll = 0
    this.returnCurrentScroll = 0
    this.currentScroll = 0
    this.scrollTransform = 0
    this.horizontalScroll = 0
    this.resizeRequest = 1
    this.scrollRequest = 0
    this.scrollItems = []
    this.lastTime = -1
    this.maxElapsedMS = 100
    this.targetFPMS = 0.06

    // this.scrollBody = options.scrollBody;
    // this.scrollSpacer = options.scrollSpacer;

    this.target = options.target

    this.scrollEase = options.scrollEase != null ? options.scrollEase : 0.1
    this.maxOffset = options.maxOffset != null ? options.maxOffset : 500


    this.childElements = this._childElements()


    this.addItems()

    window.addEventListener('resize', this._onResize)
    window.addEventListener('scroll', this._onScroll)
    //this.scrollBody.addEventListener("scroll", this._onScroll);

    this._update()
  }

  _childElements = (event) => {
    const childElementsNode = this.target.querySelectorAll('*[data-color]')
    return childElementsNode
  }



  _onResize = (event) => {
    this.resizeRequest++
    if (!this.requestId) {
      this.lastTime = performance.now()
      this.requestId = requestAnimationFrame(this._update)
    }
  }

  _onScroll = (event) => {
    this.scrollRequest++
    if (!this.requestId) {
      this.lastTime = performance.now()
      this.requestId = requestAnimationFrame(this._update)
    }

    // //document.getElementById('sticky').style.display = "flex";
    // //document.getElementById('unsticky').style.display = "none";
    // console.log(window.pageYOffset);
  }

  _onDestroy = () => {
    window.removeEventListener('resize', this._onResize)
    window.removeEventListener('scroll', this._onScroll)
  }

  _changeColorBody = (event) => {
    if (this.childElements.length > 0) {
      this.childElements.forEach((child) => {
        const wrapper = document.querySelector('.change_color_page')
        const childRect = child.getBoundingClientRect()
        const childAttr = child.getAttribute('data-color')

        if (childRect.y <= this.halfViewHeight && childRect.bottom >= this.halfViewHeight) {
          if (childAttr === 'off_white') {
            if (!document.body.classList.contains('white')) {
              document.body.classList.add('white')
            }
            if (!wrapper.classList.contains('white')) {
              wrapper.classList.add('white')
            }
          } else if (childAttr === 'dark') {
            if (document.body.classList.contains('white')) {
              document.body.classList.remove('white')
            }
            if (wrapper.classList.contains('white')) {
              wrapper.classList.remove('white')
            }
          }
        }
      })
    }
  }

  _update = (currentTime = performance.now()) => {
    const elSticky = document.getElementById('sticky')
    const unSticky = document.getElementById('unsticky')
    if (!elSticky | !unSticky) {
      return
    }
    if (this.currentScroll > 950) {
      document.getElementById('sticky').style.display = 'flex'
      document.getElementById('unsticky').style.display = 'none'
    } else {
      document.getElementById('sticky').style.display = 'none'
      document.getElementById('unsticky').style.display = 'flex'
    }
    let elapsedMS = currentTime - this.lastTime

    if (elapsedMS > this.maxElapsedMS) {
      elapsedMS = this.maxElapsedMS
    }

    const deltaTime = elapsedMS * this.targetFPMS
    const dt = 1 - Math.pow(1 - this.scrollEase, deltaTime)

    const resized = this.resizeRequest > 0
    const scrollY = window.pageYOffset
    //const scrollY = this.scrollBody.scrollTop;

    if (resized) {
      const height = this.target.clientHeight
      document.body.style.height = height + 'px'
      //this.scrollSpacer.style.height = height + "px";
      this.scrollHeight = height
      this.viewHeight = window.innerHeight
      this.halfViewHeight = this.viewHeight / 2
      this.maxDistance = this.viewHeight * 2
      this.resizeRequest = 0
      this.viewWidth = window.innerWidth
      this.halfViewWidth = this.viewWidth / 2
      this.maxDistanceWidth = this.viewWidth * 2
    }

    this.endScroll = scrollY
    // this.scrollTransform += (scrollY - this.scrollTransform) * this.scrollEase;
    this.scrollTransform += (scrollY - this.scrollTransform) * dt
    this.currentScroll += (scrollY - this.currentScroll) * dt

    if (Math.abs(scrollY - this.currentScroll) < this.endThreshold || resized) {
      this.currentScroll = scrollY
      this.scrollRequest = 0
    }

    if (Math.abs(scrollY - this.scrollTransform) < this.endThreshold || resized) {
      this.scrollTransform = scrollY
      this.scrollRequest = 0
    }

    ///change color section
    this._changeColorBody()

    // const scrollOrigin = scrollY + this.halfViewHeight;
    const scrollOrigin = this.currentScroll + this.viewHeight

    this.target.style.transform = `translate3d(0px,-${this.scrollTransform}px,0px)`

    //items
    for (let i = 0; i < this.scrollItems.length; i++) {
      const item = this.scrollItems[i]

      const distance = scrollOrigin - item.top
      const offsetRatio = distance / this.maxDistance

      item.endOffset = Math.round(this.maxOffset * item.depthRatio * offsetRatio)

      if (Math.abs(item.endOffset - item.currentOffset < this.endThreshold)) {
        item.currentOffset = item.endOffset
      } else {
        // item.currentOffset += (item.endOffset - item.currentOffset) * this.scrollEase;
        item.currentOffset += (item.endOffset - item.currentOffset) * dt
      }
      if (item.direction === 'y') {
        item.target.style.transform = `translate3d(0px,${item.currentOffset}px,0px)`
      } else if (item.direction === 'x') {
        item.target.style.transform = `translate3d(${item.currentOffset}px,0px,0px)`
      }
    }

    this.lastTime = currentTime

    this.requestId = this.scrollRequest > 0 ? requestAnimationFrame(this._update) : null
  }

  addItems() {
    this.scrollItems = []
    const elements = document.querySelectorAll('*[data-depth]')
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]
      const depth = +element.getAttribute('data-depth')
      const direction_item = element.getAttribute('data-direction')
      const rect_item = element.getBoundingClientRect()
      const item = {
        rect: rect_item,
        target: element,
        top: rect_item.top + window.pageYOffset,
        //top: rect_item.top + this.scrollBody.scrollTop,
        depth: depth,
        depthRatio: depth / this.maxDepth,
        currentOffset: 0,
        endOffset: 0,
        direction: direction_item,
      }
      this.scrollItems.push(item)
    }
    return this
  }

  currentScrollReturn() {
    return this.currentScroll
  }
}
