import React from 'react'
import styles from './VideoPlayerModal.module.scss'
import ReactPlayer from "react-player"
import {isMobileSafari} from 'react-device-detect';

const VideoModalLive = ({ videoOpen, setVideoClose, videoMute, setVideoMute }) => {

  return (
    videoOpen && (
      <div className={styles.videoModal} >
        <div className='w-full h-full flex justify-center items-center'>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=3QJc426bpA4"
            playing={true}
            playsinline={true}
            volume={1}
            muted={isMobileSafari ? videoMute : false}
            // controls={isMobileSafari ? videoMute : false}
            width='70%'
            height='70%'
            config={{ vimeo: { playerOptions: { playsinline: 1 } } }}
            style={{pointerEvents:"auto"}}
            onClick={isMobileSafari ? setVideoMute : undefined}
          />
          <button className={styles.closeButton} onClick={setVideoClose}>
            <img src='/static/svg/2023/close.svg' alt='close'  />
          </button>
           {(isMobileSafari&&videoMute) && (
          <button className={styles.muteButton} onClick={setVideoMute}>
             <img src='/static/2022/muted.png' alt='close' />
          </button>)}
        </div>
      </div>
    )
  )
}

export default VideoModalLive
