import React, {useEffect, useMemo, useRef} from 'react'
import styled from 'styled-components'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useLocation } from 'react-router'
import qs from 'query-string'
import cn from 'classnames'
import upperFirst from 'lodash.upperfirst'
import { Helmet } from 'react-helmet-async'
import ReactMarkDown from 'react-markdown'
import { Link } from 'react-router-dom'
import useScroll from '../../../hook/useScroll'
import take from 'lodash.take'
import shuffle from 'lodash.shuffle'
import { ProjectScroll } from './ProjectScroll'
import { projectType } from '../../../constant'
import Footer from '../Footer'
import WorkCard from '../../../components/2023/SimilarWorkCard'
import SearchIcon from '../../../components/2023/SearchIcon'
import { MobileRightNavs, MobileNavsBottom} from '../MobileRightNavs'
import { useDisclosure } from 'react-use-disclosure'
import FixedBottomNav from '../FixedBottomNav'
import { Element } from 'react-scroll'
import {WorkBGBottom, FooterContainer} from "../../../styles/2023/WorkStyle";
import StickerNavsBlue from '../StickerNavsBlue'

const Main = styled.div`
  cursor: url(${(props) => props.cursorUrl}), auto;
`

const Container = styled.div`
  background-color: #F0EEE9 !important;

  .projectText p {
    padding-bottom: 10px;
  }
  .projectText p a {
    color: #191919;
  }
  .projectText p a:hover {
    color: #ff6044;
  }
`

const TitleContent = styled.h2`
  font-size: 44px;
  font-family: 'grotesque_pro_light';
  text-align: center;
  margin: auto;
  padding-bottom: 24px;
  width: 50vw;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`
const Image = styled.img`
  width: 100%;
`

const TextLogoTwo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 8.5rem;
  line-height: 9rem;
  letter-spacing: 0.01em;
  font-weight: 400;
`
const ProjectTitle = styled.h3`
  font-family: 'reckless_light';
`

const Y_POS_SWAP_NAVS = 240

export default function PublishedProject(props) {
  const scroll = useScroll()
  const relatedWorkRef = useRef()
  const relatedRefTop = relatedWorkRef?.current?.getBoundingClientRect()?.y
  const scrollRef = useRef()
  const scrollRefHeight = scrollRef?.current?.offsetHeight

  const { search } = useLocation()
  const { id } = qs.parse(search)

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])

  const { data } = useQuery(PUBLISHED_PROJECT, {
    variables: {
      id,
    },
  })
  const project = useMemo(() => data?.publishedProject || {}, [data])

  const [fetchSimilar, { data: projectSimilar }] = useLazyQuery(ALL_PROJECTS_QUERY, {
    variables: {
      where: {
        published: true,
        primaryProjectType: project?.primaryProjectType,
        createdAt_gt: '2022-12-31',
      },
      first: 30,
    },
  })

  useEffect(() => {
    if (!project?.primaryProjectType) {
      return
    }

    fetchSimilar()
  }, [project, fetchSimilar])

  const similarProjects = useMemo(() => {
    if (projectSimilar?.projects?.length > 0) {
      const projects = projectSimilar?.projects
      return take(shuffle(projects), 3)
    }
    return []
  }, [projectSimilar])

  const leadMember = useMemo(() => {
    const lead = project?.members?.find((member) => member.role === 'Project Lead')?.user
    return lead || project?.members?.[0]?.user || {}
  }, [project])

  const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()
  require("./index.scss");
  return (
    <Main cursorUrl='/static/svg/2023/cursor-with-shadow.svg'>
      {project.title && (
        <Helmet>
          <title>YES! | {project.title}</title>
        </Helmet>
      )}

      <Container className='flex min-h-screen mt-40 lg:mt-0' >
      <Element name="myScrollToElement" id="scroll2023top"></Element>
      <StickerNavsBlue
                    className="w-96 fixed top-0 transition-opacity duration-700 ease-in-out hidden lg:block z-50"/>
        <ProjectScroll
          className={cn('w-96 fixed transition-opacity duration-700 ease-in-out hidden lg:block', {
            'opacity-1 visible': scroll.scrollY >= Y_POS_SWAP_NAVS,
            'opacity-0 invisible': scroll.scrollY < Y_POS_SWAP_NAVS,
            fixed: relatedRefTop - scrollRefHeight >= 80,
            absolute: relatedRefTop - scrollRefHeight < 80,
          })}
          user={leadMember}
          ref={scrollRef}
        />
        {/* <div className='w-96 bg-blue'>
          <StickerNavs />
        </div> */}
        <div className={cn('w-full text-charcoal lg:flex lg:ml-96', {
          'bg-light-grey-2022': !isOpenMenuNavs,
          'bg-white': isOpenMenuNavs
        })} >
          <SearchIcon className='hidden lg:block' />
          <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs}/>
          <div
            className={cn('transition w-full', {
              'visible opacity-100': !isOpenMenuNavs,
              'hidden invisible opacity-0': isOpenMenuNavs,
            })}
          >
            <div className='w-full mx-auto pt-2 lg:pt-24 ' style={{background:'#F0EEE9'}}>
              {project.members?.map((member) => {
                const user = member?.user || {}
                if(user?.image != null){
                return (
                  <div key={user?.id}  className="pt-2">
                    <div className='flex items-center justify-center'>
                      <Link
                        to={{
                          pathname: '/2023/student',
                          search: `id=${user.id}`,
                        }}
                        className='inline-block flex items-center text-coton'
                        style={{color:'#191919'}}
                      >
                        <img
                          src={user?.image}
                          alt='member'
                          className='w-20 h-20 rounded-full mx-auto object-cover'
                          style={{ filter: 'grayscale(100%)' }}
                        />
                        <p className='border-b border-dotted border-mad-blue -mt-2 ml-4 text-4xl' >
                          {user?.firstName} {user?.lastName}
                        </p>
                      </Link>
                    </div>

                    <p className='text-center mt-4' style={{color:'#191919'}}>
                      {user?.primaryExpertise}
                      {user?.secondaryExpertise && `, ${user?.secondaryExpertise}`}
                    </p>
                  </div>
                )
              }
              })}
              <ProjectTitle className='text-6xl lg:text-8xl text-center my-10 px-1 lg:px-16' style={{color:'#191919'}}>
                {project?.title}
              </ProjectTitle>
              <div className='flex justify-center flex-wrap lg:flex-no-wrap'>
                {['primaryProjectType', 'secondaryProjectType', 'secondaryProjectType_2'].map(
                  (key) => {
                    if (project[key]) {
                      return (
                        <div className='flex items-center mr-12' key={key}>
                          <Link
                            to={{
                              pathname: '/2023/work',
                              search: `?type=${projectType[project[key]]}`,
                            }}
                          >
                            <span className='w-3 h-3 bg-secondary rounded-full inline-block' style={{background:'#26439B'}}></span>
                            <span className='inline-block ml-2 border-b border-dotted border-mad-blue' style={{color:'#26439B' , borderColor:'#26439B'}}>
                              {projectType[project[key]] || upperFirst(project[key])}
                            </span>
                          </Link>
                        </div>
                      )
                    }
                    return null
                  },
                )}
              </div>
              <div className='mt-16 md:px-12 xl:px-24' style={{background:'#F0EEE9'}} >
                {project?.contentType?.map((type, i) => {
                  if (type === 'Heading') {
                    return <TitleContent key={i}>{project.contentData[i]}</TitleContent>
                  } else if (type === 'Text') {
                    return (
                      <div className='projectText w-full lg:w-4/6 mx-auto pb-24 px-4 lg:px-0' key={`contenttype-${i}`}>
                        <ReactMarkDown key={i} children={project.contentData[i]} />
                      </div>
                    )
                  } else if (type === 'Image') {
                    return (
                      <div className='w-full lg:w-4/6 mx-auto px-4 lg:px-0' key={i}>
                        <Image
                          src={project.contentData[i]}
                          alt='Image Preview'
                          className='pb-4 lg:pb-12'
                          // onClick={() => handleImgLightbox(project.contentData[i])}
                        />
                      </div>
                    )
                  } else if (type === 'Video') {
                    return (
                      <div key={i} className='w-full lg:w-4/6 mx-auto pb-4 lg:pb-12 lg:px-0'>
                        <video controls className='w-full mx-auto'>
                          <source type='video/mp4' src={project.contentData[i]} />
                        </video>
                      </div>
                    )
                  }
                  return null
                })}
              </div>
            </div>
            <div className='w-12 bg-blue hidden lg:block'>
              {/* <img src='/static/svg/glass.svg' alt='glass' className={cn('cursor-pointer')} /> */}
            </div>
          </div>
          <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
        </div>
      </Container>
      <div style={{color:'#191919',background:'#F0EEE9'}} className={cn('bg-white', {
        'hidden': isOpenMenuNavs,
      })}>
        <TextLogoTwo className='text-center text-secondary py-12' ref={relatedWorkRef} style={{color:'#191919',background:'#F0EEE9'}}>
          RELATED WORK
        </TextLogoTwo>
        <div className='flex text-white justify-between flex-wrap bg-light-grey-2022' style={{color:'#191919',background:'#F0EEE9'}}>
          {similarProjects?.map((_project, idx) => {
            if (idx !== 2) {
              return (
                <div key={_project.id} className='w-1/2'>
                  {<WorkCard project={_project} key={_project.id} />}
                </div>
              )
            }
            return (
              <div key={_project.id} className='w-full grid grid-cols-3'>
                <div></div>
                {<WorkCard project={_project} key={_project.id} className='w-1/2' />}
                <div></div>
              </div>
            )
          })}
          {/* <CurvedContainer>
            <svg viewBox='0 0 500 500' preserveAspectRatio='xMinYMin meet'>
              <path
                d='M0,50 C150,100 350,0 500,10 L500,00 L0,0 Z'
                style={{ keyStroke: 'none', fill: '#333' }}
              ></path>
            </svg>
          </CurvedContainer> */}
        </div>
      </div>
      <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs}/>
      <WorkBGBottom>
        <img
            src={`/static/svg/2023/Work/WorkBGBottom.png`}
            alt=""
        />
      </WorkBGBottom>
      <FixedBottomNav className="hidden lg:block"/>
      <FooterContainer data-color="off_white" >
        <Footer/>
      </FooterContainer>
    </Main>
  )
}

const PUBLISHED_PROJECT = gql`
  query PUBLISHED_PROJECT($id: ID!) {
    publishedProject(id: $id) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        role
        user {
          id
          firstName
          lastName
          image
          tagline
          program
          contactEmail
          primaryExpertise
          secondaryExpertise
          about
          image
          portfolio
          behance
          linkedIn
          instagram
          twitter
          facebook
          dribble
          youtube
          vimeo
          medium
        }
      }
      published
    }
  }
`

const ALL_PROJECTS_QUERY = gql`
  query ALL_PROJECTS_QUERY($where: ProjectWhereInput, $skip: Int = 0, $first: Int = 24) {
    projects(where: $where, first: $first, skip: $skip, orderBy: createdAt_DESC) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
    }
  }
`
