import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  p {
    text-align: left;
    padding-left: 30px;
    color: #191919;
    font-size: 1.3em;
    position: absolute;
    line-height: 60px;
    margin-bottom: 0;

  }
  position: relative;

  img {
    top: 36px;
    color: #191919;
    // right: 41%;
    transition: transform 330ms;
  }

  input {
    font-size: 2rem;
    font-family: 'grotesque_pro_light';
    height: 60px;
    border-radius: 30px;
    padding: 5px 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 3px solid ${(props) => (props.hasError ? 'red' : '#71DF71')};
    display: ${(props) => (props.submitted ? 'none' : 'block')};
  }

  input:hover {
    border: 3px solid #ff8a48;
  }
  input:focus {
    outline: none;
    border: 3px solid #ff8a48;
  }

  @media (min-width: 640px) {
    #email {
      width: 480px;
    }
  }
`

export const InputSearch = ({ onChange, value, onSearchClick }) => {
    return (
      <Container className='flex relative z-10 text-center items-center justify-center'>
        <div className='relative w-full lg:w-[480px] '>
          <input
            placeholder='Search the website'
            className='text-almost-black bg-beige-yellow placeholder-almost-black border border-almost-black w-full'
            onChange={onChange}
            value={value}
          />
          <img className='absolute right-10 cursor-pointer' src='/static/2024/svg/search.svg' alt='Search' onClick={onSearchClick}/>
        </div>

      </Container>
    )

}
