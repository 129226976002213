import React from 'react'

import { gql, useMutation } from '@apollo/client'
import { withRouter, useHistory } from 'react-router-dom'

const SIGN_OUT_MUTATION = gql`
  mutation SIGN_OUT_MUTATION {
    signout {
      message
    }
  }
`
const Signout = (props) => {
  const history = useHistory()
  const [signout] = useMutation(SIGN_OUT_MUTATION)
  return (
    <button
      onClick={async () => {
        signout().then(() => {
          localStorage.removeItem('token')
          history.push('/login')
          window.location.reload()
        })
      }}
      style={{ color: '#000' }}
    >
      Sign Out
    </button>
  )
}

export default withRouter(Signout)
