import React, { useEffect } from 'react'
import { useState } from 'react'
import { client } from '../..'
import Title from './styles/Title'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ProjectItemStyles from './styles/ProjectItemStyles'
import { gql } from 'apollo-boost'
import uniq from 'lodash.uniq'

const More = styled.p`
  margin: 50px;
  text-align: left;
`

const ProjectsContainer = styled.div`
  margin-top: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0;
  width: 100vw;
  max-width: 100vw;
  gap: 0;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`
const ALL_PROJECTS_QUERY = gql`
  query ALL_PROJECTS_QUERY($where: ProjectWhereInput, $skip: Int = 0, $first: Int = 24) {
    projects(where: $where, first: $first, skip: $skip, orderBy: createdAt_DESC) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
    }
  }
`

const SimilarProjects = ({ currentProjectId, type = '' }) => {
  const [projects, setProjects] = useState([])

  useEffect(() => {
    client
      .query({ query: ALL_PROJECTS_QUERY, variables: { where: { published: true }, first: 30 } })
      .then(({ data }) => {
        const projects = data.projects.filter((pj) => pj.primaryProjectType === type)
        if (projects.length > 0) {
          const r1 = Math.floor(Math.random() * projects.length)
          const r2 = Math.floor(Math.random() * projects.length)
          const r3 = Math.floor(Math.random() * projects.length)
          const rdArr = uniq([r1, r2, r3])
          const res = rdArr.map((idx) => projects[idx])
          setProjects(res)
        }
      })
  }, [currentProjectId, type])

  const styles = {
    float: 'right',
    textAlign: 'right',
    width: '70%',
    padding: '4px',
    marginRight: '50px',
  }

  const divStyles = {
    position: 'relative',
    left: '-50px',
  }

  const titleStyles = {
    fontFamily: "grotesque_pro_light",
    fontWeight: "normal",
    textAlign: "left"
  }

  return (
    <div style={divStyles}>
      {projects.length > 0 && <More>More like this</More>}
      <ProjectsContainer>
        {projects &&
          projects.length > 0 &&
          projects.map((project, index) => {
            let ppt = (project && project.primaryProjectType.replace('_', ' ')) || ''
            let splitStr = ppt.split(' ')
            for (var i = 0; i < splitStr.length; i++) {
              // You do not need to check if i is larger than splitStr length, as your for does that for you
              // Assign it back to the array
              splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
              if (splitStr[i].charAt(0) === '3') {
                splitStr[i] =
                  splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1).toUpperCase()
              }
            }
          
          const projectType = splitStr.join(" ");
          return (
            <ProjectItemStyles key={`${project.id}-${index}`}>
              <Link
                to={{
                  pathname: "/2020/project",
                  search: `?id=${project.id}`,
                }}
              >
                {project.coverImage && (
                  <img
                    src={project.coverImage}
                    style={{ width: 300 }}
                    alt={project.title}
                  />
                )}
                <Title style={titleStyles}>
                    {project.title}
                </Title>
              </Link>
              <p style={styles}>{projectType}</p>
            </ProjectItemStyles>
          );
        })}

      </ProjectsContainer>
    </div>
  )
}

export default SimilarProjects
