import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Title from "./styles/Title";
import ProjectItemStyles from "./styles/ProjectItemStyles";

export default class Project extends Component {
  static propTypes = {
    project: PropTypes.object.isRequired
  };
  render() {
    const { project } = this.props;
    const primaryProjectType = project.primaryProjectType;
    let ppt = primaryProjectType.replace("_", " ");
    let splitStr = ppt.split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      if(splitStr[i].charAt(0)==="3"){
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1).toUpperCase();
      }
    }
    const projectType = splitStr.join(' ');
    const styles = {
      float: 'right',
      textAlign: 'right',
      width: '60%',
      padding: 0,
    }
    return (
      <ProjectItemStyles>
        <Link
          to={{
            pathname: "/2020/project",
            search: `id=${project.id}`,
          }}
        >
          {project.coverImage && (
            <img src={project.coverImage} alt={project.title} />
          )}
        </Link>
        <Title>
          <Link
            to={{
              pathname: "/2020/project",
              search: `id=${project.id}`,
            }}
          >
            {project.title}
          </Link>
        </Title>
        <Link
          to={{
            pathname: "/2020/work",
            search: `?type=${primaryProjectType}`,
          }}
        >
          <p style={styles}>{projectType}</p>
        </Link>
      </ProjectItemStyles>
    );
  }
}
