import React, { useState, useEffect } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import styled from 'styled-components'
import ProfileForm from './styles/ProfileForm'
import InputLimit, { LengthLimitTextarea } from './styles/InputLimit'
import Error from './ErrorMessage'
import { CURRENT_USER_QUERY } from './User'
import cogoToast from 'cogo-toast'
import ReorderProjects from './ReorderProjects'
import Loading from './Loading'

const MAX_IMG_SIZE = 5 * 1024 * 1024


const EditProfile = () => {
  const [profilePicture, setProfilePicture] = useState()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [tagline, setTagline] = useState()
  const [program, setProgram] = useState()
  const [primaryExpertise, setPrimaryExpertise] = useState()
  const [secondaryExpertise, setSecondaryExpertise] = useState()
  const [about, setAbout] = useState()
  const [contactEmail, setContactEmail] = useState()
  const [portfolio, setPortfolio] = useState()
  const [behance, setBehance] = useState()
  const [linkedIn, setLinkedIn] = useState()
  const [instagram, setInstagram] = useState()
  const [twitter, setTwitter] = useState()
  const [facebook, setFacebook] = useState()
  const [dribble, setDribble] = useState()
  const [youtube, setYoutube] = useState()
  const [vimeo, setVimeo] = useState()
  const [medium, setMedium] = useState()
  const [image, setImage] = useState()
  const [uploadFinished, setUploadFinished] = useState(false)
  const [firstName_error, setFirstName_error] = useState()
  const [lastName_error, setLastName_error] = useState()
  const [tagline_error, setTagline_error] = useState()
  const [program_error, setProgram_error] = useState()
  const [primaryExpertise_error, setPrimaryExpertise_error] = useState()
  const [secondaryExpertise_error, setSecondaryExpertise_error] = useState()
  const [about_error, setAbout_error] = useState()
  const [portfolio_error, setPortfolio_error] = useState()
  const [instagram_error, setInstagram_error] = useState()
  const [twitter_error, setTwitter_error] = useState()
  const [dribble_error, setDribble_error] = useState()

  const { data, loading } = useQuery(CURRENT_USER_QUERY)
  const [uploadFile] = useMutation(USER_UPLOAD_FILE)
  const [mutateProfile, { loading: mutationLoading, error: mutationError }] =
    useMutation(EDIT_PROFILE_MUTATION)

  useEffect(() => {
    if (data && !loading) {
      const {
        firstName,
        lastName,
        tagline,
        program,
        primaryExpertise,
        secondaryExpertise,
        about,
        contactEmail,
        portfolio,
        behance,
        linkedIn,
        instagram,
        twitter,
        facebook,
        dribble,
        youtube,
        vimeo,
        medium,
        image,
      } = data.me
      setFirstName(firstName)
      setLastName(lastName)
      setTagline(tagline)
      setProgram(program)
      setPrimaryExpertise(primaryExpertise)
      setSecondaryExpertise(secondaryExpertise)
      setAbout(about)
      setContactEmail(contactEmail)
      setPortfolio(portfolio)
      setBehance(behance)
      setLinkedIn(linkedIn)
      setInstagram(instagram)
      setTwitter(twitter)
      setFacebook(facebook)
      setDribble(dribble)
      setYoutube(youtube)
      setVimeo(vimeo)
      setMedium(medium)
      setImage(image)
      setProfilePicture(image)
    }
  }, [data, loading])

  const handleChange = (e, stateFunction, errorFunction) => {
    const { name, type, value } = e.target
    const val = type === 'number' ? parseFloat(value) : value
    stateFunction(val)
    errorFunction(undefined)

  }

  const handleFileUpload = async (e) => {
    const { name } = e.target
    console.log('Uploading File')
    const files = e.target.files
    const file = files[0]
    if (file.size > MAX_IMG_SIZE) {
      alert('Maximum image size is 5MB')
      setUploadFinished(true)
      e.target.value = null
      return
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async (event) => {
        if (reader.error) {
          console.error('FileReader error:', reader.error)
        } else if (event.total > MAX_IMG_SIZE) {
          alert('Maximum image size is 5MB')
          return
        } else {
          try {
            uploadFile({ variables: { file } }).then(({ data }) => {
              const profilePicture = data.singleUpload.path
              setProfilePicture(profilePicture)
              setUploadFinished(true)
            })
          } catch (error) {
            console.error('Error uploading file:', error)
          }
          event.target.value = '' // Optionally reset the input after processing
        }
      }
    }
  }

  const editProfile = async (e, editProfileMutation, meId) => {
    e.preventDefault()
    const isValid = validateFields()
    if (isValid) {
      const profileObj = {
        firstName,
        lastName,
        tagline,
        program,
        primaryExpertise,
        secondaryExpertise,
        about,
        image: profilePicture,
        contactEmail,
        portfolio,
        behance,
        linkedIn,
        instagram,
        twitter,
        facebook,
        dribble,
        youtube,
        medium,
        vimeo,
      }
      try {
        await editProfileMutation({
          variables: {
            id: meId,
            ...profileObj,
          },
        })
        cogoToast.success('Your profile updated successfully')
      } catch (error) {
        cogoToast.error('Failed when trying to update profile')
      }
    }
  }

  const validateFields = () => {
    const obj = {
      firstName,
      lastName,
      tagline,
      program,
      primaryExpertise,
      secondaryExpertise,
      about,
      contactEmail,
      portfolio,
      behance,
      linkedIn,
      instagram,
      twitter,
      facebook,
      dribble,
      youtube,
      medium,
      vimeo,
    }
    const errorObj = {}
    for (const key of Object.keys(obj)) {
      if (!key || key.length < 2) {
        // this.setState({ [`${key}_error`]: `Require an input` });
        errorObj[`${key}_error`] = `Require an input`
        // return false;
      }
    }
    if (Object.keys(errorObj).length > 0) {
      this.setState({ ...errorObj })
      return false
    }
    return true
  }

  if (loading) return <Loading />
  if (!data.me) return <p>No User Found for ID: {data.me.id}</p>
  return (
    <ProfileForm onSubmit={(e) => editProfile(e, mutateProfile, data.me.id)}>
      <Error error={mutationError} />
      <fieldset disabled={loading} aria-busy={loading}>
        <Grid>
          <div>
            <HelveticaTitle>Bio</HelveticaTitle>
            <Columns>
              <label htmlFor='firstName'>
                First Name
                <input
                  type='text'
                  id='firstName'
                  name='firstName'
                  placeholder=''
                  defaultValue={data.me.firstName}
                  onChange={(e) => handleChange(e, setFirstName, setFirstName_error)}
                />
                {firstName_error && <ErrorInput>{firstName_error}</ErrorInput>}
              </label>
              <label htmlFor='lastName'>
                Last Name
                <input
                  type='text'
                  id='lastName'
                  name='lastName'
                  placeholder=''
                  defaultValue={data.me.lastName}
                  onChange={(e) => handleChange(e, setLastName, setLastName_error)}
                />
                {lastName_error && <ErrorInput>{lastName_error}</ErrorInput>}
              </label>
            </Columns>

            <Columns>
              <label htmlFor='Program'>
                Program
                <select name='program' onChange={(e) => handleChange(e, setProgram, setProgram_error)} defaultValue={data.me.program}>
                  {PROGRAMS.map((program, index) => (
                    <option key={`${program}-${index}`} value={program}>
                      {program}
                    </option>
                  ))}
                </select>
                {program_error && <ErrorInput>{program_error}</ErrorInput>}
              </label>
              <label htmlFor='tagline'>
              Pronouns
              <InputLimit
                type='text'
                id='tagline'
                name='tagline'
                placeholder='Pronouns'
                maxLength={80}
                value={tagline}
                onChange={(e) => handleChange(e, setTagline, setTagline_error)}
                fromtop={44}
              />
              {tagline_error && <ErrorInput>{tagline_error}</ErrorInput>}
            </label>
            </Columns>
            <Columns>
              <label htmlFor='primaryExpertise'>
                Primary Expertise
                <select name='primaryExpertise' onChange={(e) => handleChange(e, setPrimaryExpertise, setPrimaryExpertise_error)} defaultValue={data.me.primaryExpertise}>
                  {Object.keys(DISCIPLINES).map((discipline, index) => (
                    <option key={`${discipline}-${index}`} value={discipline}>
                      {DISCIPLINES[discipline]}
                    </option>
                  ))}
                </select>
              </label>
              {primaryExpertise_error && <ErrorInput>{primaryExpertise_error}</ErrorInput>}
              <label htmlFor='secondaryExpertise'>
                Secondary Expertise
                <select name='secondaryExpertise' onChange={(e) => handleChange(e, setSecondaryExpertise, setSecondaryExpertise_error)} defaultValue={data.me.secondaryExpertise}>
                  {Object.keys(DISCIPLINES).map((discipline, index) => (
                    <option key={`${discipline}-${index}`} value={discipline}>
                      {DISCIPLINES[discipline]}
                    </option>
                  ))}
                </select>
              </label>
            </Columns>
            <label htmlFor='about' style={{ marginBottom: '2rem' }}>
              About
              <LengthLimitTextarea
                type='text'
                id='about'
                name='about'
                placeholder='About'
                maxLength={200}
                value={about}
                onChange={(e) => handleChange(e, setAbout, setAbout_error)}
                fromtop={26}
              />
              {about_error && <ErrorInput>{about_error}</ErrorInput>}
            </label>
            <label htmlFor='portfolio'>
              Portfolio
              <input
                type='url'
                id='portfolio'
                name='portfolio'
                placeholder='Portfolio'
                defaultValue={data.me.portfolio}
                onChange={(e) => handleChange(e, setPortfolio, setPortfolio_error)}
              />
            </label>
            <label htmlFor='instagram'>
              Instagram
              <input
                type='url'
                id='instagram'
                name='instagram'
                placeholder='Instagram Profile Link'
                defaultValue={data.me.instagram}
                onChange={(e) => handleChange(e, setInstagram, setInstagram_error)}
              />
            </label>
            <label htmlFor='twitter'>
              X
              <input
                type='url'
                id='twitter'
                name='twitter'
                placeholder='X Profile Link'
                defaultValue={data.me.twitter}
                onChange={(e) => handleChange(e, setTwitter, setTwitter_error)}
              />
            </label>
            <label htmlFor='dribble'>
              Tiktok
              <input
                type='url'
                id='dribble'
                name='dribble'
                placeholder='Tiktok Profile Link'
                defaultValue={data.me.dribble}
                onChange={(e) => handleChange(e, setDribble, setDribble_error)}
              />
            </label>
            <button type='submit'>UPDAT{loading ? 'ING' : 'E'}</button>
          </div>
          <div>
            <HelveticaTitle>Profile Picture</HelveticaTitle>
            <label htmlFor='image'>
              Image
              <UploadBlock>
                <input
                  type='file'
                  id='image'
                  name='image'
                  placeholder='Upload an image'
                  onChange={handleFileUpload}
                  className='upload-input'
                />
                {profilePicture ? (
                  <CoverImage src={profilePicture} alt='Upload Preview' />
                ) : (
                  <img className='upload-placeholder' src={'/static/drawing.svg'} alt='' />
                )}
                <UploadIcon src='/static/edit.svg' alt='Change image' />

              </UploadBlock>
            </label>
            <HelveticaTitle>Project Profile Order</HelveticaTitle>
            <ReorderProjects meId={data?.me?.id} />
          </div>
        </Grid>
      </fieldset>
    </ProfileForm>
  )
}

export default EditProfile



const EDIT_PROFILE_MUTATION = gql`
  mutation EDIT_PROFILE_MUTATION(
    $id: ID!
    $firstName: String
    $lastName: String
    $tagline: String
    $program: String
    $contactEmail: String
    $primaryExpertise: String
    $secondaryExpertise: String
    $about: String
    $image: String
    $portfolio: String
    $behance: String
    $linkedIn: String
    $instagram: String
    $twitter: String
    $facebook: String
    $dribble: String
    $youtube: String
    $vimeo: String
    $medium: String
  ) {
    editProfile(
      id: $id
      firstName: $firstName
      lastName: $lastName
      tagline: $tagline
      program: $program
      contactEmail: $contactEmail
      primaryExpertise: $primaryExpertise
      secondaryExpertise: $secondaryExpertise
      about: $about
      image: $image
      portfolio: $portfolio
      behance: $behance
      linkedIn: $linkedIn
      instagram: $instagram
      twitter: $twitter
      facebook: $facebook
      dribble: $dribble
      youtube: $youtube
      vimeo: $vimeo
      medium: $medium
    ) {
      id
      firstName
      lastName
      tagline
      program
      contactEmail
      primaryExpertise
      secondaryExpertise
      about
      image
      portfolio
      behance
      linkedIn
      instagram
      twitter
      facebook
      dribble
      youtube
      vimeo
      medium
    }
  }
`
export { EDIT_PROFILE_MUTATION }

const USER_UPLOAD_FILE = gql`
  mutation ($file: Upload!, $isVideo: Boolean) {
    singleUpload(file: $file, isVideo: $isVideo) {
      id
      path
      filename
    }
  }
`

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 20px;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`

const PROGRAMS = [
  'Brand Design',
  'Graphic Design',
  'Interaction Design',
  'Game - Art',
  'Digital Experience Design',
  'Art & Design Foundation',
  'Interactive Media Management',
  'Interdisciplinary Design Strategy',
  'Digital Design - Game Design',
  'Concept Art for Entertainment',
  'Design Management',
  'Front-End Design',
]

const DISCIPLINES = {
  '3d_design': '3D Design',
  '3d_sculpting': '3D Sculpting',
  '3d_visualizations': '3D Visualizations',
  advertising: 'Advertising',
  animation: 'Animation',
  augmented_virtual_reality: 'Augmented/Virtual Reality',
  branding: 'Branding',
  device_development: 'Device Development',
  digital_applications: 'Digital Applications',
  drawing: 'Drawing',
  editorial: 'Editorial',
  exhibition: 'Exhibition',
  game_design: 'Game Design',
  illustration: 'Illustration',
  immersive_environments: 'Immersive Environments',
  interactive_design: 'Interactive Design',
  interface_development: 'Interface Development',
  media_production: 'Media Production',
  modeling: 'Modeling',
  motion_graphics: 'Motion Graphics',
  packaging: 'Packaging',
  photography: 'Photography',
  physical_computing: 'Physical Computing',
  strategy: 'Strategy',
  systems_and_services: 'Systems and Services',
  technological_systems: 'Technological Systems',
  typography: 'Typography',
  user_experience: 'User Experience',
  video: 'Video',
  web_design: 'Web Design',
}

const HelveticaTitle = styled.p`
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: black;
  font-size: 24px;
  margin-top: 50px;
  text-align: center;
`

const UploadBlock = styled.div`
  background-color: #e3e3e3;
  position: relative;
  display: block;
  width: 100%;

  overflow: hidden;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .upload-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(21.5em + 6.75rem + 2px);
    margin: 0;
    opacity: 0;
  }

  .upload-placeholder {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.5;
    z-index: 2;
  }
`

const UploadIcon = styled.img`
  background: rgb(243, 215, 222);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  height: 50px;
  width: 50px;
  padding: 10px;

  &:hover,
  &:focus {
    outline: none;
  }
`

const CoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`

const ErrorInput = styled.p`
  font-size: 16px;
  color: red;
`
