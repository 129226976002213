import React, { useEffect, useState } from 'react'
import { withRouter, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Nav from './Nav'
import UserNav from './UserNav'
import Marquee from 'react-fast-marquee'

const UserLogo = styled.h1`
  font-size: 2rem;
  margin-left: 2rem;
  position: relative;
  z-index: 2;

  a {
    // padding: 0.5rem 1rem;
    color: ${(props) => props.theme.white};
    text-transform: uppercase;
    text-decoration: none;
  }

  img {
    margin-top: 10px;
    width: 50px;
  }
`

const UserStyledHeader = styled.header`
  position: fixed;
  z-index: 2001;
  width: 100vw;
  .bar {
    background-color: ${(props) => props.theme.black};
    color: ${(props) => props.theme.white};
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    z-index: 10;
    /* @media (max-width: 1300px) {
      grid-template-columns: 1fr;
      justify-content: center;
    } */
  }
  .sub-bar {
    display: grid;
    grid-template-columns: 1fr auto;
    border-bottom: 1px solid ${(props) => props.theme.lightgrey};
  }
`

const Logo = styled.h1`
  flex-grow: 1;
  font-size: 2rem;
  margin-bottom: 0;
  margin-left: 10px;
  position: relative;
  z-index: 2;

  @media (min-width: 768px) {
    margin-left: 20px;
  }

  a {
    // padding: 0.5rem 1rem;
    color: ${(props) => props.theme.black};
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      color: ${(props) => props.theme.black} !important;
    }
  }

  img {
    margin-top: 10px;
    width: 50px;
  }

  /* @media (max-width: 1300px) {
    margin: 0;
    text-align: center;
  } */
`

const StyledHeader = styled.header`
  position: relative;
  z-index: 2001;
  width: 100%;
  max-width: 100vw;
  @media (min-width: 768px) {
    position: fixed;
  }

  .bar {
    color: ${(props) => props.theme.white};
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    z-index: 1000;
    background-color: white;
    flex-wrap: wrap;
  }

  .sub-bar {
    display: grid;
    grid-template-columns: 1fr auto;
    border-bottom: 1px solid ${(props) => props.theme.lightgrey};
  }

  .marquee {
    height: 50px;
    background-color: ${(props) => props.theme.pink};
    color: ${(props) => props.theme.black};
    overflow: hidden;
    font-size: 28px;
    font-family: 'grotesque_pro_light';
    line-height: 2;
  }

  // .marquee:hover {
  //   background-color: ${(props) => props.theme.black};
  //   color: #f7d8e0 !important;
  // }

  .marqueeItem {
    display: flex;
  }

  .marqueeItem:hover {
    background-color: ${(props) => props.theme.black};
  }

  .marqueeContainer:hover {
    color: #f7d8e0 !important;
    background-color: ${(props) => props.theme.black};
  }

  // .marquee:hover a {
  //   color: #f7d8e0 !important;
  // }

  // .marquee:hover span {
  //   color: #f7d8e0 !important;
  // }

  // .marqueeInner {
  //   display: block;
  //   width: 8000px;
  //   position: absolute;
  //   animation: marquee 20s linear infinite;
  // }

  // .marquee:hover .marqueeInner {
  //   -webkit-animation-play-state: paused;
  //   -moz-animation-play-state: paused;
  //   -o-animation-play-state: paused;
  //   animation-play-state: paused;
  // }

  .marqueeContainer {
    height: 50px;
    display: inline-block;
    margin: 0 4px;
    float: left;
    transition: all 0.4s ease-out;
  }

  // .marqueeEx {
  //   height: 50px;
  //   display: inline-block;
  //   margin: 0 2px;
  //   float: left;
  //   transition: all 0.4s ease-out;
  // }

  .marqueeContainer:hover {
    text-decoration: underline;
  }

  // span {
  //   float: left;
  //   width: 4000px;
  //   margin: 0;
  // }

  // @keyframes marquee {
  //   0% {
  //     left: 0;
  //   }
  //   100% {
  //     left: -100%;
  //   }
  // }
  @media (max-width: 768px) {
    span {
      width: 8000px;
    }
    .marqueeInner {
      width: 16000px;
      animation: marquee 60s linear infinite;
    }
    @keyframes marquee {
      0% {
        left: 10%;
      }
      100% {
        left: -1440%;
      }
    }
  }
`

const Header = (props) => {
  const { marquee, shouldRenderNav } = props

  // there's 3 states:
  // "default" will render marquee
  // "black" will render black header
  // "none" will return null
  const [renderType, setRenderType] = useState('default')
  const location = useLocation()

  useEffect(() => {
    const blackAllowed = ['/projects', '/edit', '/login', '/update', '/add', '/signup']
    const nullPrefix = ['/project', '/student', '/teaser']
    const { pathname, search } = location

    if (blackAllowed.includes(pathname)) {
      setRenderType('black')
    }
    if (nullPrefix.includes(pathname) && search) {
      setRenderType(null)
    }
    if (pathname === '/') {
      setRenderType(null)
    }
    if (pathname.startsWith('/teaser')) {
      setRenderType(null)
    }
  }, [location])

  switch (renderType) {
    case 'black': {
      return (
        <UserStyledHeader>
          <div className='bar'>
            <UserLogo>
              <Link to='/2020'>
                <img src={'/static/YES_2_RGB Grey.png'} alt='logo' />
              </Link>
            </UserLogo>
            <UserNav />
          </div>
          {/* <div className="sub-bar">
                  <p>Search</p>
              </div> */}
        </UserStyledHeader>
      )
    }
    case 'default': {
      return (
        <StyledHeader>
          {marquee && (
            <Marquee speed={130} pauseOnHover={true} gradientColor='#efd6d9'>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=3d_design',
                  }}
                >
                  <div className='marqueeContainer'>3D Design!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=3d_sculpting',
                  }}
                >
                  <div className='marqueeContainer'>3D Sculpting!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=3d_visualizations',
                  }}
                >
                  <div className='marqueeContainer'>3D Visualizations!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=advertising',
                  }}
                >
                  <div className='marqueeContainer'>Advertising!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=animation',
                  }}
                >
                  <div className='marqueeContainer'>Animation!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=augmented_virtual_reality',
                  }}
                >
                  <div className='marqueeContainer'>Augmented/Virtual Reality!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=branding',
                  }}
                >
                  <div className='marqueeContainer'>Branding!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=digital_applications',
                  }}
                >
                  <div className='marqueeContainer'>Digital Applications!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=drawing',
                  }}
                >
                  <div className='marqueeContainer'>Drawing!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=editorial',
                  }}
                >
                  <div className='marqueeContainer'>Editorial!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=exhibition',
                  }}
                >
                  <div className='marqueeContainer'>Exhibition!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=illustration',
                  }}
                >
                  <div className='marqueeContainer'>Illustration!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=immersive_environments',
                  }}
                >
                  <div className='marqueeContainer'>Immersive Environments!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=interactive_design',
                  }}
                >
                  <div className='marqueeContainer'>Interactive Design!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=interface_development',
                  }}
                >
                  <div className='marqueeContainer'>Interface Development!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=media_production',
                  }}
                >
                  <div className='marqueeContainer'>Media Production!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=modeling',
                  }}
                >
                  <div className='marqueeContainer'>Modeling!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=motion_graphics',
                  }}
                >
                  <div className='marqueeContainer'>Motion Graphics!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=packaging',
                  }}
                >
                  <div className='marqueeContainer'>Packaging!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=photography',
                  }}
                >
                  <div className='marqueeContainer'>Photography!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=physical_computing',
                  }}
                >
                  <div className='marqueeContainer'>Physical Computing!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=strategy',
                  }}
                >
                  <div className='marqueeContainer'>Strategy!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=systems_and_services',
                  }}
                >
                  <div className='marqueeContainer'>Systems and Services!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=technological_systems',
                  }}
                >
                  <div className='marqueeContainer'>Technological Systems!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=user_experience',
                  }}
                >
                  <div className='marqueeContainer'>User Experience!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=video',
                  }}
                >
                  <div className='marqueeContainer'>Video!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
              <div className='marqueeItem'>
                <Link
                  to={{
                    pathname: '/2020/work',
                    search: '?type=web_design',
                  }}
                >
                  <div className='marqueeContainer'>Web Design!</div>
                </Link>
              </div>
              <p className='marqueeEx'>&nbsp;</p>
            </Marquee>
          )}
          <div className='bar'>
            {shouldRenderNav && (
              <>
                <Logo>
                  <Link to='/2020'>
                    <img src={'/static/logo.svg'} alt='logo' />
                  </Link>
                </Logo>
                <Nav />
              </>
            )}
          </div>
          {/* <div className="sub-bar">
                <p>Search</p>
            </div> */}
        </StyledHeader>
      )
    }
    default: {
      return (
        <StyledHeader>
          <div className='bar'>
            {shouldRenderNav && (
              <>
                <Logo>
                  <Link to='/2020'>
                    <img src={'/static/logo.svg'} alt='logo' />
                  </Link>
                </Logo>
                <Nav />
              </>
            )}
          </div>
          {/* <div className="sub-bar">
            <p>Search</p>
        </div> */}
        </StyledHeader>
      )
    }
  }
}

export default withRouter(Header)
