import React, { useCallback } from 'react'
import cn from 'classnames'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const SearchImage = styled.img`
  position: fixed;
  top: 0px;
  right: 0px;
  width: 30px;
  z-Index: 1013;
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`

export default function SearchIcon({ className = '', goBack = false }) {
  const history = useHistory()
  const { pathname } = useLocation()

  const navigate = useCallback(
    (url) => {
      if (pathname?.startsWith(url)) {
        return
      }

      const leftLayer = document.getElementsByClassName('left-layer')?.[0]
      const rightLayer = document.getElementsByClassName('left-layer')?.[0]
      if (leftLayer && rightLayer) {
        leftLayer.classList.add('active')
        rightLayer.classList.add('active')
        setTimeout(() => history.push(url), 1500)
      }
    },
    [history, pathname],
  )

  return (
    <SearchImage
      src='/static/svg/2023/glass.svg'
      alt='glass'
      className={cn('cursor-pointer')}
      onClick={() => navigate('/2023/search')}
    />
  )
}
