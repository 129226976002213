import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { Psm } from '../../styles/2024/TextStyles'
import { programGroups24Encoded, projectType, disciplines } from '../../constant'
import { isMobile } from 'react-device-detect'
const FilterSelect = styled.div`
  border-radius: 48px;
`

export const DropdownFilter = ({ program, type }) => {
  const [isProgramFilter, setIsProgramFilter] = useState(true)
  const [isFilterClicked, setIsFilterClicked] = useState(false)
  const [selectedFilterItem, setSelectedFilterItem] = useState('')
  const eventDetailsRef = useRef({ currentTarget: null, target: null })
  const { pathname } = useLocation()
  require('./scrollbar.scss')

  useEffect(() => {
    program && setSelectedFilterItem(program)
    type && setSelectedFilterItem(type)
  }, [program, type])

  const filterSelectClass = (selectedFilter) =>
    cn(
      'rounded bg-almost-black text-beige-yellow px-6 py-2 border border-beige-yellow cursor-pointer',
      {
        'bg-comfort-green !text-almost-black border-comfort-green': selectedFilter,
      },
    )

  const filterProgramSelectClass = (group) =>
    cn(
      `w-full flex items-center justify-center h-[64px] border ${
        !isFilterClicked ? ' border-beige-yellow' : ' border-comfort-green lg:border-beige-yellow '
      } text-beige-yellow hover:text-almost-black hover:bg-comfort-green`,
      {
        '!text-almost-black bg-comfort-green': selectedFilterItem === decodeURI(group),
      },
    )

  const filterDisciplineSelectClass = (group) =>
    cn(
      `w-full flex items-center justify-center h-[64px] border ${
        isFilterClicked ? ' border-beige-yellow' : ' border-comfort-green lg:border-beige-yellow '
      } text-beige-yellow hover:text-almost-black hover:bg-comfort-green`,
      {
        '!text-almost-black bg-comfort-green': selectedFilterItem === group,
      },
    )

  const handleFilterClick = (e) => {

    setIsFilterClicked((current) => !current) // Toggle the state
    // Store event details in a ref
    eventDetailsRef.current = {
      currentTarget: e.currentTarget,
      target: e.target,
    }
  }

  useEffect(() => {
     if (isMobile && !isFilterClicked) {
       // Extract the saved event details
       // console.log('eventDetailsRef', eventDetailsRef)
       const { currentTarget, target } = eventDetailsRef.current
       if (currentTarget && target) {
         const offsetDiff = target.offsetTop - currentTarget.offsetTop
         currentTarget.scrollTop = offsetDiff
       }
     }
   }, [isFilterClicked])


   useEffect(() => {
      if (isMobile) {
        const { currentTarget, target } = eventDetailsRef.current
        if (currentTarget && target) {
          const offsetDiff = 0
          currentTarget.scrollTop = offsetDiff
        }
      }
   }, [isProgramFilter])


  return (
    <div className='w-full grid grid-cols-1 gap-10 text-beige-yellow'>
      <div className='w-full flex gap-4'>
        <FilterSelect
          className={filterSelectClass(isProgramFilter)}
          onClick={() => setIsProgramFilter(true)}
        >
          <Psm className='relative'>{'Filter by program'}</Psm>
        </FilterSelect>
        <FilterSelect
          className={filterSelectClass(!isProgramFilter)}
          onClick={() => setIsProgramFilter(false)}
        >
          <Psm className='relative'>{'Filter by discipline'}</Psm>
        </FilterSelect>
      </div>
      {isProgramFilter ? (
        <div
          className={`grid grid-cols-1 grid-rows-auto lg:grid-cols-6 ${
            isFilterClicked
              ? ' h-[380px] lg:h-[128px] overflow-scroll green-scrollbar pr-2 lg:pr-0 lg:no-scrollbar lg:overflow-hidden'
              : ' h-[64px] lg:h-[128px] overflow-hidden'
          }`}
          onClick={(e) => handleFilterClick(e)}
          style={{ overflowX: 'hidden' }}
        >
          <Link
            className={filterProgramSelectClass('')}
            onClick={() => setSelectedFilterItem('')}
            to={{
              pathname,
            }}
          >
            <Psm className='flex items-center justify-center text-center h-full w-full relative'>
              Show All
            </Psm>
          </Link>
          {programGroups24Encoded.map((group) => (
            <Link
              className={filterProgramSelectClass(group)}
              to={{
                pathname,
                search: `?program=${group}`,
              }}
              key={group}
              onClick={() => setSelectedFilterItem(group)}
            >
              <Psm className='flex items-center justify-center h-full w-full relative px-12 text-center'>
                {decodeURI(group)}
              </Psm>
            </Link>
          ))}
        </div>
      ) : (
        <div
          className={`grid grid-cols-1 grid-rows-auto lg:grid-cols-6 ${
            isFilterClicked
              ? ' h-[380px] lg:h-[128px] overflow-scroll green-scrollbar pr-2 lg:pr-0 lg:no-scrollbar'
              : ' h-[64px] lg:h-[128px] overflow-hidden'
          }`}
          onClick={(e) => handleFilterClick(e)}
          style={{ overflowX: 'hidden' }}
        >
          <Link
            className={filterDisciplineSelectClass('')}
            onClick={() => setSelectedFilterItem('')}
            to={{
              pathname,
            }}
          >
            <Psm className='flex items-center justify-center w-full h-full relative'>Show All</Psm>
          </Link>
          {Object.keys(disciplines).map((group) => (
            <Link
              className={filterDisciplineSelectClass(group)}
              to={{
                pathname,
                search: `?type=${group}`,
              }}
              key={group}
              onClick={() => setSelectedFilterItem(group)}
            >
              <Psm className='flex items-center justify-center w-full h-full relative'>
                {disciplines[group]}
              </Psm>
            </Link>
          ))}
        </div>
      )}

      {/* <ExploreG className='relative w-full md:w-1/2 xl:w-1/3 max-w-xl'>
        <input
          type='text'
          id='gname'
          name='gname'
          placeholder='Grad'
          onChange={(e) => onChange(e.target.value)}
        />
        <VscSearch className='absolute text-5xl' />
      </ExploreG> */}
    </div>
  )
}
