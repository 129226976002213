import React, { useCallback, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useWindowSize } from '../../../hook/2024/hooks/useWindowSize'
import {
  CountdownContainer,
  CountdownTitle,
  CountdownTimer,
  TextCenterTop,
  EmailSignUpCenter,
  StayTuned,
  CalendarBody,
} from '../../../styles/2024/TeaserStyles'
import LottieModal from './lottie/LottieModal'
import { H2 } from '../../../styles/2024/TextStyles'

const ThreeSlider = React.lazy(() =>
  import('../../../components/2024/SlickSlider').then((module) => ({
    default: module.ThreeSlider,
  })),
)

const END_DATE_COUNTDOWN = '05/01/2024'

const Teaser = () => {
  const endDay = dayjs(END_DATE_COUNTDOWN, 'MM/DD/YYYY')
  const [daysLeft, setDaysLeft] = useState(() =>
    endDay.diff(dayjs(), 'days') > 0 ? endDay.diff(dayjs(), 'days') : 0,
  )
  const [hoursLeft, setHoursLeft] = useState(() =>
    endDay.diff(dayjs(), 'hours') % 24 > 0 ? endDay.diff(dayjs(), 'hours') % 24 : 0,
  )
  const [minutesLeft, setMinutesLeft] = useState(() =>
    endDay.diff(dayjs(), 'minutes') % 60 > 0 ? endDay.diff(dayjs(), 'minutes') % 60 : 0,
  )
  const [email, setEmail] = useState('')
  const [isPlaying, setIsPlaying] = useState(false)
  const { screen } = useWindowSize()
  const isMobile = screen === 'sm'
  const isMedium = screen === 'md'
  const isLarge = screen === 'lg'
  const isTab = screen === 'tab'

  // Update the time left every 10 seconds
  useEffect(() => {
    if (!isTab) {
      const intervalId = setInterval(() => {
        const now = dayjs()
        const updatedDaysLeft = endDay.diff(now, 'days')
        const updatedHoursLeft = endDay.diff(now, 'hours') % 24
        const updatedMinutesLeft = endDay.diff(now, 'minutes') % 60

        setDaysLeft(updatedDaysLeft > 0 ? updatedDaysLeft : 0)
        setHoursLeft(updatedHoursLeft > 0 ? updatedHoursLeft : 0)
        setMinutesLeft(updatedMinutesLeft > 0 ? updatedMinutesLeft : 0)
      }, 10000) // Update every 10 seconds

      // Cleanup on component unmount
      return () => clearInterval(intervalId)
    }
  }, [endDay, isTab])

  // useEffect(() => {
  //   if (daysLeft !== 0 || hoursLeft !== 0) {
  //     return
  //   }
  //   history.push('/homepage')
  // }, [daysLeft, hoursLeft])

  const [errorForm, setErrorForm] = useState(false)
  const [submitForm, setSubmitForm] = useState(false)

  const handleDragStart = (e) => e.preventDefault()


  const onHandleForm = useCallback(
    (e) => {
      e.preventDefault()
      const emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
      if (!email || !email.match(emailFormat)) {
        setErrorForm(true)
        console.log('form failed')
        return
      }
      const baseUrl = process.env.REACT_APP_API_ENDPOINT_BASE || 'https://api.georgebrown.ca'
      fetch(`${baseUrl}/join-waiting-list`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      })
        .then((response) => response.json())
        .then(setSubmitForm(true))
        .then((data) => {
          //console.log(data)
        })
    },
    [email],
  )

  const onInputChange = useCallback((e) => {
    const email = e.target.value
    setEmail(email)
  }, [])

  return (
    <div className='relative flex flex-col items-center justify-center w-full bg-almost-black'>
      <img
        src='/static/2024/svg/may1-2.svg'
        alt='yes24-sticker'
        className='sticky w-[160px] top-8 z-50 justify-self-end self-end mr-6'
      />
      <div className='relative w-full min-h-max bg-almost-black flex flex-col items-center justify-center w-full lg:pt-20 lg:pb-40'>
        <div className='relative grid items-center justify-center bg-almost-black min-h-screen grid-cols-1 lg:grid-cols-2 lg:min-h-full lg:w-[1440px] !max-w-[1440px]'>
          <div className='relative flex flex-col w-full gap-4 items-center justify-center h-[80vh] lg:h-full'>
            <img
              src='/static/2024/svg/YES24_logo.svg'
              alt='yes24-sticker'
              className='z-10 w-[192px] lg:w-[344px] self-center'
            />
            <div className='absolute w-full grid grid grid-cols-3 gap-4 items-center justify-center px-6 lg:min-h-[120%]'>
              <div className='flex items-center justify-center'>
                <img
                  className='w-[138px]'
                  src='/static/2024/svg/cloudBlue.svg'
                  alt='yes24-sticker'
                />
              </div>
              <div className='flex items-center justify-center'></div>
              <div className='flex items-center self-start justify-center '>
                <img
                  className='w-[192px]'
                  src='/static/2024/svg/yellowCircle.svg'
                  alt='yes24-sticker'
                />
              </div>
              <div className='flex items-center justify-center'>
                <img
                  className='w-[138px]'
                  src='/static/2024/svg/thunderX.svg'
                  alt='yes24-sticker'
                />
              </div>
              <div className='flex items-center justify-center '></div>
              <div className='flex items-center self-start justify-center '>
                <img className='w-[56px]' src='/static/2024/svg/fatBlue.svg' alt='yes24-sticker' />
              </div>
              <div className='flex items-center justify-center'>
                <img
                  className='w-[156px]'
                  src='/static/2024/svg/blueCircle.svg'
                  alt='yes24-sticker'
                />
              </div>
              <div className='flex items-center justify-center'></div>
              <div className='flex items-center justify-center self-start'>
                <img className='w-[168px]' src='/static/2024/svg/threeX.svg' alt='yes24-sticker' />
              </div>
            </div>
          </div>
          <div className='relative grid grid grid-cols-1 sm:flex-row w-full bg-almost-black px-6 pb-24 lg:min-h-[120%]'>
            <CountdownContainer className='text-beige-yellow beige-yellow relative w-full'>
              <div className='w-full z-0 flex flex-col items-center justify-center sm:w-2/3 '>
                <div className='realtive w-full text-left '>
                  <CountdownTitle className='text-comfort-green uppercase text-left'>
                    2024
                  </CountdownTitle>
                  <CountdownTimer className='text-beige-yellow text-left'>
                    {daysLeft > 10 ? daysLeft : '0' + daysLeft}D:
                    {hoursLeft >= 10 ? hoursLeft : '0' + hoursLeft}H:
                    {minutesLeft >= 10 ? minutesLeft : '0' + minutesLeft}M
                  </CountdownTimer>
                </div>

                <TextCenterTop className='relative w-full z-10 mt-14  text-xl'>
                  <h2 className='text-beige-yellow uppercase'>George Brown College</h2>
                  <h2 className='text-beige-yellow uppercase'>School of design Year End Show</h2>
                  <h3 className='text-comfort-green '>
                    <span className=''>Find us</span>{' '}
                    <a
                      className='text-comfort-green pointer hover:text-warm-orange '
                      href='https://www.instagram.com/sod_yes/'
                      // target='_blank'
                      rel='noreferrer'
                    >
                      @sod_yes
                    </a>{' '}
                    <span className=''>on</span>{' '}
                    <a
                      className='text-comfort-green  pointer underline hover:text-warm-orange hover:underline '
                      href='https://www.instagram.com/designgbc/'
                      // target='_blank'
                      rel='noreferrer'
                    >
                      Instagram
                    </a>{' '}
                    <span className=''>and</span>{' '}
                    <a
                      className='text-comfort-green  pointer underline hover:text-warm-orange hover:underline '
                      href='https://www.tiktok.com/@designgbc'
                      // target='_blank'
                      rel='noreferrer'
                    >
                      TikTok!
                    </a>
                  </h3>
                </TextCenterTop>
                <br />
                <EmailSignUpCenter
                  className='w-full z-10'
                  hasError={errorForm}
                  submitted={submitForm}
                >
                  <form onSubmit={onHandleForm}>
                    <label className='text-beige-yellow'>
                      Sign up to our mailing list to receive updates
                    </label>
                    <input
                      id='email'
                      type='text'
                      name='name'
                      placeholder='Enter Your Email'
                      value={email}
                      onChange={onInputChange}
                      className='text-almost-black bg-beige-yellow placeholder-almost-black border border-comfort-green'
                    />
                    <input
                      id='submit'
                      type='submit'
                      value='Sign Up!'
                      className='text-almost-black bg-comfort-green'
                    />
                    <div className='inline-error' id='inline-error'>
                      Please enter a valid email address
                    </div>
                    <div id='submitted'>
                      <span className='text-comfort-green'>Success!</span> You’re in!
                    </div>
                  </form>
                </EmailSignUpCenter>
              </div>
            </CountdownContainer>
          </div>
        </div>
      </div>
      <div className='relative w-full min-h-max bg-comfort-green flex flex-col items-center justify-center w-full '>
        <div className='relative w-full bg-comfort-green grid grid-cols-1 lg:grid-cols-2 gap-4 pb-20 lg:pb-10 !max-w-[1440px]'>
          <div className='relative grid items-center justify-center'>
            <LottieModal isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
          <div className='flex w-full justify-center grid-span-2 lg:items-center'>
            <div className='relative w-full z-0 flex flex-col items-start justify-center px-6'>
              <H2 className='uppercase text-almost-black w-2/3  '>
                Mark Your Calendar!
              </H2>
              <CalendarBody className='text-almost-black mt-8 w-5/6 '>
                YES!, short for Year End Show!, is a graduation show at the School of Design in
                George Brown College. This spring, more than 450 students will celebrate the
                culmination of their studies at the School of Design. Each student has benefitted
                from the wisdom and support of faculty, peers, staff, industry partners, and the
                much broader design community. Join us May 1-2 as we showcase the talent of our
                twelve diverse programs through an exciting in-person program of activity.
              </CalendarBody>
              <CalendarBody className='text-almost-black w-5/6 '>
                Yes! We’re designers.
                <br /> Yes! We’re future-oriented. <br /> Yes! We can’t wait to show you what we’ve
                been up to.
              </CalendarBody>
            </div>
          </div>
        </div>
      </div>
      <div className='relative w-full min-h-max bg-warm-orange flex flex-col items-center justify-center w-full'>
        <div className='relative w-full bg-warm-orange flex flex-col items-left lg:items-center justify-center pb-12'>
          <H2 className='uppercase text-almost-black pl-6 lg:pl-40 relative pt-20 lg:pt-40 w-2/3 lg:w-full lg:text-center'>
            What To Expect
          </H2>

          {isMobile ? (
            <div className='flex flex-col items-center relative pb-20'>
              <img
                src='/static/2024/img/Frame62.jpeg'
                alt='Year End Show Activities'
                role='presentation'
                style={{ width: '92vw' }}
              />
              ,
              <img
                src='/static/2024/img/Frame63.jpeg'
                alt='Year End Show Activities'
                onDragStart={handleDragStart}
                role='presentation'
                style={{ width: '92vw' }}
              />
              ,
              <img
                src='/static/2024/img/Frame64.jpeg'
                alt='Year End Show Activities'
                onDragStart={handleDragStart}
                role='presentation'
                style={{ width: '92vw' }}
              />
              ,
              <img
                src='/static/2024/img/Frame65.jpeg'
                alt='Year End Show Activities'
                onDragStart={handleDragStart}
                role='presentation'
                style={{ width: '92vw' }}
              />
            </div>
          ) : (
            <ThreeSlider />
          )}
        </div>
      </div>
      <div className='relative w-full min-h-max bg-friendly-yellow flex flex-col items-center justify-center w-full'>
        <div className='relative w-full bg-friendly-yellow grid grid-cols-1 lg:grid-cols-2 gap-4 justify-center items-center px-6 pt-20 pb-40 lg:pl-0 max-w-[1440px]'>
          <H2 className='uppercase text-almost-black pl-0 lg:pl-40'>
            Stay <br /> Tuned.
          </H2>
          <EmailSignUpCenter
            className='w-full z-10 pr-0 lg:pr-40'
            hasError={errorForm}
            submitted={submitForm}
          >
            <form onSubmit={onHandleForm} className='justify-self-end items-start lg:items-end'>
              <div className=''>
                <label className='text-almost-black'>
                  Sign up to our mailing list to receive updates
                </label>
                <input
                  id='email'
                  type='text'
                  name='name'
                  placeholder='Enter Your Email'
                  value={email}
                  onChange={onInputChange}
                  className='text-almost-black bg-beige-yellow placeholder-almost-black border border-almost-black'
                />
                <input
                  id='submit'
                  type='submit'
                  value='Sign Up!'
                  className='text-beige-yellow bg-almost-black'
                />
                <div className='inline-error' id='inline-error'>
                  Please enter a valid email address
                </div>
                <div id='submitted'>
                  <span className='text-warm-orange'>Success!</span> You’re in!
                </div>
              </div>
            </form>
            <div className='flex justify-start lg:justify-end '>
              <div className='flex' style={{ width: isMobile && '100vw' }}>
                <a
                  href='https://www.instagram.com/sod_yes/'
                  // target='_blank'
                  rel='noreferrer'
                >
                  <img src='/static/2024/svg/gram.svg' alt='instagram logo' className='pt-6 pr-6' />
                </a>
                <a
                  href='https://www.tiktok.com/@designgbc'
                  // target='_blank'
                  rel='noreferrer'
                >
                  <img src='/static/2024/svg/tiktok.svg' alt='tiktok logo' className='pt-6 ' />
                </a>
              </div>
            </div>
          </EmailSignUpCenter>
        </div>
      </div>
    </div>
  )
}

export default Teaser
