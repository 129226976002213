import * as React from 'react'
import { H2, FormBody } from '../../styles/2024/TextStyles'
import { LengthLimitTextarea } from '../CMS/styles/InputLimit'
import { gql, useMutation } from '@apollo/client'
import { QUERY } from '../../graphql'
import cogoToast from 'cogo-toast'

const InputField = ({ label, placeholder, handleChange, sVar, sFunc }) => {
  return (
    <div className='flex flex-col w-full'>
      {label}
      <label htmlFor={label}>
        <input
          type='text'
          id={label}
          name={label}
          placeholder={placeholder}
          className='justify-center items-start px-6 py-4 bg-beige-yellow border border-solid border-almost-black rounded-[48px] max-md:px-5 focus:outline-none focus:ring-2 focus:ring-warm-orange focus:border-transparent placeholder-almost-black placeholder-opacity-50 w-full'
          value={sVar}
          onChange={(e) => handleChange(e, sFunc)}
        />
        {/* {firstName_error && <ErrorInput>{firstName_error}</ErrorInput>} */}
      </label>
    </div>
  )
}

const LetterForm = () => {
  const [sender, setSender] = React.useState('')
  const [recipient, setRecipient] = React.useState('')
  const [message, setMessage] = React.useState('')

  const [createLetter, { loading: createLetterLoading, error: createLetterError }] = useMutation(
    CREATE_LETER_MUTATION,
    {
      update: (cache, { data: { createLetter } }) => {
        // Read the current state of the query from the cache
        const existingLetters = cache.readQuery({
          query: QUERY.LETTERS_QUERY,
          variables: {
            where: {
              createdAt_gt: '2023-12-31',
            },
          },
        })

        // Write the updated data back to the cache
        cache.writeQuery({
          query: QUERY.LETTERS_QUERY,
          variables: {
            where: {
              createdAt_gt: '2023-12-31',
            },
          },
          data: {
            letters: [...existingLetters.letters, createLetter],
          },
        })
      },
    },
  )

  const handlePublish = async (e) => {
    e.preventDefault()
    // const badwords = new Filter()
    var Filter = require('bad-words-jd'),
      filter = new Filter()

    const variables = {
      sender,
      recipient,
      message,
    }

    if (filter.isProfane(variables.message)) {
      cogoToast.error('Message contains inappropriate language')
      return
    }
    if (filter.isProfane(variables.sender)) {
      cogoToast.error('Sender name contains inappropriate language')
      return
    }
    if (filter.isProfane(variables.recipient)) {
      cogoToast.error('Recipient name contains inappropriate language')
      return
    }
    if (!variables.sender || variables.sender === '') {
      cogoToast.error('Sender is required')
      return
    }
    if (!variables.recipient || variables.recipient === '') {
      cogoToast.error('Receiver is required')
      return
    }
    if (!variables.message || variables.message === '') {
      cogoToast.error('Message is required')
      return
    }

    const res = await createLetter({ variables }).then((res) => {
      if (res.data.createLetter) {
        setRecipient('')
        setSender('')
        setMessage('')
      }
    })

    return res
  }

  const handleChange = (e, setter) => {
    const { value } = e.target
    setter(value)
  }

  return (
    <FormBody
      className='flex flex-col justify-center p-20 bg-friendly-yellow rounded-[8px] max-w-[656px] text-almost-black max-md:px-5 font-grotesque'
      onSubmit={(e) => handlePublish(e)}
    >
      <H2 className='uppercase'>write a letter</H2>
      <div className='flex gap-4 mt-4 max-md:flex-wrap'>
        <InputField
          id='sender'
          key='sender'
          label='Sender'
          placeholder='Your Name'
          sVar={sender}
          sFunc={setSender}
          handleChange={handleChange}
        />
        <InputField
          id='recipient'
          key='recipient'
          label='Recipients'
          placeholder='Student Name'
          sVar={recipient}
          sFunc={setRecipient}
          handleChange={handleChange}
        />
      </div>
      <label htmlFor='message' className='w-full pt-4'>
        Message <br />
        <LengthLimitTextarea
          type='text'
          id='message'
          name='message'
          placeholder='Please send a supportive message to School of Design students who have worked hard throughout their academic journey.'
          maxLength={200}
          className='w-full px-6 py-4 bg-beige-yellow border border-solid border-almost-black rounded-[8px] max-md:px-5 focus:outline-none focus:ring-2 focus:ring-warm-orange h-[180px] focus:border-transparent placeholder-almost-black placeholder-opacity-50'
          value={message}
          onChange={(e) => handleChange(e, setMessage)}
          fromtop={10}
          color='#181818'
        />
        {/* {about_error && <ErrorInput>{about_error}</ErrorInput>} */}
      </label>
      <button
        type='submit'
        className='justify-center items-center px-16 py-8 mt-8 font-grotesque text-beige-yellow bg-almost-black rounded-[48px] max-md:px-5 max-md:max-w-full'
      >
        Send Message
      </button>
    </FormBody>
  )
}

export default LetterForm

const CREATE_LETER_MUTATION = gql`
  mutation CREATE_LETER_MUTATION($sender: String!, $recipient: String!, $message: String!) {
    createLetter(sender: $sender, recipient: $recipient, message: $message) {
      sender
      recipient
      message
    }
  }
`
