import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import cn from 'classnames'
import { useWindowSize } from '../../hook'
import SearchIcon from '../../components/2022/SearchIcon'
import FixedBottomNav from '../../components/2022/FixedBottomNav'
import { useDisclosure } from 'react-use-disclosure'
import { MobileRightNavs, MobileNavsBottom } from './MobileRightNavs'
import Marquee from '../../components/2022/FastMarquee'
import { gql, useQuery } from '@apollo/client'
import useCountdown from '../../hook/useCountdown'

const Container = styled.div`
  height: 100vh;
  background-color: #191919;
  cursor: url(${(props) => props.cursorUrl}), auto;
  margin: 0;
  z-index: 0;
  overflow-x: hidden;
  svg:first-child {
    // height: 1200px;
  }
`

const TextLogo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 8.5rem;
  line-height: 9rem;
  letter-spacing: 0.01em;
  font-weight: 400;
`
const TextCenterTop = styled.div`
  h2,
  h3 {
    font-family: 'grotesque_pro_light';
    font-weight: 200;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.05rem;
  }
  // bottom: 4%;
  // top : 0;
  @media (min-width: 768px) {
    // bottom: 0%;
  }
  @media (min-width: 1600px) {
    // bottom: 2%;
  }
`
const TitleWrapper = styled.div`
  left: ${(props) => props.imgLeft || 28}px;
  min-width: 160px;
  min-height: 480px;
`
const StickersWrapper = styled.div`
  top: ${(props) => props.top};
  left: ${(props) => props.imgLeft || 28}px;
  min-width: 160px;
  min-height: 480px;
`
const StickerContainer = styled.div`
  top: ${(props) => props.top};
  left: ${(props) => props.imgLeft || 28}px;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'inherit')};
  img {
    width: ${(props) => props.imgWidth || 84}px;
  }
  p {
    position: absolute;
    top: calc(${(props) => props.top} + 16px);
    font-family: Reckless;
    transform: rotate(${(props) => props.textRotate}deg);
  }

`
const CenterContainer = styled.div`
  img {
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  }
  @media (min-width: 1280px) {
    // top: 10%;
    // max-height: 40vh;
  }
  @media (min-width: 1920px) {
    // top: 20%;
  }
`
const TextCenter = styled.h4`
  color: #ff6044;
  font-family: 'sharp_grotesque_05';
  font-size: 420px;
  line-height: 360px;
  letter-spacing: 0.01em;
  font-weight: 400;
  @media (min-width: 360px) {
    font-size: 460px;
    line-height: 400px;
  }
  @media (min-width: 768px) {
    font-size: 550px;
    line-height: 550px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 600px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 700px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 1000px;
    line-height: 1200px;
  }
  @media (min-width: 2880px) {
    font-size: 1400px;
    line-height: 1400px;
  }
`
const TitleLeft = styled.h5`
  color: #ff6044;
  font-family: 'sharp_grotesque_05';
  font-size: 400px;
  line-height: normal;
  letter-spacing: 0.01em;
  font-weight: 900;
  padding-left: 12px;
  @media (min-width: 360px) {
    font-size: 400px;
    line-height: normal;
  }
  @media (min-width: 768px) {
    font-size: 400px;
    line-height: normal;
  }
  @media (min-width: 1280px) {
    font-size: 400px;
    line-height: normal;
  }
  @media (min-width: 1680px) {
    font-size: 400px;
    line-height: normal;
  }
  @media (min-width: 1920px) {
    font-size: 400px;
    line-height: normal;
  }
  @media (min-width: 2560px) {
    font-size: 500px;
    line-height: normal;
  }
  @media (min-width: 2880px) {
    font-size: 500px;
    line-height: normal;
  }
  @media (min-width: 2880px) {
    font-size: 500px;
    line-height: normal;
  }
`
const TextCenterDescription = styled.h4`
  color: #191919;
  font-family: 'sharp_grotesque_15';
  font-size: 250px;
  line-height: 250px;
  letter-spacing: 0.01em;
  font-weight: 400;
  padding-top: 100px;
  @media (min-width: 360px) {
    font-size: 250px;
    line-height: 250px;
  }
  @media (min-width: 768px) {
    font-size: 350px;
    line-height: 350px;
  }
  @media (min-width: 1280px) {
    font-size: 400px;
    line-height: 400px;
  }
  @media (min-width: 1680px) {
    font-size: 400px;
    line-height: 400px;
  }
  @media (min-width: 1920px) {
    font-size: 500px;
    line-height: 500px;
  }
  @media (min-width: 2560px) {
    font-size: 500px;
    line-height: 500px;
  }
  @media (min-width: 2880px) {
    font-size: 6000px;
    line-height: 600px;
  }
  @media (min-width: 2880px) {
    font-size: 700px;
    line-height: 700px;
  }
`
const EmailSignUpCenter = styled.div`
  form {
    height: 14vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items:center;
    font-family: 'grotesque_pro_light';
    font-size: 1.8rem;
  }
  #email{
    color: #191919;
    width: 401px;
    height: 60px;
    border-radius: 30px;
    padding: 5px 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 3px solid ${(props) => (props.hasError ? 'red' : '#191919')};
    display: ${(props) => (props.submitted ? 'none' : 'block')};
  }
  #email:hover{
    border: 3px solid red;
  }
  #email:focus{
    outline: none;
    border: 3px solid red;
  }
  #submit{
    width: 401px;
    height: 60px;
    background-color: #FF6044;
    color: #191919;
    border-radius: 30px;
    display: ${(props) => (props.submitted ? 'none' : 'block')};
  }
  #submit:hover{
    border: 3px solid #fa8975;
  }
  #inline-error{
    color: white;
    margin-top: 10px;
    display: ${(props) => (props.hasError ? 'block' : 'none')};
  }
  #submitted{
    color: white;
    margin-top: 10px;
    display: ${(props) => (props.submitted ? 'block' : 'none')};
  }
  @media (min-width: 300px) {
    top : 130%;
    form {
      height: 30vh;
      margin-bottom: 7%;
    }
    #email{
      width: 250px;
    }
    #submit{
      width: 250px;
    }
  }
  @media (min-width: 480px) {
    top : 130%;
    form {
      height: 20vh;
      margin-bottom: 7%;
    }
    #email{
      width: 401px;
    }
    #submit{
      width: 401px;
    }
  }
  @media (min-width: 768px) {
    top : 120%;
    form {
      height: 27vh;
      margin-bottom: 7%;
    }
  }
  @media (min-width: 1280px) {
    top : 114%;
    form {
      min-height: 23vh;
      margin-bottom: 7%;
    }
  }
  @media (min-width: 1440px) {
    top : 112%;
    form {
      min-height: 19vh;
      margin-bottom: 7%;
    }
  }
  @media (min-width: 1920px) {
    top : 107%;
    form {
      min-height: 18vh;
      margin-bottom: 7%;
    }
  }
  @media (min-width: 2880px) {
    top : 92%;
    form {
      min-height: 10vh;
    }
  }
`
const Sticker4Days = styled.img`
  top: 2%;
  left: 15%;
  cursor: inherit;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  @media (min-width: 1024px) {
    top: 4%;
    left: 25%;
  }
  @media (min-width: 1440px) {
    left: 30%;
  }
  @media (min-width: 1920px) {
    left: 35%;
  }
  @media (min-width: 2400px) {
    left: 45%;
  }
`
const StickerMay = styled.img`
  // bottom: 20%;
  top:  12%;
  right: 15%;
  cursor: inherit;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  @media (min-width: 768px) {
    // bottom: -5%;
  }
  @media (min-width: 1024px) {
    right: 20%;
  }
  @media (min-width: 1280px) {
    right: 25%;
  }
  @media (min-width: 1600px) {
    right: 30%;
  }
`
const DescriptionArea = styled.div`
  background-color: #ff6044;
`
const DescriptionTitle = styled.div`
  padding-top: 20px;
  p {
    font-family: 'sharp_grotesque_15';
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    letter-spacing: normal;
    font-size: 42px;
    line-height: normal;
    color: #191919;
  }
`
const DescriptionSubtitle = styled.div`
  width: 50vw;
  margin: auto;
  padding-top: 10vh;
  padding-bottom: 10vh;
  p {
    font-family: 'grotesque_pro_light';
    font-size: 24px;
    color: #191919;
    text-align: left;
  }
  @media (max-width: 411px),
   (max-height: 700px) {
    height: 0vh;
  }
`
const Sticker1 = styled.img`
position: relative;
display:inline;
    top: 10%;
    right: 10%;
    width: 5%;
    @media (max-width: 768px) {
      top: 80%;
      width: 10%;
    }
`
const Sticker2 = styled.img`
position: relative;
display:inline;
    top: 10%;
    right: 20%;
    width: 7%;
    @media (max-width: 768px) {
      top: 90%;
      width: 14%;
    }
`
const Sticker3 = styled.img`
position: relative;
display:inline;
    top: 20%;
    right: 30%;
    width: 8%;
    @media (max-width: 768px) {
      top: 100%;
      width: 16%;
    }
`
const Sticker4 = styled.img`
position: relative;
display:inline;
    top: 30%;
    right: 13%;
    width: 15%;
    @media (max-width: 768px) {
      top: 110%;
      width: 30%;
    }
`
const Sticker6 = styled.img`
position: relative;
display:inline;
    top: 40%;
    right: -10%;
    width: 9%;
    @media (max-width: 768px) {
      top: 120%;
      width: 18%;
    }
`
const Sticker7 = styled.img`
position: relative;
display:inline;
    top: 50%;
    right: -23%;
    width: 10%;
    @media (max-width: 768px) {
      top: 130%;
      width: 20%;
    }
`
const Sticker5 = styled.img`
position: relative;
display:inline;
    top: 40%;
    right: -5%;
    width: 9%;
    @media (max-width: 768px) {
      top: 80%;
      width: 18%;
    }
`
const Sticker8 = styled.img`
position: relative;
display:inline;
    top: 70%;
    right: 12%;
    width: 7%;
    @media (max-width: 768px) {
      top: 90%;
      width: 14%;
    }
`
const Sticker9 = styled.img`
position: relative;
display:inline;
    top: 20%;
    right: -20%;
    width: 20%;
    @media (max-width: 768px) {
      top: 100%;
      width: 30%;
    }
`
const Sticker10 = styled.img`
position: relative;
display:inline;
    top: 30%;
    right: -10%;
    width: 5%;
    @media (max-width: 768px) {
      top: 110%;
      width: 10%;
    }
`
const Sticker11= styled.img`
position: relative;
display:inline;
  top: 10%;
    right: 10%;
    width: 40%;
    @media (max-width: 768px) {
      top: 80%;
      width: 50%;
    }
`
const Sticker12= styled.img`
position: relative;
display:inline;
    top: 40%;
    right: -40%;
    width: 15%;
    @media (max-width: 768px) {
      top: 110%;
      width: 30%;
    }
`
const Sticker15= styled.img`
position: relative;
display:inline;
    top: 10%;
    right: -50%;
    width: 2%;
    @media (max-width: 768px) {
      top: 110%;
      width: 6%;
    }
`
const StickerPlay= styled.img`
    position: relative;
    display:inline;
    top: 10%;
    right: -3%;
    width: 9%;
    @media (max-width: 768px) {
      top: 90%;
      width: 18%;
    }
`
const GradsContainer = styled.div`
  padding-top: 50vh;
  background-color: #e3e3de;
  width: 100vw;
`
const EventsContainer = styled.div`
  position: absolute;
  background-color: #191919;
  width: 100vw;
`

const ENABLE_NAVIGATION = true

export const USERS_QUERY = gql`
  query Users($where: UserWhereInput) {
    users(where: $where) {
      id
      firstName
      lastName
    }
  }
`

const HomeCountDown = () => {
  const { minutes, hours, days } = useCountdown('05/03/2022')

  return (
    <p style={{
      color: '#191919',
    }}>
      {days} days • {hours} hours • {minutes} minutes
    </p>
  )
}

export default function Homepage() {
  const [email, setEmail] = useState('')
  const [errorForm, setErrorForm] = useState(false)
  const [submitForm, setSubmitForm] = useState(false)
  const [eventDay, setEventDay] = useState(1)

  const { data } = useQuery(USERS_QUERY, {
    variables: {
      where: {
        studentYear: 2020
      }
    }
  })
  const users = useMemo(() => data?.users || [], [data])
  console.log('Homepage -> users', users)

  const { screen } = useWindowSize()
  const isMobile = screen === 'sm'

  const history = useHistory()
  const { pathname } = useLocation()

  const isWork = useMemo(() => {
    if (pathname?.startsWith('/work')) {
      return true
    }
    return false
  }, [pathname])

  const isGrads = useMemo(() => {
    if (pathname?.startsWith('/grads')) {
      return true
    }
    return false
  }, [pathname])
  const onHandleForm = useCallback((e) => {
    e.preventDefault()
    const emailFormat=/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if(!email || !email.match(emailFormat)){
      setErrorForm(true);
      console.log('form faild');
      return
    }
    const baseUrl = process.env.REACT_APP_API_ENDPOINT_BASE || 'https://api.georgebrown.ca'
    fetch(`${baseUrl}/join-waiting-list`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    })
    .then(response => response.json())
    .then(setSubmitForm(true))
    .then(data => {
      console.log(data)
    });

  }, [email])

  const onInputChange = useCallback((e) => {
    const email = e.target.value
    setEmail(email)
  }, [])

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])

  const navigate = useCallback(
    (url) => {
      console.log('🚀 ~ Homepage ~ url', url)
      if (pathname?.startsWith(url)) {
        return
      }

      const leftLayer = document.getElementsByClassName('left-layer')?.[0]
      const rightLayer = document.getElementsByClassName('left-layer')?.[0]
      if (leftLayer && rightLayer) {
        leftLayer.classList.add('active')
        rightLayer.classList.add('active')
        setTimeout(() => history.push(url), 1500)
      }
    },
    [history, pathname],
  )

  useEffect(() => {
    ;(async () => {
      // const script = document.createElement('script')
      // script.src = '/static/transition.js'
      // script.async = true
      // script.type = 'text/javascript'

      const script2 = document.createElement('script')
      script2.src = '/static/movingsvg.js'
      script2.async = true
      script2.type = 'text/javascript'

      // document.body.appendChild(script)
      document.body.appendChild(script2)
      // script.onload = function () {
      //   const canvas = document.getElementsByTagName('canvas')[0]
      //   if (canvas) {
      //     canvas.style.cssText = `cursor: inherit ;z-index: 1 ; display: block !important;left: 0;position: absolute;top: 0; width:100%; height:100%; `
      //   }
      // }
    })()
  }, [])

  const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()

  return (
      <Container
        className='w-full h-full bg-black relative'
        cursorUrl='/static/svg/2022/cursor-with-shadow.svg'
      >

        <div className='absolute w-full h-full' style={{top:'-8%'}}>
          <img
            src={`/static/svg/2022/teaser-bg-black${isMobile ? '' : '-xl'}.svg`}
            alt='bg-teaser'
            className='w-full'
          />
        </div>
        <StickersWrapper
          className={cn('z-10 fixed  w-96 fixed top-10 h-screen transition-opacity duration-700 ease-in-out hidden lg:block', { hidden: !ENABLE_NAVIGATION })}
          top='210px'
          imgLeft='0%'
        >
          <StickerContainer
            pointer
            className='relative w-max'
            textRotate={-35}
            onClick={() => navigate('/2021/work')}
          >
            <svg width={88} height={88} viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'>
              <circle
                fill={isWork ? '#191919' : '#ff6044'}
                cx={55}
                cy={55}
                r={55}
                fillRule='evenodd'
                style={{display: "unset"}}
              />
            </svg>
            <p
              className={cn('text-5xl')}
              style={{
                top: 'calc(25% + 4px)',
                left: 10,
                color: isGrads ? '#e3e3de' : '#191919'
              }}
            >
              Work
            </p>
          </StickerContainer>
          <StickerContainer
            pointer
            imgWidth={124}
            className='relative -mt-4 w-max'
            textRotate={-15}
            onClick={() => navigate('/2021/grads')}
          >
            <svg width={128} height={87} viewBox='0 0 164 87' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M.203 71.712S60.29 13.564 113.301 1.427c51.212-11.726 71.525 64.233 21.237 79.456-26.56 8.04-68.41 8.844-134.335-9.171'
                fill={isGrads ? '#191919' : '#ff6044'}
                fillRule='evenodd'
              />
            </svg>
            <p
              className={cn('text-5xl')}
              style={{
                top: 'calc(35% + 0px)',
                left: 42,
                color: isGrads ? '#e3e3de' : '#191919'
              }}
            >
              Grads
            </p>
          </StickerContainer>
          <StickerContainer pointer imgWidth={124} className='relative w-max' textRotate={10} onClick={() => navigate('/2021/events')}>
            <img src='/static/svg/2022/nav-event.svg' alt='events-nav' />
            <p
              className='text-5xl text-blue'
              style={{
                top: 'calc(45% - 12px)',
                left: 24,
                color: isGrads ? '#e3e3de' : '#191919'
              }}
            >
              Events
            </p>
          </StickerContainer>
          <StickerContainer pointer imgWidth={94} className='relative w-max' textRotate={10} onClick={() => navigate('/2021/show')}>
            <img src='/static/svg/2022/nav-show.svg' alt='show-nav' />
            <p
              className='text-5xl text-blue'
              style={{
                top: 'calc(56% - 16px)',
                left: 16,
                color: isGrads ? '#e3e3de' : '#191919'
              }}
            >
              Shop<span style={{fontSize:'20px'}}>&#8599;</span>
            </p>
          </StickerContainer>
        </StickersWrapper>

        <div className='w-full flex justify-between pl-10 relative'>
          <div className='relative z-10 inline-block'>
            <TextLogo
              className='text-red-coral uppercase'
              onClick={() => history.push('/')}
            >
              Yes!2022
            </TextLogo>
          </div>
        </div>
        <CenterContainer className='w-full absolute mx-auto'>
        <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs} />
        <div
            className={cn( 'transition',{
              'flex visible opacity-100': !isOpenMenuNavs,
              'hidden invisible opacity-0': isOpenMenuNavs,
            })}
          >
          <div className='text-center relative h-full xl:mt-4 flex' >
            <div className='w-full'>
              <HomeCountDown />
              <TextCenter className='text-secondary'>YES!</TextCenter>
              <Sticker4Days
                src='/static/svg/2022/4-days.svg'
                alt='5-days'
                className={cn('absolute mx-auto cursor-pointer w-32 md:w-64 max-w-sm')}
              />
              <StickerMay
                src='/static/svg/2022/may3-7.svg'
                alt='5-days'
                className={cn('absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
              />
              <TextCenterTop className='relative w-full z-10'>
                <h2 className='text-light-grey-2022 uppercase text-center'>George Brown College</h2>
                <h2 className='text-light-grey-2022 uppercase text-center'>School of design</h2>
                <h3 className='text-light-grey-2022 text-center'>
                  Year End Show
                  <a
                    className='text-red-coral'
                    href='https://www.instagram.com/sod_yes/'
                    // target='_blank'
                    rel='noreferrer'
                  >
                    {' '}
                    @sod_yes
                  </a>
                </h3>
              </TextCenterTop>
              <EmailSignUpCenter className='realtive w-full z-10' hasError={errorForm} submitted={submitForm}>
                <form onSubmit={onHandleForm}>
                  <label className='text-light-grey-2022'>Sign up to our mailing list to receive updates</label>
                  <input id="email" type="text" name="name" placeholder="Enter Your Email" value={email} onChange={onInputChange}/>
                  <input id="submit" type="submit" value="Sign Up!" />
                  <div className="inline-error" id="inline-error">Please enter a valid email address</div>
                  <div id="submitted"><span className="text-red-coral">Success!</span> Check your email to confirm.</div>
                </form>
              </EmailSignUpCenter>
              <Marquee marquee />
              <DescriptionArea>
                <TextCenterDescription className='text-secondary'>YES!</TextCenterDescription>
                <DescriptionTitle className='w-full'>
                  <p className='text-center text-secondary' style={{fontFamily: 'reckless_light', fontWeight: 'normal'}}>
                    YES! is the official grad show at <br></br>
                        George Brown College’s School of Design
                    <br></br> for&nbsp; 380 students, across&nbsp;
                      10 programs.
                  </p>
                </DescriptionTitle>
                <DescriptionSubtitle>
                  <p>
                    2021 Year End Show<br></br>
                    This spring, 380 students will graduate from the School of Design. Each has
                    benefitted from the wisdom and support of faculty, peers, staff, industry partners
                    and the much broader School of Design community. Join us May 3–7 to celebrate all
                    of their hard work and dedication. Yes! is a testament to their collaboration.
                    Yes! We’re designers. Yes! We’re future-ready. Yes! We’re the School of Design
                  </p>
                </DescriptionSubtitle>
              </DescriptionArea>

              <GradsContainer>
                <Sticker1
                  src='/static/svg/2022/sticker-1.svg'
                  className={cn('moving1 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
                />
                <Sticker2
                  src='/static/svg/2022/sticker-2.svg'
                  className={cn('moving2 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
                />
                <Sticker3
                  src='/static/svg/2022/sticker-3-yes.svg'
                  className={cn('moving3 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
                />
                <Sticker4
                  src='/static/svg/2022/sticker-4.svg'
                  className={cn('moving4 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
                />
                <Sticker6
                  src='/static/svg/2022/sticker-6-yes.svg'
                  className={cn('moving5 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
                />
                <Sticker7
                  src='/static/svg/2022/sticker-7-yes.svg'
                  className={cn('moving6 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
                />
                <Sticker5
                  src='/static/svg/2022/sticker-5-yes.svg'
                  className={cn('moving7 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
                />
                <Sticker8
                  src='/static/svg/2022/sticker-8-yes.svg'
                  className={cn('moving8 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
                />
                <Sticker9
                  src='/static/svg/2022/sticker-9-sod.svg'
                  className={cn('moving9 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
                />
                <Sticker10
                  src='/static/svg/2022/sticker-10-yes.svg'
                  className={cn('moving10 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
                />
                <Sticker11
                  src='/static/svg/2022/sticker-11-future.svg'
                  className={cn('moving11 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
                />
                <Sticker12
                  src='/static/svg/2022/sticker-12-missthis.svg'
                  className={cn('moving13 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
                />
                <Sticker15
                  src='/static/svg/2022/sticker-15.svg'
                  className={cn('moving14 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
                />
                <StickerPlay
                  src='/static/svg/2022/sticker-play.svg'
                  className={cn('moving15 absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
                />
                <TitleWrapper
                  className={cn('z-10  w-96 top-10 h-screen transition-opacity duration-700 ease-in-out hidden lg:block')}>
                  <div className='text-center relative'>
                    <TitleLeft className='text-secondary' style={{ pointerEvents: "none"}}>GRADS</TitleLeft>
                  </div>
                </TitleWrapper>

              </GradsContainer>
              <EventsContainer>
                <TitleWrapper
                  className={cn('z-10  w-96 top-10 h-screen transition-opacity duration-700 ease-in-out hidden lg:block')}>
                  <div className='text-center relative'>
                    <TitleLeft className='text-secondary' style={{ pointerEvents: "none"}}>EVENTS</TitleLeft>
                  </div>
                </TitleWrapper>
                <div style={{display: 'flex', flexDirection:'column', alignItems: 'center',position: 'absolute', top: '115px',left: 0,right: 0}}>
                    <div style={{flex: 1,display: 'flex', flexDirection:'row'}}>
                      <div onClick={() => setEventDay(1)}>
                        <div style={{transform: (eventDay!==1 ? 'scaleY(-1)' : '')}}>
                        <svg width="120" height="60" viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                            <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill={(eventDay!==1 ? '#E3E3DE' : '#ff6044')} fill-rule="evenodd"/>
                        </svg>
                        </div>
                        <p
                          style={{
                            position: 'relative',
                            top: 'calc(-56px)',
                            color: '#191919',
                            fontSize: '42px',
                            letterSpacing: '1.12px',
                            fontFamily: 'sharp_grotesque',
                            height: 0,
                            overflow: 'visible'
                          }}
                        >
                          DAY 1
                        </p>
                        </div>
                        <div  onClick={() => setEventDay(2)}>
                        <div style={{transform: (eventDay!==2 ? 'scaleY(-1)' : '')}}>
                        <svg width="120" height="60" viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                            <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill={(eventDay!==2 ? '#E3E3DE' : '#ff6044')} fill-rule="evenodd"/>
                        </svg>
                        </div>
                        <p
                          style={{
                            position: 'relative',
                            top: 'calc(-56px)',
                            color: '#191919',
                            fontSize: '42px',
                            letterSpacing: '1.12px',
                            fontFamily: 'sharp_grotesque',
                            height: 0,
                            overflow: 'visible'
                          }}
                        >
                          DAY 2
                        </p>
                        </div>
                        <div onClick={() => setEventDay(3)}>
                        <div style={{transform: (eventDay!==3 ? 'scaleY(-1)' : '')}}>
                        <svg width="120" height="60" viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                            <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill={(eventDay!==3 ? '#E3E3DE' : '#ff6044')} fill-rule="evenodd"/>
                        </svg>
                        </div>
                        <p
                          style={{
                            position: 'relative',
                            top: 'calc(-56px)',
                            color: '#191919',
                            fontSize: '42px',
                            letterSpacing: '1.12px',
                            fontFamily: 'sharp_grotesque',
                            height: 0,
                            overflow: 'visible'
                          }}
                        >
                          DAY 3
                        </p>
                        </div>
                        <div onClick={() => setEventDay(4)}>
                        <div style={{transform: (eventDay!==4 ? 'scaleY(-1)' : '')}} >
                        <svg width="120" height="60" viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                            <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill={(eventDay!==4 ? '#E3E3DE' : '#ff6044')}fill-rule="evenodd"/>
                        </svg>
                        </div>
                        <p
                          style={{
                            position: 'relative',
                            top: 'calc(-56px)',
                            color: '#191919',
                            fontSize: '42px',
                            letterSpacing: '1.12px',
                            fontFamily: 'sharp_grotesque',
                            height: 0,
                            overflow: 'visible'
                          }}
                        >
                          DAY 4
                        </p>
                        </div>

                    </div>

                    <div style={{flex: 4,position: 'relative', top: '-2px'}}>
                        <svg width="480" height="241" viewBox="0 0 480 241" xmlns="http://www.w3.org/2000/svg">
                            <path d="M240 240.73c132.548 0 240-107.145 240-239.314l-.001-.687L.006.73 0 1.416c0 130.847 105.313 237.168 236.031 239.281z" fill="#E3E3DE" fill-rule="evenodd"/>
                        </svg>
                        <p
                          style={{
                            position: 'relative',
                            top: 'calc(-250px)',
                            color: '#191919',
                            fontSize: '160px',
                            letterSpacing: '1.12px',
                            fontFamily: 'sharp_grotesque'
                          }}
                        >
                          DAY {eventDay}
                        </p>
                    </div>
                </div>
              </EventsContainer>
              {/* <ShowContainer>
                         <p>Show</p>
              </ShowContainer> */}
            </div>
          </div>
          </div>
          <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
        </CenterContainer>


      {/* {
        !isOpenMenuNavs && (
            <CurvedContainer>
            <svg viewBox='0 0 500 500' preserveAspectRatio='xMinYMin meet'>
              <path
                d='M0,50 C150,100 350,0 500,10 L500,00 L0,0 Z'
                style={{ keyStroke: 'none', fill: '#191919' }}
              ></path>
            </svg>
          </CurvedContainer>
        )
      } */}


     <SearchIcon className='hidden lg:block' />
      < FixedBottomNav className='hidden lg:block' />
      {/* <FootCont style={{
        display: isOpenMenuNavs ? "none" : "block"
      }}>
        <Footer  />
      </FootCont> */}
     </Container>
  )
}
