import React, { Component } from 'react'
import gql from 'graphql-tag'
import ProjectForm from './styles/ProjectForm'
import InputLimit from './styles/InputLimit'
import styled from 'styled-components'
import { client } from '../..'
import { withRouter } from 'react-router-dom'
import TagsInput from './TagsInput'
import { Progress } from 'semantic-ui-react'
import SearchInput from './SearchInput'
import Loading from './Loading'
import { mapErrorsMsg } from '../2020/utils'
import { QUERY } from '../../graphql'

const MAX_IMG_SIZE = 5 * 1024 * 1024
const MAX_VIDEO_SIZE = 50 * 1024 * 1024
const allowedImageExt = 'image/bmp, image/jpeg, image/png, image/gif'
const allowedVideoExt = 'video/*, .mkv, video/mp4,video/x-m4v'

const SINGLE_PROJECT_QUERY = gql`
  query SINGLE_PROJECT_QUERY($id: ID!) {
    project(where: { id: $id }) {
      id
      title
      tags
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        id
        role
        user {
          id
        }
      }
      published
    }
  }
`

const UPDATE_PROJECT_MUTATION = gql`
  mutation UPDATE_PROJECT_MUTATION(
    $id: ID!
    $title: String
    $tags: String
    $primaryProjectType: String
    $secondaryProjectType: String
    $secondaryProjectType_2: String
    $contentType: [ContentType]
    $contentData: [String]
    $coverImage: String
    $members: [CustomInput]
    $published: Boolean
  ) {
    updateProject(
      id: $id
      title: $title
      tags: $tags
      primaryProjectType: $primaryProjectType
      secondaryProjectType: $secondaryProjectType
      secondaryProjectType_2: $secondaryProjectType_2
      contentType: $contentType
      contentData: $contentData
      members: $members
      coverImage: $coverImage
      published: $published
    ) {
      id
      title
      tags
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      members {
        id
        role
        user {
          id
        }
      }
      coverImage
      published
    }
  }
`

const USER_UPLOAD_FILE = gql`
  mutation($file: Upload!, $isVideo: Boolean) {
    singleUpload(file: $file, isVideo: $isVideo) {
      id
      path
      filename
    }
  }
`

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 20px;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 60px;
`
const GridOne = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  video {
    max-width: 50%;
  }
`
const GridThree = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 0.5fr;
  grid-gap: 60px;
`
const GridButton = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.5fr;
  grid-gap: 60px;
`

const UploadBlock = styled.div`
  background-color: #e3e3e3;
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .upload-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
  }

  .upload-placeholder {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.5;
    z-index: 2;
  }
`

const UploadIcon = styled.img`
  background: rgb(243, 215, 222);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  height: 50px;
  width: 50px;
  padding: 10px;

  &:hover,
  &:focus {
    outline: none;
  }
`

const CoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`

const ContentInput = styled.input`
  margin-bottom: 30px;
`

const ContentTextarea = styled.textarea`
  margin-bottom: 30px;
`

const HelveticaTitle = styled.p`
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: black;
  font-size: 24px;
`
const Button = styled.button`
  ${(props) =>
    props.isLoading
      ? `
    opacity: .7;
    pointer-events: none;`
      : ''}
`

class UpdateProject extends Component {
  state = {
    loading: true,
    project: {
      id: '',
      title: '',
      primaryProjectType: '',
      secondaryProjectType: '',
      secondaryProjectType_2: '',
      contentType: { set: [] },
      contentData: { set: [] },
      coverImage: '',
      members: [],
      published: false,
    },
    memberInputs: [],
    contentInputs: [],
    uploadFinish: true,
    usersData: [],
    error: undefined,
  }

  componentDidMount = async () => {
    try {
      const { data, loading } = await client.query({
        query: SINGLE_PROJECT_QUERY,
        variables: { id: this.props.id },
        fetchPolicy: 'no-cache',
      })
      const { data: userQuery } = await client.query({
        query: QUERY.USERS_QUERY,
        variables: {
          where: {
            studentYear: 2023
          }
        }
      })
      if (!loading && data.project) {
        if (Array.isArray(data.project.contentType)) {
          this.setState((prevState) => {
            let project = Object.assign({}, prevState.project)
            project = data.project
            if (Array.isArray(data.project.contentType)) {
              project.contentType = {
                set: data.project.contentType.map((type) => type),
              }
              project.contentData = {
                set: data.project.contentData.map((data) => data),
              }
            }
            project.members = project.members.map((member) => {
              const { role, user } = member
              return { id: user.id, role }
            })
            project.tags = project.tags ? project.tags.split(',') : []
            return { project, loading: false }
          })
        }
        const usersData = userQuery.users
        this.setState({ usersData })
      }
    } catch (error) {
      const err = mapErrorsMsg(error)
      this.setState({ error: err, loading: false })
    }
  }

  appendMember = (e) => {
    const { project, usersData } = this.state
    if (usersData.length > 0) {
      const members = [...project.members, { id: usersData[0].id, role: 'Default role' }]
      this.setState({ project: { ...project, members } })
    }
  }
  appendContent = (e) => {
    // var newContentInput = `${this.state.contentInputs.length + 1}`;
    // this.setState(prevState => ({
    //   contentInputs: prevState.contentInputs.concat([newContentInput])
    // }));
    this.setState((prevState) => {
      let project = Object.assign({}, prevState.project)

      project.contentType.set.push('Heading')

      return { project }
    })
  }
  handleChange = (e) => {
    const { name, type, value } = e.target
    const val = type === 'number' ? parseFloat(value) : value
    this.setState((prevState) => {
      let project = Object.assign({}, prevState.project) // creating copy of state variable project
      project[name] = val // update the name property, assign a new value
      return { project } // return new object project object
    })
  }
  handleType = (e) => {
    const { name, value } = e.target
    this.setState((prevState) => {
      let project = Object.assign({}, prevState.project)
      project[name] = { set: [value] }
      return { project }
    })
  }
  handleMember = (e, index) => {
    const { value } = e.target
    // console.log("value: ", value, index);
    const { project } = this.state
    const members = project.members.map((mb, idx) => {
      if (index === idx) {
        return { ...mb, id: value }
      }
      return mb
    })
    // console.log("members: ", project.members);
    this.setState({ project: { ...project, members } })
  }
  handleMemberSearch = (value, index) => {
    const { project } = this.state
    const members = project.members.map((mb, idx) => {
      if (index === idx) {
        return { ...mb, id: value }
      }
      return mb
    })
    this.setState({ project: { ...project, members } })
  }

  handleRole = (e, index) => {
    const { value } = e.target
    const { project } = this.state
    const members = project.members.map((mb, idx) => {
      if (idx === index) {
        return { ...mb, role: value }
      }
      return mb
    })
    this.setState({ project: { ...project, members } })
  }
  handleContentType = (e) => {
    const { id, name, value } = e.target
    this.setState({ [id]: value })
    const [, index] = name.split('contentType_')
    this.setState((prevState) => {
      let project = Object.assign({}, prevState.project)
      if (project.contentType.set.length <= index) {
        project.contentType.set.push(value)
      } else {
        project.contentType.set.splice(parseInt(index), 1, value)
      }
      return { project }
    })
  }
  handleContentData = (e) => {
    const { name, value } = e.target
    const [, index] = name.split('contentData_')
    //console.log(name);
    this.setState((prevState) => {
      let project = Object.assign({}, prevState.project)
      if (project.contentData.set.length <= index) {
        project.contentData.set.push(value)
      } else {
        project.contentData.set.splice(parseInt(index), 1, value)
      }
      return { project }
    })
  }
  uploadFile = async (e) => {
    const files = e.target.files
    const file = files[0]

    if (file.size > MAX_IMG_SIZE) {
      alert('Maximum image size is 5MB')
      this.setState({ uploadFinish: true })
      e.target.value = null
      return
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = async (event) => {
        if (event.total > MAX_IMG_SIZE) {
          alert('Maximum image size is 5MB')
          return
        }

        const { data } = await client.mutate({
          mutation: USER_UPLOAD_FILE,
          variables: { file },
        })
        const coverImage = data.singleUpload.path
        this.setState((prevState) => {
          let project = Object.assign({}, prevState.project)
          project.coverImage = coverImage
          return { project, uploadFinish: true }
        })
      }
    }
  }

  uploadContentFile = async (e) => {
    const { name, id } = e.target
    this.setState({ uploadFinish: false })
    const files = e.target.files
    const file = files[0]
    const [media, index] = id.split('_')
    const isVideo = media === 'video'

    // fake loading
    let interval
    const keyState = `contentType_${index}_loading`
    if (isVideo) {
      interval = this.fakeLoading(keyState)
    }

    const size = isVideo ? MAX_VIDEO_SIZE : MAX_IMG_SIZE
    if (file.size > size) {
      alert(`Maximum ${isVideo ? 'video' : 'image'} size is ${isVideo ? '50 MB' : '5 MB'}`)
      this.setState({ uploadFinish: true })
      clearInterval(interval)
      e.target.value = null
      return
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async (event) => {
        if (event.total > size) {
          alert(`Maximum ${isVideo ? 'video' : 'image'} size is ${isVideo ? '50 MB' : '5 MB'}`)
          this.setState({ uploadFinish: true })
          clearInterval(interval)
          return
        }
        const { data } = await client.mutate({
          mutation: USER_UPLOAD_FILE,
          variables: { file, isVideo },
        })
        clearInterval(interval)
        const path = data.singleUpload.path
        const [, index] = name.split('contentData_')
        this.setState(
          (prevState) => {
            let project = Object.assign({}, prevState.project)
            if (project.contentData.length < index) {
              project.contentData.set.push(path)
            } else {
              project.contentData.set.splice(parseInt(index), 1, path)
            }
            return { project, uploadFinish: true, [keyState]: 100 }
          },
          () => {
            setTimeout(() => {
              this.setState({ [keyState]: undefined })
            }, 3000)
          },
        )
      }
    }
  }

  fakeLoading = (keyState) => {
    const percent = Math.ceil(Math.random(7) * 10)
    const interval = setInterval(() => {
      if (this.state[keyState] && this.state[keyState] < 90) {
        this.setState((prevState) => ({
          [keyState]: prevState[keyState] + percent,
        }))
      } else {
        this.setState((prevState) => ({ [keyState]: 10 }))
      }
    }, 500)
    return interval
  }

  updateProject = async (e) => {
    e.preventDefault()
    const { project } = this.state
    const tags = project.tags ? project.tags.join(',') : ''

    const filteredContentData = project.contentData.set.filter((cData) => cData !== '')

    const filteredContentType = project.contentType.set.filter(
      (cType, index) => index < filteredContentData.length,
    )

    const res = await client.mutate({
      mutation: UPDATE_PROJECT_MUTATION,
      variables: {
        id: this.props.id,
        title: project.title,
        tags,
        primaryProjectType: project.primaryProjectType,
        secondaryProjectType: project.secondaryProjectType,
        secondaryProjectType_2: project.secondaryProjectType_2,
        contentType: filteredContentType,
        contentData: filteredContentData,
        coverImage: project.coverImage,
        members: project.members,
        published: true,
      },
    })
    const newProject = {
      ...project,
      members: res.data.updateProject.members.map((mb) => {
        const { role, user } = mb
        return { id: user.id, role }
      }),
    }
    this.setState({ project: newProject })
    this.props.history.push({
      pathname: '/project',
      search: `?id=${res.data.updateProject.id}`,
    })
  }

  handlePreview = async () => {
    const { project } = this.state
    const tags = project.tags ? project.tags.join(',') : ''

    const filteredContentData = project.contentData.set.filter((cData) => cData !== '')

    const filteredContentType = project.contentType.set.filter(
      (cType, index) => index < filteredContentData.length,
    )

    const variables = {
      id: this.props.id,
      title: project.title,
      tags,
      primaryProjectType: project.primaryProjectType,
      secondaryProjectType: project.secondaryProjectType,
      secondaryProjectType_2: project.secondaryProjectType_2,
      contentType: filteredContentType,
      contentData: filteredContentData,
      coverImage: project.coverImage,
      members: project.members,
    }

    try {
      await client.mutate({
        mutation: UPDATE_PROJECT_MUTATION,
        variables,
      })
    } catch (error) {
      console.log('error: ', error)
    }
    // change them to the single item page
    this.props.history.push({
      pathname: '/preview',
      search: `?id=${this.props.id}`,
    })
    // console.log("res data", res.data);
    return true
  }

  handleRemoveUserFromProject = async (index) => {
    const { project } = this.state
    const members = project.members.filter((_, idx) => idx !== index)
    this.setState({ project: { ...project, members } })
  }

  handleRemoveAddedUser = (member, index) => {
    const { memberInputs } = this.state
    this.setState({
      memberInputs: memberInputs.filter((mem) => mem !== member),
    })
  }

  handleRemoveContent = (index) => {
    const { project } = this.state
    const setType = project.contentType.set.filter((type, idx) => idx !== index)
    const setData = project.contentData.set.filter((type, idx) => idx !== index)
    this.setState({
      project: {
        ...project,
        contentType: { set: setType },
        contentData: { set: setData },
      },
    })
  }

  handleChangeTags = (tags) => {
    this.setState({
      project: {
        ...this.state.project,
        tags,
      },
    })
  }

  render() {
    const { loading, project: projectState, usersData, error } = this.state

    if (loading) return <Loading />

    if (error) {
      return <div>{error}</div>
    }

    return (
      <ProjectForm onSubmit={this.updateProject}>
        <fieldset disabled={loading} aria-busy={loading}>
          <GridOne>
            <Grid>
              <div>
                <HelveticaTitle>Project Information</HelveticaTitle>
                <label htmlFor='title'>
                  * project title (&lt;55 characters)
                  <InputLimit
                    type='text'
                    id='title'
                    name='title'
                    placeholder='Title'
                    required
                    defaultValue={projectState.title}
                    maxLength={55}
                    value={this.state.project.title}
                    onChange={this.handleChange}
                  />
                </label>
                {projectState.members.map((member, index) => {
                  const { role, id } = member
                  const user = usersData.find((usr) => usr.id === id)
                  if (user) {
                    return (
                      <Columns key={`key_${member.id}-${index}`}>
                        <label htmlFor={`member_${member.id}`}>
                          Group Member {index + 1}
                          {/* <select
                            onChange={(e) => this.handleMember(e, index)}
                            defaultValue={id}
                          >
                            {usersData.map((usr, idx) => (
                              <option value={usr.id} key={`usr_${usr.id}`}>
                                {usr.firstName} {usr.lastName}
                              </option>
                            ))}
                          </select> */}
                          <SearchInput
                            users={usersData}
                            onChange={(value) => this.handleMemberSearch(value, index)}
                            value={`${user.firstName} ${user.lastName}`}
                            disabled={index === 0}
                          />
                          {index !== 0 && (
                            <p
                              onClick={() => this.handleRemoveUserFromProject(index)}
                              style={{
                                fontSize: '18px',
                                color: '#000',
                                textDecoration: 'underline',
                              }}
                            >
                              Remove
                            </p>
                          )}
                        </label>
                        <label htmlFor={`role_${member.id}`}>
                          Role
                          <input
                            type='text'
                            id={`role_${member.id}`}
                            defaultValue={role}
                            onChange={(e) => this.handleRole(e, index)}
                          />
                        </label>
                      </Columns>
                    )
                  }
                  return null
                })}
                <p
                  onClick={() => this.appendMember()}
                  style={{
                    fontSize: '18px',
                    color: '#000',
                    textDecoration: 'underline',
                  }}
                >
                  + Group Member
                </p>
              </div>
              <div>
                <label htmlFor='coverImage'>
                  <HelveticaTitle>Cover Image</HelveticaTitle>
                  <UploadBlock>
                    <input
                      type='file'
                      id='coverImage'
                      name='coverImage'
                      placeholder='Upload an image'
                      onChange={this.uploadFile}
                      className='upload-input'
                      accept={allowedImageExt}
                    />
                    {!projectState.coverImage && (
                      <img className='upload-placeholder' src={'/static/drawing.svg'} alt='' />
                    )}
                    <UploadIcon src='/static/edit.svg' alt='Change Image' />
                    {projectState.coverImage && (
                      <CoverImage src={projectState.coverImage} alt='Upload Preview' />
                    )}
                  </UploadBlock>
                </label>
              </div>
              <div>
                <label htmlFor='tags'>
                  <HelveticaTitle>Tags</HelveticaTitle>
                  <TagsInput
                    defaultValue={projectState.tags || []}
                    onChange={this.handleChangeTags}
                  />
                </label>
              </div>
            </Grid>
            <GridThree>
              <label htmlFor='primaryProjectType'>
                <HelveticaTitle>* Primary Project Type</HelveticaTitle>
                <select
                  id='primaryProjectType'
                  name='primaryProjectType'
                  defaultValue={projectState.primaryProjectType}
                  onChange={this.handleChange}
                >
                  <option disabled value={''}>
                    -- select a project type --
                  </option>
                  <option value='3d_design'>3D Design</option>
                  <option value='3d_sculpting'>3D Sculpting</option>
                  <option value='3d_visualizations'>3D Visualizations</option>
                  <option value='advertising'>Advertising</option>
                  <option value='animation'>Animation</option>
                  <option value='augmented_virtual_reality'>Augmented/Virtual Reality</option>
                  <option value='branding'>Branding</option>
                  <option value='device_development'>Device Development</option>
                  <option value='digital_applications'>Digital Applications</option>
                  <option value='drawing'>Drawing</option>
                  <option value='editorial'>Editorial</option>
                  <option value='exhibition'>Exhibition</option>
                  <option value='game_design'>Game Design</option>
                  <option value='illustration'>Illustration</option>
                  <option value='immersive_environments'>Immersive Environments</option>
                  <option value='interactive_design'>Interactive Design</option>
                  <option value='interface_development'>Interface Development</option>
                  <option value='media_production'>Media Production</option>
                  <option value='modeling'>Modeling</option>
                  <option value='motion_graphics'>Motion Graphics</option>
                  <option value='packaging'>Packaging</option>
                  <option value='photography'>Photography</option>
                  <option value='physical_computing'>Physical Computing</option>
                  <option value='strategy'>Strategy</option>
                  <option value='systems_and_services'>Systems and Services</option>
                  <option value='technological_systems'>Technological Systems</option>
                  <option value='typography'>Typography</option>
                  <option value='user_experience'>User Experience</option>
                  <option value='video'>Video</option>
                  <option value='web_design'>Web Design</option>
                </select>
              </label>
              <label htmlFor='secondaryProjectType'>
                <HelveticaTitle>Secondary Project Type</HelveticaTitle>
                <select
                  id='secondaryProjectType'
                  name='secondaryProjectType'
                  defaultValue={projectState.secondaryProjectType}
                  onChange={this.handleChange}
                >
                  <option disabled value={''}>
                    -- select a project type --
                  </option>
                  <option value='3d_design'>3D Design</option>
                  <option value='3d_sculpting'>3D Sculpting</option>
                  <option value='3d_visualizations'>3D Visualizations</option>
                  <option value='advertising'>Advertising</option>
                  <option value='animation'>Animation</option>
                  <option value='augmented_virtual_reality'>Augmented/Virtual Reality</option>
                  <option value='branding'>Branding</option>
                  <option value='device_development'>Device Development</option>
                  <option value='digital_applications'>Digital Applications</option>
                  <option value='drawing'>Drawing</option>
                  <option value='editorial'>Editorial</option>
                  <option value='exhibition'>Exhibition</option>
                  <option value='game_design'>Game Design</option>
                  <option value='illustration'>Illustration</option>
                  <option value='immersive_environments'>Immersive Environments</option>
                  <option value='interactive_design'>Interactive Design</option>
                  <option value='interface_development'>Interface Development</option>
                  <option value='media_production'>Media Production</option>
                  <option value='modeling'>Modeling</option>
                  <option value='motion_graphics'>Motion Graphics</option>
                  <option value='packaging'>Packaging</option>
                  <option value='photography'>Photography</option>
                  <option value='physical_computing'>Physical Computing</option>
                  <option value='strategy'>Strategy</option>
                  <option value='systems_and_services'>Systems and Services</option>
                  <option value='technological_systems'>Technological Systems</option>
                  <option value='typography'>Typography</option>
                  <option value='user_experience'>User Experience</option>
                  <option value='video'>Video</option>
                  <option value='web_design'>Web Design</option>
                </select>
              </label>
              <label htmlFor='secondaryProjectType_2'>
                <HelveticaTitle>Secondary Project Type</HelveticaTitle>
                <select
                  id='secondaryProjectType_2'
                  name='secondaryProjectType_2'
                  defaultValue={projectState.secondaryProjectType_2}
                  onChange={this.handleChange}
                >
                  <option disabled value={''}>
                    -- select a project type --
                  </option>
                  <option value='3d_design'>3D Design</option>
                  <option value='3d_sculpting'>3D Sculpting</option>
                  <option value='3d_visualizations'>3D Visualizations</option>
                  <option value='advertising'>Advertising</option>
                  <option value='animation'>Animation</option>
                  <option value='augmented_virtual_reality'>Augmented/Virtual Reality</option>
                  <option value='branding'>Branding</option>
                  <option value='device_development'>Device Development</option>
                  <option value='digital_applications'>Digital Applications</option>
                  <option value='drawing'>Drawing</option>
                  <option value='editorial'>Editorial</option>
                  <option value='exhibition'>Exhibition</option>
                  <option value='game_design'>Game Design</option>
                  <option value='illustration'>Illustration</option>
                  <option value='immersive_environments'>Immersive Environments</option>
                  <option value='interactive_design'>Interactive Design</option>
                  <option value='interface_development'>Interface Development</option>
                  <option value='media_production'>Media Production</option>
                  <option value='modeling'>Modeling</option>
                  <option value='motion_graphics'>Motion Graphics</option>
                  <option value='packaging'>Packaging</option>
                  <option value='photography'>Photography</option>
                  <option value='physical_computing'>Physical Computing</option>
                  <option value='strategy'>Strategy</option>
                  <option value='systems_and_services'>Systems and Services</option>
                  <option value='technological_systems'>Technological Systems</option>
                  <option value='typography'>Typography</option>
                  <option value='user_experience'>User Experience</option>
                  <option value='video'>Video</option>
                  <option value='web_design'>Web Design</option>
                </select>
              </label>
            </GridThree>
            <HelveticaTitle>Content</HelveticaTitle>
            {projectState.contentType.set.map((input, i) => (
              <GridOne key={`contentKey_${i}`}>
                <label htmlFor={`contentType_${i}`}>
                  <select
                    id={`contentType_${i}`}
                    name={`contentType_${i}`}
                    onChange={this.handleContentType}
                    defaultValue={projectState.contentType.set[i]}
                    style={{ width: '24%' }}
                  >
                    <option disabled value={'DEFAULT'}>
                      -- select a content type --
                    </option>
                    <option value='Heading'>Heading</option>
                    <option value='Text'>Text</option>
                    <option value='Video'>Video</option>
                    <option value='Image'>Image</option>
                  </select>
                </label>
                {projectState.contentType.set[i] === 'Heading' && (
                  <label htmlFor={`contentData_${i}`}>
                    heading
                    <ContentInput
                      type='text'
                      id={`contentData_${i}`}
                      name={`contentData_${i}`}
                      placeholder=''
                      defaultValue={projectState.contentData.set[i]}
                      onChange={this.handleContentData}
                    />
                    <p
                      style={{
                        fontSize: '18px',
                        color: '#000',
                        textDecoration: 'underline',
                      }}
                      onClick={() => this.handleRemoveContent(i)}
                    >
                      Remove
                    </p>
                  </label>
                )}
                {projectState.contentType.set[i] === 'Text' && (
                  <label htmlFor={`contentData_${i}`}>
                    text
                    <ContentTextarea
                      type='text'
                      id={`contentData_${i}`}
                      name={`contentData_${i}`}
                      placeholder=''
                      defaultValue={projectState.contentData.set[i]}
                      onChange={this.handleContentData}
                    />
                    <p
                      style={{
                        fontSize: '18px',
                        color: '#000',
                        textDecoration: 'underline',
                      }}
                      onClick={() => this.handleRemoveContent(i)}
                    >
                      Remove
                    </p>
                  </label>
                )}
                {projectState.contentType.set[i] === 'Video' && (
                  <label htmlFor={`contentData_${i}`}>
                    video
                    <ContentInput
                      type='file'
                      id={`video_${i}`}
                      name={`contentData_${i}`}
                      placeholder='Upload a video'
                      onChange={this.uploadContentFile}
                      accept={allowedVideoExt}
                    />
                    {this.state[`contentType_${i}_loading`] > 0 && (
                      <Progress
                        percent={this.state[`contentType_${i}_loading`]}
                        size='small'
                        color='green'
                      />
                    )}
                    <p
                      style={{
                        fontSize: '18px',
                        color: '#000',
                        textDecoration: 'underline',
                      }}
                      onClick={() => this.handleRemoveContent(i)}
                    >
                      Remove
                    </p>
                  </label>
                )}
                {projectState.contentType.set[i] === 'Image' && (
                  <label htmlFor={`contentData_${i}`}>
                    image
                    <ContentInput
                      type='file'
                      id={`image_${i}`}
                      name={`contentData_${i}`}
                      placeholder='Upload an image'
                      onChange={this.uploadContentFile}
                      accept={allowedImageExt}
                    />
                    {this.state[`contentType_${i}_loading`] > 0 && (
                      <Progress
                        percent={this.state[`contentType_${i}_loading`]}
                        size='small'
                        color='green'
                      />
                    )}
                    <p
                      style={{
                        fontSize: '18px',
                        color: '#000',
                        textDecoration: 'underline',
                      }}
                      onClick={() => this.handleRemoveContent(i)}
                    >
                      Remove
                    </p>
                  </label>
                )}
                {projectState.contentData.set.map((content, j) => {
                  if (
                    content !== undefined &&
                    parseInt(j) === parseInt(i) &&
                    content.includes('http://yes.schoolofdesign.ca')
                  ) {
                    if (projectState.contentType.set[i] === 'Image') {
                      return <img src={content} alt='Upload Preview ' key={content} />
                    } else {
                      return (
                        <video key={j}>
                          <source type='video/mp4' src={content} key={content}></source>
                        </video>
                      )
                    }
                  }
                  return null
                })}
              </GridOne>
            ))}
            <p onClick={() => this.appendContent()}>+ Add Field</p>
            <GridButton>
              <div></div>
              <Button
                type='Button'
                onClick={this.handlePreview}
                isLoading={!this.state.uploadFinish}
              >
                SAVE/PREVIEW
              </Button>
              <Button type='submit' isLoading={!this.state.uploadFinish}>
                PUBLISH
              </Button>
            </GridButton>
          </GridOne>
        </fieldset>
      </ProjectForm>
    )
  }
}

export default withRouter(UpdateProject)
export { UPDATE_PROJECT_MUTATION }
