import React, { useMemo } from 'react'
import styled from 'styled-components'
import useCountdown from '../../hook/useCountdown'
import cn from 'classnames'
import { useLocation } from 'react-router-dom'

const CurvedContainer = styled.div`
  position: relative;
  width: 100%;
  height: 440px;
  vertical-align: middle;
  overflow: hidden;
  color: #e3e3de;
  // background: #e3e3de;
  svg {
    bottom: 20px;
    top: 66px;
  }
`

const MainContainer = styled.div`
  position: relative;
  left:0;
  bottom:0;
  font-size: 20px;
  color: #e3e3de;
  height: 200%;
`
const FooterItem = styled.footer`
bottom:50px;
color: #e3e3de;
  .yes2021 {
    color: #e3e3de;
    width: 100%;
    font-family: 'sharp_grotesque';
    font-size: 160px;
    line-height: 85%;
    @media (min-width: 360px) {
      font-size: 400px;
    }
    @media (min-width: 768px) {
      font-size: 550px;
    }
    @media (min-width: 1280px) {
      font-size: 600px;
    }
    @media (min-width: 1680px) {
      font-size: 650px;
    }
    @media (min-width: 1920px) {
      font-size: 700px;
    }
    @media (min-width: 2560px) {
      font-size: 900px;
    }
    @media (min-width: 2880px) {
      font-size: 1000px;
    }
    @media (min-width: 2880px) {
      font-size: 1400px;
    }
  }

  p, a {
    margin-bottom: 0;
    font-family: 'grotesque_pro_light';
  }
  a {
    top: -6px;
  }
  @media (min-width: 1024px) {
    top: 0px;
  }
`

export default function Footer({className = ''}) {
  const { minutes, hours, days } = useCountdown('05/03/2022')
  const { pathname } = useLocation()

  const isEventsOrHome = useMemo(() => {
    if (pathname?.startsWith('/events')) {
      return true
    }
    else if (pathname?.startsWith('/home')) {
      return true
    }
    return false
  }, [pathname])

  return (
    <MainContainer
      style={{
        // bottom: (isEventsOrHome && '100px'),
        // background: (isEvents ? '#e3e3de' :'#191919'),
      }}
    >
    {/* <link rel='stylesheet' href='/static/transition-css.css' type='text/css' />
    <div className='left-layer'></div>
    <div className='right-layer'></div> */}
    <CurvedContainer>
          <svg viewBox='0 0 500 500' preserveAspectRatio='xMinYMin meet' style={{
            position:(isEventsOrHome ? 'relative' :'absolute'),
            background: '#191919',

            //top: (isEventsOrHome && '40px'),
            }}>
            <path
              d='M0,50 C150,100 350,0 500,10 L500,00 L0,0 Z'
              style={{ keyStroke: 'none', fill: '#e3e3de' , verticalAlign:'unset',background: '#e3e3de'}}
            ></path>
          </svg>
    </CurvedContainer>
    <FooterItem className={cn('relative lg:flex justify-between z-2', className)} style={{backgroundColor:'#191919'}}>
      <div className='xl:ml-12 relative' style={{backgroundColor:'#191919'}}>
        <p className='yes2021' style={{backgroundColor:'#191919'}}>YES!</p>
        <p className='absolute top-0 left-0 uppercase text-white lg:-ml-4 font-light' style={{backgroundColor:'transparent'}}>
          School Of Design
        </p>
        <p className='absolute top-0 right-0 text-white -ml-4 font-light' style={{backgroundColor:'transparent'}}>Year End Show</p>
      </div>
      <div className='xl:mr-12 relative' style={{backgroundColor:'#191919'}}>
        <p className='yes2021 ' style={{backgroundColor:'#191919'}}>2022</p>
        <a
          href='https://www.instagram.com/sod_yes/'
          target='_blank'
          rel='noreferrer'
          className='absolute top-0 left-0  lg:-ml-4 font-light text-red-coral hover:text-red-coral hover:opacity-80 transition-opacity'
          style={{backgroundColor:'transparent'}}
        >
          @sod_yes
        </a>
        <p className='absolute top-0 right-0 mr-4 font-light' style={{backgroundColor:'transparent'}}>
          {days} days • {hours} hours • {minutes} minutes
        </p>
      </div>

    </FooterItem>
    </MainContainer>
  )
}
