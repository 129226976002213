import React, { useContext, useState } from 'react'
import { Mutation } from 'react-apollo'
import { gql, useMutation } from '@apollo/client'
import { UserProjectsContext } from './states/userProjects'
import { Button, Modal } from 'semantic-ui-react'
// import { useApolloClient } from '@apollo/client'
import { client } from '../../index'

const DELETE_ITEM_MUTATION = gql`
  mutation DELETE_ITEM_MUTATION($id: ID!) {
    deleteProject(id: $id) {
      id
    }
  }
`

const DeleteProject = (props) => {
  const [open, setOpen] = useState(false)
  const { dispatch } = useContext(UserProjectsContext)
  // const client = useApolloClient()

  const remove = async (deleteProject) => {
    client
      .mutate({
        mutation: DELETE_ITEM_MUTATION,
        variables: { id: props.id },
      })
      .then(() => {
        dispatch({ type: 'REMOVE_ONE_PROJECT', payload: props.id })
      })
  }

  const [deleteProject, { error}] = useMutation(DELETE_ITEM_MUTATION, {
    variables: { id: props.id },
    onCompleted: () => {
      dispatch({ type: 'REMOVE_ONE_PROJECT', payload: props.id })
    },
  })

  const toggleOpen = () => setOpen((v) => !v)

  return (

        <>
          <button
            // onClick={() => remove(deleteProject)}
            onClick={toggleOpen}
          >
            {props.children}
          </button>
          <Modal size='tiny' open={open} onClose={toggleOpen} centered={false}>
            <Modal.Header>
              <div style={{ fontSize: 20 }}>Delete Your Project</div>
            </Modal.Header>
            <Modal.Content>
              <p style={{ fontSize: 20 }}>Are you sure you want to delete your project?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button negative size='huge' onClick={toggleOpen}>
                No
              </Button>
              <Button
                size='huge'
                positive
                icon='checkmark'
                labelPosition='right'
                content='Yes'
                onClick={remove}
              />
            </Modal.Actions>
          </Modal>
        </>
  )
}

export default DeleteProject
