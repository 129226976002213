import styled from 'styled-components'

export const EventDiv = styled.div`
  padding: 2%;
  display: inline-block;
  height: 100%;
  vertical-align: top;

  .title {
    color: #191919;
    font-size: 24px;
    font-family: 'reckless_light';
    margin-top: 8%;
    text-align: left;
    line-height: 1.4;
  }
  .time {
    color: #191919;
    text-align: left;
    font-size: 18px;
    display: inline-block;
    font-family: 'grotesque_pro_light';
  }
  .yellow {
    color: #defc1c;
    font-size: 18px;
    display: inline-block;
    font-family: 'grotesque_pro_light';
    margin-left: 2%;
  }
  .desc {
    text-align: left;
    margin-top: 2%;
    color: #191919;
    font-size: 18px;
    height: 200px;
    line-height: 1.6;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp: 10;
    // -webkit-box-orient: vertical;
  }
  button {
    color: #ff6044;
    outline: none;
    width: 100%;
    height: 90px;
  }
`