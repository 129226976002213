import React, { useState, useEffect, useCallback } from 'react'
// import { Mutation } from 'react-apollo'
import { gql, useQuery, useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'
import LoginForm from './styles/LoginForm'
import Error from './ErrorMessage'
import { CURRENT_USER_QUERY } from './User'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { client } from '../..'
import Loading from './Loading'

const SIGNIN_MUTATION = gql`
  mutation SIGNIN_MUTATION($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      id
      email
      firstName
      lastName
    }
  }
`

const Signup = styled.p`
  font-size: 16px;
  font-weight: 300;
  display: inline;
  border-bottom: 1px solid;
`

const Signin = () => {
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  /* eslint-disable-next-line */
  const [me, setMe] = useState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [signin, { data, loadingSignin, errorSignin }] = useMutation(SIGNIN_MUTATION)

  useEffect(() => {
    client
      .query({ query: CURRENT_USER_QUERY, fetchPolicy: 'no-cache' })
      .then(({ data }) => {
        if (data && data.me) {
          setMe(data.me)
          setLoading(false)
          history.push('/projects')
        } else {
          setLoading(false)
        }
      })
      .catch()
    /* eslint-disable-next-line */
  }, [])

  const handleChangeEmail = useCallback((e) => {
    const email = e.target.value
    setEmail(email)
  }, [])
  const handleChangePasswd = useCallback((e) => {
    const pwd = e.target.value
    setPassword(pwd)
  }, [])

  if (loading) return <Loading />

  return (
    <div>
      <LoginForm
        method='post'
        onSubmit={async (e) => {
          e.preventDefault()
          try {
            await signin({
              variables: {
                email,
                password,
              },
            })
            history.push('/projects')
            window.location.reload()
          } catch (error) {
            console.log('error: ', error)
          }
        }}
      >
        <fieldset disabled={loading} aria-busy={loading}>
          <Error error={errorSignin} />
          <label htmlFor='email'>
            <input
              type='email'
              name='email'
              placeholder='email'
              value={email}
              onChange={handleChangeEmail}
            />
          </label>
          <label htmlFor='password'>
            <input
              type='password'
              name='password'
              placeholder='password'
              value={password}
              onChange={handleChangePasswd}
            />
          </label>

          <button type='submit'>Sign In!</button>
        </fieldset>
        <br />
        <br />
        {/* <Link to='/forgotpassword' style={{ textDecoration: 'none' }}>
          <Signup>Forgot password</Signup>
        </Link> */}
      </LoginForm>
    </div>
  )
}

export default Signin
