import styled from "styled-components";

const UserProjectStyles = styled.div`
  background: white;
  /* border: 1px solid ${props => props.theme.offWhite};
  box-shadow: ${props => props.theme.bs}; */
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  padding-top: 5px;

  img {
    width: 70%;
    height: 200px;
    object-fit: contain;
  }

  p {
    line-height: 2;
    font-weight: 300;
    flex-grow: 1;
    padding: 0 3rem;
    font-size: 1.5rem;
  }

  .buttonList{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1rem;
  }

  .buttonList a,
  button {
    width: 120px;
    background: ${props => props.theme.pink};
    color: ${props => props.theme.black};
    border: 0;
    font-family: 'grotesque_pro_light';
    font-size: 2rem;
    font-weight: normal;
    padding: 1.125rem 2.2rem;
    margin-top: 1rem;
    border-radius: 24px;
    height: 30px;
    line-height: 1;
    text-align: center;
  }

  button {
    margin-left: 15px;

    @media (min-width: 768px) {
      min-width: 240px;
      height: 42px;
    }
  }

  .buttonList a {
    margin-right: 15px;

    @media (min-width: 768px) {
      min-width: 240px;
      height: 42px;
    }
  }

  .buttonList div {
    text-align: right;
  }
`;

export default UserProjectStyles;
