import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { VscMenu, VscSearch } from 'react-icons/vsc'
import StickerNavs from './StickerNavs'
import { Link, useHistory, useLocation } from 'react-router-dom'

const TextLogo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 8.5rem;
  line-height: 9rem;
  letter-spacing: 0.01em;
  font-weight: 400;
`

const Container = styled.div`
  height: -webkit-calc(100vh - 90px);
  height: -moz-calc(100vh - 90px);
  height: calc(100vh - 90px);
  position: fixed;
  top: 90px;
  width: 100vw;
  z-index: 322;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
`

const TextCenter = styled.h4`
  font-family: 'sharp_grotesque_05';
  font-size: 420px;
  line-height: 360px;
  letter-spacing: 0.01em;
  font-weight: 400;
  @media (min-width: 360px) {
    font-size: 460px;
    line-height: 400px;
  }
  @media (min-width: 768px) {
    font-size: 550px;
    line-height: 550px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 600px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 700px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 1000px;
    line-height: 1200px;
  }
  @media (min-width: 2880px) {
    font-size: 1400px;
    line-height: 1400px;
  }
`

export const MobileRightNavs = ({ isOpenMenuNavs, toggleMobileNavs, className = '' }) => {
  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])

  const navigate = useCallback(
    (url) => {
      if (url !== '/' && pathname?.startsWith(url)) {
        return
      }

      const leftLayer = document.getElementsByClassName('left-layer')?.[0]
      const rightLayer = document.getElementsByClassName('left-layer')?.[0]
      if (leftLayer && rightLayer) {
        leftLayer.classList.add('active')
        rightLayer.classList.add('active')
        setTimeout(() => history.push(url), 1500)
      }
    },
    [history, pathname],
  )

  const isHome = useMemo(() => {
    if (pathname?.length < 2) {
      return true
    }
    return false
  }, [pathname])

  return (
    <div
      className={cn(
        'fixed top-0 flex lg:hidden justify-between mb-8 lg:mb-0 bg-blue z-50 h-36 w-full',
        className,
      )}
      style={{ background: '#5933ff', zIndex: 444 }}
    >
      <TextLogo
        className='fixed text-center px-6 text-secondary cursor-pointer uppercase z-50 '
        onClick={!isHome ? () => navigate('/2021/') : null}
      >
        Yes!2021
      </TextLogo>
      <div className='fixed z-50' style={{ right: '8px' }}>
        {!isOpenMenuNavs
          ? ((document.body.style.overflow = 'auto'),
            (<VscMenu className='text-5xl my-2 mb-4 text-secondary' onClick={toggleMobileNavs} />))
          : ((document.body.style.overflow = 'hidden'),
            (
              <img
                src='/static/svg/close.svg'
                alt='close'
                className='my-4'
                onClick={toggleMobileNavs}
              />
            ))}
        <Link to='/2021/search'>
          <VscSearch className='text-5xl text-secondary' />
        </Link>
      </div>
    </div>
  )
}

export const MobileNavsBottom = ({ isOpenMenuNavs }) => {
  return (
    <Container
      className={cn('transition bg-blue', {
        'block visible opacity-100 relative py-24': isOpenMenuNavs,
        'hidden invisible opacity-0': !isOpenMenuNavs,
      })}
    >
      <StickerNavs withTop={false} className='flex justify-center items-center' />
      <div className='text-center absolute top-0 left-0 w-full'>
        <TextCenter className='text-black uppercase opacity-10 uppercase text-center'>
          Explore
        </TextCenter>
      </div>

      <Link to={{ pathname: '/2020' }} className='absolute bottom-0 left-0 w-full mb-24'>
        <div className='bg-pink'>
          <p
            className='text-black text-center tracking-wide'
            style={{
              fontFamily: 'sharp_grotesque',
              fontSize: '60px',
            }}
          >
            2020
          </p>
        </div>
      </Link>
    </Container>
  )
}
