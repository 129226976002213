import React from 'react'
import styled from 'styled-components'
import useCountdown from '../../hook/useCountdown'
import cn from 'classnames'
import Marquee from '../../components/2021/Marquee'

const MainContainer = styled.div`
  position: relative;
  left:0;
  bottom:0;
`
const FooterItem = styled.footer`
  .yes2021 {
    width: 100%;
    font-family: 'sharp_grotesque';
    font-size: 160px;
    line-height: 85%;
    padding: 10px !important;
    padding-bottom: 0 !important;;
    @media (min-width: 360px) {
      font-size: 400px;
    }
    @media (min-width: 768px) {

      font-size: 550px;
    }
    @media (min-width: 1280px) {
      font-size: 600px;
    }
    @media (min-width: 1680px) {
      font-size: 650px;
    }
    @media (min-width: 1920px) {
      font-size: 700px;
    }
    @media (min-width: 2560px) {
      font-size: 900px;
    }
    @media (min-width: 2880px) {
      font-size: 1000px;
    }
    @media (min-width: 2880px) {
      font-size: 1400px;
    }
  }

  p, a {
    margin-bottom: 0;
    font-family: 'grotesque_pro_light';
  }

`

export default function Footer({className = ''}) {
  const { minutes, hours, days } = useCountdown()
  return (
    <MainContainer>
    <link rel='stylesheet' href='/static/transition-css.css' type='text/css' />
    <div className='left-layer'></div>
    <div className='right-layer'></div>
    <FooterItem className={cn('relative lg:flex bg-blue justify-between z-2', className)}>
      <div className='xl:ml-12 relative'>
        <p className='yes2021 text-secondary'>YES!</p>
        <p className='absolute top-0 left-0 uppercase text-white lg:-ml-4 font-light'>
          School Of Design
        </p>
        <p className='absolute top-0 right-0 text-white -ml-4 font-light'>Year End Show</p>
      </div>
      <div className='xl:mr-12 relative'>
        <p className='yes2021 text-secondary'>2021</p>
        <a
          href='https://www.instagram.com/sod_yes/'
          target='_blank'
          rel='noreferrer'
          className='absolute top-0 left-0 text-secondary lg:-ml-4 font-light hover:text-secondary hover:opacity-80 transition-opacity'
        >
          @sod_yes
        </a>
        <p className='absolute top-0 right-0 text-secondary mr-4 font-light'>
          {days} days • {hours} hours • {minutes} minutes
        </p>
      </div>

    </FooterItem>
    <Marquee marquee />
    </MainContainer>
  )
}
