import React, { useState, useCallback, useEffect } from 'react'
import gql from 'graphql-tag'
import { useHistory, useLocation } from 'react-router-dom'
import LoginForm from '../../../components/2021/styles/LoginForm'
import qs from 'query-string'
import cogoToast from 'cogo-toast'
import { client } from '../../..'
import Loading from '../../../components/2021/Loading'
import { mapErrorsMsg } from '../../../components/2020/utils'

const CHECK_FORGOTPWD_TOKEN_VALID = gql`
  query CHECK_FORGOTPWD_TOKEN_VALID($token: String!) {
    checkFgotPasswdToken(token: $token)
  }
`
const SUBMIT_UPDATE_PASSWORD = gql`
  mutation SUBMIT_UPDATE_PASSWORD($token: String!, $password: String!) {
    submitForgotPassword(token: $token, password: $password)
  }
`
const INITIAL_STATE = { password: '', confirmPassword: '' }

const SubForgotPasswd = () => {
  const history = useHistory()
  const location = useLocation()
  const [{ password, confirmPassword }, setState] = useState(INITIAL_STATE)
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setState((v) => ({ ...v, [name]: value }))
  }, [])

  useEffect(() => {
    const search = qs.parse(location.search)
    if (search && search.token) {
      client
        .query({ query: CHECK_FORGOTPWD_TOKEN_VALID, variables: { token: search.token } })
        .then(({ data }) => {
          if (!data.checkFgotPasswdToken) {
            setError(true)
          } else {
            setToken(search.token)
          }
        })
        .catch((error) => {
          const msg = mapErrorsMsg(error)
          if (msg) {
            setError(msg)
          }
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
      setError(true)
    }
    /* eslint-disable-next-line */
  }, [])

  const handleForm = useCallback(
    (e) => {
      e && e.preventDefault()
      if (!password || !confirmPassword) return cogoToast.error('Password must have a value')
      if (password !== confirmPassword) return cogoToast.error('Password must have a value')

      client
        .mutate({ mutation: SUBMIT_UPDATE_PASSWORD, variables: { token, password } })
        .then(({ data }) => {
          if (data && data.submitForgotPassword) {
            cogoToast.success('Password updated successfully')
            setState(INITIAL_STATE)
            setTimeout(() => {
              history.push('/login')
            }, 2500)
          }
        })
        .catch((error) => {
          const msgError = mapErrorsMsg(error)
          return cogoToast.error(msgError)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    /* eslint-disable-next-line */
    [token, password, confirmPassword],
  )

  if (loading) return <Loading />
  if (error && typeof error === 'string') return <div>{error}</div>
  if (error) return <div>The page you're looking for is not available</div>

  return (
    <LoginForm method='post' onSubmit={handleForm}>
      <fieldset>
        <label htmlFor='password'>
          <input
            type='password'
            name='password'
            placeholder='Password'
            value={password}
            onChange={handleChange}
          />
        </label>
        <label htmlFor='confirm_password'>
          <input
            type='password'
            name='confirmPassword'
            placeholder='Confirm password'
            value={confirmPassword}
            onChange={handleChange}
          />
        </label>
        <button type='submit'>Confirm </button>
      </fieldset>
    </LoginForm>
  )
}

export default SubForgotPasswd
