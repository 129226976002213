import React, { useEffect } from 'react'
import styled from 'styled-components'
import StickerNavs from '../StickerNavs'
import cn from 'classnames'
import Footer from '../../../components/2021/Footer'
import SearchCol from '../../../components/2021/SearchCol'
import { useDisclosure } from 'react-use-disclosure'
import { MobileRightNavs, MobileNavsBottom } from '../MobileRightNavs'


const Container = styled.div`
  // min-height: calc(2200vh);
  cursor: url(${(props) => props.cursorUrl}), auto;
  > div {
    top: -70px;
  }
`

const EventDiv = styled.div`
  padding: 5%;
  display: inline-block;
  height: 100%;
  vertical-align: top;

  .title {
    color: white;
    font-size: 24px;
    font-family: 'reckless_light';
    margin-top: 8%;
    text-align: left;
    line-height: 1.4;
  }
  .time {
    color: white;
    text-align: left;
    font-size: 18px;
    display: inline-block;
    font-family: 'grotesque_pro_light';
    margin-top: 8%;
  }
  .yellow {
    color: #defc1c;
    font-size: 18px;
    display: inline-block;
    font-family: 'grotesque_pro_light';
    margin-left: 2%;
  }
  .desc {
    text-align: left;
    margin-top: 6%;
    color: white;
    font-size: 18px;
    height: 200px;
    line-height: 1.6;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp: 10;
    // -webkit-box-orient: vertical;
  }
  button {
    color: #defc1c;
    outline: none;
    width: 100%;
    height: 90px;
  }
`

const EventNumber = styled.div`
  position: absolute;
  left: 1%;
  font-family: 'sharp_grotesque_05';
  font-size: 1200px;
  color: #defc1c;
  line-height: 850px;
  // top:34%;

  // @media (min-width: 2880px) {
  //   top: 39%;
  // }

  @media (max-width: 1024px) {
      display: none;
    }

`

const CenterContainer = styled.div`
  @media (min-width: 1280px) {
    // top: 10%;
    // max-height: 40vh;
  }
  @media (min-width: 1920px) {
    // top: 20%;
  }

`
const TextCenter = styled.h4`
  font-family: 'sharp_grotesque_05';
  font-size: 420px;
  line-height: 360px;
  letter-spacing: 0.01em;
  font-weight: 400;
  @media (min-width: 360px) {
    font-size: 460px;
    line-height: 400px;
  }
  @media (min-width: 768px) {
    font-size: 550px;
    line-height: 550px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 600px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 700px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 1000px;
    line-height: 1200px;
  }
  @media (min-width: 2880px) {
    font-size: 1400px;
    line-height: 1400px;
  }
`

const HeadContainer = styled.div`
position: relative;
width: 250px;
height: 250px;
background-color: #5933FF;
border-radius: 50%;
img {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 220px;
        height: 220px;
        object-fit: cover;
        filter: grayscale(100%);
        border-radius: 50%;
  }
  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 221px;
    height: 221px;
    background-color: #000;
    filter: invert(25%) sepia(97%) saturate(7476%) hue-rotate(253deg) brightness(108%) contrast(100%);
    opacity: 0.5;
    border-radius: 50%;
    z-index: 1;
  }
  @media (max-width: 1024px) {
    left:-webkit-calc(50% - 125px);
    left:-moz-calc(50% - 125px);
    left:calc(50% - 125px);
  }
`

export default function EventsComp(props) {

  useEffect(() => {
    ;(async () => {
      const script = document.createElement('script')
      script.src = '/static/expandEvent.js'
      script.async = true
      script.type = 'text/javascript'
      document.body.appendChild(script)
    })()
  }, [])

  const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()

  return (
    <>
      <Container
        className='w-full h-full bg-blue flex mt-28'
        cursorUrl='/static/svg/cursor-with-shadow.svg'
        id='mainContainer'
      >
        <StickerNavs className={cn('w-96 relative  h-screen transition-opacity duration-700 ease-in-out hidden lg:block')} />
        <CenterContainer className=' w-full mx-auto ml-0 lg:ml-10'>
          <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs} />
          <div
            className={cn( 'transition',{
              'flex visible opacity-100': !isOpenMenuNavs,
              'hidden invisible opacity-0': isOpenMenuNavs,
            })}
          >
          <div className='flex'>
            <div className='w-full' style={{ height: '200vh' }}>
              <div className='text-center'>
                <TextCenter className='text-secondary'>EVENTS</TextCenter>
              </div>
              <div id='wrapper'></div>
              <div
                style={{
                  color: 'white',
                  fontFamily: 'reckless_light',
                  fontSize: '40px',
                  margin: 'auto',
                  marginBottom: '10vh',
                  textAlign: 'center',
                  lineHeight: '1.4',
                }}
                className={cn('w-11/12  lg:w-5/12 ')}
              >
                Join us May 3–7, as we commemorate an unforgettable year through five days of
                virtual design-focused programming!
              </div>
              <EventNumber className='EventNum'>1</EventNumber>
              <div style={{ backgroundColor: '#333333', paddingBottom: '5%' }}>
                <div
                  style={{
                    backgroundColor: '#5933FF',
                    display: 'inline-block',
                    height: '150px',
                    width: '300px',
                    borderBottomLeftRadius: '300px',
                    borderBottomRightRadius: '300px',
                    position: 'relative',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    fontFamily: 'sharp_grotesque',
                    fontSize: '100px',
                    fontWeight: 900,
                    color: '#fff',
                    lineHeight: '0.7',
                  }}
                >
                  DAY ONE
                </div>
                <div
                  style={{
                    fontSize: '42px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: 'white',
                    lineHeight: '1.4',
                  }}
                  className={cn('w-11/12  lg:w-5/12 ')}
                >
                  Monday, May 3, 2021
                  <br />
                  Yes! We’re future-ready
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: '#defc1c',
                    marginTop: '20px',
                  }}
                  className={cn('w-9/12  lg:w-2/12 ')}
                >
                  • Portfolio Reviews
                </div>
                <div
                  style={{
                    fontSize: '24px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: 'white',
                    marginTop: '20px',
                    lineHeight: '1.4',
                  }}
                  className={cn('w-11/12  lg:w-4/12 ')}
                >
                  Time to show your process and talk about who you are as a designer. The School of
                  Design will offer graduating students an opportunity to practise presenting their
                  work to a design professional working in the industry through portfolio reviews.{' '}
                </div>
                <div>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <div style={{ margin: '0 auto' }}>
                    <EventDiv className={cn('w-full text-center lg:w-4/12 ')}>
                    <HeadContainer>
                      <img
                            src='/static/speakers/YES_1_CMYK-Black.png'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                      <div className='title'>
                        YES! 2020-21 Kickoff
                        <br />
                        <br />
                        <br />
                      </div>
                      <div style={{ width: '100%', textAlign: 'left' }}>
                        <div className='time'>10-10:30AM EDT •</div>
                        <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-2020-21-kickoff-registration-149607013605'
                          target='_blank'
                          rel='noreferrer'
                        >
                          RSVP
                        </a>
                      </div>
                      <div className='desc'>
                        Join Dr. Ana Rita Morais, Chair for the School of Design, as she overviews some key milestones from the 2020-21 academic year, and provides details on the programme of activity for YES! including portfolio reviews, thesis pitches, our incredible speaker series, and our annual award show!
                      </div>
                      {/* <button className='expandEvent'>
                        <img
                          src='/static/downarrow.png'
                          style={{ width: '30px', margin: 'auto' }}
                          alt='down-arrow'
                        />
                      </button> */}
                    </EventDiv>
                       <EventDiv className={cn('w-full lg:w-4/12')}>
                      <HeadContainer>
                      <img
                            src='/static/speakers/03_AB.jpg'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>

                        <div className='title'>
                          Anthony Burrill
                          <br />
                          “A Review of Work Made Under Lockdown”
                        </div>
                        <div style={{ width: '100%', textAlign: 'left' }}>
                          <div className='time'>11AM–12PM EDT •</div>
                          <a
                            className='ml-2 text-secondary hover:text-blue transition'
                            href='https://www.eventbrite.com/e/yes-speaker-series-anthony-burrill-tickets-149607368667'
                            target='_blank'
                            rel='noreferrer'
                          >
                            RSVP
                          </a>
                        </div>
                        <div className='desc'>
                          Examining how the changing cultural landscape of 2020 disrupted and
                          informed Anthony Burrill’s practise. Using selected projects to illustrate
                          the process of creative re-invention in order to make work that is
                          relevant and has something to say.
                          <br />
                          <br />
                          <b>Bio</b>
                          <br />
                          Graphic artist Anthony Burrill combines a knack for simplicity that packs
                          a punch with analogue craft skills and powerful, positive messages.
                          Burrill frequently collaborates with other forward-thinking creatives
                          across disciplines spanning music, architecture, curation, education and
                          more; pushing his traditional discipline of choice, letterpress printing,
                          into bold new territories. Words, gentle humour, no-nonsense communication
                          and people are at the heart of Burrill’s practice and his distinctive
                          brand of upbeat messaging: its core DNA is one created through a
                          longstanding passion for creativity without limitations, the power of
                          simplicity and an innate curiosity about the world and people in it.
                          Burrill was born in Littleborough, Lancashire. After studying Graphic
                          Design at Leeds Polytechnic he completed an MA in Graphic Design at the
                          Royal College of Art, London. He now lives and works on the Isle of Oxney,
                          Kent. <br />
                          <br />
                          <b>Links</b>
                          <br />
                          <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-2020-21-kickoff-registration-149607013605'
                          target='_blank'
                          rel='noreferrer'
                        >
                          https://anthonyburrill.com/
                        </a>

                          <br />
                          <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-2020-21-kickoff-registration-149607013605'
                          target='_blank'
                          rel='noreferrer'
                        >
                           https://www.instagram.com/anthonyburrill/
                        </a>

                        </div>
                        <button className='expandEvent'>
                          <img
                            src='/static/downarrow.png'
                            style={{ width: '30px', margin: 'auto' }}
                            alt='down-arrow'
                          />
                        </button>
                      </EventDiv>
                    </div>
                    <div style={{ margin: '0 auto' }}>
                       <EventDiv className={cn('w-full lg:w-4/12')}>
                      <HeadContainer>
                      <img
                            src='/static/speakers/sarah.png'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                        <div className='title'>
                          Sarah Di Domenico
                          <br /> “Character Wins”
                        </div>
                        <div style={{ width: '100%', textAlign: 'left' }}>
                          <div className='time'>1–2PM EDT •</div>
                          <a
                            className='ml-2 text-secondary hover:text-blue transition'
                            href='https://www.eventbrite.ca/e/yes-speaker-series-sarah-di-domenico-tickets-149607824029'
                            rel='noreferrer'
                            target='_blank'
                          >
                            RSVP
                          </a>
                        </div>
                        <div className='desc'>Character is everything in a world of stuff, stuff, stuff. Character cuts through noise, inspires beyond trend, is true to its values, makes us feel. That "signature" we remember in design, in brand: Homer Simpson's "doh", Coca-cola red, Glossier's positivity, NIKE's empowerment. So how do we unearth "character" in brand, in design? By digging for what's true. Authenticity out there, and, in you.
                        <br />
                        <br />
                        <b>Bio</b>
                        <br />
                          Sarah is a Cannes awarded brand storyteller & creative, who after 10+ years within global agencies, joined Wedge as Co-Founder to build a Brand Design firm for a new era.

                          <br />
                          Wedge is an independent design-driven brand firm where Sarah leads the strategic creative vision of the business as well as a variety of projects, to help brands land their distinct signature & what they stand for.
                          <br />
                          Across her career, Sarah has contributed to award- winning work for Volkswagen Global, The North Face, IKEA, Samsung, HBO and others. Her creative purpose is inspired by elevating brands and products towards their authentic truth and higher potential, with a progressive point of view.
                          </div>
                          <button className='expandEvent'>
                          <img
                            src='/static/downarrow.png'
                            style={{ width: '30px', margin: 'auto' }}
                            alt='down-arrow'
                          />
                        </button>
                      </EventDiv>
                       <EventDiv className={cn('w-full lg:w-4/12')}>
                      <HeadContainer>
                      <img
                            src='/static/speakers/GilbertLi.jpeg'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                        <div className='title'>
                          Gilbert Li
                          <br />
                          “Nowhere to Hide”
                        </div>
                        <div style={{ width: '100%', textAlign: 'left' }}>
                          <div className='time'>3–4PM EDT •</div>
                          <a
                            className='ml-2 text-secondary hover:text-blue transition'
                            href='https://www.eventbrite.ca/e/yes-speaker-series-gilbert-li-tickets-149607984509'
                            target='_blank'
                            rel='noreferrer'
                          >
                            RSVP
                          </a>
                        </div>
                        <div className='desc'>
                          A simple, well-executed design can be more effective and engaging than an
                          elaborate one. Learn how seemingly modest design concepts can communicate
                          complex ideas and emotions clearly and powerfully.
                          <br />
                          <br />
                          <b>Bio</b>
                          <br />
                          After a decade learning from very smart and talented people, Gilbert Li
                          graduated from the School of Hard Knocks and founded The Office of Gilbert
                          Li in 2004. From pocket-sized brochures to coffee-table books, the
                          Toronto-based design studio is renowned for its work with leading
                          cultural, non-profit and institutional clients such as the Art Gallery of
                          Ontario, Metcalf Foundation and University of Toronto. In addition to
                          leading his design team, Gilbert teaches part-time and always thinks about
                          what’s for lunch.
                          <br />
                          <b>Links</b>
                          <br />
                          <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-2020-21-kickoff-registration-149607013605'
                          target='_blank'
                          rel='noreferrer'
                        >
                           https://www.gilbertli.com/
                        </a>

                        </div>
                        <button className='expandEvent'>
                          <img
                            src='/static/downarrow.png'
                            style={{ width: '30px', margin: 'auto' }}
                            alt='down-arrow'
                          />
                        </button>
                      </EventDiv>
                    </div>
                  </div>
                </div>
              </div>
              <EventNumber className='EventNum'>2</EventNumber>
              <div style={{ backgroundColor: '#333333', paddingBottom: '5%', marginTop: '10%' }}>
                <div
                  style={{
                    backgroundColor: '#5933FF',
                    display: 'inline-block',
                    height: '150px',
                    width: '300px',
                    borderBottomLeftRadius: '300px',
                    borderBottomRightRadius: '300px',
                    position: 'relative',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    fontFamily: 'sharp_grotesque',
                    fontSize: '100px',
                    fontWeight: 900,
                    color: '#fff',
                    lineHeight: '0.7',
                    letterSpacing: '2px',
                  }}
                >
                  DAY TWO
                </div>
                <div
                  style={{
                    fontSize: '42px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: 'white',
                    lineHeight: '1.4',
                  }}
                  className={cn('w-11/12  lg:w-5/12 ')}
                >
                  Tuesday, May 4, 2021
                  <br />
                  Yes! We’re virtual, yet dynamic.
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: '#defc1c',
                    marginTop: '20px',
                  }}
                  className={cn('w-9/12  lg:w-2/12 ')}
                >
                  • Panel Discussion
                </div>
                <div
                  style={{
                    fontSize: '24px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: 'white',
                    marginTop: '20px',
                    lineHeight: '1.4',
                  }}
                  className={cn('w-11/12  lg:w-4/12 ')}
                >
                  The last year has been...something. We’re here to show that although we are
                  virtual, we are still a resilient group of designers. Join us for a conversation
                  between faculty, alumni, and students on the hardships and benefits of working
                  remotely over the last year.
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <div style={{ margin: '0 auto' }}>
                     <EventDiv className={cn('w-full lg:w-4/12')}>
                    <HeadContainer>
                      <img
                            src='/static/speakers/YES_1_CMYK-Black.png'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                      <div className='title'>
                        Design in a Pandemic
                        <br />A School of Design Panel Discussion
                      </div>
                      <div style={{ width: '100%', textAlign: 'left' }}>
                        <div className='time'>10AM–12PM EDT •</div>
                        <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-design-in-a-pandemic-a-school-of-design-panel-discussion-tickets-149609627423'
                          target='_blank'
                          rel='noreferrer'
                        >
                          RSVP
                        </a>
                      </div>
                      <div className='desc'>
                        Design teaching and learning is incredibly dynamic and interactive. Across
                        the world, design schools have transitioned to alternative methods of
                        delivering applied techniques and studio approaches to students. Join us for
                        a panel discussion on designing, teaching, and working in a pandemic.
                        Faculty members, alumni, and students from the School of Design will share
                        the experiences that have characterized the last year of their life,
                        including challenges, barriers and opportunities. Audience members will be
                        able to ask questions and share their own perspectives on the transition to
                        remote education and work.
                        <br />
                        <br />
                        <b>Panelists</b>
                        <br />
                        Ana Rita Morais (Chair, School of Design), Xavier Masse (Program Coordinator
                        & Faculty, Interaction Design, Interactive Media Management), Nicole Dimson
                        (Program Coordinator & Faculty, Graphic Design), Kristine Do (Faculty,
                        Graphic Design), Michelle Desgroseilliers (Faculty, Interaction Design,
                        Interactive Media Management, Digital Experience Design), Lee Jong (Alumni,
                        Graphic Design), Gwen Chiasson (Alumni, Interaction Design), Rami Shouk
                        (Alumni, Graphic Design), Yasaman Fakhr (Student, Graphic Design), Saya Kim
                        (Student, Interdisciplinary Design Strategy), Evan Rotondo (Student,
                        Interaction Design)
                      </div>
                      <button className='expandEvent'>
                        <img
                          src='/static/downarrow.png'
                          style={{ width: '30px', margin: 'auto' }}
                          alt='down-arrow'
                        />
                      </button>
                    </EventDiv>
                     <EventDiv className={cn('w-full lg:w-4/12')}>
                    <HeadContainer>
                      <img
                            src='/static/speakers/Moreen.jpeg'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                      <div className='title'>
                        Mo Bofill
                        <br />
                        “Design in a Global Pandemic”
                      </div>
                      <div style={{ width: '100%', textAlign: 'left' }}>
                        <div className='time'>1–2PM EDT •</div>
                        <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-speaker-series-mo-bofill-tickets-149610084791'
                          target='_blank'
                          rel='noreferrer'
                        >
                          RSVP
                        </a>
                      </div>
                      <div className='desc'>
                        The pandemic has impacted nearly every sector, including Design. Join Mooren
                        (Mo) Bofill, Partner at 123w as she talks about what she’s learned as a
                        Designer and Creative Leader during the pandemic. How must we as Designers
                        need to adapt and work differently, why Design is more important now more
                        than ever, and why redefining empathy is needed in the world of accelerated
                        collaboration.
                        <br />
                        <br />
                        <b>Bio</b>
                        <br />
                        Mo is the newest Partner and Creative Director, Design at 123w – one of
                        Canada’s leading agencies. An independent idea and design collective based
                        out of Vancouver and Toronto. In her new role, she will help expand their
                        offering across Canada and continue to help build agency thought-leadership
                        through the lens of Design. She believes that Design isn’t a department,
                        it’s a way of thinking. It’s how we approach solving our client’s problems
                        and helping them grow. Design is the tangible expression of what a brand is,
                        and it plays a significant role in transforming brands into something people
                        want to be a part of.
                        <br />
                        <br />
                        Mo is formerly the Executive Director of Design at johnst., where she spent
                        the last five years building the Design offering from the ground up. At john
                        st., Mo built a growing design business with an award-winning portfolio, and
                        led a diverse team of multidisciplinary design thinkers. Born and raised in
                        the Philippines, and growing up in Toronto, she brings a diverse point of
                        view that mixes her passion for design, creativity and business strategy to
                        Canadian design.
                        <br />
                        <br />
                        With over a decade of experience, Mo has helped transform some of Canada’s
                        most iconic brands from the ground up: Bell, President’s Choice, Loblaws,
                        Home Hardware, Sleep Country, Winners, Shoppers, and Koodo to name a few.
                        She has also helped transformed global brands like KFC, Coca-Cola, Corona,
                        Audi, Subaru, Mitsubishi and helped them become relevant brands to
                        Canadians.
                        <br />
                        <br />
                        She is a relentless optimist; be kind, do good work, have fun, travel often,
                        and eat more fries.
                        <br />
                        <br />
                        Outside of driving business growth for her clients, her work has  also been
                        awarded and recognized internationally by D&AD, How Design Awards, Cannes
                        Lions, Luerzer’s Archive, Communication Arts, The Advertising & Design Club
                        of Canada, Applied Arts, Marketing Magazine, among others.
                        <br />
                        <br />
                        Mo also brings leadership in community engagement. She speaks and mentors
                        regularly through RGD (Registered Graphic Designers of Ontario) is an
                        instructor and mentor at Miami Ad School, helping create the newly founded
                        Design Program and is a leader on the issue of diversity in our industry.
                        Most recently she also joined the Board of Directors of the ADCCs
                        (Advertising & Design Club of Canada).
                        <br />
                        <b>Links</b>
                        <br />
                        <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-2020-21-kickoff-registration-149607013605'
                          target='_blank'
                          rel='noreferrer'
                        >
                          https://123w.ca/
                        </a>

                        <br />
                        <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-2020-21-kickoff-registration-149607013605'
                          target='_blank'
                          rel='noreferrer'
                        >
                           https://www.instagram.com/mobofill
                        </a>

                      </div>
                      <button className='expandEvent'>
                        <img
                          src='/static/downarrow.png'
                          style={{ width: '30px', margin: 'auto' }}
                          alt='down-arrow'
                        />
                      </button>
                    </EventDiv>
                  </div>
                  <div style={{ margin: '0 auto' }}>
                     <EventDiv className={cn('w-full lg:w-4/12')}>
                    <HeadContainer>
                      <img
                            src='/static/speakers/Tschudin.jpeg'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                      <div className='title'>
                        Stüssy Tschudin
                        <br />
                        “The Art of the Pitch”
                      </div>
                      <div style={{ width: '100%', textAlign: 'left' }}>
                        <div className='time'>2–3PM EDT •</div>
                        <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-speaker-series-stussy-tschudin-tickets-149610435841'
                          target='_blank'
                          rel='noreferrer'
                        >
                          RSVP
                        </a>
                      </div>
                      <div className='desc'>
                        Communicating your ideas is challenging, being persuasive and engaging can
                        be nerve-wracking, and knowing what the rhythm and pace for a presentation
                        is can be overwhelming. How do you master the art of the pitch?
                        <br />
                        <b>Bio</b>
                        <br />
                        <br />
                        Born and raised in Switzerland, Stüssy was a Swiss banker by day and
                        graffiti artist by night. His passion for the creative ultimately led him
                        back to school and toward a career as a graphic designer. He’s a conceptual
                        and visual thinker and brings creative and strategic insight to every
                        project he oversees at Forge. His broad range of expertise includes
                        branding, identity, web, marketing communications, signage, wayfinding,
                        strategy and conceptual development.
                        <br />
                        <br />
                        Championing Forge’s commitment to human-centred design, he’s led projects
                        for clients such as Apple Canada, the Gladstone Hotel, BMO, TIFF and Lindt
                        Canada.His unwavering commitment to design and communications excellence
                        led to Stüssy being elected to the Board of Directors of the Association of
                        Registered Graphic Designers (RGD). Stüssy has served as President of the
                        Association from 2014 to 2018.
                        <br />
                        <br />
                        <b>Links</b>
                        <br />
                        <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-2020-21-kickoff-registration-149607013605'
                          target='_blank'
                          rel='noreferrer'
                        >
                           https://www.forgemedia.ca/
                        </a>

                      </div>
                      <button className='expandEvent'>
                        <img
                          src='/static/downarrow.png'
                          style={{ width: '30px', margin: 'auto' }}
                          alt='down-arrow'
                        />
                      </button>
                    </EventDiv>
                     <EventDiv className={cn('w-full lg:w-4/12')}>
                    <HeadContainer>
                      <img
                            src='/static/speakers/Ellen.jpg'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                      <div className='title'>
                        Ellen Lupton
                        <br />
                        “Storytelling in Design”
                      </div>
                      <div style={{ width: '100%', textAlign: 'left' }}>
                        <div className='time'>3–4PM EDT •</div>
                        <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-speaker-series-ellen-lupton-tickets-149610253295'
                          target='_blank'
                          rel='noreferrer'
                        >
                          RSVP
                        </a>
                      </div>
                      <div className='desc'>
                        Design is storytelling— it is used to garner emotional reactions, build
                        empathy, convey values, and ultimately to construct a narrative. What are
                        the most integral aspects of the intersection between storytelling and
                        design? What tools and methods can designers use to tell succinct and
                        powerful stories? <br />
                        <br />
                        <b>Bio</b>
                        <br />
                        After a decade learning from very smart and talented people, Gilbert Li
                        graduated from the School of Hard Knocks and founded The Office of Gilbert
                        Li in 2004. From pocket-sized brochures to coffee-table books, the
                        Toronto-based design studio is renowned for its work with leading cultural,
                        non-profit and institutional clients such as the Art Gallery of Ontario,
                        Metcalf Foundation and University of Toronto. In addition to leading his
                        design team, Gilbert teaches part-time and always thinks about what’s for
                        lunch.
                        <br />
                        <b>Bio</b>
                        <br />
                        <br />
                        Ellen Lupton is a writer, curator, educator, and designer. Lupton is the
                        Betty Cooke and William O. Steinmetz Design Chair at MICA (Maryland
                        Institute College of Art) in Baltimore. She serves as a senior curator at
                        Cooper Hewitt, Smithsonian Design Museum in New York City.
                        <br />
                        <br />
                        Ellen Lupton is Senior Curator of Contemporary Design at Cooper Hewitt,
                        Smithsonian Design Museum in New York City. Her exhibitions include “Herbert
                        Bayer: Bauhaus Master,” “Face Values: Understanding Artificial
                        Intelligence,” “How Posters Work,” and “The Senses: Design Beyond Vision.”
                        Lupton is the Betty Cooke and William O. Steinmetz Design Chair at MICA in
                        Baltimore, where she has authored numerous books on design processes,
                        including “Thinking with Type,” “Graphic Design Thinking,” and “Graphic
                        Design: The New Basics.” Her recent books “Design Is Storytelling” and
                        “Health Design Thinking” were published by Cooper Hewitt. She is an AIGA
                        Gold Medalist and a Fellow of the American Academy of Arts & Sciences.
                        <br />
                        <br />
                        <b>Links</b>
                        <br />
                        <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-2020-21-kickoff-registration-149607013605'
                          target='_blank'
                          rel='noreferrer'
                        >
                           https://ellenlupton.com/
                        </a>

                      </div>
                      <button className='expandEvent'>
                        <img
                          src='/static/downarrow.png'
                          style={{ width: '30px', margin: 'auto' }}
                          alt='down-arrow'
                        />
                      </button>
                    </EventDiv>
                  </div>
                </div>
              </div>
              <EventNumber className='EventNum'>3</EventNumber>
              <div style={{ backgroundColor: '#333333', paddingBottom: '5%', marginTop: '10%' }}>
                <div
                  style={{
                    backgroundColor: '#5933FF',
                    display: 'inline-block',
                    height: '150px',
                    width: '300px',
                    borderBottomLeftRadius: '300px',
                    borderBottomRightRadius: '300px',
                    position: 'relative',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    fontFamily: 'sharp_grotesque',
                    fontSize: '100px',
                    fontWeight: 900,
                    color: '#fff',
                    lineHeight: '0.7',
                    letterSpacing: '2px',
                  }}
                >
                  DAY THREE
                </div>
                <div
                  style={{
                    fontSize: '42px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: 'white',
                    lineHeight: '1.4',
                  }}
                  className={cn('w-11/12  lg:w-5/12 ')}
                >
                  Wednesday, May 5, 2021
                  <br />
                  Yes! We’re changing the narrative.
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: '#defc1c',
                    marginTop: '20px',
                  }}
                  className={cn('w-9/12  lg:w-2/12 ')}
                >
                  • Panel Discussion
                </div>
                <div
                  style={{
                    fontSize: '24px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: 'white',
                    marginTop: '20px',
                    lineHeight: '1.4',
                  }}
                  className={cn('w-11/12  lg:w-4/12 ')}
                >
                  Design holds a tremendous power to impact change and elevate our voices. We'll
                  focus on the School of Design’s BIPOC students, and feature some incredible
                  speakers who are using design as a vehicle for change.
                </div>

                <div style={{ margin: '0 auto' }}>

                   <EventDiv className={cn('w-full lg:w-4/12')}>
                  <HeadContainer>
                      <img
                            src='/static/speakers/Sean.png'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                    <div className='title'>
                      Sean Brown
                      <br />
                      “On Open Conversation”
                    </div>
                    <div style={{ width: '100%', textAlign: 'left' }}>
                      <div className='time'>11AM–12PM EDT •</div>
                      <a
                        className='ml-2 text-secondary hover:text-blue transition'
                        href='https://www.eventbrite.ca/e/yes-speaker-series-sean-brown-tickets-149610750783'
                        target='_blank'
                        rel='noreferrer'
                      >
                        RSVP
                      </a>
                    </div>
                    <div className='desc'>
                    <br />
                      <br />
                      <b>Bio</b>
                      <br />
                    <a
                        className='ml-2 text-secondary hover:text-blue transition'
                        href='https://byseanbrown.com/'
                        target='_blank'
                        rel='noreferrer'
                      >Sean Brown</a>  a Toronto based creative director, currently working with Combs Enterprises and Sean ‘Diddy’ Combs, as well as Canadian singer-songwriter Daniel Caesar. He is also the owner and creative director of interior design company <a
                      className='ml-2 text-secondary hover:text-blue transition'
                      href='https://curvesbyseanbrown.com/'
                      target='_blank'
                      rel='noreferrer'
                    >Curves by Sean Brown</a>. His path has seen him grow through many creative outlets. From clothing designer, to photographer, to brand developer Sean’s biggest inspiration continues to be the ever-expanding world around him.
                           <br />
                      “It took me a while to get to the point of being totally aware of what existed around me. It’s interesting to me how the phrase “stay woke” has taken on this new meaning of political awareness and correctness. But to go in depth from a personal stand point, I’m woke when I’m fully absorbing my surroundings; when all of my senses are active and I see something special in the things people tend to walk by and ignore. I mean people, places and things. Even before technology evened the playing field creatively and socially, it was still perspective that separated people with imagination.


                      <br />
The more I started travelling the more I started to realize how inspiration and information are available to download (around you) at all times. Ultimately, through these experiences I’ve explored the process of creativity, challenging the notion of art and ideas that have inspired my outlook, approach, and practice.”

                      <br />
                      <b>Links</b>
                      <br />

                      <a
                        className='text-secondary hover:text-blue transition'
                        href='https://byseanbrown.com/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        https://byseanbrown.com/
                      </a>
                    </div>
                    <button className='expandEvent'>
                      <img
                        src='/static/downarrow.png'
                        style={{ width: '30px', margin: 'auto' }}
                        alt='down-arrow'
                      />
                    </button>
                  </EventDiv>
                   <EventDiv className={cn('w-full lg:w-4/12')}>
                  <HeadContainer>
                      <img
                            src='/static/speakers/BP.jpg'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                    <div className='title'>
                      Berkeley Poole
                      <br />
                      “On Self-Advocacy and Resilience”
                    </div>
                    <div style={{ width: '100%', textAlign: 'left' }}>
                      <div className='time'>12–1PM EDT •</div>
                      <a
                        className='ml-2 text-secondary hover:text-blue transition'
                        href='https://www.eventbrite.ca/e/yes-speaker-series-berkeley-poole-tickets-149610814975'
                        target='_blank'
                        rel='noreferrer'
                      >
                        RSVP
                      </a>
                    </div>
                    <div className='desc'>
                      How can we grow beyond the distortions and systems of oppression that keep
                      underrepresented people docile, smol, unfulfilled and externally defined?
                      We’ll share tools and strategies developed from firsthand experience to
                      transmute your fear, anger, otherness and vulnerability into empathy,
                      awareness and creative energy. Learn how to cultivate resilience and advocate
                      for yourself.
                      <br /><br />
                      <b>Bio</b>
                      <br />
                      Berkeley has over ten years of creative direction and design experience, with a focus on large scale brand development. Currently she is the Creative Director at Whitman Emorson, a Toronto-based strategy-driven design studio. Recently, she built a portfolio of iconic cannabis brands at Tokyo Smoke as VP & Creative Director. She has also held roles at Barneys New York, Laird & Partners, V Magazine and Visionaire, working on brands ranging from Karl Lagerfeld to Calvin Klein, Ace Hill to SSENSE.
                      <br />

                      <br />
                      <br />
                      <b>Links</b>
                      <br />
                      <a
                        className='ml-2 text-secondary hover:text-blue transition'
                        href='http://berkeleypoole.com/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        http://berkeleypoole.com/
                      </a>
                    </div>
                    <button className='expandEvent'>
                      <img
                        src='/static/downarrow.png'
                        style={{ width: '30px', margin: 'auto' }}
                        alt='down-arrow'
                      />
                    </button>
                  </EventDiv>
                   <EventDiv className={cn('w-full lg:w-4/12')}>
                  <HeadContainer>
                      <img
                            src='/static/speakers/Fidel_Pena.jpeg'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                    <div className='title'>
                      Fidel Peña
                      <br />
                      “Type or Stereotype?”
                    </div>
                    <div style={{ width: '100%', textAlign: 'left' }}>
                      <div className='time'>1–2PM EDT •</div>
                      <a
                        className='ml-2 text-secondary hover:text-blue transition'
                        href='https://www.eventbrite.ca/e/yes-speaker-series-fidel-pena-tickets-149611252283'
                        rel='noreferrer'
                        target='_blank'
                      >
                        RSVP
                      </a>
                    </div>
                    <div className='desc'>
                      Are we using our voices as designers to amplify marginalized voices or to
                      perpetuate stereotypes? Why does this matter?
                      <br />
                      <br />
                      <b>Bio</b>
                      <br />
                      Fidel is a Creative Director and Co-founder of Underline Studio. His projects
                      at Underline have included identity and branding for the University of
                      Toronto, the Robert McLaughlin Gallery, Harvard Medical School, as well as
                      campaigns and publications for Google, UNHCR The UN Refugee Agency, and Audi.
                      Fidel’s work has received numerous accolades from across Canada, Latin
                      America, the US and Europe, including from Communication Arts, the Type
                      Directors Club, Laus Awards, Latin American Design Awards, the Advertising and
                      Design Club of Canada and D&AD. Fidel has also lectured on design topics in
                      Spain, Mexico, and Canada. He was born in El Salvador, and is a former
                      president of The Advertising and Design Club of Canada.
                      <br />
                      <b>Links</b>
                      <br />
                      <a
                        className='text-secondary hover:text-blue transition'
                        href='https://underlinestudio.com/'
                        rel='noreferrer'
                        target='_blank'
                      >
                        https://underlinestudio.com/
                      </a>
                      <br />
                      <a
                        className='text-secondary hover:text-blue transition'
                        href='https://www.instagram.com/fidelunderline/'
                        rel='noreferrer'
                        target='_blank'
                      >
                        https://www.instagram.com/fidelunderline/
                      </a>
                    </div>
                    <button className='expandEvent'>
                      <img
                        src='/static/downarrow.png'
                        style={{ width: '30px', margin: 'auto' }}
                        alt='down-arrow'
                      />
                    </button>
                  </EventDiv>
                </div>
                <div style={{ margin: '0 auto' }}>

                   <EventDiv className={cn('w-full lg:w-4/12')}>
                  <HeadContainer>
                      <img
                            src='/static/speakers/Nu_Goteh.jpg'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                    <div className='title'>
                      Nu Goteh
                      <br />
                      “Designing Design”
                    </div>
                    <div style={{ width: '100%', textAlign: 'left' }}>
                      <div className='time'>2–3PM EDT •</div>
                      <a
                        className='ml-2 text-secondary hover:text-blue transition'
                        href='https://www.eventbrite.ca/e/yes-speaker-series-nu-goteh-tickets-149611374649'
                        target='_blank'
                        rel='noreferrer'
                      >
                        RSVP
                      </a>
                    </div>
                    <div className='desc'>
                      As the world continues to shift, how can design education help to
                      contextualize and reimagine the designer's role in shaping a new world.
                      <br />
                      <br />
                      <b>Bio</b>
                      <br />
                      Nu Goteh is a multi-disciplinary creative & designer who works in audio,
                      visual and written mediums. He is the co-founder of the strategy and design
                      studio, Room for Magic, and co-founder and creative director of partner
                      publication, Deem Journal. Additionally Nu is a professor at Parsons School of
                      Design leading a course entitled Design Dichotomies which explores the
                      appropriate scale of humanitarian initiatives with a specific focus on values
                      and the market.
                      <br />
                      <br />
                      Nu’s practice is informed by his love for counter/subculture(s), his
                      background as a Liberian-born refugee, and a lifelong dedication to building
                      platforms that enable communities to engage in shared experiences. Holding a
                      Masters in Strategic Design & Management from Parsons School of Design, Nu is
                      focused on the capabilities of design-thinking and on investing in ideas that
                      solve bigger problems as a means of elevating the well-being of others. Based
                      in Los Angeles, he has helped brands such as Puma, RedBull, Sonos, Adidas, and
                      Sesame Street, improve upon the way people experience culture, community, and
                      social capital. With an inherent understanding of the elements needed to build
                      community platforms, Nu brings over a decade of branding, research, strategic
                      development, ideation and production to create equitable change to the way
                      communities are being represented.
                      <br />
                      <br />
                      <b>Links</b>
                      <br />
                      <a
                        className='text-secondary hover:text-blue transition'
                        href='https://www.deemjournal.com'
                        target='_blank'
                        rel='noreferrer'
                      >
                        www.deemjournal.com
                      </a>

                      <br />
                      <a
                        className='text-secondary hover:text-blue transition'
                        href='https://www.roomformagic.com'
                        target='_blank'
                        rel='noreferrer'
                      >
                        www.roomformagic.com
                      </a>
                    </div>
                    <button className='expandEvent'>
                      <img
                        src='/static/downarrow.png'
                        style={{ width: '30px', margin: 'auto' }}
                        alt='down-arrow'
                      />
                    </button>
                  </EventDiv>
                   <EventDiv className={cn('w-full lg:w-4/12')}>
                  <HeadContainer>
                      <img
                            src='/static/speakers/Cove.jpg'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                    <div className='title'>
                      Cove Collective
                      <br />
                      “Building Community through Design”
                    </div>
                    <div style={{ width: '100%', textAlign: 'left' }}>
                      <div className='time'>3–4PM EDT •</div>
                      <a
                        className='ml-2 text-secondary hover:text-blue transition'
                        href='https://www.eventbrite.ca/e/yes-speaker-series-cove-collective-tickets-149611326505'
                        target='_blank'
                        rel='noreferrer'
                      >
                        RSVP
                      </a>
                    </div>
                    <div className='desc'>
                      In a time where we’re feeling isolated, how can we use our design skills to
                      foster community? What does building community through design look like? Think
                      outside the box and discover the ways in which your design career can expand
                      outside the traditional scope and create impact within other creative
                      industries.
                      <br />
                      <br />
                      <b>Bio</b>
                      <br />
                      Ashley is a multidisciplinary graphic designer based in Toronto, Canada. She
                      received an Advanced Diploma in Graphic Design from Humber College in 2019.
                      Recently, she was the junior designer at Studio Wyse where she worked on
                      editorial projects for the University of Toronto, Ryerson University, and the
                      Creative Destruction Lab. Leanna is a George Brown alumni from the Event
                      Management program. Currently working as an Event Planner in the nonprofit
                      sector for a 2SLGBT+ organization with a background in retail events such as
                      product launches, sponsored and Influencer events, vendor markets, and trade
                      shows. Frustrated by the lack of intersectional representation in their
                      industries Ashley and Leanna co-founded Cove Collective, a community that
                      centres the voices of BIPOC women and Femmes through a book club, events, and
                      podcast.
                      <br />
                      <br />
                      <b>Links</b>
                      <br />
                      <a
                        className='text-secondary hover:text-blue transition'
                        href='https://www.covecollective.ca'
                        target='_blank'
                        rel='noreferrer'
                      >
                        www.covecollective.ca
                      </a>
                    </div>
                    <button className='expandEvent'>
                      <img
                        src='/static/downarrow.png'
                        style={{ width: '30px', margin: 'auto' }}
                        alt='down-arrow'
                      />
                    </button>
                  </EventDiv>
                   <EventDiv className={cn('w-full lg:w-4/12')}>
                    <HeadContainer>
                      <img
                            src='/static/speakers/Ian.jpg'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                      <div className='title'>
                        Ian Rolston
                        <br />
                        “Surviving Critiques”
                      </div>
                      <div style={{ width: '100%', textAlign: 'left' }}>
                        <div className='time'>4–5PM EDT •</div>
                        <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-speaker-series-ian-rolston-tickets-149611563213'
                          target='_blank'
                          rel='noreferrer'
                        >
                          RSVP
                        </a>
                      </div>
                      <div className='desc'>
                        <br />
                        No question crits are hard! But there is another way to use each to your
                        advantage. Critiques offer opportunities to engage your audience powerfully.
                        Join me. Let’s talk and change the way we think about the best tool
                        designers have to impact the way we view the world around us.
                        <br />
                        <br />
                        <b>Bio</b>
                        <br />
                        Ian Rolston is a creative soul, inspired by connecting humanity to what
                        matters most. After working for the world’s top brands and companies, he
                        founded Decanthropy, a methodology and inclusive design consultancy that
                        helps reimagine spaces for projects, places and people. As a thought leader,
                        registered design professional and speaker, Rolston encourages relying on
                        one’s sense of humanity to inform design solutions. His insights, studio
                        workshops and project collaborations focus clients on establishing better.
                        Better work, better play, better moments of connection and better
                        expressions of care.
                        <br />
                        <br />
                        <b>Links</b>
                        <br />
                        <a
                          className='text-secondary hover:text-blue transition'
                          href='https://decanthropy.com/'
                          target='_blank'
                          rel='noreferrer'
                        >
                          https://decanthropy.com/
                        </a>
                      </div>
                      <button className='expandEvent'>
                        <img
                          src='/static/downarrow.png'
                          style={{ width: '30px', margin: 'auto' }}
                          alt='down-arrow'
                        />
                      </button>
                    </EventDiv>
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <div style={{ margin: '0 auto' }}>
                   <EventDiv className={cn('w-full lg:w-4/12')}>
                  <HeadContainer>
                      <img
                            src='/static/speakers/YES_1_CMYK-Black.png'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                    <div className='title'>Elevating BIPOC Voices in Design Education: <br/>School of Design Panel Discussion</div>
                    <div style={{ width: '100%', textAlign: 'left' }}>
                      <div className='time'>5–6:30PM EDT •</div>
                      <a
                        className='ml-2 text-secondary hover:text-blue transition'
                        href='https://www.eventbrite.ca/e/elevating-bipoc-voices-in-design-education-panel-discussion-tickets-150917202415'
                        target='_blank'
                        rel='noreferrer'
                      >
                        RSVP
                      </a>
                    </div>
                    <div className='desc'>BIPOC (Black, Indigenous, and People of Colour) designers have long been underrepresented in the world of design. This underrepresentation often begins within the education system, which often fails to provide varying points of entry and access. How might elevate design and its value as a viable career option? How might we empower educators with the language and knowledge to better reduce systemic barriers within the design classroom? What support is required by students to feel more secure about their careers as young designers, given the existing design landscape? Join us for a conversation on how we might transform the world of design education into a more equitable and inclusive space.</div>
                    <button className='expandEvent'>
                      <img
                        src='/static/downarrow.png'
                        style={{ width: '30px', margin: 'auto' }}
                        alt='down-arrow'
                      />
                    </button>
                  </EventDiv>

                  </div>
                </div>
              </div>

              <EventNumber className='EventNum'>4</EventNumber>

              <div style={{ backgroundColor: '#333333', paddingBottom: '5%', marginTop: '10%' }}>
                <div
                  style={{
                    backgroundColor: '#5933FF',
                    display: 'inline-block',
                    height: '150px',
                    width: '300px',
                    borderBottomLeftRadius: '300px',
                    borderBottomRightRadius: '300px',
                    position: 'relative',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    fontFamily: 'sharp_grotesque',
                    fontSize: '100px',
                    fontWeight: 900,
                    color: '#fff',
                    lineHeight: '0.7',
                    letterSpacing: '2px',
                  }}
                >
                  DAY FOUR
                </div>
                <div
                  style={{
                    fontSize: '42px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: 'white',
                    lineHeight: '1.4',
                  }}
                  className={cn('w-11/12  lg:w-5/12 ')}
                >
                  Thursday, May 6, 2021
                  <br />
                  Yes! We can’t wait to show you what we’ve been up to.
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: '#defc1c',
                    marginTop: '20px',
                  }}
                  className={cn('w-9/12  lg:w-2/12 ')}
                >
                  • Thesis Pitches
                </div>
                <div
                  style={{
                    fontSize: '24px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: 'white',
                    marginTop: '20px',
                    lineHeight: '1.4',
                  }}
                  className={cn('w-11/12  lg:w-4/12 ')}
                >
                  Demonstrating a mastery of the art of the pitch, graduating students across a series of our programs will present their final thesis and capstone projects to an esteemed panel of faculty and industry professionals.
                </div>

                <div style={{ margin: '0 auto' }}>
                   <EventDiv className={cn('w-full lg:w-4/12')}>
                  <HeadContainer>
                      <img
                            src='/static/speakers/scaries.png'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>

                    <div className='title'>
                      The Scaries
                      <br />
                      “But what if they hate it?”
                    </div>
                    <div style={{ width: '100%', textAlign: 'left' }}>
                      <div className='time'>11AM–12PM EDT •</div>
                      <a
                        className='ml-2 text-secondary hover:text-blue transition'
                        href='https://www.eventbrite.ca/e/yes-speaker-series-the-scaries-tickets-149611705639'
                        target='_blank'
                        rel='noreferrer'
                      >
                        RSVP
                      </a>
                    </div>
                    <div className='desc'>
                      The Scaries is a collaboration between illustrator LeeAndra Cianci and graphic
                      designer Nicola Hamilton. As commercial artists, our mandate is to translate
                      our client’s visions into the tangible, to amplify their voices, and to
                      communicate their messaging. Acting as translators, over time, can render us
                      out of sorts with our own unique styles, processes and instincts. Combine that
                      with the hyper-connected age we’re living in—where we’re constantly comparing
                      ourselves to our peers—and that’s a recipe for some serious self-doubt. Join
                      us on an exploration of the emotional realities of making work and the turmoil
                      the process inflicts upon us.
                      <br />
                      <br />
                      <b>Bio</b>
                      <br />
                      Nicola Hamilton is an independent art director and designer, whose work has
                      been internationally recognized by the D&AD, the Society of Publication
                      Designers, and the National Magazine Awards, among others. She was an art
                      director at Studio Wyse, deputy art director at Chatelaine and associate art
                      director at The Grid. She teaches editorial design at Humber College and
                      George Brown College. She's a Certified RGD and the current President of the
                      Association of Registered Graphic Designers.
                      <br />
                      <br />
                      LeeAndra Cianci is an editorial and commercial illustrator, whose client list
                      includes The New York Times, Nike, The Globe and Mail and Toronto Life. Her
                      work has been internationally recognized by the Society of Publication
                      Designers, the Society for News Design, and the Canadian National Magazine
                      Awards. She holds a Masters of Design from NSCAD University and a Bachelor of
                      Fine Arts from Mount Allison University. She's also the artist behind
                      @drawing_vicariously and the human behind @lennytheween.
                      <br />
                      <br />
                      <b>Links</b>
                      <br />
                      <a
                        className='text-secondary hover:text-blue transition'
                        href='https://www.thescariesproject.com/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        https://www.thescariesproject.com/
                      </a>
                      <br />
                      <a
                        className='text-secondary hover:text-blue transition'
                        href='https://nicolahamilton.ca/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        https://nicolahamilton.ca/
                      </a>
                      <br />
                      <a
                        className='text-secondary hover:text-blue transition'
                        href='http://www.leeandracianci.com/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        http://www.leeandracianci.com/
                      </a>
                    </div>
                    <button className='expandEvent'>
                      <img
                        src='/static/downarrow.png'
                        style={{ width: '30px', margin: 'auto' }}
                        alt='down-arrow'
                      />
                    </button>
                  </EventDiv>
                   <EventDiv className={cn('w-full lg:w-4/12')}>
                  <HeadContainer>
                      <img
                            src='/static/speakers/square.gif'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>

                    <div className='title'>
                      Jay Wall & Kaila Jacques
                      <br />
                      “Design for Social Change”
                    </div>
                    <div style={{ width: '100%', textAlign: 'left' }}>
                      <div className='time'>1–2PM EDT •</div>
                      <a
                        className='ml-2 text-secondary hover:text-blue transition'
                        href='https://www.eventbrite.ca/e/yes-speaker-series-jay-wall-kaila-jacques-tickets-149611872137'
                        target='_blank'
                        rel='noreferrer'
                      >
                        RSVP
                      </a>
                    </div>
                    <div className='desc'>
                      Design can’t save the world, but it can still play a critical role in social
                      change. How can a small design studio help advance solutions to big challenges
                      like climate action, mental health, and housing affordability?
                      <br />
                      <br />
                      <b>Bio</b>
                      <br />
                      Jay Wall is principal and creative director of RallyRally, a Toronto-based
                      design studio dedicated to social change. For more than a decade, he has led
                      the creation of brand identities, publications, websites and campaigns with
                      purpose-driven organizations such as the Centre for Social Innovation,
                      McConnell Foundation, and the City of Toronto. Jay’s work has appeared in
                      Adbusters and his team has been recognized with three So(cial) Good Design
                      Awards. Jay was recently named the national winner of the RGD UltraBold
                      Awards, recognizing designers under the age of 35 who are making a positive
                      impact in the Canadian design community.
                      <br />
                      <br />
                      Kaila Jacques is creative director at RallyRally, a design studio dedicated to
                      social change. At RallyRally, Kaila has led major projects with a range of
                      organizations including UNICEF, United Way, and Robert Wood Johnson
                      Foundation. Prior to joining RallyRally, Kaila worked at Bruce Mau Design,
                      developing expertise in the cultural and education sectors. Before that, she
                      was a designer at Evergreen, a non-profit focused on urban sustainability. As
                      an advocate for a more inclusive society, Kaila has collaborated with her
                      brother Michael—who has autism and an intellectual disability—to publish two
                      books about inclusion and belonging.
                      <br />
                      <br />
                      <b>Links</b>
                      <br />
                      <a
                        className='text-secondary hover:text-blue transition'
                        href='https://rallyrally.design'
                        target='_blank'
                        rel='noreferrer'
                      >
                        https://rallyrally.design
                      </a>
                      <br />
                      <a
                        className='text-secondary hover:text-blue transition'
                        href='https://www.instagram.com/rallyrallyfor/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        https://www.instagram.com/rallyrallyfor/
                      </a>
                    </div>
                    <button className='expandEvent'>
                      <img
                        src='/static/downarrow.png'
                        style={{ width: '30px', margin: 'auto' }}
                        alt='down-arrow'
                      />
                    </button>
                  </EventDiv>
                   <EventDiv className={cn('w-full lg:w-4/12')}>
                  <HeadContainer>
                      <img
                            src='/static/speakers/ash.jpg'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                    <div className='title'>
                      Sydney Allen-Ash
                      <br />
                      “What We Talk About When We Talk About Money”
                    </div>
                    <div style={{ width: '100%', textAlign: 'left' }}>
                      <div className='time'>2–3PM EDT •</div>
                      <a
                        className='ml-2 text-secondary hover:text-blue transition'
                        href='https://www.eventbrite.ca/e/yes-speaker-series-sydney-allen-ash-tickets-149612295403'
                        target='_blank'
                        rel='noreferrer'
                      >
                        RSVP
                      </a>
                    </div>
                    <div className='desc'>
                      Talking about money can be uncomfortable, confusing, and for some, completely
                      debilitating—but it's not going away. I am not a financial advisor but I am
                      facing my fears about money and want you to do the same. Join me for a
                      philosophical, psychological and practical conversation about cash and the
                      creative industries.
                      <br />
                      <br />
                      <b>Bio</b>
                      <br />
                      Sydney Allen-Ash is a strategist and consultant with a background spanning
                      fashion, advertising, organizational design, and communications. She mixes
                      traditional and non-traditional approaches to create culture change and
                      transformation inside and outside of organizations and focuses her practice on
                      helping people work, learn and communicate radically better.
                      <br />
                      <br />
                      Prior to freelance life, Sydney lived in New York and worked as Senior Manager
                      of Global Internal Communications for VICE Media Group. In this role, she
                      focused on improving the employee experience by creating definitive tools,
                      systems and guidelines that shaped the company culture. She led projects
                      spanning Diversity, Equity and Inclusion, brand strategy, organizational
                      change and employee experience. Before this, she led innovation for the People
                      & Culture team and worked as a strategist in Virtue Worldwide. w<br />
                      <br />
                      Outside of VMG, Sydney is the creator and host of two podcasts, Toenails (a
                      podcast about running and also not about running) and Re:Search (a podcast
                      about ideas). She is also the host of the forthcoming Work/Place a futures
                      podcast created by foresight studio From Later. Sydney is often called upon as
                      a speaker and moderator for discussions focused on the relationship between
                      innovation and inclusion in the creative industries.
                      <br />
                      <br />
                      Sydney lives in Vancouver, likes corded headphones and is currently thinking
                      deeply (probably too deeply) about sports, her caffeine consumption, and our
                      societal fixation on maximizing our potential.
                      <br />
                      <br />
                      <b>Links</b>
                      <br />
                      <a
                        className='text-secondary hover:text-blue transition'
                        href='https://anchor.fm/toenails'
                        target='_blank'
                        rel='noreferrer'
                      >
                        https://anchor.fm/toenails
                      </a>
                    </div>
                    <button className='expandEvent'>
                      <img
                        src='/static/downarrow.png'
                        style={{ width: '30px', margin: 'auto' }}
                        alt='down-arro'
                      />
                    </button>
                  </EventDiv>
                </div>
              </div>

              <EventNumber className='EventNum'>5</EventNumber>
              <div
                style={{ backgroundColor: '#333333', paddingBottom: '5%', marginTop: '10%' }}
                id='lastDay'
              >
                <div
                  style={{
                    backgroundColor: '#5933FF',
                    display: 'inline-block',
                    height: '150px',
                    width: '300px',
                    borderBottomLeftRadius: '300px',
                    borderBottomRightRadius: '300px',
                    position: 'relative',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    fontFamily: 'sharp_grotesque',
                    fontSize: '100px',
                    fontWeight: 900,
                    color: '#fff',
                    lineHeight: '0.7',
                    letterSpacing: '2px',
                  }}
                >
                  DAY FIVE
                </div>
                <div
                  style={{
                    fontSize: '42px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: 'white',
                    lineHeight: '1.4',
                  }}
                  className={cn('w-11/12  lg:w-5/12 ')}
                >
                  Friday, May 7, 2021
                  <br />
                  Yes! We’re the School of Design.
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: '#defc1c',
                    marginTop: '20px',
                  }}
                  className={cn('w-9/12  lg:w-2/12 ')}
                >
                  • Award Show
                </div>
                <div
                  style={{
                    fontSize: '24px',
                    margin: 'auto',
                    textAlign: 'center',
                    color: 'white',
                    marginTop: '20px',
                    lineHeight: '1.4',
                  }}
                  className={cn('w-11/12  lg:w-4/12 ')}
                >
                  There's no party like a School of Design party. We're live streaming our annual Award Show, where we'll announce over 100 awards to students and faculty across our ten programs. Join us to celebrate the award recipients on their dedication and hard work!{' '}
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <div style={{ margin: '0 auto' }}>
                     <EventDiv className={cn('w-full lg:w-4/12')}>
                    <HeadContainer>
                      <img
                            src='/static/speakers/eckstein.jpg'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                      <div className='title'>
                        Vanessa Eckstein
                        <br />
                        “In Search of Our Inspirational Self”
                      </div>
                      <div style={{ width: '100%', textAlign: 'left' }}>
                        <div className='time'>1–2PM EDT •</div>
                        <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-speaker-series-vanessa-eckstein-tickets-149612385673'
                          target='_blank'
                          rel='noreferrer'
                        >
                          RSVP
                        </a>
                      </div>
                      <div className='desc'>
                        In Search of Our Inspirational Self
                        <br />
                        A conversation about the impetus of our creative aliveness and how it is
                        able to survive. The cyclical nature of experimentation.
                        <br />
                        <br />
                        <b>Bio</b>
                        <br />
                        Vanessa founded Blok in 1998 after a highly lauded career working at some of
                        the top design studios in North America. Her experiences living and working
                        in Buenos Aires, LA, New York, and Mexico City have given the studio a
                        unique international perspective and scope that has been recognized and
                        awarded around the world. Guided by the belief that design is at its best
                        when it serves society, she has collaborated with some of the world’s top
                        talents, from leading contemporary artists and photographers to architects
                        and authors to bring this vision to fruition across a broad range of
                        businesses and clients.
                        <br />
                        <br />
                        <b>Links</b>
                        <br />
                        <a
                          className='text-secondary hover:text-blue transition'
                          href='http://blokdesign.com/'
                          target='_blank'
                          rel='noreferrer'
                        >
                          http://blokdesign.com/
                        </a>
                      </div>
                      <button className='expandEvent'>
                        <img
                          src='/static/downarrow.png'
                          style={{ width: '30px', margin: 'auto' }}
                          alt='down-arrow'
                        />
                      </button>
                    </EventDiv>
                     <EventDiv className={cn('w-full lg:w-4/12')}>
                    <HeadContainer>
                      <img
                            src='/static/speakers/willey.jpeg'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                      <div className='title'>
                        Matt Willey
                        <br />
                        “Magazines, mostly… and type”
                      </div>
                      <div style={{ width: '100%', textAlign: 'left' }}>
                        <div className='time'>3–4PM EDT •</div>
                        <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.ca/e/yes-speaker-series-matt-willey-tickets-149612467919'
                          target='_blank'
                          rel='noreferrer'
                        >
                          RSVP
                        </a>
                      </div>
                      <div className='desc'>
                        From small independent titles to magazine launches to The New York Times
                        Magazine (via the pub). A somewhat accidental career in editorial design.
                        <br />
                        <br />
                        <b>Bio</b>
                        <br />
                        Matt Willey is a graphic designer, based in Brooklyn. After five years as
                        the art director of The New York Times Magazine Matt joined the New York
                        office of design firm Pentagram, as a partner, in 2020. In 2014 he was named
                        ‘Designer of the Year’ by Creative Review, and was elected as a member of
                        the Alliance Graphique Internationale (AGI) in 2015. His work for the BBC
                        drama Killing Eve was nominated for a BAFTA in 2019.
                        <br />
                        <br />
                        <b>Links</b>
                        <br />
                        <a
                          className='text-secondary hover:text-blue transition'
                          href='https://mattwilley.co.uk/'
                          target='_blank'
                          rel='noreferrer'
                        >
                          https://mattwilley.co.uk/
                        </a>
                      </div>
                      <button className='expandEvent'>
                        <img
                          src='/static/downarrow.png'
                          style={{ width: '30px', margin: 'auto' }}
                          alt='down-arrow'
                        />
                      </button>
                    </EventDiv>
                     <EventDiv className={cn('w-full lg:w-4/12')}>
                    <HeadContainer>
                      <img
                            src='/static/speakers/YES_1_CMYK-Black.png'
                            alt='glass'
                            className={cn('cursor-pointer')}
                          />
                        <div></div>
                    </HeadContainer>
                      <div className='title'>
                        Yes! 2020-21 Award Show
                        <br />
                      </div>
                      <div style={{ width: '100%', textAlign: 'left' }}>
                        <div className='time'>7-8:30PM  EDT •</div>
                        <a
                          className='ml-2 text-secondary hover:text-blue transition'
                          href='https://www.eventbrite.com/e/yes-202021-award-show-tickets-149613049659'
                          target='_blank'
                          rel='noreferrer'
                        >
                          RSVP
                        </a>
                      </div>
                      <div className='desc'>
                      This spring, nearly 400 students will graduate from the School of Design. Each has beneﬁtted from the wisdom and support of faculty, peers, staff, industry partners and the much broader School of Design community. Yes! is a testament to their collaboration.
                      <br />
                      <br />
                      To commemorate all of the hard work and dedication of our students, we will livestream our annual award show!
                      <br />
                      <br />
                      We encourage you to set up viewing parties on Zoom and engage @designgbc in your conversations! Let's celebrate this massive milestone in your design careers together!
                      <br />
                      <br />
                      Thaaaaaank you!
                      </div>
                      <button className='expandEvent'>
                        <img
                          src='/static/downarrow.png'
                          style={{ width: '30px', margin: 'auto' }}
                          alt='down-arrow'
                        />
                      </button>
                    </EventDiv>
                  </div>
                </div>
              </div>

            </div>

            <div className='w-12 bg-blue hidden lg:block'>
              {/* <img src='/static/svg/glass.svg' alt='glass' className={cn('cursor-pointer')} /> */}
            </div>
          </div>

          </div>
          <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />

        </CenterContainer>



        <SearchCol className='hidden lg:block' />
      </Container>
      <Footer />
    </>
  )
}
