import styled from "styled-components";

const NavStyles = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  justify-self: end;
  font-size: 2.2rem;
  margin-right: 10px;
  position: sticky;
  width: 100%;
  
  @media (max-width: 767px) {
    margin-right: 0;
    padding-left: 15px;
    justify-content: space-between;

    &:not(.open) {
      display: none;
    }
  }
  
  @media (min-width: 768px) {
    width: auto;
  }

  a,
  button {
    padding: 0 10px;
    display: flex;
    align-items: center;
    position: relative;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 300;
    background: none;
    border: 0;
    cursor: pointer;

    @media (min-width: 768px) {
      font-size: 22px;
      padding: 1rem 1.5rem;
    }

    @media (min-width: 992px) {
      padding: 1rem 3rem;
    }

    &:before {
      content: "";
      /* width: 2px; */
      /* background: ${(props) => props.theme.lightgrey}; */
      height: 100%;
      left: 0;
      position: absolute;
      /* transform: skew(-20deg); */
      top: 0;
      bottom: 0;
    }

    &:after {
      height: 2px;
      background: ${(props) => props.theme.pink};
      content: "";
      width: 0;
      position: absolute;
      transform: translateX(-50%);
      transition: width 0.4s;
      transition-timing-function: cubic-bezier(1, -0.65, 0, 2.31);
      left: 50%;
      margin-top: 2rem;
    }

    &:hover,
    &:focus {
      outline: none;
      color: #000000 !important;

      &:after {
        width: calc(100% - 60px);
      }

      @media (max-width: 768px) {
        width: calc(60%);
        padding-left: 14%;
      }
    }
    @media (max-width: 768px) {
      padding: 0 10px 0 0;
    }
  }
  button {
    &:hover,
    &:focus {
      @media (max-width: 768px) {
        width: 50px;
        
        padding: 0 25px;
      }
    }
  }
  

  @media (max-width: 1300px) {
    /* border-top: 1px solid ${(props) => props.theme.lightgrey};
    width: 100%;
    justify-content: center;
    font-size: 1.5rem; */
  }
`

export default NavStyles;
